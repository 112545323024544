import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { RESET_SUCCESS_MESSAGE } from '../../actions/action_parent';

const LeftColumn = styled.div`
  padding: 0 15px;
`;
const RightColumn = styled.div`
  padding: 0 15px;
  float: right;
`;
const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Header1 = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #479de6;
`;

const EditLinkStyled = styled(Link)`
  text-decoration: underline;
  color: #479de6;
  cursor: pointer;
  margin-top: 16px;
`;

const SuccessAlert = styled.div`
  margin-top: 16px;
`;

const ParentMyAccount = () => {
  const dispatch = useDispatch();
  const parent = useSelector((state) => state.parent);
  const successMessage = useSelector((state) => state.parent.successMessage);

  const formattedPhoneNumber = parsePhoneNumberFromString(
    parent.smsPhone || parent.phone,
    'US',
  );

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SUCCESS_MESSAGE });
    };
  }, [successMessage]);

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Account Information</MainHeader>
        {successMessage && (
          <SuccessAlert className="alert alert-success">
            <p>{successMessage}</p>
          </SuccessAlert>
        )}
        <Divider />
        <div className="row">
          <LeftColumn className="col-xs-12 col-md-7 col-lg-7">
            <Wrapper>
              <Header1>Profile and Contact</Header1>
              <hr />
              <div>
                <label>Name</label>
                <p>
                  {parent.firstName} {parent.lastName}
                </p>
                <EditLinkStyled to="/my-account/name">edit</EditLinkStyled>
              </div>
              <hr />
              <div>
                <label>Email & Password</label>
                <p>{parent.email}</p>
                <EditLinkStyled to="/my-account/email">edit</EditLinkStyled>
                <br />
                <EditLinkStyled to="/my-account/change-password">
                  reset password
                </EditLinkStyled>
              </div>
              <hr />
              <div>
                <label>Phone</label>
                <p>
                  {formattedPhoneNumber
                    ? formattedPhoneNumber.formatNational()
                    : 'Invalid Number'}
                </p>
                <EditLinkStyled to="/my-account/phone">edit</EditLinkStyled>
              </div>
              <hr />
              <div>
                <label>Address</label>
                <p>
                  {parent.address.address1}
                  <br />
                  {parent.address.address2 && parent.address.address2}
                  {parent.address.address2 && <br />}
                  {parent.address.city}, {parent.address.state}{' '}
                  {parent.address.zipcode}
                </p>
                <EditLinkStyled to="/my-account/address">edit</EditLinkStyled>
              </div>
            </Wrapper>
          </LeftColumn>
          <RightColumn className="col-xs-12 col-md-5 col-lg-5">
            <Wrapper>
              <Header1>Students</Header1>
              <hr />
              <p>
                {parent.students.map((student) => (
                  <span key={`${student.id}`}>
                    {student.firstName} {student.lastName}
                    <br />
                  </span>
                ))}
              </p>
              <EditLinkStyled to="/my-account/add-student">
                add student
              </EditLinkStyled>
            </Wrapper>
          </RightColumn>
        </div>
      </div>
    </div>
  );
};

export default ParentMyAccount;
