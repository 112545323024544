import { SELECT_SHOOT_PRODUCT } from '../actions/action_shoot_product';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case SELECT_SHOOT_PRODUCT:
      return action.payload;
    default:
      return state;
  }
}
