import React from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentOrderStudentId } from '../../../../../hooks/useCurrentOrderStudentId';
import { declineInterstitialProduct } from '../../../../../actions/v3Order';

const DeclineInterstitialProductuButton = ({ product }) => {
  const dispatch = useDispatch();
  const studentId = useCurrentOrderStudentId();

  return (
    <div
      className="bnl-product-config__button--cancel button bg--dark-grey"
      onClick={() => {
        dispatch(declineInterstitialProduct(studentId, product.id));
      }}
    >
      No Thanks
    </div>
  );
};

export default DeclineInterstitialProductuButton;
