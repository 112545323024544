import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useCurrentOrderSinglePersonalizationValue } from '../../../../../hooks/useCurrentOrderSinglePersonalizationValue';
import formatUSD from '../../../../../utils/formatUSD';

const LeftMarginButton = styled.button`
  margin-left: 8px;
`;

const PersonalizationWrapper = styled.div`
  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const PersonalizationForm = ({
  onSkipPersonalization,
  onSubmitPersonalization,
  personalizationValue,
  setPersonalizationValue,
}) => {
  // TODO: update this to use setting from studio
  // Determines whethe user can skip paid personalization on individual products
  const userCanSkipPaidPersonalization = false;

  const currentOrderSinglePersonalizationValue =
    useCurrentOrderSinglePersonalizationValue();

  const isPrepay = useSelector((state) => state.shoot.isPrepay);
  const personalizationProduct = useSelector(
    (state) => state.shoot.personalizationProduct,
  );
  const prepayRequiresSameValuePersonalization = useSelector(
    (state) => state.shoot.prepayRequiresSameValuePersonalization,
  );
  const postpayRequiresSameValuePersonalization = useSelector(
    (state) => state.shoot.postpayRequiresSameValuePersonalization,
  );

  useEffect(() => {
    if (
      (currentOrderSinglePersonalizationValue !== null &&
        isPrepay &&
        prepayRequiresSameValuePersonalization) ||
      (!isPrepay && postpayRequiresSameValuePersonalization)
    ) {
      setPersonalizationValue(currentOrderSinglePersonalizationValue || '');
    }
  }, [currentOrderSinglePersonalizationValue]);

  const handleChange = (event) => {
    setPersonalizationValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitPersonalization();
  };

  const handleSkip = (event) => {
    event.preventDefault();
    onSkipPersonalization();
  };

  const displayPersonalizationHeader = () => {
    if (personalizationValue) {
      return 'Your Personalization Value';
    }

    return 'Set Your Personalization Value';
  };

  const displayPersonalizationCopy = () => {
    console.log('displayPersonalizationCopy');
    console.log(personalizationValue);
    if (
      personalizationProduct &&
      personalizationProduct.price &&
      personalizationProduct.price > 0
    ) {
      if (
        personalizationValue &&
        !userCanSkipPaidPersonalization &&
        currentOrderSinglePersonalizationValue
      ) {
        return (
          <>
            <p>
              Your product will be personalized with the following value. To
              update, simply enter a new value (limit 20 characters).
            </p>
            <p>
              Note: all personalized products in your student's order will be
              personalized with the same value.
            </p>
          </>
        );
      }

      return (
        <>
          <p>
            Personalize your product by entering your student's first name using
            upper and lower case letters (limit 20 characters).
          </p>
          <p>For example: Jamie</p>
          <p>
            Please do NOT use all caps or add year (year will be added
            automatically).
          </p>
          <p>
            Personalization will be applied to all products in your student's
            order that allow for personalization.
          </p>
          <p>Cost: {formatUSD(personalizationProduct.price)}</p>
          <p>To skip, simply leave the personalization field blank.</p>
        </>
      );
    }

    if (isPrepay && prepayRequiresSameValuePersonalization) {
      return (
        <p>
          Note: the same personalization value will be applied to all
          personalized products in your student's order.
        </p>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <PersonalizationWrapper className="col-md-6">
        <h4>{displayPersonalizationHeader()}</h4>
        <form onSubmit={handleSubmit}>
          {displayPersonalizationCopy()}
          <div className="form-group">
            {/* <label htmlFor="personalization">Personalization:</label> */}
            <input
              type="text"
              className="form-control"
              id="personalization"
              placeholder="Enter personalization value"
              maxLength="20"
              onChange={handleChange}
              value={personalizationValue}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Set Personalization
          </button>
          {userCanSkipPaidPersonalization ||
            (!personalizationProduct?.price > 0 && (
              <LeftMarginButton
                type="button"
                className="btn btn-default"
                onClick={handleSkip}
              >
                Skip
              </LeftMarginButton>
            ))}
        </form>
      </PersonalizationWrapper>
    </div>
  );
};

export default PersonalizationForm;
