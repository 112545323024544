import React, { Component } from 'react';

import OrderBuildYourOwnSlot from './order_build_your_own_slot';

class OrderBuildYourOwnMobileSlots extends Component {
  renderSlots() {
    const slots = [];

    for (let i = 0; i < this.props.SLOTS_COUNT; i++) {
      slots.push(
        <OrderBuildYourOwnSlot
          configuration={this.props.configuration}
          display="mobile"
          freeIndices={this.props.freeIndices}
          key={`byo-slot-${i}`}
          mobile={this.props.mobile}
          productIsBnlYouPickX={this.props.productIsBnlYouPickX}
          removeConfiguredChildItem={this.props.removeConfiguredChildItem}
          selected={this.props.slotIndex === i}
          selectedChildItem={this.props.selectedChildItem}
          selectedShootProduct={this.props.selectedShootProduct}
          selectMobileSlot={this.props.selectMobileSlot}
          slotIndex={i}
        />,
      );
    }

    return slots;
  }

  renderPersonalization() {
    const { renderPersonalizationForm, SLOTS_COUNT, slotIndex } = this.props;

    if (slotIndex === SLOTS_COUNT) {
      return <>{renderPersonalizationForm()}</>;
    }

    return null;
  }

  renderButton() {
    const message = '';
    const fn = null;
    let className = 'byo-button disabled--bg-grey';
    if (
      this.props.canBeAdded &&
      (this.props.activeComponent === this.props.STEPS.products ||
        this.props.activeComponent === this.props.STEPS.none ||
        this.props.activeComponent === this.props.STEPS.personalization)
    ) {
      className = 'byo-button';
    }

    return (
      <div
        className={`${className} button--full-width bg--bnl-light-blue border--round`}
        onClick={this.props.addConfiguredChildItemsToCart}
      >
        Done, Add To Cart
      </div>
    );
  }

  render() {
    const { slotsDisplay } = this.props;

    return (
      <div className={`byo__slots--mobile ${slotsDisplay}`}>
        <ul className="row">{this.renderSlots()}</ul>
        {this.renderPersonalization()}
        {this.renderButton()}
      </div>
    );
  }
}

export default OrderBuildYourOwnMobileSlots;
