import React, { Component } from 'react';

class OrderProductRecs extends Component {
  renderProducts() {
    const products = this.props.products.map((product) => {
      return (
        <li
          className="order-product-recs__product"
          onClick={() =>
            this.props.selectRecommendedProductAndSetProductGroup(product)
          }
          key={`rec-${product.id}`}
        >
          <div className="order-product-recs__product__info clearfix">
            <div className="order-product-recs__product__name">
              {product.name}
            </div>
            <div className="order-product-recs__product__price">
              {this.props.displayPrice(product.price)}
            </div>
          </div>
          <img
            className="order-product-recs__product__image"
            src={product.image_thumbnail}
          />
          <div>{product.description}</div>
        </li>
      );
    });

    return <ul className="clearfix">{products}</ul>;
  }

  render() {
    return (
      <div className="order-product-recs">
        <div className="order-product-recs-container">
          <h3 className="order-product-recs__header">Congratulations!</h3>
          <div className="order-product-recs__subheader">
            You've unlocked add-ons and novelties. Check out some of our most
            popular items.
          </div>
          {this.renderProducts()}
          <div
            className="bnl-button--light-blue"
            onClick={this.props.goToSuggestProductGroup}
          >
            More Add-ons, Novelties &amp; Prints
          </div>
          <div
            className="bnl-button--dark-gray"
            onClick={this.props.keepShopping}
          >
            Shopping Home
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default OrderProductRecs;
