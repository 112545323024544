import React, { Component } from 'react';

class IncentiveConfigurationNone extends Component {
  render() {
    return (
      <div className="bnl-product-config__none">
        Configuration complete. Press 'Done, Add To Cart' to continue.
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default IncentiveConfigurationNone;
