import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import useModalCarousel from '../hooks/modalCarousel';
import ModalCarousel from '../modalCarousel/ModalCarousel';
import BackLink from '../shared/BackLink';
import { AlertText, Header, PrimaryButton, Text, Wrapper } from '../v3Styles';
import {
  CarouselImg,
  CarouselImgWrapper,
  CategoryWrapper,
  Grid,
  Image,
  ImageButton,
  ImageWrapper,
  SubHeader,
  ZoomIcon,
} from './yearbookStyles';

const SelectYearbookBackground = ({ history }) => {
  const { open, closeModal, openModal, slideIndex, setSlideIndex } =
    useModalCarousel();
  const shoot = useSelector((state) => state.shoot);
  const studentGallery = useSelector(
    (state) => state.additionalData.studentGallery,
  );
  const currentStudentGalleryId = useSelector(
    (state) => state.additionalData.currentGalleryImageId,
  );
  const date = shoot.yearbookSelectionDeadline;
  const selectedImage = studentGallery.find(
    (image) => image.id === currentStudentGalleryId,
  );

  const updateYearbookImage = async (bgId) => {
    try {
      const response = await axios.patch(
        `/api/v2/yearbook/images/${currentStudentGalleryId}`,
        {
          yearbook_selection: true,
          yearbook_background_id: bgId,
        },
      );
      if (response.status >= 200 && response.status < 300) {
        history.push('/v3/yearbook-selection-complete');
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      throw new Error('Something went wrong');
    }
  };

  const handleImageClick = (image, bg) => () => {
    Swal.fire({
      html: `
        <p class="header">
          You have made your yearbook pose selection
        </p>
        <p class="red italic">
          ${
            shoot.yearbookSelectionInstructions
              ? shoot.yearbookSelectionInstructions
              : ''
          }
        </p>
        <p>
          If this is not the pose you wish to have in the yearbook, please go back and change your selection
        </p>
        <div class="img-wrapper">
          <img class="bg" src="${bg.image_thumbnail}"/>
          <img src="${image.url}"/>
        </div>
      `,
      confirmButtonText: 'Select and Continue',
      showCancelButton: true,
      // cancelButtonText: 'Cancel',
      customClass: {
        popup: 'popup',
        confirmButton: 'confirm-button',
        cancelButton: 'cancel-button',
      },
      preConfirm: () => {
        updateYearbookImage(bg.id);
      },
    });
  };

  const backgrounds = [];
  shoot.yearbookBackgroundOptions.forEach((bgOption) => {
    backgrounds.push(...bgOption.background_images);
  });

  const handleZoomClick = (bgID) => () => {
    const index = backgrounds.findIndex((bg) => bg.id === bgID);
    setSlideIndex(index);
    openModal();
  };

  return (
    <Wrapper>
      <BackLink history={history} />
      <Wrapper>
        <Header>Select Your Background</Header>
        <Text>
          Please select the background you would like to be used with your
          selected pose in the yearbook
        </Text>
        <AlertText>{`Yearbook poses must be selected by ${moment(date).format(
          'MMMM DD, YYYY',
        )}`}</AlertText>
        <Wrapper>
          {shoot.yearbookBackgroundOptions.map((bgOption) => (
            <CategoryWrapper key={bgOption.id}>
              <SubHeader>{bgOption.name}</SubHeader>
              <Grid>
                {bgOption.background_images.map((bg) => (
                  <ImageWrapper key={bg.id}>
                    <Image
                      background={bg.image_thumbnail}
                      src={selectedImage.url}
                    />
                    {/* TODO: calculate index for backgrounds array */}
                    <ZoomIcon onClick={handleZoomClick(bg.id)} />
                    <ImageButton onClick={handleImageClick(selectedImage, bg)}>
                      Select background
                    </ImageButton>
                  </ImageWrapper>
                ))}
              </Grid>
            </CategoryWrapper>
          ))}
        </Wrapper>
      </Wrapper>

      <ModalCarousel open={open} onClose={closeModal} selectedItem={slideIndex}>
        {backgrounds.map((bg) => (
          <CarouselImgWrapper key={bg.id}>
            <CarouselImg
              background={bg.image_thumbnail}
              src={selectedImage.url}
            />
            <PrimaryButton onClick={handleImageClick(selectedImage, bg)}>
              Select pose
            </PrimaryButton>
          </CarouselImgWrapper>
        ))}
      </ModalCarousel>
    </Wrapper>
  );
};

SelectYearbookBackground.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default SelectYearbookBackground;
