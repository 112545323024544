import React, { Component } from 'react';

import OrderBuildYourOwnMobileSlots from './order_build_your_own_mobile_slots';

class OrderBuildYourOwnMobileConfiguration extends Component {
  renderInstruction() {
    const { activeComponent, mobileConfiguring, slotIndex, STEPS } = this.props;
    // let header = `Tap Item ${this.props.slotIndex + 1} to configure`;
    let header;
    let instruction = '';

    if (mobileConfiguring) {
      header = `Item ${slotIndex + 1} -`;
      switch (activeComponent) {
        case STEPS.products:
          instruction = 'Select a product';
          break;
        case STEPS.poses:
          instruction = 'Select a pose';
          break;
        case STEPS.backgrounds:
          instruction = 'Select a background';
          break;
        case STEPS.sms:
          instruction = 'Verify SMS phone';
          break;
        default:
          instruction = null;
      }
    }

    return (
      <h4 className="byo__config--mobile__inst clearfix">
        <div className="byo__config--mobile__instr_item_num">{header}</div>
        <div className="byo__config--mobile__instr_detail">{instruction}</div>
      </h4>
    );
  }

  render() {
    const slotsDisplay = !this.props.mobileConfiguring ? '' : 'hidden';
    const configDisplay = this.props.mobileConfiguring ? '' : 'hidden';

    return (
      <div className="row byo__config--mobile">
        {this.renderInstruction()}
        <OrderBuildYourOwnMobileSlots
          addConfiguredChildItemsToCart={
            this.props.addConfiguredChildItemsToCart
          }
          activeComponent={this.props.activeComponent}
          canBeAdded={this.props.canBeAdded}
          cartItems={this.props.cartItems}
          cloneObject={this.props.cloneObject}
          configuration={this.props.configuration}
          freeIndices={this.props.freeIndices}
          mobile
          productIsBnlYouPickX={this.props.productIsBnlYouPickX}
          removeConfiguredChildItem={this.props.removeConfiguredChildItem}
          renderPersonalizationForm={this.props.renderPersonalizationForm}
          selectMobileSlot={this.props.selectMobileSlot}
          selectedShootProduct={this.props.selectedShootProduct}
          slotsDisplay={slotsDisplay}
          slotIndex={this.props.slotIndex}
          SLOTS_COUNT={this.props.SLOTS_COUNT}
          STEPS={this.props.STEPS}
        />
        <div className={`byo__config__step--mobile ${configDisplay}`}>
          {this.props.renderActiveComponent}
          {/* {this.props.renderPersonalizationForm(true)} */}
        </div>
      </div>
    );
  }
}

export default OrderBuildYourOwnMobileConfiguration;
