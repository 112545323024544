import styled, { css } from 'styled-components';

const sharedInputStyle = css`
  padding: 15px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
`;

export const ProductForm = styled.form`
  max-width: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
`;

export const FormLabel = styled.label`
  font-weight: 900;
  font-size: 16px;
  margin-right: ${(props) => (props.isInline ? '5px' : '0')};
  display: ${(props) => (props.isInline ? 'inline-block' : 'block')};
`;

export const FormInput = styled.input`
  ${sharedInputStyle}
`;

export const FormTextArea = styled.textarea`
  ${sharedInputStyle}
`;

export const FormSelect = styled.select`
  ${sharedInputStyle}
`;
