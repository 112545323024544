import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentUser } from '../../actions/action_auth';
import { getStudents } from '../../actions/action_parent';
import { setHistory } from '../../actions/action_history';

// React.js components
import ParentBnlAuthContainer from './parent_bnl_auth_container';
import ParentLoginForm from './parent_login_form';
import LandingGb from '../landing/landing_gb';
import LandingBnl from '../landing/landing_bnl';
import LandingNormandy from '../landing/landing_normandy';
import LandingJellyJar from '../landing/LandingJellyJar';
import LandingTipping from '../landing/landing_tipping';
import LandingMarcel from '../landing/landing_marcel';
import LandingClassAct from '../landing/landing_class_act';
import LandingWagner from '../landing/landing_wagner';
import LandingBielmar from '../landing/landing_bielmar';
import LandingSportsJournal from '../landing/landing_sportsjournal';
import LandingGPI from '../landing/landing_gpi';
import LandingStrawbridge from '../landing/landing_strawbridge';
import LandingGeskus from '../landing/landing_geskus';

class ParentAuthContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeComponent: this.getInitialActiveComponent(),
      reloadRequired: false,
    };

    this.setActiveComponent = this.setActiveComponent.bind(this);
  }

  getInitialActiveComponent() {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.goToForm
    ) {
      return 'login';
    }

    return null;
  }

  setActiveComponent(type) {
    this.setState({ activeComponent: type });
  }

  renderContent() {
    // const className = this.props.company.name;
    const { companyToken } = this.props;

    switch (this.state.activeComponent) {
      case 'new-login':
        return <ParentLoginForm history={this.props.history} />;
      case null:
        switch (companyToken) {
          case ('geskus', 'geskusprint'):
            return (
              <LandingGeskus
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case ('bnl', 'bnlschoolpictures'):
            return (
              <LandingBnl
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case ('jellyjar', 'jellyjarphotography'):
            return (
              <LandingJellyJar
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case ('gb', 'grynnandbarrett'):
            return (
              <LandingGb
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'normandy':
            return (
              <LandingNormandy
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'tipping':
            return (
              <LandingTipping
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'marcel':
            return (
              <LandingMarcel
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'classact':
            return (
              <LandingClassAct
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'wagner':
            return (
              <LandingWagner
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'bielmar':
            return (
              <LandingBielmar
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'sportsjournal':
            return (
              <LandingSportsJournal
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'gpi':
            return (
              <LandingGPI
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
          case 'strawbridge':
            return (
              <LandingStrawbridge
                history={this.props.history}
                setActiveComponent={this.setActiveComponent}
              />
            );
        }
        break;
      case 'login':
        return <ParentLoginForm history={this.props.history} />;
      default:
        return <ParentLoginForm history={this.props.history} />;
    }
  }

  componentDidMount() {
    const that = this;
    // redirect to dashboard when directly accessing "/login" URL
    // if current user is truthy and completed sign up
    if (this.props.auth.currentUserId && this.props.auth.signupCompleted) {
      this.props.getStudents(this.props.auth.currentUserId).then((response) => {
        that.props.setHistory(that.props.history);
        that.props.history.replace('/dashboard');
      });
      // redirect to sign up step 2 when directly accessing "/login" URL
      // if current user is truthy but hasn't completed sign up
    } else if (
      this.props.auth.currentUserId &&
      !this.props.auth.signupCompleted
    ) {
      that.props.history.replace('/signup/', { step: 2 });
    }
  }

  // TODO: remove this when confirmed that it's not needed #revisit
  // // called when App component sets current user
  // componentWillReceiveProps(nextProps) {
  //   const that = this;
  //   // redirect to dashboard when directly accessing "/login" URL
  //   // if current user is truthy and completed sign up
  //   if (nextProps.auth.currentUserId && nextProps.auth.signupCompleted) {
  //     this.props.getStudents(nextProps.auth.currentUserId).then((response) => {
  //       that.props.setHistory(that.props.history);
  //       that.props.history.replace('/dashboard');
  //     });
  //     // redirec to sign up step 2 when directly accessing "/login" URL
  //     // if current user is truthy but hasn't completed sign up
  //   } else if (
  //     nextProps.auth.currentUserId &&
  //     !nextProps.auth.signupCompleted
  //   ) {
  //     that.props.history.replace('/signup/', { step: 2 });
  //   }
  // }

  render() {
    if (
      // !this.props.auth.authToken ||
      // this.props.auth.currentUserId ||
      // !this.props.company.name
      !this.props.companyToken
    ) {
      return <div id="loading" />;
    }
    return <div>{this.renderContent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentUser,
      getStudents,
      setHistory,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentAuthContainer);

ParentAuthContainer.propTypes = {
  companyToken: PropTypes.string.isRequired,
};
