import React from 'react';
import CustomerAddressEdit from '../v3/checkout/subcomponents/CustomerAddressEdit';

const Address = () => {
  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <CustomerAddressEdit onSuccessRoute="/my-account" />
      </div>
    </div>
  );
};

export default Address;
