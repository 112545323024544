import React, { Component } from 'react';
import { connect } from 'react-redux';

class BnlOrderProductConfigurationMobile extends Component {
  renderProductNameText() {
    const product = this.props.selectedShootProduct;
    if (product.is_retouching) {
      return `Add Retouching for ${this.props.displayPrice(product.price)}`;
    }
    return product.name;
  }

  renderProductNameTextForRetouching(price) {
    const { displayPrice } = this.props;

    return `Add Retouching for ${displayPrice(price)}`;
  }

  renderInstruction() {
    if (this.props.selectedShootProduct.requires_configuration) {
      return (
        <div className="bnl-product-config__mobile-instruction">
          Tap to configure
        </div>
      );
    }
  }

  renderListOfRetouchingProducts(infoClassName, stepClassName) {
    const { retouchingProducts } = this.props.shoot;
    const filteredRetouchingProducts = [];
    const retouchingProductName = [];

    if (
      typeof retouchingProducts === 'object' &&
      !Array.isArray(retouchingProducts)
    ) {
      filteredRetouchingProducts.push(retouchingProducts);
    } else {
      // Filter the list of retouching products by name to ensure we're
      // not displaying more than one instance.
      for (let i = 0; i < retouchingProducts.length; i++) {
        if (!retouchingProductName.includes(retouchingProducts[i].name)) {
          retouchingProductName.push(retouchingProducts[i].name);
          filteredRetouchingProducts.push(retouchingProducts[i]);
        }
      }
    }

    const retouchingRowStyle = {
      marginTop: '32px',
    };

    const retouchingProductsList = filteredRetouchingProducts.map(
      (product, index) => (
        <div className="row" key={index}>
          <div
            className={`bnl-product-config__step col-xs-12 ${stepClassName}`}
          >
            {index === 0 && this.props.activeStepComponent}
          </div>
          <div
            className={`bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4 ${infoClassName}`}
            style={retouchingRowStyle}
          >
            <div className="bnl-product-config__product">
              <h3 className="bnl-product-config__header text-center">
                {this.renderProductNameTextForRetouching(product.price)}
              </h3>
              <img
                className="bnl-product-config__product-image"
                src={product.image_thumbnail}
              />
              <div className="bnl-product-config__product-desc">
                {product.description}
              </div>
            </div>
            {this.props.renderMobileButton(
              product,
              index,
              filteredRetouchingProducts.length,
            )}
          </div>
        </div>
      ),
    );

    return <div>{retouchingProductsList}</div>;
  }

  renderRetouchingProducts(infoClassName, stepClassName) {
    return (
      <div className="bnl-product-config bnl-product-config--mobile">
        {this.props.renderSubheader(true)}
        <div>
          {this.renderListOfRetouchingProducts(infoClassName, stepClassName)}
        </div>
      </div>
    );
  }

  renderInterstitialProduct(product, infoClassName, stepClassName) {
    const {
      activeStepComponent,
      displayPrice,
      renderMobileButton,
      renderMobileSelectedPoseAndBackground,
      renderPersonalizationForm,
      renderRequiredAttributesForm,
      renderSmsForm,
      renderSubheader,
    } = this.props;

    const mobileConfigConfigContainerStyle = {
      paddingTop: '36px',
    };
    const mobileConfigHeaderStyle = { marginBottom: '24px' };

    return (
      <div>
        <div
          className="bnl-product-config bnl-product-config--mobile"
          style={mobileConfigConfigContainerStyle}
        >
          {/* {renderSubheader(true)} */}
          <div className="row">
            <div
              className={`bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4 ${infoClassName}`}
            >
              <div className="bnl-product-config__product">
                <h3
                  className="bnl-product-config__header text-center"
                  style={mobileConfigHeaderStyle}
                >
                  Would you like to add this recommended product?
                  {/* {this.renderProductNameText()} */}
                </h3>
                <img
                  className="bnl-product-config__product-image"
                  src={product.image_thumbnail}
                  alt="product thumbnail"
                />
                <div className="bnl-product-config__product-desc">
                  {product.description}
                </div>
                <div className="bnl-product-config__product-desc">
                  {displayPrice(product.price)}
                </div>
                <div className="interstitial-mobile-config__preview-image">
                  {this.renderGroupImage(product)}
                </div>
              </div>
              {renderMobileButton(product)}
            </div>
            {/* <div
              className={`bnl-product-config__step col-xs-12 ${stepClassName}`}
            >
              {this.props.activeStepComponent}
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  renderGroupImage(product) {
    let galleryObj;
    const groupImages = [];
    const companyPricingModelIdFromGallery = this.props?.gallery?.cpmId;

    // look for an associated group image
    if (companyPricingModelIdFromGallery && this.props?.gallery?.galleries) {
      galleryObj =
        this.props.gallery.galleries[companyPricingModelIdFromGallery];

      if (galleryObj) {
        const keys = Object.keys(galleryObj);

        if (keys.length > 0 && galleryObj[keys[0]].associatedGroupImages) {
          galleryObj[keys[0]].associatedGroupImages.forEach((groupImage) => {
            groupImages.push(groupImage);
          });
        }
      }
    }

    if (product.image_type === 'group_image' && groupImages.length > 0) {
      return (
        <div className="group-image-config-preview">
          <img src={groupImages[0].url} />
        </div>
      );
    }

    return null;
  }

  renderProduct(product, infoClassName, stepClassName) {
    return (
      <div>
        <div className="bnl-product-config bnl-product-config--mobile">
          {this.props.renderSubheader(true)}
          <div className="row">
            <div
              className={`bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4 ${infoClassName}`}
            >
              <div className="bnl-product-config__product">
                <h3 className="bnl-product-config__header text-center">
                  {this.renderProductNameText()}
                </h3>
                <img
                  className="bnl-product-config__product-image"
                  src={product.image_thumbnail}
                />
                <div className="bnl-product-config__product-desc">
                  {product.description}
                </div>
                {this.renderInstruction()}
                {this.props.renderMobileSelectedPoseAndBackground()}
                {this.props.renderPersonalizationForm(true)}
                {this.props.renderRequiredAttributesForm(true)}
                {this.props.renderSmsForm()}
              </div>
              {this.props.renderMobileButton()}
            </div>
            <div
              className={`bnl-product-config__step col-xs-12 ${stepClassName}`}
            >
              {this.props.activeStepComponent}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { mobileConfiguring, selectedShootProduct } = this.props;
    const infoClassName = mobileConfiguring ? 'hidden' : '';
    const stepClassName = mobileConfiguring ? '' : 'hidden';

    if (selectedShootProduct.is_retouching) {
      return this.renderRetouchingProducts(
        selectedShootProduct,
        infoClassName,
        stepClassName,
      );
    }

    if (selectedShootProduct.triggers_interstitial) {
      return this.renderInterstitialProduct(
        selectedShootProduct,
        infoClassName,
        stepClassName,
      );
    }

    return this.renderProduct(
      selectedShootProduct,
      infoClassName,
      stepClassName,
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    gallery: state.gallery,
    order: state.order,
    parent: state.parent,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps)(BnlOrderProductConfigurationMobile);
