import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ImgContainer = styled.div`
  flex: 1 0 calc(25% - 24px); // 25% for 4-across grid minus gutter
  max-width: calc(25% - 24px); // Ensure 4-across grid always
  cursor: pointer;
  position: 'relative';
  overflow: 'hidden';
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    // Adjustments for mobile
    flex: 1 0 50%; // Take 50% of the container width
    max-width: 50%; // Make sure it doesn't exceed 50%
    margin: 0 auto; // Center the item in the column
  }

  @media (min-width: 481px) and (max-width: 768px) {
    // Between these widths, 2-across grid
    flex: 1 0 calc(50% - 24px); // 50% for 2-across grid minus gutter
    max-width: calc(50% - 24px);
  }

  img {
    max-width: 100%;
    height: auto;
    background-color: white;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    pointer-events: none;
    -webkit-user-drag: none;
    user-select: none;
    object-fit: cover;
  }
`;

const Name = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const PoseItem = ({ onClick, name, ...rest }) => (
  <ImgContainer onClick={onClick}>
    <img
      {...rest}
      alt={rest.alt || ''}
      onContextMenu={(e) => e.preventDefault}
    />
    {name && <Name>{name}</Name>}
  </ImgContainer>
);
PoseItem.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
};

PoseItem.defaultProps = {
  onClick: null,
  name: '',
};

export default PoseItem;
