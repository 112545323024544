import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import formatUSD from '../../../utils/formatUSD';

const IncentivesWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 100px 0;
  text-align: center;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 50%; // 1:2 Aspect Ratio
  background: none;
  margin-bottom: 16px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const AdditionalText = ({ name }) =>
  name === 'Free Backpack' ? (
    <div className="incentive-display__products__product__threshold__per">
      Per Student
    </div>
  ) : null;

const IncentiveOffers = (props) => {
  const { incentiveProducts } = useSelector((state) => state.shoot);
  const { history } = props;

  useEffect(() => {
    if (!incentiveProducts || !incentiveProducts.length) {
      history.push('/v3/offers');
    }
  }, []);

  const determinePriceDisplay = (price) =>
    price === 0 ? 'Free' : `Spend ${formatUSD(price)}`;

  return (
    <IncentivesWrapper>
      <div className="incentive-display__header">Unlock Bonus Items!</div>
      <div className="incentive-display__subheader">
        Use the progress bar to track earned bonus items while you shop
      </div>
      <ul className="incentive-display__products clearfix">
        {incentiveProducts.map((incentiveProduct) => (
          <li
            className="incentive-display__products__product"
            key={`incentive-product-${incentiveProduct.id}`}
          >
            <div className="incentive-display__products__product__threshold">
              {determinePriceDisplay(incentiveProduct.incentive_threshold)}
            </div>
            <AdditionalText name={incentiveProduct.name} />
            <ImageContainer>
              <img
                className="incentive-display__products__product__image"
                src={incentiveProduct.image_thumbnail}
              />
            </ImageContainer>
            <div className="incentive-display__products__product__name">
              {incentiveProduct.name}
            </div>
          </li>
        ))}
      </ul>
      <Link to="/v3/offers">
        <div className="incentive-display__button button--bnl">Got It!</div>
      </Link>
    </IncentivesWrapper>
  );
};

export default IncentiveOffers;
