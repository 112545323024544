import React, { Component } from 'react';
import { connect } from 'react-redux';

class OrderBuildYourOwnPoses extends Component {
  renderPoses(gallery, student) {
    const poses = gallery.map((pose, i) => {
      let selected = '';

      // if (this.props.selectedPose) {
      //   if (this.props.selectedPose.id === pose.id) {
      //     selected = "bnl-product-config__pose--selected ";
      //   }
      // }
      pose['student'] = student;

      return (
        <li
          className="bnl-product-config__pose col-xs-6 col-md-3 col-lg-3 cursor-pointer"
          onClick={() => this.props.selectPose(pose)}
          key={`pose-${pose.id}`}
        >
          <img
            className={`bnl-product-config__pose-image ${selected}`}
            src={pose.url}
          />
        </li>
      );
    });

    return poses;
  }

  renderGalleries() {
    let galleries = [];
    const cpmId = this.props.gallery.cpmId;
    const galleriesObj = this.props.gallery.galleries[cpmId];
    for (let studentId in galleriesObj) {
      let galleryObj = galleriesObj[studentId];

      if (galleryObj.gallery.length > 0) {
        galleries.push(
          <div
            className="bnl-product-config__student"
            key={`gallery-${studentId}`}
          >
            <div className="bnl-product-config__student_name">
              {galleryObj.student.first_name} {galleryObj.student.last_name}
            </div>
            <ul className="bnl-product-config__student__poses row">
              {this.renderPoses(galleryObj.gallery, galleryObj.student)}
            </ul>
            <div className="bnl-divider" />
          </div>
        );
      }
    }

    return galleries;
  }

  render() {
    return (
      <ul className="byo__poses row byo__row col-xs-12 col-md-8 col-lg-8">
        {this.renderGalleries()}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.gallery,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(OrderBuildYourOwnPoses);
