import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { setCurrentUser } from '../../actions/action_auth';
import {
  setAdditionalData,
  resetAdditionalData,
} from '../../actions/additionalData';
import {
  getStudents,
  updateEmail,
  updateAddress,
  setSmsPhone,
} from '../../actions/action_parent';
import {
  getStripePublishableKey,
  acquireSms,
  storeOrderContainerOrderId,
  orderShouldOfferRetouching,
  orderHasOfferedRetouching,
  resetOrderRetouching,
  updateCurrentStudentId,
} from '../../actions/action_order';
import {
  getShootData,
  formatAndSetBackgroundOptions,
  formatAndSetIncentiveProducts,
} from '../../actions/action_shoot';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';
import {
  turnOnNotification,
  turnOffNotification,
} from '../../actions/action_notification';
import {
  storeBnlBackgroundSelection,
  storeOrder,
  removeOrder,
  updateCartCount,
  resetCart,
  removePersonalizationOnOrder,
  setPersonalizationOnOrder,
  updateBnlUnlockedGroups,
  updateBnlFreeBackgrounds,
  updateBnlRetouching,
  updateBnlPromptRetouching,
  updateInterstitialPromptedForCurrentOrder,
} from '../../actions/action_cart';
import { selectShootProduct } from '../../actions/action_shoot_product';
import {
  resetBnlIncentive,
  updateBnlIncentiveCartItems,
  updateBnlIncentiveConfiguration,
  updateBnlIncentiveCartLevelItems,
  updateBnlFreeShipping,
} from '../../actions/action_bnl_incentive';
import {
  resetGeskusIncentive,
  updateGeskusFreeShipping,
  updateGeskusIncentiveCartLevelItems,
} from '../../actions/action_geskus_incentive';
import { setBnlPreShootStudent } from '../../actions/action_bnl_pre_shoot';
import {
  updateDiscountCode,
  resetDiscountCode,
} from '../../actions/action_discount_code';

// General eCommerce React.js components
import OrderShootKeyForm from './order_shoot_key_form';
import OrderShootConfirmation from './order_shoot_confirmation';
import OrderProductAddons from './order_product_add_ons';
import OrderCheckout from './OrderCheckout';
import OrderConfirmation from './order_confirmation';
import IncentiveModalContainer from '../incentives/incentive_modal_container';
import IncentiveTracker from '../incentives/incentive_tracker';
import IncentiveConfigurationContainer from '../incentives/incentive_configuration_container';
import OrderProductRecs from './order_product_recs';
import BannerMessage from './BannerMessage';

// Geskus exclusive React.js components
import OrderProductGroups from './order_product_groups';
import OrderProductConfiguration from './order_product_configuration';
import OrderYouPickX from './order_you_pick_x';
import OrderCart from './order_cart';
import GeskusIncentiveConfigurationContainer from '../incentives/geskus/geskus_incentive_configuration_container';

// BNL exclusive React.js components
import BnlOrderProductGroups from './bnl/bnl_order_product_groups';
import NthFreeProductsList from './bnl/NthFreeProductsList';
import OrderPoseSelection from './order_pose_selection';
import BnlOrderProductConfigurationContainer from './bnl/bnl_order_product_configuration_container';
import OrderBuildYourOwn from './build_your_own/order_build_your_own';
import BnlOrderCart from './bnl/bnl_order_cart';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../constants/companies';

class OrderContainer extends Component {
  constructor(props) {
    super(props);

    this.BNL_FILTER = {
      portrait_image: ['individual_photo'],
      group_image: ['sibling_photo', 'group_photo'], // TODO: ask Zach if sibling_photo is also group_image
      no_image: [],
    };

    this.state = {
      // OrderContainer
      studentId: this.props.parent.selectedStudentId,
      studentName: this.props.parent.selectedStudentName,

      // OrderShootKeyForm
      backgroundChoiceMode: '',
      companyShippingCost: 0,
      formattedBackgroundOptions: {},
      gradeCode: 'SELECT A GRADE',
      pricingDiscount: 0,
      shootKey: '',
      shootName: '',
      teacherLastName: '',
      shoot: {},
      studentPhotoPreviewUrl: '',

      // OrderProductGroups
      selectedShootProduct: null,
      selectedProductGroup: null,
      idSuffix: 0,

      // OrderBuildYourOwn
      buildYourOwnCartItems: [],
      buildYourOwnSelectedBackgroundOptions: {},
      byoIdSuffix: 0,

      // OrderPoseSelection
      selectedPose: null,

      // OrderYouPickX
      youPickXConfiguration: {},
      youPickXCartItems: [],
      youPickXConfiguring: false,
      youPickXSelectedBackgroundOptions: {},

      // BNL Product
      bnlEditConfiguration: null, // used just for editing purpose for now
      bnlSelectedBackgroundOptions: {},
      bnlIncludedProductsBackgroundOptions: {},
      smsPhone: this.getSmsPhone(),
      smsErrorMessage: '',

      // OrderProductConfiguration
      configuration: {
        itemId: null,
        backgroundCodes: [],
        additionalBackgroundCodes: [],
        backgroundCodesPrices: [],
        additionalBackgroundCodesPrices: [],
        quantity: 1,
      },

      additionalSelectedBackgroundOptions: {},
      allBackgroundOptionsSelected: false,
      quantity: 1,
      selectedBackgroundColor: 'NONE',
      selectedBackgroundOptions: {},
      nthFreeTriggered: false,
      nthFreeThreshold: 0,

      // OrderProductAddons
      personalization: 'false',
      personalizationStatus: null,
      personalizationValue: '',
      photoEnhancement: 'NONE',
      personalizationApplied: false,
      photoEnhancementApplied: false,
      renderAddOns: true,

      // IncentiveContainer
      incentiveCartItems: [],
      incentiveConfiguration: {},
      qualifyingForIncentive: false,

      // OrderCart
      cartCount: 0,
      cartItems: [],
      bnlSelectedBackgrounds: [],
      cartItemsTotalCost: 0,
      personalizationCost: 0,
      photoEnhancementCost: 0,
      shippingPrice: 0,

      // OrderCheckout
      cardErrorMessage: '',
      parentAddress: {
        address1: this.props.auth.currentUserAddress.address_1,
        address2: this.props.auth.currentUserAddress.address_2,
        city: this.props.auth.currentUserAddress.city,
        state: this.props.auth.currentUserAddress.state,
        zipcode: this.props.auth.currentUserAddress.zipcode,
      },
      parentEmail: this.props.auth.currentUserEmail,
      submitting: false,

      // utils
      includedProducts: [],
      includedProductsParentProductId: null,
      productGroupActiveComponent: 1,
      configuringFromCart: false,
      editingFromCart: false,
      errors: {},
      recommendProducts: false,
      updated: false,
      createdAt: Date.now(),
      unmountedByBackButton: false,
    };

    this.BnlByoSheetPrice = 1500;
    this.baseState = Object.assign({}, this.state);
    this.loadOrderState();

    this.addConfiguredIncentiveItem =
      this.addConfiguredIncentiveItem.bind(this);
    this.addConfiguredGeskusIncentiveItem =
      this.addConfiguredGeskusIncentiveItem.bind(this);
    this.addConfiguredItemToCart = this.addConfiguredItemToCart.bind(this);
    this.addConfiguredBnlItemToCart =
      this.addConfiguredBnlItemToCart.bind(this);
    this.addConfiguredBuildYourOwnItem =
      this.addConfiguredBuildYourOwnItem.bind(this);
    this.addConfiguredYouPickXItemsToCart =
      this.addConfiguredYouPickXItemsToCart.bind(this);
    this.addCurrentOrderToReduxCart =
      this.addCurrentOrderToReduxCart.bind(this);
    this.addPaymentToken = this.addPaymentToken.bind(this);
    this.addStudent = this.addStudent.bind(this);
    this.applyPhotoEnhancement = this.applyPhotoEnhancement.bind(this);
    this.applyPersonalization = this.applyPersonalization.bind(this);
    this.backgroundOptionSelected = this.backgroundOptionSelected.bind(this);
    this.calculateCartItemBackgroundPrice =
      this.calculateCartItemBackgroundPrice.bind(this);
    this.calculateCartItemDiscount = this.calculateCartItemDiscount.bind(this);
    this.calculateStudentDiscount = this.calculateStudentDiscount.bind(this);
    this.calculateByoPrice = this.calculateByoPrice.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.calculateTotalForIncentive =
      this.calculateTotalForIncentive.bind(this);
    this.calculateOrderTotalForIncentive =
      this.calculateOrderTotalForIncentive.bind(this);
    this.calculateGrandTotal = this.calculateGrandTotal.bind(this);
    this.calculateShipping = this.calculateShipping.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.cartIsNotEmpty = this.cartIsNotEmpty.bind(this);
    this.checkForIncentive = this.checkForIncentive.bind(this);
    this.checkForBnlIncentive = this.checkForBnlIncentive.bind(this);
    this.cloneArray = this.cloneArray.bind(this);
    this.cloneObject = this.cloneObject.bind(this);
    this.companyIsBnlCompany = this.companyIsBnlCompany.bind(this);
    this.configureIncentiveItem = this.configureIncentiveItem.bind(this);
    this.configureBnlIncentiveItem = this.configureBnlIncentiveItem.bind(this);
    this.configureItem = this.configureItem.bind(this);
    this.configureYouPickXItem = this.configureYouPickXItem.bind(this);
    this.configureAdditionalSelectedBackgroundOptions =
      this.configureAdditionalSelectedBackgroundOptions.bind(this);
    this.confirmShoot = this.confirmShoot.bind(this);
    this.displayShippingPrice = this.displayShippingPrice.bind(this);
    this.editBackground = this.editBackground.bind(this);
    this.editIncentiveBackground = this.editIncentiveBackground.bind(this);
    this.editBnlIncentiveBackground =
      this.editBnlIncentiveBackground.bind(this);
    this.editPersonalization = this.editPersonalization.bind(this);
    this.editQuantity = this.editQuantity.bind(this);
    this.editYouPickXBackgrounds = this.editYouPickXBackgrounds.bind(this);
    this.formattedPhoneNumber = this.formattedPhoneNumber.bind(this);
    this.getCurrentStudentFromAdditionalData =
      this.getCurrentStudentFromAdditionalData.bind(this);
    this.getSmsPhone = this.getSmsPhone.bind(this);
    this.getStudentImage = this.getStudentImage.bind(this);
    this.goBackToProductGroupsProducts =
      this.goBackToProductGroupsProducts.bind(this);
    this.goToBnlCart = this.goToBnlCart.bind(this);
    this.goToBnlProductGroups = this.goToBnlProductGroups.bind(this);
    this.goToBuildYourOwn = this.goToBuildYourOwn.bind(this);
    this.goToClassPhoto = this.goToClassPhoto.bind(this);
    this.goToCheckout = this.goToCheckout.bind(this);
    this.goToDashboard = this.goToDashboard.bind(this);
    this.goToPersonalization = this.goToPersonalization.bind(this);
    this.goToSuggestProductGroup = this.goToSuggestProductGroup.bind(this);
    this.handleBnlConfigurationUnmount =
      this.handleBnlConfigurationUnmount.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSmsInputChange = this.handleSmsInputChange.bind(this);
    this.hasIncludedProducts = this.hasIncludedProducts.bind(this);
    this.addToByoIdSuffix = this.addToByoIdSuffix.bind(this);
    this.keepShopping = this.keepShopping.bind(this);
    this.navigateToComponent = this.navigateToComponent.bind(this);
    this.productIsBnlYouPickX = this.productIsBnlYouPickX.bind(this);
    this.removeBuildYourOwnItem = this.removeBuildYourOwnItem.bind(this);
    this.removeCartItem = this.removeCartItem.bind(this);
    this.removePersonalization = this.removePersonalization.bind(this);
    this.removePhotoEnhancement = this.removePhotoEnhancement.bind(this);
    this.removeYouPickXItem = this.removeYouPickXItem.bind(this);
    this.renderNextComponent = this.renderNextComponent.bind(this);
    this.renderPreviousComponent = this.renderPreviousComponent.bind(this);
    this.resetBnlProductConfiguration =
      this.resetBnlProductConfiguration.bind(this);
    this.resetConfiguration = this.resetConfiguration.bind(this);
    this.resetYouPickXConfiguration =
      this.resetYouPickXConfiguration.bind(this);
    this.resetYouPickXCartItems = this.resetYouPickXCartItems.bind(this);
    this.setBnlPromptRetouching = this.setBnlPromptRetouching.bind(this);
    this.setInterstitialPromptedForCurrentOrder =
      this.setInterstitialPromptedForCurrentOrder.bind(this);
    this.selectPose = this.selectPose.bind(this);
    this.selectProductGroup = this.selectProductGroup.bind(this);
    this.selectShootProduct = this.selectShootProduct.bind(this);
    this.selectRecommendedProductAndSetProductGroup =
      this.selectRecommendedProductAndSetProductGroup.bind(this);
    this.setStudent = this.setStudent.bind(this);
    this.shouldOfferRetouchingOrInterstitial =
      this.shouldOfferRetouchingOrInterstitial.bind(this);
    this.skipPersonalization = this.skipPersonalization.bind(this);
    this.storeBnlIncludedProductConfiguration =
      this.storeBnlIncludedProductConfiguration.bind(this);
    this.storeStudentOrder = this.storeStudentOrder.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.submitShootKeyForm = this.submitShootKeyForm.bind(this);
    this.submitSmsPhone = this.submitSmsPhone.bind(this);
    this.toggleYouPickXConfiguration =
      this.toggleYouPickXConfiguration.bind(this);
    this.updateCheckoutInformation = this.updateCheckoutInformation.bind(this);
    this.updateCheckoutSmsPhone = this.updateCheckoutSmsPhone.bind(this);
    this.updateUnmountedByBackButton =
      this.updateUnmountedByBackButton.bind(this);
    this.youPickXBackgroundOptionSelected =
      this.youPickXBackgroundOptionSelected.bind(this);
  }

  cloneObject(object) {
    return Object.assign({}, object);
  }

  deepCloneObject(object) {
    // converts date objects to strings but not back to dates
    return JSON.parse(JSON.stringify(object));
  }

  cloneArray(array) {
    return array.slice();
  }

  /**
    Helper method to allow us to send additionalData when submitting student order data.
    It's a bit of a hack to address issues where student keys and/or event data was not
    available in the cart and/or on order submit.
  **/
  storeStudentOrder(studentId, orderData) {
    const { additionalData, storeOrder } = this.props;

    storeOrder(studentId, orderData, additionalData);
  }

  loadOrderState() {
    let studentId;

    if (this.props.shoot.shoot && this.props.shoot.shoot.company_auth_token) {
      switch (this.props.shoot.shoot.company_auth_token) {
        // case GESKUS:
        //   studentId = this.state.studentId;
        //   break;
        case BNL:
        case JELLYJAR:
        case GB:
        case NORMANDY:
        case TIPPING:
        case MARCEL:
        case CLASSACT:
        case WAGNER:
        case BIELMAR:
          if (this.props.bnlPreShoot.preShoot) {
            studentId = this.props.bnlPreShoot.selectedStudent.id;
          } else if (this.props.cart.count > 0) {
            studentId = Object.keys(this.props.cart.orders)[0];
          }
          break;
        default:
          if (this.props.bnlPreShoot.preShoot) {
            studentId = this.props.bnlPreShoot.selectedStudent.id;
          } else if (this.props.cart.count > 0) {
            studentId = Object.keys(this.props.cart.orders)[0];
          }
          break;
      }
    }

    const orderState = this.props.cart.orders[studentId];

    if (orderState) this.state = orderState;
  }

  shootIsGeskusShoot() {
    return this.props.shoot.shoot.company_auth_token === GESKUS;
  }

  shootIsBnlShoot() {
    if (
      this.props.shoot.shoot.company_auth_token === BNL ||
      this.props.shoot.shoot.company_auth_token == JELLYJAR ||
      this.props.shoot.shoot.company_auth_token === GB ||
      this.props.shoot.shoot.company_auth_token === NORMANDY ||
      this.props.shoot.shoot.company_auth_token === TIPPING ||
      this.props.shoot.shoot.company_auth_token === MARCEL ||
      this.props.shoot.shoot.company_auth_token === CLASSACT ||
      this.props.shoot.shoot.company_auth_token === WAGNER ||
      this.props.shoot.shoot.company_auth_token === BIELMAR
    ) {
      return true;
    }

    return false;
  }

  shootIsAdvancedGreenScreenBackgroundsOnly() {
    return (
      this.props.shoot.shoot.background_choice_mode ===
      'advanced_greenscreen_backgrounds_only'
    );
  }

  shootIsOutdoorsBackgroundOnly() {
    return (
      this.props.shoot.shoot.background_choice_mode ===
      'outdoors_background_only'
    );
  }

  companyIsBnlCompany() {
    return [
      BNL,
      JELLYJAR,
      GB,
      NORMANDY,
      TIPPING,
      MARCEL,
      CLASSACT,
      WAGNER,
      BIELMAR,
      GESKUS,
    ].includes(this.props.shoot.shoot.company_auth_token);
  }

  companyIsGeskus() {
    return this.props.shoot.shoot.company_auth_token === GESKUS;
  }

  addElementToArray(array, element) {
    array.push(element);

    return array;
  }

  removeElementFromArray(array, element, index = null) {
    if (!index) {
      index = array.indexOf(element);
    }

    const left = array.slice(0, index);
    const right = array.slice(index + 1, array.length);
    const codes = left.concat(right);

    return codes;
  }

  cancelEdit(goBackToCart) {
    this.setState(
      {
        bnlEditConfiguration: null,
        editingFromCart: false,
      },
      () => {
        this.storeStudentOrder(this.state.studentId, this.state);
        this.props.selectShootProduct(null);
      },
    );
  }

  productIsBnlYouPickX() {
    // only true BYO has 0 required children count
    return (
      this.props.shootProduct.product_type === 'parent_product' &&
      this.props.shootProduct.required_children_count > 0
    );
  }

  incentiveItemAppliesToCart(incentiveItem) {
    return incentiveItem.incentive_applies_to === 'cart_level';
  }

  incentiveItemAppliesToOrder(incentiveItem) {
    return incentiveItem.incentive_applies_to === 'order_level';
  }

  getSmsPhone() {
    const {
      parent: { smsPhone, phone },
    } = this.props;
    let smsPhoneToReturn = '';

    if (
      smsPhone &&
      smsPhone.trim().length >= 12 &&
      smsPhone.trim().includes('+')
    ) {
      smsPhoneToReturn = smsPhone.trim().split('+')[1].slice(1);
    } else if (
      phone &&
      phone.trim().length >= 12 &&
      phone.trim().includes('+')
    ) {
      phone
        .trim()
        .split('+')[1]
        .split('')
        .forEach((num, i) => {
          if (i !== 0) smsPhoneToReturn += num;
        });
    }

    return smsPhoneToReturn;
  }

  formattedPhoneNumber() {
    // const smsPhone = this.state.smsPhone;
    const smsPhone = this.state.smsPhone.replace(/-|\s/g, '');
    const number = smsPhone.split('').map((digit, i) => {
      if (
        (i === 2 && smsPhone.length > 3) ||
        (i === 5 && smsPhone.length > 6)
      ) {
        return `${digit}-`;
      }
      return digit;
    });

    return number.join('');
  }

  resetConfiguration() {
    const configuration = {
      itemId: null,
      backgroundCodes: [],
      additionalBackgroundCodes: [],
      backgroundCodesPrices: [],
      additionalBackgroundCodesPrices: [],
    };

    this.setState({ configuration });
  }

  resetYouPickXConfiguration() {
    this.setState({
      youPickXConfiguration: {},
      youPickXConfiguring: false,
    });
  }

  goToCart() {
    console.log('goToCart');
    // console.log('%cTROUBLESHOOT - Nav 7', 'color: red');
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_CART);
  }

  updateUnmountedByBackButton() {
    this.setState(
      {
        bnlEditConfiguration: null,
        unmountedByBackButton: true,
      },
      () => {
        this.redirectAfterUnmountingByBackButton();
      },
    );
  }

  redirectAfterUnmountingByBackButton() {
    if (this.state.editingFromCart) {
      // console.log('%cTROUBLESHOOT - Nav 8', 'color: red');
      this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_CART);
      this.props.selectShootProduct(null);
    } else if (this.props.shootProduct.product_type === 'parent_product') {
      this.props.updateOrderActiveComponent(
        ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
      );
      this.props.selectShootProduct(null);
    } else if (this.state.selectedProductGroup) {
      this.goBackToProductGroupsProducts();
    } else {
      this.props.updateOrderActiveComponent(
        ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
      );
    }

    this.setState(
      {
        bnlEditConfiguration: null,
        editingFromCart: false,
        unmountedByBackButton: false,
      },
      () => {
        this.storeStudentOrder(this.state.studentId, this.state);
      },
    );
  }

  // user navigated away from the configuring screen
  handleBnlConfigurationUnmount(source = null) {
    // if user was editing from cart
    if (!this.state.unmountedByBackButton) {
      if (this.state.editingFromCart) {
        // cancel edit state and additional data that was set up when "Edit" was clicked
        this.cancelEdit();
      } else {
        // this.resetBnlProductConfiguration();
        // NOTE: here is where we're checking needed incentive config
        if (!this.state.nthFreeTriggered) {
          if (source === 'buildYourOwn') {
            // do nothing
            // TODO: revisit this logic... should heading back from YPX/BYP *never* check for an incentive?
          } else {
            // console.log('%cCHECK FOR BNL INCENTIVE 9', 'color: lime');
            this.checkForBnlIncentive(this.state);
          }
        }
      }
    } else {
      this.setState(
        {
          unmountedByBackButton: false,
        },
        () => {
          // console.log('%cCHECK FOR BNL INCENTIVE 10', 'color: lime');
          this.checkForBnlIncentive(this.state);
        },
      );
    }
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  shootIsBnlPreShoot() {
    return this.props.bnlPreShoot.preShoot;
  }

  toggleNotification(message) {
    const that = this;
    this.props.turnOnNotification(message);
    setTimeout(() => {
      that.props.turnOffNotification();
    }, 3000);
  }

  formatIncentiveProducts(incentiveProducts) {
    const formattedIncentiveProducts = {};

    incentiveProducts.forEach((incentiveProduct) => {
      const threshold = incentiveProduct.incentive_threshold;
      if (!formattedIncentiveProducts[threshold]) {
        formattedIncentiveProducts[threshold] = [];
      }

      formattedIncentiveProducts[threshold].push(incentiveProduct);
    });

    this.props.formatAndSetIncentiveProducts(formattedIncentiveProducts);

    return formattedIncentiveProducts;
  }

  formatGalleryImages(galleryImages) {
    if (this.props.shoot.shoot.company_auth_token === BNL) {
      const formattedGalleryImages = {};

      galleryImages.forEach((galleryImage) => {
        formattedGalleryImages[galleryImage.id] = galleryImage;
      });

      return formattedGalleryImages;
    }

    return [];
  }

  getStudentImage() {
    const { additionalData } = this.props;
    const {
      studentImage: {
        student_image_url,
        demo_student_image_url,
        demo_student_image_disclaimer,
      },
    } = additionalData;

    if (student_image_url) {
      return {
        url: student_image_url,
        desc: 'Student Image',
        className: 'order-student-image',
        imageDisclaimer: demo_student_image_disclaimer,
      };
    }

    if (demo_student_image_url) {
      return {
        url: demo_student_image_url,
        desc: 'Student Image',
        className: 'order-student-image',
        imageDisclaimer: demo_student_image_disclaimer,
      };
    }

    // TODO: remove
    if (this.state.studentImage.student_image_url) {
      return {
        url: this.state.studentImage.student_image_url,
        desc: 'Student Image',
        className: 'order-student-image',
        imageDisclaimer: this.state.studentImage.demo_student_image_disclaimer,
      };
    }

    // TODO: remove
    return {
      url: this.state.studentImage.demo_student_image_url,
      desc: 'Demo Student Image',
      className: 'order-demo-student-image',
      imageDisclaimer: this.state.studentImage.demo_student_image_disclaimer,
    };
  }

  productDoesNotRequirePoseOrBackground(product) {
    return product.background_options_count === 0 && product.poses_count === 0;
  }

  addToByoIdSuffix(num) {
    this.setState({ byoIdSuffix: this.state.byoIdSuffix + num });
  }

  setStudent(student, setById = false) {
    const {
      additionalData: { selectedStudent },
      updateCurrentStudentId,
    } = this.props;

    // console.log('%cSETTING STUDENT...', 'color: lime');
    // console.log(student);
    // console.log(setById);
    // console.log(selectedStudent);

    if (selectedStudent) {
      const firstName = selectedStudent.first_name || selectedStudent.firstName;
      const lastName = selectedStudent.last_name || selectedStudent.lastName;

      updateCurrentStudentId(selectedStudent.id);

      this.setState({
        studentId: selectedStudent.id,
        studentName: `${firstName} ${lastName}`,
      });
    } else if (setById) {
      const studentId = student; // in this case 'student' is sent as an id
      // console.log('SET STUDENT BY ID');
      // console.log(this.state);
      // TODO: update student ID here too
    } else {
      if (!this.state.studentId || !this.state.editingFromCart) {
        const firstName = student.first_name || student.firstName;
        const lastName = student.last_name || student.lastName;

        updateCurrentStudentId(student.id);

        this.setState({
          studentId: student.id,
          studentName: `${firstName} ${lastName}`,
        });
      }
    }
  }

  handleSmsInputChange(smsPhone) {
    this.setState({ smsPhone });
  }

  // price //
  displayPrice(price) {
    return `$${(price / 100)
      .toFixed(2)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
  }

  displayNegativePrice(price) {
    return `-$${((price / 100) * -1)
      .toFixed(2)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
  }

  hasIncludedProducts() {
    // code to handle legacy redux state that doesn't contain includedProducts;
    if (!this.state.includedProducts) return false;

    return this.state.includedProducts.length > 0;
  }

  // total for a student's current state cart items + addons total
  calculateCartItemsAddOnCost(orderState = this.state) {
    return (
      orderState.cartItemsTotalCost +
      orderState.photoEnhancementCost +
      orderState.personalizationCost
    );
  }

  calculateByoPrice(configuration, freeIndices) {
    let totalPrice = 0;

    Object.keys(configuration).forEach((key) => {
      if (configuration[key]?.childItem?.price) {
        totalPrice += configuration[key].childItem.price;
      } else {
        totalPrice += this.BnlByoSheetPrice;
      }
    });

    freeIndices.forEach((index) => {
      if (configuration[index]) {
        if (configuration[index]?.childItem?.price) {
          totalPrice -= configuration[index].childItem.price;
        } else {
          totalPrice -= this.BnlByoSheetPrice;
        }
      }
    });

    return totalPrice;
  }

  // total for the entire students' orders
  calculateTotal() {
    let total = 0;
    let orderState;
    // add cartItemTotals + addon fees of each order
    for (const studentId in this.props.cart.orders) {
      orderState = this.props.cart.orders[studentId];

      total +=
        orderState.cartItemsTotalCost +
        orderState.photoEnhancementCost +
        orderState.personalizationCost;
    }

    total += this.calculateBnlTotalBackgroundPrice();

    return total;
  }

  calculateTotalForIncentive() {
    let total = 0;

    for (const studentId in this.props.cart.orders) {
      // current student's orderState isn't stored in redux yet
      if (studentId != this.state.studentId) {
        const orderState = this.props.cart.orders[studentId];

        // console.log('2_cartItemsTotalCost - in', orderState.cartItemsTotalCost);
        // console.log(
        //   '2_photoEnhancementCost - in',
        //   orderState.photoEnhancementCost,
        // );
        // console.log(
        //   '2_personalizationCost - in',
        //   orderState.personalizationCost,
        // );

        total += orderState.cartItemsTotalCost;
        total += orderState.photoEnhancementCost;
        total += orderState.personalizationCost;
      }
    }

    // console.log('2_cartItemsTotalCost - in', this.state.cartItemsTotalCost);
    // console.log('2_photoEnhancementCost - in', this.state.photoEnhancementCost);
    // console.log('2_personalizationCost - in', this.state.personalizationCost);

    // current student's orderState isn't stored in redux yet
    total += this.state.cartItemsTotalCost;
    total += this.state.photoEnhancementCost;
    total += this.state.personalizationCost;
    total += this.calculateBnlTotalBackgroundPrice();

    return total;
  }

  calculateOrderTotalForIncentive() {
    let total = 0;

    // console.log('2_cartItemsTotalCost - in', this.state.cartItemsTotalCost);
    // console.log('2_photoEnhancementCost - in', this.state.photoEnhancementCost);
    // console.log('2_personalizationCost - in', this.state.personalizationCost);

    total +=
      this.state.cartItemsTotalCost +
      this.state.photoEnhancementCost +
      this.state.personalizationCost +
      this.calculateBnlTotalBackgroundPrice();

    return total;
  }

  calculateTotalTax() {
    const {
      bnlPreShoot: { preShoot },
      shoot: {
        shootPricing: { sales_tax },
        shoot: { company_auth_token },
      },
    } = this.props;

    if (preShoot && company_auth_token !== 'wagner') {
      return 0;
    }

    if (sales_tax && parseInt(sales_tax) === 0) {
      return 0;
    }

    const rawTax =
      (this.calculateTotal() +
        this.calculateTotalDiscount() +
        this.calculateShipping()) *
      (sales_tax / 100);

    return Math.round(rawTax);
  }

  calculateGrandTotal() {
    let total = 0;
    const shippingPrice = this.calculateShipping();

    for (const studentId in this.props.cart.orders) {
      const orderState = this.props.cart.orders[studentId];
      const { cartItemsTotalCost } = orderState;
      const { photoEnhancementCost } = orderState;
      const { personalizationCost } = orderState;
      const discountPercentage = orderState.pricingDiscount;

      total += cartItemsTotalCost + photoEnhancementCost + personalizationCost;
    }

    total +=
      this.calculateBnlTotalBackgroundPrice() +
      this.calculateTotalDiscount() +
      this.calculateTotalTax() +
      shippingPrice;

    return total;
  }

  calculateGeskusDiscount() {
    let discount = 0;
    let qualify;
    let discountStartingIndex;

    if (this.props.shoot.shootPricing.discount_available) {
      const { shootPricing } = this.props.shoot;
      const sortedStudentIds = Object.values(this.props.cart.orders)
        // UNCOMMENT HERE
        // .sort((a, b) => {
        //   return a.createdAt - b.createdAt;
        // })
        // .map(order => {
        //   return order.studentId.toString();
        // });
        .sort((a, b) => a.createdAt - b.createdAt)
        .filter((order) => {
          if (order.cartItems.length > 0) {
            return order.studentId.toString();
          }
        })
        .map((order) => order.studentId.toString());

      const ordersCount = sortedStudentIds.length;
      const previousQualifyingOrdersForDiscount =
        shootPricing.previous_qualifying_orders_for_discount;
      const discountOrdersThreshold = shootPricing.discount_orders_threshold;

      if (previousQualifyingOrdersForDiscount >= discountOrdersThreshold) {
        qualify = true;
        discountStartingIndex = 0;
      } else if (
        previousQualifyingOrdersForDiscount + ordersCount >
        discountOrdersThreshold
      ) {
        qualify = true;
        discountStartingIndex =
          ordersCount -
          (ordersCount - discountOrdersThreshold) -
          previousQualifyingOrdersForDiscount;
      }

      for (const studentId in this.props.cart.orders) {
        // need to ensure the studentId is a string given that we
        // turned ids in sortedStudentIds to strings
        const studentIdString = studentId.toString();

        const orderIndex = sortedStudentIds.indexOf(studentIdString);
        const orderState = this.props.cart.orders[studentId];
        const { photoEnhancementCost } = orderState;
        const { personalizationCost } = orderState;
        const { cartItemsTotalCost } = orderState;
        const { pricingDiscount } = orderState;

        if (qualify && orderIndex >= discountStartingIndex) {
          discount +=
            (pricingDiscount / 100) *
            (cartItemsTotalCost + photoEnhancementCost + personalizationCost);
        }
      }
    }

    return discount === 0 ? discount : -discount;
  }

  calculateBnlDiscount() {
    // if applied discount code is a valid code use discount code
    // this logic is based on the notion that discount code is
    // only available for reorders and reorders will not offer family discount
    // TODO:
    // implement this logic on the backend
    if (this.props.discountCode.valid) {
      return this.calculateBnlDiscountCodeDiscount();
    }
    if (this.props.shoot.shootPricing.discount_balance > 0) {
      return this.calculateBnlDiscountBalanceDiscount();
    }
    return this.calculateBnlFamilyDiscount();
  }

  calculateBnlDiscountCodeDiscount() {
    let discount = 0;
    const { discountCode } = this.props;

    if (discountCode.amountOff > 0) {
      if (discountCode.amountOff >= this.calculateTotal()) {
        discount = this.calculateTotal();
      } else {
        discount = discountCode.amountOff;
      }
    } else if (discountCode.percentOff > 0) {
      discount = this.calculateTotal() * (discountCode.percentOff / 100);
    }

    return discount === 0 ? discount : -Math.floor(discount);
  }

  calculateBnlDiscountBalanceDiscount() {
    let discount = 0;
    const balance = this.props.shoot.shootPricing.discount_balance;

    if (balance >= this.calculateTotal()) {
      discount = this.calculateTotal();
    } else {
      discount = balance;
    }

    return discount === 0 ? discount : -Math.floor(discount);
  }

  calculateBnlFamilyDiscount() {
    let discount = 0;
    let discountStartingIndex;
    let qualifies = false;
    const discountAll = true;

    Sentry.setContext('orders', {
      orders: this.props.orders,
    });

    const { shootPricing } = this.props.shoot;
    const option = shootPricing.discount_option;
    const threshold = shootPricing.discount_orders_threshold;
    const previousQualifyingOrdersForDiscount =
      shootPricing.previous_qualifying_orders_for_discount;
    const sortedStudentIds = Object.values(this.props.cart.orders)
      .sort((a, b) => a.createdAt - b.createdAt)
      .map((order) => order.studentId.toString());

    const ordersCount = sortedStudentIds.length;
    const pricingDiscount = shootPricing.discount_percent;

    if (previousQualifyingOrdersForDiscount >= threshold) {
      qualifies = true;
      discountStartingIndex = 0;
    } else if (previousQualifyingOrdersForDiscount + ordersCount > threshold) {
      qualifies = true;
      discountStartingIndex =
        ordersCount -
        (ordersCount - threshold) -
        previousQualifyingOrdersForDiscount;
    }

    let total = 0;

    if (qualifies) {
      for (const studentId in this.props.cart.orders) {
        const orderIndex = sortedStudentIds.indexOf(studentId);

        if (orderIndex >= discountStartingIndex) {
          const orderState = this.props.cart.orders[studentId];

          orderState.cartItems.forEach((cartItem) => {
            total += cartItem.totalPrice;
          });

          // this is a hack to ensure that the discount is
          // applied appopriately to Geskus V1 UI orders
          // TODO: revisit this
          if (this.companyIsGeskus()) {
            const { photoEnhancementCost } = orderState;
            const { personalizationCost } = orderState;

            total += photoEnhancementCost + personalizationCost;
          }

          total += this.calculateStudentBackgroundPrice(orderState);
        }
      }
    }

    discount = (pricingDiscount / 100) * total;

    return discount === 0 ? discount : -Math.floor(discount);
  }

  // Get the current student from the additionalData prop
  getCurrentStudentFromAdditionalData() {
    const { additionalData } = this.props;

    if (additionalData?.student?.id) {
      return additionalData.student;
    }

    return null;
  }

  calculateStudentBackgroundPrice(orderState) {
    const { studentId } = orderState;
    const currentStudent = this.getCurrentStudentFromAdditionalData();
    let price = 0;
    let trackingStudentId;

    if (this.shootIsBnlPreShoot()) {
      trackingStudentId = studentId;
    } else {
      if (currentStudent) {
        trackingStudentId = currentStudent.id;
      } else {
        trackingStudentId = 'postShoot';
      }
    }

    if (
      this.companyIsBnlCompany() &&
      this.props.shoot.shoot.background_choice_mode !==
        'outdoors_background_only'
    ) {
      const { formattedBackgroundOptions } = this.props.shoot;

      const studentBackgroundSelection =
        this.props.cart.bnlBackgroundSelection[trackingStudentId];

      if (!this.props.cart.bnlFreeBackgrounds[trackingStudentId]) {
        for (const backgroundPrice in studentBackgroundSelection) {
          price += parseInt(backgroundPrice);
        }
      }
    }

    return price;
  }

  calculateCartItemBackgroundPrice(cartItem, orderState) {
    const { bnlBackgroundSelection } = this.props.cart;
    const { bnlFreeBackgrounds } = this.props.cart;
    const {
      additionalData: { isGeskus, isPostEvent, student },
    } = this.props;
    const currentStudent = this.getCurrentStudentFromAdditionalData();

    Sentry.setContext('calculateBackgroundPrice', {
      bnlBackgroundSelection,
      orderState,
    });

    let bgPrice = 0;
    let { studentId } = orderState;
    let configuration;
    let parentProduct = false;

    if (cartItem.product_type === 'parent_product') {
      configuration =
        orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id];
      parentProduct = true;
    } else {
      configuration = orderState.bnlSelectedBackgroundOptions[cartItem.id];
    }

    const uniquePrices = [];
    let trackingStudentId;

    if (this.shootIsBnlPreShoot()) {
      trackingStudentId = studentId;
    } else {
      if (currentStudent) {
        trackingStudentId = currentStudent.id;
      } else {
        trackingStudentId = 'postShoot';
      }
    }

    for (const index in configuration) {
      const innerConfig = configuration[index];
      const bgCode = innerConfig.backgroundCodes[0];
      if (bgCode) {
        const background = this.props.shoot.formattedBackgroundOptions[bgCode];

        // TODO: remove this hack when we can handle multiple background lists
        let backgroundPrice = 0;

        if (background) {
          backgroundPrice = background.price;
        }

        let itemId;

        if (!this.shootIsBnlPreShoot()) {
          if (isGeskus && isPostEvent) {
            studentId = student.id;
          } else {
            studentId = innerConfig.pose.student.id;
          }
        }

        if (parentProduct) {
          itemId = innerConfig.childItem.id;
        } else {
          itemId = cartItem.id;
        }

        if (
          !this.props.shoot.shoot.all_backgrounds_free &&
          !bnlFreeBackgrounds[trackingStudentId] &&
          bgCode &&
          bnlBackgroundSelection[trackingStudentId][backgroundPrice].indexOf(
            itemId,
          ) === 0
        ) {
          if (!uniquePrices.includes(backgroundPrice)) {
            uniquePrices.push(backgroundPrice);
          }
        }
      }
    }

    uniquePrices.forEach((price) => {
      bgPrice += price;
    });

    return bgPrice;
  }

  calculateCartItemDiscount(cartItem, orderState) {
    let discount = 0;
    let discountStartingIndex;
    let qualifies = false;
    const discountAll = true;

    const { shootPricing } = this.props.shoot;
    const option = shootPricing.discount_option;
    const threshold = shootPricing.discount_orders_threshold;
    const previousQualifyingOrdersForDiscount =
      shootPricing.previous_qualifying_orders_for_discount;
    const sortedStudentIds = Object.values(this.props.cart.orders)
      .sort((a, b) => a.createdAt - b.createdAt)
      .map((order) => order.studentId.toString());

    const ordersCount = sortedStudentIds.length;
    const pricingDiscount = shootPricing.discount_percent;

    if (previousQualifyingOrdersForDiscount >= threshold) {
      qualifies = true;
      discountStartingIndex = 0;
    } else if (previousQualifyingOrdersForDiscount + ordersCount > threshold) {
      qualifies = true;
      discountStartingIndex =
        ordersCount -
        (ordersCount - threshold) -
        previousQualifyingOrdersForDiscount;
    }

    const total = 0;

    if (qualifies) {
      const orderIndex = sortedStudentIds.indexOf(
        orderState.studentId.toString(),
      );

      if (orderIndex >= discountStartingIndex) {
        discount =
          (pricingDiscount / 100) *
          (cartItem.totalPrice +
            this.calculateCartItemBackgroundPrice(cartItem, orderState));
      }
    }

    return discount === 0 ? discount : -Math.floor(discount);
  }

  calculateStudentDiscount(orderState) {
    let discount = 0;
    let discountStartingIndex;
    let qualifies = false;
    const discountAll = true;

    const { shootPricing } = this.props.shoot;
    const option = shootPricing.discount_option;
    const threshold = shootPricing.discount_orders_threshold;
    const previousQualifyingOrdersForDiscount =
      shootPricing.previous_qualifying_orders_for_discount;
    const sortedStudentIds = Object.values(this.props.cart.orders)
      .sort((a, b) => a.createdAt - b.createdAt)
      .map((order) => order.studentId.toString());

    const ordersCount = sortedStudentIds.length;
    const pricingDiscount = shootPricing.discount_percent;

    if (previousQualifyingOrdersForDiscount >= threshold) {
      qualifies = true;
      discountStartingIndex = 0;
    } else if (previousQualifyingOrdersForDiscount + ordersCount > threshold) {
      qualifies = true;
      discountStartingIndex =
        ordersCount -
        (ordersCount - threshold) -
        previousQualifyingOrdersForDiscount;
    }

    let total = 0;

    if (qualifies) {
      const orderIndex = sortedStudentIds.indexOf(
        orderState.studentId.toString(),
      );

      if (orderIndex >= discountStartingIndex) {
        orderState.cartItems.forEach((cartItem) => {
          total += cartItem.totalPrice;
        });

        discount =
          (pricingDiscount / 100) *
          (total + this.calculateStudentBackgroundPrice(orderState));
      }
    }

    return discount === 0 ? discount : -Math.floor(discount);
  }

  // TODO: revist
  // 1. ensure family discount is working as intended
  // 2. utilize same check for all studios
  calculateTotalDiscount() {
    if (this.companyIsGeskus()) {
      // if (this.props.shoot.shoot.ui_version === 'ui_v1') {
      //   return this.calculateGeskusDiscount();
      // }

      return this.calculateBnlDiscount();
    }

    return this.calculateBnlDiscount();
  }

  calculatePersonalizationCost() {
    if (this.props.shoot.personalization && this.state.personalizationApplied) {
      return this.props.shoot.personalization.price;
    }

    return 0;
  }

  calculatePhotoEnhancementCost() {
    let cost = 0;
    this.props.shoot.photoEnhancements.forEach((photoEnhancement) => {
      if (this.state.photoEnhancement === photoEnhancement.id) {
        cost = photoEnhancement.price;
      }
    });

    return cost;
  }

  calculateShipping() {
    let requiresShipping = false;

    if (
      this.props.shoot.shootPricing.free_shipping_available &&
      this.calculateTotal() >
        (this.state.freeShippingThreshold ||
          this.props.shoot.shootPricing.free_shipping_threshold)
    ) {
      return 0;
    }
    if (
      this.props.geskusIncentive.freeShipping ||
      this.props.bnlIncentive.freeShipping ||
      this.props.discountCode.isFreeShipping
    ) {
      return 0;
    }
    // TODO:
    // uncomment this later

    // else if (
    //   this.companyIsBnlCompany() && this.props.cart.bnlShipping === 'school'
    // ) {
    //   return 0;
    // }

    let orderState;
    const cartStudentIds = Object.keys(this.props.cart.orders);

    cartStudentIds.forEach((studentId) => {
      orderState = this.props.cart.orders[studentId];

      orderState.cartItems.forEach((item) => {
        if (item.product_type !== 'parent_product' && item.requires_shipping) {
          requiresShipping = true;
        } else {
          orderState.buildYourOwnCartItems.forEach((childItem) => {
            if (childItem.requires_shipping) {
              requiresShipping = true;
            }
          });
        }
      });

      if (orderState.incentiveCartItems) {
        orderState.incentiveCartItems.forEach((item) => {
          if (
            item.product_type !== 'parent_product' &&
            item.requires_shipping
          ) {
            requiresShipping = true;
          }
        });
      }
    });

    // TODO: add Geskus UI V2 here? -- or can we remove this block enitrely?
    if (this.companyIsBnlCompany()) {
      const incentiveItems = this.props.bnlIncentive.cartItems.concat(
        this.props.bnlIncentive.cartLevelItems,
      );
      incentiveItems.forEach((item) => {
        if (item.requires_shipping) {
          requiresShipping = true;
        }
      });
    }

    // TODO: find a better logic for this
    // grabbing last order's companyShippingcost for now
    let shippingCost = 0;

    if (requiresShipping) {
      // if (this.props.shoot.shoot.company_auth_token === GESKUS) {
      //   shippingCost = orderState.companyShippingCost;
      // } else {
      //   shippingCost = this.props.shoot.shootPricing.shipping_price;
      // }

      shippingCost = this.props.shoot.shootPricing.shipping_price;
    }

    if (this.props.shoot.shootPricing.discount_balance > 0) {
      shippingCost = 0;
    }

    return shippingCost;
  }

  displayShippingPrice() {
    const shippingPrice = this.calculateShipping();

    if (
      shippingPrice === 0 &&
      this.props.shoot.shoot.company_auth_token !== GB
    ) {
      return 'FREE';
    }
    return this.displayPrice(shippingPrice);
  }

  calculateBnlTotalBackgroundPrice() {
    let price = 0;

    if (
      this.companyIsBnlCompany() &&
      this.props.shoot.shoot.background_choice_mode !==
        'outdoors_background_only'
    ) {
      const { formattedBackgroundOptions } = this.props.shoot;

      for (const studentId in this.props.cart.bnlBackgroundSelection) {
        const studentBackgroundSelection =
          this.props.cart.bnlBackgroundSelection[studentId];
        if (!this.props.cart.bnlFreeBackgrounds[studentId]) {
          for (const backgroundPrice in studentBackgroundSelection) {
            price += parseInt(backgroundPrice);
          }
        }
      }
    }

    return price;
  }

  calculateCartItemsTotalCost(orderState = this.state) {
    let totalCost = 0;

    console.log('4_cart', orderState.cartItems);

    orderState.cartItems.forEach((item) => {
      totalCost += item.totalPrice;
    });

    return totalCost;
  }
  // price end //

  submitSmsPhone(callback = null) {
    const that = this;

    if (this.state.smsPhone.trim().length === 0) {
      Swal.fire('Please fill in a phone number');
    } else {
      const smsPhone = this.formattedPhoneNumber();
      Swal.fire({
        title: `Please confirm ${smsPhone} is the mobile number where we should send your SMS-based products`,
        confirmButtonText: 'OK',
        showCancelButton: true,
        reverseButtons: true,
        focusConfirm: true,
        cancelButtonText: 'Edit',
      }).then((res) => {
        if (!res.value) {
          // do nothing
        } else {
          const data = { sms_phone: that.state.smsPhone };
          const id = that.props.auth.currentUserId;

          that.storeStudentOrder(that.state.studentId, that.state);
          that.setState(
            {
              smsErrorMessage: '',
              loading: true,
            },
            () => {
              axios
                .patch(`/api/v2/parents/${id}`, data)
                .then((response) => {
                  that.props.setSmsPhone(response.data.sms_phone);
                  that.setState(
                    {
                      loading: false,
                    },
                    () => {
                      if (callback) {
                        callback.call();
                      }

                      that.props.acquireSms();
                    },
                  );
                })
                .catch((error) => {
                  if (error.response) {
                    that.setState({
                      smsErrorMessage: error.response.data.errors[0],
                      loading: false,
                    });
                  }
                });
            },
          );
        }
      });
    }
  }

  // dashboard functions//
  submitShootKeyForm(event) {
    event.preventDefault();
    if (!this.state.submitting) {
      const that = this;
      const data = {
        student_id: this.state.studentId,
        shoot_key: this.state.shootKey,
        grade_code: this.state.gradeCode,
      };

      this.setState(
        {
          errors: {},
          loading: true,
        },
        () => {
          this.props.getShootData(data).then((response) => {
            if (response.payload.data) {
              if (response.payload.data.errors) {
                that.setState({
                  errors: response.payload.data.errors,
                  loading: false,
                });
              } else {
                const { data } = response.payload;

                that.setState(
                  {
                    backgroundChoiceMode: data.shoot.background_choice_mode,
                    companyShippingCost: data.shoot_pricing.shipping_price,
                    discountOrdersThreshold:
                      data.shoot_pricing.discount_orders_threshold,
                    freeShippingThreshold:
                      data.shoot_pricing.free_shipping_threshold,
                    incentiveProducts: data.incentive_products,
                    previousQualifyingOrdersForDiscount:
                      data.shoot_pricing
                        .previous_qualifying_orders_for_discount,
                    pricingDiscount: data.shoot_pricing.discount_percent,
                    shippingAppliedToCart:
                      data.shoot_pricing.shipping_applied_to_cart,
                    shippingAppliedToOrder:
                      data.shoot_pricing.shipping_applied_to_order,
                    shootKey: data.shoot.key,
                    shootName: data.shoot.name,
                    studentImage: data.student_image,
                    taxPercent: data.shoot_pricing.sales_tax,
                    shoot: data.shoot,
                    studentPhotoPreviewUrl:
                      data.student_photo_preview_url || '',
                    loading: false,
                  },
                  () => {
                    that.storeStudentOrder(this.state.studentId, this.state);
                    that.formatBackgroundOptions(data.background_options);
                    this.formatIncentiveProducts(data.incentive_products);
                    that.renderNextComponent();
                  },
                );
              }
            }
          });
        },
      );
    }
  }

  confirmShoot() {
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
  }

  addStudent() {
    this.state.activeComponent = 1;
    this.storeStudentOrder(this.props.parent.selectedStudentId, this.state);
    this.props.history.push('/dashboard');
  }

  async shouldOfferRetouchingOrInterstitial() {
    console.log('shouldOfferRetouchingOrInterstitial');
    console.log(this.props);
    console.log('xxx');

    const {
      additionalData: { selectedStudent },
      cart: { bnlPromptRetouching, promptInterstitial },
      order: { currentStudentId },
      shoot: { interstitialProducts, retouchingProduct },
      updateBnlPromptRetouching,
    } = this.props;

    let studentId;

    if (!retouchingProduct) {
      return Promise.resolve(false);
    }

    // TODO: revist this logic to:
    // 1. remove studentId = 'postShoot'
    // 2. use currentStudentId once we've moved away from storing in the local state
    if (this.props.bnlPreShoot.preShoot) {
      studentId = this.state.studentId;

      if (studentId === undefined && currentStudentId) {
        studentId = currentStudentId;
      }
    } else {
      if (currentStudentId) {
        studentId = currentStudentId;
      } else {
        // TODO: can we remove this?
        studentId = 'postShoot';
      }
    }

    console.log(
      `RUNNING shouldOfferRetouchingOrInterstitial() for student: ${studentId}`,
    );
    console.log(
      `bnlPromptRetouching[studentId]: ${bnlPromptRetouching[studentId]}`,
    );
    console.log('interstitialProducts:', interstitialProducts);

    // In the current flow, the retouching prompt should only be shown once per student,
    // regardless of whether the parent has selected or declined retoching. If the
    // bnlPromptRetouching object has a key for the studentId, then the prompt has already been shown
    // and further retouching offers should be skipped.
    // This is a hack and should be fixed in the future.
    if (bnlPromptRetouching[studentId] === undefined) {
      console.log(
        'bnlPromptRetouching[studentId] === undefined ... render retouching',
      );
      return Promise.resolve(true);

      // Set the bnlPromptRetouching object to false for current student ID
      // to indicate that the prompt has been shown
      // bnlPromptRetouching[studentId] = false;
      // updateBnlPromptRetouching(bnlPromptRetouching);
    } else if (
      interstitialProducts.length > 0 &&
      promptInterstitial[studentId] === undefined
    ) {
      console.log(
        'bnlPromptRetouching[studentId] !== undefined ... look for interstitial render',
      );
      // At current, we're only supporting one interstitial product
      // TODO: revisit if/when supporting multiple interstitial products
      const interstitialSku = interstitialProducts[0].sku;

      const orders = this.props?.cart?.orders;
      let interstitialSkuAlreadyInCart = false;

      console.log(`interstitialSku: ${interstitialSku}`);
      console.log(`currentStudentId: ${currentStudentId}`);
      console.log(`orders: ${orders}`);
      console.log(orders[`${currentStudentId}`]);

      // Ensure that the interstitial product is not already in the cart
      if (orders && orders[`${studentId}`]) {
        orders[`${studentId}`]?.cartItems?.forEach((item) => {
          console.log(item.sku);
          console.log(interstitialSku);
          if (item.sku === interstitialSku) {
            interstitialSkuAlreadyInCart = true;
            console.log(
              'interstitialSkuAlreadyInCart for studentId: ',
              studentId,
            );
          }
        });

        if (!interstitialSkuAlreadyInCart) {
          console.log('WE ARE HERE 2.1');
          return Promise.resolve(true);
        }
      }
    }

    return Promise.resolve(false);
  }

  async promptRetouchingOrInterstitial() {
    const {
      additionalData: { selectedStudent },
      cart: { bnlPromptRetouching, orders, promptInterstitial },
      order: { currentStudentId },
      shoot: { interstitialProducts, retouchingProduct },
      updateBnlPromptRetouching,
    } = this.props;
    let studentId;

    // console.log(
    //   '%c promptRetouchingOrInterstitial',
    //   'color: #00ff00;',
    // );

    if (!retouchingProduct) {
      return;
    }

    // TODO: revist this logic to:
    // 1. remove studentId = 'postShoot'
    // 2. use currentStudentId once we've moved away from storing in the local state
    if (this.props.bnlPreShoot.preShoot) {
      studentId = this.state.studentId;

      if (studentId === undefined && currentStudentId) {
        studentId = currentStudentId;
      }
    } else {
      if (currentStudentId) {
        studentId = currentStudentId;
      } else {
        // TODO: can we remove this?
        studentId = 'postShoot';
      }
    }

    console.log(`RUNNING ROI CHECK for student: ${studentId}`);
    console.log(
      `bnlPromptRetouching[studentId]: ${bnlPromptRetouching[studentId]}`,
    );
    console.log('interstitialProducts:', interstitialProducts);

    // In the current flow, the retouching prompt should only be shown once per student,
    // regardless of whether the parent has selected or declined retoching. If the
    // bnlPromptRetouching object has a key for the studentId, then the prompt has already been shown
    // and further retouching offers should be skipped.
    // This is a hack and should be fixed in the future.
    if (bnlPromptRetouching[studentId] === undefined) {
      console.log(
        'bnlPromptRetouching[studentId] === undefined ... render retouching',
      );
      this.selectShootProduct(retouchingProduct, true);

      // Set the bnlPromptRetouching object to false for current student ID
      // to indicate that the prompt has been shown
      // bnlPromptRetouching[studentId] = false;
      // updateBnlPromptRetouching(bnlPromptRetouching);
    } else if (
      interstitialProducts.length > 0 &&
      promptInterstitial[studentId] === undefined
    ) {
      console.log(
        'bnlPromptRetouching[studentId] !== undefined ... look for interstitial render',
      );
      // At current, we're only supporting one interstitial product
      // TODO: revisit if/when supporting multiple interstitial products
      const interstitialSku = interstitialProducts[0].sku;

      // const orders = this.props?.cart?.orders;
      let interstitialSkuAlreadyInCart = false;

      console.log(`interstitialSku: ${interstitialSku}`);
      console.log(`currentStudentId: ${currentStudentId}`);
      console.log(`orders: ${orders}`);
      console.log(orders[`${currentStudentId}`]);

      // Ensure that the interstitial product is not already in the cart
      if (orders && orders[`${studentId}`]) {
        orders[`${studentId}`]?.cartItems?.forEach((item) => {
          console.log(item.sku);
          console.log(interstitialSku);
          if (item.sku === interstitialSku) {
            interstitialSkuAlreadyInCart = true;
            console.log(
              'interstitialSkuAlreadyInCart for studentId: ',
              studentId,
            );
          }
        });

        if (!interstitialSkuAlreadyInCart) {
          console.log('WE ARE IN HERE 2');
          this.selectShootProduct(interstitialProducts[0], true);
        }
      }
    } else {
      console.log('%cCHEKCING INCENTIVE PRODUCTS -- 1904', 'color: cyan;');

      if (orders && orders[`${studentId}`]) {
        let requiresIncentiveConfiguration = false;
        const { incentiveConfiguration } = orders[`${studentId}`];

        orders[`${studentId}`]?.incentiveCartItems?.forEach((item) => {
          console.log('item');
          console.log(item);
          if (
            !item.is_free_shipping &&
            incentiveConfiguration &&
            incentiveConfiguration[item.id] &&
            incentiveConfiguration[item.id].configured === false
          ) {
            requiresIncentiveConfiguration = true;
          }
        });

        console.log(requiresIncentiveConfiguration);

        if (requiresIncentiveConfiguration) {
          console.log(
            '%cORDER_NAVIGATION.INCENTIVE_CONFIGURATION - 4',
            'color: pink',
          );
          this.props.updateOrderActiveComponent(
            ORDER_NAVIGATION.INCENTIVE_CONFIGURATION,
          );
        }
      }
    }
  }

  // background //
  formatBackgroundOptions(options) {
    const backgroundOptions = this.props.shoot.formattedBackgroundOptions;
    options.forEach((option) => {
      backgroundOptions[option.id] = option;
    });

    this.props.formatAndSetBackgroundOptions(backgroundOptions);
  }

  backgroundOptionSelected(code, configuringBackground) {
    const backgroundCodes = this.state.configuration[configuringBackground];

    return backgroundCodes.indexOf(code) > -1;
  }

  youPickXBackgroundOptionSelected(code, item) {
    const { backgroundCodes } = this.state.youPickXConfiguration[item.id];

    return backgroundCodes.indexOf(code) > -1;
  }

  allBackgroundOptionsSelected() {
    const shootProduct = this.state.selectedShootProduct;
    const primary = this.state.configuration.backgroundCodes;
    const additional = this.state.configuration.additionalBackgroundCodes;
    let allSelected = true;

    // !primary, !additional = no option is selected
    if (shootProduct.background_options_count > 0) {
      if (
        !primary ||
        primary.length !== shootProduct.background_options_count
      ) {
        allSelected = false;
      }
    }

    if (shootProduct.additional_product_background_images_count > 0) {
      if (
        !additional ||
        additional.length !==
          shootProduct.additional_product_background_images_count
      ) {
        allSelected = false;
      }
    }

    return allSelected;
  }

  configureItem(itemId, configuringBackground, bgOption) {
    const configuration = this.cloneObject(this.state.configuration);
    let backgroundCodes = configuration[configuringBackground];
    let backgroundPrices = configuration[`${configuringBackground}Prices`];
    const code = bgOption.id;
    const { price } = bgOption;

    // deselecting background - only allowed for items with count > 1
    if (
      this.state.selectedShootProduct.background_options_count > 1 &&
      backgroundCodes.indexOf(code) > -1
    ) {
      backgroundCodes = this.removeElementFromArray(backgroundCodes, code);
      backgroundPrices = this.removeElementFromArray(
        backgroundPrices,
        price,
        backgroundCodes.indexOf(code),
      );
      // selecting background more than maximum count
    } else if (
      backgroundCodes.length ===
      this.state.selectedShootProduct.background_options_count
    ) {
      backgroundCodes.shift();
      backgroundCodes = this.addElementToArray(backgroundCodes, code);
      backgroundPrices.shift();
      backgroundPrices = this.addElementToArray(backgroundPrices, price);
    } else {
      backgroundCodes = this.addElementToArray(backgroundCodes, code);
      backgroundPrices = this.addElementToArray(backgroundPrices, price);
    }

    configuration.itemId = itemId;
    configuration[configuringBackground] = backgroundCodes;
    configuration[`${configuringBackground}Prices`] = backgroundPrices;

    this.setState({ configuration }, () => {
      this.setState({
        allBackgroundOptionsSelected: this.allBackgroundOptionsSelected(),
      });
    });
  }

  configureYouPickXItem(item, bgOption = null) {
    const configuration = this.cloneObject(this.state.youPickXConfiguration);

    if (!configuration[item.id]) {
      configuration[item.id] = {};
    }

    if (!bgOption) {
      const { formattedBackgroundOptions } = this.props.shoot;
      bgOption =
        formattedBackgroundOptions[Object.keys(formattedBackgroundOptions)[0]];
    }

    const itemConfiguration = configuration[item.id];

    itemConfiguration.parentId = this.state.selectedShootProduct.id;
    itemConfiguration.childItem = item;

    if (
      this.state.backgroundChoiceMode ===
      'advanced_greenscreen_backgrounds_only'
    ) {
      itemConfiguration.backgroundCodes = [bgOption.id];
    }

    this.setState({
      idSuffix: this.state.idSuffix + 1,
      youPickXConfiguration: configuration,
    });
  }

  configureIncentiveItem(code, orderState = this.state) {
    const incentiveItem = orderState.incentiveCartItems[0];
    const configuration = {};

    configuration[incentiveItem.id] = { backgroundCodes: [parseInt(code)] };
    orderState.incentiveItemConfiguration = configuration;

    this.setState(orderState, () => {
      this.storeStudentOrder(orderState.studentId, orderState);
    });
  }

  configureBnlIncentiveItem(configuration, studentId, unmounting = false) {
    const {
      cart: { bnlPromptRetouching, bnlRetouching },
    } = this.props;

    console.log('c%configureBnlIncentiveItem', 'color: cyan');
    console.log('c%configuration', 'color: cyan', configuration);

    const clonedConfig = this.cloneObject(configuration);
    let cartItems;

    // console.log('clonedConfig');
    // console.log(clonedConfig);

    if (this.shootIsBnlPreShoot()) {
      cartItems = this.state.incentiveCartItems;

      cartItems.forEach((cartItem, i) => {
        if (!unmounting && clonedConfig[cartItem.id]) {
          clonedConfig[cartItem.id].configured = true;
        }
      });
    } else {
      cartItems = this.state.incentiveCartItems;

      // console.log('post-shoot bnlIncentive config');
      // console.log(this.props.bnlIncentive);

      cartItems.forEach((cartItem, i) => {
        if (!unmounting && clonedConfig[cartItem.id]) {
          clonedConfig[cartItem.id].configured = true;
        }
      });

      // this.props.updateBnlIncentiveCartItems(cartItems);
      // this.props.updateBnlIncentiveConfiguration(clonedConfig);

      // TODO: review cases where this functionality might still be needed?
      // cartItems = this.cloneArray(this.props.bnlIncentive.cartItems);

      this.props.updateBnlIncentiveCartItems(cartItems);
      this.props.updateBnlIncentiveConfiguration(clonedConfig);
    }

    // console.log('CART ITEMS AFTER ADDING INCENTIVE CONFIG');
    // console.log(cartItems);

    let nextComponent;
    if (this.state.editingFromCart) {
      this.setState(
        {
          editingFromCart: false,
          incentiveCartItems: cartItems,
          incentiveConfiguration: configuration,
        },
        () => {
          // console.log('%cTROUBLESHOOT - Nav 9', 'color: red');
          nextComponent = ORDER_NAVIGATION.BNL_CART;
          this.props.updateOrderActiveComponent(nextComponent);
          this.storeStudentOrder(this.state.studentId, this.state);
          this.toggleNotification('Bonus Item Edit Saved');
        },
      );
    } else if (this.state.configuringFromCart) {
      // console.log('%cTROUBLESHOOT - Nav 10', 'color: red');

      nextComponent = ORDER_NAVIGATION.BNL_CART;
      this.setState(
        {
          configuringFromCart: false,
          incentiveCartItems: cartItems,
          incentiveConfiguration: configuration,
        },
        () => {
          this.props.updateOrderActiveComponent(nextComponent);
          this.storeStudentOrder(this.state.studentId, this.state);
          this.toggleNotification('Bonus Item Configured');
        },
      );
    } else if (unmounting) {
      console.error('is unmounting');
      console.log(this.props.order.shouldBeOfferedRetouching);
      console.log(this.props.order.hasOfferedRetouching);
      this.setState(
        {
          incentiveCartItems: cartItems,
          incentiveConfiguration: configuration,
        },
        () => {
          // console.log('current state is unmounting');
          // console.log(this.state);
          this.storeStudentOrder(this.state.studentId, this.state);
          this.toggleNotification('Bonus Item Configured');

          // TODO: revisit
          // if (
          //   this.props.order.shouldBeOfferedRetouching &&
          //   !this.props.order.hasOfferedRetouching
          // ) {
          //   this.props.orderHasOfferedRetouching();
          //   this.promptRetouchingOrInterstitial();
          //   // TODO: return?
          // }

          // TODO: revisit
          // Prompt retouching for regardless
          console.log('%cPROMPT ROI 3.1', 'color: orange');
          this.promptRetouchingOrInterstitial();
        },
      );
    } else {
      this.setState(
        {
          incentiveCartItems: cartItems,
          incentiveConfiguration: configuration,
        },
        () => {
          nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;

          // console.log('%c DETERMINING RETOUCHING PROMPT', 'color: cyan');
          // console.log('%cOFFERS RETOUCHING CHECK 3', 'color: cyan');

          // determine whether retouching should be offered
          // TODO: revist... assume that a product should always check for retouching post-config
          if (
            (this.props.order.shouldBeOfferedRetouching &&
              !this.props.order.hasOfferedRetouching) ||
            !this.props.order.hasOfferedRetouching
          ) {
            this.props.orderHasOfferedRetouching();
            console.log('%cPROMPT ROI 4', 'color: orange');
            this.promptRetouchingOrInterstitial();
            // TODO: return?
          } else if (
            this.state.recommendProducts &&
            this.props.shoot.suggestedProductsLinkToIndex
          ) {
            // console.log('%cTROUBLESHOOT III - Nav 4', 'color: red');
            nextComponent = ORDER_NAVIGATION.PRODUCT_RECS;
          }

          this.toggleNotification('Bonus Items Configured');
          this.props.updateOrderActiveComponent(nextComponent);

          this.storeStudentOrder(this.state.studentId, this.state);
        },
      );
    }
  }

  configureAdditionalSelectedBackgroundOptions(option) {
    const options = this.cloneObject(
      this.state.additionalSelectedBackgroundOptions,
    );
    options[this.state.selectedShootProduct.id].codes = [option.id];
    options[this.state.selectedShootProduct.id].prices = [option.price];

    this.setState({ additionalSelectedBackgroundOptions: options });
  }

  _getItemBackgroundCodes(orderState, item) {
    if (
      orderState.selectedBackgroundOptions[item.id] &&
      orderState.selectedBackgroundOptions[item.id].codes
    ) {
      return orderState.selectedBackgroundOptions[item.id].codes;
    }
    return [];
  }

  _getItemAdditionalBackgroundCodes(orderState, item) {
    if (
      orderState.additionalSelectedBackgroundOptions[item.id] &&
      orderState.additionalSelectedBackgroundOptions[item.id].codes
    ) {
      return orderState.additionalSelectedBackgroundOptions[item.id].codes;
    }
    return [];
  }

  setProductConfiguration(orderState, cartItem) {
    const configuration = this.cloneObject(orderState.configuration);
    const selectedBackgroundOptions = this.cloneObject(
      orderState.selectedBackgroundOptions,
    );
    const additionalSelectedBackgroundOptions = this.cloneObject(
      orderState.additionalSelectedBackgroundOptions,
    );

    if (selectedBackgroundOptions[cartItem.id]) {
      configuration.itemId = cartItem.id;
      configuration.backgroundCodes =
        selectedBackgroundOptions[cartItem.id].codes;
      configuration.backgroundCodesPrices =
        selectedBackgroundOptions[cartItem.id].prices;
    }

    if (additionalSelectedBackgroundOptions[cartItem.id]) {
      configuration.itemId = cartItem.id; // prob don't need this but adding for safety for now
      configuration.additionalBackgroundCodes =
        additionalSelectedBackgroundOptions[cartItem.id].codes;
      configuration.additionalBackgroundCodesPrices =
        additionalSelectedBackgroundOptions[cartItem.id].prices;
    }

    return configuration;
  }

  setParentProductConfiguration(orderState, cartItem) {
    let configuration = this.cloneObject(orderState.youPickXConfiguration);
    const shootProductId = orderState.selectedShootProduct.id;
    const selectedBackgroundOptions = this.cloneObject(
      orderState.youPickXSelectedBackgroundOptions,
    )[shootProductId];

    configuration = {};

    for (const childItemId in selectedBackgroundOptions) {
      const childItemData = selectedBackgroundOptions[childItemId];

      configuration[childItemId] = {};
      const childItemConfiguration = configuration[childItemId];

      childItemConfiguration.parentId = shootProductId;
      childItemConfiguration.childItemId = childItemId;
      childItemConfiguration.childItem = childItemData.childItem;
      childItemConfiguration.backgroundCodes = childItemData.backgroundCodes;
    }

    return configuration;
  }

  setBnlProductConfiguration(orderState, cartItem) {
    let options;
    if (cartItem.product_type === 'parent_product') {
      options = this.deepCloneObject(
        orderState.buildYourOwnSelectedBackgroundOptions,
      );
    } else {
      options = this.deepCloneObject(orderState.bnlSelectedBackgroundOptions);
    }

    return options[cartItem.id];
  }

  editBackground(cartItem, studentId) {
    const orderState = this.props.cart.orders[studentId];
    orderState.selectedShootProduct = cartItem;

    let nextComponent;

    if (
      this.props.shoot.shoot.company_auth_token === 'GESKUS' &&
      this.props.shoot.shoot.ui_version === 'ui_v2'
    ) {
      orderState.configuration = this.setProductConfiguration(
        orderState,
        cartItem,
      );
      nextComponent = ORDER_NAVIGATION.PRODUCT_CONFIGURATION;
    } else {
      orderState.bnlEditConfiguration = this.setBnlProductConfiguration(
        orderState,
        cartItem,
      );
      if (cartItem.product_type === 'parent_product') {
        nextComponent = ORDER_NAVIGATION.BUILD_YOUR_OWN;
      } else if (cartItem.product_type === 'package_product') {
        nextComponent = ORDER_NAVIGATION.BNL_PACKAGE_CONFIGURATION;
      } else {
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_CONFIGURATION;
      }
    }

    orderState.editingFromCart = true;
    this.setState(orderState, () => {
      if (this.shootIsBnlPreShoot()) {
        this.props.parent.students.forEach((student) => {
          if (student.id === studentId) {
            this.props.setBnlPreShootStudent(student);
          }
        });
      }
      this.props.selectShootProduct(cartItem);
      this.storeStudentOrder(studentId, orderState);
      this.props.updateOrderActiveComponent(nextComponent);
    });
  }

  editYouPickXBackgrounds(cartItem, studentId) {
    const orderState = this.props.cart.orders[studentId];
    orderState.selectedShootProduct = cartItem;
    orderState.youPickXConfiguration = this.setParentProductConfiguration(
      orderState,
      cartItem,
    );
    orderState.youPickXConfiguring = true;
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.YOU_PICK_X);

    this.setState(orderState);
  }

  editBnlIncentiveBackground(incentiveCartItem, orderState) {
    let configuration;

    // TODO:
    // test preshoot incentive edit
    if (this.shootIsBnlPreShoot()) {
      configuration = orderState.incentiveConfiguration;
      configuration[incentiveCartItem.id].configured = false;
      orderState.editingFromCart = true;

      this.setState(orderState, () => {
        console.log(
          '%cORDER_NAVIGATION.INCENTIVE_CONFIGURATION - 5',
          'color: pink;',
        );
        this.props.updateOrderActiveComponent(
          ORDER_NAVIGATION.INCENTIVE_CONFIGURATION,
        );
      });
    } else {
      const { bnlIncentive } = this.props;
      configuration = this.cloneObject(bnlIncentive.configuration);
      configuration[incentiveCartItem.id].configured = false;
      console.log('%cUPDATE BNL INCENTIVE CONFIGURATION 1', 'color: pink');
      this.props.updateBnlIncentiveConfiguration(configuration);

      this.setState({ editingFromCart: true }, () => {
        console.log(
          '%cORDER_NAVIGATION.INCENTIVE_CONFIGURATION - 6',
          'color: pink;',
        );
        this.props.updateOrderActiveComponent(
          ORDER_NAVIGATION.INCENTIVE_CONFIGURATION,
        );
      });
    }

    // orderState.editingFromCart = true;

    // this.setState({ editingFromCart: true }, () => {
    //   this.props.updateOrderActiveComponent(
    //     ORDER_NAVIGATION['INCENTIVE_CONFIGURATION']
    //   );
    // });
  }

  resetSelectedBackgroundOptions() {
    this.setState({
      selectedBackgroundOptions: {},
      youPickXSelectedBackgroundOptions: {},
    });
  }

  resetBnlProductConfiguration() {
    // resetting product config
    // TODO: fix needed
    this.setState({ bnlEditConfiguration: null }, () => {
      // why did i need this before???
      // this.storeStudentOrder(this.state.studentId, this.state);
      this.props.selectShootProduct(null);
    });
  }
  // background end //

  // order modification //
  selectPose(pose) {
    this.setState({ selectedPose: pose }, () => {
      this.storeStudentOrder(this.state.studentId, this.state);

      let nextComponent;

      if (this.state.selectedShootProduct.product_type === 'parent_product') {
        nextComponent = ORDER_NAVIGATION.BUILD_YOUR_OWN;
      } else {
        nextComponent = ORDER_NAVIGATION.PRODUCT_CONFIGURATION;
      }

      this.props.updateOrderActiveComponent(nextComponent);
    });
  }

  selectShootProduct(product, autoSelected = false) {
    const companyAuthToken = this.props.shoot.shoot.company_auth_token;
    const modifiedProduct = this.cloneObject(product);

    if (
      this.companyIsBnlCompany() &&
      modifiedProduct.lock_type === 'unlockable' &&
      !this.checkBnlProductRequirement(modifiedProduct)
    ) {
      return;
    }

    if (this.shootIsBnlPreShoot() && product.is_retouching) {
      const student = this.props.bnlPreShoot.selectedStudent;

      if (this.props.cart.bnlRetouching[student.id] !== undefined) {
        if (!autoSelected) {
          Swal.fire(
            'Retouching is already in your cart!',
            `Retouching will be applied to all of ${student.firstName}'s images`,
          );
        }

        return;
      }
    }

    // only use current state's idSuffix for geskus and bnl preshoot
    if (
      this.shootIsGeskusShoot() ||
      this.shootIsBnlPreShoot()
      // ||
      // product.product_type === 'parent_product'
    ) {
      modifiedProduct.id = `${modifiedProduct.id}_${this.state.idSuffix}`;
    }

    modifiedProduct.count = this.state.quantity;

    if (product && product.product_type === 'parent_product') {
      this.setState({ selectedShootProduct: modifiedProduct }, () => {
        this.storeStudentOrder(this.state.studentId, this.state);
        let nextComponent;

        switch (companyAuthToken) {
          case GESKUS:
            if (this.props.shoot.shoot.ui_version === 'ui_v1') {
              // TODO: do we need to set this here?
              this.props.selectShootProduct(modifiedProduct);
              nextComponent = ORDER_NAVIGATION.YOU_PICK_X;
            } else {
              this.props.selectShootProduct(modifiedProduct);
              nextComponent = ORDER_NAVIGATION.BUILD_YOUR_OWN;
            }
            break;
          case BNL:
          case JELLYJAR:
          case GB:
          case NORMANDY:
          case TIPPING:
          case MARCEL:
          case CLASSACT:
          case WAGNER:
          case BIELMAR:
            this.props.selectShootProduct(modifiedProduct);
            nextComponent = ORDER_NAVIGATION.BUILD_YOUR_OWN;
            break;
          default:
            this.props.selectShootProduct(modifiedProduct);
            nextComponent = ORDER_NAVIGATION.BUILD_YOUR_OWN;
            break;
        }

        this.props.updateOrderActiveComponent(nextComponent);
      });
    } else if (
      product &&
      product.product_type === 'package_product' &&
      company === BNL
    ) {
      this.setState({ selectedShootProduct: modifiedProduct }, () => {
        this.props.selectShootProduct(modifiedProduct);
        this.props.updateOrderActiveComponent(
          ORDER_NAVIGATION.BNL_PACKAGE_CONFIGURATION,
        );
      });
    } else {
      const configuration = this.cloneObject(this.state.configuration);
      configuration.itemId = modifiedProduct.id;

      // set default bg configuration if bg count is 1
      if (
        this.state.backgroundChoiceMode ===
          'advanced_greenscreen_backgrounds_only' &&
        modifiedProduct.background_options_count === 1
      ) {
        configuration.backgroundCodes = [
          this.props.shoot.backgroundOptions[0].id,
        ];
      } else {
        configuration.backgroundCodes = [];
      }
      this.setState(
        {
          configuration,
          selectedShootProduct: modifiedProduct,
        },
        () => {
          let studentId;
          let nextComponent;

          switch (companyAuthToken) {
            // Move V1 Geskus UI to V2 Pics UI
            case GESKUS:
              if (this.props.shoot.shoot.ui_version === 'ui_v1') {
                nextComponent = ORDER_NAVIGATION.PRODUCT_CONFIGURATION;
              } else {
                nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_CONFIGURATION;
              }
              break;
            case GESKUS:
            case BNL:
            case JELLYJAR:
            case GB:
            case NORMANDY:
            case TIPPING:
            case MARCEL:
            case CLASSACT:
            case WAGNER:
            case BIELMAR:
              nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_CONFIGURATION;
              break;
            default:
              nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_CONFIGURATION;
          }

          this.setState(
            {
              allBackgroundOptionsSelected: this.allBackgroundOptionsSelected(),
            },
            () => {
              // console.log('TROUBLESHOOT II -- 1');

              switch (companyAuthToken) {
                // Move V1 Geskus UI to V2 Pics UI
                case GESKUS:
                  if (this.props.shoot.shoot.ui_version === 'ui_v1') {
                    this.storeStudentOrder(this.state.studentId, this.state);
                  } else {
                    this.props.selectShootProduct(modifiedProduct);
                  }
                  break;
                case GESKUS:
                case BNL:
                case JELLYJAR:
                case GB:
                case NORMANDY:
                case TIPPING:
                case MARCEL:
                case CLASSACT:
                case WAGNER:
                case BIELMAR:
                  this.props.selectShootProduct(modifiedProduct);
                  break;
                default:
                  this.props.selectShootProduct(modifiedProduct);
                  break;
              }

              this.props.updateOrderActiveComponent(nextComponent);
            },
          );
        },
      );
    }
  }

  productGroupIsByoVariant(productGroup) {
    // TODO: revist this... a hack to handle cases where undefined is showing up here
    if (typeof productGroup === 'undefined') {
      return false;
    }
    // TODO: revisit this -- 'is BYO?' should be driven by product's properties
    const byoVariants = [
      'Build Your Own Package',
      'Build Your Own Packages',
      'You Pick 6',
      'You Pick 5',
      'You Pick 4',
      'You Pick 3',
      'You Pick 8',
      'You Pick 6 Sheets',
      'You Pick 5 Sheets',
      'You Pick 4 Sheets',
      'You Pick 3 Sheets',
      'You Pick 8 Sheets',
      'Build Your Own Package - $15 Per Item',
      'Build Your Own Package - $20 Per Item',
      'Build Your Own 5',
      "You Pick 4 - Mom's Favorite!",
      'Select-A-Sheet',
      "Mom's Favorite! Mix & Match for $48!",
      'You Pick 3!',
      'You Pick 4!',
      'You Pick 5!',
      "Mom's Favorite! Our Most Popular Bundle!",
      "Mom's Favorite! Mix & Match 6 of the Most Popular Products!",
      "Mom's Favorite! Mix & Match Exclusive Products!",
      "Mom's Favorite!",
      "Mom's Favorite Package! Our best offer ever!",
      'You Pick 6 Exclusive!',
      'You Pick 6 Basic',
      "MOM'S FAVORITE! Mix & Match any 4 popular items!",
      "Mom's Favorite! Mix & Match any 4 popular items!",
    ];

    return byoVariants.includes(productGroup.name);
  }

  goBackToProductGroupsProducts() {
    const { selectedProductGroup } = this.state;

    this.setState(
      {
        productGroupActiveComponent: 2,
        selectedProductGroup,
      },
      () => {
        this.props.updateOrderActiveComponent(
          ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
        );
        this.props.selectShootProduct(null);
      },
    );
  }

  selectProductGroup(
    productGroup,
    callback = null,
    showChildCategories = false,
  ) {
    if (!showChildCategories && this.productGroupIsByoVariant(productGroup)) {
      this.goToBuildYourOwn(productGroup);
    } else {
      let productGroupActiveComponent = 1;
      if (!callback) productGroupActiveComponent = 2;
      this.setState(
        {
          productGroupActiveComponent,
          selectedProductGroup: productGroup,
        },
        () => {
          if (callback) {
            callback(productGroup);
          } else {
            this.props.updateOrderActiveComponent(
              ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
            );
          }
        },
      );
    }
  }

  selectRecommendedProductAndSetProductGroup(product) {
    const index = this.props.shoot.suggestedProductsLinkToIndex;
    const recGroup = this.props.shoot.shootProducts[index];

    this.selectShootProduct(product);
    this.selectProductGroup(recGroup);
  }

  goToSuggestProductGroup() {
    const index = this.props.shoot.suggestedProductsLinkToIndex;
    const group = this.props.shoot.shootProducts[index];

    this.selectProductGroup(group);
  }

  preConfigureIncludedItem() {
    // preconfig product and call storeBnlIncludedProductConfiguration
    const configuration = {};

    configuration['0'] = {
      backgroundCodes: [],
      backgroundPrices: [],
      personalizationValue: '',
      pose: null,
      poseIds: [],
    };

    const backgroundOptions = this.state.bnlIncludedProductsBackgroundOptions;
    const productId = this.state.includedProductsParentProductId;

    backgroundOptions[productId] = configuration;

    this.setState({
      bnlIncludedProductsBackgroundOptions: backgroundOptions,
    });
  }

  goToConfiguration(nextComponent = null) {
    let requiresConfig = false;
    const includedProduct = this.state.includedProducts[0];

    if (this.shootIsBnlPreShoot()) {
      if (includedProduct.requires_configuration) {
        if (includedProduct.background_options_count > 0) requiresConfig = true;
      }
    } else if (includedProduct.requires_configuration) {
      if (
        includedProduct.background_options_count > 0 ||
        includedProduct.poses_count > 0
      ) {
        requiresConfig = true;
      }
    }

    if (requiresConfig) {
      this.selectShootProduct(includedProduct);
      if (!nextComponent) {
        if (this.state.editingFromCart) {
          // console.log('%cTROUBLESHOOT - Nav 11', 'color: red');

          nextComponent = ORDER_NAVIGATION.BNL_CART;
        } else {
          nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_CONFIGURATION;
        }
      }
    } else if (!nextComponent) {
      if (this.state.editingFromCart) {
        // console.log('%cTROUBLESHOOT - Nav 12', 'color: red');

        nextComponent = ORDER_NAVIGATION.BNL_CART;
      } else {
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
      }
    }

    if (nextComponent) {
      this.props.updateOrderActiveComponent(nextComponent);

      if (!requiresConfig) {
        this.setState({ includedProducts: [] });
      }
    }
  }

  addConfiguredItemToCart(nextComponent) {
    let { studentId } = this.state;

    if (this.props.additionalData.studentId) {
      studentId = this.props.additionalData.studentId;
    }

    const configuration = this.cloneObject(this.state.configuration);
    const selectedBackgroundOptions = this.cloneObject(
      this.state.selectedBackgroundOptions,
    );
    const additionalSelectedBackgroundOptions = this.cloneObject(
      this.state.additionalSelectedBackgroundOptions,
    );
    const selectedPoseOptions = this.cloneObject(
      this.state.selectedPoseOptions,
    );

    if (configuration.backgroundCodes.length > 0) {
      selectedBackgroundOptions[configuration.itemId] = {
        codes: configuration.backgroundCodes,
        prices: configuration.backgroundCodesPrices,
      };
    }

    if (configuration.additionalBackgroundCodes.length > 0) {
      additionalSelectedBackgroundOptions[configuration.itemId] = {
        codes: configuration.additionalBackgroundCodes,
        prices: configuration.additionalBackgroundCodesPrices,
      };
    }

    const { selectedShootProduct } = this.state;
    const cartItems = this.cloneArray(this.state.cartItems);
    let duplicate = false;
    let increment = 0;

    cartItems.forEach((cartItem) => {
      if (cartItem.id === selectedShootProduct.id) duplicate = true;
    });

    if (!duplicate) {
      selectedShootProduct.count = this.state.quantity;
      selectedShootProduct.totalPrice =
        this.state.quantity * selectedShootProduct.price;
      cartItems.push(selectedShootProduct);
      increment = 1;
    }

    this.props.updateOrderActiveComponent(nextComponent);

    this.setState(
      {
        additionalSelectedBackgroundOptions,
        cartItems,
        idSuffix: this.state.idSuffix + 1,
        personalizationStatus: selectedShootProduct.personalization_status,
        quantity: 1,
        selectedBackgroundOptions,
        selectedShootProduct: null,
        youPickXConfiguration: false,
      },
      () => {
        let message;
        if (this.props.shoot.shoot.name === 'GESKUS_SPRING_2018') {
          message =
            'Item added to cart. Unlock free home shipping over $30 and 2 free Key Chains over $50!';
        } else {
          message = 'Item added to cart';
        }

        this.toggleNotification(message);
        this.props.updateCartCount(this.props.cart.count + increment);
        this.setState(
          {
            cartItemsTotalCost: this.calculateCartItemsTotalCost(),
          },
          () => {
            this.storeStudentOrder(studentId, this.state);
            if (nextComponent !== ORDER_NAVIGATION.PRODUCT_ADDONS) {
              this.checkForIncentive();
            }
          },
        );
      },
    );
  }

  // Add configured item to the cart
  addConfiguredBnlItemToCart(
    configuration,
    product = null,
    // addPersonalizationProduct = false,
    personalizationProductValue = null,
  ) {
    const { additionalData, cart, setPersonalizationOnOrder } = this.props;
    const currentStudent = this.getCurrentStudentFromAdditionalData();

    console.log('ADDING CONFIGURED BNL ITEM TO CART');
    console.log(configuration);
    console.log(product);
    console.log(this.state.studentId);
    console.log(this.props.bnlIncentive);
    console.log('++++++++++++++++++++++++++++++++++++++++++++++');

    // Store the most recently-added product (the current product being added)
    // for later use.
    this.props.setAdditionalData({
      mostRecentlyAddedProduct: this.props.shootProduct,
    });

    if (!this.props.shootProduct.is_retouching) {
      Sentry.setContext('addConfiguredBnlItemToCart', {
        configuration,
        orders: this.props.cart.orders,
        state: this.state,
      });

      const backgroundCount = this.props.shootProduct.background_options_count;
      const posesCount = this.props.shootProduct.poses_count;

      if (posesCount > 0) {
        if (this.props.bnlPreShoot.preShoot) {
          if (backgroundCount != Object.keys(configuration).length) {
            throw 'Background Count Not Matching';
          }
        } else if (posesCount != Object.keys(configuration).length) {
          throw 'Poses Count Not Matching';
        }
      }
    }

    let orderState;

    if (this.props.cart.orders[this.state.studentId]) {
      orderState = this.props.cart.orders[this.state.studentId];
      console.log('TROUBLESHOOT IV - 1');
    } else {
      console.log('TROUBLESHOOT IV - 2');
      orderState = this.baseState;
      orderState.backgroundChoiceMode = this.state.backgroundChoiceMode;
      orderState.formattedIncentiveProducts =
        this.props.shoot.formattedIncentiveProducts;
      orderState.companyShippingCost = this.state.companyShippingCost;
      orderState.studentId = this.state.studentId; // set from setStudent
      orderState.studentName = this.state.studentName; // set from setStudent
    }

    if (!this.state.editingFromCart && !this.shootIsBnlPreShoot()) {
      const modifiedProduct = this.cloneObject(this.props.shootProduct);
      modifiedProduct.id = `${modifiedProduct.id}_${orderState.idSuffix}`;
      orderState.selectedShootProduct = modifiedProduct;
      // console.log('TROUBLESHOOT 1');
      this.props.selectShootProduct(modifiedProduct);
    }

    this.setState(orderState, () => {
      let student;
      let studentId;
      let trackingStudentId;
      let studentName;
      let selectedShootProduct;

      // The below is a hack specific to the case where two retouching products are shown on the same configuration screen
      // TODO: remove this if/when a multi-retouch configuration screen is added.
      if (product && product.name !== this.props.shootProduct.name) {
        selectedShootProduct = this.cloneObject(product);

        if (!Number.isInteger(selectedShootProduct.id)) {
          const productIdToString = product.id.toString();
          const secondIdComponent = this.props.shootProduct.id.split('-')[1];
          selectedShootProduct.id = `${productIdToString}_${secondIdComponent}`;
        }
      } else {
        selectedShootProduct = this.cloneObject(this.props.shootProduct);
      }

      if (this.shootIsBnlPreShoot()) {
        student = this.props.bnlPreShoot.selectedStudent;
        studentId = student.id;
        studentName = `${student.firstName} ${student.lastName}`;
        trackingStudentId = student.id;
        // deal with super cd
      } else {
        studentId = this.state.studentId;
        studentName = this.state.studentName;

        if (currentStudent) {
          trackingStudentId = currentStudent.id;
        } else {
          trackingStudentId = 'postShoot';
        }

        // console.log('TROUBLESHOOT 2');
        if (
          selectedShootProduct.name === 'Super CD + Cloud Download' ||
          (this.productDoesNotRequirePoseOrBackground(selectedShootProduct) &&
            !studentId)
        ) {
          const { cpmId } = this.props.gallery;
          student = Object.values(this.props.gallery.galleries[cpmId])[0]
            .student;
          studentId = student.id;
          studentName = `${student.firstName} ${student.lastName}`;
        }
      }

      const options = this.cloneObject(this.state.bnlSelectedBackgroundOptions);

      if (!options[selectedShootProduct.id]) {
        options[selectedShootProduct.id] = configuration;
      }

      const selectedBackgroundOptions = this.cloneObject(
        this.state.selectedBackgroundOptions,
      );

      selectedBackgroundOptions[selectedShootProduct.id] = {};

      const bgOptions = selectedBackgroundOptions[selectedShootProduct.id];
      bgOptions.codes = [];

      const bnlBackgroundSelection = this.deepCloneObject(
        this.props.cart.bnlBackgroundSelection,
      );

      const { formattedBackgroundOptions } = this.props.shoot;

      if (selectedShootProduct.requires_configuration) {
        for (const poseIndex in configuration) {
          const innerConfig = configuration[poseIndex];

          if (this.props.bnlPreShoot.preShoot) {
            student = this.props.bnlPreShoot.selectedStudent;
          } else {
            // handle Geskus post-shoot
            if (
              additionalData &&
              additionalData.student &&
              additionalData.isGeskus
            ) {
              student = additionalData.student;
            } else {
              student = innerConfig.pose.student;
            }
          }

          const firstName = student.firstName || student.first_name;
          const lastName = student.lastName || student.last_name;
          studentName = `${firstName} ${lastName}`;
          const codes = innerConfig.backgroundCodes;

          if (
            this.props.shoot.shoot.background_choice_mode !==
            'outdoors_background_only'
          ) {
            if (!bnlBackgroundSelection[trackingStudentId]) {
              bnlBackgroundSelection[trackingStudentId] = {};
            }

            codes.forEach((code) => {
              // if (!bgOptions['codes'].includes(code)) {
              bgOptions.codes.push(code);
              // }

              const { price } = formattedBackgroundOptions[code];

              if (!bnlBackgroundSelection[trackingStudentId][price]) {
                bnlBackgroundSelection[trackingStudentId][price] = [];
              }

              if (
                !bnlBackgroundSelection[trackingStudentId][price].includes(
                  selectedShootProduct.id,
                )
              ) {
                bnlBackgroundSelection[trackingStudentId][price].push(
                  selectedShootProduct.id,
                );
              }
            });
          }

          bgOptions.personalizationValue = innerConfig.personalizationValue;
        }
      }

      let shootKey;

      console.log('TROUBLESHOOT 3');

      if (this.shootIsBnlPreShoot()) {
        shootKey = this.props.bnlPreShoot.shootKey;
      } else if (
        selectedShootProduct.requires_configuration &&
        Object.values(configuration).length >= 1
      ) {
        // handle Geskus post-shoot
        if (additionalData && additionalData.shootKey) {
          shootKey = additionalData.shootKey;
        } else {
          shootKey = Object.values(configuration)[0].pose.gallery_key;
        }
      } else {
        // handle Geskus post-shoot
        if (additionalData && additionalData.shootKey) {
          shootKey = additionalData.shootKey;
        } else {
          shootKey = this.props.shoot.shoot.key;
        }
      }

      const bnlBgOptions = this.cloneObject(
        this.state.bnlSelectedBackgroundOptions,
      );

      bnlBgOptions[selectedShootProduct.id] = configuration;

      let cartItems = this.cloneArray(this.state.cartItems);
      let duplicate = false;
      let increment = 0;
      const idSuffixIncrement = 1;
      let cartContainsRetouching = false;
      const { retouchingProduct } = this.props.shoot;

      cartItems.forEach((cartItem) => {
        if (cartItem.id === selectedShootProduct.id) duplicate = true;
        if (
          (retouchingProduct &&
            !Number.isInteger(cartItem.id) &&
            cartItem.id.split('_')[0] == retouchingProduct?.id) ||
          (Number.isInteger(cartItem.id) &&
            cartItem.id == retouchingProduct?.id)
        ) {
          cartContainsRetouching = true;
        }
      });

      const bnlFreeBackgrounds = this.deepCloneObject(
        this.props.cart.bnlFreeBackgrounds,
      );

      if (!duplicate) {
        selectedShootProduct.count = this.state.quantity;
        selectedShootProduct.totalPrice =
          this.state.quantity * selectedShootProduct.price;

        console.log(
          '5_A',
          selectedShootProduct,
          this.state.quantity,
          selectedShootProduct.price,
        );

        cartItems.push(selectedShootProduct);
        increment = 1;

        if (selectedShootProduct.triggers_free_backgrounds) {
          if (!bnlFreeBackgrounds[trackingStudentId]) {
            bnlFreeBackgrounds[trackingStudentId] = [];
          }

          bnlFreeBackgrounds[trackingStudentId].push(selectedShootProduct.id);
        }
      } else if (this.shootIsAdvancedGreenScreenBackgroundsOnly()) {
        this.updateBnlItemBackgroundSelection(configuration);
      }

      console.log('TROUBLESHOOT 4');

      // Add product-based personalization
      // TODO: redvist this
      if (personalizationProductValue && personalizationProductValue !== '') {
        if (!cart.personalization[studentId]) {
          const personalizationProduct = this.cloneObject(
            this.props.shoot.personalizationProduct,
          );

          personalizationProduct.count = 1;
          personalizationProduct.totalPrice = personalizationProduct.price;

          if (selectedShootProduct.is_personalization) {
            console.log(
              'Selected product is personalization, skipping secondary personalization-add...',
            );
          } else {
            cartItems.push(personalizationProduct);
          }
        }

        const personalizationConfig = {
          studentId,
          personalizationValue: personalizationProductValue,
        };

        setPersonalizationOnOrder(personalizationConfig);
      }

      // console.log('TROUBLESHOOT 5');
      // key = studentId
      // value = boolean
      //         true - added via product that incldues retouching
      //         false - added via retouching product
      const bnlRetouching = this.cloneObject(this.props.cart.bnlRetouching);

      if (selectedShootProduct.includes_retouching) {
        bnlRetouching[trackingStudentId] = true;

        if (cartContainsRetouching) {
          const newCartItems = [];
          cartItems.forEach((cartItem) => {
            if (cartItem.id.split('_')[0] != retouchingProduct.id) {
              newCartItems.push(cartItem);
            }
          });
          console.log('5_B', newCartItems);

          cartItems = newCartItems;
        }
      } else if (
        this.props.shoot.retouchingProduct &&
        (selectedShootProduct.id == this.props.shoot.retouchingProduct.id ||
          (!Number.isInteger(selectedShootProduct.id) &&
            selectedShootProduct.id.split('_')[0] ==
              this.props.shoot.retouchingProduct.id))
      ) {
        bnlRetouching[trackingStudentId] = false;
      }

      let recommendProducts = false;
      if (
        (this.props.shoot.suggestedProducts.length > 0 &&
          selectedShootProduct.lock_type === 'unlocker') ||
        selectedShootProduct.is_retouching
      ) {
        recommendProducts = true;
      }

      // console.log('TROUBLESHOOT 6');

      this.setState(
        {
          bnlEditConfiguration: null,
          bnlSelectedBackgroundOptions: bnlBgOptions,
          cartItems,
          idSuffix: this.state.idSuffix + idSuffixIncrement,
          includedProducts: selectedShootProduct.included_products,
          recommendProducts,
          quantity: 1,
          selectedBackgroundOptions,
          shootKey,
          shootName: this.props.shoot.shoot.name,
          studentId,
          studentName,
        },
        () => {
          console.log('TROUBLESHOOT 7');
          this.toggleNotification('Item Added');
          this.checkBnlProductRequirement(selectedShootProduct);
          this.props.updateCartCount(this.props.cart.count + increment);
          if (!duplicate) {
            this.props.storeBnlBackgroundSelection(bnlBackgroundSelection);
          }
          this.props.updateBnlRetouching(bnlRetouching);
          this.props.updateBnlFreeBackgrounds(bnlFreeBackgrounds);
          this.setState(
            { cartItemsTotalCost: this.calculateCartItemsTotalCost() },
            () => {
              // console.log('TROUBLESHOOT 8');
              const { bnlPromptRetouching } = this.props.cart;

              let promptRetouchingStudentId;

              if (this.props.bnlPreShoot.preShoot) {
                promptRetouchingStudentId = this.state.studentId;
              } else {
                if (currentStudent) {
                  promptRetouchingStudentId = currentStudent.id;
                } else {
                  promptRetouchingStudentId = 'postShoot';
                }
              }

              // console.log('%cOFFERS RETOUCHING CHECK 2', 'color: cyan');
              // console.log(selectShootProduct);

              if (
                selectedShootProduct.offers_retouching &&
                !selectedShootProduct.includes_retouching &&
                bnlRetouching[promptRetouchingStudentId] === undefined &&
                (bnlPromptRetouching[promptRetouchingStudentId] === undefined ||
                  bnlPromptRetouching[promptRetouchingStudentId] === true)
              ) {
                console.log('TROUBLESHOOT 9');

                if (orderState?.editingFromCart) {
                  console.log('%cTROUBLESHOOT - Nav 13', 'color: red');

                  this.props.updateOrderActiveComponent(
                    ORDER_NAVIGATION.BNL_CART,
                  );
                } else {
                  console.log('TROUBLESHOOT 9.1');
                  // Swal.fire('This item offers retouching!'); // TODO: remove?
                  this.props.orderShouldOfferRetouching();
                  this.props.updateOrderActiveComponent(null); // TODO: remove?
                  // this.promptRetouchingOrInterstitial(); // TODO: remove?
                }

                this.storeStudentOrder(this.state.studentId, this.state);
              } else if (this.hasIncludedProducts()) {
                console.log('TROUBLESHOOT 10');
                this.storeStudentOrder(this.state.studentId, this.state);
                this.goToConfiguration();
              } else {
                console.log('TROUBLESHOOT 11');
                this.storeStudentOrder(this.state.studentId, this.state);

                // check to see if product offers retouching
                // console.log('%cOFFERS RETOUCHING CHECK 1', 'color: cyan');
                // console.log(selectedShootProduct);

                if (
                  selectedShootProduct.offers_retouching &&
                  !selectedShootProduct.includes_retouching
                ) {
                  console.log('TROUBLESHOOT 12');
                  this.props.orderShouldOfferRetouching();
                }

                // Determine whether cart state qualifies user for an nth-free product
                const cartItemsForScan = this.state.cartItems;
                let cartContainsNthFree = false;
                let nthFreeQualifyingInCart = 0;
                let nthFreeThreshold = 9999;

                // console.log('TROUBLESHOOT 13');

                for (let i = 0, len = cartItemsForScan.length; i < len; i++) {
                  const cartItem = cartItemsForScan[i];

                  if (
                    cartItem.product_type &&
                    cartItem.product_type === 'nth_free'
                  ) {
                    cartContainsNthFree = true;
                  }

                  if (cartItem.nth_free_sku && cartItem.nth_free_sku !== '') {
                    nthFreeQualifyingInCart += cartItem.count;
                  }

                  if (
                    cartItem.nth_free_threshold &&
                    cartItem.nth_free_threshold !== 0
                  ) {
                    if (cartItem.nth_free_threshold < nthFreeThreshold) {
                      nthFreeThreshold = cartItem.nth_free_threshold;
                    }
                  }
                }

                if (
                  !cartContainsNthFree &&
                  nthFreeQualifyingInCart >= nthFreeThreshold
                ) {
                  // console.log('TROUBLESHOOT 14');
                  this.setState(
                    {
                      nthFreeTriggered: true,
                    },
                    () => {
                      this.props.updateOrderActiveComponent(
                        ORDER_NAVIGATION.NTH_FREE_PRODUCTS,
                      );
                    },
                  );
                } else {
                  console.log('TROUBLESHOOT 15 -- CHECK FOR BNL INCENTIVE');
                  console.log(this.props.bnlIncentive);
                  // this.checkForBnlIncentive(this.state, false, product);
                  this.checkForBnlIncentive();
                }
              }
            },
          );
        },
      );
    });
  }

  storeBnlIncludedProductConfiguration(configuration) {
    console.log('%cSTORE BNL INCLUDED PRODUCT CONFIGURATION', 'color: lime');
    const backgroundOptions = this.state.bnlIncludedProductsBackgroundOptions;
    const productId = this.state.includedProductsParentProductId;

    backgroundOptions[productId] = configuration;

    this.setState(
      {
        bnlIncludedProductsBackgroundOptions: backgroundOptions,
        includedProducts: [],
        includedProductsParentProductId: null,
      },
      () => {
        // console.log('%cCHECK FOR BNL INCENTIVE 2', 'color: lime');
        this.checkForBnlIncentive();
      },
    );
  }

  addConfiguredYouPickXItemsToCart(nextComponent) {
    const configuration = this.cloneObject(this.state.youPickXConfiguration);
    const youPickXSelectedBackgroundOptions = this.cloneObject(
      this.state.youPickXSelectedBackgroundOptions,
    );
    const cartItems = this.cloneArray(this.state.cartItems);
    const youPickXCartItems = this.cloneArray(this.state.youPickXCartItems);
    const youPickXCartItemIds = youPickXCartItems.map(
      (cartItem, i) => cartItem.id,
    );
    const shootProductId = this.state.selectedShootProduct.id;
    youPickXSelectedBackgroundOptions[shootProductId] = {};

    for (const childItemId in configuration) {
      const youPickXItem = configuration[childItemId].childItem;
      const { backgroundCodes } = configuration[childItemId];
      youPickXSelectedBackgroundOptions[shootProductId][childItemId] = {};
      const childItem =
        youPickXSelectedBackgroundOptions[shootProductId][childItemId];

      childItem.parentId = this.state.selectedShootProduct.id;
      childItem.childItem = youPickXItem;
      childItem.backgroundCodes = backgroundCodes;

      // prevent adding the same you pick x item to cart when editing configuration
      if (youPickXCartItemIds.indexOf(youPickXItem.id) === -1) {
        youPickXCartItems.push(youPickXItem);
      }
    }

    const { selectedShootProduct } = this.state;
    let duplicate = false;
    let increment = 0;
    cartItems.forEach((cartItem) => {
      if (cartItem.id === this.state.selectedShootProduct.id) duplicate = true;
    });

    // prevent adding the same item to cart when editing configuration
    if (!duplicate) {
      selectedShootProduct.count = this.state.quantity;
      selectedShootProduct.totalPrice =
        selectedShootProduct.price * this.state.quantity;
      cartItems.push(selectedShootProduct);
      increment = 1;
    }

    this.props.updateOrderActiveComponent(nextComponent);
    this.setState(
      {
        cartItems,
        idSuffix: this.state.idSuffix + 1,
        quantity: 1,
        youPickXCartItems,
        youPickXSelectedBackgroundOptions,
      },
      () => {
        let message;
        if (this.props.shoot.shoot.name === 'GESKUS_SPRING_2018') {
          message =
            'Item added to cart. Unlock free home shipping over $30 and 2 free Key Chains over $50!';
        } else {
          message = 'Item added to cart';
        }

        this.toggleNotification(message);
        this.props.updateCartCount(this.props.cart.count + increment);
        this.setState(
          {
            cartItemsTotalCost: this.calculateCartItemsTotalCost(),
          },
          () => {
            this.checkForIncentive();
          },
        );
      },
    );
  }

  addConfiguredBuildYourOwnItem(configuration, personalizationFormValue) {
    let currentStudentId = this.state.studentId;
    let currentStudent;

    if (
      this.props.additionalData &&
      this.props.additionalData.student &&
      this.props.additionalData.student.id
    ) {
      currentStudentId = this.props.additionalData.student.id;
      currentStudent = this.props.additionalData.student;
    }

    const { setPersonalizationOnOrder } = this.props;
    let orderState;

    if (this.props.cart.orders[currentStudentId]) {
      orderState = this.props.cart.orders[currentStudentId];
    } else {
      orderState = this.baseState;
      orderState.backgroundChoiceMode = this.state.backgroundChoiceMode;
      orderState.byoIdSuffix = this.state.byoIdSuffix;
      orderState.formattedIncentiveProducts =
        this.props.shoot.formattedIncentiveProducts;
      orderState.companyShippingCost = this.state.companyShippingCost;
      orderState.studentId = currentStudentId;
      orderState.studentName = this.state.studentName;
    }

    const modifiedProduct = this.cloneObject(this.props.shootProduct);

    if (!this.shootIsBnlPreShoot() && !orderState.editingFromCart) {
      modifiedProduct.id = `${modifiedProduct.id}_${orderState.idSuffix}`;
      orderState.selectedShootProduct = modifiedProduct;
      this.props.selectShootProduct(modifiedProduct);
    }

    this.setState(orderState, () => {
      let cartItems = this.cloneArray(this.state.cartItems);
      const buildYourOwnSelectedBackgroundOptions = this.cloneObject(
        this.state.buildYourOwnSelectedBackgroundOptions,
      );
      const bnlBackgroundSelection = this.deepCloneObject(
        this.props.cart.bnlBackgroundSelection,
      );

      if (
        orderState.editingFromCart &&
        this.props.shoot.shoot.background_choice_mode !==
          'outdoors_background_only'
      ) {
        // update child item ids
        this.updateByoBnlBackgroundSelection(
          buildYourOwnSelectedBackgroundOptions[modifiedProduct.id],
          configuration,
        );
      }

      const buildYourOwnCartItems = this.cloneArray(
        this.state.buildYourOwnCartItems,
      );
      const buildYourOwnCartItemIds = buildYourOwnCartItems.map(
        (cartItem, i) => cartItem.id,
      );

      const selectedShootProduct = modifiedProduct;
      const shootProductId = selectedShootProduct.id;

      buildYourOwnSelectedBackgroundOptions[shootProductId] = {};
      const bnlFreeBackgrounds = this.deepCloneObject(
        this.props.cart.bnlFreeBackgrounds,
      );
      for (const childItemId in configuration) {
        const buildYourOwnItem = configuration[childItemId].childItem;
        const { pose } = configuration[childItemId];
        const { poseIds } = configuration[childItemId];
        const backgroundCode = configuration[childItemId].backgroundCodes[0];
        const { backgroundPrices } = configuration[childItemId];
        const backgroundCodes = backgroundCode ? [backgroundCode] : [];
        buildYourOwnSelectedBackgroundOptions[shootProductId][childItemId] = {};
        const childItem =
          buildYourOwnSelectedBackgroundOptions[shootProductId][childItemId];

        childItem.parentId = shootProductId;
        childItem.childItem = buildYourOwnItem;
        childItem.backgroundCodes = backgroundCodes;
        childItem.backgroundPrices = backgroundPrices;
        childItem.pose = pose;
        childItem.poseIds = poseIds;

        let student;
        let trackingStudentId;

        if (this.shootIsBnlPreShoot()) {
          student = this.props.bnlPreShoot.selectedStudent;
          trackingStudentId = currentStudentId;
        } else {
          // student = pose.student; // TODO: remove this -- not needed(?)
          trackingStudentId = currentStudentId;
        }

        if (selectedShootProduct.triggers_free_backgrounds) {
          if (!bnlFreeBackgrounds[trackingStudentId]) {
            bnlFreeBackgrounds[trackingStudentId] = [];
          }

          if (
            !bnlFreeBackgrounds[trackingStudentId].includes(
              selectedShootProduct.id,
            )
          ) {
            bnlFreeBackgrounds[trackingStudentId].push(selectedShootProduct.id);
          }
        }

        let price = 0;
        if (backgroundCode) {
          price =
            this.props.shoot.formattedBackgroundOptions[backgroundCode].price;
        }

        if (
          this.props.shoot.shoot.background_choice_mode !==
          'outdoors_background_only'
        ) {
          if (!bnlBackgroundSelection[trackingStudentId]) {
            bnlBackgroundSelection[trackingStudentId] = {};
          }

          if (!bnlBackgroundSelection[trackingStudentId][price]) {
            bnlBackgroundSelection[trackingStudentId][price] = [];
          }

          if (
            !bnlBackgroundSelection[trackingStudentId][price].includes(
              childItem.childItem.id,
            )
          ) {
            bnlBackgroundSelection[trackingStudentId][price].push(
              childItem.childItem.id,
            );
          }
        }

        // prevent adding the same you pick x item to cart when editing configuration
        if (buildYourOwnCartItemIds.indexOf(buildYourOwnItem.id) === -1) {
          buildYourOwnCartItems.push(buildYourOwnItem);
        }
      }

      let shootKey;

      if (this.shootIsBnlPreShoot()) {
        shootKey = this.props.bnlPreShoot.shootKey;
      } else if (Object.values(configuration).length > 0) {
        const {
          additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
        } = this.props;

        // Handle Geskus Post-Event
        // TODO: revisit
        if (isGeskus && isPostEvent && studentPreviewImageUrl) {
          shootKey = null;
        } else {
          shootKey = Object.values(configuration)[0].pose.gallery_key;
        }
      }

      let duplicate = false;
      let increment = 0;
      let byoPrice;

      // only true BYO have required_children_count of 0
      if (selectedShootProduct.required_children_count === 0) {
        byoPrice = this.calculateByoPrice(
          configuration,
          selectedShootProduct.additional_data.free_indices,
        );
      } else {
        byoPrice = selectedShootProduct.price;
      }

      selectedShootProduct.price = byoPrice;
      const updatingCartItems = [];

      cartItems.forEach((cartItem) => {
        const updatingCartItem = this.cloneObject(cartItem);

        if (cartItem.id === this.props.shootProduct.id) {
          duplicate = true;
          updatingCartItem.price = byoPrice;
          updatingCartItem.totalPrice = byoPrice * this.state.quantity;
        }

        updatingCartItems.push(updatingCartItem);
      });

      cartItems = updatingCartItems;

      if (!duplicate) {
        selectedShootProduct.count = this.state.quantity;
        selectedShootProduct.totalPrice =
          selectedShootProduct.price * this.state.quantity;
        cartItems.push(selectedShootProduct);
        increment = 1;
      }

      let student;
      let studentId;
      let studentName;
      let shootName = '';

      if (this.shootIsBnlPreShoot()) {
        student = this.props.bnlPreShoot.selectedStudent;
        studentId = student.id;
        studentName = `${student.firstName} ${student.lastName}`;
        shootName = this.props.bnlPreShoot.shootName;
        // deal with super cd
      } else {
        if (currentStudent) {
          studentId = currentStudentId;
          studentName = `${currentStudent.firstName} ${currentStudent.lastName}`;
        } else {
          studentId = this.state.studentId;
          studentName = this.state.studentName;
        }
      }

      Sentry.setContext('addConfiguredBuildYourOwnItem', {
        byoConfiguration: configuration,
        byoSelectedBackgroundOptions: buildYourOwnSelectedBackgroundOptions,
      });

      const includedProductsParentProductId =
        selectedShootProduct.included_products.length > 0
          ? selectedShootProduct.id
          : null;

      if (personalizationFormValue && personalizationFormValue !== '') {
        if (!this.props.cart.personalization[currentStudentId]) {
          const personalizationProduct = this.cloneObject(
            this.props.shoot.personalizationProduct,
          );
          personalizationProduct.count = 1;
          personalizationProduct.totalPrice = personalizationProduct.price;

          if (selectedShootProduct.is_personalization) {
            console.log(
              'Selected product is personalization, skipping secondary personalization-add...',
            );
          } else {
            cartItems.push(personalizationProduct);
          }
        }

        const personalizationConfig = {
          studentId,
          personalizationValue: personalizationFormValue,
        };

        this.props.setPersonalizationOnOrder(personalizationConfig);
      }

      this.setState(
        {
          bnlEditConfiguration: null,
          buildYourOwnCartItems,
          buildYourOwnSelectedBackgroundOptions,
          cartItems,
          idSuffix: this.state.idSuffix + 1,
          includedProducts: selectedShootProduct.included_products,
          includedProductsParentProductId,
          quantity: 1,
          selectedShootProduct,
          shootKey,
          shootName,
          studentId,
          studentName,
        },
        () => {
          let message;
          if (this.props.shoot.shoot.name === 'GESKUS_SPRING_2018') {
            message =
              'Item added to cart. Unlock free home shipping over $30 and 2 free Key Chains over $50!';
          } else {
            message = 'Item added to cart';
          }

          this.toggleNotification(message);

          if (!orderState.editingFromCart) {
            this.props.storeBnlBackgroundSelection(bnlBackgroundSelection);
          }

          this.props.updateBnlFreeBackgrounds(bnlFreeBackgrounds);
          this.checkBnlProductRequirement(selectedShootProduct);
          this.props.updateCartCount(this.props.cart.count + increment);
          this.setState(
            { cartItemsTotalCost: this.calculateCartItemsTotalCost() },
            () => {
              const { bnlPromptRetouching } = this.props.cart;
              const bnlRetouching = this.cloneObject(
                this.props.cart.bnlRetouching,
              );

              let promptRetouchingStudentId;

              if (this.props.bnlPreShoot.preShoot) {
                promptRetouchingStudentId = currentStudentId;
              } else {
                promptRetouchingStudentId = currentStudentId;
              }

              // if (
              //   selectedShootProduct.offers_retouching
              //   && !selectedShootProduct.includes_retouching
              //   && bnlRetouching[promptRetouchingStudentId] === undefined
              //   && (bnlPromptRetouching[promptRetouchingStudentId] === undefined
              //     || bnlPromptRetouching[promptRetouchingStudentId] === true)
              // ) {
              //   Swal.fire('This item offers retouching!');
              //   this.props.updateOrderActiveComponent(null);
              //   this.storeStudentOrder(this.state.studentId, this.state);
              //   // TODO: fix retouching vs. incentive bug triggered here
              //   this.);
              // } else if (this.hasIncludedProducts()) {
              //   this.storeStudentOrder(this.state.studentId, this.state);
              //   this.goToConfiguration();
              // } else {
              //   this.checkForBnlIncentive(this.state);
              // }

              if (this.hasIncludedProducts()) {
                this.storeStudentOrder(currentStudentId, this.state);
                this.goToConfiguration();
              } else {
                console.log('%cCHECK FOR BNL INCENTIVE 3', 'color: lime');
                this.checkForBnlIncentive(this.state);
              }
            },
          );
        },
      );
    });
  }

  addConfiguredIncentiveItem(configuration) {
    const orderState = this.cloneObject(
      this.props.cart.orders[this.state.studentId],
    );
    const that = this;

    orderState.incentiveConfiguration = configuration;

    this.setState(orderState, () => {
      this.storeStudentOrder(this.state.studentId, orderState);
      this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
    });
  }

  addConfiguredGeskusIncentiveItem(configuration) {
    const orderState = this.cloneObject(
      this.props.cart.orders[this.state.studentId],
    );
    const that = this;

    orderState.incentiveConfiguration = configuration;

    this.setState(orderState, () => {
      this.storeStudentOrder(this.state.studentId, orderState);
      this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
    });
  }

  removeCartItemIdFromBnlBackgroundSelection(orderState, studentId, cartItem) {
    const bnlBackgroundSelection = this.deepCloneObject(
      this.props.cart.bnlBackgroundSelection,
    );
    const currentStudent = this.getCurrentStudentFromAdditionalData();

    let configuration;
    let trackingStudentId;

    if (this.shootIsBnlPreShoot()) {
      trackingStudentId = studentId;
    } else {
      if (currentStudent) {
        trackingStudentId = currentStudent.id;
      } else {
        trackingStudentId = 'postShoot';
      }
    }

    let studentBackgroundSelection = bnlBackgroundSelection[trackingStudentId];
    const { formattedBackgroundOptions } = this.props.shoot;

    Sentry.setContext('removeCartItemIdFromBnlBackgroundSelection', {
      bnlBackgroundSelection,
      cartItem,
      orderState,
      studentId,
    });

    let innerConfig;
    let bgCode;
    let cartItemIdsArray;

    if (cartItem.product_type === 'regular_product') {
      configuration = orderState.bnlSelectedBackgroundOptions[cartItem.id];
      for (const poseId in configuration) {
        innerConfig = configuration[poseId];
        bgCode = innerConfig.backgroundCodes[0];

        if (bgCode) {
          const bgPrice = formattedBackgroundOptions[bgCode].price;
          cartItemIdsArray = studentBackgroundSelection[bgPrice];

          if (cartItemIdsArray) {
            cartItemIdsArray = this.removeElementFromArray(
              cartItemIdsArray,
              cartItem.id,
            );

            let unlockerCount = 0;
            const unlockableIds = [];

            this.state.cartItems.forEach((item) => {
              if (item.lock_type === 'unlocker') unlockerCount += 1;
              if (item.lock_type === 'unlockable') unlockableIds.push(item.id);
            });

            // if cartItem is the last unlocker, delete all unlockable ids
            if (unlockerCount === 1) {
              unlockableIds.forEach((unlockableId) => {
                cartItemIdsArray = this.removeElementFromArray(
                  cartItemIdsArray,
                  unlockableId,
                );
              });
            }

            if (cartItemIdsArray.length > 0) {
              studentBackgroundSelection[bgPrice] = cartItemIdsArray;
            } else {
              // delete bgCode key and cart items id array value from object
              // if cartItemId is the last cart item id
              delete studentBackgroundSelection[bgPrice];
            }
          }
        }
      }
    } else if (cartItem.product_type === 'parent_product') {
      // console.log('%cCART ITEM IS PARENT PRODUCT', 'color: lime');

      configuration =
        orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id];
      for (const slotIndex in configuration) {
        innerConfig = configuration[slotIndex];

        if (!this.shootIsBnlPreShoot()) {
          studentBackgroundSelection =
            bnlBackgroundSelection[trackingStudentId];
        }

        bgCode = configuration[slotIndex].backgroundCodes[0];

        // console.log('%cTHE BG CODE', 'color: lime');
        // console.log(bgCode);
        // console.log(trackingStudentId);

        if (bgCode) {
          const bgPrice = formattedBackgroundOptions[bgCode].price;
          const childItemId = configuration[slotIndex].childItem.id;

          // console.log(bgPrice);
          // console.log(childItemId);
          // console.log(studentBackgroundSelection);

          if (studentBackgroundSelection) {
            cartItemIdsArray = studentBackgroundSelection[bgPrice];
            cartItemIdsArray = this.removeElementFromArray(
              cartItemIdsArray,
              childItemId,
            );

            if (cartItemIdsArray && cartItemIdsArray.length > 0) {
              studentBackgroundSelection[bgPrice] = cartItemIdsArray;
            } else {
              // delete bgCode key and cart items id array value from object
              // if cartItemId is the last cart item id
              delete studentBackgroundSelection[bgPrice];
            }
          }
        }
      }
    }

    // remove cartItemId from studentBackgroundSelection[bgCode];
    for (const studentId in bnlBackgroundSelection) {
      if (Object.keys(bnlBackgroundSelection[studentId]).length === 0) {
        delete bnlBackgroundSelection[studentId];
      }
    }

    this.props.storeBnlBackgroundSelection(bnlBackgroundSelection);
  }

  incomingConfigurationHasDiffBg(currentConfig, incomingConfig) {
    const currentBgs = Object.values(currentConfig).map(
      (innerConfig) => innerConfig.backgroundCodes[0],
    );

    const incomingBgs = Object.values(incomingConfig).map(
      (innerConfig) => innerConfig.backgroundCodes[0],
    );

    let diff = false;

    incomingBgs.forEach((bg) => {
      if (!currentBgs.includes(bg)) {
        diff = true;
      }
    });

    return diff;
  }

  updateBnlItemBackgroundSelection(newConfiguration) {
    const product = this.props.shootProduct;
    const oldConfiguration =
      this.state.bnlSelectedBackgroundOptions[product.id];
    const { formattedBackgroundOptions } = this.props.shoot;
    const currentStudent = this.getCurrentStudentFromAdditionalData();

    let trackingStudentId;
    if (this.shootIsBnlPreShoot()) {
      trackingStudentId = this.state.studentId;
    } else {
      if (currentStudent) {
        trackingStudentId = currentStudent.id;
      } else {
        trackingStudentId = 'postShoot';
      }
    }

    let newBackgroundPrices = [];

    // gather new background prices
    for (const poseIndex in newConfiguration) {
      const innerConfig = newConfiguration[poseIndex];
      const backgroundPrices = innerConfig.backgroundCodes.map(
        (code) => formattedBackgroundOptions[code].price,
      );

      newBackgroundPrices = newBackgroundPrices.concat(backgroundPrices);
    }

    const studentBackgroundSelection =
      this.props.cart.bnlBackgroundSelection[trackingStudentId];

    for (const price in studentBackgroundSelection) {
      let productIdsArray = studentBackgroundSelection[price];

      // if id is found in the price array and that price isn't included in newBackgroundPrices
      // delete id in price array
      if (
        productIdsArray.includes(product.id) &&
        !newBackgroundPrices.includes(price)
      ) {
        productIdsArray = this.removeElementFromArray(
          productIdsArray,
          product.id,
        );
      }

      // if price array is empty, delete key value pair
      if (productIdsArray.length === 0) {
        delete studentBackgroundSelection[price];
      }
    }

    // fill studentBackgroundSelection with newBackgroundPrices
    newBackgroundPrices.forEach((price) => {
      if (!studentBackgroundSelection[price]) {
        studentBackgroundSelection[price] = [];
      }

      if (!studentBackgroundSelection[price].includes(product.id)) {
        studentBackgroundSelection[price].push(product.id);
      }
    });

    if (Object.keys(studentBackgroundSelection).length === 0) {
      delete this.props.cart.bnlBackgroundSelection[trackingStudentId];
    }

    this.props.storeBnlBackgroundSelection(
      this.props.cart.bnlBackgroundSelection,
    );
  }

  updateByoBnlBackgroundSelection(
    currentByoBgOptions,
    incomingByoConfiguration,
  ) {
    // currentChildItemIds will always have more ids than incomingChildItemIds
    const currentChildItemIds = {};
    const incomingChildItemIds = {};
    let student;
    let trackingStudentId;
    const { formattedBackgroundOptions } = this.props.shoot;
    const currentStudent = this.getCurrentStudentFromAdditionalData();

    if (this.shootIsBnlPreShoot()) {
      student = this.props.bnlPreShoot.selectedStudent;
      trackingStudentId = student.id;
    } else {
      if (currentStudent) {
        trackingStudentId = currentStudent.id;
      } else {
        trackingStudentId = 'postShoot';
      }
    }

    // process data
    for (const slotIndex in currentByoBgOptions) {
      const config = currentByoBgOptions[slotIndex];
      if (!this.shootIsBnlPreShoot()) student = config.pose.student;

      if (!currentChildItemIds[trackingStudentId]) {
        currentChildItemIds[trackingStudentId] = {};
      }

      const studentChildItemIds = currentChildItemIds[trackingStudentId];
      const bgCode = config.backgroundCodes[0];
      const { price } = formattedBackgroundOptions[bgCode];

      if (!studentChildItemIds[price]) {
        studentChildItemIds[price] = [];
      }

      studentChildItemIds[price].push(config.childItem.id);
    }

    for (const slotIndex in incomingByoConfiguration) {
      const config = incomingByoConfiguration[slotIndex];
      if (!this.shootIsBnlPreShoot()) student = config.pose.student;

      if (!incomingChildItemIds[trackingStudentId]) {
        incomingChildItemIds[trackingStudentId] = {};
      }

      const studentChildItemIds = incomingChildItemIds[trackingStudentId];
      const bgCode = config.backgroundCodes[0];
      const { price } = formattedBackgroundOptions[bgCode];

      if (!studentChildItemIds[price]) {
        studentChildItemIds[price] = [];
      }

      studentChildItemIds[price].push(config.childItem.id);
    }

    const removingDiff = {};

    for (const studentId in currentChildItemIds) {
      const studentChildItemIds = currentChildItemIds[studentId];

      for (const price in studentChildItemIds) {
        const childItemIds = studentChildItemIds[price];

        if (
          incomingChildItemIds[studentId] &&
          incomingChildItemIds[studentId][price]
        ) {
          childItemIds.forEach((childItemId) => {
            if (!incomingChildItemIds[studentId][price].includes(childItemId)) {
              if (!removingDiff[studentId]) removingDiff[studentId] = {};
              if (!removingDiff[studentId][price]) {
                removingDiff[studentId][price] = [];
              }
              removingDiff[studentId][price].push(childItemId);
            }
          });
        } else {
          if (!removingDiff[studentId]) removingDiff[studentId] = {};
          removingDiff[studentId][price] = childItemIds;
        }
      }
    }

    const addingDiff = {};

    for (const studentId in incomingChildItemIds) {
      const studentChildItemIds = incomingChildItemIds[studentId];

      for (const price in studentChildItemIds) {
        const childItemIds = studentChildItemIds[price];

        if (
          currentChildItemIds[studentId] &&
          currentChildItemIds[studentId][price]
        ) {
          childItemIds.forEach((childItemId) => {
            if (!currentChildItemIds[studentId][price].includes(childItemId)) {
              if (!addingDiff[studentId]) addingDiff[studentId] = {};
              if (!addingDiff[studentId][price]) {
                addingDiff[studentId][price] = [];
              }
              addingDiff[studentId][price].push(childItemId);
            }
          });
        } else {
          if (!addingDiff[studentId]) addingDiff[studentId] = {};
          addingDiff[studentId][price] = childItemIds;
        }
      }
    }
    //

    const bnlBackgroundSelection = this.deepCloneObject(
      this.props.cart.bnlBackgroundSelection,
    );
    const that = this;

    // filter ids out of bnlBackgroundSelection
    for (const studentId in removingDiff) {
      const studentChildItemIds = removingDiff[studentId];

      for (const price in studentChildItemIds) {
        const childItemIds = studentChildItemIds[price];

        childItemIds.forEach((childItemId) => {
          const studentBnlBackgroundSelection =
            bnlBackgroundSelection[studentId];

          for (const bgCode in studentBnlBackgroundSelection) {
            studentBnlBackgroundSelection[price] =
              studentBnlBackgroundSelection[price].filter(
                (id) => id !== childItemId,
              );
          }
        });
      }
    }

    for (const studentId in addingDiff) {
      const studentChildItemIds = addingDiff[studentId];

      for (const price in studentChildItemIds) {
        const childItemIds = studentChildItemIds[price];

        childItemIds.forEach((childItemId) => {
          const studentBnlBackgroundSelection =
            bnlBackgroundSelection[studentId];

          if (!bnlBackgroundSelection[studentId]) {
            bnlBackgroundSelection[studentId] = {};
          }

          if (
            bnlBackgroundSelection[studentId][price] &&
            !bnlBackgroundSelection[studentId][price].includes(childItemId)
          ) {
            bnlBackgroundSelection[studentId][price].push(childItemId);
          } else {
            bnlBackgroundSelection[studentId][price] = [childItemId];
          }
        });
      }
    }

    // delete key value pairs with empty value
    for (const studentId in bnlBackgroundSelection) {
      for (const price in bnlBackgroundSelection[studentId]) {
        if (bnlBackgroundSelection[studentId][price].length === 0) {
          delete bnlBackgroundSelection[studentId][price];
        }
      }

      if (Object.keys(bnlBackgroundSelection[studentId]).length === 0) {
        delete bnlBackgroundSelection[studentId];
      }
    }

    this.props.storeBnlBackgroundSelection(bnlBackgroundSelection);
  }

  removeProductLockGroup(studentId, cartItem) {
    const orderState = this.props.cart.orders[studentId];
    const lockGroupsCounter = {};

    cartItem.lock_group.forEach((set) => {
      lockGroupsCounter[set] = 1;
    });

    orderState.cartItems.forEach((item) => {
      if (item.id !== cartItem.id && item.lock_type === 'unlocker') {
        item.lock_group.forEach((set) => {
          if (lockGroupsCounter[set]) lockGroupsCounter[set] += 1;
        });
      }
    });

    let bnlUnlockedGroups = this.cloneArray(this.props.cart.bnlUnlockedGroups);
    let { cartItems } = orderState;
    let containsRetouching = false;

    for (const set in lockGroupsCounter) {
      if (lockGroupsCounter[set] === 1) {
        bnlUnlockedGroups = this.removeElementFromArray(bnlUnlockedGroups, set);
        cartItems.forEach((cartItem) => {
          if (cartItem.is_retouching) containsRetouching = true;
        });
        const newCartItems = [];

        orderState.cartItems.forEach((cartItem) => {
          if (
            cartItem.lock_type !== 'unlockable' &&
            !cartItem.lock_group.includes(set) &&
            cartItem.lock_group.length !== 1
          ) {
            newCartItems.push(cartItem);
          }
        });

        cartItems = newCartItems;
      }
    }

    orderState.cartItems = cartItems;
    if (containsRetouching) {
      const bnlRetouching = this.cloneObject(this.props.cart.bnlRetouching);
      delete bnlRetouching[studentId];

      this.props.updateBnlRetouching(bnlRetouching);
    }

    this.setState(orderState, () => {
      this.storeStudentOrder(studentId, orderState);
      this.props.updateBnlUnlockedGroups(bnlUnlockedGroups);
    });
  }

  removeCartItem(studentId, cartItem) {
    const orderState = this.props.cart.orders[studentId];
    const currentStudent = this.getCurrentStudentFromAdditionalData();
    const cartItems = [];
    let offersRetouching = 0;

    const { removePersonalizationOnOrder, shoot } = this.props;

    if (this.shootIsBnlShoot()) {
      if (cartItem.offers_retouching) {
        orderState.cartItems.forEach((cartItem) => {
          if (cartItem.offers_retouching) offersRetouching += 1;
        });
      }

      const bnlPromptRetouching = this.cloneObject(
        this.props.cart.bnlPromptRetouching,
      );
      const bnlRetouching = this.cloneObject(this.props.cart.bnlRetouching);

      let retouchingStudentId;

      if (this.props.bnlPreShoot.preShoot) {
        retouchingStudentId = studentId;
      } else {
        if (currentStudent) {
          retouchingStudentId = currentStudent.id;
        } else {
          retouchingStudentId = 'postShoot';
        }
      }

      if (offersRetouching === 1 || cartItem.is_retouching) {
        delete bnlPromptRetouching[studentId];
        delete bnlRetouching[studentId];

        this.props.updateBnlRetouching(bnlRetouching);
        this.props.updateBnlPromptRetouching(bnlPromptRetouching);
      }

      let trackingStudentId;

      if (this.shootIsBnlPreShoot()) {
        trackingStudentId = studentId;
      } else {
        if (currentStudent) {
          trackingStudentId = currentStudent.id;
        } else {
          trackingStudentId = 'postShoot';
        }
      }

      this.removeProductLockGroup(studentId, cartItem);
      this.removeCartItemIdFromBnlBackgroundSelection(
        orderState,
        trackingStudentId,
        cartItem,
      );

      const bnlFreeBackgrounds = this.cloneObject(
        this.props.cart.bnlFreeBackgrounds,
      );

      if (bnlFreeBackgrounds[trackingStudentId]) {
        const newCartItemIds = [];

        bnlFreeBackgrounds[trackingStudentId].forEach((cartItemId) => {
          if (cartItemId !== cartItem.id) newCartItemIds.push(cartItemId);
        });

        if (newCartItemIds.length === 0) {
          delete bnlFreeBackgrounds[trackingStudentId];
        }

        this.props.updateBnlFreeBackgrounds(bnlFreeBackgrounds);
      }
    }

    // clear personalization value for student/order if removing
    // personalization product from the cart
    if (
      shoot.personalizationProduct &&
      shoot.personalizationProduct.id === cartItem.id
    ) {
      removePersonalizationOnOrder(studentId);
    }

    orderState.cartItems.forEach((item) => {
      // add items with ids that aren't matching removing item id
      if (item.id != cartItem.id) {
        cartItems.push(item);
      }
    });

    orderState.cartItems = cartItems;
    orderState.cartItemsTotalCost =
      this.calculateCartItemsTotalCost(orderState);

    if (
      cartItem.product_type === 'regular_product' ||
      cartItem.product_type === 'nth_free'
    ) {
      delete orderState.bnlSelectedBackgroundOptions[cartItem.id];
    } else {
      const options =
        orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id];
      const removingChildItemIds = [];
      // collect removing child item ids
      for (const index in options) {
        const childItemConfig = options[index];
        removingChildItemIds.push(childItemConfig.childItem.id);
      }

      // store non removing child item ids
      const newBuildYourOwnCartItems = [];

      orderState.buildYourOwnCartItems.forEach((item) => {
        if (!removingChildItemIds.includes(item.id)) {
          newBuildYourOwnCartItems.push(item);
        }
      });

      orderState.buildYourOwnCartItems = newBuildYourOwnCartItems;
      delete orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id];
    }

    orderState.includedProducts = [];

    this.setState(orderState, () => {
      if (cartItems.length === 0) {
        this.props.removeOrder(studentId, this.props.cart.orders);
      } else {
        let cartContainsNthFree = false;
        let nthFreeQualifyingInCart = 0;
        let nthFreeThreshold = 9999;
        let nthFreeCartItem;

        for (let i = 0, len = cartItems.length; i < len; i++) {
          const cartItem = cartItems[i];

          if (cartItem.product_type && cartItem.product_type === 'nth_free') {
            cartContainsNthFree = true;
            nthFreeCartItem = cartItem;
          }

          if (cartItem.nth_free_sku && cartItem.nth_free_sku !== '') {
            nthFreeQualifyingInCart += cartItem.count;
          }

          if (
            cartItem.nth_free_threshold &&
            cartItem.nth_free_threshold !== 0
          ) {
            if (cartItem.nth_free_threshold < nthFreeThreshold) {
              nthFreeThreshold = cartItem.nth_free_threshold;
            }
          }
        }

        if (cartContainsNthFree && nthFreeQualifyingInCart < nthFreeThreshold) {
          this.removeCartItem(studentId, nthFreeCartItem);
          return;
        }

        if (
          !cartContainsNthFree &&
          nthFreeQualifyingInCart >= nthFreeThreshold
        ) {
          this.props.updateOrderActiveComponent(
            ORDER_NAVIGATION.NTH_FREE_PRODUCTS,
          );
          return;
        }
      }

      this.checkForIncentive(orderState, true);
    });

    this.updateCartCount();

    if (
      this.shootIsGeskusShoot() &&
      this.props.shoot.shoot.ui_version == 'ui_v1'
    ) {
      let cartItemsCount = 0;

      for (const studentId in this.props.cart.orders) {
        const orderState = this.props.cart.orders[studentId];
        cartItemsCount += orderState.cartItems.length;
      }

      if (cartItemsCount === 0) {
        this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
      }
    }
  }

  checkBnlProductRequirement(shootProduct) {
    const bnlUnlockedGroups = this.cloneArray(
      this.props.cart.bnlUnlockedGroups,
    );
    // TODO:
    // revisit
    if (shootProduct.lock_type === 'unlocker') {
      shootProduct.lock_group.forEach((set) => {
        if (!bnlUnlockedGroups.includes(set)) bnlUnlockedGroups.push(set);
      });

      this.props.updateBnlUnlockedGroups(bnlUnlockedGroups);
      return true;
    }

    if (shootProduct.lock_type === 'unlockable') {
      let unlocked = false;

      shootProduct.lock_group.forEach((set) => {
        if (bnlUnlockedGroups.includes(set)) unlocked = true;
      });

      if (!unlocked) {
        Swal.fire(
          'This is an add-on product',
          'Please add a qualifying product to your cart to unlock add-on items.',
        ).then((value) => {
          if (value.value) {
            this.props.updateOrderActiveComponent(
              ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
            );
          }
        });
        console.log('returning false from checkBnlProductRequirement');
        return false;
      }

      console.log('returning true 1 from checkBnlProductRequirement');
      return true;
    }

    console.log('returning true 2 from checkBnlProductRequirement');
    return true;
  }

  updateCartCount() {
    let count = 0;

    for (const studentId in this.props.cart.orders) {
      const orderState = this.props.cart.orders[studentId];
      count += orderState.cartItems.length;
    }

    this.props.updateCartCount(count);
  }

  setBnlPromptRetouching(promptBoolean, retouchingSelected) {
    const {
      additionalData: { selectedStudent },
      // order: { currentStudentId },
    } = this.props;

    // const currentStudent = await this.getCurrentStudentFromAdditionalData();

    console.log('%cSET BNL PROMPT RETOUCHING', 'color: lime');
    console.log(selectedStudent);
    console.log(selectedStudent?.id);
    // promptBoolean
    // false = don't prompt user
    // true = prompt user
    const bnlPromptRetouching = this.cloneObject(
      this.props.cart.bnlPromptRetouching,
    );

    let studentId;

    if (this.props.bnlPreShoot.preShoot) {
      studentId = this.state.studentId;

      if (studentId === undefined && selectedStudent) {
        studentId = selectedStudent.id;
      }
    } else {
      if (selectedStudent) {
        studentId = selectedStudent.id;
      } else {
        studentId = 'postShoot';
      }
    }

    bnlPromptRetouching[studentId] = promptBoolean;

    this.props.updateBnlPromptRetouching(bnlPromptRetouching);

    if (this.hasIncludedProducts()) {
      this.goToConfiguration(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
    } else {
      // Send 'true' to indicate retouching has been declined
      console.log('%cCHECK FOR BNL INCENTIVE 4', 'color: lime');
      // console.log('%cCHECK FOR BNL INCENTIVE', 'color: lime');
      this.checkForBnlIncentive(undefined, undefined, true);
    }
  }

  setInterstitialPromptedForCurrentOrder() {
    const { updateInterstitialPromptedForCurrentOrder } = this.props;
    const currentStudent = this.getCurrentStudentFromAdditionalData();
    let studentId;

    if (this.props.bnlPreShoot.preShoot) {
      studentId = this.props.bnlPreShoot.selectedStudent.id;
    } else {
      if (currentStudent) {
        studentId = currentStudent.id;
      } else {
        studentId = 'postShoot';
      }
    }

    updateInterstitialPromptedForCurrentOrder(studentId);

    if (this.hasIncludedProducts()) {
      this.goToConfiguration(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
    } else {
      // TODO: do we need to set 'true' here?
      // Send 'true' to indicate retouching has been declined
      console.log('%cCHECK FOR BNL INCENTIVE 5', 'color: lime');
      this.checkForBnlIncentive(undefined, undefined, true);
    }
  }

  editIncentiveBackground(incentiveCartItem, studentId) {
    const orderState = this.props.cart.orders[studentId];
    orderState.incentiveConfiguration[incentiveCartItem.id].configured = false;

    this.setState(orderState, () => {
      this.props.updateOrderActiveComponent(
        ORDER_NAVIGATION.GESKUS_INCENTIVE_CONFIGURATION,
      );
    });
  }

  checkForIncentive(orderState, fromCart = false) {
    switch (this.props.shoot.shoot.company_auth_token) {
      case GESKUS:
        if (this.props.shoot.shoot.ui_version === 'ui_v1') {
          this.checkForGeskusIncentive(orderState, fromCart);
        } else {
          console.log('%cCHECK FOR BNL INCENTIVE 6', 'color: lime');
          this.checkForBnlIncentive(orderState, fromCart);
        }
        return;
      case BNL:
      case JELLYJAR:
      case GB:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        console.log('%cCHECK FOR BNL INCENTIVE 7', 'color: lime');
        this.checkForBnlIncentive(orderState, fromCart);
        return;
      default:
        console.log('%cCHECK FOR BNL INCENTIVE 8', 'color: lime');
        this.checkForBnlIncentive(orderState, fromCart);
    }
  }

  checkForGeskusIncentive(orderState = this.state, fromCart) {
    const that = this;
    const { formattedIncentiveProducts } = this.props.shoot;
    const thresholds = Object.keys(formattedIncentiveProducts);
    thresholds.sort();

    const { geskusIncentive } = this.props;
    let qualifiedForOnlyFreeShipping = true;
    let qualifiedForAll = false;
    let qualifying = false;

    Sentry.setContext('checkForGeskusIncentive', {
      cartItems: this.state.incentiveCartItems,
      cartLevelItems: geskusIncentive.cartLevelItems,
      orderState,
    });

    let cartItems = this.cloneArray(this.state.incentiveCartItems);
    let cartLevelItems = this.cloneArray(geskusIncentive.cartLevelItems);
    const configuration = this.cloneObject(this.state.incentiveConfiguration);

    let maxCount = 0;
    Object.values(formattedIncentiveProducts).forEach((incentiveItemsArray) => {
      maxCount += incentiveItemsArray.length;
    });

    if (cartItems.length === maxCount) {
      qualifiedForAll = true;
    }

    thresholds.forEach((incentiveThreshold) => {
      const incentiveItems = formattedIncentiveProducts[incentiveThreshold];
      incentiveItems.forEach((incentiveItem) => {
        let total;

        if (
          incentiveItem.is_free_shipping &&
          total >= thresholds &&
          this.props.geskusIncentive.freeShipping
        ) {
          return;
        }

        if (this.incentiveItemAppliesToCart(incentiveItem)) {
          total = this.calculateTotalForIncentive();
        } else if (this.incentiveItemAppliesToOrder(incentiveItem)) {
          total = this.calculateOrderTotalForIncentive();
        }

        if (total >= parseInt(incentiveThreshold)) {
          let duplicate = false;
          qualifying = true;

          if (!incentiveItem.is_free_shipping) {
            qualifiedForOnlyFreeShipping = false;
          }

          if (!qualifiedForAll) {
            cartItems.forEach((cartItem) => {
              if (incentiveItem.id === cartItem.id) duplicate = true;
            });

            if (this.incentiveItemAppliesToCart(incentiveItem)) {
              cartLevelItems.forEach((cartLevelItem) => {
                if (incentiveItem.id === cartLevelItem.id) duplicate = true;
              });
            }

            if (!duplicate) {
              if (this.incentiveItemAppliesToCart(incentiveItem)) {
                cartItems.push(incentiveItem);
                cartLevelItems.push(incentiveItem);
                if (incentiveItem.is_free_shipping) {
                  that.props.updateGeskusFreeShipping(true);
                }
              } else if (this.incentiveItemAppliesToOrder(incentiveItem)) {
                cartItems.push(incentiveItem);
              }

              if (!configuration[incentiveItem.id]) {
                let configured = false;
                if (incentiveItem.pics_product_type === 'special_product') {
                  configured = true;
                }

                configuration[incentiveItem.id] = {
                  backgroundCodes: [],
                  configured,
                };
              }
            }
          }
        } else {
          // remove unqualified item from cart and configuration
          const newCartItems = [];
          const newCartLevelItems = [];

          cartItems.forEach((cartItem) => {
            if (cartItem.id !== incentiveItem.id) newCartItems.push(cartItem);
          });

          cartLevelItems.forEach((cartLevelItem) => {
            if (incentiveItem.id !== cartLevelItem.id) {
              newCartLevelItems.push(cartLevelItem);
            }
          });

          cartItems = newCartItems;
          cartLevelItems = newCartLevelItems;
          delete configuration[incentiveItem.id];

          if (incentiveItem.is_free_shipping) {
            this.props.updateGeskusFreeShipping(false);
          }
        }
      });
    });

    orderState.incentiveCartItems = cartItems;
    orderState.incentiveConfiguration = configuration;

    let notConfiguredCount = 0;
    for (const incentiveItemId in orderState.incentiveConfiguration) {
      const itemConfig = orderState.incentiveConfiguration[incentiveItemId];
      if (!itemConfig.configured) notConfiguredCount += 1;
    }

    this.props.updateGeskusIncentiveCartLevelItems(cartLevelItems);
    this.setState(orderState, () => {
      if (
        notConfiguredCount > 0 &&
        qualifying &&
        !qualifiedForAll &&
        !qualifiedForOnlyFreeShipping
      ) {
        Swal.fire(
          'Congratulations, you qualify for a bonus item!',
          'Please configure your bonus item',
        );

        this.props.updateOrderActiveComponent(
          ORDER_NAVIGATION.GESKUS_INCENTIVE_CONFIGURATION,
        );
      }

      // UNCOMMENT HERE
      // if (orderState.cartItems.length > 0) {
      this.storeStudentOrder(orderState.studentId, orderState);
      // }
    });

    if (!fromCart) {
      this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
    }
  }

  // TODO: add studentId to the incentive cart items
  async checkForBnlIncentive(
    localState = this.state,
    fromCart = false,
    retouchingDeclined = false,
  ) {
    console.log(
      '%cCHECK FOR BNL INCENTIVE',
      'color: pink; background-color: red; font-weight: bold;',
    );
    console.log(this.props.bnlIncentive);
    console.log('--------------------');

    const { additionalData, bnlIncentive, cart, shoot } = this.props;
    const { formattedIncentiveProducts } = this.props.shoot;
    let cartItems;
    let cartLevelItems = this.cloneArray(bnlIncentive.cartLevelItems);
    let configuration;
    let qualifiedForAll = false;
    let qualifying = false;
    let retouchingAllowedByPreviouslyAddedProduct = true; // by default, assume that retouching is allowed
    let retouchOrderState;
    let requiresImage = false;
    const that = this;

    console.log(bnlIncentive);
    console.log('++++++++++++');

    const thresholds = Object.keys(formattedIncentiveProducts);
    thresholds.sort();

    // Look at the most-recently-added product to see if it doesn't offer/allow retouching...
    if (additionalData?.mostRecentlyAddedProduct?.offers_retouching === false) {
      retouchingAllowedByPreviouslyAddedProduct = false;
    }

    // console.log('%c----------------------------', 'color: yellow');
    // console.log('1_formattedIncentive', formattedIncentiveProducts);
    // console.log('checking for incentive products...');
    // console.log('1_ checking for incentive with state', localState);
    // console.log(this.props);
    // console.log('%c----------------------------', 'color: lime');

    if (this.state.studentId) {
      retouchOrderState = this.props.cart.orders[this.state.studentId];
    }

    const retouchCart = this.props.cart;

    if (retouchingDeclined || !retouchingAllowedByPreviouslyAddedProduct) {
      // do nothing
    } else if (retouchOrderState && retouchOrderState.studentId) {
      if (
        retouchCart.bnlPromptRetouching &&
        Object.keys(retouchCart.bnlPromptRetouching).includes(
          retouchOrderState.studentId.toString(),
        )
      ) {
        if (
          retouchCart.bnlPromptRetouching[retouchOrderState.studentId] === true
        ) {
          this.props.selectShootProduct(
            this.props.shoot.retouchingProduct,
            true,
          );
          return;
        }
      }
    }

    cartItems = this.cloneArray(bnlIncentive.cartLevelItems);
    configuration = this.cloneObject(this.props.bnlIncentive.configuration);

    if (cartItems.length === this.props.shoot.incentiveProducts.length) {
      qualifiedForAll = true;
    }

    console.log('%cINCENTIVE CONFIGURATION AT THIS POINT', 'color: cyan');
    console.log('configuration', configuration);
    console.log('--------------------------');

    console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++');

    // const shouldOfferRetouchingOrInterstitialValue =
    //   await this.shouldOfferRetouchingOrInterstitial();

    // in

    console.log('++++++++++++++++++++++++++++++++++++++++++++');

    // TODO: revisit this -- can post-event BNL incentives line up with this logic?
    // if (
    //   this.shootIsBnlPreShoot() ||
    //   this.props.shoot.shoot.company_auth_token === 'geskus'
    // ) {
    //   cartItems = this.cloneArray(this.state.incentiveCartItems);
    //   configuration = this.cloneObject(this.state.incentiveConfiguration);
    //   let incentiveOrderLevelCartItems = 0;

    //   cartItems.forEach((cartItem) => {
    //     if (cartItem.incentive_applies_to === 'order_level') {
    //       incentiveOrderLevelCartItems += 1;
    //     }
    //   });

    //   if (
    //     incentiveOrderLevelCartItems + cartLevelItems.length ===
    //     this.props.shoot.incentiveProducts.length
    //   ) {
    //     qualifiedForAll = true;
    //   }
    // } else {
    //   cartItems = this.cloneArray(bnlIncentive.cartLevelItems);
    //   configuration = this.cloneObject(this.props.bnlIncentive.configuration);

    //   if (cartItems.length === this.props.shoot.incentiveProducts.length) {
    //     qualifiedForAll = true;
    //   }
    // }

    Sentry.setContext('checkForBnlIncentive', {
      cartItems,
      cartLevelItems,
      configuration,
      formattedIncentiveProducts,
      preShoot: this.shootIsBnlPreShoot(),
    });

    thresholds.forEach((incentiveThreshold) => {
      formattedIncentiveProducts[incentiveThreshold].forEach(
        (incentiveProduct) => {
          incentiveProduct.studentId = this.state.studentId;

          let total;

          console.log('1_INCENTIVE_PRODUCT', incentiveProduct);

          if (this.incentiveItemAppliesToCart(incentiveProduct)) {
            console.log('2_calculateTotalForIncentive');
            total = this.calculateTotalForIncentive();
          } else if (this.incentiveItemAppliesToOrder(incentiveProduct)) {
            console.log('2_calculateTotalOrderForIncentive');
            total = this.calculateOrderTotalForIncentive();
          }

          console.log('1_total is', total, incentiveThreshold);

          if (total >= parseInt(incentiveThreshold)) {
            // console.log(total);
            // console.log(incentiveThreshold);
            let duplicate = false;
            qualifying = true;

            if (!qualifiedForAll) {
              // console.log('OK we are not qualified for all...');
              // console.log(incentiveProduct);
              cartItems.forEach((cartItem) => {
                if (cartItem.id === incentiveProduct.id) duplicate = true;
              });

              console.log('1_cartLevelItems', cartLevelItems);

              if (this.incentiveItemAppliesToCart(incentiveProduct)) {
                cartLevelItems.forEach((cartLevelItem) => {
                  if (incentiveProduct.id === cartLevelItem.id) {
                    duplicate = true;
                  }
                });
              }

              if (!duplicate) {
                if (incentiveProduct.requires_image) {
                  requiresImage = true;
                }

                console.log('1_incentive before pushed', incentiveProduct);

                if (this.incentiveItemAppliesToCart(incentiveProduct)) {
                  // console.log('applies to cart -- 11');
                  console.log('1_incentive to be pushed', incentiveProduct);
                  cartItems.push(incentiveProduct);
                  cartLevelItems.push(incentiveProduct);
                  if (incentiveProduct.is_free_shipping) {
                    that.props.updateBnlFreeShipping(true);
                  }
                } else if (this.incentiveItemAppliesToOrder(incentiveProduct)) {
                  // console.log('applies to order -- 11');
                  cartItems.push(incentiveProduct);
                }

                if (!configuration[incentiveProduct.id]) {
                  // console.log('currently not in configuration!! 11');
                  let configured = false;
                  if (
                    incentiveProduct.pics_product_type === 'special_product'
                  ) {
                    configured = true;
                  }

                  configuration[incentiveProduct.id] = {
                    backgroundCodes: [],
                    configured,
                    poseIds: [],
                  };
                }
              }
            }
          } else {
            // remove unqualified item from cart and configuration
            const newCartItems = [];
            const newCartLevelItems = [];

            cartItems.forEach((cartItem) => {
              if (cartItem.id !== incentiveProduct.id) {
                newCartItems.push(cartItem);
              }
            });

            cartLevelItems.forEach((cartLevelItem) => {
              if (incentiveProduct.id !== cartLevelItem.id) {
                newCartLevelItems.push(cartLevelItem);
              }
            });

            cartItems = newCartItems;
            cartLevelItems = newCartLevelItems;
            delete configuration[incentiveProduct.id];

            console.log('LINE 5085: incentiveProduct', incentiveProduct);

            if (incentiveProduct.is_free_shipping) {
              console.log('LINE 5086: incentiveProduct.is_free_shipping');
              this.props.updateBnlFreeShipping(false);
            }
          }
        },
      );
    });

    // console.log('now we are here 22');
    // console.log(cartItems);

    console.log('CONFIGURATION ON LINE 4938', configuration);

    if (qualifying && !qualifiedForAll) {
      // alert('qualifying and !qualified for all');
      console.log('qualifying and !qualified for all');
      console.log(requiresImage);
      if (requiresImage) {
        const incentiveItemIds = Object.keys(configuration).map((id) =>
          parseInt(id),
        );

        // console.log('incentiveItemIds');
        // console.log(incentiveItemIds);
        // console.log(cartItems);

        cartItems.forEach((cartItem) => {
          // only preset config for new incentive items
          if (!incentiveItemIds.includes(cartItem.id)) {
            configuration[cartItem.id] = {
              backgroundCodes: [],
              configured: false,
              pose: {},
              poseIds: [],
              personalizationValue: '',
            };
          }
        });

        // console.log('configuration 33 ----------');
        // console.log(configuration);

        // Swal.fire(
        //   'Congratulations',
        //   'You qualify for a free bonus item. Please add your bonus item to your cart. You may need to select a background.',
        // );
      } else {
        console.log(
          '%cINSIDE THIS LOOP AND ABOUT TO SET TO TRUE?',
          'color: cyan;',
        );
        cartItems.forEach((cartItem) => {
          if (
            !cartItem.requires_image &&
            cartItem.pics_product_type === 'special_product' &&
            !configuration[cartItem.id]
          ) {
            configuration[cartItem.id] = {
              backgroundCodes: [],
              configured: true,
              pose: {},
              poseIds: [],
            };
          }
        });
      }
    } else if (!qualifying) {
      cartItems = [];
      configuration = {};
    }

    // console.log(
    // 'UPDATE BNL INCENTIVE ------------------------------------------------'
    // );
    // console.log(configuration);
    // console.log(cartLevelItems);

    console.log('%cUPDATE BNL INCENTIVE CONFIGURATION 2 - OFF', 'color: pink');
    console.log('configuration', configuration);
    console.log('%c------------------------------------', 'color: pink');

    // TODO: unwind this?
    this.props.updateBnlIncentiveConfiguration(configuration);
    this.props.updateBnlIncentiveCartLevelItems(cartLevelItems);

    let { studentId } = localState;

    // TODO: revist
    if (!studentId) studentId = this.props.parent.students[0].id;

    localState.incentiveCartItems = cartItems;
    localState.incentiveConfiguration = configuration;

    this.setState(localState, () => {
      let retouchingAllowedByPreviouslyAddedProduct = true; // by default, assume that retouching is allowed
      let renderIncentiveConfig = false;
      let nextComponent;

      // Look at the most-recently-added product to see if it doesn't offer/allow retouching...
      if (
        additionalData?.mostRecentlyAddedProduct?.offers_retouching === false
      ) {
        retouchingAllowedByPreviouslyAddedProduct = false;
      }

      console.log(
        '%cDETERMINING NEXT COMPONENT / renderIncentiveConfig',
        'color: cyan;',
      );

      for (const itemId in configuration) {
        const itemConfiguration = configuration[itemId];
        const studentId = additionalData?.selectedStudent?.id;
        const incentiveCartItems =
          this.props.cart?.orders?.[studentId]?.incentiveCartItems || [];

        let incentiveCartItemToExamine = null;

        console.log('🦒🦒🦒🦒🦒🦒🦒🦒🦒🦒🦒🦒🦒🦒');

        // Check if incentiveCartItems is an array before attempting to loop through it
        if (Array.isArray(incentiveCartItems)) {
          incentiveCartItems.forEach((incentiveCartItemForReview) => {
            if (incentiveCartItemForReview.id === parseInt(itemId, 10)) {
              incentiveCartItemToExamine = incentiveCartItemForReview;
            }
          });
        }

        if (
          !itemConfiguration.configured &&
          !incentiveCartItemToExamine?.is_free_shipping
        ) {
          renderIncentiveConfig = true;
          // const shouldOfferRetouchingOrInterstitialValue =
          //   await that.shouldOfferRetouchingOrInterstitial();
          // console.log('%cshouldOfferRetouchingOrInterstitial', 'color: cyan;');
          // console.log(shouldOfferRetouchingOrInterstitialValue);
          // console.log('$$$$');

          // Swal.fire(
          //   'Congratulations',
          //   'You qualify for a free bonus item. Please add your bonus item to your cart. You may need to select a background.',
          // );
        }
      }

      if (this.state.editingFromCart) {
        if (renderIncentiveConfig) {
          console.log(
            '%cORDER_NAVIGATION.INCENTIVE_CONFIGURATION - 1',
            'color: pink;',
          );
          nextComponent = ORDER_NAVIGATION.INCENTIVE_CONFIGURATION;
        } else {
          // console.log('%cTROUBLESHOOT - Nav 14', 'color: red');

          nextComponent = ORDER_NAVIGATION.BNL_CART;
        }
        this.setState({ editingFromCart: false }, () => {
          this.storeStudentOrder(studentId, this.state);
          this.toggleNotification('Item Edit Saved');
        });
      } else if (!fromCart) {
        if (renderIncentiveConfig) {
          console.log(
            '%cORDER_NAVIGATION.INCENTIVE_CONFIGURATION - 2',
            'color: pink;',
          );
          nextComponent = ORDER_NAVIGATION.INCENTIVE_CONFIGURATION;
        } else if (
          retouchingAllowedByPreviouslyAddedProduct &&
          this.props.order.shouldBeOfferedRetouching &&
          !this.props.order.hasOfferedRetouching &&
          (this.props.shoot.retouchingProduct ||
            (this.props.shoot.retouchingProducts &&
              this.props.shoot.retouchingProducts.length > 0))
        ) {
          // prompt retouching
          this.props.orderHasOfferedRetouching();
          console.log('%cPROMPT ROI 1', 'color: orange');
          this.promptRetouchingOrInterstitial();

          nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;

          if (
            this.state.recommendProducts &&
            this.props.shoot.suggestedProductsLinkToIndex
          ) {
            // console.log('%cTROUBLESHOOT III - Nav 5', 'color: red');
            nextComponent = ORDER_NAVIGATION.PRODUCT_RECS;
          }

          // TODO: return?
        } else if (
          retouchingAllowedByPreviouslyAddedProduct &&
          shoot.interstitialProducts !== undefined &&
          shoot.interstitialProducts.length > 0 &&
          this.props.cart.promptInterstitial &&
          this.props.cart.promptInterstitial[studentId] === undefined
          // this.props.cart.promptInterstitial.postShoot === undefined
        ) {
          console.log('%cPROMPT ROI 2', 'color: orange');
          this.promptRetouchingOrInterstitial();

          nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;

          if (
            this.state.recommendProducts &&
            this.props.shoot.suggestedProductsLinkToIndex
          ) {
            // console.log('%cTROUBLESHOOT III - Nav 2', 'color: red');
            nextComponent = ORDER_NAVIGATION.PRODUCT_RECS;
          }

          // console.log(
          //   'nextComponent set!! -- checkForBnlIncentive()',
          //   nextComponent,
          // );
        } else if (
          this.state.recommendProducts &&
          this.props.shoot.suggestedProductsLinkToIndex
        ) {
          // console.log('%cTROUBLESHOOT III - Nav 1', 'color: red');
          nextComponent = ORDER_NAVIGATION.PRODUCT_RECS;
        } else {
          nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        }

        console.log('%cPROMPT ROI 3.2', 'color: orange');
        this.promptRetouchingOrInterstitial();

        // Swal.fire(
        //   'Congratulations',
        //   'You qualify for a free bonus item. Please add your bonus item to your cart. You may need to select a background.',
        // );

        this.storeStudentOrder(studentId, this.state);
      } else if (fromCart) {
        if (renderIncentiveConfig) {
          console.log(
            '%cORDER_NAVIGATION.INCENTIVE_CONFIGURATION - 3',
            'color: pink;',
          );
          nextComponent = ORDER_NAVIGATION.INCENTIVE_CONFIGURATION;
          this.setState({ configuringFromCart: true }, () => {
            this.props.updateOrderActiveComponent(nextComponent);
          });
        } else {
          let cartItemsCount = 0;

          for (const studentId in this.props.cart.orders) {
            const orderState = this.props.cart.orders[studentId];
            cartItemsCount += orderState.cartItems.length;
          }

          // TODO: revisit this.
          // In some cases, 'fromCart' is the product's object and not a boolean...
          // unclear if this is intended or not
          if (typeof fromCart === 'object' && fromCart.is_retouching) {
            if (
              this.state.recommendProducts &&
              this.props.shoot.suggestedProductsLinkToIndex
            ) {
              // console.log('%cTROUBLESHOOT III - Nav 3', 'color: red');
              nextComponent = ORDER_NAVIGATION.PRODUCT_RECS;
            } else {
              nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
            }
          } else if (cartItemsCount === 0) {
            nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
          } else {
            // console.log('%cTROUBLESHOOT - Nav 15', 'color: red');

            nextComponent = ORDER_NAVIGATION.BNL_CART;
          }
        }
      } else {
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
      }

      this.props.updateOrderActiveComponent(nextComponent);
    });
  }

  // store order state if user happens to not click "DONE" in add ons
  // add ons states will not be stored in this case
  addCurrentOrderToReduxCart() {
    this.storeStudentOrder(this.state.studentId, this.state);
    this.resetYouPickXCartItems();
    // TODO:
    // check if i also have to reset configuration
  }

  removeYouPickXItem(itemId) {
    const configuration = this.cloneObject(this.state.youPickXConfiguration);
    delete configuration[itemId];

    this.setState({ youPickXConfiguration: configuration });
  }

  removeBuildYourOwnItem(removingItemId, slotIndex) {
    const newCart = [];

    this.state.buildYourOwnCartItems.forEach((cartItem) => {
      if (cartItem.id !== removingItemId) newCart.push(cartItem);
    });

    const buildYourOwnSelectedBackgroundOptions = this.cloneObject(
      this.state.buildYourOwnSelectedBackgroundOptions,
    );

    let newSlotIndex = 0;
    const newOption = {};
    const shootProduct =
      this.state.selectedShootProduct || this.props.shootProduct;
    const currentByoOption =
      buildYourOwnSelectedBackgroundOptions[shootProduct.id];

    if (currentByoOption) {
      for (const oldSlotIndex in currentByoOption) {
        newOption[newSlotIndex] = currentByoOption[oldSlotIndex];
        newSlotIndex += 1;
      }
    }

    buildYourOwnSelectedBackgroundOptions[shootProduct.id] = newOption;

    this.setState(
      {
        buildYourOwnCartItems: newCart,
        buildYourOwnSelectedBackgroundOptions,
      },
      () => {
        this.storeStudentOrder(this.state.studentId, this.state);
      },
    );
  }

  resetYouPickXCartItems() {
    this.setState({ youPickXCartItems: [] });
  }

  applyPhotoEnhancement(goToPersonalization) {
    this.setState(
      {
        photoEnhancement: 'BASIC',
        photoEnhancementApplied: true,
        renderAddOns: !(this.state.personalizationApplied && true),
      },
      () => {
        this.setState(
          { photoEnhancementCost: this.calculatePhotoEnhancementCost() },
          () => {
            if (
              !this.state.renderAddOns ||
              this.state.personalizationApplied ||
              !goToPersonalization
            ) {
              this.checkForIncentive();
            }
            this.storeStudentOrder(this.state.studentId, this.state);
          },
        );
      },
    );
  }

  applyPersonalization() {
    // let nextComponent = ORDER_NAVIGATION['PRODUCT_GROUPS'];
    const { photoEnhancementApplied } = this.state;

    this.setState(
      {
        personalization: true,
        personalizationApplied: true,
        personalizationStatus: null,
        personalizationValue: this.state.personalizationValue,
        renderAddOns: !(photoEnhancementApplied && true),
      },
      () => {
        this.setState(
          {
            personalizationCost: this.calculatePersonalizationCost(),
          },
          () => {
            this.storeStudentOrder(this.state.studentId, this.state);
            this.checkForIncentive();
            // this.props.updateOrderActiveComponent(nextComponent);
          },
        );
      },
    );
  }

  skipPersonalization() {
    this.setState({ personalizationStatus: null }, () => {
      this.keepShopping();
    });
  }

  editPersonalization() {
    this.setState(
      {
        personalizationEdit: false,
      },
      () => {
        this.storeStudentOrder(this.state.studentId, this.state);
      },
    );
  }

  removePersonalization(orderState) {
    orderState.personalization = 'false';
    orderState.personalizationValue = '';
    orderState.personalizationApplied = false;
    orderState.renderAddOns = !(false & this.state.photoEnhancementApplied);

    this.setState(orderState, () => {
      this.setState(
        { personalizationCost: this.calculatePersonalizationCost() },
        () => {
          this.checkForIncentive();
        },
      );
    });
  }

  removePhotoEnhancement(orderState) {
    orderState.photoEnhancement = 'NONE';
    orderState.photoEnhancementApplied = false;
    orderState.renderAddOns = !(false & this.state.personalizationApplied);

    this.setState(orderState, () => {
      this.setState(
        { photoEnhancementCost: this.calculatePhotoEnhancementCost() },
        () => {
          this.checkForIncentive();
        },
      );
    });
  }

  editQuantity(event, cartItem, orderState) {
    const updatingCartItem = this.cloneObject(cartItem);
    updatingCartItem.count = parseInt(event.target.value);
    updatingCartItem.totalPrice = parseInt(event.target.value) * cartItem.price;

    const updatingCartItems = [];

    orderState.cartItems.forEach((cartItem) => {
      if (cartItem.id !== updatingCartItem.id) {
        updatingCartItems.push(cartItem);
      } else {
        updatingCartItems.push(updatingCartItem);
      }
    });

    orderState.cartItems = updatingCartItems;
    orderState.cartItemsTotalCost =
      this.calculateCartItemsTotalCost(orderState);

    const fromCart = true;
    this.setState(orderState, () => {
      let cartContainsNthFree = false;
      let nthFreeQualifyingInCart = 0;
      let nthFreeThreshold = 9999;
      let nthFreeCartItem;

      // Check for nth-free
      // TODO: get this check into a shared function
      orderState.cartItems.forEach((cartItem) => {
        if (cartItem.product_type && cartItem.product_type === 'nth_free') {
          cartContainsNthFree = true;
          nthFreeCartItem = cartItem;
        }

        if (cartItem.nth_free_sku && cartItem.nth_free_sku !== '') {
          nthFreeQualifyingInCart += cartItem.count;
        }

        if (cartItem.nth_free_threshold && cartItem.nth_free_threshold !== 0) {
          if (cartItem.nth_free_threshold < nthFreeThreshold) {
            nthFreeThreshold = cartItem.nth_free_threshold;
          }
        }
      });

      if (cartContainsNthFree && nthFreeQualifyingInCart < nthFreeThreshold) {
        this.removeCartItem(orderState.studentId, nthFreeCartItem);
        return;
      }

      if (!cartContainsNthFree && nthFreeQualifyingInCart >= nthFreeThreshold) {
        this.props.updateOrderActiveComponent(
          ORDER_NAVIGATION.NTH_FREE_PRODUCTS,
        );
        return;
      }

      this.checkForIncentive(orderState, fromCart);
    });
  }

  goToPersonalization(studentId) {
    const orderState = this.props.cart.orders[studentId];
    orderState.personalizationEdit = true;

    this.setState(orderState, () => {
      this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_ADDONS);
    });
  }

  getBnlShootDataParams() {
    let shootKey;
    let paramsStudentId;

    if (this.shootIsBnlPreShoot()) {
      paramsStudentId = this.props.bnlPreShoot.selectedStudent.id;
    } else {
      const galleries = this.props.gallery.galleries[this.props.gallery.cpmId];

      for (const studentId in galleries) {
        const studentGallery = galleries[studentId];

        if (!shootKey && studentGallery.gallery.length > 0) {
          shootKey = studentGallery.gallery[0].gallery_key;
          paramsStudentId = studentGallery.student.id;
        }
      }
    }

    return {
      student_id: paramsStudentId,
      shoot_key: shootKey,
      grade_code: 'IDK',
    };
  }

  updateCheckoutInformation(data) {
    const that = this;

    this.setState(
      {
        parentAddress: data.parentAddress,
        parentEmail: data.parentEmail,
        submitting: true,
      },
      () => {
        const parentId = this.props.auth.currentUserId;
        const address = {
          address_1: this.state.parentAddress.address1,
          address_2: this.state.parentAddress.address2,
          city: this.state.parentAddress.city,
          state: this.state.parentAddress.state,
          zipcode: this.state.parentAddress.zipcode,
        };

        const email = { email: this.state.parentEmail };

        this.props.updateAddress(parentId, address).then(() => {
          that.props.updateEmail(parentId, email).then((resp) => {
            that.props.setCurrentUser();
            if (that.props.shoot.shoot.company_auth_token === JELLYJAR) {
              const data = that.getBnlShootDataParams();

              that.props.getShootData(data);
            }
          });
        });
      },
    );
  }

  updateCheckoutSmsPhone() {
    const that = this;
    const data = { sms_phone: this.state.smsPhone };
    const id = this.props.auth.currentUserId;

    if (!this.state.smsPhone) {
      Swal.fire('Please fill in a phone number');
    } else {
      axios
        .patch(`/api/v2/parents/${id}`, data)
        .then((response) => {
          that.props.setSmsPhone(response.data.sms_phone);
          const orderState = that.state;
          orderState.smsPhone = response.data.sms_phone.split('+')[1].slice(1);
          that.storeStudentOrder(orderState.studentId, orderState);
        })
        .catch((error) => {
          if (error.response) {
            that.setState({
              smsErrorMessage: error.response.data.errors[0],
              submitting: false,
            });
          }
        });
    }
  }
  // order modification end //

  // order submit //
  parseModifiedId(id) {
    // return parseInt(id.split('_')[0]);
    return Number.isInteger(id) ? id : parseInt(id.split('_')[0]);
  }

  getPoseIds(orderState, cartItem) {
    const poseIds = [];

    if (
      !this.shootIsBnlPreShoot() &&
      this.shootIsBnlShoot() &&
      cartItem.requires_configuration
    ) {
      const selectedBackgroundOptions = orderState.bnlSelectedBackgroundOptions;
      const configuration = selectedBackgroundOptions[cartItem.id];

      for (const poseIndex in configuration) {
        const innerConfig = configuration[poseIndex];
        const { backgroundCodes } = innerConfig;
        const ids = innerConfig.poseIds;
        const poseId = innerConfig.pose.id;
        const poseObj = {};

        poseObj[poseId] = { background_codes: innerConfig.backgroundCodes };
        poseIds.push(poseObj);
      }
    }

    return poseIds;
  }

  getBnlOrderItemPersonalization(orderState, cartItem) {
    const personalization = [];
    const options = orderState.bnlSelectedBackgroundOptions[cartItem.id];

    for (const poseIndex in options) {
      if (personalization.length === 0) {
        const configuration = options[poseIndex];
        const obj = {};

        let parsedPersonzliationValue = null;

        if (configuration.personalizationValue) {
          parsedPersonzliationValue = configuration.personalizationValue
            .split(' ')
            .map((word, i) =>
              word
                .split('')
                .map((letter, i) => {
                  if (i === 0) {
                    return letter.toUpperCase();
                  }
                  return letter.toLowerCase();
                })
                .join(''),
            )
            .join(' ');
        }

        if (this.shootIsBnlPreShoot()) {
          obj[`preShootPose-${poseIndex}`] = parsedPersonzliationValue;
        } else if (cartItem.requires_configuration && configuration.pose) {
          obj[configuration.pose.id] = parsedPersonzliationValue;
        }

        personalization.push(obj);
      }
    }

    return personalization;
  }

  getBnlOrderItemAdditionalAttributes(orderState, cartItem) {
    let additionalAttributes = {};
    const options = orderState.bnlSelectedBackgroundOptions[cartItem.id];

    // Assumes that additional attributes are stored on one of the pose indexes -- sets
    // to the first one found.
    for (const poseIndex in options) {
      const poseConfig = options[poseIndex];

      if (poseConfig.additionalAttributes) {
        if (
          Object.keys(poseConfig.additionalAttributes).length > 0 &&
          Object.keys(additionalAttributes).length === 0
        ) {
          additionalAttributes = Object.assign(
            {},
            poseConfig.additionalAttributes,
          );
        }
      }
    }

    return additionalAttributes;
  }

  getOrderItemChildrenData(orderState, cartItem) {
    const children = [];

    if (cartItem.product_type === 'parent_product') {
      if (
        this.props.shoot.shoot.company_auth_token === 'GESKUS' &&
        this.props.shoot.shoot.ui_version === 'ui_v1'
      ) {
        Object.values(
          orderState.youPickXSelectedBackgroundOptions[cartItem.id],
        ).forEach((youPickXCartItem) => {
          const child = {
            id: this.parseModifiedId(youPickXCartItem.childItem.id),
            quantity: 1,
            parent_id: this.parseModifiedId(youPickXCartItem.parentId),
            green_screen_background_codes: youPickXCartItem.backgroundCodes,
          };
          children.push(child);
        });
      } else {
        Object.values(
          orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id],
        ).forEach((buildYourOwnCartItem) => {
          const child = {
            id: this.parseModifiedId(buildYourOwnCartItem.childItem.id),
            quantity: 1,
            parent_id: this.parseModifiedId(buildYourOwnCartItem.parentId),
            pose_ids: buildYourOwnCartItem.poseIds,
            green_screen_background_codes: buildYourOwnCartItem.backgroundCodes,
            green_screen_background_prices:
              buildYourOwnCartItem.backgroundPrices,
          };

          children.push(child);
        });
      }
    }

    return children;
  }

  getPersonalization(orderState) {
    const { studentId } = orderState;
    const { cart } = this.props;

    if (
      cart.personalization &&
      cart.personalization[studentId] &&
      cart.personalization[studentId] !== ''
    ) {
      return cart.personalization[studentId];
    }

    if (orderState.personalizationApplied) {
      return orderState.personalizationValue;
    }

    return '';
  }

  getIncentiveCartItems(orderState, studentIdIndex) {
    const studentId = orderState.studentId;

    const incentiveCartItems = orderState.incentiveCartItems?.reduce(
      (result, incentiveCartItem) => {
        if (
          incentiveCartItem.studentId &&
          studentId &&
          incentiveCartItem.studentId !== studentId
        ) {
          // do nothing
        } else {
          result.push(incentiveCartItem);
        }

        return result;
      },
      [],
    );

    return incentiveCartItems;
  }

  getIncentiveItemConfiguration(orderState, handledBnlIncentive) {
    const authToken = this.props.shoot.shoot.company_auth_token;

    if (
      authToken === 'GEKUS' &&
      this.props.shoot.shoot.ui_version === 'ui_v1'
    ) {
      return this.getGeskusIncentiveConfiguration(
        orderState.incentiveConfiguration,
      );
    } else {
      if (this.props.bnlPreShoot.preShoot) {
        return this.getBnlIncentiveConfiguration(
          orderState.incentiveConfiguration,
        );
      }

      return this.getBnlIncentiveConfigurationWithOrderState(orderState);
    }

    // TODO: remove when confirmed that this is not needed

    // switch (this.props.shoot.shoot.company_auth_token) {
    //   case GESKUS:
    //     if (this.props.shoot.shoot.ui_version === 'ui_v1') {
    //       return this.getGeskusIncentiveConfiguration(
    //         orderState.incentiveConfiguration,
    //       );
    //     }

    //     if (this.props.bnlPreShoot.preShoot) {
    //       return this.getBnlIncentiveConfiguration(
    //         orderState.incentiveConfiguration,
    //       );
    //     }

    //     if (orderState.incentiveConfiguration) {
    //       return this.getBnlIncentiveConfigurationWithOrderState(orderState);
    //     }

    //     if (!handledBnlIncentive) {
    //       return this.getBnlIncentiveConfigurationWithOrderState(orderState);
    //     }

    //     return {};
    //   case BNL:
    //   case JELLYJAR:
    //   case GB:
    //   case NORMANDY:
    //   case TIPPING:
    //   case MARCEL:
    //   case CLASSACT:
    //   case WAGNER:
    //   case BIELMAR:
    //     if (this.props.bnlPreShoot.preShoot) {
    //       return this.getBnlIncentiveConfiguration(
    //         orderState.incentiveConfiguration,
    //       );
    //     }

    //     return this.getBnlIncentiveConfigurationWithOrderState(orderState);

    //   // if (!handledBnlIncentive) {
    //   //   return this.getBnlIncentiveConfigurationWithOrderState(orderState);
    //   // }

    //   // return {};

    //   default:
    //     return this.getBnlIncentiveConfigurationWithOrderState(orderState);

    //     if (this.props.bnlPreShoot.preShoot) {
    //       return this.getBnlIncentiveConfigurationWithOrderState(orderState);
    //     }

    //     if (!handledBnlIncentive) {
    //       return this.getBnlIncentiveConfigurationWithOrderState(orderState);
    //     }
    //     return {};
    // }
  }

  getGeskusIncentiveConfiguration(configuration) {
    const formattedConfiguration = {};

    for (const incentiveItemId in configuration) {
      formattedConfiguration[incentiveItemId] = {};
      const itemConfig = configuration[incentiveItemId];
      const formattedItemConfig = formattedConfiguration[incentiveItemId];

      formattedItemConfig.background_codes = itemConfig.backgroundCodes;
    }

    return formattedConfiguration;
  }

  getBnlIncentiveConfiguration(configuration) {
    const formattedConfiguration = {};

    for (const incentiveItemId in configuration) {
      formattedConfiguration[incentiveItemId] = {};

      const itemConfig = configuration[incentiveItemId];
      const formattedItemConfig = formattedConfiguration[incentiveItemId];
      formattedItemConfig.background_codes = itemConfig.backgroundCodes;
      formattedItemConfig.pose_ids = itemConfig.poseIds;
      formattedItemConfig.personalization_value =
        itemConfig.personalizationValue || null;
    }

    return formattedConfiguration;
  }

  getBnlIncentiveConfigurationWithOrderState(orderState) {
    const { incentiveCartItems, incentiveConfiguration, studentId } =
      orderState;
    const formattedConfiguration = {};

    for (let incentiveCartItem of incentiveCartItems) {
      // we only want to build the configuration for the current student
      if (
        incentiveCartItem.studentId &&
        incentiveCartItem.studentId !== studentId
      ) {
        continue;
      }

      const incentiveCartItemId = incentiveCartItem.id;

      if (incentiveCartItemId && incentiveConfiguration[incentiveCartItemId]) {
        const itemConfig = incentiveConfiguration[incentiveCartItemId];
        formattedConfiguration[incentiveCartItemId] = {};
        const formattedItemConfig = formattedConfiguration[incentiveCartItemId];

        formattedItemConfig.background_codes = itemConfig.backgroundCodes;
        formattedItemConfig.pose_ids = itemConfig.poseIds;
        formattedItemConfig.personalization_value =
          itemConfig.personalizationValue || null;
      }
    }

    return formattedConfiguration;
  }

  getFormattedOrdersData() {
    const { cart, bnlPreShoot } = this.props;
    const that = this;
    const orders = [];
    const studentIds = Object.keys(this.props.cart.orders);
    const sortedStudentIds = Object.values(this.props.cart.orders)
      .sort((a, b) => a.createdAt - b.createdAt)
      .map((order) => order.studentId.toString());
    let handledBnlIncentive = false;

    sortedStudentIds.forEach((studentId, i) => {
      Sentry.setContext('getFormattedOrdersData', {
        bnlPreShoot,
        cart,
        studentId,
      });

      const studentIdIndex = i;
      const orderState = this.props.cart.orders[studentId];
      let gradeCode;
      let teacherName;
      let teacherSalutation;
      let homeRoom;

      if (this.props.bnlPreShoot.preShoot) {
        const { studentsDetails } = this.props.bnlPreShoot;

        Sentry.setContext('getFormattedOrdersDataBnlPreShoot', {
          studentsDetails,
        });

        gradeCode = studentsDetails[studentId]?.grade;
        teacherName = studentsDetails[studentId]?.teacherLastName;
        teacherSalutation = studentsDetails[studentId]?.teacherSalutation;
        homeRoom = studentsDetails[studentId]?.homeRoom;

        if (!gradeCode) {
          Sentry.captureMessage('missing grade code');
        }
      } else {
        gradeCode = orderState.gradeCode;
        teacherName = orderState.teacherLastName;
        teacherSalutation = '';
        homeRoom = '';
      }

      if (gradeCode === 'SELECT A GRADE' || gradeCode === "I DON'T KNOW") {
        gradeCode = 'IDK';
      }

      if (orderState.cartItems.length > 0) {
        let shootKeyForSubmit;

        if (orderState.keyEntry) {
          shootKeyForSubmit = orderState.keyEntry;
        } else if (orderState.shootKey) {
          shootKeyForSubmit = orderState.shootKey;
        } else {
          shootKeyForSubmit = that.props.shoot.shoot.key;
        }

        const order = {
          student_id: orderState.studentId,
          shoot_key: shootKeyForSubmit, // TODO: revist
          grade_code: gradeCode,
          teacher_name: teacherName,
          teacher_salutation: teacherSalutation,
          home_room: homeRoom,
          items: orderState.cartItems.map((item, i) => ({
            background_option_price: 0, // TODO: properly calculate
            id: Number.isInteger(item.id)
              ? item.id
              : parseInt(item.id.split('_')[0]),
            quantity: item.count,
            background_codes: that._getItemBackgroundCodes(orderState, item),
            additional_background_codes: that._getItemAdditionalBackgroundCodes(
              orderState,
              item,
            ),
            pose_ids: that.getPoseIds(orderState, item),
            children: that.getOrderItemChildrenData(orderState, item),
            personalization: that.getBnlOrderItemPersonalization(
              orderState,
              item,
            ),
            additional_attributes: that.getBnlOrderItemAdditionalAttributes(
              orderState,
              item,
            ),
          })),
          background_color: orderState.selectedBackgroundColor,
          photo_enhancement: orderState.photoEnhancement,
          personalization: that.getPersonalization(orderState),
          incentive_cart_items: that.getIncentiveCartItems(
            orderState,
            studentIdIndex,
          ),
          incentive_item_configuration: that.getIncentiveItemConfiguration(
            orderState,
            handledBnlIncentive,
          ),
        };

        handledBnlIncentive = true;
        orders.push(order);
      }
    });

    return orders;
  }

  getBnlShipping() {
    if (this.companyIsBnlCompany()) {
      return this.props.cart.bnlShipping;
    }

    return null;
  }

  getGeskusFreeShipping() {
    if (this.companyIsGeskus()) {
      return this.props.geskusIncentive.freeShipping;
    }

    return null;
  }

  getBnlFreeShipping() {
    if (this.companyIsBnlCompany()) {
      return this.props.bnlIncentive.freeShipping;
    }

    return null;
  }

  getSelectedBackgrounds() {
    switch (this.props.shoot.shoot.company_auth_token) {
      // case GESKUS:
      //   return {};
      case BNL:
      case JELLYJAR:
      case GB:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        return this.props.cart.bnlBackgroundSelection;
      default:
        return this.props.cart.bnlBackgroundSelection;
    }
  }

  getFreeBackgrounds() {
    switch (this.props.shoot.shoot.company_auth_token) {
      // case GESKUS:
      //   return {};
      case BNL:
      case JELLYJAR:
      case GB:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        return this.props.cart.bnlFreeBackgrounds;
      default:
        return this.props.cart.bnlFreeBackgrounds;
    }
  }

  getDiscountCode() {
    switch (this.props.shoot.shoot.company_auth_token) {
      // case GESKUS:
      //   return null;
      case GESKUS:
      case BNL:
      case GB:
      case JELLYJAR:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        if (this.props.discountCode.valid) {
          return {
            amount_off: this.props.discountCode.amountOff,
            code: this.props.discountCode.code,
            discount_code_id: this.props.discountCode.discountCodeId,
            percent_off: this.props.discountCode.percentOff,
            valid: this.props.discountCode.valid,
          };
        }
        return null;

      default:
        return null;
    }
  }

  buildOrderJSON(token) {
    let cardToken = token;
    const parentAddress = this.props.auth.currentUserAddress;
    const personalizationValue =
      this.state.personalization === 'true'
        ? this.state.personalizationValue
        : '';

    if (cardToken === '') {
      cardToken = this.state.cardToken;
    }

    const order = {
      stripe_token: cardToken,
      // TODO: handle possibility that parent doesn't have self_address set
      shipping_info: {
        address1: parentAddress.address_1,
        address2: parentAddress.address_2,
        city: parentAddress.city,
        state: parentAddress.state,
        zipcode: parentAddress.zipcode,
      },
      orders: this.getFormattedOrdersData(),
      cart: {
        total: this.calculateTotal(),
        background: this.calculateBnlTotalBackgroundPrice(),
        tax: this.calculateTotalTax(),
        discount: this.calculateTotalDiscount(), // family discount
        discount_balance: this.props.shoot.shootPricing.discount_balance,
        discount_code: this.getDiscountCode(),
        shipping: this.calculateShipping(),
        grand_total: this.calculateGrandTotal(),
      },
      bnl_pre_shoot: this.props.bnlPreShoot.preShoot,
      bnl_shipping: this.getBnlShipping(),
      geskus_free_shipping: this.getGeskusFreeShipping(),
      bnl_free_shipping: this.getBnlFreeShipping(),
      selected_backgrounds: this.getSelectedBackgrounds(),
      free_backgrounds: this.getFreeBackgrounds(),
      shoot_pricing: this.props.shoot.shootPricing,
      temp_dp2_shoot_id: this.props.shoot.shoot.id,
    };
    return order;
  }

  addPaymentToken(token, last4, brand) {
    this.setState({
      cardToken: token,
      cardLast4: last4,
      cardBrand: brand,
    });

    // Submit the order
    this.submitOrder(token);
  }

  submitOrder(token = '') {
    const that = this;
    const data = this.buildOrderJSON(token);
    const uninterceptedAxiosInstance = axios.create();

    this.setState(
      {
        submitting: true,
        errors: {},
        cardErrorMessage: '',
      },
      () => {
        uninterceptedAxiosInstance
          .post('/api/v2/orders', data)
          .then((response) => {
            that.setState({ submitting: false }, () => {
              if (response.data.success) {
                that.props.storeOrderContainerOrderId(
                  response.data.order_container_order_id,
                );
                that.resetState(this.baseState);
                that.props.updateGeskusFreeShipping(false);
                that.props.updateBnlFreeShipping(false);
                that.props.updateOrderActiveComponent(
                  ORDER_NAVIGATION.ORDER_CONFIRMATION,
                );
              } else {
                Sentry.setContext('submitOrder', {
                  response,
                  error: response.error,
                  message: response.message,
                });
              }
            });
          })
          .catch((error) => {
            Sentry.setContext('submitOrderError', {
              error: error.response,
            });

            const errorMessagePrefix =
              '<p>There was an error submitting your order. Your card was not charged.</p>';

            let errorMessageBody =
              '<p>If re-submitting does not work, please try logging out and back in. If that does not resolve the error, please contact customer support.</p>';

            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              Sentry.setContext('submitOrderErrorMessage', {
                message: error.response.data.message,
              });

              errorMessageBody = `<p>Details: <em>${error.response.data.message.toLowerCase()}</em></p>`;
            }

            Sentry.setContext('submitOrderErrorObject', {
              error,
            });

            console.log(error.response);
            that.setState({ submitting: false }, () => {
              Swal.fire({
                title: 'Error',
                html: `${errorMessagePrefix}${errorMessageBody}`,
              });
            });
          });
      },
    );
  }

  formError(data) {
    const formErrors = [];
    const response = JSON.parse(data.responseText);
    if (response.errors) {
      // TODO: this is something of a hack to align with how the iOS/Android apps are
      // handling this particular error... ideally, we'd drive this messaging from the API.
      // if ( response.errors[0] === "pre-orders closed" ) {
      //   formErrors.push(this.SHOOT_KEY_EXPIRED_MESSAGE)
      // } else {
      response.errors.map((error) => {
        formErrors.push(error);
      });
      // }
    }
    this.setState({ errors: formErrors });
  }
  // order submit end //

  resetState(baseState) {
    this.setState(baseState, () => {
      this.props.resetCart();
      this.props.resetBnlIncentive();
      this.props.resetGeskusIncentive();
      this.props.resetDiscountCode();
      this.props.resetAdditionalData();
    });
  }

  cartIsNotEmpty() {
    return Object.keys(this.props.cart.orders).length > 0;
  }

  // navigation //
  goToDashboard() {
    this.props.history.push('/dashboard');
    this.props.updateOrderActiveComponent(null);
  }

  goToCheckout() {
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.CHECKOUT);
  }

  goToBnlCart() {
    // console.log('%cTROUBLESHOOT - Nav 16', 'color: red');

    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_CART);
  }

  goToBnlProductGroups() {
    this.setState({ productGroupActiveComponent: 1 }, () => {
      this.props.updateOrderActiveComponent(
        ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
      );
    });
  }

  goToBuildYourOwn(productGroup) {
    let shootProduct;

    productGroup.products.forEach((product) => {
      console.log('the product', product);
      if (product.product_type === 'parent_product') shootProduct = product;
    });

    this.selectShootProduct(shootProduct);
  }

  goToClassPhoto(productGroup) {
    this.selectShootProduct(productGroup.products[0]);
  }

  toggleYouPickXConfiguration() {
    const state = {};
    state.additionalSelectedBackgroundOptions = this.cloneObject(
      this.state.additionalSelectedBackgroundOptions,
    );
    state.youPickXConfiguring = !this.state.youPickXConfiguring;

    // configure non configured bonus items if present
    if (
      this.state.selectedShootProduct
        .additional_product_background_images_count > 0
    ) {
      if (
        !state.additionalSelectedBackgroundOptions[
          this.state.selectedShootProduct.id
        ]
      ) {
        state.additionalSelectedBackgroundOptions[
          this.state.selectedShootProduct.id
        ] = {
          codes: [this.props.shoot.backgroundOptions[0].id],
          prices: [this.props.shoot.backgroundOptions[0].price],
        };
      }
    }

    this.setState(state);
  }

  keepShopping() {
    let nextComponent;
    switch (this.props.shoot.shoot.company_auth_token) {
      case GESKUS:
        if (this.props.shoot.shoot.ui_version === 'ui_v1') {
          nextComponent = ORDER_NAVIGATION.PRODUCT_GROUPS;
        } else {
          nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        }
        break;
      case BNL:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case JELLYJAR:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case GB:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case NORMANDY:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case TIPPING:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case MARCEL:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case CLASSACT:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case WAGNER:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      case BIELMAR:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
      default:
        nextComponent = ORDER_NAVIGATION.BNL_PRODUCT_GROUPS;
        break;
    }

    this.props.updateOrderActiveComponent(nextComponent);
  }

  // check this
  navigateToComponent(component) {
    this.props.updateOrderActiveComponent(component);
  }

  renderActiveComponent() {
    const selectedShootProduct =
      this.state.selectedShootProduct || this.props.shootProduct;
    let incentiveConfiguration;
    const { shoot } = this.props;

    console.log('RENDER ACTIVE COMPONENT');
    console.log(this.props.orderNavigation.step);

    switch (this.props.orderNavigation.step) {
      case ORDER_NAVIGATION.SHOOT_KEY_FORM:
        return (
          <OrderShootKeyForm
            errors={this.state.errors}
            gradeCode={this.state.gradeCode}
            handleOnChange={this.handleOnChange}
            submitShootKeyForm={this.submitShootKeyForm}
            shootKey={this.state.shootKey}
            renderNextComponent={this.renderNextComponent}
            studentName={this.state.studentName}
            teacherLastName={this.state.teacherLastName}
          />
        );
      case ORDER_NAVIGATION.SHOOT_CONFIRMATION:
        return (
          <OrderShootConfirmation
            studentName={this.state.studentName}
            shootName={this.state.shootName}
            confirmShoot={this.confirmShoot}
            renderPreviousComponent={this.renderPreviousComponent}
          />
        );
      case ORDER_NAVIGATION.PRODUCT_GROUPS:
        return (
          <OrderProductGroups
            displayPrice={this.displayPrice}
            selectShootProduct={this.selectShootProduct}
            shootProductGroups={this.props.shoot.shootProducts}
            getStudentImage={this.getStudentImage}
            shoot={this.props.shoot}
            studentPhotoPreviewUrl={this.state.studentPhotoPreviewUrl}
            additionalData={this.props.additionalData}
          />
        );
      case ORDER_NAVIGATION.BNL_PRODUCT_GROUPS:
        let currentOrderState;

        if (this.state.studentId) {
          currentOrderState = this.props.cart.orders[this.state.studentId];
        }

        return (
          <BnlOrderProductGroups
            activeComponent={this.state.productGroupActiveComponent}
            cartIsNotEmpty={this.cartIsNotEmpty}
            cart={this.props.cart}
            displayPrice={this.displayPrice}
            goToBnlCart={this.goToBnlCart}
            goToBnlProductGroups={this.goToBnlProductGroups}
            goToDashboard={this.goToDashboard}
            goToBuildYourOwn={this.goToBuildYourOwn}
            goToClassPhoto={this.goToClassPhoto}
            orderState={currentOrderState}
            promptRetouching={this.promptRetouchingOrInterstitial}
            selectProductGroup={this.selectProductGroup}
            selectedProductGroup={this.state.selectedProductGroup}
            selectShootProduct={this.selectShootProduct}
            shootProductGroups={this.props.shoot.shootProducts}
            shoot={this.props.shoot}
            updateOrderActiveComponent={this.props.updateOrderActiveComponent}
          />
        );
      case ORDER_NAVIGATION.NTH_FREE_PRODUCTS:
        return (
          <NthFreeProductsList
            activeComponent={this.state.productGroupActiveComponent}
            cartIsNotEmpty={this.cartIsNotEmpty}
            cartItems={this.state.cartItems}
            displayPrice={this.displayPrice}
            goToBnlCart={this.goToBnlCart}
            goToBnlProductGroups={this.goToBnlProductGroups}
            goToDashboard={this.goToDashboard}
            goToBuildYourOwn={this.goToBuildYourOwn}
            goToClassPhoto={this.goToClassPhoto}
            selectProductGroup={this.selectProductGroup}
            selectedProductGroup={this.state.selectedProductGroup}
            selectShootProduct={this.selectShootProduct}
            shootProductGroups={this.props.shoot.shootProducts}
          />
        );
      case ORDER_NAVIGATION.BNL_PRODUCT_CONFIGURATION:
        return (
          <BnlOrderProductConfigurationContainer
            addConfiguredBnlItemToCart={this.addConfiguredBnlItemToCart}
            cancelEdit={this.cancelEdit}
            cloneObject={this.cloneObject}
            configuration={this.state.bnlEditConfiguration}
            configuringIncludedProducts={this.hasIncludedProducts}
            displayPrice={this.displayPrice}
            editingFromCart={this.state.editingFromCart}
            errorMessage={this.state.smsErrorMessage}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            goBackToProductGroupsProducts={this.goBackToProductGroupsProducts}
            handleBnlConfigurationUnmount={this.handleBnlConfigurationUnmount}
            handleSmsInputChange={this.handleSmsInputChange}
            incentiveItemConfiguration={this.state.incentiveItemConfiguration}
            personalizationApplied={this.state.personalizationApplied}
            resetBnlProductConfiguration={this.resetBnlProductConfiguration}
            selectedShootProduct={this.props.shootProduct}
            setBnlPromptRetouching={this.setBnlPromptRetouching}
            setInterstitialPromptedForCurrentOrder={
              this.setInterstitialPromptedForCurrentOrder
            }
            setStudent={this.setStudent}
            smsPhone={this.state.smsPhone}
            storeBnlIncludedProductConfiguration={
              this.storeBnlIncludedProductConfiguration
            }
            studentId={this.state.studentId}
            submitSmsPhone={this.submitSmsPhone}
            updateUnmountedByBackButton={this.updateUnmountedByBackButton}
            BNL_FILTER={this.BNL_FILTER}
          />
        );
      case ORDER_NAVIGATION.PRODUCT_CONFIGURATION:
        return (
          <OrderProductConfiguration
            addConfiguredItemToCart={this.addConfiguredItemToCart}
            addCurrentOrderToReduxCart={this.addCurrentOrderToReduxCart}
            additionalSelectedBackgroundOptions={
              this.state.additionalSelectedBackgroundOptions
            }
            allBackgroundOptionsSelected={
              this.state.allBackgroundOptionsSelected
            }
            backgroundChoiceMode={this.props.shoot.shoot.background_choice_mode}
            backgroundOptions={this.props.shoot.backgroundOptions}
            backgroundOptionSelected={this.backgroundOptionSelected}
            checkForIncentive={this.checkForIncentive}
            configuration={this.state.configuration}
            configureItem={this.configureItem}
            displayPrice={this.displayPrice}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            getStudentImage={this.getStudentImage}
            handleOnChange={this.handleOnChange}
            history={this.props.history}
            metallicOption={this.state.metallicOption}
            navigateToComponent={this.navigateToComponent}
            personalizationApplied={this.state.personalizationApplied}
            photoEnhancementApplied={this.state.photoEnhancementApplied}
            renderAddOns={this.state.renderAddOns}
            resetConfiguration={this.resetConfiguration}
            selectedBackgroundOptions={this.state.selectedBackgroundOptions}
            selectedPose={this.state.selectedPose}
            shootProduct={this.state.selectedShootProduct}
            studentId={this.state.studentId}
            shoot={this.state.shoot}
            studentPhotoPreviewUrl={this.state.studentPhotoPreviewUrl}
          />
        );
      case ORDER_NAVIGATION.YOU_PICK_X:
        return (
          <OrderYouPickX
            additionalSelectedBackgroundOptions={
              this.state.additionalSelectedBackgroundOptions
            }
            addConfiguredYouPickXItemsToCart={
              this.addConfiguredYouPickXItemsToCart
            }
            backgroundChoiceMode={this.state.backgroundChoiceMode}
            backgroundOptionSelected={this.backgroundOptionSelected}
            backgroundOptions={this.props.shoot.backgroundOptions}
            configuration={this.state.configuration}
            configureAdditionalSelectedBackgroundOptions={
              this.configureAdditionalSelectedBackgroundOptions
            }
            configureItem={this.configureItem}
            configureYouPickXItem={this.configureYouPickXItem}
            displayPrice={this.displayPrice}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            getStudentImage={this.getStudentImage}
            history={this.props.history}
            idSuffix={this.state.idSuffix}
            incrementIdSuffix={this.incrementIdSuffix}
            navigateToComponent={this.navigateToComponent}
            removeYouPickXItem={this.removeYouPickXItem}
            renderAddOns={this.state.renderAddOns}
            resetYouPickXConfiguration={this.resetYouPickXConfiguration}
            resetYouPickXCartItems={this.resetYouPickXCartItems}
            selectedShootProduct={this.state.selectedShootProduct}
            toggleYouPickXConfiguration={this.toggleYouPickXConfiguration}
            youPickXBackgroundOptionSelected={
              this.youPickXBackgroundOptionSelected
            }
            youPickXCartItems={this.state.youPickXCartItems}
            youPickXConfiguration={this.state.youPickXConfiguration}
            youPickXConfiguring={this.state.youPickXConfiguring}
          />
        );
      case ORDER_NAVIGATION.BUILD_YOUR_OWN:
        const configuration = this.state.bnlEditConfiguration;

        return (
          <OrderBuildYourOwn
            addConfiguredBuildYourOwnItem={this.addConfiguredBuildYourOwnItem}
            addToByoIdSuffix={this.addToByoIdSuffix}
            backgroundChoiceMode={this.state.backgroundChoiceMode}
            byoIdSuffix={this.state.byoIdSuffix}
            calculateByoPrice={this.calculateByoPrice}
            cancelEdit={this.cancelEdit}
            cloneArray={this.cloneArray}
            cloneObject={this.cloneObject}
            configuration={configuration}
            displayPrice={this.displayPrice}
            editingFromCart={this.state.editingFromCart}
            idSuffix={this.state.idSuffix}
            navigateToComponent={this.navigateToComponent}
            productIsBnlYouPickX={this.productIsBnlYouPickX}
            removeBuildYourOwnItem={this.removeBuildYourOwnItem}
            renderAddOns={this.state.renderAddOns}
            handleBnlConfigurationUnmount={this.handleBnlConfigurationUnmount}
            selectedShootProduct={selectedShootProduct}
            selectPose={this.selectPose}
            setStudent={this.setStudent}
            studentId={this.state.studentId}
            errorMessage={this.state.smsErrorMessage}
            handleSmsInputChange={this.handleSmsInputChange}
            smsPhone={this.state.smsPhone}
            submitSmsPhone={this.submitSmsPhone}
            updateUnmountedByBackButton={this.updateUnmountedByBackButton}
          />
        );
      case ORDER_NAVIGATION.PRODUCT_RECS:
        return (
          <OrderProductRecs
            displayPrice={this.displayPrice}
            goToSuggestProductGroup={this.goToSuggestProductGroup}
            keepShopping={this.keepShopping}
            products={this.props.shoot.suggestedProducts}
            selectRecommendedProductAndSetProductGroup={
              this.selectRecommendedProductAndSetProductGroup
            }
            selectShootProduct={this.selectShootProduct}
          />
        );
      case ORDER_NAVIGATION.PRODUCT_ADDONS:
        return (
          <OrderProductAddons
            addCurrentOrderToReduxCart={this.addCurrentOrderToReduxCart}
            applyPersonalization={this.applyPersonalization}
            applyPhotoEnhancement={this.applyPhotoEnhancement}
            checkForIncentive={this.checkForIncentive}
            displayPrice={this.displayPrice}
            editPersonalization={this.editPersonalization}
            handleOnChange={this.handleOnChange}
            history={this.props.history}
            keepShopping={this.keepShopping}
            personalization={this.props.shoot.personalization}
            personalizationApplied={this.state.personalizationApplied}
            personalizationEdit={this.state.personalizationEdit}
            personalizationPrice={this.calculatePersonalizationCost()}
            personalizationStatus={this.state.personalizationStatus}
            personalizationValue={this.state.personalizationValue}
            photoEnhancement={this.state.photoEnhancement}
            photoEnhancementApplied={this.state.photoEnhancementApplied}
            photoEnhancements={this.props.shoot.photoEnhancements}
            shoot={this.state.shoot}
            skipPersonalization={this.skipPersonalization}
          />
        );
      case ORDER_NAVIGATION.INCENTIVE_CONFIGURATION:
        let cartItems;

        const {
          additionalData: { selectedStudent },
          cart: { orders },
        } = this.props;

        // console.log(
        //   'SETTING CART ITEMS BEFORE LOADING INCENTIVE CONFIGURATION CONTAINER'
        // );
        // console.log(this.shootIsBnlPreShoot());
        // console.log(this.state);
        // console.log(this.props);

        // console.log('xxx');
        // console.warn(this.state.incentiveConfiguration);
        // console.warn(this.props.bnlIncentive.configuration);
        // console.log('xxx');

        if (this.shootIsBnlPreShoot()) {
          cartItems = this.state.incentiveCartItems;
          incentiveConfiguration = this.state.incentiveConfiguration;
        } else {
          if (selectedStudent && orders[selectedStudent.id]) {
            cartItems = orders[selectedStudent.id].incentiveCartItems;
          } else {
            cartItems = this.state.incentiveCartItems;
          }

          // cartItems = this.state.incentiveCartItems;
          // incentiveConfiguration = this.state.incentiveConfiguration;
          // cartItems = this.props.bnlIncentive.cartLevelItems;
          incentiveConfiguration = this.props.bnlIncentive.configuration;
        }

        console.log(
          '%c============================================================',
          'color: cyan',
        );
        console.log(incentiveConfiguration);
        console.log(cartItems);
        console.log(
          '%c============================================================',
          'color: cyan',
        );

        // console.log(incentiveConfiguration);
        // console.log(cartItems);
        // console.log('x-x-x-x-x-x-x-x-x-x-x-x');

        return (
          <IncentiveConfigurationContainer
            cartItems={cartItems}
            cloneObject={this.cloneObject}
            configuration={incentiveConfiguration}
            configureBnlIncentiveItem={this.configureBnlIncentiveItem}
            displayPrice={this.displayPrice}
            incentiveConfiguration={incentiveConfiguration}
            preConfigureBnlIncentiveItem={this.preConfigureBnlIncentiveItem}
            errorMessage={this.state.smsErrorMessage}
            handleSmsInputChange={this.handleSmsInputChange}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            shouldOfferRetouchingOrInterstitial={
              this.shouldOfferRetouchingOrInterstitial
            }
            smsPhone={this.state.smsPhone}
            studentName={this.state.studentName}
            studentId={this.state.studentId}
            submitSmsPhone={this.submitSmsPhone}
            BNL_FILTER={this.BNL_FILTER}
          />
        );
      case ORDER_NAVIGATION.GESKUS_INCENTIVE_CONFIGURATION:
        return (
          <GeskusIncentiveConfigurationContainer
            addConfiguredGeskusIncentiveItem={
              this.addConfiguredGeskusIncentiveItem
            }
            backgroundChoiceMode={shoot.shoot.background_choice_mode}
            cartItems={this.state.incentiveCartItems}
            cloneObject={this.cloneObject}
            configuration={this.state.incentiveConfiguration}
            displayPrice={this.displayPrice}
            formattedBackgroundOptions={shoot.formattedBackgroundOptions}
            getStudentImage={this.getStudentImage}
          />
        );
      case ORDER_NAVIGATION.CART:
        return (
          <OrderCart
            discountTotal={this.calculateTotalDiscount()}
            displayPrice={this.displayPrice}
            displayNegativePrice={this.displayNegativePrice}
            editBackground={this.editBackground}
            editIncentiveBackground={this.editIncentiveBackground}
            editQuantity={this.editQuantity}
            goToPersonalization={this.goToPersonalization}
            editYouPickXBackgrounds={this.editYouPickXBackgrounds}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            keepShopping={this.keepShopping}
            personalization={this.props.shoot.personalization}
            personalizationApplied={this.state.personalizationApplied}
            photoEnhancements={this.props.shoot.photoEnhancements}
            photoEnhancementApplied={this.state.photoEnhancementApplied}
            quantity={this.state.quantity}
            removeCartItem={this.removeCartItem}
            removePersonalization={this.removePersonalization}
            removePhotoEnhancement={this.removePhotoEnhancement}
            renderNextComponent={this.renderNextComponent}
            subTotal={this.calculateTotal()}
            taxTotal={this.calculateTotalTax()}
            shoot={this.state.shoot}
            studentPhotoPreviewUrl={this.state.studentPhotoPreviewUrl}
          />
        );
      case ORDER_NAVIGATION.BNL_CART:
        if (this.shootIsBnlPreShoot()) {
          cartItems = this.state.incentiveCartItems;
          incentiveConfiguration = this.state.incentiveConfiguration;
        } else {
          cartItems = this.props.bnlIncentive.cartLevelItems;
          incentiveConfiguration = this.props.bnlIncentive.configuration;
        }

        return (
          <BnlOrderCart
            addConfiguredIncentiveItem={this.addConfiguredIncentiveItem}
            cloneObject={this.cloneObject}
            configureBnlIncentiveItem={this.configureBnlIncentiveItem}
            calculateCartItemBackgroundPrice={
              this.calculateCartItemBackgroundPrice
            }
            calculateCartItemDiscount={this.calculateCartItemDiscount}
            calculateStudentDiscount={this.calculateStudentDiscount}
            calculateShipping={this.calculateShipping}
            discountTotal={this.calculateTotalDiscount()}
            displayShippingPrice={this.displayShippingPrice}
            displayPrice={this.displayPrice}
            displayNegativePrice={this.displayNegativePrice}
            editBackground={this.editBackground}
            editBnlIncentiveBackground={this.editBnlIncentiveBackground}
            editQuantity={this.editQuantity}
            editYouPickXBackgrounds={this.editYouPickXBackgrounds}
            goToDashboard={this.goToDashboard}
            goToPersonalization={this.goToPersonalization}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            incentiveCartItems={this.state.incentiveCartItems}
            incentiveConfiguration={incentiveConfiguration}
            keepShopping={this.keepShopping}
            personalization={this.props.shoot.personalization}
            personalizationApplied={this.state.personalizationApplied}
            photoEnhancements={this.props.shoot.photoEnhancements}
            photoEnhancementApplied={this.state.photoEnhancementApplied}
            quantity={this.state.quantity}
            removeCartItem={this.removeCartItem}
            removePersonalization={this.removePersonalization}
            removePhotoEnhancement={this.removePhotoEnhancement}
            renderNextComponent={this.renderNextComponent}
            subTotal={this.calculateTotal()}
            taxTotal={this.calculateTotalTax()}
          />
        );
      case ORDER_NAVIGATION.CHECKOUT:
        return (
          <OrderCheckout
            addStudent={this.addStudent}
            addPaymentToken={this.addPaymentToken}
            calculateGrandTotal={this.calculateGrandTotal}
            cardErrorMessage={this.state.cardErrorMEssage}
            companyAuthToken={this.props.shoot.shoot.company_auth_token}
            companyIsBnlCompany={this.companyIsBnlCompany}
            discountTotal={this.calculateTotalDiscount()}
            displayShippingPrice={this.displayShippingPrice}
            displayPrice={this.displayPrice}
            displayNegativePrice={this.displayNegativePrice}
            errorMessage={this.state.smsErrorMessage}
            goToDashboard={this.goToDashboard}
            handleSmsInputChange={this.handleSmsInputChange}
            keepShopping={this.keepShopping}
            parentAddress={this.props.auth.currentUserAddress}
            parentEmail={this.props.auth.currentUserEmail}
            parentFirstName={this.props.auth.currentUserFirstName}
            parentLastName={this.props.auth.currentUserLastName}
            shippingPrice={this.calculateShipping()}
            smsPhone={this.state.smsPhone}
            stripePublishableKey={this.props.stripePublishableKey}
            submitting={this.state.submitting}
            submitOrder={this.submitOrder}
            updateCheckoutSmsPhone={this.updateCheckoutSmsPhone}
            subTotal={this.calculateTotal()}
            taxTotal={this.calculateTotalTax()}
            updateCheckoutInformation={this.updateCheckoutInformation}
          />
        );
      case ORDER_NAVIGATION.ORDER_CONFIRMATION:
        return (
          <OrderConfirmation
            companyAuthToken={this.props.shoot.shoot.company_auth_token}
            orderContainerOrderId={this.props.order.orderContainerOrderId}
          />
        );
    }
  }

  renderPreviousComponent(event, callback = null) {
    this.props.updateOrderActiveComponent(this.props.orderNavigation.step - 1);
    if (callback) callback();
  }

  renderNextComponent() {
    this.props.updateOrderActiveComponent(this.props.orderNavigation.step + 1);
  }

  getMarginTop() {
    if (this.props.orderNavigation.step > 2) {
      return 'margin-for-incentive-tracker';
    }

    return '';
  }

  renderIncentiveTracker() {
    if (this.props.orderNavigation.step > 2) {
      return (
        <IncentiveTracker
          calculateTotal={this.calculateTotal}
          calculateOrderTotal={this.calculateOrderTotalForIncentive}
          companyAuthToken={this.props.shoot.shoot.company_auth_tokens}
          displayPrice={this.displayPrice}
          geskusFreeShipping={this.props.geskusIncentive.freeShipping}
          incentiveProducts={this.props.shoot.incentiveProducts || []}
        />
      );
    }
  }

  // navigation end //
  render() {
    switch (this.props.shoot.shoot.company_auth_token) {
      // case GESKUS:
      //   if (!this.state.studentId || !this.state.studentName) {
      //     return <div id="loading" />;
      //   }
      //   break;
      case BNL:
      case JELLYJAR:
      case GB:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        break;
      default:
        break;
    }
    const marginTop = this.getMarginTop();
    const loading = this.state.loading ? 'loading' : 'hidden';

    return (
      <div className={`order-container ${marginTop}`}>
        <div className={loading}>
          <div className="spinner" />
        </div>
        <BannerMessage shoot={this.props.shoot.shoot} />
        {this.renderIncentiveTracker()}
        {this.renderActiveComponent()}
      </div>
    );
  }

  componentDidMount() {
    // TODO: revist when we run this.props.resetOrderRetouching() here, depending on configuration of
    // current retouching status? That is, de we offer once per order? Or multiple times,
    // if we can determine that a different student is being ordered for?

    const { shoot } = this.props;
    let eventKey = Cookies.get('pics_event_key');

    if (eventKey && eventKey !== '') {
      eventKey = eventKey.toUpperCase();

      if (
        this.props.orderNavigation.step &&
        this.props.orderNavigation.step === ORDER_NAVIGATION.PRODUCT_GROUPS
      ) {
        Cookies.remove('pics_event_key');
      } else {
        this.setState({ shootKey: eventKey });
      }
    }

    this.resetRetouchingOffer();

    // TODO: do we need this JELLYJAR-specic code?
    if (shoot.shoot.company_auth_token === JELLYJAR) {
      this.setState({
        backgroundChoiceMode: shoot.shoot.background_choice_mode,
        companyShippingCost: shoot.shootPricing.shipping_price,
        freeShippingThreshold: shoot.shootPricing.free_shipping_threshold,
        incentiveProducts: shoot.incentiveProducts,
        previousQualifyingOrdersForDiscount:
          shoot.shootPricing.previous_qualifying_orders_for_discount,
        pricingDiscount: shoot.shootPricing.discount_percent,
        shippingAppliedToCart: shoot.shootPricing.shipping_applied_to_cart,
        shippingAppliedToOrder: shoot.shootPricing.shipping_appied_to_order,
        shootKey: shoot.shoot.key,
        shootName: shoot.shoot.name,
        submitting: false,
        updated: true,
      });
    } else {
      this.setState({ submitting: false });
    }
  }

  // Reset retouching offer, based on cart
  resetRetouchingOffer() {
    const currentStudentId = this.props.bnlPreShoot?.selectedStudent?.id;

    // don't reset if the current student ID has an order in the
    // cart that contains retouching
    for (const order of Object.values(this.props.cart.orders)) {
      const { studentId } = order;
      for (const cartItem of order.cartItems) {
        if (cartItem.is_retouching) {
          if (currentStudentId && studentId === currentStudentId) {
            return;
          }
        }
      }
    }

    this.props.resetOrderRetouching();
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlIncentive: state.bnlIncentive,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    discountCode: state.discountCode,
    gallery: state.gallery,
    geskusIncentive: state.geskusIncentive,
    orderNavigation: state.orderNavigation,
    order: state.order,
    parent: state.parent,
    shoot: state.shoot,
    shootProduct: state.shootProduct,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acquireSms,
      getShootData,
      getStripePublishableKey,
      getStudents,
      formatAndSetBackgroundOptions,
      formatAndSetIncentiveProducts,
      orderHasOfferedRetouching,
      orderShouldOfferRetouching,
      removeOrder,
      removePersonalizationOnOrder,
      resetAdditionalData,
      resetBnlIncentive,
      resetGeskusIncentive,
      resetDiscountCode,
      resetCart,
      resetOrderRetouching,
      selectShootProduct,
      setAdditionalData,
      setBnlPreShootStudent,
      setPersonalizationOnOrder,
      setSmsPhone,
      setCurrentUser,
      storeBnlBackgroundSelection,
      storeOrder,
      storeOrderContainerOrderId,
      turnOnNotification,
      turnOffNotification,
      updateCartCount,
      updateAddress,
      updateBnlFreeBackgrounds,
      updateBnlIncentiveCartItems,
      updateBnlIncentiveCartLevelItems,
      updateBnlIncentiveConfiguration,
      updateBnlUnlockedGroups,
      updateBnlPromptRetouching,
      updateBnlRetouching,
      updateBnlFreeShipping,
      updateCurrentStudentId,
      updateDiscountCode,
      updateGeskusFreeShipping,
      updateGeskusIncentiveCartLevelItems,
      updateEmail,
      updateInterstitialPromptedForCurrentOrder,
      updateOrderActiveComponent,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
