import React from 'react';
import StudentOrder from './StudentOrder';

const CartContents = ({ orders }) => (
  <div className="bnl-cart-content">
    <ul className="bnl-order-content__cart-items">
      {Object.entries(orders).map(([studentId, order]) => (
        <StudentOrder key={studentId} studentId={studentId} order={order} />
      ))}
    </ul>
  </div>
);

export default CartContents;
