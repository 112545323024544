import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import OrderProductGroup from './order_product_group';
import OrderPhotoPreview from './orderPhotoPreview';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';

class OrderProductGroups extends Component {
  renderProductGroups() {
    const productGroups = this.props.shootProductGroups.map(
      (shootProductGroup, i) => {
        return (
          <OrderProductGroup
            name={shootProductGroup.name}
            displayPrice={this.props.displayPrice}
            key={`order-product-group-${i}`}
            products={shootProductGroup.products}
            selectShootProduct={this.props.selectShootProduct}
            {...this.props}
          />
        );
      }
    );

    return productGroups;
  }

  render() {
    return (
      <div>
        <OrderPhotoPreview {...this.props} />
        <div className="order-product-groups">{this.renderProductGroups()}</div>
      </div>
    );
  }

  componentDidMount() {
    // this.props.updateOrderActiveComponent(ORDER_NAVIGATION["PRODUCT_GROUPS"]);
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(null, mapDispatchToProps)(OrderProductGroups);
