/* eslint-disable radix */
import { getProductByLocation } from './store';

export const shouldBreakConfigLoop = (shootProducts, location) => {
  const product = getProductByLocation(shootProducts, location);
  const maxChildCount = product.maximum_children_count;
  const loc = location.pathname.split('/');
  const route = loc[loc.length - 1];

  if (maxChildCount > parseInt(route) || !maxChildCount) return false;

  return true;
};

export const test = () => {
  console.log('test');
  return true;
};
