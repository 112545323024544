import {
  RESET_ALL,
  UPDATE_CART_ITEMS,
  UPDATE_CONFIGURATION,
  UPDATE_CART_LEVEL_ITEMS,
  UPDATE_BNL_FREE_SHIPPING,
} from '../actions/action_bnl_incentive';

const INITIAL_STATE = {
  cartLevelItems: [],
  cartItems: [],
  configuration: {},
  freeShipping: false,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case RESET_ALL:
      console.log('RESET INCENTIVE STATE');
      console.log(INITIAL_STATE);
      return INITIAL_STATE;
    case UPDATE_CART_ITEMS:
      return { ...state, cartItems: action.payload };
    case UPDATE_CONFIGURATION:
      const newConfiguration = action.payload;

      if (newConfiguration) {
        const mergedConfiguration = {
          ...state.configuration,
          ...newConfiguration,
        };

        return {
          ...state,
          configuration: mergedConfiguration,
        };
      } else {
        return state;
      }
    case UPDATE_CART_LEVEL_ITEMS:
      return { ...state, cartLevelItems: action.payload };
    case UPDATE_BNL_FREE_SHIPPING:
      return { ...state, freeShipping: action.payload };
    default:
      return state;
  }
}
