import { omit } from 'lodash';

import {
  CONFIRM_USER_CONTACT_ADDRESS,
  REMOVE_ORDER,
  REMOVE_PERSONALIZATION_ON_ORDER,
  RESET_CART,
  SET_BNL_SHIPPING,
  SET_PERSONALIZATION_ON_ORDER,
  STORE_BNL_BACKGROUND_SELECTION,
  STORE_ORDER,
  UPDATE_BNL_UNLOCKED_GROUPS,
  UPDATE_BNL_FORMATTED_LOCK_GROUPS,
  UPDATE_BNL_RETOUCHING,
  UPDATE_BNL_PROMPT_RETOUCHING,
  UPDATE_BNL_FREE_BACKGROUNDS,
  UPDATE_CART_COUNT,
  UPDATE_RETOUCHING_PROMPTED_FOR_CURRENT_ORDER,
  UPDATE_INTERSTITIAL_PROMPTED_FOR_CURRENT_ORDER,
} from '../actions/action_cart';

const INITIAL_STATE = {
  bnlBackgroundSelection: {},
  bnlFormattedLockGroups: {},
  bnlFreeBackgrounds: {},
  bnlPromptRetouching: {},
  bnlRetouching: {},
  bnlShipping: 'school',
  bnlUnlockedGroups: [],
  count: 0,
  orders: {},
  personalization: {},
  promptInterstitial: {},
  userHasConfirmedAddress: false,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case STORE_ORDER:
      console.log('STORE_ORDER', action.payload);

      if (action.payload.studentId && action.payload.order.studentId) {
        console.log(
          `storing order for ${action.payload.studentId}`,
          action.payload.order,
        );

        const tmpOrderData = action.payload.order;
        const { additionalData } = action.payload;

        if (
          additionalData &&
          additionalData.keyEntry &&
          additionalData.eventName &&
          additionalData?.studentId === action.payload.studentId
        ) {
          tmpOrderData.keyEntry = additionalData.keyEntry;
          tmpOrderData.eventName = additionalData.eventName;

          if (additionalData.studentPreviewImageUrl) {
            tmpOrderData.studentPreviewImageUrl =
              additionalData.studentPreviewImageUrl;
          }
        }

        // Handle the incentiveConfiguration payload, which was submitting as a single config object and
        // creating a condition where incentive config objects 'waiting' for updated config data were being deleted before
        // they could be estasblished.
        // TODO: fix the submitted payload
        if (
          tmpOrderData.incentiveConfiguration &&
          state.orders[action.payload.studentId]
        ) {
          let oldIncentiveConfiguration =
            state.orders[action.payload.studentId].incentiveConfiguration;
          let newIncentiveConfiguration = tmpOrderData.incentiveConfiguration;

          Object.keys(newIncentiveConfiguration).forEach((key) => {
            oldIncentiveConfiguration[key] = newIncentiveConfiguration[key];
          });

          // After updating, assign it back to tmpOrderData.
          tmpOrderData.incentiveConfiguration = oldIncentiveConfiguration;
        }

        return {
          ...state,
          orders: {
            ...state.orders,
            [action.payload.studentId]: tmpOrderData,
          },
        };
      }
      return { ...state };
    case REMOVE_ORDER:
      return { ...state, orders: action.payload };
    case UPDATE_CART_COUNT:
      return { ...state, count: action.payload };
    case STORE_BNL_BACKGROUND_SELECTION:
      return { ...state, bnlBackgroundSelection: action.payload };
    case SET_BNL_SHIPPING:
      return { ...state, bnlShipping: action.payload };
    case CONFIRM_USER_CONTACT_ADDRESS:
      return { ...state, userHasConfirmedAddress: true };
    case UPDATE_BNL_UNLOCKED_GROUPS:
      return { ...state, bnlUnlockedGroups: action.payload };
    case UPDATE_BNL_FORMATTED_LOCK_GROUPS:
      return { ...state, bnlFormattedLockGroups: action.payload };
    case UPDATE_BNL_RETOUCHING:
      return { ...state, bnlRetouching: action.payload };
    case UPDATE_BNL_PROMPT_RETOUCHING:
      console.log('update bnl prompt retouching via legacy reducer');
      return { ...state, bnlPromptRetouching: action.payload };
    case UPDATE_RETOUCHING_PROMPTED_FOR_CURRENT_ORDER: {
      console.log('update bnl prompt retouching via new reducer');

      let studentId = action.payload;

      if (!studentId) {
        studentId = 'postShoot';
      }

      return {
        ...state,
        bnlPromptRetouching: {
          ...state.bnlPromptRetouching,
          [studentId]: true,
        },
      };
    }
    case UPDATE_INTERSTITIAL_PROMPTED_FOR_CURRENT_ORDER: {
      let studentId = action.payload;

      if (!studentId) {
        studentId = 'postShoot';
      }

      return {
        ...state,
        promptInterstitial: {
          ...state.bnlPromptRetouching,
          [studentId]: true,
        },
      };
    }
    case UPDATE_BNL_FREE_BACKGROUNDS:
      return { ...state, bnlFreeBackgrounds: action.payload };
    case SET_PERSONALIZATION_ON_ORDER:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          [action.payload.studentId]: action.payload.personalizationValue,
        },
      };
    case REMOVE_PERSONALIZATION_ON_ORDER:
      return {
        ...state,
        personalization: omit(state.personalization, [action.payload]),
      };
    case RESET_CART:
      return INITIAL_STATE;
    default:
      return state;
  }
}
