import {
  TURN_ON_NOTIFICATION,
  TURN_OFF_NOTIFICATION,
} from '../actions/action_notification';

const INITIAL_STATE = { on: false, message: '' };

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case TURN_ON_NOTIFICATION:
      return { ...state, on: true, message: action.payload };
    case TURN_OFF_NOTIFICATION:
      return { ...state, on: false, message: action.payload };
    default:
      return state;
  }
}
