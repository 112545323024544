import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { getMyOrders } from '../../actions/action_parent';

// constants files
import { GESKUS, BNL } from '../../constants/companies';

const BackLink = styled(Link)`
  display: inline-block;
  color: #479de6;
  margin-top: 12px;
  margin-left: 25px;
  font-size: 16px;
  &:hover,
  &:active {
    cursor: pointer;
    color: #479de6;
  }
`;

class ParentMyOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandingOrderIds: [],
      receivedMyOrders: false,
    };

    this.BnlByoSheetPrice = 1500;
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  cloneArray(array) {
    return array.slice();
  }

  displayPrice(price) {
    return (
      '$' + (price / 100).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    );
  }

  calculateByoPrice(childProducts) {
    let decrement = 0;
    let childCount = childProducts.length;
    // TODO
    // wire free indices
    if (childCount >= 6) decrement += 1;
    if (childCount >= 8) decrement += 1;
    childCount -= decrement;

    return childCount * this.BnlByoSheetPrice;
  }

  expanded(orderContainerOrderId) {
    return this.state.expandingOrderIds.includes(orderContainerOrderId);
  }

  toggleExpand(orderContainerOrderId) {
    let expandingOrderIds = this.cloneArray(this.state.expandingOrderIds);

    if (this.expanded(orderContainerOrderId)) {
      let newExpandingOrderIds = [];
      expandingOrderIds.forEach((expandingOrderId) => {
        if (expandingOrderId !== orderContainerOrderId) {
          newExpandingOrderIds.push(expandingOrderId);
        }
      });
      expandingOrderIds = newExpandingOrderIds;
    } else {
      expandingOrderIds.push(orderContainerOrderId);
    }

    this.setState({ expandingOrderIds: expandingOrderIds });
  }

  renderArrow(orderContainerOrderId) {
    let src = 'https://s3.amazonaws.com/pics-assets/utils/arrow_side.png';

    if (this.expanded(orderContainerOrderId)) {
      src = 'https://s3.amazonaws.com/pics-assets/utils/arrow_down.png';
    }

    return <img src={src} />;
  }

  renderPrice(orderItem) {
    let price;

    if (orderItem.is_bonus) {
      price = 'FREE';
    } else if (
      this.props.parent.orders.lab_software === 'dp2' &&
      orderItem.product_type === 'parent_product' &&
      orderItem.required_children_count === 0
    ) {
      price = this.displayPrice(
        this.calculateByoPrice(orderItem.child_products),
      );
    } else {
      price = this.displayPrice(orderItem.price);
    }

    return <div className="my-order__order-item__price">{price}</div>;
  }

  renderContents(orderItem) {
    let contents;

    if (orderItem.product_type !== 'parent_product') {
      contents = orderItem.contents.map((content, i) => {
        return <li key={`content-${i}`}>{content}</li>;
      });
    } else {
      contents = orderItem.child_products.map((childProduct, i) => {
        return <li key={`content-${i}`}>{childProduct.name}</li>;
      });
    }

    return contents;
  }

  renderBackgroundsOnly(orderItem) {
    // const bgOptions = this.props.shoot.formattedBackgroundOptions;
    const backgrounds = orderItem.green_screen_background_image_urls.map(
      (image_url, i) => {
        let style = {
          background: `url("${image_url}")`,
          backgroundSize: 'cover',
          width: '75px',
          height: '125px',
        };

        return (
          <li>
            <div style={style} />
          </li>
        );
      },
    );

    return backgrounds;
  }

  renderPoseOnly(orderItem) {
    const bgOptions = this.props.shoot.formattedBackgroundOptions;
    const pose = orderItem.poses.map((obj, i) => {
      let poseUrl = obj.pose_url;

      return (
        <li className="my-order__order-item__pose-list">
          <img className="my-order__order-item__pose--only" src={poseUrl} />
        </li>
      );
    });

    return pose;
  }

  renderPoseOverBackgrounds(orderItem) {
    let backgrounds = [];
    const bgOptions = this.props.shoot.formattedBackgroundOptions;

    if (orderItem.product_type === 'parent_product') {
      orderItem.child_products.forEach((childProduct) => {
        if (childProduct.poses.length > 0) {
          childProduct.poses.forEach((obj, i) => {
            let code = obj.green_screen_background_codes[0];
            let image_url = obj.green_screen_background_image_urls[0];

            let poseUrl = obj.pose_url;
            let bgstyle = {
              background: `url("${image_url}")`,
              backgroundSize: 'cover',
            };

            backgrounds.push(
              <li className="my-order__order-item__background-list">
                <div
                  className="my-order__order-item__background-img"
                  style={bgstyle}
                >
                  <img className="my-order__order-item__pose" src={poseUrl} />
                </div>
              </li>,
            );
          });
        } else {
          childProduct.green_screen_background_image_urls.forEach(
            (image_url, i) => {
              let bgstyle = {
                background: `url("${image_url}")`,
                backgroundSize: 'cover',
              };

              backgrounds.push(
                <li className="my-order__order-item__background-list">
                  <div
                    className="my-order__order-item__background-img"
                    style={bgstyle}
                  />
                </li>,
              );
            },
          );
        }
      });
    } else {
      backgrounds = orderItem.poses.map((obj, i) => {
        let image_url = obj.green_screen_background_image_urls[0];
        let poseUrl = obj.pose_url;
        let bgstyle = {
          background: `url("${image_url}")`,
          backgroundSize: 'cover',
        };

        return (
          <li className="my-order__order-item__background-list">
            <div
              className="my-order__order-item__background-img"
              style={bgstyle}
            >
              <img className="my-order__order-item__pose" src={poseUrl} />
            </div>
          </li>
        );
      });
    }

    return backgrounds;
  }

  renderBackgrounds(orderItem) {
    if (this.props.parent.orders.lab_software !== 'dp2') {
      return this.renderBackgroundsOnly(orderItem);
    } else if (orderItem.image_type === 'group_image') {
      return this.renderPoseOnly(orderItem);
    } else if (orderItem.name === 'Super CD + Cloud Download') {
      return null;
    } else {
      return this.renderPoseOverBackgrounds(orderItem);
    }
  }

  renderOrderItems(order) {
    const that = this;
    const orderContainerOrderId = order.order_container_order_id;
    const display = this.state.expandingOrderIds.includes(orderContainerOrderId)
      ? ''
      : 'hidden';
    const orderItems = order.order_items.map((orderItem, i) => {
      return (
        <li
          className="my-order__order-item"
          key={`${orderContainerOrderId}-${i}`}
        >
          <div className="my-order__order-item__name-price clearfix">
            <div className="my-order__order-item__name">{orderItem.name}</div>
            {that.renderPrice(orderItem)}
          </div>
          <div className="clearfix">
            <div className="my-order__order-item__img-container">
              <img
                className="my-order__order-item-img"
                src={orderItem.image_thumbnail}
              />
            </div>
            <ul className="my-order__order-item__contents">
              {that.renderContents(orderItem)}
            </ul>
            <ul className="my-order__order-item__backgrounds clearfix">
              {that.renderBackgrounds(orderItem)}
            </ul>
          </div>
        </li>
      );
    });

    return <ul className={`my-order__order_items ${display}`}>{orderItems}</ul>;
  }

  renderOrders() {
    const that = this;
    const orders = this.props.parent.orders.my_orders.map((order) => {
      let orderContainerOrderId = order.order_container_order_id;
      return (
        <div
          className="my-order__order-container"
          key={`my-order-${orderContainerOrderId}`}
        >
          <div className="clearfix">
            <div
              className="my-order__expand-button"
              onClick={() => this.toggleExpand(orderContainerOrderId)}
            >
              {this.renderArrow(orderContainerOrderId)}
            </div>
            <div className="my-order__order-info">
              <div className="my-order__order-info__code-total clearfix">
                <div className="my-order__order-code">
                  Order: {orderContainerOrderId}
                </div>
                <div className="my-order__order-total">
                  {that.displayPrice(order.grand_total)}
                </div>
              </div>
              <div className="my-order__order-date">{order.order_date}</div>
            </div>
          </div>
          <div className="clearfix">
            <div className="my-order__filler" />
            {that.renderOrderItems(order)}
          </div>
        </div>
      );
    });

    return orders;
  }

  componentWillMount() {
    this.props.getMyOrders(this.props.auth.currentUserId);
  }

  render() {
    if (!this.props.parent.orders) return null;

    return (
      <div className="my-orders">
        <h3 className="my-orders__header">My Orders</h3>
        <BackLink to="/dashboard">&larr; Back</BackLink>
        <div className="my-orders__orders-container">{this.renderOrders()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    parent: state.parent,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getMyOrders }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentMyOrders);
