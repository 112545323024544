import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import usePreventNavigation from '../../../../hooks/usePreventNavigation';
import RegularProductConfig from '../regular/RegularProductConfig';
import { usePreCartOfferProductsToPrompt } from '../../../../hooks/usePreCartOfferProductsToPrompt';

const PreCartOfferConfig = ({ history }) => {
  // TODO: describe the purpose of this hook
  usePreventNavigation(history, '/v3/pre-cart-offers');

  const searchParams = new URLSearchParams(location.search);
  const redirectSource = searchParams.get('source');

  const preCartOfferProductsToPrompt = usePreCartOfferProductsToPrompt();
  const prevLocation = useSelector((state) => state.uiLocation.previous);

  useEffect(() => {
    if (preCartOfferProductsToPrompt.length === 0) {
      history.push(prevLocation === '/v3/cart' ? '/v3/offers' : '/v3/cart');
    }
  }, [preCartOfferProductsToPrompt, prevLocation, history]);

  return preCartOfferProductsToPrompt.length > 0 ? (
    <RegularProductConfig
      hideBackLink={true}
      redirectSource={redirectSource}
      productIsPreCartOffer={true}
      product={preCartOfferProductsToPrompt[0]}
    />
  ) : null;
};

PreCartOfferConfig.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PreCartOfferConfig;
