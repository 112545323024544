const createNewCartItem = (cartObject, variantId = 1) => {
  const newItemId = `${cartObject.productId}-${variantId}`;

  return {
    ...cartObject,
    id: newItemId,
    variantId: variantId,
  };
};

const createNewPersonalizationCartItem = (
  personalizationProduct,
  variantId = 1,
) => {
  const newItemId = `${personalizationProduct.productId}-${variantId}`;

  return {
    ...personalizationProduct,
    id: newItemId,
    variantId: variantId,
  };
};

const getCount = (orders) => {
  const totalCartItems = Object.values(orders).reduce((acc, order) => {
    return acc + order.cartItems.length;
  }, 0);

  return totalCartItems;
};

const getNextVariantId = (cartItems, productId) => {
  let maxVariantId = 0;

  Object.values(cartItems).forEach((cartItem) => {
    if (cartItem.productId === productId) {
      maxVariantId = Math.max(maxVariantId, cartItem.variantId);
    }
  });

  return maxVariantId + 1;
};

const calculateTotalPrice = (orders) => {
  let total = 0;

  for (let studentId in orders) {
    let studentOrder = orders[studentId];

    for (let cartItemId in studentOrder.cartItems) {
      let cartItem = studentOrder.cartItems[cartItemId];

      // Use determineCartItemPrice to get the price of each cart item
      let itemPrice = determineCartItemPrice(cartItem);

      // Add the price (multiplied by quantity) to the total
      total += itemPrice * cartItem.quantity;
    }
  }

  return total;
};

const sumUniqueBackgroundPrices = (orders) => {
  let total = 0;

  console.log('Starting sumUniqueBackgroundPrices function');

  for (let studentId in orders) {
    const studentOrder = orders[studentId];
    const uniqueBackgroundIds = new Set(); // To track unique background IDs
    let singleChargeApplied = false; // Flag to track if the single charge has been applied globally

    console.log(`Processing studentOrder for studentId: ${studentId}`);
    const chargeForYpxBackgroundUpgrades =
      studentOrder.event.chargeForYpxBackgroundUpgrades;

    for (let cartItemId in studentOrder.cartItems) {
      const cartItem = studentOrder.cartItems[cartItemId];
      console.log(`Processing cartItem with cartItemId: ${cartItemId}`);

      // Check if backgroundSelections key exists and it's an array
      if (Array.isArray(cartItem.backgroundSelections)) {
        for (const selection of cartItem.backgroundSelections) {
          let price = parseInt(selection.price, 10); // Ensure price is an integer

          console.log(
            `Processing selection with id: ${selection.id} and price: ${price}`,
          );

          console.log(
            'chargeForYpxBackgroundUpgrades:',
            chargeForYpxBackgroundUpgrades,
          );

          // Only proceed if the price is greater than 0
          if (price > 0) {
            if (chargeForYpxBackgroundUpgrades === false && cartItem.isYPX) {
              console.log(
                'Skipping background price calculation due to chargeForYpxBackgroundUpgrades === false',
              );
              continue;
            }

            // If single charge is enabled globally and we've already applied it, skip further additions
            if (
              selection.single_charge_for_background_upgrades &&
              singleChargeApplied
            ) {
              console.log(
                'Skipping additional charges due to single_charge_for_background_upgrades',
              );
              continue;
            }

            // If we haven't encountered this background ID before, add its price to the total
            if (!uniqueBackgroundIds.has(selection.id)) {
              console.log(
                `Adding price ${price} for background ID ${selection.id} to total`,
              );
              total += price;
              uniqueBackgroundIds.add(selection.id); // Mark this ID as encountered

              // If single charge is enabled, set the flag to true after the first addition
              if (selection.single_charge_for_background_upgrades) {
                singleChargeApplied = true;
                console.log('Single charge applied');
              }
            } else {
              console.log(
                `Background ID ${selection.id} already processed, skipping`,
              );
            }
          } else {
            console.log(
              `Skipping selection with id: ${selection.id} due to non-positive price`,
            );
          }
        }
      } else {
        console.log('No valid backgroundSelections array found in cartItem');
      }
    }
  }

  console.log('Final Total:', total);

  return total;
};

const allOrdersAreEmpty = (orders) => {
  return Object.values(orders).every((order) => order.cartItems.length === 0);
};

const orderHasNoRetouching = (cartItems) => {
  return (
    cartItems?.every((cartItem) => cartItem.isRetouching === false) ?? false
  );
};

const unlockedGroups = (cartItems) => {
  // Check if cartItems is an array, if not, default to an empty array
  let groups = (Array.isArray(cartItems) ? cartItems : [])
    .filter((item) => {
      return item.lockType === 'unlocker' && Array.isArray(item.lockGroup);
    })
    .flatMap((item) => item.lockGroup);

  return [...new Set(groups)];
};

// TODO: flesh this out
const getCartTotal = (orders) => {
  let total = 0;

  for (let orderId in orders) {
    total += getOrderTotal(orders[orderId].cartItems);
  }

  return total;
};

const getOrderTotal = (cartItems) => {
  const orderTotal = cartItems.reduce((total, item) => {
    if (item.isBYO) {
      return total + determineCartItemPrice(item);
    }

    // TODO: should we just use determineCartItemPrice for all items?
    return total + item.price * item.quantity;
  }, 0);

  return orderTotal;
};

const getAllCartSKUs = (orders) => {
  const allSKUsSet = Object.values(orders).reduce((allSKUs, order) => {
    order.cartItems.forEach((item) => {
      allSKUs.add(item.sku);
    });
    return allSKUs;
  }, new Set());

  return Array.from(allSKUsSet);
};

const getAllOrdersTotal = (orders) => {
  return Object.values(orders).reduce((total, order) => {
    return total + getOrderTotal(order.cartItems);
  }, 0);
};

const eligibleIncentivesForOrder = (cartItems, incentiveProducts) => {
  // Check for at least one non-incentive product in the cart
  const hasNonIncentiveProduct = cartItems.some((item) => !item.isIncentive);

  // If no non-incentive product is found, return an empty array
  if (!hasNonIncentiveProduct) {
    return [];
  }

  const orderTotal = getOrderTotal(cartItems);

  const eligibleIncentives = incentiveProducts.filter((incentiveProduct) => {
    // Check if order total is greater than incentive product's threshold
    if (
      orderTotal >= incentiveProduct.incentive_threshold &&
      incentiveProduct.incentive_applies_to !== 'cart_level'
    ) {
      // Check if incentive product is not yet in the user's cart
      const isInCart = cartItems.some(
        (cartItem) => cartItem.productId === incentiveProduct.id,
      );
      return !isInCart;
    }
    return false;
  });

  return eligibleIncentives;
};

const eligibleIncentivesForCart = (orders, incentiveProducts) => {
  const cartTotal = getCartTotal(orders);

  const eligibleIncentives = incentiveProducts.filter((incentiveProduct) => {
    // Check if order total is greater than incentive product's threshold
    if (
      cartTotal >= incentiveProduct.incentive_threshold &&
      incentiveProduct.incentive_applies_to === 'cart_level'
    ) {
      // Check if incentive product is not yet in any order's cartItems
      // For cart-level items, we just need to check the SKU vs. the product ID, which may be different across
      // different price lists
      let isInCart = false;
      for (let orderId in orders) {
        if (
          orders[orderId].cartItems.some(
            (cartItem) => cartItem.productId === incentiveProduct.id,
          )
        ) {
          isInCart = true;
          break;
        }
      }
      return !isInCart;
    }
    return false;
  });

  return eligibleIncentives;
};

// Sort cart items so that incentives come after non-incentives
const sortCartItems = (cartItems) => {
  return [...cartItems].sort((a, b) => {
    if (a.isIncentive === b.isIncentive) {
      // if both items are the same type (either both incentive or both non-incentive), don't change order
      return 0;
    }
    // if a is incentive and b is not, a should come after b
    if (a.isIncentive && !b.isIncentive) {
      return 1;
    }
    // if b is incentive and a is not, a should come before b
    if (!a.isIncentive && b.isIncentive) {
      return -1;
    }
  });
};

const cartItemProductHasContentsArray = (cartItem) => {
  return (
    cartItem.contents &&
    Array.isArray(cartItem.contents) &&
    cartItem.contents.length > 0
  );
};

const interstitialProductsToPrompt = (
  interstitialProducts,
  interstitialProductsPrompted,
  cartItems = [],
) => {
  const productsToPrompt = interstitialProducts.filter((product) => {
    if (
      product.lock_type !== 'unlockable' ||
      interstitialProductsPrompted.includes(product.id)
    )
      return false;

    return cartItems.some(
      (cartItem) =>
        cartItem.lockType === 'unlocker' &&
        product.lock_group.some((group) => cartItem.lockGroup.includes(group)),
    );
  });

  return productsToPrompt;
};

const nthFreeQualifiersInCart = (cartItems) => {
  const nthFreeQualifiersInCart = cartItems.filter((item) => {
    return item.isNthFreeQualifier === true;
  });

  return nthFreeQualifiersInCart;
};

const nthFreeProductsInCart = (cartItems) => {
  const nthFreeProductsInCart = cartItems.filter((item) => {
    return item.isNthFreeProduct === true;
  });

  return nthFreeProductsInCart;
};

const highestPriceNthFreeQualifier = (cartItems) => {
  const nthFreeQualifiersInCart = cartItems.filter((item) => {
    return item.isNthFreeQualifier === true;
  });

  if (nthFreeQualifiersInCart.length === 0) {
    return 0;
  }

  return nthFreeQualifiersInCart.reduce((highestPrice, currentItem) => {
    return Math.max(highestPrice, currentItem.price);
  }, nthFreeQualifiersInCart[0].price);
};

const configureCartItem = ({
  backgroundIds,
  backgroundSelections,
  incentiveProductIds,
  productConfig,
  productData,
  poses,
  poseIds,
  personalizationValueForCart,
  personalizationSetToSameValue,
  quantity,
  requiredFields,
  productOptionKey,
}) => {
  let cartObject = {};
  cartObject.additionalData = productData.additional_data || {};
  cartObject.backgroundOptionsCount = productData.background_options_count;
  cartObject.productId = productData.id;
  cartObject.productName = productData.name;
  cartObject.quantity = quantity || 1;
  cartObject.personalizationValue = personalizationValueForCart;
  cartObject.price = productData.price;
  cartObject.nthFreeAmountAllowed = productData.nth_free_amount_allowed;
  cartObject.nthFreeProductId = productData.nth_free_product_id;
  cartObject.nthFreeSKU = productData.nth_free_sku;
  cartObject.nthFreeThreshold = productData.nth_free_threshold;
  cartObject.nthFreeValue = productData.nth_free_value;
  cartObject.personalizationStatus = productData.personalization_status;
  cartObject.posesCount = productData.poses_count;
  cartObject.productType = productData.product_type;
  cartObject.contents = productData.contents;
  cartObject.imageThumbnail = productData.image_thumbnail;
  cartObject.imageType = productData.image_type;
  cartObject.isGroup = productData.image_type === 'group_image';
  cartObject.incentiveThreshold = productData.incentive_threshold || null;
  cartObject.includesRetouching = productData.includes_retouching;
  cartObject.isPersonalization = productData.is_personalization;
  cartObject.isPreCartOfferProduct = productData.is_pre_cart_offer || false;
  cartObject.isRetouching = productData.is_retouching;
  cartObject.lockType = productData.lock_type;
  cartObject.lockGroup = productData.lock_group;
  cartObject.isIncentive = incentiveProductIds.includes(productData.id);
  cartObject.backgroundIds = backgroundIds || [];
  cartObject.backgroundSelections = backgroundSelections || [];
  cartObject.requiresShipping = productData.requires_shipping;
  cartObject.sku = productData.sku;

  // TODO: update this to use poseIds as passed... need to confirm that YPX/BYO is submitting poseIds
  cartObject.poseIds = poses ? poses.map((p) => p.id) : poseIds || [];

  cartObject.poses = poses || [];
  cartObject.productIds = [];
  cartObject.isBYO = productData.is_byo;
  cartObject.isYPX = productData.is_ypx;
  cartObject.isMultiConfig = productData.is_multi_config;
  cartObject.requiredFields = requiredFields;
  const selectedCrop = poses?.find(
    (pose) => !!pose.selected_crop,
  )?.selected_crop;
  cartObject.selectedCrop = selectedCrop;

  // establish whether product is an nth-free product
  cartObject.isNthFreeProduct = productData.product_type == 'nth_free';

  if (productOptionKey) {
    cartObject.productOption = productData.product_options.find(
      (option) => option.key === productOptionKey,
    );
  }

  let isNthFreeQualifier = false;

  if (
    productData.nth_free_sku &&
    productData.nth_free_sku !== null &&
    productData.nth_free_sku !== ''
  ) {
    isNthFreeQualifier = true;
  }

  cartObject.isNthFreeQualifier = isNthFreeQualifier;

  console.log('cartObject at this stage...', cartObject);

  if (personalizationValueForCart) {
    // do nothing
  } else {
    // get personalization value for child items from productConfig
    const personalizationValues = Object.values(productConfig).map(
      (p) => p.personalization,
    );
    personalizationValues.reverse();
    cartObject.personalizationValue = personalizationSetToSameValue
      ? personalizationValues.find((p) => !p)
      : null;
  }

  // !productData.is_personalization handles case where personalization producted is added to cart
  // alongside a personalized YPX product
  // TODO: find a better way to handle this
  if (!productData.is_personalization) {
    cartObject.children = Object.values(productConfig).map((child) => {
      const { product } = child;
      if (child.product) cartObject.productIds.push(child.product.id);
      if (child.background) {
        cartObject.backgroundIds.push(child.background.id);
        cartObject.backgroundSelections.push(child.background);
      }

      if (child.pose) cartObject.poseIds.push(child.pose.id);

      return {
        price: product.price,
        productId: product.id,
        productName: product.name,
        backgrounds: child.background ? [child.background] : [],
        poses: child.pose ? [child.pose] : [],
        personalizationStatus: product.personalization_status,
        personalizationValue: child.personalization,
        quantity: 1,
        additionalData: {},
        variantId: null,
      };
    });

    // TODO: revisit this logic -- it might be better to handle this via the above 'child' logic
    if (productData.is_multi_config) {
      cartObject.multiConfigChildProducts = productData.child_products.map(
        (child, index) => {
          if (child) cartObject.productIds.push(child.id);

          const pose = cartObject.poses[index];
          const background = cartObject.backgroundSelections[index];

          if (pose) {
            cartObject.poseIds.push(pose.id);
          }

          return {
            price: child.price,
            productId: child.id,
            productName: child.name,
            backgrounds: background ? [background] : [],
            poses: pose ? [pose] : [],
            // personalizationStatus: product.personalization_status, // TODO: do we need to handle personalization?
            // personalizationValue: product.personalization, // TODO: do we need to handle personalization?
            quantity: 1,
            additionalData: {},
            variantId: null,
          };
        },
      );
    }
  }

  return cartObject;
};

const cartContainsPersonalizableProducts = (cartItems) => {
  return cartItems.some((cartItem) => {
    // Check the personalization status of the cart item itself
    const isPersonalizable =
      cartItem.personalizationStatus === 'personalization_required' ||
      cartItem.personalizationStatus === 'personalization_optional';

    // Check if there are children and if any of them have a required or optional personalization status
    const hasPersonalizableChildren =
      cartItem.children &&
      cartItem.children.some((childProduct) => {
        return (
          childProduct.personalizationStatus === 'personalization_optional' ||
          childProduct.personalizationStatus === 'personalization_required'
        );
      });

    // Return true if either the item itself or any of its children are personalizable
    return isPersonalizable || hasPersonalizableChildren;
  });
};

const cartContainsNonIncentiveProducts = (cartItems) => {
  return cartItems.some((cartItem) => !cartItem.isIncentive);
};

const determineCartItemPrice = (cartItem) => {
  if (cartItem.isBYO) {
    const freeIndices = cartItem?.additionalData?.free_indices || [];

    console.log('freeIndices', freeIndices);

    // Check if cartItem has a 'children' property and that it's an array
    if (Array.isArray(cartItem.children)) {
      // Sum the price of each child, unless its index is in the freeIndices array
      let sum = cartItem.children.reduce((acc, child, index) => {
        if (!freeIndices.includes(index)) {
          return acc + (child.price || 0); // Add child price if index is not in freeIndices
        }
        return acc;
      }, 0);

      return sum;
    }
  }

  // Return the price of the cartItem if it is not a BYO
  return cartItem.price;
};

const cartItemsIncludeRetouching = (cartItems) => {
  return cartItems?.some((cartItem) => cartItem?.includesRetouching === true);
};

const cartItemsContainRetouchingProduct = (cartItems) => {
  return cartItems?.some((cartItem) => cartItem?.isRetouching === true);
};

const applyDiscountCodeToCart = (currentState, discountCode) => {
  const discountCodes = currentState.discountCodes || [];

  if (
    discountCodes.some(
      (code) => code.discount_code_id === discountCode.discount_code_id,
    )
  ) {
    return currentState;
  }

  return {
    ...currentState,
    discountCodes: [...discountCodes, discountCode],
  };
};

const cartItemsAreGroupProductsOnly = (cartItems) => {
  return cartItems.every((cartItem) => cartItem.isGroup === true);
};

const cartContainsPrepayOrders = (orders) => {
  const isPrepay = Object.values(orders).some((order) => {
    return order.isPrepay === true;
  });

  return isPrepay;
};

const cartContainsPostEventOrders = (orders) => {
  const isPostEvent = Object.values(orders).some((order) => {
    return order.isPrepay === false;
  });

  return isPostEvent;
};

const cartContainsShippingRequiredProducts = (orders) => {
  const hasShippingRequiredCartItems = Object.values(orders).some((order) => {
    return order.cartItems.some((item) => item?.requiresShipping === true);
  });

  return hasShippingRequiredCartItems;
};

const hasLockedProductsNoUnlockers = (cartItems) => {
  // Check for undefined, null, or non-array input and return false
  if (!Array.isArray(cartItems)) {
    return false;
  }

  // Check if there is at least one item with lockType === 'unlockable'
  const hasUnlockable = cartItems.some(
    (item) => item && item.lockType === 'unlockable',
  );

  // Check if there are no items with lockType === 'unlocker'
  const hasNoUnlocker = cartItems.every(
    (item) => item && item.lockType !== 'unlocker',
  );

  // Return true if both conditions are met, otherwise return false
  return hasUnlockable && hasNoUnlocker;
};

const lockedProductIds = (cartItems) => {
  // Check for undefined, null, or non-array input and return an empty array
  if (!Array.isArray(cartItems)) {
    return [];
  }

  const lockedIds = cartItems
    .filter((item) => {
      return item && item.lockType === 'unlockable';
    })
    .map((item) => item.id);

  return lockedIds;
};

const preCartOfferProductIds = (cartItems) => {
  return cartItems
    .filter((item) => item.isPreCartOfferProduct)
    .map((item) => item.id);
};

const productIdsWithLockedBackgrounds = (cartItems, unlockedGroups) => {
  if (!Array.isArray(cartItems)) {
    return [];
  }

  const productIds = cartItems
    .filter(({ backgroundSelections }) =>
      backgroundSelections.some(
        ({ lock_type, lock_group }) =>
          lock_type === 'unlockable' &&
          !unlockedGroups.some((set) => lock_group.includes(set)),
      ),
    )
    .map((item) => item.id);

  return productIds;
};

const productBackgroundLockGroup = (cartItems) => {};

const preCartOfferProductsToPrompt = (
  cartObject,
  preCartOfferProducts,
  triggersPreCartOfferProducts,
) => {
  // Return empty array if any argument is falsy or not an array where expected
  if (
    !cartObject ||
    !Array.isArray(preCartOfferProducts) ||
    !Array.isArray(triggersPreCartOfferProducts) ||
    cartObject.cartItems?.every(
      (item) => item.isPersonalization || item.isRetouching,
    )
  ) {
    return [];
  }

  // Ensure cartItems is an array, then map over it, default to an empty array if cartItems is undefined/null
  // Look through 'children' to handle YPX/BYO products
  const cartProductIds = Array.isArray(cartObject.cartItems)
    ? cartObject.cartItems.flatMap((item) => {
        // Get the productId of the current item
        const productIds = [item.productId];

        // If the item has children, add their productIds to the array
        if (Array.isArray(item.children)) {
          item.children.forEach((child) => {
            if (child.productId) {
              productIds.push(child.productId);
            }
          });
        }

        return productIds;
      })
    : [];

  // Check if any of the triggersPreCartOfferProducts are in the cart
  const isTriggerProductInCart = triggersPreCartOfferProducts.some((product) =>
    cartProductIds.includes(product.id),
  );

  // Use empty array as a default value for preCartOfferProductsPrompted
  const preCartOfferProductsPromptedIds =
    cartObject.preCartOfferProductsPrompted || [];

  // If a trigger product is in the cart, return empty array
  if (isTriggerProductInCart) {
    return [];
  }

  // Filter preCartOfferProducts to include products not yet prompted and not in the cart
  return preCartOfferProducts.filter(
    (product) =>
      !cartProductIds.includes(product.id) &&
      !preCartOfferProductsPromptedIds.includes(product.id),
  );
};

const cartHasShippingCost = (cartTotalsObject) =>
  cartTotalsObject &&
  typeof cartTotalsObject.shipping === 'number' &&
  cartTotalsObject.shipping > 0;

// Determine whether the order contains a single background upgrade and return true if the studio has set the single_charge_for_background_upgrades flag
const orderContainsSingleBackgroundUpgrade = (
  orders,
  studentId,
  chargeForYpxBackgroundUpgrades = false,
) => {
  if (orders && orders[studentId]) {
    const cartItems = orders[studentId].cartItems;

    for (const cartItem of cartItems) {
      if (cartItem.backgroundSelections) {
        for (const selection of cartItem.backgroundSelections) {
          if (selection.price && parseInt(selection.price) > 0) {
            if (
              cartItem?.isYPX === true &&
              chargeForYpxBackgroundUpgrades === false
            ) {
              return false;
            } else {
              if (selection.single_charge_for_background_upgrades) {
                return true;
              }
            }
          }
        }
      }
    }
  }

  return false;
};

export default {
  allOrdersAreEmpty,
  applyDiscountCodeToCart,
  calculateTotalPrice,
  cartContainsNonIncentiveProducts,
  cartContainsPersonalizableProducts,
  cartContainsPostEventOrders,
  cartContainsPrepayOrders,
  cartContainsShippingRequiredProducts,
  cartHasShippingCost,
  cartItemsAreGroupProductsOnly,
  cartItemsContainRetouchingProduct,
  cartItemsIncludeRetouching,
  cartItemProductHasContentsArray,
  configureCartItem,
  createNewCartItem,
  createNewPersonalizationCartItem,
  determineCartItemPrice,
  eligibleIncentivesForCart,
  eligibleIncentivesForOrder,
  getAllCartSKUs,
  getAllOrdersTotal,
  getCount,
  getNextVariantId,
  getOrderTotal,
  hasLockedProductsNoUnlockers,
  highestPriceNthFreeQualifier,
  interstitialProductsToPrompt,
  lockedProductIds,
  nthFreeProductsInCart,
  nthFreeQualifiersInCart,
  orderHasNoRetouching,
  orderContainsSingleBackgroundUpgrade,
  preCartOfferProductsToPrompt,
  preCartOfferProductIds,
  productIdsWithLockedBackgrounds,
  sortCartItems,
  sumUniqueBackgroundPrices,
  unlockedGroups,
};
