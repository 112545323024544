import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { updatePassword } from '../../actions/action_parent';

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const CancelLink = styled(Link)`
  display: inline-block;
  margin-left: 8px;
`;

const ChangePasswordForm = ({ history, onSuccessRoute }) => {
  const dispatch = useDispatch();

  const [submissionError, setSubmissionError] = useState('');

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const requestData = {
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    };

    dispatch(updatePassword(requestData))
      .then(() => {
        history.push(onSuccessRoute);
      })
      .catch((error) => {
        let errorMessages = '';
        if (error.response.data.errors) {
          error.response.data.errors.forEach((message) => {
            errorMessages += message;
          });
        }
        setSubmissionError(errorMessages);
      });
  };

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Change Password</MainHeader>
        <Divider />
        <div className="order-checkout__main-content row">
          <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
            <Wrapper>
              <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="form-group">
                  <label htmlFor="password">New password</label>
                  <input
                    type="password"
                    className={`form-control ${
                      errors?.password ? 'is-invalid' : ''
                    }`}
                    name="password"
                    {...register('password', {
                      required: 'New password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },
                    })}
                  />
                  {errors.password && (
                    <div className="text-danger">
                      {errors?.password.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="passwordConfirmation">
                    Confirm new password
                  </label>
                  <input
                    type="password"
                    className={`form-control ${
                      errors?.passwordConfirmation ? 'is-invalid' : ''
                    }`}
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    {...register('passwordConfirmation', {
                      required: 'Confirmation is required',
                      validate: {
                        match: (v) => {
                          if (watch('password') != v) {
                            return 'Password and confirmation do not match';
                          }
                        },
                      },
                    })}
                  />
                  {submissionError && (
                    <div className="text-danger">{submissionError}</div>
                  )}
                  {errors.passwordConfirmation && (
                    <div className="text-danger">
                      {errors?.passwordConfirmation.message}
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary">
                  Change Password
                </button>
                <CancelLink to={onSuccessRoute} className="ml-2">
                  Cancel
                </CancelLink>
              </form>
            </Wrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  onSuccessRoute: PropTypes.string,
};

export default withRouter(ChangePasswordForm);
