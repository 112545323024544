/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import SMSNumberConfirmation from '../products/regular/subcomponents/SMSNumberConfirmation';

import { updateSMSNumberConfirmed } from '../../../actions/v3Order';

const DoneWrapper = styled.div`
  font-size: 19px;
  font-weight: 800;
  margin-top: 90px;
  flex: 1;
`;

const Done = () => (
  <DoneWrapper>
    Configuration complete. Please click 'Done, Add to Cart'
  </DoneWrapper>
);

function DoneScreen() {
  const {
    parent: { smsPhone, phone },
    auth: { currentUserId },
    v3Order: { smsNumberConfirmed },
  } = useSelector((s) => s);

  const dispatch = useDispatch();
  const { productConfig } = useSelector((s) => s.v3Order);
  const [editMode, setEditMode] = useState(false);
  const [smsConfigVisible, setSmsConfigVisible] = useState(false);

  const allProducts = Object.values(productConfig).map((p) => p.product);

  const requiresSms = !!allProducts.find((p) => p.sms_required);

  const askForPhoneConfirmation = async () => {
    const userPhone = smsPhone || phone;

    const shouldShowSmsConfig =
      requiresSms && (!smsNumberConfirmed || !userPhone);

    if (!shouldShowSmsConfig) return;

    const phoneNumberObject = parsePhoneNumberFromString(userPhone, 'US');

    if (!phoneNumberObject || !phoneNumberObject.isValid()) {
      Swal.fire('Invalid Phone Number', '', 'error');
      return;
    }

    const result = await Swal.fire({
      title: 'Please Confirm',
      text: `Please confirm ${phoneNumberObject.formatNational()} is the mobile number where we should send your SMS-based products`,
      confirmButtonText: 'OK',
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      cancelButtonText: 'Edit',
    });

    if (result.value === true) {
      dispatch(updateSMSNumberConfirmed(true));
      Swal.fire(
        'Confirmed',
        'You may now add the product to your cart',
        'success',
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      setSmsConfigVisible(true);
    }
  };

  useEffect(() => {
    askForPhoneConfirmation();
  }, [smsPhone]);

  return smsConfigVisible ? (
    <div style={{ marginTop: 30 }}>
      <SMSNumberConfirmation
        currentUserId={currentUserId}
        editMode={editMode}
        phone={phone}
        setEditMode={setEditMode}
        smsNumber={smsPhone}
        smsNumberConfirmed={smsNumberConfirmed}
      />
    </div>
  ) : (
    <Done />
  );
}

export default withRouter(DoneScreen);
