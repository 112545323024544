import React from 'react';
import formatUSD from '../../../../utils/formatUSD';

const CheckoutSummaryLineItem = ({
  hideIfZero,
  label,
  price,
  showZeroIfZero,
  showFreeIfDiscount,
}) => {
  const displayPrice = (price) => {
    if (!price || price === 0) {
      if (price === 0 && showZeroIfZero) {
        return formatUSD(price);
      }

      if (price === 0 && showFreeIfDiscount) {
        return 'FREE';
      }

      return '--';
    }

    return formatUSD(price);
  };

  if (!price && hideIfZero) {
    return null;
  }

  if (price && hideIfZero && price === 0) {
    return null;
  }

  return (
    <div className="order-summary__total clearfix">
      <div className="order-summary__text">{label}</div>
      <div className="order-summary__price">{displayPrice(price)}</div>
    </div>
  );
};

export default CheckoutSummaryLineItem;
