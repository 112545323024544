import { STORE_ORDER_CONTAINER_STEP } from '../actions/action_navbar';

const INITIAL_STATE = { orderContainerStep: null };

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case STORE_ORDER_CONTAINER_STEP:
      return { ...state, orderContainerStep: action.payload };
    default:
      return state;
  }
}
