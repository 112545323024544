import React from 'react';
import styled from 'styled-components';

const LandingWrapper = styled.div`
  padding-top: 25px;
  @media (max-width: 992px) {
    padding-top: 0;
  }
`;

const Logo = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 300px;
  height: 120px;
  background-image: url('https://pics-assets.s3.amazonaws.com/logos/normandy-logo-new.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (max-width: 992px) {
    height: 90px;
    width: 100%;
  }
`;

const Header = styled.h1`
  margin-top: 30px;
`;

const LandingNormandy = ({ history }) => {
  return (
    <LandingWrapper className="gb-landing">
      <div className="gb-landing__top clearfix">
        <div className="gb-landing__top__left">
          <Logo alt="Normandy Logo" />
          <Header className="gb-landing__top__left__header">Welcome</Header>
          <h3 className="gb-landing__top__left__subheader">
            To the brand-new Normandy Studio online ordering platform. Sign in
            or create an account to get started.
          </h3>
          <div
            className="gb-landing__signup-button cursor-pointer"
            onClick={() => history.push('/account-lookup', { step: 1 })}
          >
            Place Your Order
          </div>
        </div>
        <img
          className="gb-landing__top__image"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_main.png"
        />
      </div>
      <div className="gb-landing__center">
        <h2 className="gb-landing__center__header">Online Exclusives</h2>
        <div className="clearfix">
          <div className="gb-landing__center__left">
            <div className="gb-landing__center__box-header">More Options</div>
            <div className="gb-landing__center__box-desc">
              Exclusive items and packages available online only!
            </div>
          </div>
          <div className="gb-landing__center__right">
            <div className="gb-landing__center__box-header">
              Pay with Credit Card
            </div>
            <div className="gb-landing__center__box-desc">
              No more stuffing checks or cash into paper envelopes. Pay securely
              with your credit card.
            </div>
          </div>
        </div>
      </div>
      <div className="gb-landing__bottom clearfix">
        <div className="gb-landing__bottom__left">
          <h2 className="gb-landing__bottom__left__header">
            Print or Digital, Quality first.
          </h2>
          <div className="gb-landing__bottom__left__desc">
            It starts with capturing a great photo, but it doesn't end there.
            Quality products and great customer service are the cornerstones of
            what we do.
          </div>
        </div>
        <img
          className="gb-landing__bottom__right"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_phone.png"
        />
      </div>
    </LandingWrapper>
  );
};

export default LandingNormandy;
