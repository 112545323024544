import {
  GET_STRIPE_PUBLISHABLE_KEY,
  ACQUIRE_SMS,
  STORE_ORDER_CONTAINER_ORDER_ID,
  ORDER_SHOULD_OFFER_RETOUCHING,
  ORDER_HAS_OFFERED_RETOUCHING,
  RESET_ORDER_RETOUCHING,
  UPDATE_CURRENT_STUDENT_ID,
} from '../actions/action_order';

const INITIAL_STATE = {
  currentStudentId: null,
  hasOfferedRetouching: false,
  orderContainerOrderId: null,
  shouldBeOfferedRetouching: false,
  smsAcquired: false,
  stripePublishableKey: null,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case GET_STRIPE_PUBLISHABLE_KEY:
      return {
        ...state,
        stripePublishableKey: action.payload.data.stripePublishableKey,
      };
    case ACQUIRE_SMS:
      return { ...state, smsAcquired: action.payload };
    case ORDER_SHOULD_OFFER_RETOUCHING:
      return { ...state, shouldBeOfferedRetouching: action.payload };
    case ORDER_HAS_OFFERED_RETOUCHING:
      return { ...state, hasOfferedRetouching: action.payload };
    case RESET_ORDER_RETOUCHING:
      return {
        ...state,
        hasOfferedRetouching: action.payload,
        shouldBeOfferedRetouching: action.payload,
      };
    case STORE_ORDER_CONTAINER_ORDER_ID:
      return { ...state, orderContainerOrderId: action.payload };
    case UPDATE_CURRENT_STUDENT_ID:
      return { ...state, currentStudentId: action.payload };
    default:
      return state;
  }
}
