/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  declinePersonalization,
  submitPersonalization,
} from '../../../../actions/v3Order';

const PersonalizeProduct = (props) => {
  const [personalization, setPersonalization] = useState('');

  const {
    additionalData: { selectedStudent },
  } = props;

  return (
    <div>
      <h1>Would you like to personalize?</h1>
      <input
        value={personalization}
        onChange={(e) => setPersonalization(e.target.value)}
        placeholder="Personalization"
      />
      <p>
        <button
          onClick={() =>
            props.submitPersonalization(selectedStudent.id, personalization)
          }
        >
          submit
        </button>
      </p>
      <p>
        <button
          onClick={() => props.declinePersonalization(selectedStudent.id)}
        >
          no thanks
        </button>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  additionalData: state.additionalData,
  shoot: state.shoot,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      declinePersonalization,
      submitPersonalization,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizeProduct);
