import React, { Component } from 'react';
import { connect } from 'react-redux';

class OrderBuildYourOwnSlot extends Component {
  constructor(props) {
    super(props);

    this.selectMobileSlot = this.selectMobileSlot.bind(this);
  }

  selectMobileSlot() {
    if (this.props.mobile) {
      this.props.selectMobileSlot(this.props.slotIndex);
    }
  }

  renderRemoveButton() {
    if (
      this.props.configuration[this.props.slotIndex].backgroundCodes &&
      this.props.configuration[this.props.slotIndex].backgroundCodes.length ===
        1
    ) {
      return (
        <div
          className="byo__slot-content__remove-button cursor-pointer"
          onClick={() =>
            this.props.removeConfiguredChildItem(this.props.slotIndex)
          }
        >
          X
        </div>
      );
    }
  }

  renderUnfilledContent() {
    let topText = `Item ${this.props.slotIndex + 1}`;

    let bottomText = '$15'; // TODO: get price from product
    let freeSlot = '';

    if (this.props.productIsBnlYouPickX()) {
      topText = 'Select';
      bottomText = `Item ${this.props.slotIndex + 1}`;
    }

    if (
      this.props.freeIndices &&
      this.props.freeIndices.includes(this.props.slotIndex)
    ) {
      bottomText = 'FREE';
      freeSlot = 'byo__slot--free';
    }

    const selected = this.props.selected ? 'byo--selected' : '';

    return (
      <div
        className={`byo__slot-content ${selected} ${freeSlot}`}
        onClick={this.selectMobileSlot}
      >
        <div className="byo__slot-content__add-sheet">{topText}</div>
        {bottomText === 'FREE' && <div>{bottomText}</div>}
      </div>
    );
  }

  renderPose(itemConfig) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
      bnlPreShoot,
    } = this.props;

    if (!bnlPreShoot.preShoot) {
      let poseStyle;
      let poseUrl = '';

      // Handle Geskus Post-Event
      // TODO: revisit
      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        poseUrl = studentPreviewImageUrl;
      } else if (itemConfig.pose && itemConfig.pose.url) {
        poseUrl = itemConfig.pose.url;
      }

      if (itemConfig.backgroundCodes && itemConfig.backgroundCodes.length > 0) {
        poseStyle = {
          backgroundImage: `url("${poseUrl}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '80px',
          margin: '0 auto',
          position: 'absolute',
          width: '65px',
        };
      } else if (itemConfig.pose) {
        poseStyle = {
          backgroundImage: `url("${poseUrl}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '80px',
          margin: '0 auto',
          position: 'absolute',
          width: '65px',
        };
      }

      return <div className="byo__slot-content__pose" style={poseStyle} />;
    }

    return null;
  }

  renderContent() {
    let itemConfig = null;

    if (
      this.props.configuration &&
      this.props.configuration[this.props.slotIndex]
    ) {
      itemConfig = this.props.configuration[this.props.slotIndex];
    }

    if (itemConfig) {
      const { childItem } = itemConfig;
      const prodName = childItem.name;
      let url;
      let prodOrBgstyle;

      if (
        itemConfig.backgroundCodes &&
        itemConfig.backgroundCodes.length > 0 &&
        itemConfig.backgroundCodes[0] !== null // TODO revisit.
      ) {
        const backgroundCode = itemConfig.backgroundCodes[0];
        url =
          this.props.shoot.formattedBackgroundOptions[backgroundCode]
            .image_thumbnail;

        prodOrBgstyle = {
          backgroundImage: `url("${url}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '80px',
          margin: '0 auto',
          width: '65px',
        };
      } else if (itemConfig.pose) {
        prodOrBgstyle = {
          height: '80px',
          margin: '0 auto',
          width: '65px',
        };
      } else if (itemConfig.childItem) {
        url = childItem.image_thumbnail;
        prodOrBgstyle = {
          backgroundImage: `url("${url}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '80px',
          margin: '0 auto',
          width: '65px',
        };
      }

      return (
        <div className="byo__slot-content byo__slot-content--filled">
          <div className="byo__slot-content__image" style={prodOrBgstyle}>
            {this.renderPose(itemConfig)}
          </div>
          <div className="byo__slot-content__name">{prodName}</div>
          {this.renderRemoveButton()}
        </div>
      );
    }
    return this.renderUnfilledContent();
  }

  render() {
    const { display } = this.props;
    const evenOdd = (this.props.slotIndex + 1) % 2 === 0 ? 'even' : 'odd';

    if (this.props.display === 'desktop') {
      return (
        <li className={`byo__slot byo__slot--${display}`}>
          {this.renderContent()}
        </li>
      );
    }
    if (this.props.display === 'mobile') {
      return (
        <li className="byo__mobile-slot-container col-xs-6">
          <div className={`byo__slot byo__slot--${display}--${evenOdd}`}>
            {this.renderContent()}
          </div>
        </li>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlPreShoot: state.bnlPreShoot,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(OrderBuildYourOwnSlot);
