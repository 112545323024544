import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { setCurrentUser } from '../../../actions/action_auth';
import { setAdditionalData } from '../../../actions/additionalData';
import { addStudent } from '../../../actions/action_parent';
import {
  getGalleries,
  setCpmId,
  storeGallery,
} from '../../../actions/action_gallery';
import { storeYearbookStudentId } from '../../../actions/action_bnl_yearbook_photo_selection';
import {
  formatAndSetBackgroundOptions,
  formatAndSetIncentiveProducts,
  getShootData,
  setRetouchingProduct,
  setRetouchingProducts,
  setPersonalizationProduct,
  setInterstitialProducts,
  setPreCartOfferProducts,
  setTriggersPreCartOfferProducts,
} from '../../../actions/action_shoot';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';
import { setBnlPreShoot } from '../../../actions/action_bnl_pre_shoot';
import { updateBnlUnlockedGroups } from '../../../actions/action_cart';

// constants files
import { ORDER_NAVIGATION } from '../../../constants/order_navigation';

import UIContext from '../../../contexts/UIContext';

class BnlParentAddStudentForm extends Component {
  static contextType = UIContext;

  constructor(props) {
    super(props);

    this.STEPS = {
      form: 0,
      redirect: 1,
    };

    this.state = {
      activeComponent: this.STEPS.form,
      errors: {},
      firstName: '',
      grade: '',
      homeRoom: '',
      lastName: '',
      loading: false,
      teacherLastName: '',
      teacherSalutation: '',
      submitting: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  cloneArray(array) {
    return array.slice();
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  addStudent() {
    const that = this;
    const data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
    };
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (!this.state.submitting) {
      const that = this;
      const data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      };

      this.setState(
        {
          errors: {},
          submitting: true,
        },
        () => {
          if (that.props.bnlPreShoot.preShoot) {
            this.props.addStudent(data).then((response) => {
              that.setState({ submitting: false }, () => {
                if (response.payload.data) {
                  if (response.payload.data.errors) {
                    that.setState({ errors: response.payload.data.errors });
                  } else {
                    const { students } = this.props.parent;
                    const student_ids = students.map(
                      (student, i) => student.id,
                    );
                    that.props.history.push('/select-student');
                  }
                }
              });
            });
          } else {
            this.setState({ submitting: false }, () => {
              that.handleAssignGallery();
            });
          }
        },
      );
    }
  }

  renderHeader() {
    return (
      <div className="col-xs-12 col-md-12 col-lg-12">
        <div className="add-student__header--bnl text-center">
          Enter your student's name exactly as it appears in school records.
        </div>
        <p className="text-center add-student__header-description">
          Do not use nicknames or shortened versions. (e.g. Tim for Timothy)
        </p>
      </div>
    );
  }

  renderImagePreviews() {
    const images = this.props.bnlPreShoot.imageUrls.map((imageUrl) => (
      <li className="col-xs-3 col-md-3 col-lg-3" key={`image-${imageUrl}`}>
        <img
          className="bnl-dashboard__student-selection__img-previews__img"
          src={imageUrl}
        />
      </li>
    ));

    return images;
  }

  storeGallery(student, responseData) {
    const that = this;
    let storingGallery = [];
    const cpmId = responseData.shoot.pricing_model.id;

    // redo this
    if (
      this.props.gallery.galleries[cpmId] &&
      this.props.gallery.galleries[cpmId][student.id] &&
      this.props.gallery.galleries[cpmId][student.id].gallery
    ) {
      storingGallery = this.cloneArray(
        this.props.gallery.galleries[cpmId][student.id].gallery,
      );
    }

    responseData.gallery_images.forEach((galleryImage) => {
      let duplicate = false;
      if (
        this.props.gallery.galleries[cpmId] &&
        this.props.gallery.galleries[cpmId][student.id] &&
        this.props.gallery.galleries[cpmId][student.id].gallery
      ) {
        that.props.gallery.galleries[cpmId][student.id].gallery.forEach(
          (storedGallery) => {
            if (galleryImage.id === storedGallery.id) duplicate = true;
          },
        );
      }

      if (!duplicate) storingGallery.push(galleryImage);
    });

    const storingAssociatedGroupImages = [];

    if (
      this.props.gallery.galleries[cpmId] &&
      this.props.gallery.galleries[cpmId][student.id] &&
      this.props.gallery.galleries[cpmId][student.id].associatedGroupImages
    ) {
      this.cloneArray(
        this.props.gallery.galleries[cpmId][student.id].associatedGroupImages,
      );
    }

    responseData.associated_group_images.forEach((groupImage) => {
      let duplicate = false;
      if (
        this.props.gallery.galleries[cpmId] &&
        this.props.gallery.galleries[cpmId][student.id] &&
        this.props.gallery.galleries[cpmId][student.id].associatedGroupImages
      ) {
        that.props.gallery.galleries[cpmId][
          student.id
        ].associatedGroupImages.forEach((storedGallery) => {
          if (groupImage.id === storedGallery.id) duplicate = true;
        });
      }

      if (!duplicate) storingAssociatedGroupImages.push(groupImage);
    });

    const gallery = {
      gallery: storingGallery,
      associatedGroupImages: storingAssociatedGroupImages,
      shoot: responseData.shoot,
    };

    this.props.storeGallery(
      cpmId,
      student,
      gallery,
      responseData.requires_yearbook_photo_selection,
    );
  }

  formatBackgroundOptions(containsBackgroundCategories, backgroundOptions) {
    const formattedBackgroundOptions = {};

    if (containsBackgroundCategories) {
      backgroundOptions.forEach((backgroundSet) => {
        backgroundSet.background_images.forEach((backgroundOption) => {
          formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
        });
      });
    } else {
      backgroundOptions.forEach((backgroundOption) => {
        formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
      });
    }

    this.props.formatAndSetBackgroundOptions(formattedBackgroundOptions);
  }

  setUnlockedGroups(studentId) {
    const orderState = this.props.cart.orders[studentId];
    let bnlUnlockedGroups = [];

    if (orderState) {
      bnlUnlockedGroups = this.cloneArray(this.props.cart.bnlUnlockedGroups);

      orderState.cartItems.forEach((cartItem) => {
        if (cartItem.lock_type === 'unlocker') {
          cartItem.lock_group.forEach((set) => {
            if (!bnlUnlockedGroups.includes(set)) bnlUnlockedGroups.push(set);
          });
        }
      });
    }

    this.props.updateBnlUnlockedGroups(bnlUnlockedGroups);
  }

  handleAssignGallery() {
    let url = this.props.bnlPreShoot.imageUrls[0];
    const that = this;
    const studentData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
    };

    if (this.props.company?.name === 'gpi') {
      const galleryId =
        this.props.additionalData?.galleryThatInitiatedOrder?.galleryId;

      if (galleryId) {
        // Find the gallery object where the id matches the galleryId
        const gallery =
          this.props.additionalData?.selectedStudent?.galleries?.find(
            (gallery) => gallery.id === galleryId,
          );

        // If the gallery object exists and has images, update the url
        if (gallery && gallery.images && gallery.images.length > 0) {
          url = gallery.images[0].url;
        }
      }
    }

    Swal.fire({
      title: `Please confirm gallery ${this.props.bnlPreShoot.galleryPassword}`,
      html: `
        <img class="swal-img-preview" src="${url}"/>
        <br></br>
        <div>${studentData.first_name} ${studentData.last_name}</div>
      `,
      focusConfirm: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'OK',
    }).then((res) => {
      if (res.value) {
        this.setState({ loading: true }, () => {
          // add student first
          this.props.addStudent(studentData).then((response) => {
            if (response.payload.data) {
              if (response.payload.data.errors) {
                that.setState({
                  errors: response.payload.data.errors,
                  loading: false,
                });
              } else {
                const student = response.payload.data;
                const shootData = {
                  student_id: response.payload.data.id,
                  shoot_key: that.props.bnlPreShoot.galleryPassword,
                  grade_code: 'IDK',
                };
                that.props.getShootData(shootData).then((response) => {
                  if (!response.payload.data.errors) {
                    const shootData = response.payload.data;
                    // assign gallery
                    that.storeGallery(student, shootData);
                    that.formatBackgroundOptions(
                      shootData.contains_background_categories,
                      shootData.background_options,
                    );
                    // if dp2 shoot requires year book selection
                    if (
                      shootData.requires_yearbook_photo_selection &&
                      !shootData.yearbook_photo_selection_expired
                    ) {
                      that.props.storeYearbookStudentId(student.id);
                      that.props.history.push('/yearbook-photo-selection');
                    } else {
                      that.setState({
                        activeComponent: this.STEPS.redirect,
                        loading: false,
                      });
                    }
                  }
                });
              }
            }
          });
        });
      }
    });
  }

  formatIncentiveProducts(incentiveProducts) {
    const formattedIncentiveProducts = {};

    incentiveProducts.forEach((incentiveProduct) => {
      if (!formattedIncentiveProducts[incentiveProduct.incentive_threshold]) {
        formattedIncentiveProducts[incentiveProduct.incentive_threshold] = [];
      }

      formattedIncentiveProducts[incentiveProduct.incentive_threshold].push(
        incentiveProduct,
      );
    });

    this.props.formatAndSetIncentiveProducts(formattedIncentiveProducts);
  }

  startOrder() {
    const uiSettings = this.context; // get UI version from context
    const { uiVersion } = uiSettings;
    const { setAdditionalData } = this.props;

    // console.log('%cstartOrder()', 'color: pink;');

    this.setState(
      {
        activeComponent: null,
        loading: true,
      },
      () => {
        const cpmId = this.props.shoot.shoot.pricing_model.id;
        const gallery = this.props.gallery.galleries[cpmId];
        const that = this;

        // get gallery that contains all poses for all grouped students
        const flatGallery = Object.values(gallery).reduce((acc, user) => {
          return acc.concat(user.gallery);
        }, []);

        // get group images for all grouped students
        const flatGroupImages = Object.values(studentsGalleries || {}).reduce(
          (acc, user) => {
            return acc.concat(user.associatedGroupImages);
          },
          [],
        );

        const galleryKeys = Object.keys(gallery); // each key is a student id

        // we want the last student id in the array (it's the most-recently added student)
        const studentId = galleryKeys[galleryKeys.length - 1];

        // console.log(Object.keys(gallery));
        // console.log('studentId', studentId);

        const studentsGalleries = gallery;
        const studentGalleryObj = gallery[studentId];
        const selectedStudent = studentGalleryObj.student;
        const galleryKey = studentGalleryObj.gallery[0].gallery_key;

        const studentGroupImages = studentGalleryObj.associatedGroupImages;

        const data = {
          student_id: studentId,
          shoot_key: galleryKey,
          grade_code: 'IDK',
        };

        // set the selected student in additionalData (this is a very important step)
        setAdditionalData({ selectedStudent });

        this.props.setBnlPreShoot(false);
        this.props.getShootData(data).then((resp) => {
          that.setState(
            {
              loading: false,
            },
            () => {
              if (!resp.payload.data.errors) {
                // get the ui version from the event (shoot)
                const eventUIVersion = resp.payload.data.shoot?.ui_version;

                that.formatBackgroundOptions(
                  resp.payload.data.contains_background_categories,
                  resp.payload.data.background_options,
                );

                that.props.setRetouchingProduct(
                  resp.payload.data.retouching_product,
                );

                that.props.setRetouchingProducts(
                  resp.payload.data.retouching_products,
                );

                that.props.setPersonalizationProduct(
                  resp.payload.data.personalization_product,
                );

                that.props.setInterstitialProducts(
                  resp.payload.data.interstitial_products,
                );

                that.props.setPreCartOfferProducts(
                  resp.payload.data.pre_cart_offer_products,
                );

                that.props.setTriggersPreCartOfferProducts(
                  resp.payload.data.triggers_pre_cart_offer_products,
                );

                that.formatIncentiveProducts(
                  resp.payload.data.incentive_products,
                );

                that.props.setCpmId(cpmId);

                that.setUnlockedGroups(studentId);

                that.props.updateOrderActiveComponent(
                  ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
                );

                // TODO: v3/combine with same logic in BnlParentDashboard

                const student = studentGalleryObj.student;

                that.props.setAdditionalData({
                  // availablePoses: studentGalleryObj.gallery,
                  availablePoses: flatGallery,
                  availableGroupImages: flatGroupImages,
                  studentGallery: studentGalleryObj?.gallery || [],
                  studentGroupImages,
                  eventUIVersion,
                  selectedStudent: student,
                  student,
                  studentId: student.id,
                  uiVersion,
                });

                // TODO: v3 - remove
                console.log('%corder entry point: 3', 'color: yellow;');
                console.log(`%cUI version: ${uiVersion}`, `color: yellow;`);
                console.log(
                  `%cevent UI version: ${eventUIVersion}`,
                  `color: yellow;`,
                );

                // TODO: v3 - move to helper function
                if (
                  ['3', 'v3'].includes(uiVersion) ||
                  eventUIVersion === 'ui_v3'
                ) {
                  this.props.history.push('/v3/incentive-offers');
                } else {
                  that.props.history.push('/order');
                }
              }
            },
          );
        });
      },
    );
  }

  getBgColor() {
    if (this.state.firstName && this.state.lastName) {
      return 'bg--bnl-light-blue';
    }

    return 'bg--bnl-dark-grey';
  }

  renderSubmitButton() {
    const bgColor = this.getBgColor();

    if (this.props.bnlPreShoot.preShoot) {
      return (
        <input
          className={`add-student-form__button button--round-border ${bgColor} center-block`}
          value="Add Student"
          type="submit"
        />
      );
    }
    return (
      <input
        className={`add-student-form__button button--round-border ${bgColor} center-block`}
        value="Assign Gallery"
        type="submit"
      />
    );
  }

  renderForm() {
    const { errors } = this.state;
    const firstNameErrorClassName = errors.firstName ? '' : 'hidden';
    const lastNameErrorClassName = errors.lastName ? '' : 'hidden';
    const studentErrorClassName = errors.student ? '' : 'hidden';

    return (
      <div>
        {this.renderHeader()}
        <form onSubmit={this.handleOnSubmit} className="add-student-form">
          <div className="row">
            <div className="col-xs-12 col-md-4 col-md-offset-4">
              <input
                id="first-name"
                className="add-student-form__input border--round"
                name="firstName"
                onChange={this.handleOnChange}
                value={this.state.firstName}
                placeholder="First Name"
              />
              <div className={firstNameErrorClassName}>{errors.firstName}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4 col-md-offset-4">
              <input
                id="last-name"
                className="add-student-form__input border--round"
                name="lastName"
                onChange={this.handleOnChange}
                value={this.state.lastName}
                placeholder="Last Name"
              />
              <div className={lastNameErrorClassName}>{errors.lastName}</div>
              <div className={studentErrorClassName}>{errors.student}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4 col-md-offset-4">
              {this.renderSubmitButton()}
            </div>
          </div>
        </form>
        <ul className="row">{this.renderImagePreviews()}</ul>
      </div>
    );
  }

  renderRedirectModal() {
    const that = this;

    Swal.fire({
      title: 'Gallery Assigned!',
      text: 'Your gallery has been successfully assigned. You can now return to the dashboard and order.',
      showConfirmButton: true,
      showCancelButton: false,
      customClass: {
        confirmButton: 'add-student-redirect__go-to-dashboard',
        // confirmButton: 'add-student-redirect__go-to-order',
        // cancelButton: 'add-student-redirect__assign-gallery',
      },
      confirmButtonText: 'Back to Dashboard',
      // cancelButtonText: 'Add',
      // reverseButtons: true,
    }).then((res) => {
      if (res.value) {
        // that.startOrder();
        that.props.history.push('/dashboard');
      } else {
        that.props.history.push('/dashboard');
      }
    });
  }

  renderActiveComponent() {
    switch (this.state.activeComponent) {
      case this.STEPS.form:
        return this.renderForm();
      case this.STEPS.redirect:
        this.renderRedirectModal();
    }
  }

  componentWillReceiveProps(nextProps) {
    // redirect to signup step 2 when directly accessing "//add-student"
    // if current user is truthy but hasn't completed signup
    if (nextProps.auth.currentUserId && !nextProps.auth.signupCompleted) {
      this.props.history.replace('/signup/step-2');
    }
  }

  render() {
    const loading = this.state.loading ? 'loading' : 'hidden';

    return (
      <div>
        <div className={loading}>
          <div className="spinner" />
        </div>
        {this.renderActiveComponent()}
      </div>
    );
  }

  componentDidMount() {
    this.setState({ submitting: false });
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    company: state.company,
    gallery: state.gallery,
    parent: state.parent,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addStudent,
      formatAndSetBackgroundOptions,
      formatAndSetIncentiveProducts,
      getGalleries,
      getShootData,
      setAdditionalData,
      setBnlPreShoot,
      setCpmId,
      setCurrentUser,
      setRetouchingProduct,
      setRetouchingProducts,
      setPersonalizationProduct,
      setInterstitialProducts,
      setPreCartOfferProducts,
      setTriggersPreCartOfferProducts,
      storeGallery,
      storeYearbookStudentId,
      updateBnlUnlockedGroups,
      updateOrderActiveComponent,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BnlParentAddStudentForm);
