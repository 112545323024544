import React, { Component } from 'react';
import { connect } from 'react-redux';

class BnlOrderProductConfigurationBackgroundsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeBgSetGroupId: this.getInitialActiveBgSetGroupId(),
    };

    this.setCategory = this.setCategory.bind(this);
  }

  getInitialActiveBgSetGroupId() {
    if (this.props.containsBackgroundCategories) {
      const sortedBgSetGroupIds = Object.values(this.props.data)
        .sort((a, b) => a.order - b.order)
        .map((bgSetGroup) => bgSetGroup.id);

      return sortedBgSetGroupIds[0];
    }
  }

  setCategory(bgSetGroupId) {
    this.setState({ activeBgSetGroupId: bgSetGroupId });
  }

  renderCategorizedBackgroundOptions() {
    const id = this.state.activeBgSetGroupId;
    const activeBgGroup = this.props.data[id].htmlElements;
    const categoryTabs = [];

    const sortedBgSetGroupIds = Object.values(this.props.data)
      .sort((a, b) => a.order - b.order)
      .map((bgSetGroup) => bgSetGroup.id);

    sortedBgSetGroupIds.forEach((bgSetGroupId) => {
      let selected = '';
      if (id === bgSetGroupId) selected = 'group-active';

      categoryTabs.push(
        <li
          className={`bnl-product-config__bg-selector__tab ${selected}`}
          key={`category-tab-${bgSetGroupId}`}
          onClick={() => this.setCategory(bgSetGroupId)}
        >
          {this.props.data[bgSetGroupId].name}
        </li>,
      );
    });

    return (
      <div>
        <ul className="bnl-product-config__bg-selector__tabs-container">
          {categoryTabs}
        </ul>
        {activeBgGroup}
      </div>
    );
  }

  renderBackgrounds() {
    if (this.props.containsBackgroundCategories) {
      return this.renderCategorizedBackgroundOptions();
    }
    return this.props.data.all.htmlElements;
  }

  render() {
    return <div>{this.renderBackgrounds()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    shoot: state.shoot,
  };
}

export default connect(
  mapStateToProps,
  null,
)(BnlOrderProductConfigurationBackgroundsSelector);
