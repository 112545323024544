import React from 'react';
import { useDispatch } from 'react-redux';
import { removeCartItem } from '../../../actions/v3Order';
import styled from 'styled-components';

// TODO: move to a more generic styling file
const RemoveLink = styled.a`
  color: #479de6;
  display: block;
  width: 100%;
  padding-top: 8px;
  &:hover {
    color: #479de6;
  }
`;

const CartItemRemoveButton = ({ cartItem, studentId }) => {
  const dispatch = useDispatch();

  if (cartItem?.isIncentive) {
    return null;
  }

  return (
    <RemoveLink
      onClick={() => dispatch(removeCartItem(cartItem.id, studentId))}
      className="bnl-cart-item__remove cursor-pointer"
    >
      Remove
    </RemoveLink>
  );
};

export default CartItemRemoveButton;
