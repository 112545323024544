import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';

// React.js components

import OrderBuildYourOwnDesktopConfiguration from './order_build_your_own_desktop_configuration';
import OrderBuildYourOwnMobileConfiguration from './order_build_your_own_mobile_configuration';
import OrderBuildYourOwnProducts from './order_build_your_own_products';
import OrderBuildYourOwnPoses from './order_build_your_own_poses';
import OrderBuildYourOwnBackgrounds from './order_build_your_own_backgrounds';
import BnlOrderProductConfigurationNone from '../bnl/bnl_order_product_configuration_none';
import BnlOrderSmsForm from '../bnl/bnl_order_sms_form';

// constants files
import { ORDER_NAVIGATION } from '../../../constants/order_navigation';
import PersonalizationConfigurationV2 from '../bnl/PersonalizationConfigurationV2';

class OrderBuildYourOwn extends Component {
  constructor(props) {
    super(props);

    this.SLOTS_COUNT = this.props.selectedShootProduct.maximum_children_count;

    this.STEPS = {
      products: 1,
      poses: 2,
      backgrounds: 3,
      sms: 4,
      personalization: 6,
      none: 5,
    };

    this.state = {
      activeComponent: this.getInitialActiveComponent(),
      canBeAdded: this.getInitialCanBeAdded(),
      cartItems: this.getInitialCartItems(),
      configuration: this.getInitialConfiguration(),
      freeIndices: this.props.selectedShootProduct.free_indices,
      mobileConfiguring: false,
      idSuffix: this.props.byoIdSuffx,
      selectedChildItem: this.getInitialSelectedChildItem(),
      selectedPose: this.getInitalSelectedPose(),
      slotIndex: this.getInitialSlotIndex(),
      personalizationFormValue: '',
      personalizationAlreadyExists: false,
    };

    this.addConfiguredChildItemsToCart =
      this.addConfiguredChildItemsToCart.bind(this);
    this.addConfiguredChildItem = this.addConfiguredChildItem.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goBackMobile = this.goBackMobile.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.removeConfiguredChildItem = this.removeConfiguredChildItem.bind(this);
    this.selectBackground = this.selectBackground.bind(this);
    this.selectChildItem = this.selectChildItem.bind(this);
    this.selectPose = this.selectPose.bind(this);
    this.selectMobileSlot = this.selectMobileSlot.bind(this);
    this.submitSmsPhone = this.submitSmsPhone.bind(this);
    this.getPersonalizationValue = this.getPersonalizationValue.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.renderPersonalizationForm = this.renderPersonalizationForm.bind(this);
  }

  getInitialActiveComponent() {
    if (
      this.props.configuration &&
      Object.keys(this.props.configuration).length === this.SLOTS_COUNT
    ) {
      return this.STEPS.none;
    }

    return this.STEPS.products;
  }

  getInitialCanBeAdded() {
    let canBeAdded = false;
    if (this.props.configuration) canBeAdded = true;

    return canBeAdded;
  }

  getInitialCartItems() {
    if (this.props.configuration) {
      const cartItems = Object.values(this.props.configuration).map(
        (itemConfig, i) => itemConfig.childItem,
      );

      return cartItems;
    }

    return [];
  }

  getInitialConfiguration() {
    const configuration = this.props.configuration || {};

    return configuration;
  }

  getInitialSelectedChildItem() {
    const slotIndex = this.getInitialSlotIndex();

    if (
      this.props.configuration &&
      Object.keys(this.props.configuration).length > 0 &&
      this.props.configuration[slotIndex]
    ) {
      return Object.values(this.props.configuration)[slotIndex].childItem;
    }
    return null;
  }

  getInitalSelectedPose() {
    const slotIndex = this.getInitialSlotIndex();

    if (
      this.props.configuration &&
      Object.keys(this.props.configuration).length > 0 &&
      this.props.configuration[slotIndex]
    ) {
      return Object.values(this.props.configuration)[slotIndex].pose;
    }

    return null;
  }

  getInitialSlotIndex() {
    let slotIndex = 0;

    if (this.props.configuration) {
      slotIndex = Object.keys(this.props.configuration).length;
    }

    return slotIndex;
  }

  shootIsPreShoot() {
    return this.props.bnlPreShoot.preShoot;
  }

  shootIsOutdoorsBackground() {
    return (
      this.props.shoot.shoot.background_choice_mode ===
      'outdoors_background_only'
    );
  }

  getPrice() {
    let price;

    if (this.props.productIsBnlYouPickX()) {
      price = this.props.selectedShootProduct.price;
    } else {
      const { configuration } = this.state;
      const freeIndices =
        this.props.selectedShootProduct.additional_data.free_indices;

      price = this.props.calculateByoPrice(configuration, freeIndices);
    }

    if (price > 0) {
      return `- ${this.props.displayPrice(price)}`;
    }
  }

  validateConfiguration() {
    const { selectedChildItem } = this.state;

    if (this.shootIsPreShoot()) {
      if (
        this.shootIsOutdoorsBackground() ||
        (selectedChildItem.poses_count === 0 &&
          selectedChildItem.backgrounds_count === 0)
      ) {
        return this.selectedChildItem();
      }
      return this.selectedChildItem() && this.selectedBackground();
    }

    if (this.shootIsOutdoorsBackground()) {
      return this.selectedChildItem() && this.selectedPose();
    }

    if (
      selectedChildItem.poses_count === 0 &&
      selectedChildItem.backgrounds_count === 0
    ) {
      return this.selectedChildItem();
    }

    return (
      this.selectedChildItem() &&
      this.selectedPose() &&
      this.selectedBackground()
    );
  }

  submitSmsPhone() {
    const callback = this.addConfiguredChildItem;
    this.props.submitSmsPhone(callback);
  }

  goToNextStep() {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;
    let goToNextStep = false;
    let activeComponent;

    switch (this.state.activeComponent) {
      case this.STEPS.products:
        if (this.selectedChildItem()) goToNextStep = true;
        if (this.shootIsPreShoot()) {
          // TODO:
          // revisit
          if (this.shootIsOutdoorsBackground()) {
            if (
              this.state.selectedChildItem.sms_required &&
              !this.props.order.smsAcquired
            ) {
              this.setState({
                activeComponent: this.STEPS.sms,
              });
            } else {
              this.addConfiguredChildItem();
            }
            return;
          }

          if (
            this.state.selectedChildItem.poses_count === 0 &&
            this.state.selectedChildItem.backgrounds_count === 0
          ) {
            if (
              this.state.selectedChildItem.sms_required &&
              !this.props.order.smsAcquired
            ) {
              this.setState({
                activeComponent: this.STEPS.sms,
              });
            } else {
              this.addConfiguredChildItem();
            }
            return;
          }

          activeComponent = this.STEPS.backgrounds;
        } else {
          if (
            this.state.selectedChildItem.poses_count === 0 &&
            this.state.selectedChildItem.backgrounds_count === 0
          ) {
            if (
              this.state.selectedChildItem.sms_required &&
              !this.props.order.smsAcquired
            ) {
              this.setState({
                activeComponent: this.STEPS.sms,
              });
            } else {
              this.addConfiguredChildItem();
            }
            return;
          }

          // Handle Geskus Post-Event
          // TODO: revisit
          if (isGeskus && isPostEvent && studentPreviewImageUrl) {
            activeComponent = this.STEPS.backgrounds;
          } else {
            activeComponent = this.STEPS.poses;
          }
        }
        break;
      case this.STEPS.poses:
        if (this.selectedPose()) goToNextStep = true;
        if (this.shootIsOutdoorsBackground()) {
          if (this.state.selectedChildItem.sms_required) {
            activeComponent = this.STEPS.sms;
          } else if (this.state.slotIndex + 1 < this.SLOTS_COUNT) {
            activeComponent = this.STEPS.products;
            this.addConfiguredChildItem();
          } else {
            activeComponent = this.STEPS.none;
          }
        } else {
          activeComponent = this.STEPS.backgrounds;
        }
        break;
      case this.STEPS.backgrounds:
        if (this.selectedBackground()) goToNextStep = true;
        activeComponent = this.STEPS.sms;
        break;
      case this.STEPS.sms:
        if (this.props.smsPhone) goToNextStep = true;
        activeComponent = this.STEPS.products;

        if (this.state.slotIndex + 1 === this.SLOTS_COUNT) {
          activeComponent = this.STEPS.none;
        }
        break;
      default:
        activeComponent = this.STEPS.products;
    }

    if (goToNextStep) {
      this.setState({ activeComponent });
    } else {
      Swal.fire('Please select an option to go to next step');
    }
  }

  processNavigationAtProducts() {
    if (
      this.state.cartItems.length === 0 ||
      // configured and added one item to cart
      (this.state.cartItems.length === 1 && this.state.slotIndex === 0)
    ) {
      this.props.updateUnmountedByBackButton();
      // parent got to next item step but did not click on the next child item
    } else if (
      Object.keys(this.state.configuration).length === this.state.slotIndex
    ) {
      const slotIndex = this.state.slotIndex - 1;
      const { configuration } = this.state;
      const lastInnerConfig = configuration[slotIndex];
      let activeComponent = this.STEPS.backgrounds;
      if (this.shootIsOutdoorsBackground()) {
        activeComponent = this.STEPS.poses;
      }

      this.setState(
        {
          activeComponent,
          canBeAdded: true,
          configuration,
          selectedChildItem: lastInnerConfig.childItem,
          selectedPose: lastInnerConfig.pose,
          slotIndex,
        },
        () => {
          this.props.addToByoIdSuffix(-1);
        },
      );
      // parent got to next item step and clicked on the next child item
    } else if (
      Object.keys(this.state.configuration).length > this.state.slotIndex
    ) {
      const configuration = this.props.cloneObject(this.state.configuration);
      const childItemIds = Object.keys(configuration);
      const lastId = childItemIds[childItemIds.length - 1];
      delete configuration[lastId];
      childItemIds.pop();

      if (this.state.cartItems.length > this.state.slotIndex) {
        this.state.cartItems.pop();
      }

      // lastId = childItemIds[childItemIds.length - 1];
      // let lastInnerConfig = this.state.configuration[lastId];
      const slotIndex = this.state.slotIndex - 1;
      const lastInnerConfig = configuration[slotIndex];
      lastInnerConfig.canBeRemoved = false;
      this.setState(
        {
          activeComponent: this.STEPS.backgrounds,
          canBeAdded: true,
          configuration,
          selectedChildItem: lastInnerConfig.childItem,
          selectedPose: lastInnerConfig.pose,
          slotIndex: this.state.slotIndex - 1,
        },
        () => {
          this.props.addToByoIdSuffix(-1);
        },
      );
    }
  }

  goBack() {
    const configuration = this.props.cloneObject(this.state.configuration);
    let activeComponent;

    const returnFromLastStep = () => {
      const slotIndex = this.state.slotIndex - 1;
      const selectedChildItem = this.state.configuration[slotIndex].childItem;

      this.setState({
        activeComponent: this.STEPS.backgrounds,
        selectedChildItem,
        slotIndex,
      });
    };

    switch (this.state.activeComponent) {
      case this.STEPS.products:
        const cartItems = this.removeCartItem(this.state.slotIndex);
        this.setState({ cartItems }, () => {
          this.processNavigationAtProducts();
        });
        return;
      case this.STEPS.poses:
        delete configuration[this.state.slotIndex];
        this.setState({
          activeComponent: this.STEPS.products,
          configuration,
          selectedChildItem: null,
        });
        return;
      case this.STEPS.backgrounds:
        if (this.shootIsPreShoot()) {
          activeComponent = this.STEPS.products;
        } else {
          activeComponent = this.STEPS.poses;
        }

        const itemConfig = configuration[this.state.slotIndex];
        delete itemConfig.backgroundCodes;
        delete itemConfig.backgroundPrices;

        this.setState({
          activeComponent,
          configuration,
        });
        return;
      case this.STEPS.sms:
        this.setState({ activeComponent: this.STEPS.backgrounds });
        return;
      case this.STEPS.personalization:
        returnFromLastStep();
        return;
      case this.STEPS.none:
        if (this.props.isGeskus) {
          this.setState({ activeComponent: this.STEPS.backgrounds });
        } else {
          returnFromLastStep();
        }
        break;
      default:
        if (this.props.isGeskus) {
          this.setState({ activeComponent: this.STEPS.backgrounds });
        } else {
          returnFromLastStep();
        }
    }
  }

  goBackMobile() {
    let configuration;
    let itemConfig;
    let activeComponent;
    const { slotIndex } = this.state;

    if (!this.state.mobileConfiguring && this.props.editingFromCart) {
      this.props.updateUnmountedByBackButton();
      return;
    }

    switch (this.state.activeComponent) {
      case this.STEPS.products:
        if (this.state.mobileConfiguring) {
          const configuration = this.props.cloneObject(
            this.state.configuration,
          );
          delete configuration[this.state.slotIndex];
          let canBeAdded = false;
          if (Object.keys(configuration).length > 0) canBeAdded = true;

          this.setState({
            canBeAdded,
            configuration,
            mobileConfiguring: false,
          });
        } else if (this.state.slotIndex === 0) {
          this.props.updateOrderActiveComponent(
            ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
          );
        } else {
          configuration = this.deleteItemConfig(slotIndex);
          const cartItems = this.removeCartItem(slotIndex);
          const selectedPose = configuration[slotIndex - 1].pose;
          const selectedChildItem = configuration[slotIndex - 1].childItem;
          let activeComponent = this.STEPS.backgrounds;

          if (this.shootIsOutdoorsBackground()) {
            activeComponent = this.STEPS.poses;
          }

          this.setState({
            activeComponent,
            cartItems,
            configuration,
            mobileConfiguring: true,
            selectedChildItem,
            selectedPose,
            slotIndex: this.state.slotIndex - 1,
          });
        }
        break;
      case this.STEPS.poses:
        configuration = this.props.cloneObject(this.state.configuration);
        itemConfig = configuration[slotIndex];

        delete itemConfig.pose;
        delete itemConfig.poseIds;

        this.setState({
          activeComponent: this.STEPS.products,
          configuration,
          selectedPose: null,
        });
        break;
      case this.STEPS.backgrounds:
        if (this.shootIsPreShoot()) {
          activeComponent = this.STEPS.products;
        } else {
          activeComponent = this.STEPS.poses;
        }

        configuration = this.props.cloneObject(this.state.configuration);
        itemConfig = configuration[slotIndex];

        delete itemConfig.backgroundCodes;
        delete itemConfig.backgroundPrices;

        this.setState({
          activeComponent,
          configuration,
        });
        break;
      case this.STEPS.sms:
        this.setState({ activeComponent: this.STEPS.backgrounds });
        break;
      case this.STEPS.personalization:
        this.setState({ activeComponent: this.STEPS.backgrounds });
        break;
    }
  }

  // validations
  selectedChildItem() {
    return this.state.selectedChildItem;
  }

  selectedPose() {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    // Handle Geskus Post-Event
    // TODO: revisit
    if (isGeskus && isPostEvent && studentPreviewImageUrl) {
      return true;
    }

    if (this.selectedChildItem()) {
      // old code
      // const configuration = this.state.configuration[this.state.selectedChildItem.id];

      // TODO: @sjkim24 is this correct? -- attempting to solve for a condition
      // where this.state.configuration appears to be undefined
      if (this.state.configuration === 'undefined') {
        return false;
      }

      const configuration = this.state.configuration[this.state.slotIndex];
      // TODO:
      // replace 0 with a specific required posts count from rails
      if (configuration.poseIds) {
        return configuration.poseIds.length > 0;
      }
    }

    return false;
  }

  selectedBackground() {
    if (this.selectedChildItem()) {
      const configuration = this.state.configuration[this.state.slotIndex];

      // TODO:
      // replace 0 with a specific required bg count from rails
      if (configuration.backgroundCodes) {
        return configuration.backgroundCodes.length > 0;
      }
    }

    return false;
  }

  selectChildItem(item) {
    const configuration = this.props.cloneObject(this.state.configuration);

    if (Object.keys(configuration).length === this.SLOTS_COUNT) {
      Swal.fire('Please remove an item before adding another one');
      return;
    }

    const modifiedItem = this.props.cloneObject(item);
    // modifiedItem.id = `${modifiedItem.id}_${this.state.idSuffix}`;
    modifiedItem.id = `${modifiedItem.id}_${this.props.byoIdSuffix}`;
    const { slotIndex } = this.state;

    if (!configuration[slotIndex]) configuration[slotIndex] = {};

    const itemConfig = configuration[slotIndex];
    itemConfig.parentId = this.props.selectedShootProduct.id;
    itemConfig.childItem = modifiedItem;

    if (!this.state.selectedChildItem) {
      itemConfig.poseIds = [];
      itemConfig.backgroundCodes = [];
      itemConfig.backgroundPrices = [];
    }

    this.setState(
      {
        configuration,
        selectedChildItem: modifiedItem,
      },
      () => {
        this.goToNextStep();
      },
    );
  }

  selectMobileSlot(slotIndex) {
    if (this.state.slotIndex !== slotIndex) {
      Swal.fire(
        `Please tap Item ${this.state.slotIndex + 1} to begin configuring`,
      );
    } else {
      this.setState({
        activeComponent: this.STEPS.products,
        mobileConfiguring: true,
      });
    }
  }

  selectPose(pose) {
    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfiguration = configuration[this.state.slotIndex];
    itemConfiguration.pose = pose;
    itemConfiguration.poseIds = [pose];

    if (this.shootIsOutdoorsBackground()) {
      if (!itemConfiguration.backgroundCodes) {
        itemConfiguration.backgroundCodes = [];
        itemConfiguration.backgroundPrices = [];
      }
    }

    this.setState(
      {
        configuration,
        selectedPose: pose,
      },
      () => {
        this.goToNextStep();
        this.props.setStudent(pose.student);
      },
    );
  }

  selectBackground(bgOption) {
    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfiguration = configuration[this.state.slotIndex];
    itemConfiguration.backgroundCodes = [bgOption.id];
    itemConfiguration.backgroundPrices = [bgOption.price];
    itemConfiguration.canBeRemoved = true;

    let { mobileConfiguring } = this.state;
    if (this.state.mobileConfiguring && this.props.order.smsAcquired) {
      mobileConfiguring = false;
    }

    this.setState(
      {
        configuration,
        mobileConfiguring,
      },
      () => {
        if (
          this.state.selectedChildItem.sms_required &&
          !this.props.order.smsAcquired
        ) {
          this.goToNextStep();
        } else {
          this.addConfiguredChildItem();
        }
      },
    );
  }

  removeConfiguredChildItem(slotIndex) {
    if (this.state.slotIndex === slotIndex) {
      Swal.fire('Please finish configuring your product first');
    } else {
      const oldConfiguration = this.props.cloneObject(this.state.configuration);
      const cartItems = [];
      const removingChildItem = oldConfiguration[slotIndex].childItem;
      delete oldConfiguration[slotIndex];

      this.state.cartItems.forEach((cartItem, i) => {
        if (i !== slotIndex) cartItems.push(cartItem);
      });

      const newConfiguration = {};
      let newSlotIndex = 0;
      for (const slotIndex in oldConfiguration) {
        newConfiguration[newSlotIndex] = oldConfiguration[slotIndex];
        newSlotIndex += 1;
      }
      this.setState(
        {
          cartItems,
          configuration: newConfiguration,
          slotIndex: this.state.slotIndex - 1,
        },
        () => {
          // this.props.removeBuildYourOwnItem(removingChildItem.id, slotIndex);

          if (
            this.state.activeComponent === this.STEPS.sms ||
            this.state.activeComponent === this.STEPS.none
          ) {
            this.setState({
              activeComponent: this.STEPS.products,
            });
          }
        },
      );
    }
  }

  addConfiguredChildItem() {
    if (this.validateConfiguration()) {
      const cartItems = this.props.cloneArray(this.state.cartItems);
      const { selectedChildItem } = this.state;
      // users can't go back to specific slot index so this isn't needed
      // there can never be a duplicate id
      // let duplicate = false;

      // cartItems.forEach(cartItem => {
      //   if (cartItem.id === selectedChildItem.id) duplicate = true;
      // });

      // if (!duplicate) cartItems.push(selectedChildItem);
      cartItems.push(selectedChildItem);

      let activeComponent;

      if (this.state.slotIndex + 1 === this.SLOTS_COUNT) {
        activeComponent =
          this.props.isGeskus && this.props.shoot.personalizationProduct
            ? this.STEPS.personalization
            : this.STEPS.none;
      } else {
        activeComponent = this.STEPS.products;
      }

      let { mobileConfiguring } = this.state;
      if (this.state.mobileConfiguring) mobileConfiguring = false;

      this.setState(
        {
          activeComponent,
          canBeAdded: true,
          cartItems,
          mobileConfiguring,
          selectedChildItem: null,
          selectedPose: null,
          slotIndex: this.state.slotIndex + 1,
        },
        () => {
          this.props.addToByoIdSuffix(1);
          // TODO:
          // revisit and confirm on what this function does
          // check if there is more pre configured child item based on slot index
          this.preFillSelectedChildItemAndPose();
        },
      );
    } else {
      Swal.fire('Please finish configuring your sheet');
    }
  }

  preFillSelectedChildItemAndPose() {
    const configuration = Object.values(this.state.configuration)[
      this.state.slotIndex
    ];

    if (configuration) {
      this.setState({
        selectedChildItem: configuration.childItem,
        selectedPose: configuration.pose,
      });
    }
  }

  deleteItemConfig(slotIndex) {
    const configuration = this.props.cloneObject(this.state.configuration);

    if (configuration[slotIndex]) {
      delete configuration[slotIndex];
    }

    return configuration;
  }

  removeCartItem(slotIndex) {
    const newCartItems = [];

    this.state.cartItems.forEach((cartItem, i) => {
      if (i !== slotIndex) newCartItems.push(cartItem);
    });

    return newCartItems;
  }

  addConfiguredChildItemsToCart() {
    if (this.props.productIsBnlYouPickX()) {
      this.addConfiguredYpxChildItemsToCart();
    } else {
      // BYO
      this.addConfiguredByoChildItemsToCart();
    }
  }

  addConfiguredByoChildItemsToCart() {
    if (
      (this.state.cartItems.length > 0 &&
        this.state.canBeAdded &&
        this.state.activeComponent === this.STEPS.products) ||
      this.state.activeComponent === this.STEPS.none
    ) {
      this.props.addConfiguredBuildYourOwnItem(this.state.configuration);
    } else if (
      !this.state.canBeAdded ||
      (this.state.canBeAdded &&
        this.state.activeComponent !== this.STEPS.products) ||
      this.state.activeComponent !== this.STEPS.none
    ) {
      Swal.fire('Please finish configuring your sheet');
    } else if (this.state.cartItems.length === 0) {
      Swal.fire(
        'Please configure at least one item to add Build Your Own to cart',
      );
    }
  }

  addConfiguredYpxChildItemsToCart() {
    const maxChildrenCount =
      this.props.selectedShootProduct.maximum_children_count;
    let hasMaximumChildren = true;

    for (let slotIndex = 0; slotIndex < maxChildrenCount; slotIndex++) {
      if (!this.state.configuration[slotIndex]) hasMaximumChildren = false;
    }

    if (!hasMaximumChildren) {
      Swal.fire(`Please configure ${maxChildrenCount} items`);
    } else if (
      this.state.activeComponent === this.STEPS.none ||
      this.state.activeComponent === this.STEPS.personalization
    ) {
      this.props.addConfiguredBuildYourOwnItem(
        this.state.configuration,
        this.state.personalizationFormValue,
      );
    } else if (!this.selectedChildItem()) {
      Swal.fire('Please select a product');
    } else if (!this.shootIsPreShoot() && !this.selectedPose()) {
      Swal.fire('Please select a pose');
    } else if (!this.selectedBackground()) {
      Swal.fire('Please select a background');
    }
  }

  renderInstruction() {
    const number = this.state.slotIndex + 1;

    switch (this.state.activeComponent) {
      case this.STEPS.products:
        return `Select Item ${number}`;
      case this.STEPS.poses:
        return `Select Pose ${number}`;
      case this.STEPS.backgrounds:
        return 'Select A Background';
      case this.STEPS.personalization:
        return 'Personalization';
    }
  }

  getPersonalizationValue() {
    const {
      additionalData: { isGeskus },
      shoot,
    } = this.props;

    const { personalizationFormValue } = this.state;

    if (isGeskus && shoot.personalizationProduct) {
      return personalizationFormValue;
    }

    return null;
  }

  handleOnChange(event) {
    if (event.target.name === 'personalizationProductValue') {
      this.setState({ personalizationFormValue: event.target.value });
    } else {
    }
  }

  renderPersonalizationForm(mobile = false) {
    const {
      additionalData: { isGeskus },
      shoot,
    } = this.props;

    if (
      isGeskus &&
      shoot.personalizationProduct &&
      this.state.activeComponent === this.STEPS.personalization &&
      (this.props.selectedShootProduct.personalization_status ===
        'personalization_required' ||
        this.props.selectedShootProduct.personalization_status ===
          'personalization_optional')
    ) {
      return (
        <PersonalizationConfigurationV2
          handleOnChange={this.handleOnChange}
          personalizationProduct={this.props.shoot.personalizationProduct}
          personalizationFormValue={this.state.personalizationFormValue}
          personalizationAlreadyExists={this.state.personalizationAlreadyExists}
          personalizationType={
            this.props.selectedShootProduct.personalization_type
          }
          requirement={this.props.selectedShootProduct.personalization_status}
          selectedShootProduct={this.props.selectedShootProduct}
        />
      );
    }

    return null;
  }

  renderActiveComponent() {
    switch (this.state.activeComponent) {
      case this.STEPS.products:
        return (
          <OrderBuildYourOwnProducts
            selectChildItem={this.selectChildItem}
            selectedChildItem={this.state.selectedChildItem}
            products={this.props.selectedShootProduct.child_products}
          />
        );
      case this.STEPS.poses:
        return (
          <OrderBuildYourOwnPoses
            configuration={this.state.configuration}
            selectedChildItem={this.state.selectedChildItem}
            selectPose={this.selectPose}
            selectedPose={this.state.selectedPose}
          />
        );
      case this.STEPS.backgrounds:
        return (
          <OrderBuildYourOwnBackgrounds
            configuration={this.state.configuration}
            displayPrice={this.props.displayPrice}
            formattedBackgroundOptions={
              this.props.shoot.formattedBackgroundOptions
            }
            selectBackground={this.selectBackground}
            selectedChildItem={this.state.selectedChildItem}
            selectedPose={this.state.selectedPose}
            selectedShootProduct={this.props.selectedShootProduct}
            slotIndex={this.state.slotIndex}
          />
        );
      case this.STEPS.sms:
        return (
          <div className="col-xs-12 col-md-8 col-lg-8">
            <BnlOrderSmsForm
              errorMessage={this.props.errorMessage}
              handleSmsInputChange={this.props.handleSmsInputChange}
              smsPhone={this.props.smsPhone}
            />
            <div
              className="byo-sms-button button--bnl"
              onClick={this.submitSmsPhone}
            >
              Submit
            </div>
          </div>
        );
      case this.STEPS.personalization:
        return (
          <PersonalizationConfigurationV2
            handleOnChange={this.handleOnChange}
            personalizationProduct={this.props.shoot.personalizationProduct}
            personalizationFormValue={this.state.personalizationFormValue}
            personalizationAlreadyExists={
              this.state.personalizationAlreadyExists
            }
            personalizationType={
              this.props.selectedShootProduct.personalization_type
            }
            requirement={this.props.selectedShootProduct.personalization_status}
            selectedShootProduct={this.props.selectedShootProduct}
          />
        );
      case this.STEPS.none:
        return <BnlOrderProductConfigurationNone />;
    }
  }

  render() {
    const renderActiveComponent = this.renderActiveComponent();
    const requiredChildrenCount = this.SLOTS_COUNT;
    const freeIndices =
      this.props.selectedShootProduct.additional_data.free_indices;
    const cartButtonClass =
      this.state.cartItems.length > 0 && this.state.canBeAdded
        ? 'cursor-pointer'
        : 'disabled';

    return (
      <div className="byo">
        <div
          className="bnl-back-button bnl-back-button--byo bnl-back-button--byo--desktop"
          onClick={this.goBack}
        >
          {'<  Back'}
        </div>
        <div
          className="byo__back-button bnl-back-button--byo--mobile"
          onClick={this.goBackMobile}
        >
          {'<  Back'}
        </div>
        <h3 className="byo__header">
          {this.props.selectedShootProduct.name} {this.getPrice()}
        </h3>
        <OrderBuildYourOwnDesktopConfiguration
          activeComponent={this.state.activeComponent}
          addConfiguredChildItemsToCart={this.addConfiguredChildItemsToCart}
          canBeAdded={this.state.canBeAdded}
          cartItems={this.state.cartItems}
          cloneObject={this.props.cloneObject}
          configuration={this.state.configuration}
          freeIndices={freeIndices}
          goBack={this.goBack}
          productIsBnlYouPickX={this.props.productIsBnlYouPickX}
          removeConfiguredChildItem={this.removeConfiguredChildItem}
          renderActiveComponent={renderActiveComponent}
          SLOTS_COUNT={this.SLOTS_COUNT}
          STEPS={this.STEPS}
        />
        <OrderBuildYourOwnMobileConfiguration
          activeComponent={this.state.activeComponent}
          addConfiguredChildItemsToCart={this.addConfiguredChildItemsToCart}
          canBeAdded={this.state.canBeAdded}
          cartItems={this.state.cartItems}
          cloneObject={this.props.cloneObject}
          configuration={this.state.configuration}
          freeIndices={freeIndices}
          goBackMobile={this.goBackMobile}
          renderPersonalizationForm={this.renderPersonalizationForm}
          mobileConfiguring={this.state.mobileConfiguring}
          productIsBnlYouPickX={this.props.productIsBnlYouPickX}
          removeConfiguredChildItem={this.removeConfiguredChildItem}
          renderActiveComponent={renderActiveComponent}
          selectMobileSlot={this.selectMobileSlot}
          selectedShootProduct={this.props.selectedShootProduct}
          slotIndex={this.state.slotIndex}
          SLOTS_COUNT={this.SLOTS_COUNT}
          STEPS={this.STEPS}
        />
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const {
      additionalData: { student },
      cart,
    } = this.props;

    let personalizationFormValue;
    let personalizationAlreadyExists = false;

    if (student && cart.personalization && cart.personalization[student.id]) {
      personalizationFormValue = cart.personalization[student.id];
      personalizationAlreadyExists = true;
    }

    this.setState({
      personalizationFormValue,
      personalizationAlreadyExists,
    });
  }

  componentWillUnmount() {
    this.props.handleBnlConfigurationUnmount('buildYourOwn');
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlPreShoot: state.bnlPreShoot,
    gallery: state.gallery,
    order: state.order,
    shoot: state.shoot,
    isGeskus: state.additionalData.isGeskus,
    cart: state.cart,
    additionalData: state.additionalData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderBuildYourOwn);
