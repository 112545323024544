import React from 'react';
import styled from 'styled-components';
import CartItemsList from './CartItemsList';

const StudentListItem = styled.li`
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 36px;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const StudentName = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #479de6;
`;

const Eventname = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #444;
`;

const ProductsList = styled.ul`
  list-style: none;
  padding: 0 16px;
  margin: 0;
`;

const StudentOrder = ({ studentId, order }) => (
  <StudentListItem key={`bnl-order-content__cart-items__${studentId}`}>
    <StudentName>
      {order.student.firstName} {order.student.lastName}
    </StudentName>
    <Eventname>{order.event.name}</Eventname>
    <ProductsList>
      <CartItemsList order={order} studentId={studentId} />
    </ProductsList>
  </StudentListItem>
);

export default StudentOrder;
