import {
  APPLY_DISCOUNT_CODE,
  UPDATE_DISCOUNT_CODE,
  RESET_DISCOUNT_CODE,
} from '../actions/action_discount_code';

const INITIAL_STATE = {
  amountOff: 0,
  code: '',
  discountCodeId: null,
  isFreeShipping: false,
  message: '',
  percentOff: 0,
  valid: false,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case APPLY_DISCOUNT_CODE:
      if (action.payload.data) {
        if (action.payload.data.valid) {
          return {
            ...state,
            amountOff: action.payload.data.amount_off,
            code: action.payload.data.code,
            discountCodeId: action.payload.data.discount_code_id,
            isFreeShipping: action.payload.data.is_free_shipping,
            message: action.payload.data.message,
            percentOff: action.payload.data.percent_off,
            valid: action.payload.data.valid,
          };
        }
      }
    case UPDATE_DISCOUNT_CODE:
      return {
        ...state,
        amountOff: action.payload.amountOff,
        code: action.payload.code,
        discountCodeId: action.payload.discountCodeId,
        isFreeShipping: action.payload.isFreeShipping,
        message: action.payload.message,
        percentOff: action.payload.percentOff,
        valid: action.payload.valid,
      };
    case RESET_DISCOUNT_CODE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
