import React, { Component } from 'react';

import { shootProductGroups } from '../../../dummy_data';

class BnlOrderProductGroup extends Component {
  componentDidMount() {}

  renderCheckoutButton() {
    if (this.props.cartIsNotEmpty()) {
      return (
        <div
          className="bnl-product-group__button--right"
          onClick={this.props.goToBnlCart}
        >
          Check Out Now
        </div>
      );
    }

    return null;
  }

  renderNavigationButtons() {
    if (this.props.topProduct) {
      return (
        <div className="bnl-product-group__buttons-container clearfix">
          {this.renderCheckoutButton()}
          <div
            className="bnl-product-group__button--left"
            onClick={this.props.goToDashboard}
          />
        </div>
      );
    }

    return null;
  }

  renderContent() {
    const name = this.props.shootProductGroup.name
      .toLowerCase()
      .split(' ')
      .join('-');

    if (
      this.props.shootProductGroup.image_url &&
      this.props.shootProductGroup.image_url !== ''
    ) {
      let subheader = '';

      if (
        this.props.shootProductGroup.subheader &&
        this.props.shootProductGroup.subheader !== ''
      ) {
        subheader = this.props.shootProductGroup.subheader;
      }

      return (
        <div>
          <h3 className="bnl-product-group__header">
            {this.props.shootProductGroup.name}
          </h3>
          <div className="bnl-product-group__subheader">{subheader}</div>
          <div className="bnl-product-group__image-container">
            <img
              className="bnl-product-group__image--single"
              src={this.props.shootProductGroup.image_url}
            />
          </div>
        </div>
      );
    }
    switch (name) {
      case 'packages':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Easy to order. The best value
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image--single"
                src="https://s3.amazonaws.com/cdn-portraitpics/assets/bnl/Categories_Packages.png"
              />
            </div>
          </div>
        );
      case 'most-popular-package':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              {/* Most Popular Packages */}
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_MOST_POPULAR.png"
              />
            </div>
          </div>
        );
      case 'family-double-package':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Choose two backgrounds!
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_FAMILY_DOUBLE.png"
              />
            </div>
          </div>
        );
      case 'basic-package':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              {/* Most Popular Packages */}
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_BASIC.png"
              />
            </div>
          </div>
        );
      case 'value-double-package':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Choose two backgrounds!
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_VALUE_DOUBLE.png"
              />
            </div>
          </div>
        );
      case 'essential-package':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              {/* Most Popular Packages */}
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_ESSENTIAL.png"
              />
            </div>
          </div>
        );
      case 'digital-packages-&-products':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              {/* Most Popular Packages */}
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_DIGITAL.png"
              />
            </div>
          </div>
        );
      case 'build-your-own-package':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Get exactly what you want
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/cdn-portraitpics/assets/bnl/BYO_Graphic.png"
              />
            </div>
          </div>
        );
      case 'you-pick-6':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Get exactly what you want
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_YOU_PICK_6.png"
              />
            </div>
          </div>
        );
      case 'digital-products':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Unlimited post, print, & share
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/cdn-portraitpics/assets/bnl/DD_Multi_Device.png"
              />
            </div>
          </div>
        );
      case 'novelties':
      case 'add-on-novelties-&-prints':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Portraits brought to life, in your life
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/pics-assets/products/gb/fall-2018/CATEGORY_ADD_ONS_NOVELTIES_PRINTS.png"
              />
            </div>
          </div>
        );
      case 'inspirational-prints':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Specialty prints for just the right occasion
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/cdn-portraitpics/assets/bnl/Inspirational.png"
              />
            </div>
          </div>
        );
      case 'class-photo':
        return (
          <div>
            <h3 className="bnl-product-group__header">
              {this.props.shootProductGroup.name}
            </h3>
            <div className="bnl-product-group__subheader">
              Order your child's class photo!
            </div>
            <div className="bnl-product-group__image-container">
              <img
                className="bnl-product-group__image"
                src="https://s3.amazonaws.com/cdn-portraitpics/products/2/2018-spring/class-photo.png"
              />
            </div>
          </div>
        );
    }
  }

  render() {
    const name = this.props.shootProductGroup.name
      .toLowerCase()
      .split(' ')
      .join('-');

    return (
      <li
        className={this.props.className}
        key={this.props.name}
        onClick={() => {
          this.props.selectProductGroup(this.props.shootProductGroup);
        }}
      >
        {this.renderNavigationButtons()}
        {this.renderContent()}
      </li>
    );
  }
}

export default BnlOrderProductGroup;
