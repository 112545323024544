import React, { Component } from 'react';

// React.js components
import RadioButtons from '../utils/radio_buttons';

class OrderProductAddOnsRetouching extends Component {
  getPrice() {
    let price = 0;

    this.props.photoEnhancements.forEach((photoEnhancement) => {
      if (photoEnhancement.id === 'BASIC') {
        price = photoEnhancement.price;
      }
    });

    return this.props.displayPrice(price);
  }

  render() {
    return (
      <div className="order-product-add-on">
        <div className="add-on__cta-container col-xs-12 col-md-4 col-lg-4">
          <div className="add-on__header-container clearfix">
            <div className="add-on__header">Premium Retouching</div>
            <div className="add-on__subheader">{this.getPrice()}</div>
          </div>
          <div className="add-on__desc">
            <p>Apply retouching to your entire order.</p>
            <p>
              Removes minor skin blemishes and adds subtle enhancements to help
              your student look their best.
            </p>
          </div>
          <div
            onClick={this.props.applyPhotoEnhancement}
            className="add-on__button-cta button--full-width bg--dark-blue"
          >
            Add Retouching
          </div>
          <div
            onClick={this.props.skipPhotoEnhancement}
            className="add-on__done-button button--full-width bg--dark-grey"
          >
            Skip Retouching
          </div>
        </div>
        <div className="add-on__images col-xs-12 col-md-4 col-lg-4">
          <div className="add-on-image-container col-xs-12 col-md-6 col-lg-6">
            <img
              className="add-on__image"
              src="https://s3.amazonaws.com/pics-assets/products/geskus/spring-2019/retouching-before.png"
            />
            <div className="add-on__image-desc">Before</div>
          </div>
          <div className="add-on-image-container col-xs-12 col-md-6 col-lg-6">
            <img
              className="add-on__image"
              src="https://s3.amazonaws.com/pics-assets/products/geskus/spring-2019/retouching-after.png"
            />

            <div className="add-on__image-desc">After</div>
          </div>
        </div>
        <div className="add-on__upsale-container col-xs-12 col-md-4 col-lg-4" />
      </div>
    );
  }
}

export default OrderProductAddOnsRetouching;
