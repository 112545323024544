import { useSelector } from 'react-redux';

export const useCurrentOrderSinglePersonalizationValue = () => {
  const {
    additionalData: { selectedStudent },
    v3Order: { orders },
  } = useSelector((state) => state);

  const order = orders[selectedStudent?.id];

  if (order) {
    for (const cartItem of order.cartItems) {
      // Check personalizationValue on the cartItem

      if (
        cartItem.personalizationValue &&
        cartItem.personalizationValue !== null
      ) {
        return cartItem.personalizationValue;
      }

      // If no personalizationValue on the cartItem, check its childProducts
      if (cartItem.children) {
        for (const childProduct of cartItem.children) {
          if (
            childProduct.personalizationValue &&
            childProduct.personalizationValue !== null
          ) {
            return childProduct.personalizationValue;
          }
        }
      }
    }
  }

  return null;
};
