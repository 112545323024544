import { add } from 'lodash';
import { setProductConfig } from '../../../actions/v3Order';

export const getTotalPages = (shoot, hasPoses) => {
  let skipPoseSelection = false;

  // TODO: revisit this... hasPoses is an object and note a boolean in
  // some cases; presumably not intended
  const additionalData = hasPoses?.additionalData;

  if (shoot.isPrepay) {
    skipPoseSelection = true;
  }

  if (additionalData?.isGeskus) {
    skipPoseSelection = true;
  }

  const skipBackgroundSelection = !shoot.isGreenScreen;
  const pages = ['product'];
  if (!skipPoseSelection) pages.push('pose');
  if (!skipBackgroundSelection) pages.push('background');
  return pages;
};

export const getNextUrl = (current, pages, history, productConfig, event) => {
  const currentUrlIndex = pages.indexOf(current);
  const url = history.location.pathname.split('/');
  let nextUrl = pages[currentUrlIndex + 1];
  let index = parseInt(url[url.length - 1]);

  const currentProduct = productConfig[index]?.product;
  let skipsPersonalization =
    currentProduct?.personalization_status === 'no_personalization';

  // in this case, personalization will be prompted before checkout only
  if (
    event.isPrepay &&
    event.prepayPersonalizationOfferType ===
      'prepay_personalization_prompted_before_checkout_only'
  ) {
    skipsPersonalization = true;
  }

  console.log('%cskipsPersonalization: ' + skipsPersonalization, 'color: lime');
  console.log('CURRENT URL INDEX', currentUrlIndex, current);

  if (currentUrlIndex === pages.length - 1) {
    nextUrl =
      !skipsPersonalization && !productConfig[index]?.personalization
        ? 'personalization'
        : pages[0];
    if (nextUrl !== 'personalization') index += 1;
  }

  if (currentUrlIndex < 0) index += 1;

  const nextHash = nextUrl === 'product' ? '#slots' : '';
  url.splice(url.length - 2, 2);

  const result = `${url.join('/')}/${nextUrl}/${index}${nextHash}`;
  return result;
};

const updateStackRouter = (dispatch, history) => {
  const { pathname, hash } = history.location;
  const paths = [pathname + hash];
  if (!hash && pathname.includes('/product/'))
    paths.unshift(pathname + '#slots');
  dispatch(addToConfigStack(paths));
};

export const addToConfig = ({
  dispatch,
  history,
  productConfig,
  pages,
  itemToAdd,
  event,
  cropIndex = null,
}) => {
  const { pathname, hash } = history.location;
  const currentUrlSplit = pathname.split('/');
  const current = currentUrlSplit[currentUrlSplit.length - 2];
  const index = pathname.split('/').pop().replace('#slots', '');
  let newItem = itemToAdd;

  if (cropIndex !== null && current === 'pose') {
    newItem = { ...itemToAdd };
    newItem.selected_crop = newItem.cropped_images[cropIndex];
  }

  const item = { [current]: newItem };

  if (pathname.includes('product/') && hash !== '#slots')
    item.usedSlotSelectionUi = true;

  dispatch(setProductConfig({ index, itemToAdd: item, cropIndex }));
  history.push(getNextUrl(current, pages, history, productConfig, event));
};
