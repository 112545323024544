import React, { Component } from 'react';

class IncentiveModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configuration: {},
    };

    this.addConfiguredIncentiveItem =
      this.addConfiguredIncentiveItem.bind(this);
  }

  backgroundOptionSelected(code, item) {
    if (item && this.props.incentiveItemConfiguration[item.id]) {
      const backgroundCodes =
        this.props.incentiveItemConfiguration[item.id].backgroundCodes;

      return backgroundCodes.indexOf(code) > -1 ? true : false;
    }

    return false;
  }

  // configureIncentiveItem(code) {
  //   const incentiveItem = this.props.incentiveCartItems[0];
  //   let configuration = {};
  //
  //   configuration[incentiveItem.id] = { backgroundCodes: [parseInt(code)] };
  //
  //   this.setState({ configuration: configuration });
  // }

  addConfiguredIncentiveItem() {
    $('#incentive-container').modal('hide');
    this.props.addConfiguredIncentiveItem(
      this.props.incentiveItemConfiguration
    );
  }

  renderBackgroundOptions() {
    let options = [];
    const item = this.props.incentiveCartItems[0];

    for (let code in this.props.formattedBackgroundOptions) {
      const bgOption = this.props.formattedBackgroundOptions[code];
      const selected = this.backgroundOptionSelected(parseInt(code), item)
        ? 'order-background-option--selected'
        : '';

      const style = {
        backgroundImage: `url('${bgOption.image_thumbnail}')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      options.push(
        <li
          onClick={() => this.props.configureIncentiveItem(code)}
          className={`background-option col-xs-6 col-md-2 col-lg-2 cursor-pointer ${selected}`}
          key={`background-option-${code}`}
        >
          <div style={style} className="background-option__image" />
        </li>
      );
    }

    return options;
  }

  render() {
    return (
      <div id="incentive-container" className="modal fade bd-example-modal-lg">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="incentive-header">
              {/* TODO: bonus item name should be dynamic */}
              Congratulations! Your order qualifies for a free bonus item: Brown
              Leatherette Keychains (Set of 2).
            </div>
            <div className="incentive-subheader">
              Please select a background for your bonus item.
            </div>
            <ul className="background-options-list row">
              {this.renderBackgroundOptions()}
            </ul>
            <div
              onClick={this.addConfiguredIncentiveItem}
              className="button bg--dark-blue"
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncentiveModalContainer;
