const GESKUS = 'geskus';
const BNL = 'bnl';
const JELLYJAR = 'jellyjar';
const GB = 'gb';
const NORMANDY = 'normandy';
const TIPPING = 'tipping';
const MARCEL = 'marcel';
const CLASSACT = 'classact';
const WAGNER = 'wagner';
const BIELMAR = 'bielmar';

export {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
};
