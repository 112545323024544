import React, { Component } from 'react';

class BnlOrderProductMostPopular extends Component {
  render() {
    const product = this.props.product;
    const imageContainer = 'bnl-product-most-popular__image-container';

    return (
      <div className="row">
        <div className="bnl-product-most-popular-left col-xs-12 col-md-5 col-lg-5">
          <h3 className="bnl-product-most-popular__header">
            {product.name} - Our Most Popular Option
          </h3>
          <div className={`${imageContainer} ${imageContainer}--mobile`}>
            <img
              className="bnl-product-most-popular__image"
              src={product.image_thumbnail}
            />
          </div>
          <div className="bnl-product__items row">
            {this.props.renderContentsOrDesc(product)}
          </div>
          <div
            className="bnl-product__button button--round-border bg--white-black cursor-pointer"
            onClick={() => this.props.selectShootProduct(product)}
          >
            Get {product.name} - {this.props.displayPrice(product.price)}
          </div>
        </div>
        <div className="bnl-product-most-popular-right col-xs-12 col-md-7 col-lg-7">
          <div className="bnl-product-most-popular__image-container">
            <img
              className="bnl-product-most-popular__image"
              src={product.image_thumbnail}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BnlOrderProductMostPopular;
