import React, { Component } from 'react';

class ProductDigitalDownload extends Component {
  renderButton(product, className) {
    switch (product.name) {
      case 'Digital Download':
        return (
          <div
            className={`${className}__button button--small bg--white-light-blue`}
            onClick={this.props.selectShootProduct}
          >
            Select
          </div>
        );
      case 'Digital Download 6 Pack':
        return (
          <div
            className={`${className}__button button--small bg--dark-blue`}
            onClick={this.props.selectShootProduct}
          >
            Pick Six
          </div>
        );
    }
  }

  render() {
    const product = this.props.product;
    const pricingModel = this.props.shoot.pricing_model;

    let ddOfferImgSrc;

    // TODO: clean up how we're handling this

    if (
      (pricingModel && pricingModel.name === 'CMG18') ||
      (pricingModel && pricingModel.name === 'CM18')
    ) {
      ddOfferImgSrc =
        'https://s3.amazonaws.com/pics-assets/products/geskus/2018/commencement/Digital-Download-Blue-Boards.png';
    } else {
      if (
        product.name === 'Digital Download 6 Pack' ||
        product.name === 'Digital Download 6-Pack'
      ) {
        ddOfferImgSrc =
          'https://s3.amazonaws.com/cdn-portraitpics/products/1/2018-spring/DD6_FullGraphics_1200X600.jpg';
      } else {
        ddOfferImgSrc =
          'https://s3.amazonaws.com/cdn-portraitpics/products/1/2018-spring/DD1_FullGraphics_1200X600.jpg';
      }
    }

    const className = `product-${product.name
      .toLowerCase()
      .split(' ')
      .join('-')}`;

    return (
      <div className={className} onClick={this.props.selectShootProduct}>
        <img src={ddOfferImgSrc} />
      </div>
    );
  }
}

export default ProductDigitalDownload;
