import React, { useEffect } from 'react';

const DashboardRedirect = ({ history }) => {
  useEffect(() => {
    history.push('/dashboard');
  }, [history]);

  return null; // render nothing, this component is just for redirection
};

export default DashboardRedirect;
