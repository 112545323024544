import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  turnOnNotification,
  turnOffNotification,
} from '../../actions/action_notification';

class Notification extends Component {
  render() {
    const slide = this.props.notification.on
      ? 'notification-slide-down-and-up'
      : '';

    return (
      <div className={`notification ${slide}`}>
        <div>{this.props.notification.message}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { notification: state.notification };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ turnOnNotification, turnOffNotification });
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
