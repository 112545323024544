import { domainToCompanyToken } from '../constants/companiesByDomain';

export const getCompanyTokenFromURL = (fullUrl) => {
  const url = new URL(fullUrl);
  const hostname = url.hostname;

  for (let key in domainToCompanyToken) {
    if (hostname.includes(key)) {
      return domainToCompanyToken[key];
    }
  }

  return null;
};
