import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AssignStudentButton,
  BackLinkWrapper,
  Card,
  Dropdown,
  EventName,
  EventYear,
  GalleryImage,
  GalleryPassword,
  GalleryResultsWrapper,
  PrimaryLink,
  ResultCard,
  ResultsSection,
  SearchDetails,
  StudentAssignedMessage,
  StudentAssignmentSection,
  StudentAssignmentWrapper,
  StudentName,
  StyledInput,
  Title,
  UtilityLink,
} from '../../../../styles/StudentKeyLookupStyles';

const GalleryResults = ({
  error,
  errorMessage,
  formError,
  galleries,
  getAssignedStudent,
  handleAssignStudent,
  handleDropdownChange,
  handleFirstNameChange,
  handleFormSubmit,
  handleLastNameChange,
  handleSelectGallery,
  isExistingStudent,
  loading,
  lookupPerformed,
  newStudentFirstName,
  newStudentLastName,
  selectedStudentId,
  selectedVenue,
  setGalleries,
  setLookupPerformed,
  students,
  studentId,
}) => {
  // TODO: move to a const file?
  const BASE_IMAGE_URL = 'https://pics-previews.s3.amazonaws.com/';
  const BASE_WATERMARKED_IMAGE_URL =
    'https://pics-previews-watermarked.s3.amazonaws.com/';

  const [galleryToAssignId, setGalleryToAssignId] = useState(null);
  const [studentFormIsOpen, setStudentFormIsOpen] = useState(false);

  const handleAddNewStudentClick = (event) => {
    console.log('handleAddNewStudentClick');
    console.log(event);
    event.preventDefault();
    setStudentFormIsOpen(true);
  };

  const handleActivateGalleryForAssignment = (galleryId) => {
    setGalleryToAssignId(galleryId);
  };

  return (
    <ResultsSection>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        errorMessage ? (
          <p>Error: {errorMessage}</p>
        ) : (
          <p>Error: could not find the requested information.</p>
        )
      ) : galleries && galleries.length > 0 ? (
        <>
          <Card>
            <Title>
              <strong>Step 3</strong>
              <br />
              Select a Gallery result to assign to one of your students.
            </Title>
            <SearchDetails>
              school: {selectedVenue.name}
              <br />
              student ID: {studentId}
            </SearchDetails>
            <BackLinkWrapper>
              <UtilityLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setGalleries([]);
                  setLookupPerformed(false);
                }}
              >
                back to step 2
              </UtilityLink>
            </BackLinkWrapper>
          </Card>
          {/* <p>{galleries.length} results.</p> */}
          {galleries.map((gallery) => {
            const assignedStudent = getAssignedStudent(gallery.id);
            return (
              <ResultCard
                key={gallery.id}
                // selected={
                //   selectedGalleries.includes(gallery.id) && !assignedStudent
                // }
                // onClick={() =>
                //   !assignedStudent && toggleGallerySelection(gallery.id)
                // }
                style={{ pointerEvents: assignedStudent ? 'none' : 'auto' }}
              >
                <StudentName>
                  {gallery.student_first_name} {gallery.student_last_name}
                </StudentName>
                <EventName>{gallery.event_name}</EventName>
                <EventYear>{gallery.event_year}</EventYear>
                <GalleryPassword>
                  gallery password: {gallery.password}
                </GalleryPassword>
                <GalleryResultsWrapper>
                  {gallery.images.map((image) => {
                    const imageSrc =
                      image.watermarked_preview_filename !== null &&
                      image.watermarked_preview_filename !== undefined
                        ? `${BASE_WATERMARKED_IMAGE_URL}${image.watermarked_preview_filename}`
                        : `${BASE_IMAGE_URL}${image.web_preview_filename}`;
                    return (
                      <GalleryImage
                        key={image.web_preview_filename}
                        src={imageSrc}
                        alt="Gallery Image Preview"
                      />
                    );
                  })}
                </GalleryResultsWrapper>
                <StudentAssignmentWrapper>
                  {assignedStudent ? (
                    <StudentAssignedMessage>
                      gallery assigned to:{' '}
                      <strong>
                        {assignedStudent.first_name} {assignedStudent.last_name}{' '}
                      </strong>{' '}
                      &#x2705;
                    </StudentAssignedMessage>
                  ) : (
                    !galleryToAssignId && (
                      <AssignStudentButton
                        onClick={() => {
                          handleActivateGalleryForAssignment(gallery.id);
                        }}
                      >
                        Assign Gallery to Student
                      </AssignStudentButton>
                    )
                  )}
                  {galleryToAssignId === gallery.id && (
                    <StudentAssignmentSection>
                      <p>
                        Select a student from the dropdown or{' '}
                        <PrimaryLink
                          href="#"
                          onClick={(e) => {
                            handleAddNewStudentClick(e);
                          }}
                        >
                          {' '}
                          add a new student
                        </PrimaryLink>
                        .
                      </p>
                      {formError && <p style={{ color: 'red' }}>{formError}</p>}
                      {isExistingStudent() && (
                        <p style={{ color: 'red' }}>
                          Error: Student already exists. Please select their
                          name from the dropdown.
                        </p>
                      )}
                      <Dropdown
                        value={selectedStudentId}
                        onChange={handleDropdownChange}
                        // onChange={(e) => setSelectedStudent(e.target.value)}
                      >
                        <option value="">Select a Student</option>
                        {students.map((student) => (
                          <option key={student.id} value={student.id}>
                            {student.first_name} {student.last_name}
                          </option>
                        ))}
                      </Dropdown>
                      <div>
                        {studentFormIsOpen && (
                          <>
                            <StyledInput
                              type="text"
                              placeholder="First Name"
                              value={newStudentFirstName}
                              onChange={handleFirstNameChange}
                            />

                            <StyledInput
                              type="text"
                              placeholder="Last Name"
                              value={newStudentLastName}
                              onChange={handleLastNameChange}
                            />
                          </>
                        )}
                      </div>
                      <div>
                        <AssignStudentButton
                          onClick={(e) => {
                            handleFormSubmit(gallery.id, e);
                          }}
                        >
                          Assign Gallery to Student
                        </AssignStudentButton>
                      </div>
                    </StudentAssignmentSection>
                  )}
                </StudentAssignmentWrapper>
              </ResultCard>
            );
          })}
        </>
      ) : lookupPerformed ? (
        <p>No results.</p>
      ) : (
        <></>
      )}
    </ResultsSection>
  );
};

export default GalleryResults;

GalleryResults.propTypes = {
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  formError: PropTypes.string.isRequired,
  galleries: PropTypes.array.isRequired,
  getAssignedStudent: PropTypes.func.isRequired,
  handleAssignStudent: PropTypes.func.isRequired,
  handleDropdownChange: PropTypes.func.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleSelectGallery: PropTypes.func.isRequired,
  isExistingStudent: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  lookupPerformed: PropTypes.bool.isRequired,
  newStudentFirstName: PropTypes.string.isRequired,
  newStudentLastName: PropTypes.string.isRequired,
  selectedStudentId: PropTypes.string.isRequired,
  selectedVenue: PropTypes.object.isRequired,
  setGalleries: PropTypes.func.isRequired,
  setLookupPerformed: PropTypes.func.isRequired,
  students: PropTypes.array.isRequired,
  studentId: PropTypes.string.isRequired,
};
