import React, { Component } from 'react';
import Swal from 'sweetalert2';

class GeskusIncentiveConfigurationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartItems: this.getInitialConfiguringItems(),
      configuration: this.props.configuration,
      configuringIndex: 0,
    };

    this.processConfiguration = this.processConfiguration.bind(this);
    this.selectBackground = this.selectBackground.bind(this);
  }

  getInitialConfiguringItems() {
    const items = this.props.cartItems.filter((item) => {
      if (
        !this.props.configuration[item.id].configured &&
        item.requires_image
      ) {
        return item;
      }
    });

    return items;
  }

  getSelectedBackground() {
    const configuringItemId = this.configuringItem().id;
    const code = this.state.configuration[configuringItemId].backgroundCodes[0];

    if (code) {
      return this.props.formattedBackgroundOptions[code];
    }
  }

  configuringItem() {
    return this.state.cartItems[this.state.configuringIndex];
  }

  shootIsNotOutdoors() {
    return this.props.backgroundChoiceMode !== 'outdoors_background_only';
  }

  hasItemsToConfigureNext() {
    let hasItemsToConfigure = false;
    const configuringItemIds = Object.keys(this.state.configuration);
    const that = this;

    this.state.cartItems.forEach((item, i) => {
      if (i > this.state.configuringIndex) {
        const itemConfig = that.state.configuration[item.id];
        if (!itemConfig.configured) hasItemsToConfigure = true;
      }
    });

    return hasItemsToConfigure;
  }

  nextItemToConfigureIsSpecialProduct() {
    const item = this.state.cartItems[this.state.configuringIndex + 1];

    return item.pics_product_type === 'Special Product';
  }

  selectBackground(option) {
    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfig = configuration[this.configuringItem().id];
    itemConfig.backgroundCodes = [option.id];

    this.setState({ configuration });
  }

  presetConfiguration() {
    if (this.shootIsNotOutdoors()) {
      this.props.cartItems.forEach((item) => {
        const itemConfig = this.state.configuration[item.id];

        if (!itemConfig.configured && item.requires_image) {
          // first bg
          const bgId = Object.keys(this.props.formattedBackgroundOptions)[0];
          itemConfig.backgroundCodes = [bgId];
        }
      });
    }

    this.props.addConfiguredGeskusIncentiveItem(this.state.configuration);
  }

  processConfiguration() {
    if (this.shootIsNotOutdoors() && !this.getSelectedBackground()) {
      Swal.fire('Please select a background');
    } else {
      const configuringItemId = this.configuringItem().id;
      const { configuration } = this.state;
      const itemConfig = configuration[configuringItemId];
      itemConfig.configured = true;

      if (this.hasItemsToConfigureNext()) {
        let increment = 1;
        if (this.nextItemToConfigureIsSpecialProduct()) increment += 1;

        this.setState({
          configuration,
          configuringIndex: this.state.configuringIndex + increment,
        });
      } else {
        this.setState({ configuration }, () => {
          this.props.addConfiguredGeskusIncentiveItem(this.state.configuration);
        });
      }
    }
  }

  renderSelectedBackgroundOption() {
    if (this.shootIsNotOutdoors()) {
      const backgrounds = [];
      const configuringItemId = this.configuringItem().id;

      if (this.getSelectedBackground()) {
        const url = this.getSelectedBackground().image_thumbnail;
        const style = {
          backgroundImage: `url("${url}")`,
          backgroundSize: 'cover',
        };

        return (
          <div>
            <div>Selected Background(s)</div>
            <div
              style={style}
              className="order-background-option"
              key="order-background-option"
            />
          </div>
        );
      }
      return (
        <div>
          <div>Selected Background(s)</div>
          <div
            className="order-background-option order-background-option--empty"
            key="order-background-option-missing"
          >
            1
          </div>
        </div>
      );
    }
  }

  renderButton() {
    let text = 'Done';

    if (this.hasItemsToConfigureNext()) {
      text = 'Next';
    }

    return (
      <div className="button bg--dark-blue" onClick={this.processConfiguration}>
        {text}
      </div>
    );
  }

  showDisclaimerText() {
    const studentImage = this.props.getStudentImage();

    const disclaimerStyle = {
      fontStyle: 'italic',
    };

    if (studentImage.imageDisclaimer) {
      return (
        <div>
          <p style={disclaimerStyle}>{studentImage.imageDisclaimer}</p>
        </div>
      );
    }

    return null;
  }

  renderDemoStudent() {
    if (this.shootIsNotOutdoors()) {
      let url = '';

      if (this.getSelectedBackground()) {
        url = this.getSelectedBackground().image_thumbnail;
      }

      const bgStyle = {
        backgroundImage: `url("${url}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        float: 'left',
        position: 'absolute',
      };

      const studentImage = this.props.getStudentImage();

      let className;
      let studentImageUrl;
      let desc;

      className = studentImage.className;
      desc = studentImage.desc;

      const studentStyle = {
        backgroundImage: `url("${studentImage.url}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return (
        <div className="order-options__demo col-lg-4 col-xs-12 col-md-4">
          <div className="order-options__demo-desc">{desc}</div>
          {this.showDisclaimerText()}
          <div className="order-options__demo-background" style={bgStyle} />
          <div
            className={`order-options__demo-student ${className}`}
            style={studentStyle}
          />
        </div>
      );
    }
  }

  renderBackgroundOptions() {
    if (this.shootIsNotOutdoors()) {
      const bgOptions = [];

      for (const bgId in this.props.formattedBackgroundOptions) {
        const option = this.props.formattedBackgroundOptions[bgId];
        const style = {
          backgroundImage: `url("${option.image_thumbnail}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        bgOptions.push(
          <li
            className="order-background-option cursor-pointer"
            key={`background-option-${bgId}`}
            onClick={() => this.selectBackground(option)}
            style={style}
          />,
        );
      }

      return (
        <div className="product-config__background-options col-lg-4 col-xs-12 col-md-4">
          <div className="order-background-options__header">
            Select Background(s)
          </div>
          <ul className="order-background-options__list equal-height">
            {bgOptions}
          </ul>
        </div>
      );
    }
  }

  render() {
    const colName = this.shootIsNotOutdoors()
      ? 'col-xs-12 col-md-4 col-lg-4'
      : 'product-config__info--outdoors';
    return (
      <div className="order-product-config row">
        <div className={`product-config__info ${colName}`}>
          <div
            className="order-product-config__home-button cursor-pointer"
            onClick={this.props.goToProductGroups}
          >
            {'<  Back'}
          </div>
          <div className="product-config-info__desc clearfix">
            <div className="product-config-info__product-name">
              {this.configuringItem().name}
            </div>
            <div className="product-config-info__product-price">FREE</div>
          </div>
          <div className="product-config-info__product-img-container clearfix">
            <img
              className="product-config-info__product-img"
              src={this.configuringItem().image_thumbnail}
            />
            <div className="product-config-info__product-desc-container">
              <div className="product-config-info__product-desc">
                {this.configuringItem().description}
              </div>
            </div>
          </div>
          {this.renderSelectedBackgroundOption()}
          {this.renderButton()}
        </div>
        {this.renderDemoStudent()}
        {this.renderBackgroundOptions()}
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.presetConfiguration();
  }
}

export default GeskusIncentiveConfigurationContainer;
