import { push } from 'connected-react-router';
import {
  removeCartItem,
  removeCartItems,
  resetRetouching,
  V3_ADD_TO_CART,
  V3_DECLINE_INTERSTITIAL_PRODUCT,
  V3_DECLINE_PERSONALIZATION,
  V3_DECLINE_PRE_CART_OFFER_PRODUCT,
  V3_DECLINE_RETOUCHING,
  V3_MARK_PRE_CART_OFFER_PRODUCT_AS_PROMPTED,
  V3_REMOVE_CART_ITEM,
  V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS,
  V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS,
  V3_SUBMIT_PERSONALIZATION,
} from '../actions/v3Order';
import cartUtils from '../utils/cart';

const postConfigRoutingMiddleware =
  ({ history }) =>
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    // Call the next middleware in the chain
    next(action);

    const {
      additionalData: { selectedStudent },
      v3Order: { orders },
      shoot: {
        incentiveProducts,
        interstitialProducts,
        retouchingProducts,
        suggestedProducts,
        additionalOfferProducts,
      },
    } = getState();

    // Wait for promise to resolve before proceeding
    if (action.payload instanceof Promise) {
      try {
        const resolvedPayload = await action.payload;
        action = { ...action, payload: resolvedPayload };
      } catch (error) {
        // Handle error if promise rejects
        console.error('Error in action:', action.type, error);
      }
    } else {
      // Add to cart
      // Redirect in the following order:
      // 1. If retouching is required, redirect to retouching UI
      // 2. If interstitial product should be prompted, redirect to interstitial UI
      // 3. If incetnive product has been activated, redirect to incentive UI
      // 4. If 'suggested products' category is present, redirect to suggested products UI
      if (
        action.type === V3_ADD_TO_CART ||
        action.type === V3_SUBMIT_PERSONALIZATION ||
        action.type === V3_DECLINE_PERSONALIZATION ||
        action.type === V3_SUBMIT_PERSONALIZATION ||
        action.type === V3_DECLINE_INTERSTITIAL_PRODUCT
      ) {
        const currentOrder = orders[selectedStudent.id];
        const cartItems = currentOrder.cartItems || [];
        const shouldPromptRetouching = currentOrder.retouching?.shouldPrompt;
        const shouldPromptPersonalization =
          currentOrder.personalization?.shouldPrompt;

        console.log(
          'middleware -- should prompt personalization: ',
          shouldPromptPersonalization,
        );

        const cartItemsIncludeRetouching =
          cartUtils.cartItemsIncludeRetouching(cartItems);

        const cartItemsContainRetouchingProduct =
          cartUtils.cartItemsContainRetouchingProduct(cartItems);

        const eligibleIncentivesForCart = cartUtils.eligibleIncentivesForCart(
          orders,
          incentiveProducts,
        );

        const eligibleIncentivesForOrder = cartUtils.eligibleIncentivesForOrder(
          cartItems,
          incentiveProducts,
        );

        const interstitialProductsToPrompt =
          cartUtils.interstitialProductsToPrompt(
            interstitialProducts,
            currentOrder.interstitialProductsPrompted,
            cartItems,
          );

        if (
          eligibleIncentivesForCart &&
          eligibleIncentivesForCart?.length > 0
        ) {
          console.log('ROUTING TO *INCENTIVES FOR CART* VIA MIDDLEWARE');
          return dispatch(push('/v3/incentives/config'));
        }

        if (
          eligibleIncentivesForOrder &&
          eligibleIncentivesForOrder?.length > 0
        ) {
          console.log('ROUTING TO INCENTIVES VIA MIDDLEWARE');
          return dispatch(push('/v3/incentives/config'));
        }

        // TODO: comment in when personalization intersitial is ready
        // if (shouldPromptPersonalization) {
        //   return dispatch(push('/v3/personalization'));
        // }

        // TODO: move this to a cart utils function
        const hasGroupCartItem = cartItems.some(
          (cartItem) => cartItem.isGroup === true,
        );

        const filteredInterstitialProducts = hasGroupCartItem
          ? interstitialProductsToPrompt.filter(
              (product) => product.image_type !== 'group_image',
            )
          : interstitialProductsToPrompt;

        if (filteredInterstitialProducts.length > 0) {
          console.log('ROUTING TO INTERSTITIAL OFFER VIA MIDDLEWARE');
          return dispatch(push('/v3/interstitial'));
        }

        // redirect to retouching UI as needed

        if (
          shouldPromptRetouching &&
          !cartItemsIncludeRetouching &&
          !cartItemsContainRetouchingProduct &&
          retouchingProducts?.length > 0 &&
          !cartUtils.cartItemsAreGroupProductsOnly(cartItems)
        ) {
          console.log('ROUTING TO RETOUCHING VIA MIDDLEWARE');
          return dispatch(push('/v3/retouching'));
        }

        if (
          action.type === V3_ADD_TO_CART &&
          action.payload?.cartObject?.isPreCartOfferProduct
        ) {
          if (action.payload.redirectSource === 'dashboard') {
            console.log(
              'ADDED PRE-CART OFFER PRODUCT; ROUTING TO DASHBOARD VIA MIDDLEWARE',
            );
            return dispatch(push('/dashboard'));
          }

          console.log(
            'ADDED PRE-CART OFFER PRODUCT; ROUTING TO CART VIA MIDDLEWARE',
          );
          return dispatch(push('/v3/cart'));
        }

        // redirect to 'suggested products' UI as needed
        if (suggestedProducts && suggestedProducts?.length > 0) {
          console.log('ROUTING TO SUGGESTED PRODUCTS VIA MIDDLEWARE');
          return dispatch(push('/v3/suggested-products'));
        }

        if (additionalOfferProducts && additionalOfferProducts?.length > 0) {
          return dispatch(push('/v3/additional-offers'));
        }

        // ...otherwise, redirect to primary offers UI
        console.log('ROUTING TO OFFERS VIA MIDDLEWARE');
        dispatch(push('/v3/offers'));
      }

      if (action.type === V3_DECLINE_PRE_CART_OFFER_PRODUCT) {
        if (action.payload.redirectSource === 'dashboard') {
          console.log(
            'DECLINED PRE-CART OFFER PRODUCT; ROUTING TO DASHBOARD VIA MIDDLEWARE',
          );
          return dispatch(push('/dashboard'));
        }
        console.log('ROUTING TO CART VIA MIDDLEWARE');
        dispatch(push('/v3/cart'));
      }

      // The following assumes that the user adds/skips personalization on an
      // interstitial UI while en route to the cart
      if (
        action.type === V3_SET_PERSONALIZATION_ON_ALL_ELIGIBLE_CART_ITEMS ||
        action.type === V3_SKIP_PERSONALIZATION_ON_ALL_CART_ITEMS
      ) {
        const currentOrder = orders[selectedStudent.id];
        if (!currentOrder.personalization?.shouldPrompt) {
          if (action.payload.redirectSource === 'dashboard') {
            return dispatch(push('/dashboard'));
          }

          return dispatch(push('/v3/cart'));
        }
      }

      // Decline retouching
      if (action.type === V3_DECLINE_RETOUCHING) {
        if (suggestedProducts && suggestedProducts?.length > 0) {
          console.log('ROUTING TO SUGGESTED PRODUCTS VIA MIDDLEWARE');
          return dispatch(push('/v3/suggested-products'));
        }

        dispatch(push('/v3/offers'));
      }

      if (action.type === V3_DECLINE_PERSONALIZATION) {
        dispatch(push('/v3/offers'));
      }

      // Reset retouching prompt status if user removes all retouching items from cart.
      if (action.type === V3_REMOVE_CART_ITEM) {
        const { studentId } = action.payload;
        const cartItems = orders[studentId]?.cartItems;
        const unlockedGroups = cartUtils.unlockedGroups(cartItems);

        if (unlockedGroups?.length > 0) {
          const productIdsWithLockedBackgrounds =
            cartUtils.productIdsWithLockedBackgrounds(
              cartItems,
              unlockedGroups,
            );
          dispatch(removeCartItems(productIdsWithLockedBackgrounds, studentId));
        }

        const lockedProductIds = cartUtils.lockedProductIds(cartItems);

        if (cartUtils.hasLockedProductsNoUnlockers(cartItems)) {
          if (lockedProductIds.length > 0) {
            dispatch(removeCartItems(lockedProductIds, studentId));
          }
        }

        if (
          cartUtils.orderHasNoRetouching(orders && cartItems) &&
          orders[studentId].retouching.shouldPrompt === false
        ) {
          dispatch(resetRetouching(studentId));
        }

        if (cartItems?.length === 1 && cartItems[0].isPreCartOfferProduct) {
          dispatch(removeCartItem(cartItems[0].id, studentId));
        }

        if (
          cartItems &&
          cartItems.length > 0 &&
          cartItems.every(
            (item) =>
              item.isPreCartOfferProduct ||
              item.isRetouching ||
              item.isPersonalization,
          )
        ) {
          dispatch(
            removeCartItems(
              cartUtils.preCartOfferProductIds(cartItems),
              studentId,
            ),
          );
        }
      }
    }

    // Proceed with next middleware or reducer
    return action;
  };

export default postConfigRoutingMiddleware;
