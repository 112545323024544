import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dotenv from 'dotenv';
import WithTippy from '../../utils/tippy';

dotenv.config();

const linkStyles = `
  color: #479DE6;
  text-decoration: underline;
`;

const PrepayCode = styled.p`
  a {
    ${linkStyles}
  }
  margin-bottom: 8px !important;
  margin-top: 4px !important;
  font-size: 14px;

  @media (max-width: 991px) {
    margin-top: 16px !important;
  }
`;

const StudentIdLookup = styled.p`
  a {
    ${linkStyles}
  }
  font-size: 14px;
`;

const KeyFormWrapper = styled.div`
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  padding-top: 32px;
  margin-bottom: 36px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  p {
    text-align: center;
    line-height: 1.54;
    margin-bottom: 0;
  }
`;

// .bnl-gallery-key-form-container,
// .bnl-gallery-dashboard-notice {
//   background: $white;
//   border-radius: 5px;
//   margin-bottom: 25px;
//   padding: 25px;
//   @include card-shadow;
//   p {
//     text-align: center;
//     line-height: 1.54;
//     margin-bottom: 0;
//   }
// }

const BnlParentGalleryKeyForm = ({
  companyName,
  handleOnChange,
  handleKeyPress,
  galleryKey,
  goToStudentSelection,
  presetEventKey,
  eventLookupByZipcodeEnabled,
}) => {
  // const color = !galleryKey ? 'bg--bnl-dark-grey' : 'bg--bnl-light-blue';
  const color = 'bg--bnl-light-blue';

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      goToStudentSelection();
    }
  };

  const showSecurityMessage = () => {
    if (companyName === 'wagner') {
      return ' For security purposes, please enter a Pre-order or Gallery Password below:';
    }

    if (companyName === 'geskusprint') {
      return "For security purposes, please enter your student's unique code below and click 'Start Order'";
    }

    return "For security reasons, please enter a Prepay Code or Gallery Password and click 'Start Order'";
  };

  // TODO: This value should ultimately be set in the backend
  const studioShouldShowLookupTools = () =>
    ['bnlschoolpictures', 'wagner'].includes(companyName);

  const showLookupByVenueZipcode =
    studioShouldShowLookupTools() &&
    process.env.REACT_DASHBOARD_VENUE_LOOKUP_BY_ZIPCODE &&
    process.env.REACT_DASHBOARD_VENUE_LOOKUP_BY_ZIPCODE === 'on' &&
    eventLookupByZipcodeEnabled;

  const showGalleryLookupByStudentId =
    studioShouldShowLookupTools() &&
    process.env.REACT_DASHBOARD_GALLERY_LOOKUP_BY_STUDENT_ID &&
    process.env.REACT_DASHBOARD_GALLERY_LOOKUP_BY_STUDENT_ID === 'on';

  return (
    <KeyFormWrapper>
      <p>{showSecurityMessage()}</p>
      <div className="clearfix">
        <WithTippy
          visible={presetEventKey}
          text="Your key has been entered. Click or tap 'Start Order'!"
        >
          <input
            className="bnl-gallery-key-form__input border--round"
            name="galleryKey"
            onChange={handleOnChange}
            onKeyPress={handleKeyPress}
            placeholder="enter code here"
            value={galleryKey}
          />
        </WithTippy>
        <div
          className={`bnl-gallery-key-form__button button--round-border ${color}`}
          onClick={() => goToStudentSelection()}
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          Start Order
        </div>
      </div>

      {showLookupByVenueZipcode && (
        <PrepayCode>
          Need help locating your prepay event code?{' '}
          <Link to="/venues/lookup-by-zipcode">Click Here</Link>
        </PrepayCode>
      )}

      {showGalleryLookupByStudentId && (
        <StudentIdLookup>
          Need help locating your images?{' '}
          <Link to="/v3/lookup-by-student-id">Click Here</Link>
        </StudentIdLookup>
      )}
    </KeyFormWrapper>
  );
};

BnlParentGalleryKeyForm.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  galleryKey: PropTypes.string.isRequired,
  goToStudentSelection: PropTypes.func.isRequired,
};

export default BnlParentGalleryKeyForm;
