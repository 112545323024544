export const getParentProductById = (shootProducts, id) => {
  const category = shootProducts.find(
    (category) => category.products[0]?.id?.toString() === id.toString(),
  );

  if (!category) throw new Error('Product not found');

  return category.products[0];
};

export const getProductByLocation = (shootProducts, location) => {
  const path = location.pathname.split('/');
  const productId = path[path.findIndex((e) => e === 'products') + 1];

  const matchingCategory = shootProducts.find(
    (category) => category.id.toString() === productId.toString(),
  );

  if (!matchingCategory) return getParentProductById(shootProducts, productId);

  const product = matchingCategory.products.find(
    (p) => p.id.toString() === productId,
  );

  if (!product) throw new Error('Product not found');

  return product;
};

export const getChildProductById = (shootProducts, location, id) => {
  const path = location.pathname.split('/');
  const productId = path[path.findIndex((e) => e === 'products') + 1];

  let matchingCategory = shootProducts.find(
    (category) => category.id.toString() === productId.toString(),
  );

  const product = matchingCategory
    ? matchingCategory.products.find((p) => p.id.toString() === productId)
    : getParentProductById(shootProducts, productId);

  if (!product) throw new Error('Product not found');

  return product.child_products.find((p) => p.id === id);
};
