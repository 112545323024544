// helpers.js
import { camelCase } from 'lodash';

export const mapCartTotalsResponseToCamelCase = (responseData) => {
  const allowedKeys = [
    'total',
    'shipping',
    'tax',
    'discount',
    'familyDiscount',
    'grandTotal',
    'cartTotalsObject',
  ];

  const mappedData = {};

  for (const key in responseData) {
    if (responseData.hasOwnProperty(key)) {
      const camelKey = camelCase(key);
      if (allowedKeys.includes(camelKey)) {
        mappedData[camelKey] = responseData[key];
      }
    }
  }
  return mappedData;
};
