// TODO: capture errors with Sentry
const assignStudentToPicsGallery = async (galleryId, studentId) => {
  console.log('galleryId:', galleryId);
  try {
    const response = await fetch(
      `/api/v3/galleries/${galleryId}/assign-student`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ student_id: studentId }),
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
};

export default {
  assignStudentToPicsGallery,
};
