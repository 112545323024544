import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import OrderProductAddOnsRetouching from './order_product_add_ons_retouching';
import OrderProductAddOnsPersonalization from './order_product_add_ons_personalization';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';

class OrderProductAddOns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeComponent: this.getActiveComponent(),
    };

    this.applyPersonalization = this.applyPersonalization.bind(this);
    this.applyPhotoEnhancement = this.applyPhotoEnhancement.bind(this);
    this.renderNextOrProcessAddOns = this.renderNextOrProcessAddOns.bind(this);
    this.skipPhotoEnhancement = this.skipPhotoEnhancement.bind(this);
    this.skipPersonalization = this.skipPersonalization.bind(this);
  }

  applyPhotoEnhancement() {
    const goToPersonalization =
      this.props.personalizationStatus === 'personalization_optional' ||
      this.props.personalizationStatus === 'personalization_required';

    this.props.applyPhotoEnhancement(goToPersonalization);
    if (!this.props.personalizationApplied && goToPersonalization) {
      this.setState({ activeComponent: 2 });
    } else {
      this.props.keepShopping();
    }
  }

  applyPersonalization() {
    this.props.applyPersonalization();
  }

  getActiveComponent() {
    if (
      this.props.personalizationEdit ||
      this.props.photoEnhancementApplied ||
      this.props.photoEnhancements.length === 0 ||
      (this.props.shoot.pricing_model &&
        this.props.shoot.pricing_model.offers_basic_enhancement === false)
    ) {
      return 2;
    }

    return 1;
  }

  skipPhotoEnhancement() {
    if (
      (!this.props.personalizationApplied &&
        this.props.personalizationStatus === 'personalization_optional') ||
      this.props.personalizationStatus === 'personalization_required'
    ) {
      this.setState({ activeComponent: 2 });
    } else {
      this.props.keepShopping();
    }
  }

  skipPersonalization() {
    this.props.skipPersonalization();
  }

  renderNextOrProcessAddOns() {
    if (
      this.props.photoEnhancementApplied &&
      this.props.personalizationApplied
    ) {
      this.props.processAddOns();
    } else if (this.state.activeComponent === 1) {
      this.setState({ activeComponent: this.state.activeComponent + 1 });
    }
  }

  renderActiveComponent() {
    switch (this.state.activeComponent) {
      case 1:
        return (
          <OrderProductAddOnsRetouching
            applyPhotoEnhancement={this.applyPhotoEnhancement}
            displayPrice={this.props.displayPrice}
            renderNextOrProcessAddOns={this.renderNextOrProcessAddOns}
            photoEnhancement={this.props.photoEnhancement}
            photoEnhancements={this.props.photoEnhancements}
            photoEnhancementApplied={this.props.photoEnhancementApplied}
            skipPhotoEnhancement={this.skipPhotoEnhancement}
          />
        );
      case 2:
        return (
          <OrderProductAddOnsPersonalization
            applyPersonalization={this.applyPersonalization}
            displayPrice={this.props.displayPrice}
            editPersonalization={this.props.editPersonalization}
            handleOnChange={this.props.handleOnChange}
            renderNextOrProcessAddOns={this.renderNextOrProcessAddOns}
            personalization={this.props.personalization}
            personalizationApplied={this.props.personalizationApplied}
            personalizationEdit={this.props.personalizationEdit}
            personalizationPrice={this.props.personalizationPrice}
            personalizationValue={this.props.personalizationValue}
            skipPersonalization={this.skipPersonalization}
          />
        );
    }
  }

  render() {
    const photoEnhancementApplied = this.props.photoEnhancementApplied
      ? 'hidden'
      : '';

    return (
      <div className="order-product-add-ons">
        {this.renderActiveComponent()}
      </div>
    );
  }

  componentWillUnmount() {
    // store order state user happens to not click "DONE"
    // add ons states will not be stored in this case
    this.props.addCurrentOrderToReduxCart();
    this.props.checkForIncentive();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(null, mapDispatchToProps)(OrderProductAddOns);
