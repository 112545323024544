import React from 'react';

const Details = () => {
  const [email, setEmail] = React.useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform any necessary logic or API calls with the updated username and email
    // ...

    // Reset the input fields
    setUsername('');
    setEmail('');
  };

  return (
    <div>
      <h1>My Account</h1>
      <form onSubmit={handleSubmit}>
        <br />
        <label>
          Email:
          <input type="email" value={email} onChange={handleEmailChange} />
        </label>
        <br />
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default Details;
