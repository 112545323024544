import React, { Component } from 'react';

class BnlOrderProductDesktopConfiguration extends Component {
  renderHeaderText() {
    const product = this.props.selectedShootProduct;

    if (product.is_retouching) {
      return 'Add Retouching';
    }

    if (product.triggers_interstitial) {
      return `${product.name}`;
    }

    return `${product.name} - ${this.props.renderInstruction()}`;
  }

  renderListOfRetouchingProducts() {
    const { retouchingProducts } = this.props.shoot;
    const filteredRetouchingProducts = [];
    const retouchingProductName = [];

    if (
      typeof retouchingProducts === 'object' &&
      !Array.isArray(retouchingProducts)
    ) {
      filteredRetouchingProducts.push(retouchingProducts);
    } else {
      // Filter the list of retouching products by name to ensure we're
      // not displaying more than one instance.
      for (let i = 0; i < retouchingProducts.length; i++) {
        if (!retouchingProductName.includes(retouchingProducts[i].name)) {
          retouchingProductName.push(retouchingProducts[i].name);
          filteredRetouchingProducts.push(retouchingProducts[i]);
        }
      }
    }

    const retouchingProductsList = filteredRetouchingProducts.map(
      (product, index) => (
        <div className="row" key={index}>
          <div className="bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4">
            <div className="bnl-product-config__product">
              <h5 className="bnl-product-config__desc">{product.name}</h5>
              <img
                className="bnl-product-config__product-image"
                src={product.image_thumbnail}
              />
              <div className="bnl-product-config__product-desc">
                {product.description}
              </div>
              <div className="bnl-product-config__product-desc">
                {this.props.displayPrice(product.price)}
              </div>
              {this.props.renderDesktopSelectedPoseAndBackground()}
            </div>
            {this.props.renderDesktopButton(
              product,
              index,
              filteredRetouchingProducts.length
            )}
          </div>
          <div className="bnl-product-config__step col-xs-12 col-md-8 col-lg-8">
            {index === 0 && this.props.activeStepComponent}
          </div>
        </div>
      )
    );

    return retouchingProductsList;
  }

  renderRetouchingProducts() {
    return (
      <div className="bnl-product-config bnl-product-config--desktop">
        <h3 className="bnl-product-config__header">
          {this.renderHeaderText()}
        </h3>
        <ul className="bnl-product-config__subheader-container clearfix">
          {this.props.renderSubheader()}
        </ul>
        <div>{this.renderListOfRetouchingProducts()}</div>
      </div>
    );
  }

  renderInterstitialProduct(product) {
    const {
      activeStepComponent,
      displayPrice,
      renderDesktopButton,
      renderDesktopSelectedPoseAndBackground,
      renderSubheader,
    } = this.props;

    return (
      <div className="bnl-product-config bnl-product-config--desktop">
        <h3 className="bnl-product-config__header">
          {this.renderHeaderText()}
        </h3>
        <ul className="bnl-product-config__subheader-container clearfix">
          {/* {renderSubheader()} */}
        </ul>
        <div>
          <div className="row">
            <div className="bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4">
              <div className="bnl-product-config__product">
                <h5 className="bnl-product-config__desc">{product.name}</h5>
                <img
                  className="bnl-product-config__product-image"
                  src={product.image_thumbnail}
                  alt="product thumbnail"
                />
                <div className="bnl-product-config__product-desc">
                  {product.description}
                </div>
                <div className="bnl-product-config__product-desc">
                  {displayPrice(product.price)}
                </div>
                {renderDesktopSelectedPoseAndBackground()}
              </div>
              {renderDesktopButton(product)}
            </div>
            <div
              id="interstitialDesktopInsructions"
              className="bnl-product-config__step col-xs-12 col-md-8 col-lg-8"
            >
              <h3>Would you like to add this recommended product?</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProduct(product) {
    return (
      <div className="bnl-product-config bnl-product-config--desktop">
        <h3 className="bnl-product-config__header">
          {this.renderHeaderText()}
        </h3>
        <ul className="bnl-product-config__subheader-container clearfix">
          {this.props.renderSubheader()}
        </ul>
        <div className="row">
          <div className="bnl-product-config__product-info col-xs-12 col-md-4 col-lg-4">
            <div className="bnl-product-config__product">
              <h5 className="bnl-product-config__desc">{product.name}</h5>
              <img
                className="bnl-product-config__product-image"
                src={product.image_thumbnail}
              />
              <div className="bnl-product-config__product-desc">
                {product.description}
              </div>
              {this.props.renderDesktopSelectedPoseAndBackground()}
            </div>
            {this.props.renderDesktopButton()}
          </div>
          <div className="bnl-product-config__step col-xs-12 col-md-8 col-lg-8">
            {this.props.activeStepComponent}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { selectedShootProduct } = this.props;

    if (selectedShootProduct.is_retouching) {
      return this.renderRetouchingProducts();
    }

    if (selectedShootProduct.triggers_interstitial) {
      return this.renderInterstitialProduct(selectedShootProduct);
    }

    return this.renderProduct(selectedShootProduct);
  }
}

export default BnlOrderProductDesktopConfiguration;
