import React, { Component } from 'react';

class OrderPoseSelection extends Component {
  renderPoses() {
    let poses = [];

    for (let poseId in this.props.galleryImages) {
      let pose = this.props.galleryImages[poseId];

      poses.push(
        <li
          className="pose col-xs-12 col-md-2 col-md-2 cursor-pointer"
          key={`pose-${poseId}`}
          onClick={() => this.props.selectPose(pose)}
        >
          <img className="pose__image" src={pose.url} />
        </li>
      );
    }

    return poses;
  }

  render() {
    return (
      <div className="pose-selection">
        <div>Temporary Pose Selection Screen</div>
        <h3>Select Your Pose</h3>
        <ul className="row pose-list">{this.renderPoses()}</ul>
      </div>
    );
  }
}

export default OrderPoseSelection;
