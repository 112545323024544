import React from 'react';
import PropTypes from 'prop-types';

const OrderPhotoPreview = (props) => {
  const {
    additionalData: { studentPreviewImageUrl },
    shoot,
  } = props;

  const imgContainerStyle = {
    paddingRight: '25px',
    maxWidth: '300px',
  };

  const imgStyle = {
    maxWidth: '100%',
    marginLeft: '15px',
  };

  if (
    shoot.shoot.background_choice_mode === 'outdoors_background_only' &&
    studentPreviewImageUrl !== ''
  ) {
    return (
      <div className="order-product-group">
        <div className="order-product-group__header">Photo Preview</div>
        <div className="row" style={imgContainerStyle}>
          <img
            style={imgStyle}
            src={studentPreviewImageUrl}
            alt="student preview"
          />
        </div>
      </div>
    );
  }
  return null;
};

export default OrderPhotoPreview;

OrderPhotoPreview.propTypes = {
  additionalData: PropTypes.shape({
    studentPreviewImageUrl: PropTypes.string,
  }).isRequired,
  shoot: PropTypes.shape({ shoot: PropTypes.object }).isRequired,
};
