import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import styled from 'styled-components';

const TipBox = styled.div`
  background-color: #479de6;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #ffffff;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  @media (max-width: 992px) {
    display: none;
  }
`;

export default function WithTippy({ children, visible = true, text }) {
  return (
    <Tippy
      visible={visible}
      placement="bottom"
      zIndex={2}
      render={(attrs) => (
        <TipBox>
          <div style={{ maxWidth: 200 }}>{text}</div>
        </TipBox>
      )}
    >
      {children}
    </Tippy>
  );
}
