import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  setPersonalizationOnAllEligibleCartItems,
  skipPersonalizationOnAllCartItems,
} from '../../../../actions/v3Order';

const ContentBox = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px 15px 35px 15px;
  margin: 20px;
`;

const Wrapper = styled.div`
  margin-top: 20px;
  @media (max-width: 992px) {
    margin-top: 8px;
  }
`;

const CenteredTitle = styled.h3`
  text-align: center;
  width: 100%;
`;

const LeftMarginButton = styled.button`
  margin-left: 8px;
`;

const ExplainerCopy = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

const PersonalizationOffer = ({ history }) => {
  const searchParams = new URLSearchParams(location.search);
  const redirectSource = searchParams.get('source');

  const selectedStudent = useSelector(
    (state) => state.additionalData.selectedStudent,
  );
  const dispatch = useDispatch();
  const [personalizationValue, setPersonalizationValue] = useState('');

  const handleChange = (event) => {
    setPersonalizationValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      setPersonalizationOnAllEligibleCartItems(
        selectedStudent.id,
        personalizationValue,
        redirectSource,
      ),
    );
  };

  const handleSkip = (event) => {
    event.preventDefault();
    dispatch(
      skipPersonalizationOnAllCartItems(selectedStudent.id, redirectSource),
    );
  };

  return (
    <ContentBox className="row">
      <CenteredTitle>Personalization</CenteredTitle>
      <div className="col-xs-12 col-md-6 col-md-offset-3">
        <Wrapper>
          <ExplainerCopy>
            Order for:{' '}
            <strong>{`${selectedStudent.firstName} ${selectedStudent.lastName}`}</strong>
          </ExplainerCopy>
          <ExplainerCopy>
            One or more items in your order are eligible for free
            personalization!
          </ExplainerCopy>
          <ExplainerCopy>
            To apply personalization, please enter the student's first name in
            the field below (limit 20 characters) and click Submit.
          </ExplainerCopy>
          <ExplainerCopy>Enter student's first name:</ExplainerCopy>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="personalization"
                placeholder="Enter student's first name"
                maxLength="20"
                onChange={handleChange}
                value={personalizationValue}
              />
            </div>
            <button
              type="button"
              className="btn btn-default"
              onClick={handleSkip}
            >
              Skip Personalization
            </button>
            <LeftMarginButton
              type="submit"
              className="btn btn-primary"
              disabled={personalizationValue === ''}
              title={
                personalizationValue === ''
                  ? 'Please enter a personalization value before submitting'
                  : ''
              }
            >
              Submit
            </LeftMarginButton>
          </form>
        </Wrapper>
      </div>
    </ContentBox>
  );
};

export default PersonalizationOffer;
