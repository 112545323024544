// props required to use RadioButtons components
// handleOnChange - onChange event handler that updates React.js state
// name - the state key that is being updated (represented by a string)
// option - the state value that is selected by default (represented by a string)
// options - object with keys representing state values and values representing label text
// e.g. this.props.options = { true: "Yes, upgrad to metallic paper", false: "No", don't upgrade };

import React, { Component } from 'react';

class RadioButtons extends Component {
  renderRadioButtons() {
    let radioButtons = [];

    for (let value in this.props.options) {
      const button = (
        <label
          key={`radio-${this.props.name}-${value}`}
          className="cursor-pointer"
        >
          <input
            checked={this.props.option === value}
            name={this.props.name}
            onChange={this.props.handleOnChange}
            type="radio"
            value={value}
          />
          {this.props.options[value]}
        </label>
      );

      radioButtons.push(button);
    }

    return radioButtons;
  }

  render() {
    const className = this.props.className ? this.props.className : '';

    return <form className={className}>{this.renderRadioButtons()}</form>;
  }
}

export default RadioButtons;
