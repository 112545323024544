import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { signupParentStep1 } from '../../actions/action_auth';

const instructionsContainerStyle = {
  textAlign: 'center',
};

const instructionsTextStyle = {
  fontWeight: 'bold',
  fontSize: '16px',
  marginBottom: '16px',
};

const SignupHeader = styled.div`
  margin-bottom: 30px;
`;

const LoginLink = styled.span`
  color: #479de6;
  &:hover {
    cursor: pointer;
  }
`;

const SignupMessageContainer = styled.div`
  padding-bottom: 5px;
`;

class ParentSignupFormStep1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errors: {},
    };

    this.goToLoginForm = this.goToLoginForm.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.renderSecurityMessage = this.renderSecurityMessage.bind(this);
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOnSubmit(event) {
    event.preventDefault();
    const that = this;
    const data = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.signupParentStep1(data).then((response) => {
      if (response.payload.data) {
        if (!response.payload.data.errors) {
          that.props.updateStep(2);
        } else {
          that.setState({ errors: response.payload.data.errors });
        }
      }
    });
  }

  goToLoginForm() {
    this.props.history.replace('/login', { goToForm: true });
  }

  renderSecurityMessage() {
    const { company } = this.props;

    if (company.name.toLowerCase() === 'wagner') {
      return (
        <div style={instructionsTextStyle}>
          For the security of your child, you must create an account to place an
          order.
        </div>
      );
    }

    return null;
  }

  render() {
    const className =
      Object.keys(this.state.errors).length > 0 ? 'error--red' : 'hidden';

    return (
      <div>
        <SignupHeader className="signup__header">
          Create Your Account
        </SignupHeader>
        <SignupMessageContainer className="parent-signup__login-container">
          <div style={instructionsContainerStyle}>
            {this.renderSecurityMessage()}
            <div style={instructionsTextStyle}>
              Already have an account?&nbsp;
              <LoginLink onClick={this.goToLoginForm}>Log In</LoginLink>
            </div>
          </div>
        </SignupMessageContainer>
        <form onSubmit={this.handleOnSubmit} className="signup-form">
          <input
            id="email"
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="email"
            value={this.state.email}
            placeholder="Email"
          />
          <input
            id="password"
            className="signup-form__input border--round"
            onChange={this.handleOnChange}
            name="password"
            value={this.state.password}
            placeholder="Password"
            type="password"
          />
          <div className={`signup-error ${className}`}>
            {this.state.errors.email}
          </div>
          <input
            className={`${this.props.company.name}-auth-button button--round-border`}
            value="Done"
            type="submit"
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { company: state.company };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signupParentStep1 }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentSignupFormStep1);
