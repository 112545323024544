import axios from 'axios';

export const GET_SHOOT_DATA = 'GET_SHOOT_DATA';
export const FORMAT_AND_SET_BACKGROUND_OPTIONS =
  'FORMAT_AND_SET_BACKGROUND_OPTIONS';
export const FORMAT_AND_SET_INCENTIVE_PRODUCTS =
  'FORMAT_AND_SET_INCENTIVE_PRODUCTS';
export const SET_INTERSTITIAL_PRODUCTS = 'SET_INTERSTITIAL_PRODUCTS';
export const SET_PRE_CART_OFFER_PRODUCTS = 'SET_PRE_CART_OFFER_PRODUCTS';
export const SET_TRIGGERS_PRE_CART_OFFER_PRODUCTS =
  'SET_TRIGGERS_PRE_CART_OFFER_PRODUCTS';
export const SET_RETOUCHING_PRODUCT = 'SET_RETOUCHING_PRODUCT';
export const SET_RETOUCHING_PRODUCTS = 'SET_RETOUCHING_PRODUCTS';
export const SET_PERSONALIZATION_PRODUCT = 'SET_PERSONALIZATION_PRODUCT';
export const FORMAT_AND_SET_BNL_INCENTIVE_PRODUCTS =
  'FORMAT_AND_SET_BNL_INCENTIVE_PRODUCTS';
export const SET_ADDITIONAL_ORDER_DATA = 'SET_ADDITIONAL_ORDER_DATA';

const apiUrl = '/api/v2';

export function getShootData(data) {
  const request = axios.get(`${apiUrl}/shoot-products`, { params: data });

  return {
    type: GET_SHOOT_DATA,
    payload: request,
  };
}

export function formatAndSetBackgroundOptions(options) {
  return {
    type: FORMAT_AND_SET_BACKGROUND_OPTIONS,
    payload: options,
  };
}

export function formatAndSetIncentiveProducts(products) {
  return {
    type: FORMAT_AND_SET_INCENTIVE_PRODUCTS,
    payload: products,
  };
}

export function setAdditionalOrderData(payload) {
  return {
    type: SET_ADDITIONAL_ORDER_DATA,
    payload,
  };
}

export function setInterstitialProducts(products) {
  return {
    type: SET_INTERSTITIAL_PRODUCTS,
    payload: products,
  };
}

export function setPreCartOfferProducts(products) {
  return {
    type: SET_PRE_CART_OFFER_PRODUCTS,
    payload: products,
  };
}

export function setTriggersPreCartOfferProducts(products) {
  return {
    type: SET_TRIGGERS_PRE_CART_OFFER_PRODUCTS,
    payload: products,
  };
}

export function setRetouchingProducts(products) {
  return {
    type: SET_RETOUCHING_PRODUCTS,
    payload: products,
  };
}

export function setRetouchingProduct(product) {
  return {
    type: SET_RETOUCHING_PRODUCT,
    payload: product,
  };
}

export function setPersonalizationProduct(product) {
  return {
    type: SET_PERSONALIZATION_PRODUCT,
    payload: product,
  };
}

export function formatAndSetBnlIncentiveProducts(products) {
  return {
    type: FORMAT_AND_SET_BNL_INCENTIVE_PRODUCTS,
    payload: products,
  };
}
