import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import BnlOrderProduct from './bnl_order_product';

class NthFreeProductsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qualifiedValue: 0,
    };

    this.determineNthMaxValue = this.determineNthMaxValue.bind(this);
  }

  componentDidMount() {
    this.determineNthMaxValue();

    Swal.fire(
      'Congratulations, you qualify for a free item!',
      'Please configure your free item'
    );
  }

  determineNthMaxValue() {
    const { cartItems } = this.props;
    let maxValue = 0;

    cartItems.forEach((cartItem) => {
      if (cartItem.nth_free_sku && cartItem.nth_free_sku !== '') {
        if (cartItem.price > maxValue) {
          maxValue = cartItem.price;
        }
      }
    });

    this.setState({ qualifiedValue: maxValue });
  }

  render() {
    let nthFreeProductGroup;
    const filteredNthFreeProducts = [];
    const {
      shoot: { shootProducts },
      displayPrice,
      selectShootProduct,
    } = this.props;
    const { qualifiedValue } = this.state;

    console.log('rendering nth free');
    console.log(this.props);

    shootProducts.forEach((productGroup) => {
      console.log(productGroup);
      if (productGroup && productGroup.category_type === 'nth_free') {
        console.log('nth free product group match found');
        nthFreeProductGroup = productGroup;
      }
    });

    console.log('nth free group');
    console.log(nthFreeProductGroup);

    if (!nthFreeProductGroup) {
      return null;
      // TODO: throw error
    }

    nthFreeProductGroup.products.forEach((nthFreeProduct) => {
      if (nthFreeProduct.nth_free_value <= qualifiedValue) {
        filteredNthFreeProducts.push(nthFreeProduct);
      }
    });

    const nthFreeProducts = filteredNthFreeProducts.map((product, i) => (
      <BnlOrderProduct
        displayPrice={displayPrice}
        key={`order-product-${i}`}
        name={`order-products-${i}`}
        product={product}
        selectShootProduct={selectShootProduct}
      />
    ));

    const headerStyle = {
      float: 'none',
    };

    const headerParagraphStyle = {
      marginLeft: '-15px',
    };

    return (
      <div className="bnl-product-groups-container">
        <div className="clearfix">
          <h2
            className="bnl-product-groups__header bnl-product-groups__header--categorized"
            style={headerStyle}
          >
            Select a Free Product of Equal of Lesser Value
          </h2>
          <p style={headerParagraphStyle}>
            qualified for: {displayPrice(qualifiedValue)}
          </p>
        </div>
        <ul className="row equal-height">{nthFreeProducts}</ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderNavigation: state.orderNavigation,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(NthFreeProductsList);

NthFreeProductsList.propTypes = {
  shoot: PropTypes.shape({
    shootProducts: PropTypes.array,
  }).isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.obj).isRequired,
  selectShootProduct: PropTypes.func.isRequired,
  displayPrice: PropTypes.func.isRequired,
};
