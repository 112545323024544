import styled from 'styled-components';
import { Text } from '../v3Styles';

export const Section = styled.div`
  margin: 40px 0;
`;

export const BoxWrapper = styled.div`
  margin: 12px 0;
`;

export const Box = styled.div`
  width: 480px;
  min-height: 50px;
  ${'' /* display: flex; */}
  align-items: center;
  padding: 14px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d7dbe0;
  background: white;
  box-shadow: 2px 2px 5px 0px rgba(215, 219, 224, 0.3);
  textarea {
    border: 0;
    width: 100%;
    resize: none;
  }
`;

export const CheckboxWrapper = styled.label`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  flex-grow: 1;
`;

export const UnsubAllMessage = styled.div`
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
  @media screen and (max-width: 992px) {
    position: relative;
  }
`;

export const SectionHeader = styled.h2`
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 600;
`;

export const SectionText = styled(Text)`
  width: 655px;
  max-width: 100%;
`;

export const CheckboxContent = styled.div`
  display: flex;
`;

export const SubscribedText = styled.p`
  margin: 0 0 0 20px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
`;
