import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CartContents from './CartContents';
import OrderSummary from './OrderSummary';
import NewOrder from './NewOrder';
import KeepShoppingButton from './KeepShoppingButton';
import { resetAdditionalData } from '../../../actions/additionalData';
import cartUtils from '../../../utils/cart';

const CartHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const CartDivider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const CartList = styled.ul`
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const Cart = ({ history }) => {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.v3Order.orders);

  useEffect(() => {
    if (cartUtils.allOrdersAreEmpty(orders)) {
      // dispatch(resetAdditionalData());
      history.push('/v3/offers');
    }
  }, [orders, history]);

  return (
    <div className="bnl-order-cart">
      <CartHeader>Your Cart</CartHeader>
      <CartDivider />
      <div className="bnl-order-cart-container row">
        <CartList className="bnl-order-cart__content col-xs-12 col-md-8 col-lg-8">
          <CartContents orders={orders} />
        </CartList>
        <div className="bnl-order-cart__right col-xs-12 col-md-4 col-lg-4 clearfix">
          <OrderSummary orders={orders} />
          <NewOrder />
          <KeepShoppingButton />
        </div>
      </div>
    </div>
  );
};

export default Cart;
