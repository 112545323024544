import React from 'react';
import styled from 'styled-components';
import CartItemBackgroundsPoses from './CartItemBackgroundsPoses';
import CartItemPersonalization from './CartItemPersonalization';
import CartItemPrice from './CartItemPrice';
import CartItemProductNameImage from './CartItemProductNameImage';
import CartItemRemoveButton from './CartItemRemoveButton';
import ProductChildElements from './ProductChildElements';

const LeftColumn = styled.div`
  padding: 0;
`;

const RightColumn = styled.div`
  padding: 0;
`;

const StyledLi = styled.li`
  display: block;
  padding: 36px 0 36px 0;
  border-bottom: ${(props) => (props.isLastItem ? 'none' : '1px solid #ddd')};
`;

const FieldWrapper = styled.div`
  margin: 10px 0;
`;

const FieldName = styled.span`
  font-weight: 900;
`;

const CartItem = ({
  cartItem,
  isLastItem,
  order,
  studentId,
  shouldDisplayBackgroundPrice,
  singleChargeForBackgroundUpgrades,
}) => {
  const { isYPX, isBYO } = cartItem;
  const orderAdditionalData = order.additionalData || {};

  return (
    <StyledLi
      isLastItem={isLastItem}
      className="row"
      key={`cart-item-${order.student.id}-${cartItem.id}`}
    >
      <LeftColumn className="col-xs-9">
        {isBYO || isYPX ? (
          <>
            <CartItemProductNameImage cartItem={cartItem} />
            <ProductChildElements
              cartItem={cartItem}
              shouldDisplayBackgroundPrice={shouldDisplayBackgroundPrice}
              singleChargeForBackgroundUpgrades={
                singleChargeForBackgroundUpgrades
              }
            />
          </>
        ) : (
          <>
            <CartItemProductNameImage cartItem={cartItem} />
            <CartItemBackgroundsPoses
              cartItem={cartItem}
              orderAdditionalData={orderAdditionalData}
              shouldDisplayBackgroundPrice={shouldDisplayBackgroundPrice}
              singleChargeForBackgroundUpgrades={
                singleChargeForBackgroundUpgrades
              }
            />
            <CartItemPersonalization cartItem={cartItem} />
          </>
        )}
        {Array.isArray(cartItem.requiredFields) &&
          cartItem.requiredFields.length > 0 &&
          cartItem.requiredFields.map((field) => (
            <FieldWrapper>
              <FieldName>{field.label}: </FieldName>
              <span>{field.value}</span>
            </FieldWrapper>
          ))}
        {cartItem.productOption && (
          <FieldWrapper>
            <FieldName>Product Option: </FieldName>
            <span>{cartItem.productOption.name}</span>
          </FieldWrapper>
        )}
      </LeftColumn>
      <RightColumn className="col-xs-3 text-right">
        <CartItemPrice cartItem={cartItem} isBYO={isBYO} />
        <CartItemRemoveButton cartItem={cartItem} studentId={studentId} />
      </RightColumn>
    </StyledLi>
  );
};

/* TODO: implement the following as needed */
/* {renderEditButton(cartItem, orderState)} */
/* {renderQuantity(cartItem, orderState)} */
/* {renderPersonalization(cartItem, orderState)} */
/* {renderIncludedItems(cartItem, orderState)} */

{
  /* <div className="col-md-4 col-xs-6">
        <CartItemProductNameImage cartItem={cartItem} />
      </div>
      <div className="col-md-5 col-md-push-3 col-xs-6">
        <div className="row">
          <div className="col-md-6 hidden-sm hidden-xs">
            <PaddingElement>&nbsp;</PaddingElement>
            <CartItemBackgroundsPoses cartItem={cartItem} />
            <CartItemPersonalization cartItem={cartItem} />
          </div>
          <div className="col-md-6 col-xs-12 text-right">
          </div>
        </div>
      </div>
      <div className="col-md-3 col-md-pull-5 col-xs-12">
        <div className="row">
          {cartUtils.cartItemProductHasContentsArray(cartItem) && (
            <div className="col-xs-6 col-md-12">
              <PaddingElement>&nbsp;</PaddingElement>
              <CartItemComponentsList cartItem={cartItem} />
            </div>
          )}
          <div className="col-xs-6 hidden-md hidden-lg hidden-xl">
            <PaddingElement>&nbsp;</PaddingElement>
            <CartItemPersonalization cartItem={cartItem} />
          </div>
        </div>
      </div>
    </li> */
}

// const ListItem = styled.li`
//   display: flex;
//   flex-wrap: wrap;
// `;

// const LeftColumn = styled.div`
//   flex-basis: calc(75% - 15px); /* 9/12 columns minus half-gutter */
//   flex-grow: 0;
//   flex-shrink: 0;
// `;

// const RightColumn = styled.div`
//   flex-basis: calc(25% - 15px);
//   flex-grow: 0;
//   flex-shrink: 0;
//   text-align: right;
// `;

// const ProductImageRow = styled.div`
//   width: 100%;
// `;

// const ProductImage = styled.img`
//   width: 100%;
//   max-width: 400px;
//   border: 1px solid red;
//   display: block;
//   margin: 0 auto; // Centering the image
// `;

// const BackgroundPoseRow = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// // Utilizing the `Column` component we defined in the previous code.

// const FullWidthRow = styled.div`
//   width: 100%;
// `;

// const ThreeColumnRow = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const Column = styled.div`
//   flex-basis: calc(33.3333% - 10px);
//   max-width: 150px;
//   position: relative;

//   &:before {
//     content: '';
//     display: block;
//     padding-top: 125%; /* 8:10 ratio */
//   }

//   > img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const PersonalizationRow = styled.div`
//   width: 100%;
// `;

// // Apply the styles and utilize components within the main CartItem component

// const CartItem = ({ cartItem, isLastItem, order, studentId }) => {
//   // ...
//   return (
//     <ListItem key={`cart-item-${order.student.id}-${cartItem.id}`}>
//       <LeftColumn>
//         <FullWidthRow></FullWidthRow>
//         <FullWidthRow>
//           <CartItemProductNameImage cartItem={cartItem} />
//         </FullWidthRow>
//         <BackgroundPoseRow>
//           <Column>
//             <img src={cartItem.backgroundImage} alt={cartItem.backgroundName} />
//             <i>{cartItem.backgroundName}</i>
//           </Column>
//           <Column>
//             <img src={cartItem.poseImage} alt={cartItem.poseName} />
//           </Column>
//         </BackgroundPoseRow>
//         <PersonalizationRow>
//           <CartItemPersonalization cartItem={cartItem} />
//         </PersonalizationRow>
//       </LeftColumn>
//       <RightColumn>
//         <CartItemPrice cartItem={cartItem} />
//         <CartItemRemoveButton cartItem={cartItem} studentId={studentId} />
//       </RightColumn>
//     </ListItem>
//   );
// };

export default CartItem;
