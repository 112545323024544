import axios from 'axios';

import { resetCart } from './action_cart';
import { resetBnlIncentive } from './action_bnl_incentive';
import { resetGeskusIncentive } from './action_geskus_incentive';
import { resetDiscountCode } from './action_discount_code';
import { resetAdditionalData } from './additionalData';
import { setCompanyName } from './action_company';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const LOGIN_PARENT = 'LOGIN_PARENT';
export const LOGOUT_PARENT = 'LOGOUT_PARENT';
export const CLEAR = 'CLEAR';
export const SIGNUP_PARENT_STEP_1 = 'SIGNUP_PARENT_STEP_1';
export const SIGNUP_PARENT_STEP_2 = 'SIGNUP_PARENT_STEP_2';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

const apiUrl = '/api/v2';

// TODO: revisit how we're handling the logout logic... it's a bit messy
// Should we chain these calls together? Should we use async/await?
export const logoutAndReset =
  (companyName = null) =>
  (dispatch, getState) => {
    console.log('logoutAndReset called...');

    dispatch(resetCart());
    dispatch(resetBnlIncentive());
    dispatch(resetGeskusIncentive());
    dispatch(resetDiscountCode());
    dispatch(resetAdditionalData());
    dispatch(logoutParent());
    // Do we need to set the company name here?
    // dispatch(setCompanyName(companyName));
  };

export function setAuthToken(token) {
  return {
    type: SET_AUTH_TOKEN,
    payload: token,
  };
}

export function loginParent(data) {
  const request = axios.post('/login', data);

  return {
    type: LOGIN_PARENT,
    payload: request,
  };
}

export function logoutParent() {
  const request = axios.get('/logout');

  return {
    type: LOGOUT_PARENT,
    payload: request,
  };
}

export function clearParent() {
  return {
    type: CLEAR,
    payload: request,
  };
}

export function signupParentStep1(data) {
  const request = axios.post(`${apiUrl}/parents`, data);

  return {
    type: SIGNUP_PARENT_STEP_1,
    payload: request,
  };
}

export function signupParentStep2(data) {
  const request = axios.patch(`${apiUrl}/parents/signup/step-2`, data);

  return {
    type: SIGNUP_PARENT_STEP_2,
    payload: request,
  };
}

export function setCurrentUser() {
  const request = axios.get(`${apiUrl}/current-user`);

  return {
    type: SET_CURRENT_USER,
    payload: request,
  };
}
