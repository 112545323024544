import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class BnlOrderProductConfigurationPoses extends Component {
  renderPoses(galleryObj, student, mobile = false) {
    let poses = [];
    const product = this.props.selectedShootProduct;

    let workingGallery;

    switch (product.image_type) {
      case 'portrait_image':
        workingGallery = galleryObj.gallery;
        break;
      case 'group_image':
        workingGallery = galleryObj.associatedGroupImages;
        break;
    }

    workingGallery.forEach((pose, i) => {
      let selected = '';
      let onClickHandler = this.props.selectPose.bind(this, pose);

      if (mobile) {
        onClickHandler = this.props.selectMobilePose.bind(this, pose);
      }

      if (this.props.selectedPose) {
        if (this.props.selectedPose.id === pose.id) {
          selected = 'bnl-product-config__pose--selected ';
        }
      }
      pose['student'] = student;

      poses.push(
        <li
          className="bnl-product-config__pose col-xs-6 col-md-3 col-lg-3 cursor-pointer"
          onClick={onClickHandler}
          key={`pose-${pose.id}`}
        >
          <img
            className={`bnl-product-config__pose-image ${selected}`}
            src={pose.url}
          />
        </li>,
      );
    });

    return poses;
  }

  renderGalleries() {
    let galleries = [];

    const companyPricingModelId = this.props.gallery.cpmId;
    let galleriesObj = this.props.gallery.galleries[companyPricingModelId];

    for (let studentId in galleriesObj) {
      let galleryObj = galleriesObj[studentId];
      if (galleryObj.gallery.length > 0) {
        // TODO: revist
        // find out why some names are stored in snakecase
        let firstName =
          galleryObj.student.first_name || galleryObj.student.firstName;
        let lastName =
          galleryObj.student.last_name || galleryObj.student.lastName;
        const className = 'bnl-product-config__student__poses row';
        const desktopPoses = this.renderPoses(galleryObj, galleryObj.student);
        const mobilePoses = this.renderPoses(
          galleryObj,
          galleryObj.student,
          true,
        );

        if (desktopPoses.length > 0) {
          galleries.push(
            <div
              className="bnl-product-config__student"
              key={`gallery-${studentId}`}
            >
              <div>
                {firstName} {lastName}
              </div>
              <ul
                className={`${className} bnl-product-config__student__poses--desktop`}
              >
                {desktopPoses}
              </ul>
              <ul
                className={`${className} bnl-product-config__student__poses--mobile`}
              >
                {mobilePoses}
              </ul>
              <div className="bnl-divider" />
            </div>,
          );
        }
      }
    }

    return galleries;
  }

  render() {
    return (
      <div className="bnl-product-config__poses">{this.renderGalleries()}</div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.gallery,
  };
}

export default connect(
  mapStateToProps,
  null,
)(BnlOrderProductConfigurationPoses);
