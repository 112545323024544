import React from 'react';
import PropTypes from 'prop-types';
import {
  VenueButton,
  ResultCard,
  VenueName,
} from '../../../../styles/StudentKeyLookupStyles';

const VenueSearchResults = ({
  setSelectedVenue,
  venueNameForSearch,
  venueSearchInitiated,
  venueSearchResults,
  venueSearchResultsLoading,
}) => {
  if (venueSearchResults.length === 0) {
    if (venueNameForSearch.length >= 3 && venueSearchInitiated) {
      if (venueSearchResultsLoading) {
        return <p>Loading...</p>;
      }

      return <p>No results.</p>;
    }

    return null;
  }

  return venueSearchResults.map((venue, index) => {
    return (
      <ResultCard key={venue.id}>
        <VenueName>{venue.name}</VenueName>
        <p>
          {venue.address_1}
          <br />
          {venue.city}, {venue.state} {venue.zipcode}
        </p>
        <VenueButton
          onClick={() => {
            setSelectedVenue(venue);
          }}
        >
          Select this School
        </VenueButton>
      </ResultCard>
    );
  });
};

export default VenueSearchResults;

VenueSearchResults.propTypes = {
  venueNameForSearch: PropTypes.string.isRequired,
  venueSearchInitiated: PropTypes.bool.isRequired,
  venueSearchResults: PropTypes.array.isRequired,
  venueSearchResultsLoading: PropTypes.bool.isRequired,
  setSelectedVenue: PropTypes.func.isRequired,
};
