import { push } from 'connected-react-router';

// Thunk to skip yearbook selection and go to order
export const skipYearbookSelectionAndGoToOrder = () => {
  return async (dispatch) => {
    try {
      dispatch(push('/v3/incentive-offers'));
    } catch (error) {
      // TODO: log error with Sentry
      console.error('Error processing yearbook selections:', error);
    }
  };
};
