/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { clearProductConfig } from '../../../actions/v3Order';

const ClearConfig = ({ ...props }) => {
  useEffect(() => {
    if (!props.location.pathname.includes('/v3/products/'))
      props.clearProductConfig();
  }, [props.location]);

  return <div />;
};

function mapStateToProps(state) {
  return {
    shoot: state.shoot,
    order: state.v3Order,
    hasPoses: state.additionalData?.availablePoses?.length,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearProductConfig,
    },
    dispatch,
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClearConfig),
);
