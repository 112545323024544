import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import Dropdown from '../utils/dropdown';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';

class OrderShootKeyForm extends Component {
  renderGradeDropdown() {
    const options = [
      'SELECT A GRADE',
      'PK',
      'K',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      "I DON'T KNOW",
    ];

    return (
      <Dropdown
        className="order-shoot-key-form__dropdown"
        options={options}
        value={this.props.gradeCode}
        name="gradeCode"
        handleOnChange={this.props.handleOnChange}
      />
    );
  }

  render() {
    const errorClassName =
      Object.keys(this.props.errors).length > 0 ? 'error--red' : 'hidden';

    return (
      <div className="order-shoot-key">
        <div className="order-shoot-key__header">
          Key for {this.props.studentName}
        </div>
        <div className="order-shoot-key__instructions">
          Please enter your unique code
        </div>
        <div className={errorClassName}>{this.props.errors.shootKeyForm}</div>
        <form
          onSubmit={this.props.submitShootKeyForm}
          className="order-shoot-key-form"
        >
          <label htmlFor="shoot-key" className="order-shoot-key-form__label">
            Code
          </label>
          <input
            id="shoot-key"
            className="order-shoot-key-form__input border--round"
            value={this.props.shootKey}
            name="shootKey"
            onChange={this.props.handleOnChange}
            placeholder="Code"
          />
          <label className="order-shoot-key-form__label">Grade</label>
          {this.renderGradeDropdown()}
          <label
            htmlFor="teacher-last-name"
            className="order-shoot-key-form__label"
          >
            Teacher's Last Name (optional)
          </label>
          <input
            id="teacher-last-name"
            className="order-shoot-key-form__input border--round"
            value={this.props.teacherLastName}
            name="teacherLastName"
            onChange={this.props.handleOnChange}
          />
          <input
            className="button bg--dark-blue order-shoot-key__submit-button"
            value="Next"
            type="submit"
          />
        </form>
      </div>
    );
  }

  componentDidMount() {
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.SHOOT_KEY_FORM);
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(null, mapDispatchToProps)(OrderShootKeyForm);
