import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import usePreventNavigation from '../../../../hooks/usePreventNavigation';
import formatUSD from '../../../../utils/formatUSD';

const SuggestedProducts = ({ history }) => {
  // Prevent user from navigating away from this page
  usePreventNavigation(history, '/v3/suggested-products');

  const {
    shoot,
    shootProducts,
    suggestedProducts,
    suggestedProductsLinkToIndex,
  } = useSelector((state) => state.shoot);

  const suggestedProductsCategoryProducts =
    shootProducts[suggestedProductsLinkToIndex]?.products || [];

  if (
    suggestedProducts?.length > 0 &&
    suggestedProductsCategoryProducts?.length === 0
  ) {
    console.log('Sentry should fire here');
    Sentry.captureMessage(
      `Event ${shoot.id} has a misconfigured suggested products category`,
    );
  }

  // Iterate through the suggestedProducts
  const mappedIds = suggestedProducts.reduce((acc, suggestedProduct) => {
    // Find a matching product based on sku
    const matchingProduct = suggestedProductsCategoryProducts.find(
      (shootProduct) => shootProduct.sku === suggestedProduct.sku,
    );

    // If a match is found, map their ids
    if (matchingProduct) {
      acc[suggestedProduct.id] = matchingProduct.id;
    }

    return acc;
  }, {});

  const suggestedProductsCategoryId =
    shootProducts?.[suggestedProductsLinkToIndex]?.id;

  const renderSuggestedProduct = (product) => {
    return (
      <li className="order-product-recs__product" key={`rec-${product.id}`}>
        <div className="order-product-recs__product__info clearfix">
          <div className="order-product-recs__product__name">
            {product.name}
          </div>
          <div className="order-product-recs__product__price">
            {formatUSD(product.price)}
          </div>
        </div>
        <img
          className="order-product-recs__product__image"
          src={product.image_thumbnail}
        />
        <div>{product.description}</div>
      </li>
    );
  };

  const renderProducts = () => {
    const products = suggestedProducts.map((product) => {
      return mappedIds[product.id] ? (
        <Link to={`/v3/products-regular/${mappedIds[product.id]}`}>
          {renderSuggestedProduct(product)}
        </Link>
      ) : (
        <>{renderSuggestedProduct(product)}</>
      );
    });

    return <ul className="clearfix">{products}</ul>;
  };

  return (
    <div className="order-product-recs">
      <div className="order-product-recs-container">
        <h3 className="order-product-recs__header">Congratulations!</h3>
        <div className="order-product-recs__subheader">
          You've unlocked add-ons and novelties. Check out some of our most
          popular items.
        </div>
        {renderProducts()}
        <div
          className="bnl-button--light-blue"
          onClick={() => {
            history.push('/v3/categories/' + suggestedProductsCategoryId);
          }}
        >
          More Add-ons, Novelties &amp; Prints
        </div>
        <div
          className="bnl-button--dark-gray"
          onClick={() => {
            history.push('/v3/offers');
          }}
        >
          Shopping Home
        </div>
      </div>
    </div>
  );
};

SuggestedProducts.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    block: PropTypes.func.isRequired,
  }).isRequired,
};

export default SuggestedProducts;
