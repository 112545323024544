import React, { Component } from 'react';
import { connect } from 'react-redux';

class IncentiveDisplay extends Component {
  componentWillMount() {
    const { incentiveProducts } = this.props.shoot;

    if (incentiveProducts.length === 0) {
      this.props.goToOrder();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  determinePriceDisplay(price) {
    if (price === 0) {
      return 'Free';
    }

    return this.displayPrice(price);
  }

  displayPrice(price) {
    return `Spend $${(price / 100)
      .toFixed(2)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
  }

  renderAdditionalText(incentiveProduct) {
    if (incentiveProduct.name === 'Free Backpack') {
      return (
        <div className="incentive-display__products__product__threshold__per">
          Per Student
        </div>
      );
    }
  }

  renderIncentiveItems() {
    const items = [];

    this.props.shoot.incentiveProducts.forEach((incentiveProduct) => {
      items.push(
        <li
          className="incentive-display__products__product"
          key={`incentive-product-${incentiveProduct.id}`}
        >
          <div className="incentive-display__products__product__threshold">
            {this.determinePriceDisplay(incentiveProduct.incentive_threshold)}
          </div>
          {this.renderAdditionalText(incentiveProduct)}
          <img
            className="incentive-display__products__product__image"
            src={incentiveProduct.image_thumbnail}
          />
          <div className="incentive-display__products__product__name">
            {incentiveProduct.name}
          </div>
        </li>,
      );
    });

    return items;
  }

  render() {
    return (
      <div className="incentive-display">
        <div className="incentive-display__header">Unlock Bonus Items!</div>
        <div className="incentive-display__subheader">
          Use the progress bar to track earned bonus items while you shop
        </div>
        <ul className="incentive-display__products clearfix">
          {this.renderIncentiveItems()}
        </ul>
        <div
          className="incentive-display__button button--bnl"
          onClick={this.props.goToOrder}
        >
          Got It!
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(IncentiveDisplay);
