import axios from 'axios';

export const GET_STRIPE_PUBLISHABLE_KEY = 'GET_STRIPE_PUBLISHABLE_KEY';
export const ACQUIRE_SMS = 'ACQUIRE_SMS';
export const STORE_ORDER_CONTAINER_ORDER_ID = 'STORE_ORDER_CONTAINER_ORDER_ID';
export const ORDER_SHOULD_OFFER_RETOUCHING = 'ORDER_SHOULD_OFFER_RETOUCHING';
export const ORDER_HAS_OFFERED_RETOUCHING = 'ORDER_HAS_OFFERED_RETOUCHING';
export const RESET_ORDER_RETOUCHING = 'RESET_ORDER_RETOUCHING';
export const UPDATE_CURRENT_STUDENT_ID = 'UPDATE_CURRENT_STUDENT_ID';

const apiUrl = '/api/v2';

export function getStripePublishableKey() {
  const request = axios.get(`${apiUrl}/orders/get-stripe-publishable-key`);

  return {
    type: GET_STRIPE_PUBLISHABLE_KEY,
    payload: request,
  };
}

export function acquireSms() {
  return {
    type: ACQUIRE_SMS,
    payload: true,
  };
}

export function storeOrderContainerOrderId(orderId) {
  return {
    type: STORE_ORDER_CONTAINER_ORDER_ID,
    payload: orderId,
  };
}

export function orderShouldOfferRetouching() {
  return {
    type: ORDER_SHOULD_OFFER_RETOUCHING,
    payload: true,
  };
}

export function orderHasOfferedRetouching() {
  return {
    type: ORDER_HAS_OFFERED_RETOUCHING,
    payload: true,
  };
}

export function resetOrderRetouching() {
  return {
    type: RESET_ORDER_RETOUCHING,
    payload: false,
  };
}

export function updateCurrentStudentId(studentId) {
  return {
    type: UPDATE_CURRENT_STUDENT_ID,
    payload: studentId,
  };
}
