import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { checkAndApplyDisountCode } from '../../../../actions/v3Order';
import OverlayLoader from '../../utils/OverlayLoader';

// Styled components
const DiscountBox = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 32px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const DiscountInput = styled.input`
  margin-top: 16px;
  padding: 8px;
  width: 80%; // adjust as needed
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ApplyButton = styled.input`
  display: inline-block;
  margin-top: 16px;
  padding: 10px 16px;
  background-color: #479de6;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  width: 200px;
  font-size: 16px;
  border: none;

  &:hover,
  &:focus {
    background-color: #357ab8;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

// Main component
const DiscountCode = () => {
  const dispatch = useDispatch();
  const [discountCode, setDiscountCode] = useState('');
  const checkoutIsLoading = useSelector(
    (state) => state.v3Order.checkoutIsLoading,
  );

  const handleInputChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const applyDiscountCode = (event) => {
    event.preventDefault();
    dispatch(checkAndApplyDisountCode(discountCode));
  };

  return (
    <DiscountBox>
      <Header>Discount Code</Header>
      <form onSubmit={applyDiscountCode}>
        <label htmlFor="discountCodeInput" className="sr-only">
          Enter Discount Code
        </label>
        <DiscountInput
          id="discountCodeInput"
          name="code"
          onChange={handleInputChange}
          placeholder="Discount Code"
          value={discountCode}
          aria-label="Discount Code Input"
          aria-required="true"
        />
        <ApplyButton
          type="submit"
          value="Apply Discount Code"
          aria-label="Submit Discount Code"
          disabled={checkoutIsLoading}
        />
      </form>
      {checkoutIsLoading && <OverlayLoader />}
    </DiscountBox>
  );
};

export default DiscountCode;
