import React, { Component } from 'react';

class ProductPackage extends Component {
  render() {
    const product = this.props.product;
    const className = 'product-package';

    const imageThumbnail = () => {
      if (
        typeof this.props.shoot !== undefined &&
        typeof this.props.shoot.pricing_model !== undefined &&
        this.props.shoot.pricing_model !== null &&
        this.props.shoot.pricing_model &&
        this.props.shoot.pricing_model.name === 'GROUP_1' && // TODO: make this a bit more flexible
        typeof this.props.studentPhotoPreviewUrl !== undefined &&
        this.props.studentPhotoPreviewUrl.length > 0
      ) {
        return this.props.studentPhotoPreviewUrl;
      } else {
        return this.props.product.image_thumbnail;
      }
    };

    return (
      <div className={className} onClick={this.props.selectShootProduct}>
        <div className={`${className}__header clearfix`}>
          <div className={`${className}-header__name`}>
            {this.props.product.name}
          </div>
          <div className={`${className}-header__price`}>
            {this.props.displayPrice(this.props.product.price)}
          </div>
        </div>
        <div className={`order-product__image-container`}>
          <img className={`order-product__image`} src={imageThumbnail()} />
        </div>
        <div className={`${className}__items`}>{product.description}</div>
        <div className="order-product__select-button-container">
          <div
            onClick={this.props.selectShootProduct}
            className="button bg--dark-blue"
          >
            Select
          </div>
        </div>
      </div>
    );
  }
}

export default ProductPackage;
