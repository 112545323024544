import React from 'react';
import styled from 'styled-components';

const LandingWrapper = styled.div`
  padding-top: 25px;
  @media (max-width: 992px) {
    padding-top: 0;
  }
`;

const Logo = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const Header = styled.h1`
  margin-top: 30px;
`;

const LandingBNL = (props) => {
  const { history } = props;

  return (
    <LandingWrapper className="gb-landing">
      <div className="gb-landing__top clearfix">
        <div className="gb-landing__top__left">
          <Logo className="bnl-landing__logo" alt="BNL Logo" />
          <Header className="gb-landing__top__left__header">Welcome</Header>
          <h3 className="gb-landing__top__left__subheader">
            To BNL online ordering!
          </h3>
          <h3 className="gb-landing__top__left__subheader">
            For the security of your child, you must create an account to place
            an order.
          </h3>
          <div
            className="gb-landing__signup-button cursor-pointer"
            onClick={() => history.push('/account-lookup', { step: 1 })}
          >
            Place Your Order
          </div>
        </div>
        <img
          className="gb-landing__top__image"
          src="https://pics-assets.s3.amazonaws.com/landing/BNL-LANDING-PAGE-2024.jpg"
          alt=""
        />
      </div>
    </LandingWrapper>
  );
};

export default LandingBNL;
