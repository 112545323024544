/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfigScreen from './configScreen';

export const Pose = ({ poses }) => (
  <ConfigScreen
    instruction="Select a Pose"
    itemsDesktop={5}
    itemsMobile={3}
    items={poses}
  />
);

const mapStateToProps = (state) => ({
  poses:
    state.additionalData.allAvailablePosesForOrder &&
    state.additionalData.allAvailablePosesForOrder.length > 0
      ? state.additionalData.allAvailablePosesForOrder
      : state.additionalData.availablePoses,
});

export default withRouter(connect(mapStateToProps)(Pose));
