import React, { Component } from 'react';

class IncentiveDesktopConfiguration extends Component {
  render() {
    const product = this.props.getConfiguringItem();

    return (
      <div className="incentive-config--desktop row">
        <h3 className="incentive-config__header">
          Free {product.name} - {this.props.getInstruction()}
        </h3>
        {this.props.renderBackButton()}
        {this.props.renderDesktopConfiguringItem()}
        <div className="col-xs-12 col-md-8 col-lg-8">
          {this.props.activeStep}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.presetConfiguration();
  }
}

export default IncentiveDesktopConfiguration;
