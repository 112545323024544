import React from 'react';
import {
  ProductItem,
  ProductItemButton,
  ProductItemWrapper,
  ProductItemName,
  ProductItemSecondaryButton,
} from './multiConfigStyles';

const MultiConfigProductItem = ({ item, onClick }) => {
  const { product, background, pose } = item;
  const backgroundOptionStyle = {
    backgroundImage: `url(${background?.image_thumbnail})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const renderCTA = () => {
    if (!pose || !background) {
      return (
        <ProductItemButton onClick={onClick(product)}>
          Choose Photo
        </ProductItemButton>
      );
    }
    if (pose && background) {
      return (
        <ProductItemSecondaryButton>Edit Selection</ProductItemSecondaryButton>
      );
    }
  };

  return (
    <ProductItemWrapper>
      <ProductItem
        style={background ? backgroundOptionStyle : {}}
        onClick={onClick(product)}
      >
        {pose ? (
          <img
            src={pose.url}
            onContextMenu={(e) => e.preventDefault()}
            alt="Overlay Pose"
          />
        ) : (
          product.image_thumbnail && (
            <img src={product.image_thumbnail} alt={product?.name} />
          )
        )}
        {renderCTA()}
      </ProductItem>
      <ProductItemName>{`${pose && background ? '✅ ' : ''}${
        product?.name
      }`}</ProductItemName>
    </ProductItemWrapper>
  );
};

export default MultiConfigProductItem;
