import { SET_COMPANY_NAME } from '../actions/action_company';

const INITIAL_STATE = {
  name: '',
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case SET_COMPANY_NAME:
      return { ...state, name: action.payload };
    default:
      return state;
  }
}
