import { SET_HISTORY, SET_REDIRECT } from '../actions/action_history';

const INITIAL_STATE = { history: {} };

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case SET_HISTORY:
      return { ...state, history: action.payload };
    default:
      return { ...state };
  }
}
