import React from 'react';
import styled from 'styled-components';

const LandingWrapper = styled.div`
  padding-top: 25px;
  @media (max-width: 992px) {
    padding-top: 0;
  }
`;

const Logo = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 300px;
  height: 120px;
  background-image: url('https://pics-assets.s3.amazonaws.com/logos/jellyjar-logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (max-width: 992px) {
    height: 90px;
    width: 100%;
  }
`;

const Header = styled.h1`
  margin-top: 30px;
`;

const LandingJellyJar = (props) => {
  const { history } = props;

  return (
    <LandingWrapper className="gb-landing">
      <div className="gb-landing__top__left">
        <Logo alt="Jelly Jar Logo" />
        <Header className="gb-landing__top__left__header">Welcome</Header>
        <h3 className="gb-landing__top__left__subheader">
          To Jelly Jar online ordering!
        </h3>
        <h3 className="gb-landing__top__left__subheader">
          For the security of your child, you must create an account to place an
          order.
        </h3>
        <div
          className="gb-landing__signup-button cursor-pointer"
          onClick={() => history.push('/account-lookup', { step: 1 })}
        >
          Place Your Order
        </div>
      </div>
      <img
        className="gb-landing__top__image"
        src="https://s3.amazonaws.com/pics-assets/landing/bnl_landing_default.png"
        alt=""
      />
    </LandingWrapper>
  );
};

export default LandingJellyJar;
