import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    Sentry.setUser({
      email: this.props.auth.currentUserEmail,
      id: this.props.auth.currentUserId,
    });
    Sentry.setContext('errorBoundary', {
      errorBoundaryTriggered: true,
    });

    Sentry.captureException(error);
    console.log('CAUGHT ERROR', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h3 className="error-boundary__header">Oops. There was an error.</h3>
          <div className="error-boundary__instruction">
            Logging out, reloading the page and logging back in may resolve the
            issue.
            <br />
            If it doesn't, please check back shortly or contact customer
            service.
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, null)(ErrorBoundary);
