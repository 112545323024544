import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { Integrations } from '@sentry/tracing';
import dotenv from 'dotenv';
import createRootReducer from '../reducers';
import Root from '../containers/Root';
import postConfigRoutingMiddleware from '../middlewares/postConfigRoutingMiddleware';
import history from '../utils/history';

require('react-hot-loader/patch');

dotenv.config();

if (
  process.env.REACT_SENTRY_DSN &&
  process.env.REACT_SENTRY_TRACE_SAMPLE_RATE
) {
  Sentry.init({
    dsn: process.env.REACT_SENTRY_DSN,
    replaysSessionSampleRate: parseFloat(
      process.env.REACT_SENTRY_TRACE_SAMPLE_RATE,
    ),
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: parseFloat(process.env.REACT_SENTRY_TRACE_SAMPLE_RATE),
    normalizeDepth: 10,
  });
}

const MIXPANEL_TOKENS = {
  prod: 'cfe83be0101fed901abe64885635b1d9',
  stage: '74a7b3d61e4a319eb60eaa307d4e7628',
  dev: '28dc4f492427ee4a9384346c8d53fc2d',
};

// Default to dev if REACT_MIXPANEL_ENV is not set or doesn't match any key in MIXPANEL_TOKENS
const mixpanelToken =
  MIXPANEL_TOKENS[process.env.REACT_MIXPANEL_ENV] || MIXPANEL_TOKENS.dev;

console.log('Mixpanel token:', mixpanelToken);

mixpanel.init(mixpanelToken, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  ignore_dnt: true,
});

const persistConfig = {
  key: 'root',
  storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// TODO: remove this if we end up not needing it
// Create browser history object
// export const history = createBrowserHistory();

const middleware = [
  promise,
  routerMiddleware(history),
  thunk,
  postConfigRoutingMiddleware({ history }),
];

// const middleware = [promise];
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

document.addEventListener('DOMContentLoaded', () => {
  const rootDiv = document.querySelector('#root');
  const containerDiv = rootDiv.appendChild(document.createElement('div'));

  const configData = document.querySelector('#config-data');
  const data = JSON.parse(configData.getAttribute('data-config'));

  ReactDOM.render(
    <AppContainer>
      <Root
        {...data}
        history={history}
        store={store}
        persistor={persistStore(store)}
      />
    </AppContainer>,
    containerDiv,
  );

  if (module.hot) {
    module.hot.accept('../containers/Root', () => {
      const newCreateStoreWithMiddleware = composeWithDevTools(
        applyMiddleware(...middleware),
      )(createStore);
      const newStore = newCreateStoreWithMiddleware(persistedReducer);
      const NewRoot = require('../containers/Root').default;

      ReactDOM.render(
        <AppContainer>
          <NewRoot store={newStore} persistor={persistStore(newStore)} />
        </AppContainer>,
        containerDiv,
      );
    });
  }
});
