import React, { Component } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Sentry from '@sentry/react';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';
import { acquireSms } from '../../../actions/action_order';
import {
  updateBnlPromptRetouching,
  updateRetouchingPromptedForCurrentOrder,
  updateInterstitialPromptedForCurrentOrder,
} from '../../../actions/action_cart';

// React.js components
import BnlOrderProductDesktopConfiguration from './bnl_order_product_desktop_configuration';
import BnlOrderProductMobileConfiguration from './bnl_order_product_mobile_configuration';
import BnlOrderProductConfigurationPoses from './bnl_order_product_configuration_poses';
import BnlOrderProductConfigurationBackgrounds from './bnl_order_product_configuration_backgrounds';
import BnlOrderProductConfigurationPersonalization from './bnl_order_product_configuration_personalization';
import BnlOrderProductConfigurationRequiredAttributes from './BnlOrderProductConfigurationRequiredAttributes';
import BnlOrderSmsForm from './bnl_order_sms_form';
import BnlOrderProductConfigurationNone from './bnl_order_product_configuration_none';
import PersonalizationConfigurationV2 from './PersonalizationConfigurationV2';

// constants files
import { ORDER_NAVIGATION } from '../../../constants/order_navigation';
import { DUMMY_IMAGE_URL } from '../../../constants/image_urls';
import { add } from 'lodash';

class BnlOrderProductConfigurationContainer extends Component {
  constructor(props) {
    super(props);

    this.STEPS = {
      pose: 1,
      background: 2,
      personalization: 3,
      sms: 4,
      none: 5,
      requiredAttributes: 6,
    };

    this.state = {
      activeStep: this.getInitialActiveStep(),
      mobileConfiguring: false,
      configuration: this.getInitialConfiguration(),
      isGeskusPostEvent: false,
      poseIndex: 0,
      selectedPose: this.getSelectedPose(),
      selectedBackground: this.getSelectedBackground(),
      personalizationFormValue: '',
      personalizationAlreadyExists: false,
    };

    this.addConfiguredItemToCart = this.addConfiguredItemToCart.bind(this);
    this.addRetouching = this.addRetouching.bind(this);
    this.addInterstitialProduct = this.addInterstitialProduct.bind(this);
    this.getPersonalizationValue = this.getPersonalizationValue.bind(this);
    this.getAdditionalAttributeValue =
      this.getAdditionalAttributeValue.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goBackMobile = this.goBackMobile.bind(this);
    this.goToBackgroundStep = this.goToBackgroundStep.bind(this);
    this.goToNextPose = this.goToNextPose.bind(this);
    this.goToNoneStep = this.goToNoneStep.bind(this);
    this.goToPersonalizationStep = this.goToPersonalizationStep.bind(this);
    this.goToPose = this.goToPose.bind(this);
    this.configureSuperCd = this.configureSuperCd.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.isGeskusPostEvent = this.isGeskusPostEvent.bind(this);
    this.processConfiguration = this.processConfiguration.bind(this);
    this.selectPose = this.selectPose.bind(this);
    this.selectMobilePose = this.selectMobilePose.bind(this);
    this.selectBackground = this.selectBackground.bind(this);
    this.setConfigurationForGeskusPostEvent =
      this.setConfigurationForGeskusPostEvent.bind(this);
    this.shootIsOutdoorsBackground = this.shootIsOutdoorsBackground.bind(this);
    this.storeBnlIncludedProductConfiguration =
      this.storeBnlIncludedProductConfiguration.bind(this);
    this.submitSmsPhone = this.submitSmsPhone.bind(this);

    // for organization purpose until mobile is finished
    // these are the functions i'm passing to both desktop and mobile components
    this.goToMobileBackgroundStep = this.goToMobileBackgroundStep.bind(this);
    this.goToMobilePoseStep = this.goToMobilePoseStep.bind(this);
    this.renderInstruction = this.renderInstruction.bind(this);
    this.renderSubheader = this.renderSubheader.bind(this);
    this.renderDesktopSelectedPoseAndBackground =
      this.renderDesktopSelectedPoseAndBackground.bind(this);
    this.renderMobileSelectedPoseAndBackground =
      this.renderMobileSelectedPoseAndBackground.bind(this);
    this.renderDesktopButton = this.renderDesktopButton.bind(this);
    this.renderMobileButton = this.renderMobileButton.bind(this);
    this.renderActiveStep = this.renderActiveStep.bind(this);
    this.selectMobileBackground = this.selectMobileBackground.bind(this);
    this.renderPersonalizationForm = this.renderPersonalizationForm.bind(this);
    this.renderRequiredAttributesForm =
      this.renderRequiredAttributesForm.bind(this);
    this.renderSmsForm = this.renderSmsForm.bind(this);
  }

  componentDidMount() {
    const {
      additionalData: { student, selectedStudent },
      cart,
      setStudent,
      studentId,
    } = this.props;

    // Set the student on mount (if available). Allows us to handle post-event no-config products, where
    // we would otherwise access the studentId via during pose selection.
    if (studentId) {
      setStudent(studentId, true);
    } else if (selectedStudent) {
      setStudent(selectedStudent, false);
    }

    let personalizationFormValue;
    let personalizationAlreadyExists = false;

    if (student && cart.personalization && cart.personalization[student.id]) {
      personalizationFormValue = cart.personalization[student.id];
      personalizationAlreadyExists = true;
    }

    this.scrollToTop();

    this.setState(
      {
        isGeskusPostEvent: this.isGeskusPostEvent(),
        personalizationFormValue,
        personalizationAlreadyExists,
      },
      () => {
        this.setConfigurationForGeskusPostEvent();
      },
    );
  }

  componentWillUnmount() {
    this.props.handleBnlConfigurationUnmount();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  setConfigurationForGeskusPostEvent() {
    const { isGeskusPostEvent, selectedPose } = this.state;
    const {
      additionalData: { student },
    } = this.props;

    if (isGeskusPostEvent) {
      // console.log('Geskus post-event detected... setting configuration.');

      // TODO: revist this
      // We need to set the configuration here to match the functionality triggered
      // 'Handle Geskus Post-Event'
      // when selecting a pose. For Geskus, the pose is already selected out of the box.
      // if (this.isGeskusPostEvent()) {
      const configuration = {}; // default config for Geskus is an empty object
      const { poseIndex } = this.state;

      if (!configuration[this.state.poseIndex]) {
        const innerConfig = {
          backgroundCodes: [],
          backgroundPrices: [],
          personalizationValue: '',
          additionalAttributes: {},
          pose: selectedPose[poseIndex],
          poseIds: [0], // set to 0 because we only have one pose
        };

        configuration[this.state.poseIndex] = innerConfig;

        this.setState(
          {
            configuration,
          },
          () => {
            // console.log(
            //   '%cNEED TO SET STUDENT DATA HERE from additional data',
            //   'color: red; font-weight: bold;',
            // );
            // console.log(student);
            this.props.setStudent(student);
            // do nothing
          },
        );
      }
    }
  }

  getSelectedPose() {
    const selectedPose = {};
    const configuration = this.getInitialConfiguration();

    if (configuration && configuration[0] && configuration[0].pose) {
      selectedPose[0] = configuration[0].pose;
    }

    if (configuration && configuration[1] && configuration[1].pose) {
      selectedPose[1] = configuration[1].pose;
    }

    return selectedPose;
  }

  getSelectedBackground() {
    const selectedBackground = {};
    const configuration = this.getInitialConfiguration();

    if (
      configuration &&
      configuration[0] &&
      configuration[0].backgroundCodes &&
      configuration[0].backgroundCodes.length > 0
    ) {
      selectedBackground[0] =
        this.props.formattedBackgroundOptions[configuration[0].backgroundCodes];
    }

    if (
      configuration &&
      configuration[1] &&
      configuration[1].backgroundCodes &&
      configuration[1].backgroundCodes.length > 0
    ) {
      selectedBackground[1] =
        this.props.formattedBackgroundOptions[configuration[1].backgroundCodes];
    }

    return selectedBackground;
  }

  getInitialActiveStep() {
    let activeStep = this.STEPS.pose;
    const product = this.props.selectedShootProduct;

    if (!this.props.selectedShootProduct.requires_configuration) {
      activeStep = this.STEPS.none;
    } else if (
      this.props.selectedShootProduct.name === 'Super CD + Cloud Download'
    ) {
      if (this.props.order.smsAcquired) {
        activeStep = this.STEPS.none;
      } else if (this.props.selectedShootProduct.sms_required) {
        activeStep = this.STEPS.sms;
      } else {
        activeStep = this.STEPS.none;
      }
    } else if (this.productDoesNotRequirePoseOrBackground()) {
      if (this.props.order.smsAcquired) {
        activeStep = this.STEPS.none;
      } else if (this.props.selectedShootProduct.sms_required) {
        activeStep = this.STEPS.sms;
      } else if (this.productRequiresPersonalization()) {
        activeStep = this.STEPS.personalization;
      } else {
        activeStep = this.STEPS.none;
      }
    } else if (this.isGeskusPostEvent()) {
      // Handle Geskus Post Event
      // TODO: revisit this logic
      activeStep = this.STEPS.background;
    } else if (this.shootIsPreShoot()) {
      if (this.shootIsOutdoorsBackground()) {
        if (this.productRequiresPersonalization()) {
          activeStep = this.STEPS.personalization;
        } else if (this.productRequiresSms()) {
          if (!this.props.order.smsAcquired) {
            activeStep = this.STEPS.sms;
          } else {
            activeStep = this.STEPS.none;
          }
        } else {
          activeStep = this.STEPS.none;
        }
      } else if (this.props.selectedShootProduct.image_type === 'group_image') {
        activeStep = this.STEPS.none;
      } else {
        activeStep = this.STEPS.background;
      }
    }

    return activeStep;
  }

  getInitialConfiguration() {
    const configuration = {};

    // console.log('getInitialConfiguration: ', this.props.configuration);

    if (this.props.selectedShootProduct.product_type === 'nth_free') {
      // reset background when user is brought to nth-free config step
      this.setState({ selectedBackground: {} });
    }

    if (!this.props.selectedShootProduct.requires_configuration) {
      return {
        0: {
          backgroundCodes: [],
          backgroundPrices: [],
          personalizationValue: '',
          additionalAttributes: {},
          pose: null,
          poseIds: [],
        },
      };
    }
    if (this.shootIsPreShoot() || this.shootIsOutdoorsBackground()) {
      if (this.props.selectedShootProduct.background_options_count === 0) {
        configuration['0'] = {
          backgroundCodes: [],
          backgroundPrices: [],
          personalizationValue: '',
          additionalAttributes: {},
          pose: null,
          poseIds: [],
        };
      }

      for (
        let i = 0;
        i < this.props.selectedShootProduct.background_options_count;
        i++
      ) {
        configuration[i] = {};
        const innerConfig = configuration[i];

        innerConfig.backgroundCodes = [];
        innerConfig.backgroundPrices = [];
        innerConfig.personalizationValue = '';
        innerConfig.additionalAttributes = {};
        innerConfig.pose = null;
        innerConfig.poseIds = [];
      }

      // console.log('INITIAL CONFIGURATION: ', configuration);

      return configuration;
    }

    if (this.productDoesNotRequirePoseOrBackground()) {
      const {
        additionalData: { isGeskus, isPostEvent },
      } = this.props;

      let pose;

      if (!isGeskus) {
        const { cpmId } = this.props.gallery;
        const galleryObj = Object.values(
          this.props.gallery.galleries[cpmId],
        )[0];
        const { student } = galleryObj;

        galleryObj.gallery.forEach((galleryImage) => {
          if (!pose && galleryImage.image_type === 'individual_photo') {
            pose = galleryImage;
          }
        });

        pose.student = student;
      }

      configuration['0'] = {
        backgroundCodes: [],
        backgroundPrices: [],
        personalizationValue: '',
        additionalAttributes: {},
        pose,
        poseIds: [pose],
      };

      return configuration;
    }

    return this.props.configuration || {};
  }

  productDoesNotRequirePoseOrBackground() {
    const product = this.props.selectedShootProduct;
    return product.background_options_count === 0 && product.poses_count === 0;
  }

  isGeskusPostEvent() {
    const {
      additionalData: { isGeskus, isPostEvent },
    } = this.props;

    if (isGeskus && isPostEvent) {
      return true;
    }

    return false;
  }

  shootIsPreShoot() {
    const { additionalData } = this.props;

    // Handles Geskus post-event orders
    // TODO: revist this
    if (additionalData) {
      if (additionalData?.isPostEvent === true) {
        return false;
      } else if (additionalData?.isPostEvent === false) {
        return true;
      }
    }

    const {
      bnlPreShoot: { preShoot },
    } = this.props;

    const {
      shoot: {
        shoot: { company_auth_token },
      },
    } = this.props;

    // #GESKUS_V2_UI_TODO
    // TODO: handle geskus post-shoots
    // TODO: revisit this
    if (company_auth_token === 'geskus') {
      return true;
    }

    return preShoot;
  }

  shootIsOutdoorsBackground() {
    return (
      this.props.shoot.shoot.background_choice_mode ===
      'outdoors_background_only'
    );
  }

  isAMultiPosesConfiguration() {
    let maxCount;

    if (this.shootIsPreShoot()) {
      maxCount = this.props.selectedShootProduct.background_options_count;
    } else {
      maxCount = this.props.selectedShootProduct.poses_count;
    }
    return maxCount > 1;
  }

  hasAdditionalPoseOrBackgroundToConfigure() {
    let maxCount;

    if (this.shootIsPreShoot()) {
      maxCount = this.props.selectedShootProduct.background_options_count;
    } else {
      maxCount = this.props.selectedShootProduct.poses_count;
    }

    return this.state.poseIndex + 1 < maxCount;
  }

  productRequiresPersonalization() {
    return (
      this.props.selectedShootProduct.personalization_status ===
      'personalization_required'
    );
  }

  productRequiresAdditionalAttributes() {
    return (
      this.props.selectedShootProduct.required_attributes !== undefined &&
      this.props.selectedShootProduct.required_attributes.length > 0
    );
  }

  productHasOptionalPersonalization() {
    return (
      this.props.selectedShootProduct.personalization_status ===
      'personalization_optional'
    );
  }

  configuringLastPose() {
    return (
      this.state.poseIndex === this.props.selectedShootProduct.poses_count - 1
    );
  }

  productRequiresSms() {
    return this.props.selectedShootProduct.sms_required;
  }

  productImageTypeIsPortraitImage() {
    return this.props.selectedShootProduct.image_type === 'portrait_image';
  }

  productImageTypeIsGroupImage() {
    return this.props.selectedShootProduct.image_type === 'group_image';
  }

  productIsRetouching() {
    const { selectedShootProduct, shoot } = this.props;

    const retouchingProductIds = shoot.retouchingProducts.map(
      (product) => product.id,
    );

    let selectedShootProductId = selectedShootProduct.id;

    if (
      typeof selectedShootProductId === 'string' &&
      selectedShootProductId.includes('_')
    ) {
      selectedShootProductId = parseInt(
        selectedShootProductId.split('_')[0],
        10,
      );
    }

    if (retouchingProductIds.includes(selectedShootProductId)) {
      return true;
    }

    return false;
  }

  productIsInterstitial() {
    const { selectedShootProduct, shoot } = this.props;

    const interstitialProductIds = shoot.interstitialProducts.map(
      (product) => product.id,
    );

    let selectedShootProductId = selectedShootProduct.id;

    if (
      typeof selectedShootProductId === 'string' &&
      selectedShootProductId.includes('_')
    ) {
      selectedShootProductId = parseInt(
        selectedShootProductId.split('_')[0],
        10,
      );
    }

    if (interstitialProductIds.includes(selectedShootProductId)) {
      return true;
    }

    return false;
  }

  selectedAllPoses() {
    const { isGeskus } = this.props.additionalData;

    const product = this.props.selectedShootProduct;
    const poseCount = Object.keys(this.state.selectedPose).length;

    // console.log('selectedAllPoses()');
    // console.log('product', product);
    // console.log('state', this.state);
    // console.log('poseCount', poseCount);
    // console.log('product.poses_count', product.poses_count);

    // TODO: revist
    // Handle Geskus Post-Event
    if (isGeskus) {
      return true;
    }

    // TODO: revisit this
    // If the product does not require a pose, then can we can assume that
    // we should just return true here?
    if (product.poses_count === 0) return true;

    return poseCount === product.poses_count;
  }

  selectedAllBackgrounds() {
    const product = this.props.selectedShootProduct;
    const backgroundCount = Object.keys(this.state.selectedBackground).length;

    let maxCount;

    if (this.shootIsPreShoot()) {
      maxCount = product.background_options_count;
    } else {
      maxCount = product.poses_count;
    }

    return backgroundCount === maxCount;
  }

  selectedProductIsSuperCd() {
    return this.props.selectedShootProduct.name === 'Super CD + Cloud Download';
  }

  canBeAddedToCart() {
    if (this.selectedProductIsSuperCd()) return !!this.props.smsPhone;

    if (Object.keys(this.state.configuration).length === 0) {
      return false;
    }

    const product = this.props.selectedShootProduct;

    if (this.shootIsPreShoot()) {
      if (this.shootIsOutdoorsBackground()) {
        if (this.productRequiresPersonalization()) {
          return !!this.getPersonalizationValue();
        }
        if (this.productRequiresSms()) {
          return !this.props.smsPhone;
        }

        return true;
      }

      if (this.productImageTypeIsPortraitImage()) {
        for (
          let poseIndex = 0;
          poseIndex < product.background_options_count;
          poseIndex++
        ) {
          const configuration = this.state.configuration[poseIndex];

          if (!product.requires_configuration) {
            return true;
          }
          if (!configuration) {
            return false;
          }
          if (
            this.shootIsOutdoorsBackground() &&
            configuration.backgroundCodes.length === 0
          ) {
            return false;
          }
          if (this.productRequiresPersonalization()) {
            !this.getPersonalizationValue();
          } else if (this.productRequiresSms() && !this.props.smsPhone) {
            return false;
          }
        }
      }

      return true;
    }

    if (product.image_type === 'portrait_image') {
      for (let poseIndex = 0; poseIndex < product.poses_count; poseIndex++) {
        const configuration = this.state.configuration[poseIndex];

        if (!configuration) {
          return false;
        }
        if (configuration.poseIds.length === 0) {
          return false;
        }
        if (
          !this.shootIsOutdoorsBackground() &&
          configuration.backgroundCodes.length === 0
        ) {
          return false;
        }
        if (
          product.personalization_status === 'personalization_required' &&
          !this.getPersonalizationValue()
        ) {
          return false;
        }
        if (product.sms_required && !this.props.smsPhone) {
          return false;
        }
      }

      return true;
    }

    if (product.is_personalization) {
      return true;
    }

    if (product.image_type === 'group_image') {
      // console.log(`product image_type is group_image`);
      const canBeAdded = this.selectedAllPoses();
      // console.log(`canBeAdded: ${canBeAdded}`);
      return canBeAdded;
    }
  }

  goBack() {
    const { updateOrderActiveComponent } = this.props;
    const product = this.props.selectedShootProduct;

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        if (this.isAMultiPosesConfiguration() && this.state.poseIndex > 0) {
          let activeStep;
          if (this.productRequiresPersonalization()) {
            activeStep = this.STEPS.personalization;
          } else if (this.shootIsOutdoorsBackground()) {
            activeStep = this.STEPS.pose;
          } else {
            activeStep = this.STEPS.background;
          }

          const prevPoseIndex = this.state.poseIndex - 1;
          const bgCode =
            this.state.configuration[prevPoseIndex].backgroundCodes[0];
          const configuration = this.props.cloneObject(
            this.state.configuration,
          );
          const selectedPose = this.props.cloneObject(this.state.selectedPose);

          if (selectedPose[this.state.poseIndex]) {
            delete selectedPose[this.state.poseIndex];
          }

          if (configuration[this.state.poseIndex]) {
            delete configuration[this.state.poseIndex];
          }

          this.setState({
            activeStep,
            configuration,
            poseIndex: this.state.poseIndex - 1,
            selectedPose,
          });
        } else {
          this.props.updateUnmountedByBackButton();
        }
        return;
      case this.STEPS.background:
        const configuration = this.props.cloneObject(this.state.configuration);
        const selectedBackground = this.props.cloneObject(
          this.state.selectedBackground,
        );

        if (this.shootIsPreShoot()) {
          if (
            !this.isAMultiPosesConfiguration() ||
            this.state.poseIndex === 0
          ) {
            this.props.updateUnmountedByBackButton();
          } else {
            delete configuration[this.state.poseIndex];
            delete selectedBackground[this.state.poseIndex];

            this.setState({
              configuration,
              poseIndex: this.state.poseIndex - 1,
              selectedBackground,
            });
          }
        } else {
          const { poseIndex } = this.state;

          if (selectedBackground[poseIndex]) {
            delete selectedBackground[poseIndex];
          }

          if (configuration[poseIndex]) {
            configuration[poseIndex].backgroundCodes = [];
            configuration[poseIndex].backgroundPrices = [];
          }

          this.setState({
            activeStep: this.STEPS.pose,
            configuration,
            selectedBackground,
          });
        }
        return;
      case this.STEPS.personalization:
        if (this.shootIsPreShoot() && this.shootIsOutdoorsBackground()) {
          updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
        } else if (this.shootIsOutdoorsBackground()) {
          this.setState({ activeStep: this.STEPS.pose });
        } else {
          this.setState({ activeStep: this.STEPS.background });
        }
        return;
      case this.STEPS.requiredAttributes:
        if (this.shootIsPreShoot() && this.shootIsOutdoorsBackground()) {
          updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
        } else if (this.shootIsOutdoorsBackground()) {
          this.setState({ activeStep: this.STEPS.pose });
        } else {
          this.setState({ activeStep: this.STEPS.background });
        }
        return;
      case this.STEPS.sms:
        if (this.shootIsPreShoot() && this.shootIsOutdoorsBackground()) {
          if (!this.productRequiresPersonalization()) {
            updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
          }
          // TODO: revist how we're handling the Super CD here
        } else if (
          product.name === 'Super CD + Cloud Download' ||
          (product.background_options_count === 0 &&
            product.poses_count === 0 &&
            product.child_products.length === 0)
        ) {
          updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
        } else if (this.productRequiresPersonalization()) {
          this.setState({ activeStep: this.STEPS.personalization });
        } else if (this.shootIsOutdoorsBackground()) {
          if (this.productRequiresPersonalization()) {
            this.setState({ activeStep: this.STEPS.personalization });
          } else {
            this.setState({ activeStep: this.STEPS.pose });
          }
        } else {
          this.setState({ activeStep: this.STEPS.background });
        }
        return;
      case this.STEPS.none:
        if (product.is_retouching) {
          this.props.updateUnmountedByBackButton();
        } else if (this.shootIsPreShoot() && this.shootIsOutdoorsBackground()) {
          if (this.productRequiresSms()) {
            this.setState({ activeStep: this.STEPS.sms });
          } else if (this.productRequiresPersonalization()) {
            this.setState({ activeStep: this.STEPS.personalization });
          } else {
            updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
          }
        } else if (product.image_type === 'group_image') {
          if (this.shootIsPreShoot()) {
            updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
          } else {
            this.setState({ activeStep: this.STEPS.pose });
          }
          // TODO: revist how we're handling the Super CD here
        } else if (
          product.name === 'Super CD + Cloud Download' ||
          (product.background_options_count === 0 &&
            product.poses_count === 0 &&
            product.child_products.length === 0)
        ) {
          updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
        } else {
          let activeStep;
          if (this.props.order.smsAcquired) {
            if (this.productRequiresPersonalization()) {
              this.setState({ activeStep: this.STEPS.personalization });
            } else if (!this.productImageTypeIsGroupImage()) {
              this.setState({ activeStep: this.STEPS.background });
            } else {
              updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
            }
          } else {
            this.setState({ activeStep: this.STEPS.sms });
          }
        }
        break;
      default:
        updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
    }
  }

  goBackMobile() {
    this.setState({ mobileConfiguring: false });
    // saving this for later incase

    // const poseIndex = this.state.poseIndex;
    // const selectedPose = this.props.cloneObject(this.state.selectedPose);
    // const selectedBackground = this.props.cloneObject(
    //   this.state.selectedBackground
    // );
    //
    // switch(this.state.activeStep) {
    //   case this.STEPS["pose"]:
    //     if (selectedPose[poseIndex]) delete selectedPose[poseIndex];
    //     this.setState({
    //       mobileConfiguring: false,
    //       selectedPose: selectedPose
    //     });
    //     return;
    //   case this.STEPS["background"]:
    //     if (selectedBackground[poseIndex]) delete selectedBackground[poseIndex];
    //     this.setState({
    //       mobileConfiguring: false,
    //       selectedPose: selectedBackground
    //     });
    //     return;
    // }
  }

  selectPose(pose) {
    const selectedPose = this.props.cloneObject(this.state.selectedPose);
    selectedPose[this.state.poseIndex] = pose;

    this.setState({ selectedPose }, () => {
      this.props.setStudent(pose.student);
      const product = this.props.selectedShootProduct;
      if (this.shootIsOutdoorsBackground()) {
        this.goToNextStep();
      } else if (product.image_type === 'portrait_image') {
        this.goToBackgroundStep();
      } else if (product.image_type === 'group_image') {
        this.processGroupImageConfiguration();
      }
    });
  }

  selectMobilePose(pose) {
    const { configuration } = this.state;
    const selectedPose = this.props.cloneObject(this.state.selectedPose);
    selectedPose[this.state.poseIndex] = pose;

    if (this.shootIsOutdoorsBackground()) {
      const innerConfig = configuration[this.state.poseIndex];

      innerConfig.pose = selectedPose;
      innerConfig.poseIds = [selectedPose.id];
    }

    this.setState(
      {
        configuration,
        mobileConfiguring: false,
        selectedPose,
      },
      () => {
        if (this.props.selectedShootProduct.image_type === 'group_image') {
          this.processGroupImageConfiguration();
        }
        this.props.setStudent(pose.student);
        window.scrollTo(0, 0);
      },
    );
  }

  selectBackground(bgOption) {
    const product = this.props.selectedShootProduct;
    const { isGeskusPostEvent, poseIndex } = this.state;
    const configuration = this.props.cloneObject(this.state.configuration);
    const selectedBackground = this.props.cloneObject(
      this.state.selectedBackground,
    );

    // console.log('SELECT BACKGROUND');
    // console.log(configuration);
    // console.log(poseIndex);
    // console.log(selectedBackground);

    Sentry.setContext('selectBackground', {
      configuration,
      selectedBackground,
      selectedShootProduct: product,
      orders: this.props.cart.orders,
    });

    selectedBackground[poseIndex] = bgOption;

    // console.log(selectedBackground[poseIndex]);
    // console.log(configuration[poseIndex]);
    // console.log('++++');

    // Handle Geskus Post-Event
    // TODO: revisit this
    if (isGeskusPostEvent && !configuration[poseIndex]) {
      configuration[poseIndex] = {
        backgroundCodes: [],
      };
    }

    configuration[poseIndex].backgroundCodes = [bgOption.id];

    // set student
    let student;

    if (this.shootIsPreShoot) {
      student = this.props.bnlPreShoot.selectedStudent;
    } else {
      student = configuration[poseIndex].pose.student;
    }

    configuration[poseIndex].backgroundPrices = [0];

    this.setState(
      {
        configuration,
        selectedBackground,
      },
      () => {
        if (
          this.productRequiresAdditionalAttributes() &&
          this.configuringLastPose()
        ) {
          this.setState({ activeStep: this.STEPS.requiredAttributes });
        } else if (
          (this.productRequiresPersonalization() ||
            this.productOffersPersonalization()) &&
          this.configuringLastPose()
        ) {
          this.setState({ activeStep: this.STEPS.personalization });
        } else if (this.hasAdditionalPoseOrBackgroundToConfigure()) {
          if (this.shootIsPreShoot()) {
            this.setState({ poseIndex: this.state.poseIndex + 1 });
          } else {
            this.goToNextPose();
          }
        } else if (this.productRequiresSms()) {
          if (!this.props.order.smsAcquired) {
            this.setState({ activeStep: this.STEPS.sms });
          } else {
            this.setState({ activeStep: this.STEPS.none });
          }
        }

        this.scrollToTop();
      },
    );
  }

  selectMobileBackground(bgOption) {
    const product = this.props.selectedShootProduct;
    const { poseIndex } = this.state;
    const configuration = this.props.cloneObject(this.state.configuration);
    const selectedBackground = this.props.cloneObject(
      this.state.selectedBackground,
    );

    selectedBackground[poseIndex] = bgOption;
    configuration[poseIndex].backgroundCodes = [bgOption.id];
    configuration[poseIndex].backgroundPrices = [bgOption.price];

    this.setState(
      {
        configuration,
        mobileConfiguring: false,
        selectedBackground,
      },
      () => {
        window.scrollTo(0, 0);
      },
    );
  }

  selectedAPose() {
    return !!this.state.selectedPose;
  }

  selectedABackground() {
    return !!this.state.selectedBackground;
  }

  productRequiresPersonalization() {
    return (
      this.props.selectedShootProduct.personalization_status ===
      'personalization_required'
    );
  }

  productOffersPersonalization() {
    return (
      this.props.selectedShootProduct.personalization_status ===
      'personalization_optional'
    );
  }

  productRequiresSms() {
    return this.props.selectedShootProduct.sms_required;
  }

  goToPose(clickedIndex) {
    const { poseIndex } = this.state;
    const configuration = this.props.cloneObject(this.state.configuration);

    if (poseIndex !== clickedIndex) {
      if (configuration[poseIndex].backgroundCodes.length === 0) {
        delete configuration[poseIndex];
      }
    }

    if (configuration[clickedIndex]) {
      this.setState({
        activeStep: this.STEPS.pose,
        configuration,
        poseIndex: clickedIndex,
      });
    }
  }

  goToMobilePoseStep(poseIndex) {
    if (!this.shootIsPreShoot()) {
      this.setState(
        {
          activeStep: this.STEPS.pose,
          mobileConfiguring: true,
          poseIndex,
        },
        () => {
          window.scrollTo(0, 0);
        },
      );
    }
  }

  goToMobileBackgroundStep(poseIndex) {
    const {
      additionalData: { isGeskus },
    } = this.props;

    if (this.shootIsOutdoorsBackground()) {
      Swal.fire(
        "This shoot is an outdoor shoot and does not need a background. Please press 'Done, Add To Cart'",
      );
      return;
    }

    if (
      !this.shootIsPreShoot() &&
      !this.state.selectedPose[poseIndex] &&
      !isGeskus
    ) {
      Swal.fire(`Please select a pose first for Pose ${poseIndex + 1}`);
    } else {
      this.setState(
        {
          activeStep: this.STEPS.pose,
          mobileConfiguring: true,
          poseIndex,
        },
        () => {
          this.goToBackgroundStep();
        },
      );
    }
  }

  goToBackgroundStep() {
    // console.log('GOING TO BACKGROUND STEP');
    // console.log(this.state.selectedPose);
    if (this.state.selectedPose) {
      const configuration = this.props.cloneObject(this.state.configuration);
      const { poseIndex } = this.state;

      if (!configuration[this.state.poseIndex]) {
        const innerConfig = {
          backgroundCodes: [],
          backgroundPrices: [],
          personalizationValue: '',
          additionalAttributes: {},
          pose: this.state.selectedPose[poseIndex],
          poseIds: [this.state.selectedPose[poseIndex].id],
        };

        configuration[this.state.poseIndex] = innerConfig;
      }

      this.setState(
        {
          activeStep: this.STEPS.background,
          configuration,
        },
        () => {
          window.scrollTo(0, 0);
        },
      );
    } else {
      Swal.fire('Please select a pose');
    }
  }

  goToPersonalizationStep() {
    const configuration = this.state.configuration[this.state.poseIndex];

    if (
      this.shootIsOutdoorsBackground() ||
      configuration.backgroundCodes.length > 0
    ) {
      switch (this.props.selectedShootProduct.personalization_status) {
        case 'personalization_required':
        case 'personalization_optional':
          this.setState({ activeStep: this.STEPS.personalization });
          return;
        case 'no_personalization':
          this.processConfiguration();
      }
    } else {
      Swal.fire('Please select a background');
    }
  }

  goToAdditionalAttributesStep() {
    this.setState({ activeStep: this.STEPS.requiredAttributes });
  }

  goToSmsStep() {
    this.setState({ activeStep: this.STEPS.sms });
  }

  goToNoneStep() {
    this.setState({ activeStep: this.STEPS.none });
  }

  goToSmsFormOraddConfiguredBnlItemToCart() {
    const product = this.props.selectedShootProduct;
    // console.log('goToSmsFormOraddConfiguredBnlItemToCart');

    if (this.productRequiresSms() && !this.props.order.smsAcquired) {
      this.goToSmsStep();
    } else {
      // console.log('we are here -- addConfiguredItemToCart()');
      this.addConfiguredItemToCart();
    }
  }

  goToNextPose() {
    this.setState({
      activeStep: this.STEPS.pose,
      poseIndex: this.state.poseIndex + 1,
    });
  }

  addConfiguredItemToCart(product = null) {
    // console.log('addConfiguredItemToCart');
    // console.log(product);
    const { personalizationFormValue } = this.state;

    this.props.addConfiguredBnlItemToCart(
      this.state.configuration,
      product,
      personalizationFormValue,
    );
  }

  storeBnlIncludedProductConfiguration() {
    this.props.storeBnlIncludedProductConfiguration(this.state.configuration);
  }

  handleOnChange(event) {
    // handle product-based personalization
    if (event.target.name === 'personalizationProductValue') {
      this.setState({ personalizationFormValue: event.target.value });
    } else {
      const newConfiguration = this.props.cloneObject(this.state.configuration);

      if (event.target.name === 'personalizationValue') {
        newConfiguration[this.state.poseIndex].personalizationValue =
          event.target.value;
      } else {
        // assumption is that the additionalAttributes inputs will be the only other inputs linked to this method
        newConfiguration[this.state.poseIndex].additionalAttributes[
          event.target.name
        ] = event.target.value;
      }

      this.setState({ configuration: newConfiguration });
    }
  }

  getPersonalizationValue() {
    const {
      additionalData: { isGeskus },
      shoot,
    } = this.props;
    const { configuration, personalizationFormValue, poseIndex } = this.state;

    if (isGeskus && shoot.personalizationProduct) {
      return personalizationFormValue;
    }

    const orderConfig = configuration[poseIndex];

    if (orderConfig) {
      return orderConfig.personalizationValue;
    }

    return null;
  }

  // TODO: implement
  getAdditionalAttributeValue(attr) {
    const configuration = this.state.configuration[this.state.poseIndex];

    if (
      configuration &&
      configuration.additionalAttributes &&
      configuration.additionalAttributes[attr]
    ) {
      return configuration.additionalAttributes[attr];
    }

    return '';
  }

  containsValuesForAllAdditionalAttributes() {
    let configuredProperly = true;

    if (!this.props.selectedShootProduct.required_attributes) {
      return false;
    }

    this.props.selectedShootProduct.required_attributes.forEach((attrName) => {
      const attrVal =
        this.state.configuration[this.state.poseIndex].additionalAttributes[
          attrName
        ];

      if (!attrVal || attrVal === '') {
        configuredProperly = false;
      }
    });

    return configuredProperly;
  }

  configureSuperCd() {
    let configPose;
    const { cpmId } = this.props.gallery;
    const galleries = this.props.gallery.galleries[cpmId];

    for (const studentId in galleries) {
      const { gallery } = galleries[studentId];
      gallery.forEach((pose, i) => {
        if (i === 0 && !configPose) {
          configPose = pose;
          configPose.student = galleries[studentId].student;
        }
      });
    }

    const configuration = {};
    configuration[this.state.poseIndex] = {
      backgroundCodes: [],
      backgroundPrices: [],
      personalizationValue: '',
      additionalAttributes: {},
      pose: configPose,
      poseIds: [configPose],
    };

    this.setState({ configuration }, () => {
      this.props.addConfiguredBnlItemToCart(this.state.configuration);
    });
  }

  validateMobileConfiguration() {
    const {
      additionalData: { isGeskus },
    } = this.props;

    const product = this.props.selectedShootProduct;
    let maxCount;

    // TODO: revist this -- not sure why the background options count is based
    // on pre vs. post-shoot status?
    if (this.shootIsPreShoot()) {
      maxCount = product.background_options_count;
    } else if (product.background_options_count === 0) {
      maxCount = 0;
    } else {
      maxCount = product.poses_count;
    }

    for (let index = 0; index < maxCount; index++) {
      // pose
      if (!this.shootIsPreShoot() && !isGeskus) {
        if (!this.state.selectedPose[index]) {
          Swal.fire(`Please select Pose ${index + 1}`);
          return false;
        }
      }

      // background
      if (!this.shootIsOutdoorsBackground() && maxCount > 0) {
        if (!this.state.selectedBackground[index]) {
          Swal.fire(`Please select Background ${index + 1}`);
          return false;
        }
      }
    }

    // personalization
    if (this.productRequiresPersonalization()) {
      if (
        !this.getPersonalizationValue() ||
        !this.getPersonalizationValue().trim()
      ) {
        Swal.fire('Please fill in a personalization');
        return false;
      }
    }

    // required attributes
    if (
      this.productRequiresAdditionalAttributes() &&
      !this.containsValuesForAllAdditionalAttributes()
    ) {
      Swal.fire('Please fill in all fields');
      return false;
    }

    // sms
    if (this.productRequiresSms() && !this.props.order.smsAcquired) {
      if (!this.props.smsPhone) {
        Swal.fire('Please fill in a phone number');
        return false;
      }
    }

    return true;
  }

  goToNextStep() {
    const configuration = this.props.cloneObject(this.state.configuration);

    if (this.state.selectedPose) {
      const { poseIndex } = this.state;

      const innerConfig = {
        backgroundCodes: [],
        backgroundPrices: [],
        personalizationValue: '',
        additionalAttributes: {},
        pose: this.state.selectedPose[poseIndex],
        poseIds: [this.state.selectedPose[poseIndex].id],
      };

      configuration[poseIndex] = innerConfig;
    }

    this.setState({ configuration }, () => {
      if (this.hasAdditionalPoseOrBackgroundToConfigure()) {
        // if poseIndex isn't last pose, go to next poseIndex
        this.goToNextPose();
      } else if (this.productRequiresAdditionalAttributes()) {
        this.goToAdditionalAttributesStep();
      } else if (
        this.productRequiresPersonalization() ||
        this.productHasOptionalPersonalization()
      ) {
        // if product requires personalization, go to personalization step
        this.goToPersonalizationStep();
      } else if (this.productRequiresSms() && !this.props.order.smsAcquired) {
        // if product requires sms and app hasn't acquired sms yet, go to sms step
        this.goToSmsStep();
      }
    });
  }

  processConfiguration(mobile = false) {
    const { poseIndex } = this.state;
    const shootProduct = this.props.selectedShootProduct;
    const posesCount = shootProduct.poses_count;
    const personalizationRequirement = shootProduct.personalization_status;

    // console.log('PROCESS CONFIGURATION');
    // console.log(this.state.activeStep);
    // console.log(this.STEPS);

    if (this.selectedProductIsSuperCd()) {
      this.props.addConfiguredBnlItemToCart(this.state.configuration);
    } else if (mobile === true) {
      if (!this.validateMobileConfiguration()) {
        return;
      }
    } else {
      switch (this.state.activeStep) {
        case this.STEPS.pose:
          if (!this.state.selectedPose[poseIndex]) {
            Swal.fire(`Please select Pose ${poseIndex + 1}`);
            return false;
          }
          break;
        case this.STEPS.background:
          if (
            !this.shootIsOutdoorsBackground() &&
            !this.state.selectedBackground[poseIndex]
          ) {
            Swal.fire(`Please select Background ${poseIndex + 1}`);
            return false;
          }
          break;
        case this.STEPS.personalization:
          if (
            this.productRequiresPersonalization() &&
            !this.getPersonalizationValue()
          ) {
            Swal.fire('Please fill in a personalization');
            return false;
          }
          break;
        case this.STEPS.requiredAttributes:
          if (
            this.productRequiresAdditionalAttributes() &&
            !this.containsValuesForAllAdditionalAttributes()
          ) {
            Swal.fire('Please fill in all fields');
            return false;
          }
          break;
        case this.STEPS.sms:
          if (!this.props.smsPhone || this.props.smsPhone.trim().length === 0) {
            Swal.fire('Please fill in a phone number');
            return false;
          }
          break;
      }
    }

    if (this.canBeAddedToCart()) {
      if (shootProduct.image_type === 'portrait_image') {
        const configuration = this.props.cloneObject(this.state.configuration);

        let maxCount;

        if (this.shootIsPreShoot()) {
          maxCount = shootProduct.background_options_count;
        } else {
          maxCount = shootProduct.poses_count;
        }

        for (let poseIndex = 0; poseIndex < maxCount; poseIndex++) {
          const innerConfig = configuration[poseIndex];

          if (!this.shootIsPreShoot()) {
            // console.log('STATE');
            // console.log(this.state);
            const { isGeskusPostEvent } = this.state;
            const selectedPose = this.state.selectedPose[poseIndex];
            innerConfig.pose = selectedPose;

            if (isGeskusPostEvent) {
              innerConfig.poseIds = [0];
            } else {
              innerConfig.poseIds = [selectedPose.id];
            }
          }

          // TODO: add attributes here

          innerConfig.personalizationValue = this.getPersonalizationValue();
        }

        this.setState({ configuration }, () => {
          this.goToSmsFormOraddConfiguredBnlItemToCart();
        });
      } else if (
        shootProduct.image_type === 'group_image' ||
        shootProduct.image_type === 'no_image'
      ) {
        const { personalizationFormValue } = this.state;
        this.props.addConfiguredBnlItemToCart(
          this.state.configuration,
          null,
          personalizationFormValue,
        );
      }
    }
  }

  processGroupImageConfiguration() {
    const shootProduct = this.props.selectedShootProduct;
    const configuration = this.props.cloneObject(this.state.configuration);

    for (let poseIndex = 0; poseIndex < shootProduct.poses_count; poseIndex++) {
      const innerConfig = (configuration[poseIndex] = {});
      const selectedPose = this.state.selectedPose[poseIndex];

      innerConfig.backgroundCodes = [];
      innerConfig.backgroundPrices = [];
      innerConfig.pose = selectedPose;
      innerConfig.poseIds = [selectedPose.id];
      innerConfig.personalizationValue = this.getPersonalizationValue();
    }

    this.setState({ configuration });
  }

  submitSmsPhone(callback) {
    this.props.submitSmsPhone(callback);
  }

  renderSubheader(mobile = false) {
    const subheaders = [];
    const product = this.props.selectedShootProduct;
    let onClickHandler = this.goBack;

    if (mobile) {
      if (this.state.mobileConfiguring) {
        onClickHandler = this.goBackMobile;
      } else {
        onClickHandler = this.props.updateUnmountedByBackButton;
      }
    }

    subheaders.push(
      <div
        className="bnl-back-button cursor-pointer"
        key="bnl-back-button--float"
        onClick={onClickHandler}
      >
        {'<  Back'}
      </div>,
    );

    return subheaders;
  }

  renderInstruction() {
    const number = this.state.poseIndex + 1;

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        return `Select Pose ${number}`;
      case this.STEPS.background:
        return `Select Background ${number}`;
      case this.STEPS.personalization:
        return 'Personalization';
      case this.STEPS.sms:
        return 'Enter Your Phone Number';
      case this.STEPS.none:
        if (this.props.selectedShootProduct.is_retouching) return;
        return 'Done';
    }
  }

  renderActiveStep() {
    const { isGeskusPostEvent } = this.state;
    const {
      additionalData: { isGeskus },
      shoot,
    } = this.props;

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        return (
          <BnlOrderProductConfigurationPoses
            galleryImages={this.props.galleryImages}
            selectMobilePose={this.selectMobilePose}
            selectPose={this.selectPose}
            selectedPose={this.state.selectedPose}
            selectedShootProduct={this.props.selectedShootProduct}
            FILTER={this.props.BNL_FILTER}
          />
        );
      case this.STEPS.background:
        return (
          <BnlOrderProductConfigurationBackgrounds
            configuration={this.state.configuration[this.state.poseIndex]}
            displayPrice={this.props.displayPrice}
            isGeskusPostEvent={isGeskusPostEvent}
            formattedBackgroundOptions={this.props.formattedBackgroundOptions}
            selectBackground={this.selectBackground}
            selectMobileBackground={this.selectMobileBackground}
            selectedPose={this.state.selectedPose[this.state.poseIndex]}
            selectedShootProduct={this.props.selectedShootProduct}
            shootIsOutdoorsBackground={this.shootIsOutdoorsBackground}
          />
        );
      case this.STEPS.personalization:
        // TODO: add additional studios to V2 personalization as they come online
        if (isGeskus && shoot.personalizationProduct) {
          return (
            <PersonalizationConfigurationV2
              handleOnChange={this.handleOnChange}
              personalizationProduct={shoot.personalizationProduct}
              personalizationFormValue={this.state.personalizationFormValue}
              // getPersonalizationValue={this.getPersonalizationValue}
              personalizationAlreadyExists={
                this.state.personalizationAlreadyExists
              }
              personalizationType={
                this.props.selectedShootProduct.personalization_type
              }
              processConfiguration={this.processConfiguration}
              requirement={
                this.props.selectedShootProduct.personalization_status
              }
              selectedShootProduct={this.props.selectedShootProduct}
            />
          );
        }

        return (
          <BnlOrderProductConfigurationPersonalization
            handleOnChange={this.handleOnChange}
            getPersonalizationValue={this.getPersonalizationValue}
            personalizationType={
              this.props.selectedShootProduct.personalization_type
            }
            processConfiguration={this.processConfiguration}
            requirement={this.props.selectedShootProduct.personalization_status}
          />
        );
      case this.STEPS.sms:
        return (
          <BnlOrderSmsForm
            errorMessage={this.props.errorMessage}
            handleSmsInputChange={this.props.handleSmsInputChange}
            smsPhone={this.props.smsPhone}
          />
        );
      case this.STEPS.requiredAttributes:
        return (
          <BnlOrderProductConfigurationRequiredAttributes
            handleOnChange={this.handleOnChange}
            getAdditionalAttributeValue={this.getAdditionalAttributeValue}
            {...this.props}
          />
        );
        return (
          <BnlOrderSmsForm
            errorMessage={this.props.errorMessage}
            handleSmsInputChange={this.props.handleSmsInputChange}
            smsPhone={this.props.smsPhone}
          />
        );
      case this.STEPS.none:
        let promptRetouching = false;
        if (this.props.bnlPreShoot.preShoot) {
          promptRetouching =
            this.props.cart.bnlPromptRetouching[
              this.props.bnlPreShoot.selectedStudent.id
            ];
        }

        return (
          <BnlOrderProductConfigurationNone
            selectedShootProduct={this.props.selectedShootProduct}
            promptRetouching={promptRetouching}
          />
        );
    }
  }

  onLastStep() {
    const product = this.props.selectedShootProduct;

    if (product.image_type === 'portrait_image') {
      // product requires sms
      if (!product.sms_required) {
        // and requires personalization
        if (this.productRequiresPersonalization()) {
          return this.state.activeStep === this.STEPS.personalization;
          // and does not require personalization
        }
        return this.state.activeStep === this.STEPS.background;

        // product requires sms
      }
      // app already acquired sms phone
      if (this.props.order.smsAcquired) {
        return this.state.activeStep === this.STEPS.none;
      }
      return this.state.activeStep === this.STEPS.sms;
    }
    if (product.image_type === 'group_image') {
      return this.selectedAllPoses();
    }
  }

  onMobileLastStep() {
    const { personalizationFormValue } = this.state;
    const product = this.props.selectedShootProduct;

    if (this.state.activeStep === this.STEPS.none) {
      return true;
    }
    if (product.image_type === 'portrait_image') {
      if (
        (this.selectedAllPoses() && this.selectedAllBackgrounds()) ||
        (this.shootIsPreShoot() && this.selectedAllBackgrounds()) ||
        (this.shootIsOutdoorsBackground() && this.selectedAllPoses())
      ) {
        if (
          this.productRequiresPersonalization() &&
          this.productRequiresSms()
        ) {
          return !!this.getPersonalizationValue() && !!this.props.smsPhone;
        }
        if (this.productRequiresPersonalization()) {
          return !!this.getPersonalizationValue();
        }
        if (this.productRequiresSms()) {
          return !!this.props.smsPhone;
        }
        return true;
      }
      return false;
    }

    if (product.image_type === 'group_image') {
      if (this.shootIsPreShoot()) return true;
      return this.selectedAllPoses();
    }

    if (
      product.is_personalization &&
      personalizationFormValue &&
      personalizationFormValue !== ''
    ) {
      return true;
    }
  }

  addRetouching(product = null) {
    this.addConfiguredItemToCart(product);
    this.props.setBnlPromptRetouching(false, true);
  }

  addInterstitialProduct(product = null) {
    const { studentId, updateInterstitialPromptedForCurrentOrder } = this.props;

    this.addConfiguredItemToCart(product);
    updateInterstitialPromptedForCurrentOrder(studentId);
  }

  renderRetouchingPromptButtons(className, product, index, arrLength) {
    // TODO: adjust this to add additional retouching

    let productName = 'Retouching';

    if (product && product.name) {
      productName = product.name;
    }

    const retouchButtonStyle = {
      marginBottom: '40px',
    };

    const noThanksButtonStyle = {
      marginBottom: '40px',
    };

    return (
      <div>
        <div
          className={`bnl-product-config__button ${className} button`}
          onClick={() => this.addRetouching(product)}
          style={retouchButtonStyle}
        >
          Add {productName}
        </div>
        {}
        {index === arrLength - 1 && (
          <div
            className="bnl-product-config__button--cancel button bg--dark-grey"
            style={noThanksButtonStyle}
            onClick={() =>
              this.props.setBnlPromptRetouching(false, false, true)
            }
          >
            No Thank You
          </div>
        )}
      </div>
    );
  }

  renderInterstitialProductButtons(className, product) {
    const { studentId, setInterstitialPromptedForCurrentOrder } = this.props;

    let productName = 'Suggested Product';

    if (product && product.name) {
      productName = product.name;
    }

    const addToCartButtonStyle = {
      marginBottom: '40px',
    };

    const noThanksButtonStyle = {
      marginBottom: '40px',
    };

    return (
      <div>
        <div
          id="addInterstitialProductButton"
          className={`bnl-product-config__button ${className} button`}
          onClick={() => this.addInterstitialProduct(product)}
          style={addToCartButtonStyle}
        >
          Add {productName}
        </div>
        <div
          id="declineInterstitialProductButton"
          className="bnl-product-config__button--cancel button bg--dark-grey"
          style={noThanksButtonStyle}
          onClick={() => {
            setInterstitialPromptedForCurrentOrder();
          }}
        >
          No Thank You
        </div>
      </div>
    );
  }

  renderDesktopButton(product = null, index = null, arrLength = null) {
    let callback;
    let onClickHandler = this.processConfiguration.bind(this, false);
    const className = this.canBeAddedToCart() ? '' : 'disabled--bg-grey';

    if (this.selectedProductIsSuperCd()) {
      // console.log('case 1');
      if (this.props.order.smsAcquired) {
        onClickHandler = this.configureSuperCd;
      } else {
        onClickHandler = this.submitSmsPhone.bind(this, this.configureSuperCd);
      }
    } else if (this.state.activeStep === this.STEPS.sms) {
      // console.log('case 2');
      onClickHandler = this.submitSmsPhone.bind(
        this,
        this.addConfiguredItemToCart,
      );
    } else if (this.props.configuringIncludedProducts()) {
      // console.log('case 3');
      onClickHandler = this.storeBnlIncludedProductConfiguration;
    }

    // TODO: revisit
    let studentId;

    if (this.props.bnlPreShoot.preShoot) {
      studentId = this.props.bnlPreShoot.selectedStudent.id;
    } else {
      studentId = 'postShoot';
    }

    if (
      this.props.selectedShootProduct.is_retouching // TODO: confirm this will work in all scenarios
      // this.props.selectedShootProduct.is_retouching &&
      // this.props.cart.bnlPromptRetouching[studentId]
    ) {
      return this.renderRetouchingPromptButtons(
        className,
        product,
        index,
        arrLength,
      );
    }

    if (this.productIsInterstitial()) {
      return this.renderInterstitialProductButtons(className, product);
    }

    return (
      <div
        className={`bnl-product-config__button ${className} button`}
        onClick={onClickHandler}
      >
        Done, Add To Cart
      </div>
    );
  }

  renderMobileButton(product = null, index = null, arrLength = null) {
    let callback;
    let onClickHandler = this.processConfiguration.bind(this, true);
    const className = this.onMobileLastStep() ? '' : 'disabled--bg-grey';

    if (this.selectedProductIsSuperCd() && !this.props.order.smsAcquired) {
      onClickHandler = this.submitSmsPhone.bind(this, this.configureSuperCd);
    } else if (this.state.activeStep === this.STEPS.sms) {
      onClickHandler = this.submitSmsPhone.bind(
        this,
        this.addConfiguredItemToCart,
      );
    } else if (this.props.configuringIncludedProducts()) {
      onClickHandler = this.storeBnlIncludedProductConfiguration;
    }

    // TODO
    // revisit
    let studentId;

    if (this.props.bnlPreShoot.preShoot) {
      studentId = this.props.bnlPreShoot.selectedStudent.id;
    } else {
      studentId = 'postShoot';
    }

    if (this.props.selectedShootProduct.is_retouching) {
      return this.renderRetouchingPromptButtons(
        className,
        product,
        index,
        arrLength,
      );
    }

    if (this.productIsInterstitial()) {
      return this.renderInterstitialProductButtons(className, product);
    }

    return (
      <div
        className={`bnl-product-config__button ${className} button`}
        onClick={onClickHandler}
      >
        Done, Add To Cart
      </div>
    );
  }

  getDesktopSelectedPose(poseIndex, maxCount) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    const captionText = maxCount === 1 ? 'Pose' : `Pose ${poseIndex + 1}`;

    if (!this.shootIsPreShoot()) {
      const imageType = this.props.selectedShootProduct.image_type
        .split('_')
        .join('-');
      const outdoor = this.shootIsOutdoorsBackground() ? '--outdoor' : '';

      // let className = 'bnl-product-config__selected-pose';
      // className += `${className}--${imageType}${outdoor}`;

      const className = 'bnl-product-config__selected-pose';
      // const className = `bnl-product-config__selected-pose--${imageType}${outdoor}`;

      let imgClassName = `bnl-product-config__selected-pose__image ${imageType}`;

      console.log('getDesktopSelectedPose', imageType);
      console.log('getDesktopSelectedPose -- image class name', imgClassName);

      // Handle Geskus Post-Event
      // TODO: revisit this
      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        return (
          <div className={className}>
            <img className={imgClassName} src={studentPreviewImageUrl} />
          </div>
        );
      }

      if (this.state.selectedPose[poseIndex]) {
        return (
          <div className={className}>
            <img
              className={imgClassName}
              src={this.state.selectedPose[poseIndex].url}
            />
          </div>
        );
      }

      return (
        <div className={className}>
          <img className={imgClassName} src={DUMMY_IMAGE_URL} />
          <div className="bnl-product-config__selected-background__desc">
            {captionText}
          </div>
        </div>
      );
    }
  }

  getMobileSelectedPose(poseIndex, maxCount) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    const captionText = maxCount === 1 ? 'Pose' : `Pose ${poseIndex + 1}`;

    if (!this.shootIsPreShoot()) {
      const imageType = this.props.selectedShootProduct.image_type
        .split('_')
        .join('-');
      const outdoor = this.shootIsOutdoorsBackground() ? '--outdoor' : '';
      let className = 'bnl-product-config__selected-option-container';
      className += ` ${className}--${imageType}${outdoor} cursor-pointer`;

      // Handle Geskus Post-Event
      // TODO: revisit this
      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        return (
          <div className={className}>
            <img
              className="bnl-product-config__selected-pose__image"
              src={studentPreviewImageUrl}
            />
          </div>
        );
      }

      if (this.state.selectedPose[poseIndex]) {
        return (
          <div
            className={className}
            onClick={() => this.goToMobilePoseStep(poseIndex)}
          >
            <img
              className="bnl-product-config__selected-pose__image"
              src={this.state.selectedPose[poseIndex].url}
            />
          </div>
        );
      }

      return (
        <div
          className={className}
          onClick={() => this.goToMobilePoseStep(poseIndex)}
        >
          <img
            className="bnl-product-config__selected-pose__image"
            src={DUMMY_IMAGE_URL}
          />
          <div className="bnl-product-config__selected-pose__desc">
            {`Pose ${poseIndex + 1}`}
          </div>
        </div>
      );
    }
  }

  getDesktopSelectedBackground(poseIndex, maxCount) {
    const className = 'bnl-product-config__selected-background';
    const shootClassName = this.shootIsPreShoot()
      ? 'bnl-product-config__selected-background--pre-shoot'
      : 'bnl-product-config__selected-background--post-shoot';
    const captionText =
      maxCount === 1 ? 'Background' : `Background ${poseIndex + 1}`;

    if (
      this.props.selectedShootProduct.image_type !== 'group_image' &&
      !this.shootIsOutdoorsBackground()
    ) {
      if (this.state.selectedBackground[poseIndex]) {
        return (
          <div className={`${className} ${shootClassName}`}>
            <img
              className="bnl-product-config__selected-background__image"
              src={this.state.selectedBackground[poseIndex].image_thumbnail}
            />
          </div>
        );
      }

      return (
        <div className={`${className} ${shootClassName}`}>
          <div className="bnl-product-config__selected-background__image" />
          <div className="bnl-product-config__selected-background__desc">
            {captionText}
          </div>
        </div>
      );
    }
  }

  getMobileSelectedBackground(poseIndex) {
    if (
      this.props.selectedShootProduct.image_type !== 'group_image' &&
      !this.shootIsOutdoorsBackground()
    ) {
      if (this.state.selectedBackground[poseIndex]) {
        return (
          <div
            className="bnl-product-config__selected-option-container cursor-pointer"
            onClick={() => this.goToMobileBackgroundStep(poseIndex)}
          >
            <img
              className="bnl-product-config__selected-background__image"
              src={this.state.selectedBackground[poseIndex].image_thumbnail}
            />
          </div>
        );
      }

      return (
        <div
          className="bnl-product-config__selected-option-container cursor-pointer"
          onClick={() => this.goToMobileBackgroundStep(poseIndex)}
        >
          <div className="bnl-product-config__selected-background__image" />
          <div className="bnl-product-config__selected-background__desc">
            {`Background ${poseIndex + 1}`}
          </div>
        </div>
      );
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  renderPersonalizationForm(mobile = false) {
    const {
      additionalData: { isGeskus },
      shoot,
    } = this.props;

    if (
      isGeskus &&
      shoot.personalizationProduct &&
      (this.productRequiresPersonalization() ||
        this.productOffersPersonalization())
    ) {
      return (
        <PersonalizationConfigurationV2
          handleOnChange={this.handleOnChange}
          personalizationProduct={shoot.personalizationProduct}
          personalizationFormValue={this.state.personalizationFormValue}
          // getPersonalizationValue={this.getPersonalizationValue}
          personalizationAlreadyExists={this.state.personalizationAlreadyExists}
          personalizationType={
            this.props.selectedShootProduct.personalization_type
          }
          processConfiguration={this.processConfiguration}
          requirement={this.props.selectedShootProduct.personalization_status}
          selectedShootProduct={this.props.selectedShootProduct}
        />
      );
    }

    if (
      this.productRequiresPersonalization() ||
      this.productOffersPersonalization()
    ) {
      return (
        <BnlOrderProductConfigurationPersonalization
          handleOnChange={this.handleOnChange}
          getPersonalizationValue={this.getPersonalizationValue}
          mobile={mobile}
          personalizationType={
            this.props.selectedShootProduct.personalization_type
          }
          processConfiguration={this.processConfiguration}
          requirement={this.props.selectedShootProduct.personalization_status}
        />
      );
    }

    return null;
  }

  renderRequiredAttributesForm(mobile = false) {
    if (this.productRequiresAdditionalAttributes()) {
      return (
        <BnlOrderProductConfigurationRequiredAttributes
          mobile={mobile}
          handleOnChange={this.handleOnChange}
          getAdditionalAttributeValue={this.getAdditionalAttributeValue}
          {...this.props}
        />
      );
    }
  }

  renderSmsForm() {
    if (
      this.props.selectedShootProduct.sms_required &&
      !this.props.order.smsAcquired
    ) {
      return (
        <BnlOrderSmsForm
          errorMessage={this.props.errorMessage}
          handleSmsInputChange={this.props.handleSmsInputChange}
          mobileConfiguring
          smsPhone={this.props.smsPhone}
        />
      );
    }
  }

  renderDesktopSelectedPoseAndBackground() {
    const product = this.props.selectedShootProduct;
    const className = 'bnl-product-config__selected-container';
    let shootClassName;
    let galleryObj;
    const groupImages = [];
    const companyPricingModelIdFromGallery = this.props?.gallery?.cpmId;
    const {
      additionalData: { selectedStudent },
    } = this.props;

    // look for an associated group image
    if (companyPricingModelIdFromGallery && this.props?.gallery?.galleries) {
      galleryObj =
        this.props.gallery.galleries[companyPricingModelIdFromGallery];

      if (galleryObj) {
        const keys = Object.keys(galleryObj);

        // favor selectedStudent from props when available
        if (
          selectedStudent?.id &&
          keys.includes(selectedStudent.id.toString())
        ) {
          galleryObj[
            selectedStudent.id.toString()
          ].associatedGroupImages.forEach((groupImage) => {
            groupImages.push(groupImage);
          });
        } else if (
          keys.length > 0 &&
          galleryObj[keys[0]].associatedGroupImages
        ) {
          galleryObj[keys[0]].associatedGroupImages.forEach((groupImage) => {
            groupImages.push(groupImage);
          });
        }
      }
    }

    // console.log('renderDesktopSelectedPoseAndBackground', product);
    // console.log('----');
    // console.log(this.props);
    // console.log('++++');
    // console.log(this.props.gallery.galleries[companyPricingModelIdFromGallery]);
    // console.log(
    //   'companyPricingModelIdFromGallery',
    //   companyPricingModelIdFromGallery,
    // );
    // console.log('galleryObj', galleryObj);
    // console.log(groupImages);

    if (this.shootIsPreShoot()) {
      if (!this.shootIsOutdoorsBackground()) {
        shootClassName = `${className}--one-box`;
      }
    } else if (this.shootIsOutdoorsBackground()) {
      shootClassName = `${className}--one-box`;
    } else if (product?.background_options_count === 0) {
      shootClassName = `${className}--one-box`;
    } else {
      shootClassName = `${className}--two-boxes`;
    }

    let maxCount;

    if (this.props.bnlPreShoot.preShoot) {
      maxCount = product.background_options_count;
    } else {
      maxCount = product.poses_count;
    }

    if (product.name !== 'Super CD + Cloud Download') {
      const elements = [];

      // If there is a group image associated with the gallery and
      // the current product is a group image, show the group image
      if (groupImages.length > 0 && product.image_type === 'group_image') {
        elements.push(
          <div className="group-image-config-preview">
            <img src={groupImages[0].url} />
          </div>,
        );
      } else {
        for (let index = 0; index < maxCount; index++) {
          elements.push(
            <div
              className={`${className} ${shootClassName} clearfix`}
              key={`pose-and-bg-desktop-container-${index}`}
            >
              {this.getDesktopSelectedPose(index, maxCount)}
              {this.getDesktopSelectedBackground(index, maxCount)}
            </div>,
          );
        }
      }

      return elements;
    }
  }

  renderMobileSelectedBackground(poseIndex, bgClassName, bgShootClassName) {
    if (this.props.selectedShootProduct.requires_configuration) {
      return (
        <div className={`${bgClassName} ${bgShootClassName}`}>
          {this.getMobileSelectedBackground(poseIndex)}
        </div>
      );
    }
  }

  renderMobileSelectedPoseAndBackground() {
    const product = this.props.selectedShootProduct;
    const imageType = product.image_type.split('_').join('-');
    const {
      additionalData: { selectedStudent },
    } = this.props;

    const bgShootClassName = this.shootIsPreShoot()
      ? 'bnl-product-config__selected-background--pre-shoot'
      : 'bnl-product-config__selected-background--post-shoot';
    let containerClassName;
    let outdoorClassName = '';

    let galleryObj;
    const groupImages = [];
    const companyPricingModelIdFromGallery = this.props?.gallery?.cpmId;

    // look for an associated group image
    if (companyPricingModelIdFromGallery && this.props?.gallery?.galleries) {
      galleryObj =
        this.props.gallery.galleries[companyPricingModelIdFromGallery];

      if (galleryObj) {
        const keys = Object.keys(galleryObj);

        // console.log(galleryObj);
        // console.log('gallery keys', keys);

        // favor selectedStudent from props when available
        if (
          selectedStudent?.id &&
          keys.includes(selectedStudent.id.toString())
        ) {
          galleryObj[
            selectedStudent.id.toString()
          ].associatedGroupImages.forEach((groupImage) => {
            groupImages.push(groupImage);
          });
        } else if (
          keys.length > 0 &&
          galleryObj[keys[0]].associatedGroupImages
        ) {
          galleryObj[keys[0]].associatedGroupImages.forEach((groupImage) => {
            groupImages.push(groupImage);
          });
        }
      }
    }

    // console.log('%cGROUP IMAGES', 'color: red;');
    // console.log(groupImages);
    // console.log('++++');

    if (this.shootIsPreShoot()) {
      if (!this.shootIsOutdoorsBackground()) {
        containerClassName = 'bnl-product-config__selected-container--one-box';
      }
    } else if (this.shootIsOutdoorsBackground()) {
      containerClassName = 'bnl-product-config__selected-container--one-box';
      outdoorClassName = '--outdoor';
    } else {
      containerClassName = 'bnl-product-config__selected-container--two-boxes';
    }

    let poseClassName = 'bnl-product-config__selected-pose';
    let bgClassName = 'bnl-product-config__selected-background';
    poseClassName += ` ${poseClassName}--${imageType}${outdoorClassName} cursor-pointer`;
    bgClassName += ` ${bgClassName}--${imageType} cursor-pointer`;

    if (product.name !== 'Super CD + Cloud Download') {
      const elements = [];

      let maxCount;

      if (this.props.bnlPreShoot.preShoot) {
        maxCount = product.background_options_count;
      } else {
        maxCount = product.poses_count;
      }

      // If there is a group image associated with the gallery and
      // the current product is a group image, show the group image
      if (groupImages.length > 0 && product.image_type === 'group_image') {
        elements.push(
          <div className="group-image-config-preview">
            <img src={groupImages[0].url} />
          </div>,
        );
      } else {
        for (let index = 0; index < maxCount; index++) {
          elements.push(
            <div
              className={containerClassName}
              key={`pose-and-bg-mobile-container-${index}`}
            >
              <div className="clearfix">
                <div className={`${poseClassName} ${outdoorClassName}`}>
                  {this.getMobileSelectedPose(index, maxCount)}
                </div>
                {this.renderMobileSelectedBackground(
                  index,
                  bgClassName,
                  bgShootClassName,
                )}
              </div>
            </div>,
          );
        }
      }

      return elements;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedShootProduct.id !== this.props.selectedShootProduct.id
    ) {
      this.setState({
        activeStep: this.getInitialActiveStep(),
        configuration: this.getInitialConfiguration(),
      });
    }
  }

  render() {
    const product = this.props.selectedShootProduct;
    const activeStepComponent = this.renderActiveStep();
    const { additionalData } = this.props;

    return (
      <div className="bnl-product-config-container">
        <BnlOrderProductDesktopConfiguration
          activeStepComponent={activeStepComponent}
          addConfiguredBnlItemToCart={this.props.addConfiguredBnlItemToCart}
          additionalData={additionalData}
          cloneObject={this.props.cloneObject}
          displayPrice={this.props.displayPrice}
          errorMessage={this.props.errorMessage}
          formattedBackgroundOptions={this.props.formattedBackgroundOptions}
          handleSmsInputChange={this.props.handleSmsInputChange}
          incentiveItemConfiguration={this.props.incentiveItemConfiguration}
          personalizationApplied={this.props.personalizationApplied}
          renderActiveStep={this.renderActiveStep}
          renderDesktopButton={this.renderDesktopButton}
          renderInstruction={this.renderInstruction}
          renderDesktopSelectedPoseAndBackground={
            this.renderDesktopSelectedPoseAndBackground
          }
          renderSubheader={this.renderSubheader}
          selectedShootProduct={this.props.selectedShootProduct}
          setStudent={this.props.setStudent}
          shoot={this.props.shoot}
          smsPhone={this.props.smsPhone}
          submitSmsPhone={this.props.submitSmsPhone}
        />
        <BnlOrderProductMobileConfiguration
          activeStepComponent={activeStepComponent}
          addConfiguredBnlItemToCart={this.props.addConfiguredBnlItemToCart}
          additionalData={additionalData}
          cloneObject={this.props.cloneObject}
          mobileConfiguring={this.state.mobileConfiguring}
          configuring={this.state.configuring}
          displayPrice={this.props.displayPrice}
          errorMessage={this.props.errorMessage}
          formattedBackgroundOptions={this.props.formattedBackgroundOptions}
          goToBackgroundStep={this.goToBackgroundStep}
          handleSmsInputChange={this.props.handleSmsInputChange}
          incentiveItemConfiguration={this.props.incentiveItemConfiguration}
          personalizationApplied={this.props.personalizationApplied}
          renderActiveStep={this.renderActiveStep}
          renderMobileButton={this.renderMobileButton}
          renderInstruction={this.renderInstruction}
          renderMobileSelectedPoseAndBackground={
            this.renderMobileSelectedPoseAndBackground
          }
          renderPersonalizationForm={this.renderPersonalizationForm}
          renderRequiredAttributesForm={this.renderRequiredAttributesForm}
          renderSmsForm={this.renderSmsForm}
          renderSubheader={this.renderSubheader}
          selectedShootProduct={this.props.selectedShootProduct}
          setStudent={this.props.setStudent}
          shoot={this.props.shoot}
          smsPhone={this.props.smsPhone}
          submitSmsPhone={this.props.submitSmsPhone}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    gallery: state.gallery,
    order: state.order,
    parent: state.parent,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRetouchingPromptedForCurrentOrder,
      updateInterstitialPromptedForCurrentOrder,
      updateBnlPromptRetouching,
      updateOrderActiveComponent,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BnlOrderProductConfigurationContainer);
