import React from 'react';

const BannerMessage = (props) => {
  const bannerMessage = props.shoot.banner_message;
  const priceListBannerMessage = props.shoot.price_list_banner_message;
  const showPriceListBannerMessage = props.shoot.show_price_list_banner_message;

  const wrapperStyle = {
    paddingTop: '26px',
    paddingBottom: '24px',
  };

  const alertStyle = {
    marginTop: '0px',
    border: '3px solid #faf2cc',
    padding: '15px',
    borderRadius: 'none',
    color: '#8a6d3b',
    backgroundColor: '#fcf8e3',
    marginLeft: '0px',
    marginRight: '0px',
    textAlign: 'center',
  };

  if (
    priceListBannerMessage &&
    priceListBannerMessage.length > 0 &&
    showPriceListBannerMessage
  ) {
    return (
      <div className="row">
        <div style={wrapperStyle}>
          <div style={alertStyle}>{priceListBannerMessage}</div>
        </div>
      </div>
    );
  }

  if (bannerMessage && bannerMessage.length > 0) {
    return (
      <div className="row">
        <div style={wrapperStyle}>
          <div style={alertStyle}>{bannerMessage}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default BannerMessage;
