import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title, Input } from '../../../../styles/StudentKeyLookupStyles';

const VenueSearchInput = ({ loading, searchVenueName, venueNameForSearch }) => {
  return (
    <Card>
      <Title>
        <strong>Step 1</strong>
        <br />
        Enter your student's school
      </Title>
      <Input
        type="text"
        placeholder="school name"
        value={venueNameForSearch}
        onChange={(e) => searchVenueName(e.target.value)}
        disabled={loading}
      />
    </Card>
  );
};

export default VenueSearchInput;

VenueSearchInput.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchVenueName: PropTypes.func.isRequired,
  venueNameForSearch: PropTypes.string.isRequired,
};
