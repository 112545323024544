import {
  GET_BNL_KEY_LOOKUP,
  SET_BNL_PRE_SHOOT_STUDENT,
  SET_BNL_PRE_SHOOT_STUDENTS_DETAILS,
  SET_BNL_PRE_SHOOT,
  SET_ENTERED_KEY_IS_PREPAY,
} from '../actions/action_bnl_pre_shoot';

const INITIAL_STATE = {
  imageUrls: [],
  galleryPassword: '',
  keySubmittedByUser: null,
  preShoot: null,
  enteredKeyIsPrepay: false,
  selectedStudent: null,
  shootKey: '',
  shootName: '',
  studentsDetails: {},
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case GET_BNL_KEY_LOOKUP:
      if (
        action.payload &&
        action.payload.data &&
        !action.payload.data.errors
      ) {
        return {
          ...state,
          imageUrls: action.payload.data.image_urls,
          galleryPassword: action.payload.data.gallery_password,
          keySubmittedByUser: action.payload.data.key_submitted_by_user,
          shootKey: action.payload.data.shoot_key,
          shootId: action.payload.data.shoot_id,
          shootName: action.payload.data.shoot_name,
        };
      }
      return state;

    case SET_BNL_PRE_SHOOT:
      return {
        ...state,
        preShoot: state.preShoot ? state.preShoot : action.payload,
      };
    case SET_ENTERED_KEY_IS_PREPAY:
      return {
        ...state,
        enteredKeyIsPrepay: action.payload,
      };
    case SET_BNL_PRE_SHOOT_STUDENT:
      return { ...state, selectedStudent: action.payload };
    case SET_BNL_PRE_SHOOT_STUDENTS_DETAILS:
      return {
        ...state,
        studentsDetails: {
          ...state.studentsDetails,
          [action.payload.studentId]: action.payload.details,
        },
      };
    default:
      return state;
  }
}
