export const isParentProduct = (product) => {
  if (product?.product_type === 'parent_product') return true;

  return false;
};

export const doesCategoryContainsMetallicProducts = (categoryProducts) => {
  if (!Array.isArray(categoryProducts)) {
    return false;
  }

  return categoryProducts.some((product) => product.is_metallic === true);
};

export const isMultiConfigProduct = (product) => {
  if (product?.is_multi_config) return true;

  return false;
};
