import React, { Component } from 'react';
import ReactLoading from 'react-loading';

class Spinner extends Component {
  render() {
    const loading = this.props.loading ? 'loading' : 'hidden';

    return (
      <div className={loading}>
        <div className="spinner" />
      </div>
    );
  }
}

export function SpinnerSmall() {
  return <ReactLoading type="spin" color="black" height={20} width={20} />;
}

export default Spinner;
