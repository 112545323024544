import React, { Component } from 'react';

class ParentBnlAuthContainer extends Component {
  renderSteps() {
    return (
      <div className="bnl-auth__steps">
        <div className="bnl-auth__step-container">
          <div className="bnl-auth__step bnl-auth__step--1">
            <div className="bnl-auth__step__num">1</div>
            <div className="bnl-auth__step__header">Create Account</div>
            <div className="bnl-auth__step__subheader">
              Sign in if you already have one
            </div>
          </div>
        </div>
        <div className="bnl-auth__step-container">
          <div className="bnl-auth__step bnl-auth__step--2">
            <div className="bnl-auth__step__num">2</div>
            <div className="bnl-auth__step__header">Add</div>
            <div className="bnl-auth__step__subheader">
              Your students and galleries
            </div>
          </div>
        </div>
        <div className="bnl-auth__step-container">
          <div className="bnl-auth__step bnl-auth__step--3">
            <div className="bnl-auth__step__num">3</div>
            <div className="bnl-auth__step__header">Order</div>
            <div className="bnl-auth__step__subheader">
              In minutes, access your photos forever
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const className = this.props.className;

    return (
      <div className={`${className}-auth`}>
        <div className="bnl-auth__top clearfix">
          <div className="bnl-auth__img-container bnl-auth__img-container--desktop">
            <img
              className="bnl-auth__default-img"
              src="https://s3.amazonaws.com/pics-assets/landing/bnl_landing_default.png"
            />
          </div>
          <div className="bnl-auth__logo-container">
            <div className="bnl-auth__logo-inner-container">
              <div className={`auth__company-logo ${className}-logo`} />
              <h3 className={`${className}-header`}>
                Welcome to our brand new ordering system
              </h3>
              <div className="bnl-auth__subheader">
                Create a free account to get started
              </div>
              <div className="auth__buttons-container row">
                <div className="col-xs-6 col-md-6 col-lg-6">
                  <div
                    className={`${className}-auth__register button--round-border bg--bnl-light-blue col-xs-6 col-md-6 col-lg-6`}
                    onClick={() =>
                      this.props.history.push('/signup', { step: 1 })
                    }
                  >
                    Create Account
                  </div>
                </div>
                <div className="col-xs-6 col-md-6 col-lg-6 clearfix">
                  <div
                    className={`${className}-auth__login button--round-border bg--bnl-dark-grey col-xs-6 col-md-6 col-lg-6`}
                    onClick={() => this.props.setActiveComponent('login')}
                  >
                    Log In
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bnl-auth__img-container bnl-auth__img-container--mobile">
          <img
            className="bnl-auth__default-img"
            src="https://s3.amazonaws.com/pics-assets/landing/bnl_landing_default.png"
          />
        </div>
        {this.renderSteps()}
      </div>
    );
  }
}

export default ParentBnlAuthContainer;
