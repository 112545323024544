import React from 'react';
import styled from 'styled-components';

const ProductName = styled.h3`
  padding-bottom: 8px !important;
`;

const ProductHeader = ({ product }) => {
  return (
    <ProductName className="bnl-product-config__header">
      {product.name}
    </ProductName>
  );
};

export default ProductHeader;
