/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import BackLinkFromYPX from '../shared/BackLinkFromYPX.jsx';
import Background from './background';
import Personalization from './personalization';
import Pose from './pose';
import Product from './product';

const OrderRouter = () => (
  // const { match, history, location } = props;
  // const shoot = useSelector((state) => state.shoot);
  // const productId = match.params.id;
  // const matchingCategory = shoot.shootProducts.find((category) =>
  //   category.products.some((product) => product.id.toString() === productId),
  // );

  // useEffect(() => {
  //   if (matchingCategory) {
  //     const product = matchingCategory.products.find(
  //       (prod) => prod.id.toString() === productId,
  //     );
  //     if (
  //       isParentProduct(product) &&
  //       !isMultiConfigProduct(product) &&
  //       location.pathname === `/v3/products/${productId}`
  //     ) {
  //       history.push(`/v3/products/${productId}/product/0#slots`);
  //     }

  //     if (isParentProduct(product) && isMultiConfigProduct(product)) {
  //       history.push(`/v3/products-regular/${productId}`);
  //     }
  //   }
  // }, []);

  <div>
    <BackLinkFromYPX />
    <Route path="/v3/products/:id/background/:number" component={Background} />
    <Route path="/v3/products/:id/pose/:number" component={Pose} />
    <Route path="/v3/products/:id/product/:number" component={Product} />
    <Route
      path="/v3/products/:id/personalization/:number"
      component={Personalization}
    />
  </div>
);
export default withRouter(OrderRouter);
