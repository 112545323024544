import React, { Component } from 'react';
import { connect } from 'react-redux';

// React.js components
import BnlOrderProductConfigurationBackgroundsSelector from './bnl_order_product_configuration_backgrounds_selector';

class BnlOrderProductConfigurationBackgrounds extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderPose() {
    if (!this.props.bnlPreShoot.preShoot) {
      let poseStyle = {};

      if (this.props.selectedPose && this.props.selectedPose.url) {
        poseStyle = {
          backgroundImage: `url("${this.props.selectedPose.url}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };
      }

      return <div className="bnl-product-config__bg-pose" style={poseStyle} />;
    }
  }

  renderAllBackgroundOptions(mobile) {
    const options = [];
    const { configuration } = this.props;

    console.log('configuration', configuration);

    const that = this;
    this.props.shoot.backgroundOptions.forEach((backgroundImage) => {
      const bgId = backgroundImage.id;
      const option = this.props.formattedBackgroundOptions[bgId];
      const bgStyle = {
        backgroundImage: `url("${backgroundImage.image_thumbnail}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      let selected = '';
      let onClickHandler = this.props.selectBackground.bind(this, option);
      const bgClass = '';

      if (mobile) {
        onClickHandler = this.props.selectMobileBackground.bind(this, option);
      }

      if (
        configuration?.backgroundCodes &&
        configuration.backgroundCodes[0] === option.id
      ) {
        selected = 'bnl-product-config__bg--selected';
      }

      const bgName = backgroundImage.display_name || '';
      const bgPrice =
        backgroundImage.price > 0
          ? this.props.displayPrice(backgroundImage.price).split('.')[0]
          : '';

      options.push(
        <li
          key={`bg-${bgId}`}
          className="bnl-product-config__bg-container col-lg-2 cursor-pointer"
        >
          <div
            className={`bnl-product-config__bg ${selected}`}
            onClick={onClickHandler}
            style={bgStyle}
          >
            {this.renderPose()}
          </div>
        </li>
      );
    });

    const htmlElements = <ul className="row equal-height">{options}</ul>;
    const data = {
      all: {
        name: null,
        htmlElements,
      },
    };

    return (
      <BnlOrderProductConfigurationBackgroundsSelector
        containsBackgroundCategories={
          this.props.shoot.containsBackgroundCategories
        }
        data={data}
      />
    );
  }

  renderCategorizedBackgroundOptions(mobile) {
    const that = this;
    const product = this.props.selectedShootProduct;
    const data = {};

    this.props.shoot.backgroundOptions.forEach((backgroundOption) => {
      const filteredBackgroundImages = [];
      backgroundOption.background_images.forEach((backgroundImage) => {
        let unlocked = false;
        if (product.limit_group.length > 0) {
          product.limit_group.forEach((set) => {
            if (backgroundImage.limit_group.includes(set)) unlocked = true;
          });
        } else if (backgroundImage.lock_type === 'unlocked') {
          unlocked = true;
        } else if (backgroundImage.lock_type === 'unlockable') {
          backgroundImage.lock_group.forEach((set) => {
            if (that.props.cart.bnlUnlockedGroups.includes(set)) {
              unlocked = true;
            }
          });

          if (
            !unlocked &&
            that.props.selectedShootProduct.lock_type === 'unlocker'
          ) {
            backgroundImage.lock_group.forEach((set) => {
              if (that.props.selectedShootProduct.lock_group.includes(set)) {
                unlocked = true;
              }
            });
          }
        }

        if (unlocked) {
          const bgId = backgroundImage.id;
          const option = this.props.formattedBackgroundOptions[bgId];
          const bgStyle = {
            backgroundImage: `url("${backgroundImage.image_thumbnail}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          };
          const selected = '';
          let onClickHandler = this.props.selectBackground.bind(this, option);
          const bgClass = '';

          if (mobile) {
            onClickHandler = this.props.selectMobileBackground.bind(
              this,
              option
            );
          }

          // if (this.props.configuration.backgroundCodes[0] === option.id) {
          //   selected = 'bnl-product-config__bg--selected';
          // }

          const bgName = backgroundImage.display_name || '';

          let bgPrice =
            backgroundImage.price > 0
              ? this.props.displayPrice(backgroundImage.price).split('.')[0]
              : '';

          if (that.props.bnlPreShoot.preShoot) {
            const studentId = that.props.bnlPreShoot.selectedStudent.id;

            if (
              (that.props.cart.bnlBackgroundSelection[studentId] &&
                that.props.cart.bnlBackgroundSelection[studentId][
                  backgroundImage.price
                ]) ||
              that.props.cart.bnlFreeBackgrounds[studentId]
            ) {
              bgPrice = '';
            }
          }

          let bgClassName = '';

          if (
            option.display_name &&
            option.display_name.split(' ').length > 1
          ) {
            bgClassName = option.display_name
              .split(' ')
              .slice(1)
              .map((word) => word.toLowerCase())
              .join('_');
          }

          filteredBackgroundImages.push(
            <li
              key={`bg-${bgId}`}
              className="bnl-product-config__bg-container col-lg-2"
            >
              <div
                className={`bnl-product-config__bg bnl-product-config__bg--${bgClassName} ${selected} cursor-pointer`}
                onClick={onClickHandler}
                style={bgStyle}
              >
                {this.renderPose()}
              </div>
              <div className="bnl-bg-text text-center">
                {bgName} {bgPrice}
              </div>
            </li>
          );
        }
      });

      if (filteredBackgroundImages.length > 0) {
        const htmlElements = (
          <ul className="row equal-height">{filteredBackgroundImages}</ul>
        );

        data[backgroundOption.id] = {
          id: backgroundOption.id,
          order: backgroundOption.order,
          name: backgroundOption.name,
          htmlElements,
        };
      }
    });

    return (
      <BnlOrderProductConfigurationBackgroundsSelector
        containsBackgroundCategories={
          this.props.shoot.containsBackgroundCategories
        }
        data={data}
      />
    );
  }

  renderBackgroundOptions(mobile = false) {
    if (!this.props.shoot.containsBackgroundCategories) {
      return this.renderAllBackgroundOptions(mobile);
    }
    return this.renderCategorizedBackgroundOptions(mobile);
  }

  render() {
    return (
      <div>
        <ul className="bnl-product-config__bg--desktop">
          {this.renderBackgroundOptions()}
        </ul>
        <ul className="bnl-product-config__bg--mobile ">
          {this.renderBackgroundOptions(true)}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    shoot: state.shoot,
  };
}

export default connect(
  mapStateToProps,
  null
)(BnlOrderProductConfigurationBackgrounds);
