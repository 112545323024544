import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { logoutAndReset } from '../../../actions/action_auth';
import { termsHTML } from '../../../data/termsAndConditions.html';
import OverlayLoader from '../utils/OverlayLoader';

const TermsConditions = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = async () => {
    setIsLoading(true);
    try {
      await axios.post('/api/v2/parents/accept-tc');
      history.push('/dashboard');
    } catch (error) {
      console.error('Error accepting terms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(logoutAndReset());
  };

  return (
    <div>
      {isLoading && <OverlayLoader />}
      <h2>Terms and Conditions</h2>
      <p>
        Please read through and accept our Terms and Conditions before placing
        your order.
      </p>
      <div
        style={{
          overflowY: 'scroll',
          height: '400px',
          border: '1px solid black',
          margin: '24px 0',
          padding: '10px',
        }}
        dangerouslySetInnerHTML={{ __html: termsHTML }}
      ></div>
      <div className="text-center" style={{ marginTop: '24px' }}>
        <button onClick={handleAccept} className="btn btn-primary">
          Accept and Continue
        </button>
      </div>
      <div className="text-center" style={{ marginTop: '16px' }}>
        <a
          href="#"
          onClick={handleCancel}
          className="btn btn-link"
          style={{
            color: 'grey',
            fontSize: '14px',
            textDecoration: 'underline',
          }}
          id="cancel-button"
        >
          cancel and log out
        </a>
      </div>
    </div>
  );
});

export default TermsConditions;
