export const TURN_ON_NOTIFICATION = 'TURN_ON_NOTIFICATION';
export const TURN_OFF_NOTIFICATION = 'TURN_OFF_NOTIFICATION';

export function turnOnNotification(message) {
  return {
    type: TURN_ON_NOTIFICATION,
    payload: message,
  };
}

export function turnOffNotification() {
  return {
    type: TURN_OFF_NOTIFICATION,
    payload: '',
  };
}
