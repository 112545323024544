export const termsHTML = `
<div>
  <p class="c1">
    <span class="c2">
      Welcome to Portrait Pics, LLC (&ldquo;Pics&rdquo;). Please read these
      Terms of Use (&ldquo;Terms&rdquo;) carefully. They contain (a) the General
      Terms of Service (General Terms) that govern your use of and access to any
      and all of our websites, mobile sites, and mobile applications
      (collectively, our &ldquo;Sites and Apps&rdquo;), as well as our provision
      of products and services. Certain offline or online services, promotions,
      events and features may have additional terms and conditions specific to
      them as communicated at the point of purchase, in advertising or in
      person, and those additional terms and conditions are incorporated herein
      by reference. If there is a conflict between these Terms and the terms
      posted for or applicable to a specific portion of the Site or for any
      service offered on or through the Site or App, the latter terms shall
      control with respect to your use of that portion of the Site or App or the
      specific service.
    </span>
  </p>
  <h5 class="c11" id="h.duxkzavj7tqx">
    <span class="c15 c14 c6">I.General Terms of Service</span>
  </h5>
  <p class="c1">
    <span class="c2">
      By visiting any of our Sites and Apps that link to these Terms, you are
      signifying your assent to these Terms and our [link to: Privacy Policy],
      which is incorporated herein by reference. Any products ordered or
      services used through any of our Sites and Apps are also governed by these
      Terms. We may revise these Terms from time to time by posting a revised
      version. YOUR CONTINUED USE OF ANY OF THE SITES AND APPS AFTER WE POST ANY
      CHANGES WILL CONSTITUTE YOUR ACCEPTANCE OF SUCH CHANGES. IN ADDITION, BY
      ORDERING PRODUCTS OR USING SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ
      AND REVIEWED THESE TERMS IN THEIR ENTIRETY, YOU AGREE TO THESE TERMS AND
      THE PRIVACY POLICY AND THESE TERMS CONSTITUTE BINDING AND ENFORCEABLE
      OBLIGATIONS ON YOU.
    </span>
  </p>
  <p class="c1 c10">
    <span class="c2"></span>
  </p>
  <h5 class="c8" id="h.tfd9egujjmzg">
    <span class="c15 c14 c6">1. YOUR USE OF OUR SITES AND APPS</span>
  </h5>
  <p class="c1">
    <span class="c2">
      You may access and use our Sites and Apps solely for your personal,
      noncommercial use. Except as expressly authorized hereunder, our Sites and
      Apps may not be reproduced, duplicated, copied, sold, resold, visited,
      reverse-engineered or otherwise exploited for any commercial purpose
      without our prior written authorization. We reserve the right to alter or
      discontinue our Sites and Apps, in whole or in part, at any time in our
      sole discretion.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      Subject to and conditioned upon your compliance with these Terms, we grant
      to you a non-exclusive, non-transferable, limited right and license,
      without right of sublicense, to access and use our Sites and Apps,
      including any images, text, graphics, sounds, data, links and other
      materials incorporated into our Sites and Apps , solely as made available
      by us and solely for your own personal purposes. Except as expressly
      authorized by these Terms, you may not use, reproduce, distribute, modify,
      transmit or publicly display any portion of our Sites and Apps or create
      derivative works of any portion our Sites and Apps without our written
      consent. While using any of our Sites and Apps, you agree not to:
    </span>
  </p>
  <ul class="c3 lst-kix_4f9fkjmgliep-0 start">
    <li class="c9 li-bullet-0">
      <span class="c2">
        Access or attempt to access images that are not your images or accounts
        that you do not own, unless given permission by the image subject (or,
        in the case of a minor, the image subject&rsquo;s parent or legal
        guardian);
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Defame, abuse, harass, stalk, threaten or otherwise violate the rights
        of others, including, without limitation, others&rsquo; privacy rights
        or rights of publicity;
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Impersonate any person or entity or use any fraudulent, misleading or
        inaccurate email address or other contact information;
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Restrict or inhibit any other user from using any of our Sites and Apps,
        including, without limitation, by means of &ldquo;hacking&rdquo; or
        defacing any portion our Sites and Apps;
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">Violate any applicable laws or regulations;</span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Upload to, transmit through, or display on any of our Sites and Apps (a)
        any material that is unlawful, fraudulent, threatening, abusive,
        libelous, defamatory, obscene or otherwise objectionable, or infringes
        our or any third party&rsquo;s intellectual property or other rights;
        (b) any confidential, proprietary or trade secret information of any
        third party; or (c) any advertisements, solicitations, chain letters,
        pyramid schemes, investment opportunities or other unsolicited
        commercial communication (except as otherwise expressly permitted by
        us);
      </span>
    </li>
  </ul>
  <p class="c10 c12">
    <span class="c2"></span>
  </p>
  <ul class="c3 lst-kix_4f9fkjmgliep-0">
    <li class="c9 li-bullet-0">
      <span class="c2">Engage in spamming;</span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Transmit any software or other materials that contain any viruses,
        worms, trojan horses, defects, or other destructive items;
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Modify, adapt, translate, distribute, reverse engineer, decompile or
        disassemble any portion of our Sites and Apps; and
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Remove any copyright, trademark or other proprietary rights notices
        contained in or displayed on any portion of our Sites and Apps.
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Display adult nudity or inappropriate child nudity.
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Upload photographs of people who have not given permission for their
        photographs to be uploaded to a share site.
      </span>
    </li>
    <li class="c9 li-bullet-0">
      <span class="c2">
        Post objectionable material, such as material containing hate or
        malicious content or offers for adult services, or material inciting or
        advocating terrorism or violence.
      </span>
    </li>
  </ul>
  <p class="c13 c10">
    <span class="c7"></span>
  </p>
  <p class="c1">
    <span class="c2">
      If you fail to comply with the above rules, such failure will constitute a
      violation of these Terms, and in addition to any other rights or remedies
      we may have, we may immediately terminate your access to and use of our
      Sites and Apps.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      Some features of our Sites and Apps may enable you to send and receive
      transmissions. You acknowledge that we have no responsibility or liability
      for any transmissions, and/or any content included in such transmissions,
      sent or received by you. We reserve the right, in our sole discretion and
      at any time, to set limits on the number and size of any transmissions
      sent by or received through our Sites and Apps and/or the amount of
      storage space available for transmissions or for any feature made
      available through our website.
    </span>
  </p>
  <h5 class="c8" id="h.x8fnbwizlkpu">
    <span class="c15 c14 c6">2. YOUR MEMBER ACCOUNT</span>
  </h5>
  <p class="c1">
    <span class="c2">
      You may create a member account with any of our Sites and Apps by
      registering your name, providing certain information about yourself, and
      creating a password. You agree that you will provide complete, current and
      accurate information about yourself as requested, and that you will keep
      that information up to date. You are responsible for safeguarding the
      confidentiality of your username and password that you use to access your
      member account on our Sites and Apps. You agree not to disclose your
      username or password to any third party. YOU AGREE THAT YOU ARE SOLELY AND
      FULLY RESPONSIBLE FOR ANY ACTIVITIES OR ACTIONS TAKEN UNDER YOUR ACCOUNT,
      WHETHER OR NOT YOU HAVE AUTHORIZED SUCH ACTIVITIES OR ACTIONS. You will
      immediately notify us of any unauthorized use of your account on any of
      our Sites and Apps.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      In order to create a member account with any of our Sites and Apps, you
      must be at least 18 years of age. You represent to us that you are at
      least 18 years old. If you are not, please do not set up an account with
      any of our Sites and Apps.
    </span>
  </p>
  <h5 class="c8" id="h.dn9ax5tnugjd">
    <span class="c15 c14 c6">3. MAKING PURCHASES</span>
  </h5>
  <p class="c1">
    <span class="c2">
      If you wish to purchase any products or services through any of our Sites
      and Apps, we will ask you to supply certain information applicable to your
      purchase, including, without limitation, payment and other information.
      Any such information will be treated as described in our [link to: Privacy
      Policy]. All information that you provide to us or our third party payment
      processor must be accurate, current and complete. YOU REPRESENT AND
      WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) OR OTHER
      PAYMENT MEANS USED TO INITIATE ANY TRANSACTION. You agree to pay all
      charges incurred by you or any users of your account and credit card (or
      other applicable payment mechanism) at the prices in effect when such
      charges are incurred. You will also be responsible for paying any
      applicable taxes relating to your purchases. Verification of information
      applicable to a purchase may be required prior to our acceptance of any
      order.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      Descriptions, images, references, features, content, specifications,
      products, price and availability of any products or services are subject
      to change without notice, and our current prices can be found on our Sites
      and Apps. We make reasonable efforts to accurately display the attributes
      of our products, including the applicable colors; however, the actual
      color you see will depend on your computer system, and we cannot guarantee
      that your computer will accurately display such colors. The inclusion of
      any products or services on our Sites and Apps at a particular time does
      not imply or warrant that these products or services will be available at
      any time. It is your responsibility to ascertain and obey all applicable
      local, state, federal and international laws (including minimum age
      requirements) in regard to the possession, use and sale of any item
      purchased through our Sites and Apps. By placing an order, you represent
      that the products ordered will be used only in a lawful manner. We reserve
      the right, with or without prior notice, to limit the available quantity
      of or discontinue any product or service; to honor, or impose conditions
      on the honoring of, any coupon, coupon code, promotional code or other
      similar promotions; to bar any user from making any or all purchase(s);
      and/or to refuse to provide any user with any product or service.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      The personalized products we offer are made to order, and we reserve the
      right to charge your payment account method immediately when you place
      your order for such products. Title and risk of loss for any purchases
      pass to you upon our delivery to our carrier. We reserve the right to ship
      partial orders (at no additional cost to you).
    </span>
  </p>
  <h5 class="c8" id="h.4kz6l6jl08ds">
    <span class="c14 c6 c15">4. CONTESTS AND SWEEPSTAKES</span>
  </h5>
  <p class="c1">
    <span class="c2">
      In addition to the terms and conditions of these Terms, any contests,
      sweepstakes, surveys, games or similar promotions (collectively,
      &ldquo;Promotions&rdquo;) made available through our Sites and Apps may be
      governed by specific rules that are separate from this Agreement. By
      participating in any such Promotion, you will become subject to those
      rules, which may vary from these Terms. We urge you to review any specific
      rules applicable to a particular Promotion, which will be linked from such
      Promotion, and to review our [Privacy Policy link] which, in addition to
      these Terms, governs any information you submit in connection with such
      Promotion. To the extent that the terms and conditions of such rules
      conflict with these Terms, the terms and conditions of such rules will
      control.
    </span>
  </p>
  <p class="c1 c10">
    <span class="c2"></span>
  </p>
  <h5 class="c8" id="h.bjq3rnne54zx">
    <span class="c15 c14 c6">5. THIRD-PARTY SITES, SOFTWARE AND SERVICES</span>
  </h5>
  <p class="c1">
    <span class="c2">
      The Sites and Apps may contain links to other websites (&quot;Linked
      Sites&quot;). Such links are provided solely as a convenience to you. Pics
      does not screen, approve, review or otherwise endorse any content or
      information contained in any Linked Sites. You acknowledge and agree that
      Pics, its affiliates and partners are not responsible for the contents of
      any Linked Sites, including the accuracy or availability of information
      provided by Linked Sites, and makes no representations or warranties
      regarding the Linked Sites or your use of them.
    </span>
  </p>
  <h5 class="c8" id="h.qus3b75v0mno">
    <span class="c6 c14">6. PROFESSIONAL PHOTOGRAPHY</span>
  </h5>
  <p class="c1">
    <span class="c4">
      The following Additional Terms apply to customers of Pics professional
      photographs taken in portrait studios, schools, businesses and other
      organizations that Pics serves.{' '}
    </span>
    <span class="c4 c6 c16">Note to Schools:</span>
    <span class="c4">
      &nbsp;Separate terms of service, not these Terms, apply to products and
      services (including websites, applications and online services) that are
      designed for the use and benefit of the schools and school districts.
    </span>
    <span class="c2">
      &nbsp;Pics provides service to for their administrative and educational
      purposes and which are used by or at the direction of teachers or other
      school or district employees (a &ldquo;School Service&rdquo;). Please
      refer to the Terms of Service associated with those School Services or
      contact us through your Pics school account representative for further
      information.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      Pics delivers all digital images to customers via its photo platform. If
      you purchase a digital image or are eligible to receive a digital image
      through a promotion or loyalty program, you will need a Pics account to
      claim such digital images.
    </span>
  </p>
  <p class="c1">
    <span class="c4">
      Pics or its partner photography studio retains the copyright in its
      professional photographs. If you purchase a digital image, you will
      receive a copy authorization form that permits you and your authorized
      service providers{' '}
    </span>
    <span class="c4">
      to use and reproduce the image for any legal purpose.
    </span>
  </p>
  <h5 class="c8" id="h.i6txiwkybkpg">
    <span class="c15 c14 c6">7. Mobile Apps - General</span>
  </h5>
  <p class="c1">
    <span class="c2">
      Before using any of our mobile applications (&ldquo;App&rdquo; or
      &ldquo;Apps&rdquo;), we may ask you to install a valid copy of the App on
      your mobile device, register for an account, input your account
      information into the App as requested, and meet certain hardware and
      connection requirements which may change as the App evolves. You are
      responsible for any internet connection fees and/or mobile carrier charges
      that you incur when accessing or using an App. Should you uninstall an App
      from your mobile device, you may not be able to use all or some of the
      features of the App. We use reasonable efforts to accurately display the
      attributes of any photographs in products that you order through an App,
      including the colors of those photographs; however, the actual color you
      see is dependent upon your mobile device, and we cannot guarantee that
      your mobile device will accurately display such colors.
    </span>
  </p>
  <p class="c1 c10">
    <span class="c2"></span>
  </p>
  <ul class="c3 lst-kix_23n0hptpiyf9-0 start">
    <li class="c9 c10 li-bullet-0">
      <span class="c2"></span>
    </li>
  </ul>
  <p class="c1 c10">
    <span class="c0"></span>
  </p>
  <h5 class="c8" id="h.catenadjze7">
    <span class="c15 c14 c6">8. DISCLAIMER OF WARRANTIES</span>
  </h5>
  <p class="c1">
    <span class="c2">
      EXCEPT FOR THE EXPRESS WARRANTIES CONTAINED IN THESE TERMS, OUR PRODUCTS
      AND SERVICES, INCLUDING ALL MATERIALS INCORPORATED THEREIN, ARE PROVIDED
      &ldquo;AS IS&rdquo; AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
      IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, WE AND OUR
      DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, CONTRACTORS, AGENTS,
      REPRESENTATIVES AND AFFILIATES DISCLAIM ALL WARRANTIES, EXPRESS OR
      IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE,
      NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
      PURPOSE, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE
      OF PERFORMANCE OR USAGE OF TRADE. NOTE: CERTAIN APPLICABLE LAW MAY NOT
      ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS
      MAY NOT APPLY TO YOU. NO STATEMENT OF OURS OR ANY OF OUR DIRECTORS,
      OFFICERS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS, AGENTS, REPRESENTATIVES
      AND AFFILIATES SHALL CREATE ANY WARRANTY OTHER THAN THOSE EXPRESSLY
      CONTAINED IN THESE TERMS.
    </span>
  </p>
  <h5 class="c8" id="h.pk422kkd0ry3">
    <span class="c15 c14 c6">9. LIMITATION OF LIABILITY</span>
  </h5>
  <p class="c1">
    <span class="c2">
      TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, NEITHER WE NOR ANY OF
      OUR DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, CONTRACTORS, AGENTS,
      REPRESENTATIVES, OR AFFILIATES (THE &ldquo;PICS PARTIES&rdquo;) SHALL BE
      LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR
      PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
      BUSINESS, PROFITS, USE OR DATA), WHETHER BASED ON WARRANTY, CONTRACT, TORT
      (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE AND STRICT LIABILITY) OR ANY
      OTHER LEGAL THEORY, EVEN IF THE PICS PARTIES HAVE BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR RELATING IN ANY WAY TO OUR
      PROVISION OF (OR FAILURE TO PROVIDE) PRODUCTS OR SERVICES, EVEN IF A
      REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. YOU
      SPECIFICALLY ACKNOWLEDGE THAT THE PICS PARTIES ARE NOT LIABLE FOR ANY
      DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES
      AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
      FURTHERMORE, THE PICS PARTIES WILL HAVE NO LIABILITY TO YOU OR TO ANY
      THIRD PARTY FOR ANY THIRD-PARTY CONTENT UPLOADED. YOUR SOLE AND EXCLUSIVE
      REMEDY FOR DISSATISFACTION WITH PRODUCTS IS TO OBTAIN A REFUND, AND YOUR
      SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH SERVICES IS TO STOP
      USING THE SERVICES. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW,
      THE MAXIMUM LIABILITY OF THE PICS PARTIES ARISING OUT OF OR RELATING IN
      ANY WAY TO OUR PROVISION OF (OR FAILURE TO PROVIDE) PRODUCTS OR SERVICES
      SHALL BE THE ACTUAL PRICE PAID THEREFORE BY YOU. NOTE: CERTAIN
      JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL,
      CONSEQUENTIAL OR CERTAIN OTHER TYPES OF DAMAGES, SO SOME OF THE ABOVE
      EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.
    </span>
  </p>
  <h5 class="c8" id="h.6ypc44na0hlo">
    <span class="c15 c14 c6">[10. POLICY FOR IDEA SUBMISSION</span>
  </h5>
  <p class="c1">
    <span class="c2">
      Many of our customers are interested in submitting ideas and suggestions
      for products and services to be used at our Sites and Apps, either
      independently of, or in conjunction with, our internally developed
      concepts. We appreciate our customers&rsquo; interest in improving our
      Sites and Apps; however, please note that any such ideas or suggestions
      that you submit will be owned by us, and you hereby irrevocably assign any
      intellectual property rights in such ideas and suggestions to us. If you
      intend to retain any intellectual property rights in your ideas and
      suggestions (patent, trade secrets, copyright, trademark, etc.), please do
      not submit them to us without our prior written approval.
    </span>
  </p>
  <p class="c1">
    <span class="c2">
      You can inquire regarding such approval by sending a letter to Portrait
      Pics, LLC, Attn: Idea Submission, [address]. If we are interested in
      pursuing any idea or suggestion of yours, we will contact you. Please note
      that an additional legal agreement may be required by us in order to
      evaluate your idea or suggestion.]
    </span>
  </p>
  <h5 class="c8" id="h.7jz57z892e2e">
    <span class="c15 c14 c6">11. NOTICE FOR CALIFORNIA USERS</span>
  </h5>
  <p class="c1">
    <span class="c2">
      This notice is for our California users: The Complaint Assistance Unit of
      the Division of Consumer Services of the California Department of Consumer
      Affairs may be contacted by mail at 1625 North Market Blvd., Sacramento,
      CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
    </span>
  </p>
  <h5 class="c8" id="h.47oqsfpr1xfl">
    <span class="c14 c6">12. INTELLECTUAL PROPERTY NOTICES</span>
  </h5>
  <p class="c1">
    <span class="c4">
      All trademarks and service marks on any of our Sites and Apps not owned by
      us are the property of their respective owners. [
    </span>
    <span class="c4">
      Pics owns the registered trademarks and logos listed here.
    </span>
    <span class="c2">
      ] The trade names, trademarks and service marks owned by us, whether
      registered or unregistered, may not be used in connection with any product
      or service that is not ours, in any manner that is likely to cause
      confusion. Nothing contained on any of our Sites and Apps should be
      construed as granting, by implication, estoppel or otherwise, any license
      or right to use any of our trade names, trademarks or service marks
      without our express prior written consent.
    </span>
  </p>
  <h5 class="c8" id="h.2y48qmtyh9id">
    <span class="c15 c14 c6">13. JURISDICTIONAL ISSUES</span>
  </h5>
  <p class="c1">
    <span class="c2">
      Our Sites and Apps are controlled and operated from the United States. Our
      Sites and Apps are not subject to the laws or jurisdiction of any state,
      country or territory other than that of the United States. We do not
      represent or warrant that any of our Sites and Apps, products, and/or
      services or any part thereof is appropriate or available for use in any
      particular jurisdiction. Those who choose to access Pics do so on their
      own initiative and at their own risk, and are responsible for complying
      with all applicable laws, rules and regulations. We may limit the
      availability of our Sites and Apps, in whole or in part, to any person,
      geographic area or jurisdiction we choose, at any time and in our sole
      discretion.
    </span>
  </p>
  <h5 class="c8" id="h.7gfl2zsc4oq9">
    <span class="c15 c14 c6">
      14. CHOICE OF LAW; VENUE; STATUTE OF LIMITATIONS
    </span>
  </h5>
  <p class="c1">
    <span class="c4 c6">
      Please read this carefully. It affects your rights.
    </span>
    <span class="c2">
      &nbsp;These Terms and your relationship with Pics (past, present and in
      the future) are governed by and construed in accordance with the laws of
      the state in which you were billed for your purchase of the Pics product
      or service that is subject to this Agreement, without regard to the
      state&rsquo;s conflict of law provisions, and except to the extent
      preempted by or inconsistent with federal law. You and Pics agree that if
      any claim or cause of action arising out of or relating in any way to the
      Pics service or these Terms of Use has a statute of limitations in excess
      of two years, such claim or cause of action must be filed within two years
      after the claim or cause of action has accrued or it will be forever
      barred. To the extent any claim, cause of action, or request for relief is
      not subject to arbitration under Section 19 below, you and Pics agree to
      submit to the exclusive jurisdiction of any State or Federal court located
      in the [NJ?], United States of America, and waive any jurisdictional,
      venue or inconvenient forum objections to such courts.
    </span>
  </p>
  <h5 class="c8" id="h.e4opw0svz5uh">
    <span class="c15 c14 c6">15. MISCELLANEOUS</span>
  </h5>
  <p class="c1">
    <span class="c4">
      If any provision of these Terms, or the application thereof to any person,
      place or circumstance, will be or are held to be invalid, void or
      otherwise unenforceable, such provision will be enforced to the maximum
      extent possible, or, if incapable of such enforcement, will be deemed to
      be deleted from these Terms, and the remainder of these Terms and such
      provisions as applied to other persons, places and circumstances will
      remain in full force and effect. No waiver by either party of any breach
      or default hereunder will be deemed to be a waiver of any preceding or
      subsequent breach or default, nor will any delay or omission on the part
      of either party to exercise or avail itself of any right or remedy that it
      has or may have hereunder operate as a waiver of any right or remedy. This
      is the entire agreement between us relating to the subject matter herein
      and supersedes any and all prior or contemporaneous written or oral
      agreements between us with respect to such subject matter. These Terms are
      not assignable, transferable or sublicenseable by you except with our
      prior written consent. These Terms may not be modified or amended except
      as set forth in the introductory section of these Terms. Any heading,
      caption or section title contained in these Terms is inserted only as a
      matter of convenience and in no way defines or explains any section or
      provision hereof. If you have any questions or comments regarding these
      Terms, please contact:[create email to link]
    </span>
    <span class="c0">.</span>
  </p>
  <h5 class="c8" id="h.mrwvj1d8i1ai">
    <span class="c15 c14 c6">16. TERMINATION</span>
  </h5>
  <p class="c1">
    <span class="c2">
      Either you or we may terminate your access to our Sites and Apps and to
      your account at any time, with or without cause, and with or without prior
      notice. Without limiting the foregoing, we may terminate your access if
      you violate these Terms. Upon termination for any reason, your right to
      access and/or use our Sites and Apps will immediately cease. Upon
      termination, you will have no further access to, and we may delete, any
      information, files or materials in or related to your account. Upon
      termination, Pics may delete all information, files and materials related
      to your account and we will have no obligation whatsoever to save or make
      any such information, files or materials available to you. You agree that
      we will have no liability whatsoever to you or any other party as a result
      of a termination of your access our Sites and Apps and to your account
      and/or as a result of the deletion or loss of any information, files or
      materials in or related to your account.
    </span>
  </p>
  <h5 class="c8" id="h.j24kfgg7wzfl">
    <span class="c15 c14 c6">20. PICS PRIVACY POLICY</span>
  </h5>
  <ul class="c3 lst-kix_yhc5umt6owfq-0 start">
    <li class="c9 li-bullet-0">
      <span class="c4">
        View our Privacy Policy by visiting: [link to: Privacy Policy]
      </span>
    </li>
  </ul>
  <h5 class="c10 c11" id="h.x4fl909o7b9o">
    <span class="c15 c14 c6"></span>
  </h5>
  <h5 class="c8 c10" id="h.cka6wto4xhnb">
    <span class="c15 c14 c6"></span>
  </h5>
  <p class="c1 c10">
    <span class="c2"></span>
  </p>
  <p class="c1 c10">
    <span class="c2"></span>
  </p>
  <p class="c10 c13">
    <span class="c7"></span>
  </p>
</div>
`;
