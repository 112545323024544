import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { applyDiscountCode } from '../../actions/action_discount_code';
import { updateBnlFreeShipping } from '../../actions/action_bnl_incentive';

class DiscountCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
    };

    this.applyDiscountCode = this.applyDiscountCode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  applyDiscountCode(event) {
    event.preventDefault();
    const that = this;
    const data = {
      code: this.state.code,
      company_pricing_model_id: this.props.shoot.shoot.pricing_model.id,
    };

    this.props.applyDiscountCode(data).then((response) => {
      Swal.fire(`${response.payload.data.message}`);
      if (response.payload.data.is_free_shipping) {
        that.props.updateBnlFreeShipping(
          response.payload.data.is_free_shipping
        );
      }
    });
  }

  renderRemainingBalance() {
    if (this.props.shoot.shootPricing.discount_balance > 0) {
      return (
        <div className="discount-balance">
          <div>
            Remaining Credit:{' '}
            {this.props.displayPrice(
              this.props.shoot.shootPricing.discount_balance
            )}
          </div>
          <div>has been applied to this order</div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="order-checkout__side-box border--round">
        <div className="order-checkout__header">Discount Code</div>
        {this.renderRemainingBalance()}
        <form onSubmit={this.applyDiscountCode}>
          <input
            className="discount-code__input"
            name="code"
            onChange={this.handleInputChange}
            placeholder="Discount Code"
            value={this.state.code}
          />
          <input
            className="button--bnl bg--dark-grey discount-code__button"
            type="submit"
            value="Apply Discount Code"
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    discountCode: state.discountCode,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { applyDiscountCode, updateBnlFreeShipping },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCode);
