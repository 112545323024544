import axios from 'axios';

export const STORE_STUDENT_ID = 'STORE_STUDENT_ID';
export const STORE_IMAGE_ID = 'REMOVE_SSTORE_IMAGE_IDTUDENT_IMAGE';

const apiUrl = '';

export function storeYearbookStudentId(id) {
  return {
    type: STORE_STUDENT_ID,
    payload: id,
  };
}

export function storeImageId(id) {
  return {
    type: STORE_IMAGE_ID,
    payload: id,
  };
}
