import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const BannerRow = styled.div`
  margin: 0 -15px;

  .wrapper {
    padding-top: 12px;
    padding-bottom: 12px;

    .alert {
      margin-top: 0px;
      border: 3px solid #faf2cc;
      padding: 4px;
      border-radius: 4px; // Added a small rounded border
      color: #8a6d3b;
      background-color: #fcf8e3;
      margin-left: 0px;
      margin-right: 0px;
      text-align: center;
      margin-bottom: 0px !important;
    }

    .student-info {
      margin-top: 0px;
      border: 2px solid #bee5eb; // Updated border color
      padding: 4px;
      border-radius: 4px; // Added a small rounded border
      color: #0c5460; // Updated font color
      background-color: #d1ecf1; // Updated background color
      margin-left: 0px;
      margin-right: 0px;
      text-align: center;
      margin-bottom: 6px !important;
    }
  }
`;

const BannerMessage = ({ showStudentBanner }) => {
  const { selectedStudent } = useSelector((state) => state.additionalData);

  // grab the nested shoot object
  const { shoot } = useSelector((state) => state.shoot);

  const banner_message = shoot?.banner_message || null;
  const price_list_banner_message = shoot?.price_list_banner_message || null;
  const show_price_list_banner_message =
    shoot?.show_price_list_banner_message || null;

  const studentFirstName =
    selectedStudent?.firstName || selectedStudent?.first_name || null;
  const studentLastName =
    selectedStudent?.lastName || selectedStudent?.last_name || null;
  const hasStudent = studentFirstName && studentLastName;

  const selectedBannerMessage =
    show_price_list_banner_message && price_list_banner_message
      ? price_list_banner_message
      : banner_message;

  if (!hasStudent && !selectedBannerMessage) return null;

  return (
    <BannerRow>
      <div className="wrapper">
        {hasStudent && showStudentBanner && (
          <div className="student-info">
            Current Order:{' '}
            <strong>
              {studentFirstName} {studentLastName}
            </strong>
            {shoot.name && (
              <>
                {' '}
                at <strong>{shoot?.name}</strong>
              </>
            )}
          </div>
        )}
        {selectedBannerMessage && (
          <div className="alert">{selectedBannerMessage}</div>
        )}
      </div>
    </BannerRow>
  );
};

export default BannerMessage;
