import axios from 'axios';

export const GET_GALLERIES = 'GET_GALLERIES';
export const STORE_GALLERY = 'STORE_GALLERY';
export const REMOVE_STUDENT_IMAGE = 'REMOVE_STUDENT_IMAGE';
export const SET_GALLERIES = 'SET_GALLERIES';
export const SET_CPM_ID = 'SET_CPM_ID';

const apiUrl = '/api/v2';

export function getGalleries(data) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/galleries/get-gallery`, {
        params: data,
      });

      dispatch({
        type: GET_GALLERIES,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error fetching galleries:', error);

      // TODO: Dispatch an error action
      // dispatch({
      //   type: GET_GALLERIES_ERROR,
      //   payload: error,
      // });
    }
  };
}

export function storeGallery(
  cpmId,
  student,
  gallery,
  requiresYearbookPhotoSelection,
) {
  const data = {
    cpmId,
    student,
    gallery,
    requiresYearbookPhotoSelection,
  };

  return {
    type: STORE_GALLERY,
    payload: data,
  };
}

export function removeStudentImage(data) {
  const request = axios.delete(`${apiUrl}/galleries/remove-student-image`, {
    params: data,
  });

  return {
    type: REMOVE_STUDENT_IMAGE,
    payload: request,
  };
}

export function setGalleries(galleries) {
  return {
    type: SET_GALLERIES,
    payload: galleries,
  };
}

export function setCpmId(cpmId) {
  return {
    type: SET_CPM_ID,
    payload: cpmId,
  };
}
