import {
  GET_GALLERIES,
  SET_CPM_ID,
  STORE_GALLERY,
  REMOVE_STUDENT_IMAGE,
  SET_GALLERIES,
} from '../actions/action_gallery';

const INITIAL_STATE = {
  cpmId: null,
  galleries: {},
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case GET_GALLERIES:
      return { ...state, galleries: action.payload?.galleries || [] };
    case SET_GALLERIES:
      return { ...state, galleries: action.payload };
    case STORE_GALLERY:
      const cpmId = action.payload.cpmId;
      if (state.galleries[cpmId]) {
        return {
          ...state,
          galleries: {
            [cpmId]: {
              ...state.galleries[cpmId],
              [action.payload.student.id]: {
                student: action.payload.student,
                gallery: action.payload.gallery.gallery,
                associatedGroupImages:
                  action.payload.gallery.associatedGroupImages,
                watermarkedImages: action.payload.gallery.watermarkedImages,
                shoot: action.payload.gallery.shoot,
                requiresYearbookPhotoSelection:
                  action.payload.requiresYearbookPhotoSelection,
              },
            },
          },
        };
      }
      return {
        ...state,
        galleries: {
          [cpmId]: {
            [action.payload.student.id]: {
              student: action.payload.student,
              gallery: action.payload.gallery.gallery,
              associatedGroupImages:
                action.payload.gallery.associatedGroupImages,
              shoot: action.payload.gallery.shoot,
              requiresYearbookPhotoSelection:
                action.payload.requiresYearbookPhotoSelection,
            },
          },
        },
      };
    case REMOVE_STUDENT_IMAGE:
      if (action.payload.data.company_pricing_model_id) {
        return {
          ...state,
          galleries: {
            ...state.galleries,
            [action.payload.data.company_pricing_model_id]: {
              ...state.galleries[action.payload.data.company_pricing_model_id],
              [action.payload.data.student.id]: {
                student: action.payload.data.student,
                gallery: action.payload.data.gallery,
                associatedGroupImages:
                  action.payload.data.associatedGroupImages,
                shoot: action.payload.data.shoot,
              },
            },
          },
        };
      }
      return {
        ...state,
        galleries: {
          ...state.galleries,
          no_images: {
            ...state.galleries.no_images,
            [action.payload.data.student.id]: {
              student: action.payload.data.student,
              gallery: [],
              associatedGroupImages: [],
              shoot: null,
            },
          },
        },
      };
    case SET_CPM_ID:
      return { ...state, cpmId: action.payload };
    default:
      return state;
  }
}
