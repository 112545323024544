import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setBnlShipping,
  confirmUserContactAddress,
} from '../../actions/action_cart';

// React.js components
import DiscountCode from '../discount_code/discount_code';
import BnlOrderSmsForm from './bnl/bnl_order_sms_form';
import Dropdown from '../utils/dropdown';
import ConfirmUserAddress from './ConfirmUserAddress';

// constants files
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../constants/companies';

class OrderCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address1: this.props.parentAddress.address_1,
      address2: this.props.parentAddress.address_2,
      cardNumber: '',
      city: this.props.parentAddress.city,
      editing: false,
      loading: false,
      parentEmail: this.props.parentEmail,
      parentFirstName: this.props.parentFirstName,
      parentLastName: this.props.parentLastName,
      shipping: this.props.cart.bnlShipping,
      state: this.props.parentAddress.state,
      zipcode: this.props.parentAddress.zipcode,
    };

    this.handleAddCard = this.handleAddCard.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.setShipping = this.setShipping.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.renderContactAddressSaveButton =
      this.renderContactAddressSaveButton.bind(this);
    this.updateContactAddressInfo = this.updateContactAddressInfo.bind(this);
    this.setUserHasConfirmedContactAddress =
      this.setUserHasConfirmedContactAddress.bind(this);
    this.renderEditButton = this.renderEditButton.bind(this);
    this.formatSmsNumber = this.formatSmsNumber.bind(this);
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  setShipping(event) {
    event.persist();
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.props.setBnlShipping(event.target.value);
    });
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  updateContactAddressInfo() {
    const { updateCheckoutInformation, updateCheckoutSmsPhone } = this.props;
    const { address1, address2, city, state, zipcode, parentEmail } =
      this.state;

    const data = {
      parentAddress: {
        address1,
        address2,
        city,
        state,
        zipcode,
      },
      parentEmail,
    };

    this.setState({ loading: true });
    updateCheckoutInformation(data);
    updateCheckoutSmsPhone();
    this.setUserHasConfirmedContactAddress();
    // this.props.submitSmsPhone();
  }

  setUserHasConfirmedContactAddress() {
    this.props.confirmUserContactAddress();
    this.setState({ loading: false });
  }

  handlePayment() {
    if (this.props.calculateGrandTotal() > 0) {
      this.handleAddCard();
    } else {
      this.props.submitOrder();
    }
  }

  getPaymentText() {
    if (this.props.calculateGrandTotal() > 0) {
      return 'Pay with Credit or Debit Card';
    }
    return 'Place Order';
  }

  shootIsBnlPreShoot() {
    const { preShoot } = this.props.bnlPreShoot;

    return preShoot;
  }

  formatSmsNumber(unformattedSms) {
    const smsPhone = unformattedSms.substr(2);

    const number = smsPhone.split('').map((digit, i) => {
      if (
        (i === 2 && smsPhone.length > 3) ||
        (i === 5 && smsPhone.length > 6)
      ) {
        return `${digit}-`;
      }
      return digit;
    });

    return number.join('');
  }

  // TODO: move to to OrderContainer
  handleAddCard() {
    let name;
    let description;

    switch (this.props.companyAuthToken) {
      case GESKUS:
        name = 'Geskus';
        description = 'Photo Prints';
        break;
      case BNL:
        name = 'BNL';
        description = 'School Photography';
        break;
      case JELLYJAR:
        name = 'Jelly Jar';
        description = 'School Photography';
        break;
      case GB:
        name = 'G&B';
        description = 'School Photography';
        break;
      case NORMANDY:
        name = 'Normandy Studio';
        description = 'School Photography';
        break;
      case TIPPING:
        name = 'Tipping';
        description = 'School Pictures';
        break;
      case MARCEL:
        name = 'Marcel Studios';
        description = 'School Photography';
        break;
      case CLASSACT:
        name = 'Class Act';
        description = 'Photographers';
        break;
      case WAGNER:
        name = 'Wagner';
        description = 'Portrait Group';
        break;
      case BIELMAR:
        name = 'Bielmar Inc.';
        description = '';
        break;
    }

    this.stripeHandler.open({
      name,
      description,
      amount: this.props.calculateGrandTotal(),
      email: this.state.parentEmail,
    });
  }

  renderEditButtonText() {
    return this.state.editing ? 'Save' : 'Edit';
  }

  renderFormattedAddress(formattedAddressDisplay) {
    return (
      <div
        className={`payment-info__displayed-info ${formattedAddressDisplay}`}
      >
        <div>
          {this.state.address1} {this.state.address2}
        </div>
        <div>
          {this.state.city}, {this.state.state} {this.state.zipcode}
        </div>
      </div>
    );
  }

  renderStateDropdown(inputDisplay) {
    const states = [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ];

    return (
      <Dropdown
        className={`payment-info__input payment-info__input--state ${inputDisplay}`}
        handleOnChange={this.handleOnChange}
        name="state"
        options={states}
        value={this.state.state}
      />
    );
  }

  renderBnlShippingDropdown() {
    return (
      <select
        className="payment-info__input"
        name="shipping"
        onChange={this.setShipping}
        value={this.state.shipping}
      >
        <option value="school">Ship to School</option>
        <option value="home">Ship to Home - $5.95</option>
      </select>
    );
  }

  renderSubheader() {
    if (this.props.companyIsBnlCompany()) {
      return (
        <p>
          Please confirm your email address.
          <br />
          <br />
          Please confirm a mobile number to send SMS-based products to.
        </p>
      );
    }
    return (
      <p>
        Please confirm your email address.
        <br />
        <br />
        Please confirm your mobile number.
      </p>
    );
  }

  renderContactInformation(infoDisplay, inputDisplay) {
    return (
      <div className="payment-info-container clearfix">
        <div className="payment-info-desc__header">
          <div className="payment-info-desc__header-1">Contact Information</div>
          <div className="payment-info-desc__header-2">
            {this.renderSubheader()}
          </div>
        </div>
        <div className="payment-info__input-container payment-info__input-container--contact">
          <div className={`payment-info__displayed-info ${infoDisplay}`}>
            {this.state.parentEmail}
          </div>
          <input
            onChange={this.handleOnChange}
            value={this.state.parentEmail}
            name="parentEmail"
            className={`payment-info__input ${inputDisplay}`}
            placeholder="Email"
          />
          {this.renderSmsPhoneForm()}
        </div>
      </div>
    );
  }

  renderSmsPhoneForm() {
    let smsPhone;

    if (this.props.smsPhone || this.props.parent.smsPhone) {
      if (this.props.parent.smsPhone) {
        smsPhone = this.formatSmsNumber(this.props.parent.smsPhone);
      } else {
        smsPhone = this.props.smsPhone;
      }
    } else {
      for (const studentId in this.props.cart.orders) {
        const orderState = this.props.cart.orders[studentId];
        if (orderState.smsPhone) smsPhone = orderState.smsPhone;
      }
    }

    if (smsPhone) {
      return (
        <BnlOrderSmsForm
          checkout
          displayFormatted={!this.state.editing}
          editing={this.state.editing}
          errorMessage={this.props.errorMessage}
          handleSmsInputChange={this.props.handleSmsInputChange}
          smsPhone={smsPhone}
        />
      );
    }
  }

  renderShipping(infoDisplay, inputDisplay) {
    if (
      this.props.shoot.shoot.company_shipping_options !== 'ship_to_school_only'
    ) {
      return this.renderGeskusShipping(infoDisplay, inputDisplay);
    }

    return null;
  }

  renderGeskusShipping(infoDisplay, inputDisplay) {
    return (
      <div className="payment-info-container clearfix">
        <div className="payment-info-desc__header">Shipping Address</div>
        <div className="payment-info__input-container payment-info__input-container--shipping">
          {this.renderFormattedAddress(infoDisplay)}
          <input
            name="address1"
            value={this.state.address1}
            onChange={this.handleOnChange}
            className={`payment-info__input ${inputDisplay}`}
            placeholder="Street Address 1"
          />
          <input
            name="address2"
            value={this.state.address2}
            onChange={this.handleOnChange}
            className={`payment-info__input ${inputDisplay}`}
            placeholder="Street Address 2"
          />
          <input
            name="city"
            value={this.state.city}
            onChange={this.handleOnChange}
            className={`payment-info__input ${inputDisplay}`}
            placeholder="City"
          />
          {this.renderStateDropdown(inputDisplay)}
          <input
            name="zipcode"
            value={this.state.zipcode}
            onChange={this.handleOnChange}
            className={`payment-info__input ${inputDisplay}`}
            placeholder="Zipcode"
          />
        </div>
      </div>
    );
  }

  renderBnlShipping(infoDisplay, inputDisplay) {
    const formattedAddressDisplay =
      this.state.shipping === 'home' && !this.state.editing ? '' : 'hidden';
    const shippingInputDisplay =
      this.state.shipping === 'home' && this.state.editing ? '' : 'hidden';

    return (
      <div className="payment-info-container clearfix">
        <div className="payment-info-desc__header">
          <div className="payment-info-desc__header-1">Shipping Address</div>
          <div className="payment-info-desc__header-2">
            Please select your shipping option. We can ship your order directly
            to your home for $5.95 or send the order to the school free of
            charge
          </div>
        </div>
        <div className="payment-info__input-container">
          {this.renderBnlShippingDropdown()}
          {this.renderFormattedAddress(formattedAddressDisplay)}
          <div className="clearfix">
            <div
              className={`payment-info__div payment-info__div--left ${shippingInputDisplay}`}
            >
              {this.state.parentFirstName}
            </div>
            <div
              className={`payment-info__div payment-info__div--right ${shippingInputDisplay}`}
            >
              {this.state.parentLastName}
            </div>
          </div>
          <input
            name="address1"
            value={this.state.address1}
            onChange={this.handleOnChange}
            className={`payment-info__input ${shippingInputDisplay}`}
            placeholder="Street Address 1"
          />
          <input
            name="address2"
            value={this.state.address2}
            onChange={this.handleOnChange}
            className={`payment-info__input ${shippingInputDisplay}`}
            placeholder="Street Address 2"
          />
          <div className="clearfix">
            <input
              name="city"
              value={this.state.city}
              onChange={this.handleOnChange}
              className={`payment-info__input payment-info__input--city ${shippingInputDisplay}`}
              placeholder="City"
            />
            {this.renderStateDropdown(shippingInputDisplay)}
            <input
              name="zipcode"
              value={this.state.zipcode}
              onChange={this.handleOnChange}
              className={`payment-info__input payment-info__input--zipcode ${shippingInputDisplay}`}
              placeholder="Zipcode"
            />
          </div>
        </div>
      </div>
    );
  }

  renderDiscount() {
    if (this.props.discountTotal !== 0) {
      return (
        <div className="clearfix">
          <div className="order-summary__text">Discount:</div>
          <div className="order-summary__price">
            {this.props.displayNegativePrice(this.props.discountTotal)}
          </div>
        </div>
      );
    }
  }

  renderTax() {
    if (this.props.taxTotal > 0) {
      return (
        <div className="order-cart-summary__subtotal clearfix">
          <div className="order-summary__text">Estimated Tax:</div>
          <div className="order-summary__price">
            {this.props.displayPrice(this.props.taxTotal)}
          </div>
        </div>
      );
    }

    return null;
  }

  renderAddStudentBox() {
    return (
      <div className="order-checkout__add-student">
        <div className="order-checkout__header">Add Student</div>
        <div className="order-checkout__sub-header">
          You can add another student to this order
        </div>
        <div className="button bg--dark-grey" onClick={this.props.addStudent}>
          Add Student
        </div>
      </div>
    );
  }

  renderAddAnotherOrderBox() {
    if (this.shootIsBnlPreShoot()) {
      return (
        <div className="bnl-cart__side-box border--round">
          <div className="bnl-cart__new-shoot-key__header">
            Add Another Order
          </div>
          <div className="bnl-cart__new-shoot-key__subheader">
            Ordering for more than 1 student? Enter their unique key to create
            another order before checking out.
          </div>
          <div
            className="button--bnl bg--dark-grey"
            onClick={this.props.goToDashboard}
          >
            Add Order
          </div>
        </div>
      );
    }
  }

  renderDiscountCode() {
    if (
      // this.props.shoot.shoot.company_auth_token !== GESKUS &&
      this.props.shoot.shoot.company_offers_discount_codes
      // && !this.shootIsBnlPreShoot()
    ) {
      return <DiscountCode displayPrice={this.props.displayPrice} />;
    }

    return null;
  }

  renderBnlSideBoxes() {
    return (
      <div>
        {this.renderDiscountCode()}
        {this.renderAddAnotherOrderBox()}
        <div className="order-checkout__side-box border--round">
          <div
            className="button bg--dark-grey border--round"
            onClick={this.props.keepShopping}
          >
            Keep Shopping
          </div>
        </div>
      </div>
    );
  }

  renderSideBox() {
    switch (this.props.companyAuthToken) {
      case GESKUS:
        // return this.renderAddStudentBox();
        return this.renderBnlSideBoxes();
      case BNL:
      case JELLYJAR:
      case GB:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        return this.renderBnlSideBoxes();
      default:
        return this.renderBnlSideBoxes();
    }
  }

  renderShippingPrice() {
    // TODO: adjust to account for per-event shipping settings
    // TODO: remove Wagner-specific logic
    const companyShippingOptions =
      this.props.shoot.shoot.company_shipping_options;

    if (companyShippingOptions !== 'ship_to_school_only') {
      if (
        this.props.shoot.shoot.company_auth_token === 'wagner' &&
        this.props.shoot.shoot.pricing_model.shipping_price === 0
      ) {
        return null;
      }
      return (
        <div className="order-cart-summary__subtotal clearfix">
          <div className="order-summary__text">Shipping:</div>
          <div className="order-summary__price">
            {this.props.displayShippingPrice()}
          </div>
        </div>
      );
    }

    return null;
  }

  renderContactAddressSaveButton() {
    const { editing } = this.state;

    if (editing) {
      return (
        <div className="contactAddressButtonContainer">
          <button
            type="submit"
            id="saveContactAddress"
            className="cursor-pointer button--round-border--no-vertical-margin bg--bnl-light-blue"
            onClick={() => {
              this.updateContactAddressInfo();
            }}
          >
            Save
          </button>
        </div>
      );
    }

    return null;
  }

  renderEditButton() {
    const { editing } = this.state;

    if (editing) {
      return null;
    }

    return (
      <div
        onClick={this.toggleEdit}
        className="payment-info__edit cursor-pointer"
      >
        {this.renderEditButtonText()}
      </div>
    );
  }

  render() {
    const infoDisplay = this.state.editing ? 'hidden' : '';
    const inputDisplay = this.state.editing ? '' : 'hidden';
    const buttonBg =
      this.props.companyName === GESKUS
        ? 'button bg--dark-blue'
        : 'button--round-border bg--bnl-light-blue';

    const { userHasConfirmedAddress } = this.props.cart;
    const { submitting } = this.props;
    const { loading } = this.state;

    let loadingSpinner = 'hidden';

    if (loading || submitting) {
      loadingSpinner = 'loading';
    }

    const paymentButtonStyle = {
      maxWidth: '280px',
    };

    return (
      <div className="order-checkout clearfix">
        <div className={loadingSpinner}>
          <div className="spinner" />
        </div>
        <ConfirmUserAddress
          parent={this.props.parent}
          shoot={this.props.shoot}
          userHasConfirmedAddress={userHasConfirmedAddress}
          setUserHasConfirmedContactAddress={
            this.setUserHasConfirmedContactAddress
          }
          toggleEdit={this.toggleEdit}
          {...this.state}
        />
        <div className="order-checkout__main-header">
          Customer Details / Payment / Review
        </div>
        <div className="row">
          <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
            {this.renderEditButton()}
            {this.renderContactInformation(infoDisplay, inputDisplay)}
            {this.renderShipping(infoDisplay, inputDisplay)}
            {this.renderContactAddressSaveButton()}
          </div>
          <div className="order-checkout__right col-xs-12 col-md-4 col-lg-4">
            <div className="order-checkout__summary">
              <div className="order-checkout__header">Order Summary</div>
              <div className="clearfix">
                <div className="order-summary__text">Subtotal:</div>
                <div className="order-summary__price">
                  {this.props.displayPrice(this.props.subTotal)}
                </div>
              </div>
              {this.renderDiscount()}
              {this.renderShippingPrice()}
              {this.renderTax()}
              <div className="order-summary__total clearfix">
                <div className="order-summary__text">Total:</div>
                <div className="order-summary__price">
                  {this.props.displayPrice(this.props.calculateGrandTotal())}
                </div>
              </div>
              <div
                className={buttonBg}
                style={paymentButtonStyle}
                id="paymentButton"
                onClick={this.handlePayment}
              >
                {this.getPaymentText()}
              </div>
            </div>
            {this.renderSideBox()}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // init the Stripe JS handler
    this.stripeHandler = StripeCheckout.configure({
      key: this.props.stripePublishableKey,
      image: '', // TODO: add company's logo
      locale: 'auto',
      allowRememberMe: false,
      token: (token) => {
        this.props.addPaymentToken(
          token.id,
          token.card.last4,
          token.card.brand,
        );
      },
    });

    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    shoot: state.shoot,
    parent: state.parent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setBnlShipping, confirmUserContactAddress },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCheckout);
