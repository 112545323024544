export const UPDATE_GESKUS_FREE_SHIPPING = 'UPDATE_GESKUS_FREE_SHIPPING';
export const UPDATE_CART_LEVEL_ITEMS = 'UPDATE_CART_LEVEL_ITEMS';
export const RESET_GESKUS_INCENTIVE = 'RESET_GESKUS_INCENTIVE';

export function updateGeskusFreeShipping(boolean) {
  return {
    type: UPDATE_GESKUS_FREE_SHIPPING,
    payload: boolean,
  };
}

export function updateGeskusIncentiveCartLevelItems(cartItems) {
  return {
    type: UPDATE_CART_LEVEL_ITEMS,
    payload: cartItems,
  };
}

export function resetGeskusIncentive() {
  return {
    type: RESET_GESKUS_INCENTIVE,
    payload: null,
  };
}
