import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

const ConfirmUserAddress = (props) => {
  const {
    userHasConfirmedAddress,
    toggleEdit,
    setUserHasConfirmedContactAddress,
    editing,
    address1,
    address2,
    city,
    parent,
    state,
    zipcode,
    parentEmail,
    shoot,
  } = props;

  let formattedAddress2 = '';
  let confirmPhoneNumber = parent.smsPhone;

  if (!confirmPhoneNumber || confirmPhoneNumber === '') {
    confirmPhoneNumber = parent.phone;
  }

  if (address2) {
    formattedAddress2 = `<br>${address2}`;
  }

  if (
    !userHasConfirmedAddress &&
    !editing &&
    shoot.shoot.company_shipping_options === 'ship_to_school_only'
  ) {
    Swal.fire({
      title: 'Confirm Your Info',
      html: `
        <h6>Email</h6>
        <p>${parentEmail}</p>
        <h6>Mobile Phone Number</h6>
        <p>${confirmPhoneNumber}</p>
      `,
      customClass: {
        container: 'confirmAddress',
      },
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      cancelButtonText: 'Edit',
      confirmButtonText: 'OK',
    }).then((res) => {
      if (res.value) {
        setUserHasConfirmedContactAddress();
      } else {
        toggleEdit();
      }
    });
  } else if (!userHasConfirmedAddress && !editing) {
    Swal.fire({
      title: 'Confirm Your Info',
      html: `
        <h6>Shipping Address</h6>
        <p>${address1}${formattedAddress2}<br>${city}, ${state} ${zipcode}</p>
        <h6>Email</h6>
        <p>${parentEmail}</p>
        <h6>Mobile Phone Number</h6>
        <p>${confirmPhoneNumber}</p>
      `,
      customClass: {
        container: 'confirmAddress',
      },
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      cancelButtonText: 'Edit',
      confirmButtonText: 'OK',
    }).then((res) => {
      if (res.value) {
        setUserHasConfirmedContactAddress();
      } else {
        toggleEdit();
      }
    });
  }
  return null;
};

ConfirmUserAddress.propTypes = {
  setUserHasConfirmedContactAddress: PropTypes.func,
  toggleEdit: PropTypes.func,
  userHasConfirmedAddress: PropTypes.bool,
  editing: PropTypes.bool,
};

export default ConfirmUserAddress;
