import React from 'react';
import { useSelector } from 'react-redux';
import cartUtils from '../../../utils/cart';
import formatUSD from '../../../utils/formatUSD';

const IncentiveTracker = () => {
  const selectedStudent = useSelector(
    (state) => state.additionalData.selectedStudent,
  );
  const incentiveProducts = useSelector(
    (state) => state.shoot.incentiveProducts,
  );
  const orders = useSelector((state) => state.v3Order.orders);

  if (!selectedStudent) {
    return null;
  }

  const currentOrderCartItems = orders[selectedStudent?.id]?.cartItems || [];
  const currentOrderTotal = cartUtils.getOrderTotal(currentOrderCartItems);
  const allOrdersTotal = cartUtils.getAllOrdersTotal(orders);
  const allCartSKUs = cartUtils.getAllCartSKUs(orders);

  const renderSections = () => {
    const widthStyle = {
      width: `${100 / incentiveProducts.length}%`,
    };

    const sections = incentiveProducts.map((product, i) => {
      let desktopText;
      let mobileTextTop;
      let mobileTextCenter;
      let mobileTextBottom;
      let diff;
      let cartLevelProductInCart = false;

      if (product.incentive_applies_to === 'cart_level') {
        diff = product.incentive_threshold - allOrdersTotal;
      } else if (product.incentive_applies_to === 'order_level') {
        diff = product.incentive_threshold - currentOrderTotal;
      }

      let className = 'incentive-tracker__section--unfilled';
      className += ` incentive-tracker__section--unfilled-${i + 1}`;

      const price = formatUSD(diff, true);

      if (
        diff <= 0 ||
        (product.incentive_applies_to === 'cart_level' &&
          allCartSKUs.includes(product.sku))
      ) {
        desktopText = `Earned: ${product.name}!`;
        mobileTextTop = 'Earned:';
        mobileTextCenter = `${product.name}`;
        mobileTextBottom = '!';
        className = 'incentive-tracker__section--filled';
        className += ` incentive-tracker__section--filled-${i + 1}`;
      } else {
        desktopText = `Spend ${price} more: ${product.name}`;
        mobileTextTop = `Spend ${price} more:`;
        mobileTextCenter = `${product.name}`;
      }

      const textClassName = 'incentive-tracker__section-text';
      const textCompanyClassName = '';
      const countclassName = `incentive-tracker__section--${incentiveProducts.length}-total`;

      return (
        <li
          className={`incentive-tracker__section ${className}`}
          key={`incentive-tracker-section-${i}`}
          style={widthStyle}
        >
          <div
            className={`${textClassName} ${textClassName}--desktop ${textCompanyClassName} ${countclassName}`}
          >
            {desktopText}
          </div>
          <div
            className={`${textClassName} ${textClassName}--mobile ${textCompanyClassName}`}
          >
            <div>{mobileTextTop}</div>
            <div>{mobileTextCenter}</div>
            <div>{mobileTextBottom}</div>
          </div>
        </li>
      );
    });

    return sections;
  };

  return <ul className="incentive-tracker clearfix">{renderSections()}</ul>;
};

export default IncentiveTracker;
