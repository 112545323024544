import { UPDATE_ORDER_ACTIVE_COMPONENT } from '../actions/action_order_navigation';

const INITIAL_STATE = { step: 1 };

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case UPDATE_ORDER_ACTIVE_COMPONENT:
      return { ...state, step: action.payload };
    default:
      return state;
  }
}
