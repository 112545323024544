import React, { Component } from 'react';
import { connect } from 'react-redux';

import BnlOrderProductGroup from './bnl_order_product_group';
import BnlOrderProduct from './bnl_order_product';

import { ORDER_NAVIGATION } from '../../../constants/order_navigation';

class BnlOrderProductGroups extends Component {
  constructor(props) {
    super(props);

    this.STEPS = {
      PRODUCT_GROUPS: 1,
      PRODUCTS: 2,
      CHILD_PRODUCT_GROUPS: 3,
    };

    this.state = {
      activeComponent: this.props.activeComponent,
      category: this.getInitialCategory(),
      metallicProducts: this.getInitialMetallicProducts(),
      nonMetallicProducts: this.getInitialNonMetallicProducts(),
      currentActiveParentCategoryId: null,
    };

    this.categorizeProducts = this.categorizeProducts.bind(this);
    this.showChildCategories = this.showChildCategories.bind(this);
    this.goBack = this.goBack.bind(this);
    this.selectProductGroup = this.selectProductGroup.bind(this);
    this.selectProductGroupById = this.selectProductGroupById.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  componentDidMount() {
    const {
      orderState,
      updateOrderActiveComponent,
      cart,
      selectShootProduct,
      shoot,
    } = this.props;

    // TODO: move this logic into Redux
    if (orderState && orderState.studentId) {
      if (
        cart.bnlPromptRetouching &&
        Object.keys(cart.bnlPromptRetouching).includes(
          orderState.studentId.toString(),
        )
      ) {
        if (cart.bnlPromptRetouching[orderState.studentId] === true) {
          selectShootProduct(shoot.retouchingProduct, true);
          return;
        }
      }
    }

    // TODO: use a shared function for this check
    // TODO: move into Redux
    if (orderState) {
      let cartContainsNthFree = false;
      let nthFreeQualifyingInCart = 0;
      let nthFreeThreshold = 9999;
      let nthFreeCartItem;

      orderState.cartItems.forEach((cartItem) => {
        if (cartItem.product_type && cartItem.product_type === 'nth_free') {
          cartContainsNthFree = true;
          nthFreeCartItem = cartItem;
        }

        if (cartItem.nth_free_sku && cartItem.nth_free_sku !== '') {
          nthFreeQualifyingInCart += cartItem.count;
        }

        if (cartItem.nth_free_threshold && cartItem.nth_free_threshold !== 0) {
          if (cartItem.nth_free_threshold < nthFreeThreshold) {
            nthFreeThreshold = cartItem.nth_free_threshold;
          }
        }
      });

      if (!cartContainsNthFree && nthFreeQualifyingInCart >= nthFreeThreshold) {
        updateOrderActiveComponent(ORDER_NAVIGATION.NTH_FREE_PRODUCTS);
        return;
      }
    }

    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.activeComponent != nextProps.activeComponent) {
      this.setState({ activeComponent: nextProps.activeComponent });
    }
  }

  componentDidUpdate(nextProps, nextState) {
    // TODO:
    // revisit
    // this is def VERY hackish
    if (
      this.props.orderNavigation.step === nextProps.orderNavigation.step &&
      this.state.activeComponent === 2 &&
      nextState.activeComponent === 2 &&
      this.state.category === nextState.category
    ) {
      this.props.goToBnlProductGroups();
    }

    if (this.state.activeComponent !== nextState.activeComponent) {
      window.scrollTo(0, 0);
    }
  }

  getInitialCategory() {
    const paperType = this.props.shoot.shoot.pricing_model.default_paper_type;

    if (!paperType) {
      return null;
    }
    if (paperType === 'regular') {
      return 'nonMetallic';
    }
    if (paperType === 'metallic') {
      return 'metallic';
    }
  }

  getInitialMetallicProducts() {
    const products = [];

    if (this.props.activeComponent === this.STEPS.PRODUCTS) {
      this.props.selectedProductGroup.products.forEach((product) => {
        if (product.is_metallic) products.push(product);
      });
    }

    return products;
  }

  getInitialNonMetallicProducts() {
    const products = [];

    if (this.props.activeComponent === this.STEPS.PRODUCTS) {
      this.props.selectedProductGroup.products.forEach((product) => {
        if (!product.is_metallic) products.push(product);
      });
    }

    return products;
  }

  selectProductGroupById(productGroupId) {
    let targetProductGroup;
    const allProductGroups = this.props.shootProductGroups;

    allProductGroups.forEach((productGroup) => {
      if (productGroup.id === productGroupId) {
        targetProductGroup = productGroup;
      }
    });

    this.selectProductGroup(targetProductGroup);
  }

  selectProductGroup(productGroup) {
    let callback = this.categorizeProducts;
    let showChildCategories = false;

    if (
      productGroup.hierarchy_label &&
      productGroup.hierarchy_label === 'parent'
    ) {
      callback = this.showChildCategories;
      showChildCategories = true;
    }

    this.setState({ category: this.getInitialCategory() }, () => {
      this.props.selectProductGroup(
        productGroup,
        callback,
        showChildCategories,
      );
    });
  }

  goBack() {
    this.setState({ activeComponent: this.STEPS.PRODUCT_GROUPS });
  }

  setCategory(category) {
    this.setState({ category });
  }

  showChildCategories(parentProductGroup) {
    this.setState({
      activeComponent: this.STEPS.CHILD_PRODUCT_GROUPS,
      currentActiveParentCategoryId: parentProductGroup.id,
    });
  }

  categorizeProducts(productGroup) {
    const metallicProducts = [];
    const nonMetallicProducts = [];

    productGroup.products.forEach((product) => {
      if (product.is_metallic) {
        metallicProducts.push(product);
      } else {
        nonMetallicProducts.push(product);
      }
    });

    this.setState({
      activeComponent: this.STEPS.PRODUCTS,
      metallicProducts,
      nonMetallicProducts,
    });
  }

  handleBackButtonClick(parentCategoryId = null) {
    const { currentActiveParentCategoryId } = this.state;

    if (currentActiveParentCategoryId) {
      this.selectProductGroupById(currentActiveParentCategoryId);
    } else {
      this.props.goToBnlProductGroups();
    }

    // TODO: handle additional cases
  }

  renderAllProducts() {
    const products = [];
    let firstProductIsParent = false;

    const { nonMetallicProducts } = this.state;
    const { displayPrice, selectShootProduct, selectedProductGroup } =
      this.props;

    if (
      nonMetallicProducts.length > 0 &&
      nonMetallicProducts[0].product_type === 'parent_product'
    ) {
      firstProductIsParent = true;
    }

    nonMetallicProducts.forEach((product, i) => {
      if (firstProductIsParent && product.product_type === 'child_product') {
        // do nothing
      } else {
        products.push(
          <BnlOrderProduct
            displayPrice={displayPrice}
            key={`order-product-${i}`}
            name={`order-products-${i}`}
            product={product}
            selectShootProduct={selectShootProduct}
          />,
        );
      }
    });

    return (
      <div>
        <div
          className="bnl-product-group__back-button"
          onClick={this.handleBackButtonClick}
          // onClick={this.props.goToBnlProductGroups}
        >
          {'<  Back'}
        </div>
        <div className="clearfix">
          <h2 className="bnl-product-groups__header">
            {this.props.selectedProductGroup.name}
          </h2>
        </div>
        <ul className="row equal-height">{products}</ul>
      </div>
    );
  }

  renderCategorizedProducts() {
    let products;

    if (this.state.category === 'metallic') {
      products = this.state.metallicProducts;
    } else {
      products = this.state.nonMetallicProducts;
    }

    const categorizedProducts = products.map((product, i) => (
      <BnlOrderProduct
        displayPrice={this.props.displayPrice}
        key={`order-product-${i}`}
        name={`order-products-${i}`}
        product={product}
        selectShootProduct={this.props.selectShootProduct}
      />
    ));

    const metallicSelected =
      this.state.category === 'metallic'
        ? 'bnl-product-groups__categories__category--selected'
        : '';
    const nonMetallicSelected =
      this.state.category === 'nonMetallic'
        ? 'bnl-product-groups__categories__category--selected'
        : '';

    return (
      <div>
        <div
          className="bnl-product-group__back-button"
          onClick={this.props.goToBnlProductGroups}
        >
          {'<  Back'}
        </div>
        <div className="clearfix">
          <h2 className="bnl-product-groups__header bnl-product-groups__header--categorized">
            {this.props.selectedProductGroup.name}
          </h2>
          <div className="bnl-product-groups__categories clearfix">
            <div
              className={`bnl-product-groups__categories__category ${nonMetallicSelected}`}
              onClick={() => this.setCategory('nonMetallic')}
            >
              Basic Paper
            </div>
            <div
              className={`bnl-product-groups__categories__category ${metallicSelected}`}
              onClick={() => this.setCategory('metallic')}
            >
              Metallic Paper
            </div>
          </div>
        </div>
        <ul className="row equal-height">{categorizedProducts}</ul>
      </div>
    );
  }

  renderProductGroupProducts() {
    if (this.state.metallicProducts.length === 0) {
      return this.renderAllProducts();
    }
    return this.renderCategorizedProducts();
  }

  renderProductGroups() {
    const { shootProductGroups } = this.props;
    const maxIndex = shootProductGroups.length - 1;

    const groups = shootProductGroups.reduce(
      (displayedGroups, shootProductGroup, i) => {
        const hierarchyLabel = shootProductGroup.hierarchy_label;
        const categoryType = shootProductGroup.category_type;

        if (hierarchyLabel !== 'child' && categoryType !== 'nth_free') {
          let className =
            'bnl-product-group bnl-product-group--small col-xs-12 col-md-6 col-lg-6 cursor-pointer';

          if (i === 0) {
            className =
              'bnl-product-group bnl-product-group--top col-xs-12 col-md-12 col-lg-12 cursor-pointer';
          } else if (
            i === maxIndex &&
            i !== 2 &&
            i % 2 !== 0 &&
            shootProductGroup.display_type !== 'small'
          ) {
            className =
              'bnl-product-group bnl-product-group--bottom col-xs-12 col-md-12 col-lg-12 cursor-pointer';
          }

          displayedGroups.push(
            <BnlOrderProductGroup
              cartIsNotEmpty={this.props.cartIsNotEmpty}
              className={className}
              goToBnlCart={this.props.goToBnlCart}
              goToDashboard={this.props.goToDashboard}
              key={`order-product-group-${i}`}
              name={`order-product-group-${i}`}
              selectProductGroup={this.selectProductGroup}
              shootProductGroup={shootProductGroup}
              topProduct={i === 0}
            />,
          );
        }
        return displayedGroups;
      },
      [],
    );

    return <ul className="bnl-product-groups row">{groups}</ul>;
  }

  renderChildProductGroups() {
    const { currentActiveParentCategoryId } = this.state;
    const { shootProductGroups } = this.props;
    const maxIndex = shootProductGroups.length - 1;

    const groups = shootProductGroups.reduce(
      (displayedGroups, shootProductGroup, i) => {
        const parentId = shootProductGroup.parent_category_id;

        if (parentId === currentActiveParentCategoryId) {
          let className =
            'bnl-product-group bnl-product-group--small col-xs-12 col-md-6 col-lg-6 cursor-pointer';

          if (i === 0) {
            className =
              'bnl-product-group bnl-product-group--top col-xs-12 col-md-12 col-lg-12 cursor-pointer';
          } else if (
            i === maxIndex &&
            i !== 2 &&
            i % 2 !== 0 &&
            displayedGroups.length % 2 === 0 &&
            shootProductGroup?.display_type !== 'small'
          ) {
            className =
              'bnl-product-group bnl-product-group--bottom col-xs-12 col-md-12 col-lg-12 cursor-pointer';
          }

          displayedGroups.push(
            <BnlOrderProductGroup
              cartIsNotEmpty={this.props.cartIsNotEmpty}
              className={className}
              goToBnlCart={this.props.goToBnlCart}
              goToDashboard={this.props.goToDashboard}
              key={`order-product-group-${i}`}
              name={`order-product-group-${i}`}
              selectProductGroup={this.selectProductGroup}
              shootProductGroup={shootProductGroup}
              topProduct={i === 0}
            />,
          );
        }

        return displayedGroups;
      },
      [],
    );

    return (
      <div>
        <div
          className="bnl-product-group__back-button"
          onClick={this.props.goToBnlProductGroups}
        >
          {'<  Back'}
        </div>
        <div className="clearfix">
          <h2 className="bnl-product-groups__header">
            {this.props.selectedProductGroup.name}
          </h2>
        </div>
        <ul className="bnl-product-groups row">{groups}</ul>
      </div>
    );
  }

  renderActiveComponent() {
    const { activeComponent } = this.state;

    switch (activeComponent) {
      case this.STEPS.PRODUCT_GROUPS:
        return this.renderProductGroups();
      case this.STEPS.CHILD_PRODUCT_GROUPS:
        return this.renderChildProductGroups();
      case this.STEPS.PRODUCTS:
        return this.renderProductGroupProducts();
      default:
        return this.renderProductGroups();
    }
  }

  render() {
    return (
      <div className="bnl-product-groups-container">
        {this.renderActiveComponent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderNavigation: state.orderNavigation,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(BnlOrderProductGroups);
