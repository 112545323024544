import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentUser } from '../../actions/action_auth';
import { addStudent } from '../../actions/action_parent';
import { getGalleries } from '../../actions/action_gallery';

// React.js components
import BnlParentAddStudentForm from './bnl/BnlParentAddStudentForm';

class ParentAddStudentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      firstName: '',
      grade: '',
      homeRoom: '',
      lastName: '',
      teacherLastName: '',
      teacherSalutation: '',
      submitting: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (!this.state.submitting) {
      const that = this;
      const data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      };

      this.setState(
        {
          errors: {},
          submitting: true,
        },
        () => {
          this.props.addStudent(data).then((response) => {
            that.setState({ submitting: false }, () => {
              if (response.payload.data) {
                if (response.payload.data.errors) {
                  that.setState({ errors: response.payload.data.errors });
                } else if (that.props.company.name === 'geskusprint') {
                  const { students } = this.props.parent;
                  const student_ids = students.map((student, i) => student.id);
                  if (!that.props.bnlPreShoot.preShoot && student_ids) {
                    that.props.getGalleries({ student_ids });
                  }
                  that.props.history.push('/select-student');
                }
              }
            });
          });
        },
      );
    }
  }

  renderHeader() {
    return (
      <div className="col-xs-12 col-md-4 col-md-offset-4">
        <div className="add-student__header--geskus text-center">Enter</div>
        <p className="text-center add-student__header-description">
          The student's first and last name
          <br />
          as it would appear in school records
        </p>
      </div>
    );
  }

  getBgColor() {
    return 'bg--light-blue';
  }

  renderForm() {
    const { errors } = this.state;
    const firstNameErrorClassName = errors.firstName ? '' : 'hidden';
    const lastNameErrorClassName = errors.lastName ? '' : 'hidden';
    const studentErrorClassName = errors.student ? '' : 'hidden';
    const bgColor = this.getBgColor();

    switch (this.props.company.name) {
      case 'geskusprint':
        return (
          <div>
            <div className="row">{this.renderHeader()}</div>
            <form onSubmit={this.handleOnSubmit} className="add-student-form">
              <div className="row">
                <div className="col-xs-12 col-md-4 col-md-offset-4">
                  <input
                    id="first-name"
                    className="add-student-form__input border--round"
                    name="firstName"
                    onChange={this.handleOnChange}
                    value={this.state.firstName}
                    placeholder="First Name"
                  />
                  <div className={firstNameErrorClassName}>
                    {errors.firstName}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-4 col-md-offset-4">
                  <input
                    id="last-name"
                    className="add-student-form__input border--round"
                    name="lastName"
                    onChange={this.handleOnChange}
                    value={this.state.lastName}
                    placeholder="Last Name"
                  />
                  <div className={lastNameErrorClassName}>
                    {errors.lastName}
                  </div>
                  <div className={studentErrorClassName}>{errors.student}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-4 col-md-offset-4">
                  <input
                    className={`add-student-form__button button--round-border ${bgColor} center-block`}
                    value="Add Student"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        );
      case 'bnlschoolpictures':
      case 'jellyjarphotography':
      case 'grynnandbarrett':
        return <BnlParentAddStudentForm history={this.props.history} />;
      default:
        return <BnlParentAddStudentForm history={this.props.history} />;
    }
  }

  componentWillReceiveProps(nextProps) {
    // redirect to signup step 2 when directly accessing "//add-student"
    // if current user is truthy but hasn't completed signup
    if (nextProps.auth.currentUserId && !nextProps.auth.signupCompleted) {
      this.props.history.replace('/signup/step-2');
    }
  }

  render() {
    if (!this.props.auth.currentUserId || !this.props.auth.signupCompleted) {
      return <div id="loading" />;
    }

    const { errors } = this.state;
    const firstNameErrorClassName = errors.firstName ? '' : 'hidden';
    const lastNameErrorClassName = errors.lastName ? '' : 'hidden';
    const studentErrorClassName = errors.student ? '' : 'hidden';
    const bgColor = this.getBgColor();

    return <div className="add-student">{this.renderForm()}</div>;
  }

  componentDidMount() {
    this.setState({ submitting: false });
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    company: state.company,
    parent: state.parent,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentUser,
      addStudent,
      getGalleries,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParentAddStudentForm);
