import React, { Component } from 'react';
import { connect } from 'react-redux';

class BnlParentClickAndSafe extends Component {
  renderWaterMarkedImage(watermarkedImage) {
    return (
      <a
        className="watermarked-image"
        href={watermarkedImage.url}
        target="_blank"
        download
      >
        <img className="watermarked-image__image" src={watermarkedImage.url} />
        <div className="watermarked-image__download-btn">Download</div>
      </a>
    );
  }

  renderSafetyIdCard(safetyCardImage) {
    return (
      <a
        className="safety-id-card"
        href={safetyCardImage.url}
        target="_blank"
        download="clickandsafe"
      >
        <img className="safety-id-card__card-image" src={safetyCardImage.url} />
        <div className="safety-id-card__download-btn">Download</div>
      </a>
    );
  }

  renderClickAndSafes() {
    let clickAndSafes = [];

    for (let cpmId in this.props.gallery.galleries) {
      let studentsGalleries = this.props.gallery.galleries[cpmId];

      for (let studentId in studentsGalleries) {
        let watermarkedImage;

        if (studentsGalleries[studentId]['watermarkedImages']) {
          watermarkedImage =
            studentsGalleries[studentId]['watermarkedImages'][0];
        }

        let safetyCardImage;
        if (
          studentsGalleries[studentId] &&
          studentsGalleries[studentId]['safetyIdCardImage']
        ) {
          safetyCardImage = studentsGalleries[studentId]['safetyIdCardImage'];
        }

        let images = [];

        if (watermarkedImage) {
          images.push(this.renderWaterMarkedImage(watermarkedImage));
        }

        if (safetyCardImage) {
          images.push(this.renderSafetyIdCard(safetyCardImage));
        }

        clickAndSafes.push(
          <li className="click-and-safe__template equal-height">{images}</li>
        );
      }
    }

    return <ul className="click-and-safe__templates">{clickAndSafes}</ul>;
  }

  render() {
    return (
      <div className="click-and-safe">
        <h3 className="click-and-safe__header">Click & Safe</h3>
        <div className="click-and-safe__content-container">
          <h4 className="click-and-safe__subheader">
            Designed to fit in your wallet or billfold, the Safety ID card
            contains important photo & description that law enforcement can use
            for a rapid response.
          </h4>
          {this.renderClickAndSafes()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gallery: state.gallery,
  };
}

export default connect(mapStateToProps, null)(BnlParentClickAndSafe);
