import React from 'react';
import PropTypes from 'prop-types';
import { Header, PrimaryButton, Wrapper } from '../v3Styles';
import BackLink from '../shared/BackLink';

const UnsubscribeMessage = ({ history }) => {
  const handleClick = () => history.push('/dashboard');
  return (
    <Wrapper>
      <BackLink />
      <Wrapper>
        <Header>You have been unsubscribed from all emails.</Header>
        <PrimaryButton onClick={handleClick}>
          Return to My Galleries
        </PrimaryButton>
      </Wrapper>
    </Wrapper>
  );
};

UnsubscribeMessage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default UnsubscribeMessage;
