import React, { Component } from 'react';

import OrderBuildYourOwnSlot from './order_build_your_own_slot';

class OrderBuildYourOwnDesktopSlots extends Component {
  renderSlots() {
    let slots = [];

    for (var i = 0; i < this.props.SLOTS_COUNT; i++) {
      slots.push(
        <OrderBuildYourOwnSlot
          configuration={this.props.configuration}
          display={'desktop'}
          freeIndices={this.props.freeIndices}
          key={`byo-slot-${i}`}
          productIsBnlYouPickX={this.props.productIsBnlYouPickX}
          removeConfiguredChildItem={this.props.removeConfiguredChildItem}
          selected={this.props.slotIndex === i}
          selectedChildItem={this.props.selectedChildItem}
          selectedShootProduct={this.props.selectedShootProduct}
          slotIndex={i}
        />,
      );
    }

    return slots;
  }

  renderButton() {
    let message = '';
    let fn = null;
    let className = 'byo-button disabled--bg-grey';
    if (
      this.props.canBeAdded &&
      (this.props.activeComponent === this.props.STEPS['personalization'] ||
        this.props.activeComponent === this.props.STEPS['products'] ||
        this.props.activeComponent === this.props.STEPS['sms'] ||
        this.props.activeComponent === this.props.STEPS['none'])
    ) {
      className = 'byo-button';
    }

    return (
      <div
        className={`${className} button--full-width bg--bnl-light-blue border--round`}
        onClick={this.props.addConfiguredChildItemsToCart}
      >
        Done, Add To Cart
      </div>
    );
  }

  render() {
    return (
      <div className="col-xs-12 col-md-4 col-lg-4">
        <ul className="clearfix">{this.renderSlots()}</ul>
        {this.renderButton()}
      </div>
    );
  }
}

export default OrderBuildYourOwnDesktopSlots;
