import React from 'react';
import { useFormState } from 'react-hook-form';
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextArea,
  ProductForm,
} from './requiredProductFormStyles';

const RequiredProductForm = ({ product, control, register }) => {
  const { errors } = useFormState({ control });

  if (
    !product.required_product_fields ||
    product.required_product_fields.length <= 0
  ) {
    return null;
  }

  const renderInput = (field) => {
    const { name, placeholder, field_type: fieldType, label } = field;
    const inputFields = {
      placeholder,
      name,
    };
    switch (fieldType) {
      case 'text':
        return (
          <FormTextArea
            {...inputFields}
            {...register(name, { required: `${label} is required` })}
          />
        );
      case 'date':
        return (
          <FormInput
            {...inputFields}
            type="date"
            {...register(name, { required: `${label} is required` })}
          />
        );
      case 'boolean':
        return (
          <FormSelect
            {...inputFields}
            {...register(name, { required: `${label} is required` })}
          >
            <option value>true</option>
            <option value={false}>false</option>
          </FormSelect>
        );
      case 'string':
        return (
          <FormInput
            {...inputFields}
            type="text"
            {...register(name, { required: `${label} is required` })}
          />
        );
      case 'integer':
        return (
          <FormInput
            type="number"
            step="1"
            {...register(name, { required: `${label} is required` })}
          />
        );
      case 'decimal':
        return (
          <FormInput
            type="number"
            step="0.01"
            {...register(name, { required: `${label} is required` })}
          />
        );
      default:
        return (
          <FormInput
            {...inputFields}
            type={fieldType}
            {...register(name, { required: `${label} is required` })}
          />
        );
    }
  };

  return (
    <div>
      <ProductForm>
        {product.required_product_fields.map((field) => (
          <div key={`${field.key}-${field.field_type}`}>
            <FormLabel
              isInline={field.field_type === 'string'}
            >{`${field.label}:`}</FormLabel>
            {renderInput(field)}
            {/* <Controller
              name={field.name}
              control={control}
              defaultValue=""
              render={() => renderInput(field)}
              rules={
                field.field_type !== 'string' && {
                  required: `${field.label} is required.`,
                }
              }
            /> */}
            {errors[field.name] && (
              <p className="error--red">{errors[field.name].message}</p>
            )}
          </div>
        ))}
      </ProductForm>
    </div>
  );
};

export default RequiredProductForm;
