import React from 'react';
import formatUSD from '../../../utils/formatUSD';
import styled from 'styled-components';
import cart from '../../../utils/cart';

const PriceDisplay = styled.div`
  font-weight: 900;
`;

const CartItemPrice = ({ cartItem }) => {
  const price = cart.determineCartItemPrice(cartItem);

  return <PriceDisplay>{price === 0 ? 'Free' : formatUSD(price)}</PriceDisplay>;
};

export default CartItemPrice;
