import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cartUtils from '../../utils/cart';
import cart from '../../utils/cart';

const PreCartOffers = ({ children, history }) => {
  const [checksComplete, setChecksComplete] = useState(false);

  const selectedStudent = useSelector(
    (state) => state.additionalData.selectedStudent,
  );
  const orders = useSelector((state) => state.v3Order.orders);
  const prepayPersonalizationOfferType = useSelector(
    (state) => state.shoot.prepayPersonalizationOfferType,
  );
  const incentiveProducts = useSelector(
    (state) => state.shoot.incentiveProducts,
  );

  const { preCartOfferProducts, triggersPreCartOfferProducts } = useSelector(
    (state) => state.shoot,
  );
  const eventIsPrepay = useSelector((state) => state?.shoot?.shoot?.is_prepay);

  useEffect(() => {
    const currentOrder = orders[selectedStudent?.id];
    const cartItems = currentOrder?.cartItems || [];
    const shouldPromptForPersonalization =
      currentOrder?.personalization?.shouldPrompt;
    const cartContainsPersonalizableProducts =
      cartUtils.cartContainsPersonalizableProducts(cartItems);
    const eligibleIncentivesForCart = cartUtils.eligibleIncentivesForCart(
      orders,
      incentiveProducts,
    );
    const eligibleIncentivesForOrder = cartUtils.eligibleIncentivesForOrder(
      cartItems,
      incentiveProducts,
    );

    const preCartOfferProductsToPrompt = cartUtils.preCartOfferProductsToPrompt(
      currentOrder,
      preCartOfferProducts,
      triggersPreCartOfferProducts,
    );

    const cartContainsNonIncentiveProducts =
      cartUtils.cartContainsNonIncentiveProducts(cartItems);

    if (
      cartContainsNonIncentiveProducts &&
      (eligibleIncentivesForCart?.length > 0 ||
        eligibleIncentivesForOrder?.length > 0)
    ) {
      console.log('pushing to incentives config from cart-based check');
      history.push('/v3/incentives/config');
    } else if (
      eventIsPrepay &&
      prepayPersonalizationOfferType ===
        'prepay_personalization_prompted_before_checkout_only' &&
      cartContainsPersonalizableProducts &&
      shouldPromptForPersonalization
    ) {
      if (eventIsPrepay === false) {
        // TODO: revist this logic
        // Don't show the personalization offer if the event is not prepay
        setChecksComplete(true);
      } else {
        history.push('/v3/personalization-offer');
      }
    } else if (preCartOfferProductsToPrompt.length > 0) {
      history.push('/v3/pre-cart-offers');
    } else {
      setChecksComplete(true);
    }
  }, [
    orders,
    prepayPersonalizationOfferType,
    selectedStudent,
    incentiveProducts,
    preCartOfferProducts,
  ]);

  if (checksComplete) {
    return children;
  }

  // TODO: render loading spinner
  return <div>Loading...</div>;
};

export default PreCartOffers;
