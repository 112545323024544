import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// constants files
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../constants/companies';

class OrderConfirmation extends Component {
  getBnlText() {
    // if (this.props.bnlPreShoot.preShoot) {
    //   return 'Please fill out the top portion of the picture day flyer and write your order ID in the red box provided. Alternatively, you may have your child bring a copy of the emailed receipt.';
    // }
    return 'Thank you for your order! You will receive an email shortly with your order details.';
  }

  renderContent() {
    const geskusTopPadding = { marginTop: '16px' };

    switch (this.props.companyAuthToken) {
      case GESKUS:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">Thank You!</div>
            <div className="order-confirmation__subheader">
              <p>Your order has been received.</p>
              <p style={geskusTopPadding}>
                You will receive a receipt of your order via email within the
                next few hours.
              </p>
            </div>
            <div className="order-confirmation__home-button">
              <Link
                to="/dashboard"
                className="button bg--dark-blue display--block"
              >
                Account Home
              </Link>
            </div>
          </div>
        );
      case BNL:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 732-414-2115 or Live Chat at{' '}
              <a
                className="order-confirmation__bnl-link"
                href="https://bnlschoolpictures.com/"
              >
                www.bnlschoolpictures.com
              </a>
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case GB:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: (860) 265-1440
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case JELLYJAR:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 732-252-8067
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case NORMANDY:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 908-755-0431
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case TIPPING:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 610-359-8846
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case MARCEL:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 800.794.2279
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case CLASSACT:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 718.338.2322
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case WAGNER:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 314.567.5900
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      case BIELMAR:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">
              Thank you for your order!
            </div>
            <div className="order-confirmation__subheader">
              Your order ID is: <code>{this.props.orderContainerOrderId}</code>
            </div>
            <div className="order-confirmation__instruction">
              {this.getBnlText()}
              <br />
              <br />
              If you have any questions, please feel free to contact us!
              <br />
              <br />
              Reach us at: 518-792-7919
            </div>
            <div className="order-confirmation__home-button--bnl">
              <Link to="/dashboard" className="bnl-button--light-blue ">
                Account Home
              </Link>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <div className="order-confirmation__company-logo" />
            <div className="order-confirmation__header">Thank You!</div>
            <div className="order-confirmation__subheader">
              Email confirmation is on the way
            </div>
            <div className="order-confirmation__home-button">
              <Link
                to="/dashboard"
                className="button bg--dark-blue display--block"
              >
                Account Home
              </Link>
            </div>
          </div>
        );
    }
  }

  render() {
    return <div className="order-confirmation">{this.renderContent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    bnlPreShoot: state.bnlPreShoot,
  };
}

export default connect(mapStateToProps, null)(OrderConfirmation);
