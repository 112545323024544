import {
  RESET_GESKUS_INCENTIVE,
  UPDATE_GESKUS_FREE_SHIPPING,
  UPDATE_CART_LEVEL_ITEMS,
} from '../actions/action_geskus_incentive';

const INITIAL_STATE = {
  freeShipping: false,
  cartLevelItems: [],
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case UPDATE_GESKUS_FREE_SHIPPING:
      return { ...state, freeShipping: action.payload };
    case UPDATE_CART_LEVEL_ITEMS:
      return { ...state, cartLevelItems: action.payload };
    case RESET_GESKUS_INCENTIVE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
