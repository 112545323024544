import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BackLinkWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: left;
`;

export const UtilityLink = styled.a`
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #4a4a4a;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const PrimaryLink = styled.a`
  color: #479de6;
  cursor: pointer;
  text-decoration: underline;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #479de6;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StudentAssignmentSection = styled.div`
  padding-top: 12px;
  border-top: 1px solid #d3d3d3;
`;

export const QuestionTitle = styled.div`
  cursor: pointer;
  width: 100%;
  ${
    '' /* font-style: italic;
  color: #007bff; */
  }
  margin-top: -6px;
  margin-bottom: 10px;
`;

export const AnswerSection = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: #f2f2f2;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 12px;
  width: 100%;
  text-align: left;
  ul {
    list-style-type: disc;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 12px;
  }
  li {
    margin-bottom: 6px;
    padding-bottom: 0;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

export const Button = styled.button`
  background-color: #479de6; // your primary color
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;

  &:hover {
    background-color: #357abd; // a darker shade for hover
  }
`;

export const GalleryResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const StudentAssignmentWrapper = styled.div``;

export const AssignStudentButton = styled(Button)``;

export const StudentAssignedMessage = styled.div`
  font-style: italic;
`;

export const BackLink = styled(Link)`
  display: inline-block;
  color: #479de6;
  margin-bottom: 12px;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  margin-top: 115px;
`;

export const Header = styled.h1`
  margin-bottom: 16px;
  padding-bottom: 0;
`;

export const ResultCard = styled.div`
  display: block;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  border: 1px solid ${(props) => (props.selected ? 'blue' : 'transparent')}; // Change color on selection
  border: ${(props) =>
    props.selected ? '2px solid #479DE6' : '1px solid #ccc'};
  padding: ${(props) => (props.selected ? '23px' : '24px')}; // Adjust padding
  ${
    '' /* &:hover {
    cursor: pointer;
    border: ${(props) =>
      props.selected ? '2px solid #479DE6' : '2px solid #ccc'};
    padding: 23px;
  } */
  }
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const Title = styled.p`
  margin-bottom: 16px;
  padding-bottom: 0;
  text-align: left;
  width: 100%;
`;

export const SearchDetails = styled.p`
  margin-bottom: 10px;
  padding-bottom: 0;
  text-align: left;
  width: 100%;
  color: #666;
  font-size: 13px;
  ${'' /* color: #4a4a4a */}
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px; // added margin for spacing
`;

export const VenueButton = styled(Button)`
  margin-top: 8px;
`;

export const BackButton = styled(Link)`
  display: block;
  background-color: #479de6; // your primary color
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #357abd; // a darker shade for hover
    color: white;
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Align items to the start of the container
`;

export const ResultsSection = styled.div`
  margin-top: 20px;
`;

export const StudentName = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #479de6;
`;

export const EventName = styled.p`
  font-weight: 600;
  margin-bottom: 0;
`;

export const EventYear = styled.p`
  margin-bottom: 0;
`;

export const VenueName = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #479de6;
`;

export const GalleryPassword = styled.p`
  margin-bottom: 0;
`;

export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  ${'' /* margin-top: 16px; */}
  margin-bottom: 24px;
`;

export const GalleryImage = styled.img`
  max-height: 225px;
  max-width: 180px; /* 8/10 of 225px for larger screens */
  width: auto;
  height: auto;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // subtle drop shadow

  @media (max-width: 768px) {
    max-width: 135px; /* 8/10 of 225px for smaller screens */
  }
`;

export const AssignSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 36px;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const Dropdown = styled.select`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const StyledInput = styled(Input)`
  width: 100%; // Adjust width to fill the wrapper
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px; // added margin for spacing
`;

export const SuccessMessage = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 12px 20px;
  margin-bottom: 24px;
  border-radius: 4px;
  border: 1px solid transparent;
`;
