import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';

class OrderShootConfirmation extends Component {
  render() {
    const confirmButtonStyle = {
      display: 'block',
    };

    return (
      <div className="order-shoot-key-confirmation">
        <div className="order-shoot-key-confirmation__header">
          Please confirm that {this.props.studentName} attends{' '}
          {this.props.shootName}
        </div>
        <button
          className="button bg--dark-blue"
          style={confirmButtonStyle}
          onClick={this.props.confirmShoot}
        >
          Confirm
        </button>
        <div
          className="order-shoot-key-confirmation__go-back cursor-pointer"
          onClick={this.props.renderPreviousComponent}
        >
          Cancel And Go Back
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.props.updateOrderActiveComponent(
      ORDER_NAVIGATION['SHOOT_CONFIRMATION']
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(null, mapDispatchToProps)(OrderShootConfirmation);
