import React, { Component } from 'react';
import { connect } from 'react-redux';

// React.js components
import BnlOrderProductConfigurationBackgroundsSelector from '../bnl/bnl_order_product_configuration_backgrounds_selector';

class OrderBuildYourOwnBackgrounds extends Component {
  renderPose() {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
      bnlPreShoot,
      configuration,
      selectedPose,
      slotIndex,
    } = this.props;

    if (!bnlPreShoot.preShoot) {
      const pose = selectedPose || configuration[slotIndex].pose;

      let poseUrl = '';

      if (!bnlPreShoot.preShoot) {
        // Handle Geskus Post-Event
        // TODO: revisit
        if (isGeskus && isPostEvent && studentPreviewImageUrl) {
          poseUrl = studentPreviewImageUrl;
        } else {
          poseUrl = pose.url;
        }

        const poseStyle = {
          backgroundImage: `url("${poseUrl}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        return <div className="byo__background-pose" style={poseStyle} />;
      }
    }

    return null;
  }

  renderAllBackgroundOptions(mobile) {
    const options = [];
    const that = this;

    this.props.shoot.backgroundOptions.forEach((backgroundImage) => {
      const bgId = backgroundImage.id;
      const option = this.props.formattedBackgroundOptions[bgId];
      const bgStyle = {
        backgroundImage: `url("${backgroundImage.image_thumbnail}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      let selected = '';
      let onClickHandler = this.props.selectBackground.bind(this, option);
      const bgClass = '';

      if (mobile) {
        onClickHandler = this.props.selectMobileBackground.bind(this, option);
      }

      if (
        that.props.configuration[that.props.slotIndex].backgroundCodes &&
        that.props.configuration[that.props.slotIndex].backgroundCodes[0] ===
          option.id
      ) {
        selected = 'bnl-product-config__bg--selected';
      }

      const bgName = backgroundImage.display_name || '';
      const bgPrice =
        backgroundImage.price > 0
          ? this.props.displayPrice(backgroundImage.price).split('.')[0]
          : '';

      options.push(
        <li
          key={`bg-${bgId}`}
          className="bnl-product-config__bg-container col-xs-4 col-md-2 col-lg-2 cursor-pointer"
        >
          <div
            className={`bnl-product-config__bg ${selected}`}
            onClick={onClickHandler}
            style={bgStyle}
          >
            {this.renderPose()}
          </div>
        </li>,
      );
    });

    const htmlElements = <ul className="row equal-height">{options}</ul>;
    const data = {
      all: {
        name: null,
        htmlElements,
      },
    };

    return (
      <BnlOrderProductConfigurationBackgroundsSelector
        containsBackgroundCategories={
          this.props.shoot.containsBackgroundCategories
        }
        data={data}
      />
    );
  }

  renderCategorizedBackgroundOptions(mobile) {
    const data = {};
    const that = this;

    this.props.shoot.backgroundOptions.forEach((backgroundOption) => {
      const backgrounImages = backgroundOption.background_images.map(
        (backgroundImage) => {
          const bgId = backgroundImage.id;
          const option = this.props.formattedBackgroundOptions[bgId];
          const bgStyle = {
            backgroundImage: `url("${backgroundImage.image_thumbnail}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          };
          let selected = '';
          let onClickHandler = this.props.selectBackground.bind(this, option);
          const bgClass = '';

          if (mobile) {
            onClickHandler = this.props.selectMobileBackground.bind(
              this,
              option,
            );
          }

          if (
            that.props.configuration[that.props.slotIndex].backgroundCodes &&
            that.props.configuration[that.props.slotIndex]
              .backgroundCodes[0] === option.id
          ) {
            selected = 'bnl-product-config__bg--selected';
          }

          const bgName = backgroundImage.display_name || '';
          const bgPrice =
            backgroundImage.price > 0
              ? this.props.displayPrice(backgroundImage.price).split('.')[0]
              : '';

          let bgClassName = '';

          if (
            option.display_name &&
            option.display_name.split(' ').length > 1
          ) {
            bgClassName = option.display_name
              .split(' ')
              .slice(1)
              .map((word) => word.toLowerCase())
              .join('_');
          }

          return (
            <li
              key={`bg-${bgId}`}
              className="bnl-product-config__bg-container col-xs-4 col-md-2 col-lg-2 cursor-pointer"
            >
              <div
                className={`bnl-product-config__bg bnl-product-config__bg--${bgClassName} ${selected}`}
                onClick={onClickHandler}
                style={bgStyle}
              >
                {this.renderPose()}
              </div>
              <div className="bnl-bg-text text-center">
                {bgName} {bgPrice}
              </div>
            </li>
          );
        },
      );

      const htmlElements = (
        <ul className="row equal-height">{backgrounImages}</ul>
      );

      data[backgroundOption.id] = {
        id: backgroundOption.id,
        order: backgroundOption.order,
        name: backgroundOption.name,
        htmlElements,
      };
    });

    return (
      <BnlOrderProductConfigurationBackgroundsSelector
        containsBackgroundCategories={
          this.props.shoot.containsBackgroundCategories
        }
        data={data}
      />
    );
  }

  renderBackgroundOptions(mobile = false) {
    if (!this.props.shoot.containsBackgroundCategories) {
      return this.renderAllBackgroundOptions(mobile);
    }
    return this.renderCategorizedBackgroundOptions(mobile);
  }

  render() {
    return (
      <div className="byo__config--backgrounds col-xs-12 col-md-8 col-lg-8">
        {this.renderBackgroundOptions()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlPreShoot: state.bnlPreShoot,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(OrderBuildYourOwnBackgrounds);
