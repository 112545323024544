import React from 'react';
import IncentiveTracker from '../incentives/IncentiveTracker';
import BannerMessage from '../banner/BannerMessage';

const V3Layout = ({ children, showStudentBanner }) => {
  const loading = 'hidden';

  return (
    <div className={`order-container margin-for-incentive-tracker`}>
      <div className={loading}>
        <div className="spinner" />
      </div>
      <IncentiveTracker />
      <BannerMessage showStudentBanner={showStudentBanner} />
      {children}
    </div>
  );
};

export default V3Layout;
