export const domainToCompanyToken = {
  geskus: 'geskus',
  jellyjarphotography: 'jellyjar',
  grynnandbarrett: 'gb',
  bnlschoolpictures: 'bnl',
  normandy: 'normandy',
  tippingschoolpictures: 'tipping',
  marcelstudios: 'marcel',
  classact: 'classact',
  wagner: 'wagner',
  bielmar: 'bielmar',
};
