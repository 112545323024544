import React, { Component } from 'react';

// React.js components
import Swal from 'sweetalert2';
import Dropdown from '../utils/dropdown';
import RadioButtons from '../utils/radio_buttons';

class OrderProductConfigurationWithBg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configuringBackground: 'backgroundCodes',
      hoverUrl: '',
    };

    this.addConfiguredItemToCart = this.addConfiguredItemToCart.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.toggleConfiguringBackground =
      this.toggleConfiguringBackground.bind(this);
  }

  componentDidMount() {
    console.log('component mounted');
    console.log(this.props);
  }

  mouseOver(url) {
    this.setState({ hoverUrl: url });
  }

  mouseOut() {
    this.setState({ hoverUrl: '' });
  }

  toggleConfiguringBackground() {
    let configuringBackground;

    if (this.state.configuringBackground === 'backgroundCodes') {
      configuringBackground = 'additionalBackgroundCodes';
    } else if (
      this.state.configuringBackground === 'additionalBackgroundCodes'
    ) {
      configuringBackground = 'backgroundCodes';
    }

    this.setState({ configuringBackground });
  }

  addConfiguredItemToCart() {
    if (this.props.allBackgroundOptionsSelected) {
      this.props.addConfiguredItemToCart(this.props.nextComponent);
    } else {
      Swal.fire('Please select required background(s)');
    }
  }

  renderHeader() {
    if (this.state.configuringBackground === 'backgroundCodes') {
      return 'Select Background(s)';
    }
    return 'Select Additional Background(s)';
  }

  renderSubheader() {
    if (
      this.state.configuringBackground === 'backgroundCodes' &&
      this.props.shootProduct.background_options_count > 1
    ) {
      return <div>Click on a selected background to unselect it</div>;
    }
    if (
      this.state.configuringBackground === 'additionalBackgroundCodes' &&
      thhis.props.shootProduct.additional_product_background_images_count > 1
    ) {
      return <div>Click on a selected background to unselect it</div>;
    }
  }

  renderBackgroundOptions() {
    const that = this;
    const itemId = this.props.shootProduct.id;
    const { configuringBackground } = this.state;
    const backgroundOptions = this.props.backgroundOptions.map((option, i) => {
      const selected = that.props.backgroundOptionSelected(
        option.id,
        configuringBackground,
      )
        ? 'order-background-option--selected'
        : '';
      const style = {
        backgroundImage: `url("${option.image_thumbnail}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return (
        <li
          className={`order-background-option cursor-pointer ${selected}`}
          key={`background-option-${i}`}
          onClick={() =>
            this.props.configureItem(itemId, configuringBackground, option)
          }
          onMouseOver={() => this.mouseOver(option.image_thumbnail)}
          onMouseOut={this.mouseOut}
          style={style}
        />
      );
    });

    return backgroundOptions;
  }

  showDisclaimerText() {
    const studentImage = this.props.getStudentImage();

    const disclaimerStyle = {
      fontStyle: 'italic',
    };

    if (studentImage.imageDisclaimer) {
      return (
        <div>
          <p style={disclaimerStyle}>{studentImage.imageDisclaimer}</p>
        </div>
      );
    }

    return null;
  }

  renderDemoStudent() {
    let selectedUrl = '';
    const lastCode =
      this.props.configuration[this.state.configuringBackground][
        this.props.configuration[this.state.configuringBackground].length - 1
      ];

    if (lastCode) {
      selectedUrl =
        this.props.formattedBackgroundOptions[lastCode].image_thumbnail;
    }

    const hoverClassName = this.state.hoverUrl ? '' : 'hidden';
    const url = this.state.hoverUrl || selectedUrl;
    const hoverBgStyle = {
      backgroundImage: `url("${url}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      float: 'left',
      position: 'absolute',
    };

    // determine student url depending on pre shoot or post shoot with image found
    let studentImage;

    if (this.props.selectedPose) {
      studentImage = this.props.selectedPose;
    } else {
      studentImage = this.props.getStudentImage();
    }

    let className;
    let studentImageUrl;
    let desc;

    className = studentImage.className;
    desc = studentImage.desc;

    const studentStyle = {
      backgroundImage: `url("${studentImage.url}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };

    return (
      <div className="order-options__demo col-lg-4 col-xs-12 col-md-4">
        <div className="order-options__demo-desc">{desc}</div>
        {this.showDisclaimerText()}
        <div className="order-options__demo-background" style={hoverBgStyle} />
        <div
          className={`order-options__demo-student ${className}`}
          style={studentStyle}
        />
      </div>
    );
  }

  renderQuantityDropdown() {
    const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
      <Dropdown
        className="order-options-qty__dropdown cursor-pointer"
        handleOnChange={this.props.handleOnChange}
        name="quantity"
        options={options}
        value={this.props.quantity}
      />
    );
  }

  renderMetallicOptionRadioButtons() {
    const options = {
      true: 'Yes, upgrade to metallic paper',
      false: "No, don't upgrade",
    };

    return (
      <RadioButtons
        className="order-options-metallic__dropdown"
        handleOnChange={this.props.handleOnChange}
        name="metallicOption"
        option={this.props.metallicOption}
        options={options}
      />
    );
  }

  renderButton() {
    // TODO:
    // disable button if not all backgorunds are selected
    if (
      this.props.shootProduct.additional_product_background_images_count === 0
    ) {
      return (
        <div
          className="button--full-width bg--dark-blue"
          onClick={this.addConfiguredItemToCart}
        >
          Done
        </div>
      );
    }
    if (
      this.props.shootProduct.additional_product_background_images_count > 0 &&
      this.state.configuringBackground === 'additionalBackgroundCodes'
    ) {
      return (
        <div>
          <div
            className="order-product-config-button button bg--dark-blue"
            onClick={this.toggleConfiguringBackground}
          >
            Confirm & Select Background(s)
          </div>
          <div
            className="order-product-config-button button bg--dark-blue"
            onClick={this.addConfiguredItemToCart}
          >
            Done
          </div>
        </div>
      );
    }
    return (
      <div
        className="order-product-config-button button bg--dark-blue"
        onClick={this.toggleConfiguringBackground}
      >
        Confirm & Select Additional Background(s)
      </div>
    );
  }

  renderSelectedBackgroundOptions(codes, count) {
    const { shootProduct } = this.props;
    const backgrounds = [];

    for (let i = 1; i <= count; i++) {
      if (i <= codes.length) {
        const code = codes[i - 1];
        const url = this.props.formattedBackgroundOptions[code].image_thumbnail;
        const style = {
          backgroundImage: `url("${url}")`,
          backgroundSize: 'cover',
        };

        backgrounds.push(
          <li
            style={style}
            className="order-background-option"
            key={`order-background-option-${i}`}
          />,
        );
      } else {
        backgrounds.push(
          <li
            className="order-background-option order-background-option--empty"
            key={`order-background-option-missing-${i}`}
          >
            {i}
          </li>,
        );
      }
    }

    return backgrounds;
  }

  renderSelectedPrimaryBackgroundOptions() {
    const codes = this.props.configuration[this.state.configuringBackground];
    const count = this.props.shootProduct.background_options_count;

    return (
      <div>
        <div>Selected Background(s)</div>
        <ul className="equal-height">
          {this.renderSelectedBackgroundOptions(codes, count)}
        </ul>
      </div>
    );
  }

  renderSelectedAdditionalBackgroundOptions() {
    const count =
      this.props.shootProduct.additional_product_background_images_count;

    if (count > 0) {
      const options = this.props.additionalSelectedBackgroundOptions;
      return (
        <div>
          <div>Selected Additional Background(s)</div>
          <ul className="equal-height">
            {this.renderSelectedBackgroundOptions(options, count)}
          </ul>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="product-config-bg row">
        <div className="product-config__info col-lg-4 col-xs-12 col-md-4">
          <div
            className="order-product-config__home-button cursor-pointer"
            onClick={this.props.goToProductGroups}
          >
            {'<  Back'}
          </div>
          <div className="product-config-info__desc clearfix">
            <div className="product-config-info__product-name">
              {this.props.shootProduct.name}
            </div>
            <div className="product-config-info__product-price">
              {this.props.displayPrice(this.props.shootProduct.price)}
            </div>
          </div>
          <div className="product-config-info__product-img-container clearfix">
            <img
              className="product-config-info__product-img"
              src={this.props.shootProduct.image_thumbnail}
            />
            <div className="product-config-info__product-desc-container">
              <div className="product-config-info__product-desc">
                {this.props.shootProduct.description}
              </div>
            </div>
          </div>
          {this.renderSelectedPrimaryBackgroundOptions()}
          {this.renderSelectedAdditionalBackgroundOptions()}
          {this.renderButton()}
        </div>
        {this.renderDemoStudent()}
        <div className="product-config__background-options col-lg-4 col-xs-12 col-md-4">
          <div className="order-background-options__header">
            {this.renderHeader()}
            {this.renderSubheader()}
          </div>
          <ul className="order-background-options__list equal-height">
            {this.renderBackgroundOptions()}
          </ul>
        </div>
      </div>
    );
  }
}

export default OrderProductConfigurationWithBg;
