/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfigScreen from './configScreen';

export const Background = ({ shoot }) => (
  <ConfigScreen
    instruction="Select a Background"
    itemsDesktop={6}
    itemsMobile={3}
    categories={shoot.backgroundOptions.map((b) => ({
      name: b.name,
      items: b.background_images,
    }))}
  />
);

const mapStateToProps = (state) => ({
  shoot: state.shoot,
});

export default withRouter(connect(mapStateToProps)(Background));
