import React from 'react';

const landingWrapper = {
  paddingTop: '25px',
};

const logoStyle = {
  position: 'relative',
  top: 0,
  left: 0,
  width: '200px',
  height: '150px',
};

const headerStyle = {
  marginTop: '30px',
};

const LandingWagner = (props) => {
  const { history, setActiveComponent } = props;

  return (
    <div className="gb-landing" style={landingWrapper}>
      <div className="gb-landing__top clearfix">
        <div className="gb-landing__top__left">
          <div className="strawbridge-landing__logo" alt="" style={logoStyle} />
          <h1 className="gb-landing__top__left__header" style={headerStyle}>
            Welcome
          </h1>
          <h3 className="gb-landing__top__left__subheader">
            To Strawbridge Studios online ordering!
          </h3>
          <h3 className="gb-landing__top__left__subheader">
            For the security of your child, you must create an account to place
            an order.
          </h3>
          <div
            className="gb-landing__signup-button cursor-pointer"
            onClick={() => history.push('/account-lookup', { step: 1 })}
          >
            Place Your Order
          </div>
        </div>
        <img
          className="gb-landing__top__image"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_main.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default LandingWagner;
