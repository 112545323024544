import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import Dropdown from '../utils/dropdown';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';

class OrderCart extends Component {
  constructor(props) {
    super(props);

    this.editQuantity = this.editQuantity.bind(this);
    this.goToCheckout = this.goToCheckout.bind(this);
  }

  componentDidMount() {
    // console.log('OrderCart mounted');
    // console.log(this.props);
  }

  componentWillUpdate() {
    // console.log('OrderCart updated');
    // console.log(this.props);
  }

  editQuantity(event, cartItem, orderState) {
    this.props.editQuantity(event, cartItem, orderState);
  }

  goToCheckout() {
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.CHECKOUT);
  }

  shootIsOutdoors(orderState) {
    const { shoot } = this.props;

    console.log(
      'ORDER_CART: shootIsOutdoors: ',
      shoot.shoot.background_choice_mode === 'outdoors_background_only',
    );

    return shoot.shoot.background_choice_mode === 'outdoors_background_only';
  }

  renderEditButton(cartItem, orderState) {
    if (
      orderState.backgroundChoiceMode ===
      'advanced_greenscreen_backgrounds_only'
    ) {
      return (
        <div
          onClick={() =>
            this.props.editBackground(cartItem, orderState.studentId)
          }
          className="cart-item-background__edit cursor-pointer"
        >
          Edit
        </div>
      );
    }

    return <div className="cart-item-background__edit" />;
  }

  renderBackgroundOptionsSection(cartItem, orderState) {
    if (!this.shootIsOutdoors(orderState)) {
      let text;
      if (cartItem.background_options_count === 1) {
        text = 'Background';
      } else {
        text = 'Backgrounds';
      }

      return (
        <div className="cart-item__background">
          <div className="cart-item-background__header">{text}:</div>
          <ul className="cart-item-background__options clearfix">
            {this.renderBackgroundOptions(cartItem, orderState)}
          </ul>
        </div>
      );
    }
    return <div className="cart-item__background" />;
  }

  renderBackgroundOptions(cartItem, orderState) {
    const that = this;

    const options = orderState.selectedBackgroundOptions[cartItem.id].codes.map(
      (code, i) => {
        const background = that.props.formattedBackgroundOptions[code];
        const style = {
          background: `url("${background.image_thumbnail}")`,
          backgroundSize: 'cover',
        };
        return (
          <div
            className="cart-item-background-option clearfix"
            key={`cart-background-option-${code}-${i}`}
          >
            <div className="cart-item-background__image" style={style} />
          </div>
        );
      },
    );

    return options;
  }

  renderAdditionalBackgroundOptions(cartItem, orderState) {
    if (cartItem.additional_product_background_images_count > 0) {
      const that = this;
      const options = orderState.additionalSelectedBackgroundOptions[
        cartItem.id
      ].codes.map((code, i) => {
        const background = that.props.formattedBackgroundOptions[code];
        const style = {
          background: `url("${background.image_thumbnail}")`,
          backgroundSize: 'cover',
        };
        return (
          <div
            className="cart-item-background-option clearfix"
            key={`cart-background-option-${code}-${i}`}
          >
            <div className="cart-item-background__image" style={style} />
          </div>
        );
      });

      return (
        <div className="cart-item__background">
          <div className="cart-item-background__header">
            Additional Background:
          </div>
          <ul className="cart-item-background__options clearfix">
            {[options]}
          </ul>
        </div>
      );
    }
  }

  renderYouPickXBackgroundOptions(cartItem, orderState) {
    // console.log('here is the order state');
    // console.log(orderState);

    const backgroundOptions = [];
    const youPickXSelectedBackgroundOptions =
      orderState.youPickXSelectedBackgroundOptions[cartItem.id];

    for (const childItemId in youPickXSelectedBackgroundOptions) {
      // console.log('childItemId');
      // console.log(childItemId);
      // console.log('youPickXSelectedBackgroundOptions');
      // console.log(youPickXSelectedBackgroundOptions);

      const childItemData = youPickXSelectedBackgroundOptions[childItemId];

      // console.log('childItemData');
      // console.log(childItemData);

      const code = childItemData.backgroundCodes[0]; // for now, you pick x only has 1 bg code
      const background = this.props.formattedBackgroundOptions[code];

      // console.log(childItemData);
      // console.log(youPickXSelectedBackgroundOptions);
      // console.log('code');
      // console.log(code);
      // console.log('background');
      // console.log(background);
      // console.log(this.props.formattedBackgroundOptions);

      const style = {
        background: `url("${background.image_thumbnail}")`,
        backgroundSize: 'cover',
      };

      backgroundOptions.push(
        <div
          className="cart-item-background-option clearfix"
          key={`cart-background-option-${code}-${childItemId}`}
        >
          <div className="cart-item-background__image" style={style} />
        </div>,
      );
    }

    return backgroundOptions;
  }

  renderIncentiveBackgroundOptions(cartItem, orderState) {
    const backgroundOptions = [];
    const configuration = orderState.incentiveConfiguration[cartItem.id];
    const code = configuration.backgroundCodes[0];

    if (code) {
      const background = this.props.formattedBackgroundOptions[code];

      const style = {
        background: `url("${background.image_thumbnail}")`,
        backgroundSize: 'cover',
      };

      backgroundOptions.push(
        <div
          key={`incentive-background-${code}`}
          className="cart-item-background-option clearfix"
        >
          <div className="cart-item-background__image" style={style} />
        </div>,
      );

      return backgroundOptions;
    }
  }

  renderQuantity(cartItem, orderState) {
    const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
      <div className="cart-item__quantity clearfix">
        <Dropdown
          className="cart-item-quantity__dropdown"
          handleOnChange={(event) =>
            this.editQuantity(event, cartItem, orderState)
          }
          name="quantity"
          options={options}
          value={cartItem.count}
        />
        <div className="cart-item-quantity__header">Qty</div>
      </div>
    );
  }

  renderCartItems(orderState) {
    const cartItems = orderState.cartItems.map((cartItem, i) => {
      if (
        cartItem.product_type === 'regular_product' ||
        cartItem.product_type === 'child_product'
      ) {
        const imageThumbnail = () => {
          if (
            typeof this.props.shoot !== undefined &&
            typeof this.props.shoot.pricing_model !== undefined &&
            this.props.shoot.pricing_model !== null &&
            this.props.shoot.pricing_model &&
            this.props.shoot.pricing_model.name === 'GROUP_1' && // TODO: make this a bit more flexible
            typeof this.props.studentPhotoPreviewUrl !== undefined &&
            this.props.studentPhotoPreviewUrl.length > 0
          ) {
            return this.props.studentPhotoPreviewUrl;
          }
          return cartItem.image_thumbnail;
        };

        return (
          <li className="cart-item clearfix" key={`cart-item-${i}`}>
            <div className="cart-item__image-container">
              <img className="cart-item-image" src={imageThumbnail()} />
            </div>
            <div className="cart-item__desc-container">
              <div className="cart-item__name">{cartItem.name}</div>
            </div>
            <div className="cart-item__configuration">
              <div className="cart-item__configuration-buttons clearfix">
                {this.renderEditButton(cartItem, orderState)}
                <div className="cart-item-right__right">
                  <div className="cart-item__price">
                    {this.props.displayPrice(cartItem.totalPrice)}
                  </div>
                  <div
                    onClick={() =>
                      this.props.removeCartItem(orderState.studentId, cartItem)
                    }
                    className="cart-item__remove cursor-pointer"
                  >
                    Remove
                  </div>
                </div>
              </div>
              {this.renderBackgroundOptionsSection(cartItem, orderState)}
              {this.renderQuantity(cartItem, orderState)}
            </div>
          </li>
        );
      }
      if (cartItem.product_type === 'parent_product') {
        return (
          <li className="cart-item clearfix" key={`cart-item-${i}`}>
            <div className="cart-item__image-container">
              <img className="cart-item-image" src={cartItem.image_thumbnail} />
            </div>
            <div className="cart-item__desc-container">
              <div className="cart-item__name">{cartItem.name}</div>
            </div>
            <div className="cart-item__configuration">
              <div className="cart-item__configuration-buttons clearfix">
                <div
                  onClick={() =>
                    this.props.editYouPickXBackgrounds(
                      cartItem,
                      orderState.studentId,
                    )
                  }
                  className="cart-item-background__edit cursor-pointer"
                >
                  Edit
                </div>
                <div className="cart-item-right__right">
                  <div className="cart-item__price">
                    {this.props.displayPrice(cartItem.totalPrice)}
                  </div>
                  <div
                    onClick={() =>
                      this.props.removeCartItem(orderState.studentId, cartItem)
                    }
                    className="cart-item__remove cursor-pointer"
                  >
                    Remove
                  </div>
                </div>
              </div>
              <div className="cart-item__background">
                <div className="cart-item-background__header">Backgrounds:</div>
                <ul className="cart-item-background__options clearfix">
                  {this.renderYouPickXBackgroundOptions(cartItem, orderState)}
                </ul>
              </div>
              {this.renderAdditionalBackgroundOptions(cartItem, orderState)}
            </div>
          </li>
        );
      }
    });

    return cartItems;
  }

  renderAddOns(orderState) {
    const addOns = [];

    if (orderState.photoEnhancementApplied) {
      let photoEnhancementOption;

      this.props.photoEnhancements.forEach((photoEnhancement) => {
        if (photoEnhancement.id === 'BASIC')
          photoEnhancementOption = photoEnhancement;
      });

      addOns.push(
        <li className="cart-item clearfix" key="cart-item-retouching">
          <div className="cart-item__image-container">
            <img
              src="https://s3.amazonaws.com/pics-assets/products/geskus/spring-2019/retouching-before.png"
              className="cart-item-image--addon"
            />
          </div>
          <div className="cart-item__desc-container">
            <div className="cart-item__name">Retouching</div>
          </div>
          <div className="cart-item__configuration">
            <div className="cart-item-background__edit" />
            <div className="cart-item-right__right">
              <div className="cart-item__price">
                {this.props.displayPrice(photoEnhancementOption.price)}
              </div>
              <div
                className="cart-item__remove cursor-pointer"
                onClick={() => this.props.removePhotoEnhancement(orderState)}
              >
                Remove
              </div>
            </div>
          </div>
        </li>,
      );
    }

    if (orderState.personalizationApplied) {
      addOns.push(
        <li className="cart-item clearfix" key="cart-item-personalization">
          <div className="cart-item__image-container">
            <img
              src="https://s3.amazonaws.com/cdn-portraitpics/products/1/2018-spring/personalization-example-2018-600.jpg"
              className="cart-item-image--addon"
            />
          </div>
          <div className="cart-item__desc-container">
            <div className="cart-item__name">Personalization</div>
          </div>
          <div className="cart-item__configuration clearfix">
            <div
              onClick={() =>
                this.props.goToPersonalization(orderState.studentId)
              }
              className="cart-item-background__edit cursor-pointer"
            >
              Edit
            </div>
            <div className="cart-item-right__right">
              <div className="cart-item__price">
                {this.props.displayPrice(this.props.personalization.price)}
              </div>
              <div
                className="cart-item__remove cursor-pointer"
                onClick={() => this.props.removePersonalization(orderState)}
              >
                Remove
              </div>
            </div>
          </div>
        </li>,
      );
    }

    return addOns;
  }

  renderIncentiveEditButton(incentiveCartItem, orderState) {
    if (
      incentiveCartItem.pics_product_type !== 'special_product' &&
      !this.shootIsOutdoors(orderState)
    ) {
      return (
        <div
          onClick={() =>
            this.props.editIncentiveBackground(
              incentiveCartItem,
              orderState.studentId,
            )
          }
          className="cart-item-background__edit cursor-pointer"
        >
          Edit
        </div>
      );
    }
    return <div className="cart-item-background__edit" />;
  }

  renderBackground(incentiveCartItem, orderState) {
    if (
      incentiveCartItem.pics_product_type !== 'special_product' &&
      !this.shootIsOutdoors(orderState)
    ) {
      return (
        <div className="cart-item__background">
          <div className="cart-item-background__header">Backgrounds:</div>
          <ul>
            {this.renderIncentiveBackgroundOptions(
              incentiveCartItem,
              orderState,
            )}
          </ul>
        </div>
      );
    }
    return <div className="cart-item__background" />;
  }

  renderIncentiveCartItems(orderState) {
    const incentiveCartItems = orderState.incentiveCartItems.map(
      (incentiveCartItem, i) => (
        <li key={`incentive-item-${i}`} className="cart-item clearfix">
          <div className="cart-item__image-container">
            <img
              src={incentiveCartItem.image_thumbnail}
              className="cart-item-image"
            />
          </div>
          <div className="cart-item__desc-container">
            <div className="cart-item__name">{incentiveCartItem.name}</div>
            <div>Free Bonus Item</div>
          </div>
          <div className="cart-item__configuration">
            <div className="cart-item__configuration-buttons clearfix">
              {this.renderIncentiveEditButton(incentiveCartItem, orderState)}
              <div className="cart-item-right__right">
                <div className="cart-item__price">FREE</div>
                <div className="cart-item__remove" />
              </div>
            </div>
            {this.renderBackground(incentiveCartItem, orderState)}
          </div>
        </li>
      ),
    );

    return incentiveCartItems;
  }

  renderOrderContent(orderState) {
    return (
      <div className="order__content" key={`cart-${orderState.studentId}`}>
        <div className="order-content__header clearfix">
          <div className="order-content__student-name">
            {orderState.studentName}
          </div>
          <div className="order-content__shoot-name">
            {orderState.shootName}
          </div>
        </div>
        <ul className="order-content__cart-items">
          {this.renderCartItems(orderState)}
          {this.renderAddOns(orderState)}
          {this.renderIncentiveCartItems(orderState)}
        </ul>
      </div>
    );
  }

  renderCartContent() {
    const contents = [];

    for (const studentId in this.props.cart.orders) {
      const orderState = this.props.cart.orders[studentId];
      if (orderState.cartItems.length > 0) {
        contents.push(this.renderOrderContent(orderState));
      }
    }

    return contents;
  }

  renderDiscount() {
    if (this.props.discountTotal !== 0) {
      return (
        <div className="clearfix">
          <div className="order-summary__text">Discount:</div>
          <div className="order-summary__price">
            {this.props.displayNegativePrice(this.props.discountTotal)}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="order-cart">
        <div className="order-cart__items-count">
          {this.props.cart.count} items in your cart
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8 col-lg-8">
            {this.renderCartContent()}
          </div>
          <div className="col-xs-12 col-md-4 col-lg-4 clearfix">
            <div className="order-cart__right-container">
              <div className="order-cart__header">Order Summary</div>
              <div className="order-cart-summary__subtotal clearfix">
                <div className="order-summary__text">Subtotal:</div>
                <div className="order-summary__price">
                  {this.props.displayPrice(this.props.subTotal)}
                </div>
              </div>
              {this.renderDiscount()}
              <div className="order-summary__total clearfix">
                <div className="order-summary__text">Total:</div>
                <div className="order-summary__price">
                  {this.props.displayPrice(
                    this.props.subTotal + this.props.discountTotal,
                  )}
                </div>
              </div>
              <div className="button bg--dark-blue" onClick={this.goToCheckout}>
                Proceed to Checkout
              </div>
            </div>
            <div className="order-cart__right-container hidden">
              <div className="order-cart__header">Free Shipping</div>
              <div className="order-cart-free-shipping__desc">
                You just need to add $3 to get free shipping. REPLACE
                PLACEHOLDER
              </div>
              <div
                className="button bg--dark-grey"
                onClick={this.props.keepShopping}
              >
                Keep Shopping
              </div>
            </div>
            <div className="order-cart__right-container hidden">
              <div className="order-cart__header">Get a free backpack</div>
              <div className="order-cart-incentive__desc">
                When adding another 8x10. REPLACE PLACEHOLDER
              </div>
              <div className="button bg--dark-grey">Add to Cart</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCart);
