import React from 'react';
import LandingImg from 'images/home/geskus-home.webp';
import Logo from 'images/logos/geskus-landing-logo.png';

const landingWrapper = {
  paddingTop: '25px',
};

const logoStyle = {
  position: 'relative',
  top: 0,
  left: 0,
  width: '150px',
  height: '150px',
};

const headerStyle = {
  marginTop: '30px',
};

const LandingGeskus = (props) => {
  const { history, setActiveComponent } = props;

  return (
    <div className="gb-landing" style={landingWrapper}>
      <div className="gb-landing__top clearfix">
        <div className="gb-landing__top__left">
          <div className="geskus-landing__logo" alt="" style={logoStyle}>
            <img className="" src={Logo} />
          </div>
          <h1 className="gb-landing__top__left__header" style={headerStyle}>
            Welcome
          </h1>
          <h3 className="gb-landing__top__left__subheader">
            To Geskus Studios &amp; Yearbook Publishing online ordering!
          </h3>
          <h3 className="gb-landing__top__left__subheader">
            For the security of your child, you must create an account to place
            an order.
          </h3>
          <div
            className="gb-landing__signup-button cursor-pointer"
            onClick={() => history.push('/account-lookup', { step: 1 })}
          >
            Place Your Order
          </div>
        </div>
        <img className="gb-landing__top__image" src={LandingImg} alt="" />
      </div>
    </div>
  );
};

export default LandingGeskus;
