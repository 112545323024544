import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cartUtils from '../../utils/cart';

const NthFreeQualification = (props) => {
  const selectedStudent = useSelector(
    (state) => state.additionalData.selectedStudent,
  );
  const orders = useSelector((state) => state.v3Order.orders);

  const { children, history } = props;

  const [checkComplete, setCheckComplete] = useState(false);

  // TODO: adjust to allow for multiple nth-free products per order
  const maxNthFreePerOrder = 1; // assume only one nth-free product per order

  const cartItems = orders[selectedStudent?.id]?.cartItems || [];
  const nthFreeQualifiersInCart = cartUtils.nthFreeQualifiersInCart(cartItems);
  const nthFreeProductsInCart = cartUtils.nthFreeProductsInCart(cartItems);

  const nthFreeQualifierThreshold =
    nthFreeQualifiersInCart[0]?.nthFreeThreshold || null;

  useEffect(() => {
    if (
      nthFreeQualifierThreshold &&
      nthFreeQualifiersInCart.length >= nthFreeQualifierThreshold &&
      nthFreeProductsInCart.length < maxNthFreePerOrder
    ) {
      history.push('/v3/categories/nth-free');
    } else {
      setCheckComplete(true);
    }
  }, [orders, selectedStudent]);

  if (checkComplete) {
    return children;
  }

  return <div>Loading...</div>;
};

export default NthFreeQualification;
