// required props to use Dropdown component:
// handleOnChange - onChange event handler that updates React.js state
// name - the state key that is being updated (represented by a string)
// value - the state value that is selected by default (represented by a string)
// options - array of options
// e.g. this.props.options = ["AK", "AL", "AZ", "NY, "NJ", "FL", etc...] for states

import React, { Component } from 'react';

class Dropdown extends Component {
  renderOptions() {
    const options = this.props.options.map((option, i) => {
      return (
        <option value={option} key={`${this.props.name}-${i}`}>
          {option}
        </option>
      );
    });

    return options;
  }

  render() {
    const className = this.props.className ? this.props.className : '';

    return (
      <select
        className={className}
        onChange={this.props.handleOnChange}
        value={this.props.value}
        name={this.props.name}
      >
        {this.renderOptions()}
      </select>
    );
  }
}

export default Dropdown;
