import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import App from '../components/app';
import { config } from '../config/axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearParent } from '../actions/action_auth';
import UIContext from '../contexts/UIContext';

const AppWithRedirect = (props) => {
  config(props.clearParent);
  return <App {...props} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearParent }, dispatch);
}

const ConnectedAppWithRedirect = connect(
  () => ({}),
  mapDispatchToProps,
)(AppWithRedirect);

export default function Root({ store, history, persistor, ...data }) {
  const [uiSettings, setUiSettings] = useState({
    uiVersion: data?.ui_version,
    useTestingCategoryPaths: data?.ui_v3_use_testing_category_paths || false,
  });

  return (
    <UIContext.Provider value={uiSettings}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <ConnectedAppWithRedirect {...data} history={history} />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </UIContext.Provider>
  );
}
