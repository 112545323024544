import React from 'react';
import { useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useCurrentOrderStudentId } from '../../../../../hooks/useCurrentOrderStudentId';
import { declinePreCartOfferProduct } from '../../../../../actions/v3Order';

const DeclinePreCartOfferProductuButton = ({ product, redirectSource }) => {
  const dispatch = useDispatch();
  const studentId = useCurrentOrderStudentId();

  return (
    <div
      className="bnl-product-config__button--cancel button bg--dark-grey"
      onClick={() => {
        mixpanel.track('Pre-Cart Offer Button Click', {
          Selection: 'Decline',
        });
        dispatch(
          declinePreCartOfferProduct(studentId, product.id, redirectSource),
        );
      }}
    >
      No Thanks
    </div>
  );
};

export default DeclinePreCartOfferProductuButton;
