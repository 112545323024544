import {
  STORE_STUDENT_ID,
  STORE_IMAGE_ID,
} from '../actions/action_bnl_yearbook_photo_selection';

const INITIAL_STATE = {
  studentId: null,
  galleryPassword: null,
  imageId: null,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case STORE_STUDENT_ID:
      return { ...state, studentId: action.payload };
    case STORE_IMAGE_ID:
      return { ...state, imageId: action.payload };
    default:
      return state;
  }
}
