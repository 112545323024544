import { SET_CURRENT_LOCATION } from '../actions/action_location';

const initialState = {
  current: '',
  previous: '',
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        previous: state.current, // Current becomes previous
        current: action.payload, // Update current location
      };
    default:
      return state;
  }
};

export default locationReducer;
