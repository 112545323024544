import React, { Component } from 'react';

class BnlOrderProductConfigurationNone extends Component {
  renderInstruction() {
    if (
      this.props.selectedShootProduct &&
      this.props.selectedShootProduct.is_retouching
      // && this.props.promptRetouching // TODO: do we need this?
    ) {
      return (
        <div>
          <p>Would you like to add retouching?</p>
          <p>
            Select 'Add Retouching' to add retouching to your student's order.
          </p>
        </div>
      );
      // return " ";
    }
    return "Configuration complete. Press 'Done, Add To Cart' to continue.";
  }

  render() {
    return (
      <div className="bnl-product-config__none">{this.renderInstruction()}</div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default BnlOrderProductConfigurationNone;
