import PropTypes from 'prop-types';
import React, { CSSPropertyRule } from 'react';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledIcon = styled.svg`
  fill: none;
  stroke: ${({ stroke }) => stroke || 'white'};
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: ${({ checked }) => (checked ? '#327AB7' : 'white')};
  transition: all 150ms;
  border: 2px solid #327ab7;
  border-radius: 0;
  ${StyledIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = ({ stroke }) => (
  <StyledIcon viewBox="0 0 24 24" stroke={stroke}>
    <polyline points="20 6 9 17 4 12" />
  </StyledIcon>
);

export const StyledCheck = ({ checked, className, style, onClick, stroke }) => (
  <StyledCheckbox
    checked={checked}
    className={className}
    style={style}
    onClick={onClick}
  >
    <Icon stroke={stroke} />
  </StyledCheckbox>
);

const Checkbox = ({
  containerClassName,
  className,
  checked,
  onClick,
  stroke,
  ...props
}) => (
  <CheckboxContainer className={containerClassName} onClick={onClick}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} className={className}>
      <Icon stroke={stroke} />
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  stroke: PropTypes.string,
};

Checkbox.defaultProps = {
  className: null,
  containerClassName: null,
  onClick: null,
  stroke: 'white',
};

StyledCheck.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  stroke: PropTypes.string,
  style: PropTypes.instanceOf(CSSPropertyRule),
};

StyledCheck.defaultProps = {
  className: null,
  onClick: null,
  style: null,
  stroke: 'white',
};

Icon.propTypes = {
  stroke: PropTypes.string,
};

Icon.defaultProps = {
  stroke: 'white',
};

export default Checkbox;
