import { useState } from 'react';

const useModalCarousel = (defaultSlideIndex = 0) => {
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(defaultSlideIndex);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  return {
    open,
    closeModal,
    openModal,
    slideIndex,
    setSlideIndex,
  };
};

export default useModalCarousel;
