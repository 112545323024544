import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';
import cartUtils from '../../../utils/cart';
import { setCurrentUser } from '../../../actions/action_auth';
import {
  addStudent,
  getStudents,
  setStudentData,
  viewedCompanyMessage,
} from '../../../actions/action_parent';
import { setAdditionalData } from '../../../actions/additionalData';
import {
  getGalleries,
  setCpmId,
  setGalleries,
  storeGallery,
  removeStudentImage,
} from '../../../actions/action_gallery';
import {
  getShootData,
  formatAndSetBackgroundOptions,
  formatAndSetIncentiveProducts,
  setRetouchingProduct,
  setRetouchingProducts,
  setPersonalizationProduct,
  setInterstitialProducts,
  setPreCartOfferProducts,
  setTriggersPreCartOfferProducts,
} from '../../../actions/action_shoot';
import {
  getBnlKeyLookup,
  setBnlPreShoot,
  setEnteredKeyIsPrepay,
} from '../../../actions/action_bnl_pre_shoot';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';
import {
  storeOrder,
  updateBnlUnlockedGroups,
} from '../../../actions/action_cart';

// React.js components
import BnlParentGalleryKeyForm from './bnl_parent_gallery_key_form';
import BnlDashboardNotice from './bnl_dashboard_notice';
import CompanyDashboardMessage from './company_dashboard_message';

// constants files
import { ORDER_NAVIGATION } from '../../../constants/order_navigation';
import { DUMMY_IMAGE_URL } from '../../../constants/image_urls';
import WithTippy from '../../utils/tippy';

import UIContext from '../../../contexts/UIContext';
import DashboardGalleries from './../../v3/dashboard/DashboardGalleries';

class BnlParentDashboard extends Component {
  static contextType = UIContext;

  constructor(props) {
    super(props);

    this.state = {
      activeComponent: 1,
      editGallery: false,
      editingStudentId: null,
      galleryKey: props.location.state?.key ? props.location.state.key : '',
      getStudentsCalled: false,
      loading: false,
      selectedStudent: null,
      domLoaded: false,
      presetEventKey: false,
      requiresTermsConditions: false,
    };

    this.STEPS = {
      dashboard: 1,
      studentSelection: 2,
    };

    this.goToOrder = this.goToOrder.bind(this);
    this.goToStudentSelection = this.goToStudentSelection.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.removeStudentImage = this.removeStudentImage.bind(this);
    this.toggleEditGallery = this.toggleEditGallery.bind(this);
    this.handleDomLoaded = this.handleDomLoaded.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const that = this;
    // // redirect to signup step 2 when directly accessing "/dashboard" URL
    // // if current user is truthy but hasn't completed signup
    if (nextProps.auth.currentUserId && !nextProps.auth.signupCompleted) {
      this.props.history.replace('/signup', { step: 2 });
    } else if (
      this.props.orderNavigation.step === null &&
      nextProps.orderNavigation.step === null
    ) {
      this.setState({ activeComponent: this.STEPS.dashboard });
    }
  }

  componentDidMount() {
    // TODO: check for T&C terms here
    axios
      .get('/api/v2/parents/check-tc')
      .then((response) => {
        const requireTerms = response.data.requires_user_to_accept_terms;
        const termsAccepted = response.data.terms_accepted;

        if (requireTerms && !termsAccepted) {
          this.setState({ requiresTermsConditions: true });
        } else {
          this.setState({ requiresTermsConditions: false });
        }
      })
      .catch((error) => console.error('Error checking T&C:', error));

    // TODO: we should move this call somewhere else... it's a bit hacky
    this.getStudents(this.props.auth.currentUserId);

    const eventKey = Cookies.get('pics_event_key');
    const productKey = Cookies.get('product_key');
    const campaignEventId = Cookies.get('campaign_event_id');

    if (eventKey && eventKey !== '') {
      this.setState({ galleryKey: eventKey, presetEventKey: true });
    }

    if (Object.keys(this.props.cart.orders).length === 0) {
      this.props.setBnlPreShoot(null);
    }

    this.props.updateOrderActiveComponent(null);
    window.scrollTo(0, 0);

    this._ismounted = true;
    window.addEventListener('load', this.handleDomLoaded);

    // hack to clear edit configuration when user navigates from edit to dashboard
    const { orders } = this.props.cart;

    for (const studentId in orders) {
      const orderState = orders[studentId];
      orderState.editingFromCart = false;
      orderState.bnlEditConfiguration = null;

      this.props.storeOrder(studentId, orderState);
    }

    if (eventKey && productKey && campaignEventId) {
      this.goToStudentSelection(eventKey);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  handleDomLoaded() {
    if (this._ismounted) {
      this.setState({ domLoaded: true });
    }
  }

  cloneArray(array) {
    return array.slice();
  }

  deepCloneObject(object) {
    // converts date objects to strings but not back to dates
    return JSON.parse(JSON.stringify(object));
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.goToStudentSelection();
    }
  }

  parentHasAtLeastOneStudent() {
    if (typeof this.props.parent.students === 'undefined') {
      return false;
    }

    return this.props.parent.students.length > 0;
  }

  canGoToOrder() {
    // return (
    //   this.parentHasAtLeastOneStudent() &&
    //   this.parentAuthenticatedAtLeastOneGallery()
    // );
  }

  toggleEditGallery(studentId) {
    this.setState({
      editGallery: !this.state.editGallery,
      editingStudentId: studentId,
    });
  }

  getEditGalleryText(studentId) {
    if (this.state.editingStudentId === studentId && this.state.editGallery) {
      return 'Done';
    }

    return 'Edit Gallery';
  }

  removeStudentImage(cpmId, galleryImageId, studentId) {
    const that = this;
    const data = {
      image_id: galleryImageId,
      student_id: studentId,
    };
    // let imagesCount = 0; // WIP
    // imagesCount += this.props.gallery.galleries[cpmId][studentId].gallery.length;
    // imagesCount += this.props.gallery.galleries[cpmId][studentId].associatedGroupImages.length;
    //
    // if (this.props.gallery.galleries[cpmId][studentId].gallery.length === 1) {
    //   let galleries = this.deepCloneObject(this.props.gallery.galleries);
    //   // last student with last image within cpmId
    //   if (Object.keys(galleries[cpmId]).length === 1) {
    //   // if (Object.keys(galleries[cpmId]).length === 1 && imagesCount === 1) { // WIP
    //     delete galleries[cpmId]
    //   } else {
    //   // } else if (imagesCount === 1) { // WIP
    //     let studentsGalleries = galleries[cpmId];
    //     delete studentsGalleries[studentId];
    //   }
    //
    //   this.props.setGalleries(galleries);
    // }
    //
    // this.props.removeStudentImage(data).then(response => {
    //   if (response.payload.data.gallery.length === 0) {
    //     that.setState({
    //       editGallery: false,
    //       editingStudentId: null
    //     });
    //   }
    // });
  }

  setUnlockedGroups(studentId) {
    const orderState = this.props.cart.orders[studentId];
    let bnlUnlockedGroups = [];

    if (orderState) {
      bnlUnlockedGroups = this.cloneArray(this.props.cart.bnlUnlockedGroups);

      orderState.cartItems.forEach((cartItem) => {
        if (cartItem.lock_type === 'unlocker') {
          cartItem.lock_group.forEach((set) => {
            if (!bnlUnlockedGroups.includes(set)) bnlUnlockedGroups.push(set);
          });
        }
      });
    }

    this.props.updateBnlUnlockedGroups(bnlUnlockedGroups);
  }

  goToOrder(studentsGalleries, cpmId, selectedStudent) {
    const uiSettings = this.context; // get UI version from context
    const { uiVersion } = uiSettings;
    const {
      bnlPreShoot,
      cart,
      setBnlPreShoot,
      getShootData,
      setPersonalizationProduct,
      v3Order: { orders },
    } = this.props;

    const v3CartContainsPrepayOrders =
      cartUtils.cartContainsPrepayOrders(orders);

    if (
      (bnlPreShoot.preShoot &&
        cart.orders &&
        Object.keys(cart.orders).length > 0) ||
      v3CartContainsPrepayOrders
    ) {
      Swal.fire({
        title: 'Your cart contains prepay orders',
        html: '<p>Your cart contains prepay orders, which cannot be submitted with gallery-based orders.</p><p>Please submit or clear your prepay orders to enable gallery-based ordering.</p>',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Okay',
      });

      // Swal.fire(
      //   'Pre-order in progress',
      //   "Please complete your pre order(s). Pre ordering and gallery based ordering cannot be completed together. If you'd like to start a different order, please empty your cart and return to the dashboard.",
      // );

      if (v3CartContainsPrepayOrders) {
        Sentry.setContext('orders', {
          orders: orders,
          orderKeys: Object.keys(orders),
        });
      } else {
        Sentry.setContext('orders', {
          orders: cart.orders,
          orderKeys: Object.keys(cart.orders),
          preShoot: bnlPreShoot?.preShoot,
        });
      }

      Sentry.captureMessage('pre-order in progress warning');
    } else {
      this.setState({ loading: true }, () => {
        const that = this;

        // const studentId = Object.keys(studentsGalleries)[0];
        // TODO: confirm this is the correct way to get studentId (vs. Object.keys(studentsGalleries)[0];)
        const studentId = selectedStudent.id;
        const studentGalleryObj = studentsGalleries[studentId];
        const studentGroupImages = studentGalleryObj.associatedGroupImages;

        // get gallery that contains all poses for all grouped students
        const flatGallery = Object.values(studentsGalleries || {}).reduce(
          (acc, user) => {
            return acc.concat(user.gallery);
          },
          [],
        );

        // get group images for all grouped students
        const flatGroupImages = Object.values(studentsGalleries || {}).reduce(
          (acc, user) => {
            return acc.concat(user.associatedGroupImages);
          },
          [],
        );

        const galleryKey = studentGalleryObj.gallery[0].gallery_key; // TODO: PRIORITY REVIST

        const data = {
          student_id: studentId,
          shoot_key: galleryKey,
          grade_code: 'IDK',
        };

        setBnlPreShoot(false);

        getShootData(data).then((resp) => {
          that.setState(
            {
              loading: false,
            },
            () => {
              if (!resp.payload.data.errors) {
                const shootData = resp.payload.data;

                // get the ui version from the event (shoot)
                const eventUIVersion = resp.payload.data.shoot?.ui_version;
                const { setAdditionalData } = that.props;

                if (selectedStudent) {
                  setAdditionalData({ selectedStudent });
                }

                that.formatBackgroundOptions(
                  shootData.contains_background_categories,
                  shootData.background_options,
                );
                that.props.setRetouchingProduct(shootData.retouching_product);
                that.props.setRetouchingProducts(shootData.retouching_products);
                setPersonalizationProduct(shootData.personalization_product);
                that.props.setInterstitialProducts(
                  shootData.interstitial_products,
                );
                that.props.setPreCartOfferProducts(
                  shootData.pre_cart_offer_products,
                );
                that.props.setTriggersPreCartOfferProducts(
                  shootData.triggers_pre_cart_offer_products,
                );
                that.formatIncentiveProducts(shootData.incentive_products);
                that.props.setCpmId(cpmId);
                that.setUnlockedGroups(studentId);

                // console.log('checking that vs this');
                // console.log(
                //   `that: ${Object.values(that.props.resetOrderRetouching())}`
                // );
                // console.log(
                //   `this: ${Object.values(this.props.resetOrderRetouching())}`
                // );
                // console.log('------------');

                for (const galleryStudentId in that.props.gallery.galleries[
                  cpmId
                ]) {
                  const galleryObj =
                    that.props.gallery.galleries[cpmId][galleryStudentId];

                  // if at least 1 student gallery requires yearbook photo selection
                  // go to selection screen
                  if (galleryObj.requiresYearbookPhotoSelection) {
                    that.props.history.push('/yearbook-photo-selection');
                    return;
                  }
                }

                that.props.updateOrderActiveComponent(
                  ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
                );

                const student = studentGalleryObj.student;

                that.props.setAdditionalData({
                  // availablePoses: studentGalleryObj.gallery,
                  availablePoses: flatGallery,
                  availableGroupImages: flatGroupImages,
                  studentGallery: studentGalleryObj?.gallery || [],
                  studentGroupImages,
                  eventUIVersion,
                  selectedStudent: student,
                  student,
                  studentId: student.id,
                  uiVersion,
                });

                // TODO: v3 - remove
                console.log('%corder entry point: 2', 'color: yellow;');
                console.log(`%cUI version: ${uiVersion}`, `color: yellow;`);
                console.log(
                  `%cevent UI version: ${eventUIVersion}`,
                  `color: yellow;`,
                );

                // TODO: v3 - move to helper function
                if (
                  ['3', 'v3'].includes(uiVersion) ||
                  eventUIVersion === 'ui_v3'
                ) {
                  this.props.history.push('/v3/incentive-offers');
                } else {
                  that.props.history.push('/order');
                }
              }
            },
          );
        });
      });
    }
  }

  formatBackgroundOptions(containsBackgroundCategories, backgroundOptions) {
    const formattedBackgroundOptions = {};

    if (containsBackgroundCategories) {
      backgroundOptions.forEach((backgroundSet) => {
        backgroundSet.background_images.forEach((backgroundOption) => {
          formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
        });
      });
    } else {
      backgroundOptions.forEach((backgroundOption) => {
        formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
      });
    }

    this.props.formatAndSetBackgroundOptions(formattedBackgroundOptions);
  }

  formatIncentiveProducts(incentiveProducts) {
    const formattedIncentiveProducts = {};

    incentiveProducts.forEach((incentiveProduct) => {
      if (!formattedIncentiveProducts[incentiveProduct.incentive_threshold]) {
        formattedIncentiveProducts[incentiveProduct.incentive_threshold] = [];
      }

      formattedIncentiveProducts[incentiveProduct.incentive_threshold].push(
        incentiveProduct,
      );
    });

    this.props.formatAndSetIncentiveProducts(formattedIncentiveProducts);
  }

  getStudents(currentUserId) {
    const that = this;

    this.props.getStudents(currentUserId).then((response) => {
      if (that.parentHasAtLeastOneStudent()) {
        const { students } = this.props.parent;
        const student_ids = students.map((student, i) => student.id);
        that.props.getGalleries({ student_ids });
      }
    });
  }

  goToStudentSelection(eventKey = null) {
    const galleryKey = eventKey || this.state.galleryKey;
    const {
      companyAuthToken,
      v3Order: { orders },
    } = this.props;

    const v3CartContainsPostEventOrders =
      cartUtils.cartContainsPostEventOrders(orders);

    // TODO: implement this
    // const v3CartContainsSeniorsOrders =

    if (galleryKey) {
      const that = this;

      const data = {
        shoot_key: galleryKey,
        company_auth_token: companyAuthToken,
      };

      console.log('KEY TO LOOK UP: ', data);
      console.log(this.state);
      console.log(this.props);

      this.props.getBnlKeyLookup(data).then((resp) => {
        if (resp.payload.data) {
          if (!resp.payload.data.errors) {
            // flag dp2 shoots with simple photo ordering service and render modal
            if (
              resp.payload.data.ordering_service === 'order_via_simple_photo'
            ) {
              const spRedirectUrl = resp.payload.data.sp_redirect_url;

              Swal.fire({
                title: 'Oops!',
                text: "The key you entered is correct but orders must be placed via a different website. Click 'OK' to redirect and place your order.",
                buttons: true,
              }).then((data) => {
                if (data.value) {
                  window.location.href = spRedirectUrl + this.state.galleryKey;
                }
              });

              return;
            }

            if (resp.payload.data.pre_shoot && v3CartContainsPostEventOrders) {
              Swal.fire({
                title: 'Your cart contains gallery-based orders',
                html: '<p>Your cart contains gallery-based orders, which cannot be submitted with prepay orders.</p><p>Please submit or clear your gallery-based orders to enable prepay ordering.</p>',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Okay',
              });

              Sentry.captureMessage(
                'v3 gallery-based order in progress warning from dashboard',
              );

              return;
            }

            if (
              that.props.bnlPreShoot.preShoot !== null &&
              !that.props.bnlPreShoot.preShoot &&
              resp.payload.data.pre_shoot
            ) {
              Swal.fire(
                'Gallery based order in progress',
                "Please complete your gallery based order(s). Gallery based ordering and pre-event ordering cannot be completed together. If you'd like to start a different order, please empty your cart and return to the dashboard.",
              );
            } else {
              that.props.setAdditionalData({
                isSports: resp.payload.data.is_sports,
                keySubmittedByUser: resp.payload.data.key_submitted_by_user,
                priceListEventType: resp.payload.data.price_list_event_type,
                picsGalleryId: resp.payload.data.pics_gallery_id,
              });
              that.props.setBnlPreShoot(resp.payload.data.pre_shoot);
              that.props.setEnteredKeyIsPrepay(resp.payload.data.pre_shoot);

              if (that.props.parent.students.length === 0) {
                that.props.history.push('/add-student');
              } else {
                that.props.history.push('/select-student');
              }
            }
          } else {
            Swal.fire({
              title: 'Notice',
              html: resp.payload.data.errors.shootKeyForm,
              icon: 'warning',
            });
          }
        }
      });
    } else {
      Swal.fire('Please fill in your unique Prepay Code or Gallery Password');
    }
  }

  getShootKeyFromAuthenticatedGallery(galleries) {
    let shootKey;

    for (const studentId in galleries) {
      const studentGallery = galleries[studentId];

      if (!shootKey && studentGallery.gallery.length > 0) {
        shootKey = studentGallery.gallery[0].gallery_key;
      }
    }

    return shootKey;
  }

  submitGalleryKey(galleryKey) {
    const that = this;
    const data = {
      student_id: this.state.selectedStudent.id,
      shoot_key: galleryKey,
      grade_code: 'IDK',
    };

    this.props.getShootData(data).then((response) => {
      if (!response.payload.data.errors) {
        const student = that.state.selectedStudent;
        that.storeGallery(student, response.payload.data);
        that.formatBackgroundOptions(response.payload.data.background_options);

        that.setState({
          activeComponent: this.STEPS.dashboard,
          selectedStudent: null,
        });
      } else {
        Swal.fire(
          "Oops! We can't find that gallery",
          'Please check the spelling of your password and try again. If you need additional help, contact support',
        );
        console.log(response);
      }
    });
  }

  renderStudents() {
    if (this.props.parent.students.length > 0) {
      const students = this.props.parent.students.map((student) => (
        <li
          className="bnl-dashboard__my-students__student"
          key={`dashboard-student-${student.id}`}
        >
          {student.firstName} {student.lastName}
        </li>
      ));
      return (
        <ul className="bnl-dashboard__my-students__container">{students}</ul>
      );
    }

    return (
      <div className="bnl-dashboard__my-students__container">
        <div className="bnl-dashboard__my-students__no-student-header">
          You currently have no students to display.
        </div>
        <div className="bnl-dashboard__my-students__no-student-subheader">
          Enter your unique code or gallery password above to add students and
          start an order.
        </div>
      </div>
    );
  }

  renderMyStudentsSection(companyName) {
    let msg =
      'To get started, enter a Prepay Code or a Gallery Password above.';

    if (companyName && companyName.toLowerCase() === 'wagner') {
      msg = 'To get started, enter a Pre-order or Gallery Password above.';
    }

    if (companyName && companyName.toLowerCase() === 'geskusprint') {
      msg =
        "To get started, enter your student's unique code above and click 'Start Order'";
    }

    return (
      <div className="bnl-dashboard__my-students">
        <p>{msg}</p>
        {this.renderStudents()}
      </div>
    );
  }

  renderCompanyMessage(parentHasViewedMessage) {
    if (!parentHasViewedMessage && this.state.domLoaded) {
      return <CompanyDashboardMessage />;
    }
  }

  render() {
    const {
      auth,
      companyAuthToken,
      companyName,
      eventLookupByZipcodeEnabled,
      history,
    } = this.props;
    // prevent from rendering until redux retrieves current user
    if (
      auth.currentUserId === null &&
      auth.signupCompleted === null &&
      !this.props.parent
    ) {
      return <div id="loading" />;
    }

    const disabled = !this.canGoToOrder() ? 'disabled--bg-grey' : '';
    const loading = this.state.loading ? 'loading' : 'hidden';
    const { requiresTermsConditions } = this.state;
    const centerTextStyle = { textAlign: 'center' };
    const paragraphStyle = { marginBottom: '12px' };

    // if (requiresTermsConditions) {
    //   return <Redirect to="/v3/terms-conditions" />;
    // }

    return (
      <div className="bnl-dashboard-container">
        <div className={loading}>
          <div className="spinner" />
        </div>
        {/* <h4 className="bnl-dashboard-container__header">My Dashboard</h4> */}
        <div className="bnl-dashboard">
          <BnlDashboardNotice {...this.props} />
          <div className="page-header">
            <h1>Start a New Order</h1>
          </div>
          {companyAuthToken !== 'geskus' && (
            <div className="alert alert-info alert-dismissible" role="alert">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              To start a new order, please enter your student&apos;s Prepay Code
              or Gallery Password below and click &quot;Start Order.&quot;
            </div>
          )}
          <BnlParentGalleryKeyForm
            galleryKey={this.state.galleryKey}
            goToStudentSelection={this.goToStudentSelection}
            handleKeyPress={this.handleKeyPress}
            handleOnChange={this.handleOnChange}
            submitGalleryKey={this.submitGalleryKey}
            companyName={companyName}
            presetEventKey={this.state.presetEventKey}
            eventLookupByZipcodeEnabled={eventLookupByZipcodeEnabled}
          />
          {companyAuthToken !== 'geskus' && (
            <>
              <div className="page-header">
                <h1>Portrait Galleries</h1>
              </div>
              <div
                className="alert alert-warning alert-dismissible"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                The area below is where your portraits will be stored{' '}
                <strong>AFTER</strong> picture day. Portraits will not
                automatically appear until you add them to your account.
              </div>
              <DashboardGalleries history={history} />
              {/* <BnlParentGalleries
                editingStudentId={this.state.editingStudentId}
                editGallery={this.state.editGallery}
                goToOrder={this.goToOrder}
                removeStudentImage={this.removeStudentImage}
                toggleEditGallery={this.toggleEditGallery}
              /> */}
            </>
          )}
          {/* <div className="page-header">
            <h1>My Students</h1>
          </div> */}
          {/* {this.renderMyStudentsSection(companyName)} */}
          {/* {this.renderCompanyMessage(this.props.parent.hasViewedCompanyMessage)} */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    gallery: state.gallery,
    parent: state.parent,
    orderNavigation: state.orderNavigation,
    reducerHistory: state.reducerHistory,
    shoot: state.shoot,
    v3Order: state.v3Order,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addStudent,
      getStudents,
      formatAndSetBackgroundOptions,
      formatAndSetIncentiveProducts,
      getBnlKeyLookup,
      getGalleries,
      getShootData,
      removeStudentImage,
      setAdditionalData,
      setBnlPreShoot,
      setCpmId,
      setCurrentUser,
      setEnteredKeyIsPrepay,
      setGalleries,
      setRetouchingProduct,
      setRetouchingProducts,
      setPersonalizationProduct,
      setInterstitialProducts,
      setPreCartOfferProducts,
      setTriggersPreCartOfferProducts,
      setStudentData,
      storeGallery,
      storeOrder,
      updateOrderActiveComponent,
      updateBnlUnlockedGroups,
      viewedCompanyMessage,
    },
    dispatch,
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BnlParentDashboard),
);
