import React, { Component } from 'react';
import { connect } from 'react-redux';

// React.js components
import BnlOrderProductConfigurationBackgroundsSelector from '../orders/bnl/bnl_order_product_configuration_backgrounds_selector';

class IncentiveConfigurationBackground extends Component {
  renderPose() {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
      bnlPreShoot,
      selectedPose,
    } = this.props;

    let poseUrl = '';

    if (!bnlPreShoot.preShoot) {
      // Handle Geskus Post-Event
      // TODO: revisit
      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        poseUrl = studentPreviewImageUrl;
      } else {
        poseUrl = selectedPose.url;
      }

      const poseStyle = {
        backgroundImage: `url("${poseUrl}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return <div className="bnl-product-config__bg-pose" style={poseStyle} />;
    }

    return null;
  }

  renderAllBackgroundOptions(mobile) {
    const options = [];

    const that = this;
    this.props.shoot.backgroundOptions.forEach((backgroundImage) => {
      const bgId = backgroundImage.id;
      const option = this.props.formattedBackgroundOptions[bgId];
      const bgStyle = {
        backgroundImage: `url("${backgroundImage.image_thumbnail}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      let selected = '';
      let onClickHandler = this.props.selectBackground.bind(this, option);
      const bgClass = '';

      if (mobile) {
        onClickHandler = this.props.selectMobileBackground.bind(this, option);
      }

      if (this.props.configuration.backgroundCodes[0] === option.id) {
        selected = 'bnl-product-config__bg--selected';
      }

      const bgName = backgroundImage.display_name || '';
      const bgPrice =
        backgroundImage.price > 0
          ? this.props.displayPrice(backgroundImage.price).split('.')[0]
          : '';

      options.push(
        <li
          key={`bg-${bgId}`}
          className="bnl-product-config__bg-container col-xs-4 col-md-2 col-lg-2 cursor-pointer"
        >
          <div
            className={`bnl-product-config__bg ${selected}`}
            onClick={onClickHandler}
            style={bgStyle}
          >
            {this.renderPose()}
          </div>
        </li>,
      );
    });

    const htmlElements = <ul className="row equal-height">{options}</ul>;
    const data = {
      all: {
        name: null,
        htmlElements,
      },
    };

    return (
      <BnlOrderProductConfigurationBackgroundsSelector
        containsBackgroundCategories={
          this.props.shoot.containsBackgroundCategories
        }
        data={data}
      />
    );
  }

  renderCategorizedBackgroundOptions(mobile) {
    const data = {};

    this.props.shoot.backgroundOptions.forEach((backgroundOption) => {
      const backgrounImages = backgroundOption.background_images.map(
        (backgroundImage) => {
          const bgId = backgroundImage.id;
          const option = this.props.formattedBackgroundOptions[bgId];
          const bgStyle = {
            backgroundImage: `url("${backgroundImage.image_thumbnail}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          };
          let selected = '';
          let onClickHandler = this.props.selectBackground.bind(this, option);
          const bgClass = '';

          if (mobile) {
            onClickHandler = this.props.selectMobileBackground.bind(
              this,
              option,
            );
          }

          if (this.props?.configuration?.backgroundCodes?.[0] === option.id) {
            selected = 'bnl-product-config__bg--selected';
          }

          const bgName = backgroundImage.display_name || '';
          const bgPrice =
            backgroundImage.price > 0
              ? this.props.displayPrice(backgroundImage.price).split('.')[0]
              : '';

          return (
            <li
              key={`bg-${bgId}`}
              className="bnl-product-config__bg-container col-xs-6 col-md-2 col-lg-2 cursor-pointer"
            >
              <div
                className={`bnl-product-config__bg ${selected}`}
                onClick={onClickHandler}
                style={bgStyle}
              >
                {this.renderPose()}
              </div>
              <div className="text-center">
                {bgName} {bgPrice}
              </div>
            </li>
          );
        },
      );

      const htmlElements = (
        <ul className="row equal-height">{backgrounImages}</ul>
      );

      data[backgroundOption.id] = {
        id: backgroundOption.id,
        order: backgroundOption.order,
        name: backgroundOption.name,
        htmlElements,
      };
    });

    return (
      <BnlOrderProductConfigurationBackgroundsSelector
        containsBackgroundCategories={
          this.props.shoot.containsBackgroundCategories
        }
        data={data}
      />
    );
  }

  renderBackgroundOptions(mobile = false) {
    if (!this.props.shoot.containsBackgroundCategories) {
      return this.renderAllBackgroundOptions(mobile);
    }
    return this.renderCategorizedBackgroundOptions(mobile);
  }

  render() {
    return (
      <ul>{this.renderBackgroundOptions(this.props.mobileConfiguring)}</ul>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlPreShoot: state.bnlPreShoot,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(IncentiveConfigurationBackground);
