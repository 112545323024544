import { addStudent, getStudents } from '../../actions/action_parent';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { SpinnerSmall } from '../utils/spinner';

const Wrapper = styled.div`
  margin-top: 115px;
`;

const Title = styled.h1`
  margin-bottom: 16px;
  padding-bottom: 0;
`;

const Box = styled.div`
  background: #ffffff;
  margin-bottom: 25px;
  box-shadow: 0 8px 16px rgb(0 0 0 / 15%) !important;
`;

const InputWrapper = styled.div`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  border-radius: 5px;
  align-items: center;
`;

const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
`;

const Instructions = styled.h5`
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Fields = styled.div`
  display: grid;
  gap: 10px;
  max-width: 60%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'instructions instructions'
    'school school';
  margin: auto;
  padding-bottom: 25px;

  @media (max-width: 700px) {
    max-width: initial;
  }
`;

const ShootsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 60%;
  margin: auto;
  margin-top: 20px;

  @media (max-width: 700px) {
    max-width: initial;
  }
`;

const Section = styled.div`
  box-shadow: 0 3px 5px rgb(0 0 0 / 10%) !important;
  padding: 25px;
  max-height: 350px;
  overflow-x: scroll;
`;

const ResultTitle = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
`;

const ShootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  padding: 10px;
  cursor: pointer;

  & span {
    opacity: 0.5;
    font-size: 13px;
  }
`;

const BackLink = styled(Link)`
  display: inline-block;
  color: #479de6;
  margin-bottom: 12px;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const _Shoot = ({ shoot, history }) => {
  const onClick = () => {
    history.push('/dashboard', { key: shoot.key });
  };

  let dateStrForDisplay = '';

  if (shoot.shoot_date) {
    const date = new Date(shoot.shoot_date);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    dateStrForDisplay = date.toLocaleDateString('en-US', options);
  }

  return (
    <ShootWrapper onClick={onClick}>
      <div>
        {shoot.name} - {shoot.key}
      </div>
      <span>
        {shoot.date_range_display
          ? shoot.date_range_display
          : dateStrForDisplay}
      </span>
    </ShootWrapper>
  );
};

const Shoot = withRouter(_Shoot);

const TextInput = ({ style, loading, ...props }) => (
  <InputWrapper style={style}>
    <Input {...props} />
    {loading && <SpinnerSmall />}
  </InputWrapper>
);

const Form = ({ runQuery, clearResults }) => {
  const [schoolName, setSchoolName] = useState('');
  const [loading, setLoading] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    if (schoolName.length < 3) return clearResults();

    clearTimeout(timeout?.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      await runQuery(schoolName);
      setLoading(false);
    }, 300);
  }, [schoolName]);

  return (
    <Section>
      <Fields>
        <Instructions style={{ gridArea: 'instructions' }}>
          Enter your school name
        </Instructions>

        <TextInput
          loading={loading}
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
          placeholder="School Name"
          style={{ gridArea: 'school' }}
        />
      </Fields>
    </Section>
  );
};

const Shoots = ({ shoots }) => {
  if (!shoots.length) return <div />;

  return (
    <Section style={{ paddingTop: 0 }}>
      <ShootsWrapper>
        <ResultTitle>select your event</ResultTitle>
        {shoots.map((s) => (
          <Shoot shoot={s} />
        ))}
      </ShootsWrapper>
    </Section>
  );
};

function FindKey({}) {
  const [shoots, setShoots] = useState([]);

  const shootLookup = async (query) => {
    const shootResults = await axios.get('/api/v2/shoots/school-lookup', {
      params: { query },
    });
    setShoots(shootResults.data.shoots);
  };

  const clearResults = () => {
    setShoots([]);
  };

  return (
    <Wrapper>
      <Title>Prepay Key Lookup</Title>
      <BackLink to="/dashboard">&larr; Back</BackLink>
      <Box>
        <Form runQuery={shootLookup} clearResults={clearResults} />
        <Shoots shoots={shoots} />
      </Box>
    </Wrapper>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    parent: state.parent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addStudent,
      getStudents,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FindKey);
