import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStudents } from '../../actions/action_parent';

// React.js components
import ParentSignupFormStep1 from './parent_signup_form_step_1';
import ParentSignupFormStep2 from './parent_signup_form_step_2';

class ParentSignupFormContainer extends Component {
  constructor(props) {
    super(props);

    let step;
    if (this.props.history.location.state) {
      step = this.props.history.location.state.step;
    } else {
      step = 1;
    }

    this.state = {
      step: step,
    };

    this.updateStep = this.updateStep.bind(this);
  }

  updateStep(step) {
    this.setState({ step: step });
  }

  renderParentSignupForm() {
    switch (this.state.step) {
      case 1:
        return (
          <ParentSignupFormStep1
            history={this.props.history}
            updateStep={this.updateStep}
          />
        );
      case 2:
        return <ParentSignupFormStep2 history={this.props.history} />;
    }
  }

  componentWillReceiveProps(nextProps) {
    const that = this;
    // update step to 2 when directly accessing
    if (nextProps.auth.currentUserId && !nextProps.auth.signupCompleted) {
      this.setState({ step: 2 });
      // redirect to dashboard when directly accessing "/signup/" URL
      // if current user is truthy and completed signup
    } else if (nextProps.auth.currentUserId && nextProps.auth.signupCompleted) {
      this.props.getStudents(nextProps.auth.currentUserId).then(() => {
        that.props.history.replace('/dashboard');
      });
    }
  }

  render() {
    return <div className="parent-signup">{this.renderParentSignupForm()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getStudents }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentSignupFormContainer);
