import React from 'react';

const LandingTipping = ({ history }) => {
  return (
    <div className="gb-landing">
      <img className="tipping-landing__logo" />
      <div className="gb-landing__top clearfix">
        <div className="gb-landing__top__left">
          <h1 className="gb-landing__top__left__header">Welcome</h1>
          <h3 className="gb-landing__top__left__subheader">
            To the brand-new Tipping School Pictures online ordering platform.
            Sign in or create an account to get started.
          </h3>
          <div
            className="gb-landing__signup-button cursor-pointer"
            onClick={() => history.push('/account-lookup', { step: 1 })}
          >
            Place Your Order
          </div>
        </div>
        <img
          className="gb-landing__top__image"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_main.png"
        />
      </div>
      <div className="gb-landing__center">
        <h2 className="gb-landing__center__header">Online Exclusives</h2>
        <div className="clearfix">
          <div className="gb-landing__center__left">
            <div className="gb-landing__center__box-header">
              More Backgrounds
            </div>
            <div className="gb-landing__center__box-desc">
              Order online and get access to over 150 exciting backgrounds you
              won't find on our flyer!
            </div>
          </div>
          <div className="gb-landing__center__right">
            <div className="gb-landing__center__box-header">
              Pay with Credit Card
            </div>
            <div className="gb-landing__center__box-desc">
              No more stuffing checks or cash into paper envelopes. Pay securely
              with your credit card.
            </div>
          </div>
        </div>
      </div>
      <div className="gb-landing__bottom clearfix">
        <div className="gb-landing__bottom__left">
          <h2 className="gb-landing__bottom__left__header">
            Print or Digital, Quality first.
          </h2>
          <div className="gb-landing__bottom__left__desc">
            It starts with capturing a great photo, but it doesn't end there.
            Quality products and great customer service are the cornerstones of
            what we do.
          </div>
        </div>
        <img
          className="gb-landing__bottom__right"
          src="https://s3.amazonaws.com/pics-assets/landing/gb_phone.png"
        />
      </div>
    </div>
  );
};

export default LandingTipping;
