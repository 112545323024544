import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import BackLink from '../shared/BackLink';
import { Header, PrimaryButton, SecondaryButton } from '../v3Styles';
import { BlueSubHeader, ButtonRow, Wrapper } from './yearbookStyles';

const CompleteWrapper = styled.div`
  margin: 57px 0;
  width: 929px;
  max-width: 90%;
`;

const SelectYearbookComplete = ({ history }) => {
  const handleContinueClick = () => {
    history.push('/v3/incentive-offers');
  };

  const handleReturnClick = () => {
    history.push('/dashboard');
  };

  return (
    <Wrapper>
      <BackLink history={history} />
      <CompleteWrapper>
        <Header>Thank you for selecting your yearbook pose!</Header>
        <BlueSubHeader>
          Would you like to purchase any of your yearbook photos?
        </BlueSubHeader>
      </CompleteWrapper>
      <ButtonRow>
        <SecondaryButton onClick={handleReturnClick}>
          Return to My Galleries
        </SecondaryButton>
        <PrimaryButton onClick={handleContinueClick}>
          Yes, continue
        </PrimaryButton>
      </ButtonRow>
    </Wrapper>
  );
};

SelectYearbookComplete.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    block: PropTypes.func.isRequired,
  }).isRequired,
};

export default SelectYearbookComplete;
