export default function formatUSD(amount, displayAsWholeNumber = false) {
  // Convert the amount to dollars
  let dollarAmount = amount / 100;

  if (displayAsWholeNumber) {
    // Convert to a whole number if needed
    dollarAmount = Math.floor(dollarAmount);
    return `$${dollarAmount}`;
  }

  if (dollarAmount < 0) {
    dollarAmount *= -1.0;

    return `-$${dollarAmount.toFixed(2)}`;
  }

  // If not, return the price with two decimal places
  return `$${dollarAmount.toFixed(2)}`;
}
