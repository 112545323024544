export const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA';
export const RESET_ADDITIONAL_DATA = 'RESET_ADDITIONAL_DATA';

export function setAdditionalData(payload) {
  return {
    type: SET_ADDITIONAL_DATA,
    payload,
  };
}

export function resetAdditionalData() {
  return {
    type: RESET_ADDITIONAL_DATA,
  };
}
