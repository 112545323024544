import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import usePreventNavigation from '../../../../hooks/usePreventNavigation';
import formatUSD from '../../../../utils/formatUSD.js';
import {
  addToCart,
  declineRetouching,
  markRetouchingPrompted,
} from '../../../../actions/v3Order';

const RetouchingConfig = (props) => {
  const {
    additionalData: { selectedStudent },
    addToCart,
    declineRetouching,
    history,
    markRetouchingPrompted,
    shoot: { retouchingProducts },
  } = props;

  // prevent user from navigating away from this page
  usePreventNavigation(history, '/v3/retouching');

  useEffect(() => {
    markRetouchingPrompted(selectedStudent.id);
  }, []);

  const filteredRetouchingProducts = [];
  const retouchingProductName = [];

  if (
    typeof retouchingProducts === 'object' &&
    !Array.isArray(retouchingProducts)
  ) {
    filteredRetouchingProducts.push(retouchingProducts);
  } else {
    // Filter the list of retouching products by name to ensure we're
    // not displaying more than one instance.
    for (let i = 0; i < retouchingProducts.length; i++) {
      if (!retouchingProductName.includes(retouchingProducts[i].name)) {
        retouchingProductName.push(retouchingProducts[i].name);
        filteredRetouchingProducts.push(retouchingProducts[i]);
      }
    }
  }

  // TODO: move this to the middleware
  // If no retouching products are available, push to offerings
  if (filteredRetouchingProducts.length === 0) {
    history.push('/v3/offers');
  }

  const marginBottomStyle = { marginBottom: '40px' };
  const marginTopZeroTyle = { marginTop: '0px' };

  const retouchingProductsList = filteredRetouchingProducts.map((product) => (
    <div key={product.id}>
      <div className="bnl-product-config__product">
        <h5 className="bnl-product-config__desc">{product.name}</h5>
        <img
          className="bnl-product-config__product-image"
          src={product.image_thumbnail}
        />
        <div className="bnl-product-config__product-desc">
          {product.description}
        </div>
        <div className="bnl-product-config__product-desc">
          {formatUSD(product.price)}
        </div>
      </div>
      <div
        className="bnl-product-config__button button"
        onClick={() => addProductToCart(product)}
        style={marginBottomStyle}
      >
        Add {product.name}
      </div>
    </div>
  ));

  const addProductToCart = (product) => {
    addToCart({
      studentId: selectedStudent.id,
      fullproductData: product,
    });
  };

  return (
    <>
      <h3 className="bnl-product-config__header_two">Retouching</h3>
      <div className="row">
        <div className="col-xs-12 col-md-8 col-md-push-4">
          <div className="bnl-product-config__none">
            <p>
              Select 'Add Retouching' if you would like to add it to your order.
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 col-md-pull-8">
          {retouchingProductsList}
          <div
            className="bnl-product-config__button--cancel button bg--dark-grey"
            onClick={() => declineRetouching(selectedStudent.id)}
            style={{ ...marginBottomStyle, ...marginTopZeroTyle }}
          >
            No Thanks
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  additionalData: state.additionalData,
  shoot: state.shoot,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addToCart,
      declineRetouching,
      markRetouchingPrompted,
    },
    dispatch,
  );
}

RetouchingConfig.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    block: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RetouchingConfig);
