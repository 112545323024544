/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  addToConfigStack,
  clearProductConfig,
  popItemFromConfig,
  setProductConfig,
} from '../../../actions/v3Order';
import cartUtils from '../../../utils/cart';
import formatUSD from '../../../utils/formatUSD';
import productConfigUtils from '../../../utils/productConfig';
import { shouldBreakConfigLoop } from '../helpers/config';
import { getProductByLocation } from '../helpers/store';
import useScreenSize, { MOBILE_L } from '../hooks/screen';
import DoneScreen from './done';
import SlotSelector from './slotSelector';
import { addToConfig, getTotalPages } from './utils';

const ConfigScreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 100px;
  ${'' /* margin-top: 100px; */}

  @media (max-width: ${MOBILE_L}px) {
    align-items: center;
    justify-content: center;
  }

  h3 {
    margin-bottom: 30px;
  }
`;

const Instruction = styled.span`
  color: #479de3;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 160px;
  align-items: center;
  font-weight: 800;
  cursor: pointer;
  font-size: 12px;
  ${'' /* border: 2px solid red; */}
  text-align: center;
  height: 200px;
  p {
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 1.5;
    display: inline-block;
    ${'' /* border: 1px solid purple; */}
  }
`;

const ItemImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  padding-top: 50%; // 1:1 Aspect Ratio
  ${'' /* background-color: #f0f0f0; // Placeholder color */}
  ${'' /* border: 1px solid yellow; */}

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: contain;
  }
`;

const ItemImage = styled.img`
  margin-bottom: 8px;
  width: 100%;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const ItemPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: ${MOBILE_L}px) {
    margin-left: 8%;
    margin-right: 8%;
  }

  @media (min-width: ${MOBILE_L}px) {
    margin-top: 25px;
  }
`;

const CategorySelector = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const CategoryButton = styled.button`
  height: auto;
  padding: 10px;
  font-size 14px;
  border-radius: 3px;
  font-weight: 800;
  margin: 5px;
  width: 200px;
  color: ${(props) => (props.selected ? 'white' : '#479DE3')};
  border: ${(props) => (props.selected ? 'none' : '1px solid #479DE3')};
  background: ${(props) => (props.selected ? '#479DE3' : 'none')};
  pointer-events: ${(props) => (props.selected ? 'none' : 'initial')};
`;

const ItemsGrid = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 30px;
  grid-template-columns: repeat(${(props) => props.itemsMobile || 2}, 1fr);

  @media (max-width: ${MOBILE_L}px) {
    align-self: center;
    width: 100%;
  }

  @media (min-width: ${MOBILE_L}px) {
    grid-template-columns: repeat(${(props) => props.itemsDesktop || 3}, 1fr);
  }
`;

const ItemImageBackgroundSelection = styled.img`
  width: 128px; // 8x10 aspect ratio
  height: 160px;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  @media (max-width: ${MOBILE_L}px) {
    height: 120px;
    width: 96px; // adjusted to keep 8x10 ratio
  } ;
`;

const PoseImage = styled.img`
  width: 128px; // 8x10 aspect ratio
  height: 160px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: ${MOBILE_L}px) {
    height: 120px;
    width: 96px; // adjusted to keep 8x10 ratio
  } ;
`;

const ItemImageBackgroundWrapper = styled.div`
  width: 128px; // 8x10 aspect ratio
  height: 160px;
  position: relative;
  cursor: pointer;
  @media (max-width: ${MOBILE_L}px) {
    height: 120px;
    width: 96px; // adjusted to keep 8x10 ratio
  } ;
`;

const BackgroundNamePriceWrapper = styled.div`
  position: relative;
  p {
    margin-top: 6px !important;
  }
`;

const getLastPoseUrl = (productConfig) => {
  const lastKey = Object.keys(productConfig).sort((a, b) => b - a)[0];
  const lastPose = productConfig[lastKey]?.pose;
  const url = lastPose?.selected_crop
    ? lastPose.selected_crop?.url
    : lastPose?.url;
  return url || null;
};

const Item = ({ src, onClick = () => {}, name, price, location }) => {
  const { pathname } = location;
  const { productConfig } = useSelector((state) => state.v3Order);
  const event = useSelector((state) => state.shoot);
  const poseUrl = getLastPoseUrl(productConfig);
  const { studentPreviewImageUrl, studentId } = useSelector(
    (state) => state.additionalData,
  );
  const orders = useSelector((state) => state.v3Order.orders);

  let isBackgroundSelection = false;

  if (pathname && pathname.includes('background')) {
    isBackgroundSelection = true;
  }

  let chargeForYpxBackgroundUpgrades;

  if (typeof event.chargeForYpxBackgroundUpgrades === 'boolean') {
    chargeForYpxBackgroundUpgrades = event.chargeForYpxBackgroundUpgrades;
  } else {
    chargeForYpxBackgroundUpgrades = true;
  }

  const orderContainsSingleBackgroundUpgrade =
    cartUtils.orderContainsSingleBackgroundUpgrade(orders, studentId);

  const productConfigContainsSingleBackgroundUpgrade =
    productConfigUtils.productConfigContainsSingleBackgroundUpgrade(
      productConfig,
    );

  return (
    <ItemWrapper>
      {isBackgroundSelection && (
        <>
          <ItemImageBackgroundWrapper onClick={onClick}>
            <ItemImageBackgroundSelection src={src} alt={name} />
            {(poseUrl || studentPreviewImageUrl) && (
              <PoseImage
                src={poseUrl || studentPreviewImageUrl}
                alt="Pose Image"
                onContextMenu={(event) => event.preventDefault()}
              />
            )}
          </ItemImageBackgroundWrapper>
          <BackgroundNamePriceWrapper>
            <p>
              {name}
              {price &&
                !orderContainsSingleBackgroundUpgrade &&
                !productConfigContainsSingleBackgroundUpgrade &&
                chargeForYpxBackgroundUpgrades && (
                  <>
                    <br />
                    {formatUSD(price)}
                  </>
                )}
            </p>
          </BackgroundNamePriceWrapper>
        </>
      )}
      {!isBackgroundSelection && (
        <>
          <ItemImageContainer>
            <ItemImage
              src={src}
              onClick={onClick}
              alt={name}
              onContextMenu={(event) => event.preventDefault()}
            />
          </ItemImageContainer>
          <p>{name.length > 24 ? name.substring(0, 24) + '...' : name}</p>
        </>
      )}
    </ItemWrapper>
  );
};

const _ItemButton = ({ pages, history, item, cropIndex = null, ...props }) => {
  const { productConfig } = useSelector((s) => s.v3Order);
  const event = useSelector((s) => s.shoot);
  const dispatch = useDispatch();

  const handleClick = () => {
    addToConfig({
      dispatch,
      history,
      productConfig,
      pages,
      itemToAdd: item,
      event,
      cropIndex,
    });
  };

  return <Item price={item.price || null} {...props} onClick={handleClick} />;
};

const ItemButton = withRouter(_ItemButton);

export const ConfigScreen = ({
  shoot,
  items,
  categories,
  itemsDesktop,
  itemsMobile,
  instruction,
  gridStyle = {},
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const [screenSize, loadingScreenSize] = useScreenSize();
  const hash = props.location.hash?.replace('#', '');
  const product = getProductByLocation(shoot?.shootProducts, location);
  const { name, price, maximum_children_count: slotCount } = product;
  const hasHash = !!hash;
  const pages = getTotalPages(shoot, props);
  const { productConfig } = useSelector((s) => s.v3Order);

  useEffect(() => {
    const currentUrlSplit = props.history.location.pathname.split('/');
    const currentSection = currentUrlSplit[currentUrlSplit.length - 2];
    const currentIndex = parseInt(
      currentUrlSplit[currentUrlSplit.length - 1]?.replace('#slots', ''),
    );
    const item = productConfig[currentIndex];
    if (item && item[currentSection])
      dispatch(popItemFromConfig(currentIndex, currentSection));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  const shouldShowSlots = screenSize !== 'Mobile' || hasHash;
  const shouldShowItems = screenSize !== 'Mobile' || !hasHash;

  if (loadingScreenSize) return <div />;

  const ItemGrid = ({
    itemsDesktop,
    itemsMobile,
    items,
    pages,
    history,
    gridStyle = {},
  }) => (
    <ItemsGrid
      itemsDesktop={itemsDesktop}
      itemsMobile={itemsMobile}
      style={gridStyle}
    >
      {items.map((item) => {
        if (item.cropped_images?.length > 0) {
          return item.cropped_images.map((image, i) => (
            <ItemButton
              key={`cropped-img-${image.id}`}
              history={history}
              pages={pages}
              id={item.id}
              name={image.name}
              src={image.url}
              shoot={shoot}
              item={item}
              cropIndex={i}
              {...props}
            />
          ));
        }
        return (
          <ItemButton
            key={item.id}
            history={history}
            pages={pages}
            id={item.id}
            name={item.display_name || item.name}
            src={item.image_thumbnail || item.url}
            {...props}
            shoot={shoot}
            item={item}
          />
        );
      })}
    </ItemsGrid>
  );

  const CategoryGrid = ({
    itemsDesktop,
    itemsMobile,
    pages,
    categories = [],
    gridStyle,
  }) => {
    const [visibleCategory, setVisibleCategory] = useState(categories[0]);

    return (
      <>
        <CategorySelector>
          {categories.map((c) => (
            <CategoryButton
              key={c.name}
              selected={c.name === visibleCategory.name}
              onClick={() => setVisibleCategory(c)}
            >
              {c.name}
            </CategoryButton>
          ))}
        </CategorySelector>
        <ItemGrid
          pages={pages}
          itemsMobile={itemsMobile}
          itemsDesktop={itemsDesktop}
          gridStyle={gridStyle}
          items={visibleCategory.items}
        />
      </>
    );
  };

  return (
    <>
      <ConfigScreenWrapper>
        {shouldShowSlots && (
          <SlotSelector pages={pages} instruction={instruction} />
        )}
        {shouldBreakConfigLoop(shoot.shootProducts, props.location) &&
        shouldShowItems ? (
          <DoneScreen product={product} />
        ) : shouldShowItems ? (
          <ItemPanel>
            {screenSize !== 'Desktop' && (
              <h3>
                {name} <br />
                <Instruction>{instruction}</Instruction>
              </h3>
            )}
            {children ? (
              children
            ) : categories ? (
              <CategoryGrid
                itemsDesktop={itemsDesktop}
                itemsMobile={itemsMobile}
                gridStyle={gridStyle}
                categories={categories}
                pages={pages}
              />
            ) : (
              <ItemGrid
                itemsDesktop={itemsDesktop}
                itemsMobile={itemsMobile}
                gridStyle={gridStyle}
                items={items}
                pages={pages}
              />
            )}
          </ItemPanel>
        ) : (
          <div />
        )}
      </ConfigScreenWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    additionalData: state.additionalData,
    shoot: state.shoot,
    order: state.v3Order,
    hasPoses: state.additionalData?.availablePoses?.length,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setProductConfig,
      clearProductConfig,
      addToConfigStack,
    },
    dispatch,
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfigScreen),
);
