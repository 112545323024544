import React from 'react';
import PropTypes from 'prop-types';
import formatUSD from '../../../utils/formatUSD';

const CategoryHeader = ({
  categoryContainsMetallicProducts,
  categoryName,
  highestPriceNthFreeQualifier,
  isNthFree,
  metallicProductsFilterActive,
  setMetallicProductsFilterActive,
}) => {
  if (categoryContainsMetallicProducts) {
    const metallicSelected =
      metallicProductsFilterActive === true
        ? 'bnl-product-groups__categories__category--selected'
        : '';
    const nonMetallicSelected =
      metallicProductsFilterActive !== true
        ? 'bnl-product-groups__categories__category--selected'
        : '';

    return (
      <div className="clearfix">
        <h2 className="bnl-product-groups__header bnl-product-groups__header--categorized">
          {categoryName}
        </h2>
        <div className="bnl-product-groups__categories clearfix">
          <div
            className={`bnl-product-groups__categories__category ${nonMetallicSelected}`}
            onClick={() => setMetallicProductsFilterActive(false)}
          >
            Basic Paper
          </div>
          <div
            className={`bnl-product-groups__categories__category ${metallicSelected}`}
            onClick={() => setMetallicProductsFilterActive(true)}
          >
            Metallic Paper
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="clearfix">
      <h2 className="bnl-product-groups__header">
        {isNthFree
          ? 'Select a Free Product of Equal of Lesser Value'
          : categoryName}
      </h2>
      {isNthFree && (
        <p>qualified for: {formatUSD(highestPriceNthFreeQualifier)}</p>
      )}
    </div>
  );
};

CategoryHeader.propTypes = {
  categoryContainsMetallicProducts: PropTypes.bool.isRequired,
  categoryName: PropTypes.string.isRequired,
  isNthFree: PropTypes.bool.isRequired,
  metallicProductsFilterActive: PropTypes.bool.isRequired,
  setMetallicProductsFilterActive: PropTypes.func.isRequired,
};

export default CategoryHeader;
