import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BnlOrderProductRegular extends Component {
  constructor(props) {
    super(props);

    this.renderProductImage = this.renderProductImage.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
  }

  renderPrice() {
    const { product } = this.props;
    const { displayPrice } = this.props;
    const valueStyle = { fontSize: '16px' };

    if (product.product_type === 'nth_free') {
      return (
        <div>
          <span>FREE</span>
          <br />
          <span style={valueStyle}>
            value: {displayPrice(product.nth_free_value)}
          </span>
        </div>
      );
    }
    return <span>{displayPrice(product.price)}</span>;
  }

  renderProductImage() {
    const { product } = this.props;

    if (product.display_image_in_list) {
      return (
        <div className="bnl-product-regular__image-container">
          <img
            className="bnl-product-regular__image"
            src={product.image_thumbnail}
            alt={product.name}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    const { product } = this.props;
    const { renderContents } = this.props;

    if (product.display_product_in_list) {
      return (
        <div>
          <div className="row">
            <h3 className="bnl-product__name col-xs-8 col-md-8 col-lg-8">
              {product.name}
            </h3>
            <div className="bnl-product__price col-xs-4 col-md-4 col-lg-4">
              {this.renderPrice()}
            </div>
          </div>
          {this.renderProductImage()}
          <div className="bnl-product-regular__desc">{product.description}</div>
          <div className="bnl-product__items row">
            {renderContents(product)}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default BnlOrderProductRegular;

BnlOrderProductRegular.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    display_image_in_list: PropTypes.bool,
    display_product_in_list: PropTypes.bool,
    image_thumbnail: PropTypes.string,
    name: PropTypes.string,
    nth_free_value: PropTypes.number,
    price: PropTypes.number,
    product_type: PropTypes.string,
  }).isRequired,
  renderContents: PropTypes.func.isRequired,
  displayPrice: PropTypes.func.isRequired,
};
