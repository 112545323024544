import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../v3Styles';

export const MultiConfigHeader = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 30px;
`;

export const MultiConfigSubHeader = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;

export const MultiConfigGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 80px;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 515px) {
    gap: 15px;
  }
`;

export const MultiConfigGridItem = styled.div`
  aspect-ratio: 8/10;
  position: relative;
  cursor: pointer;
  border: 1px solid lightgrey;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ProductItemWrapper = styled.div``;

export const ProductItem = styled(MultiConfigGridItem)`
  background-color: #a0a0a0;
  object-fit: cover;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: contain;
  }
`;

export const ProductItemButton = styled(PrimaryButton)`
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

export const ProductItemSecondaryButton = styled(SecondaryButton)`
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

export const ProductItemName = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 700px;
  margin: 0;
  text-align: center;
  margin-top: 15px;
  @media screen and (max-width: 515px) {
    margin-top: 5px;
  }
`;

export const MultiConfigButton = styled.div`
  background: #0b76b7;
  border-color: #0b76b7;
  border-radius: 6px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  padding: 13px 28px;
  margin: 0 auto;
  display: block;
  width: 172px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
`;

export const ProductOptionsWrapper = styled.div`
  margin: 20px 0;
`;
