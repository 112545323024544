import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import IncentiveConfigurationPose from './incentive_configuration_pose';
import IncentiveConfigurationBackground from './incentive_configuration_background';
import BnlOrderProductConfigurationPersonalization from '../orders/bnl/bnl_order_product_configuration_personalization';
import IncentiveConfigurationNone from './incentive_configuration_none';
import BnlOrderSmsForm from '../orders/bnl/bnl_order_sms_form';
import IncentiveDesktopConfiguration from './incentive_desktop_configuration';
import IncentiveMobileConfiguration from './incentive_mobile_configuration';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';
import { DUMMY_IMAGE_URL } from '../../constants/image_urls';

class IncentiveConfigurationContainer extends Component {
  constructor(props) {
    super(props);

    this.STEPS = {
      pose: 1,
      background: 2,
      personalization: 3,
      sms: 4,
      none: 5,
    };

    // this.state = {
    //   activeStep: this.getInitialActiveStep(),
    //   configuration: this.props.configuration,
    //   configuringIndex: 0,
    //   configuringItems: this.getConfiguringItems(),
    //   mobileConfiguring: false,
    //   selectedPose: this.getInitialSelectedPose(),
    //   selectedBackground: this.getInitialSelectedBackground(),
    // };

    this.state = {
      activeStep: this.getInitialActiveStep(),
      configuration: this.props.configuration,
      configuringIndex: 0,
      configuringItems: this.getConfiguringItems(),
      mobileConfiguring: false,
      selectedPose: {},
      selectedBackground: {},
    };

    this.addConfiguredIncentiveItemsToCart =
      this.addConfiguredIncentiveItemsToCart.bind(this);
    this.selectBackground = this.selectBackground.bind(this);
    this.selectPose = this.selectPose.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goToNextItem = this.goToNextItem.bind(this);
    this.presetConfiguration = this.presetConfiguration.bind(this);
    this.getInstruction = this.getInstruction.bind(this);
    this.getConfiguringItem = this.getConfiguringItem.bind(this);
    this.renderBackButton = this.renderBackButton.bind(this);
    this.renderDesktopConfiguringItem =
      this.renderDesktopConfiguringItem.bind(this);
    this.renderMobileConfiguringItem =
      this.renderMobileConfiguringItem.bind(this);
    this.renderActiveStep = this.renderActiveStep.bind(this);
    this.renderSmsForm = this.renderSmsForm.bind(this);
    this.getPersonalizationValue = this.getPersonalizationValue.bind(this);
    this.goToMobilePoseStep = this.goToMobilePoseStep.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.selectMobilePose = this.selectMobilePose.bind(this);
    this.goToMobileBackgroundStep = this.goToMobileBackgroundStep.bind(this);
    this.selectMobileBackground = this.selectMobileBackground.bind(this);
    this.renderMobileButton = this.renderMobileButton.bind(this);
    this.submitSmsPhone = this.submitSmsPhone.bind(this);
    this.renderMobileBackButton = this.renderMobileBackButton.bind(this);
    this.goBackMobile = this.goBackMobile.bind(this);
    this.renderPersonalizationForm = this.renderPersonalizationForm.bind(this);
  }

  async componentDidMount() {
    console.log('%cSTART---------------------------------', 'color: red');
    console.log('%cmounted incentive configuration container', 'color: red');

    const { cartItems, configuration, incentiveConfiguration } = this.props;

    console.log(
      '%cprops in component did mount +++++++++++++++++++++++++++++++++',
      'color: red',
    );
    console.log('cartItems', cartItems);
    console.log('configuration', configuration);
    console.log('incentiveConfiguration', incentiveConfiguration);
    console.log(this.props);
    console.log(
      '%cprops in component did mount +++++++++++++++++++++++++++++++++',
      'color: red',
    );

    const shouldShowRetouchOrInterstitial =
      await this.props.shouldOfferRetouchingOrInterstitial();

    if (!shouldShowRetouchOrInterstitial) {
      Swal.fire(
        'Congratulations',
        'You qualify for a free bonus item. Please add your bonus item to your cart. You may need to select a background.',
      );
    }

    console.log('%c---------------------------------END', 'color: red');
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(
  //     '%cprops in componentDidUpdate +++++++++++++++++++++++++++++++++',
  //     'color: green',
  //   );

  //   console.log('prevProps', prevProps);
  //   console.log('prevState', prevState);
  //   console.log(this.state);
  //   console.log(this.props);

  //   if (
  //     this.props.cartItems &&
  //     this.props.cartItems.length > 0 &&
  //     this.state.configuringItems.length != this.props.cartItems.length
  //   ) {
  //     const items = [];
  //     const { cartItems, configuration, incentiveConfiguration } = this.props;

  //     cartItems.forEach((item, i) => {
  //       if (!configuration[item.id].configured && item.requires_configuration) {
  //         items.push(item);
  //       }
  //     });

  //     // TODO: this is a bit of a hack -- should be addressed at some point
  //     if (items.length === 0 && incentiveConfiguration) {
  //       Object.keys(incentiveConfiguration).forEach((key, i) => {
  //         if (!configuration[key].configured) {
  //           items.push(configuration[key]);
  //         }
  //       });
  //     }

  //     this.setState({ configuringItems: items });
  //   }

  //   // this.getConfiguringItems();
  //   // this.setState({this.configuringItems: this.getConfiguringItems()});
  //   // const { cartItems, configuration, incentiveConfiguration } = this.props;
  //   // console.log(
  //   //   '%cprops in component did mount +++++++++++++++++++++++++++++++++',
  //   //   'color: green',
  //   // );
  //   // console.log('cartItems', cartItems);
  //   // console.log('configuration', configuration);
  //   // console.log('incentiveConfiguration', incentiveConfiguration);
  //   // console.log(this.props);
  //   // console.log(
  //   //   '%cprops in component did mount +++++++++++++++++++++++++++++++++',
  //   //   'color: green',
  //   // );
  // }

  getInitialActiveStep() {
    // console.log('getInitialActiveStep');
    const firstConfiguringItem = this.getConfiguringItems()[0];
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    // console.log(this.getConfiguringItems());
    // console.log(firstConfiguringItem);
    // console.log('++++');

    if (
      (firstConfiguringItem &&
        firstConfiguringItem.name === 'Super CD + Cloud Download') ||
      (firstConfiguringItem &&
        this.productDoesNotRequirePoseOrBackground(firstConfiguringItem))
    ) {
      if (firstConfiguringItem.sms_required && !this.props.order.smsAcquired) {
        return this.STEPS.sms;
      }
      return this.STEPS.none;
    }

    if (this.shootIsPreShoot()) {
      return this.STEPS.background;
    }

    if (isGeskus && isPostEvent && studentPreviewImageUrl) {
      return this.STEPS.background;
    }

    return this.STEPS.pose;
  }

  shootIsPreShoot() {
    return this.props.bnlPreShoot.preShoot;
  }

  productDoesNotRequirePoseOrBackground(product) {
    // console.log('productDoesNotRequirePoseOrBackground');
    // console.log(`here is the product: ${product}`);

    return product.background_options_count === 0 && product.poses_count === 0;
  }

  // TODO: revist to figure out how we're handling order-level items
  getConfiguringItems() {
    const items = [];
    const that = this;
    const { cartItems, configuration, incentiveConfiguration } = this.props;

    console.log('getConfigurtingItems +++++++++++++++++++++++++++++++++');
    console.log('cartItems', cartItems);
    console.log('configuration', configuration);
    console.log('incentiveConfiguration', incentiveConfiguration);
    console.log(this.props);

    cartItems.forEach((item, i) => {
      if (
        (!configuration[item.id] && item.requires_configuration) ||
        (!configuration[item.id]?.configured && item.requires_configuration)
      ) {
        items.push(item);
      }
    });

    // TODO: this is a bit of a hack -- should be addressed at some point
    if (items.length === 0 && incentiveConfiguration) {
      Object.keys(incentiveConfiguration).forEach((key, i) => {
        if (!configuration[key].configured) {
          items.push(configuration[key]);
        }
      });
    }

    console.log('%c--------------------', 'color: yellow');
    console.log('%cCONFIGURING ITEMS', 'color: yellow');
    console.log(items);
    console.log(this.props.order);
    console.log('%c--------------------', 'color: yellow');

    return items;
  }

  getConfiguringItem() {
    return this.state.configuringItems[this.state.configuringIndex];
  }

  getInitialSelectedPose() {
    return {};
    // let pose = null;
    //
    // for (let incentiveItemId in this.props.configuration) {
    //   let innerConfig = this.props.configuration[incentiveItemId];
    //
    //   if (!innerConfig.configured && innerConfig.pose) {
    //     pose = innerConfig.pose;
    //   }
    // }
    //
    // return pose;
  }

  getInitialSelectedBackground() {
    return {};
  }

  selectedPose() {
    return !!this.state.selectedPose[this.state.configuringIndex];
  }

  selectedBackground() {
    return !!this.state.selectedBackground[this.state.configuringIndex];
  }

  configuringItemRequiresSms() {
    return this.configuringItem().sms_required;
  }

  configuringItemIsSuperCd() {
    return this.configuringItem().name === 'Super CD + Cloud Download';
  }

  handleOnChange(event) {
    const configuration = this.props.cloneObject(this.state.configuration);
    const innerConfiguration = (configuration[
      this.configuringItem().id
    ].personalizationValue = event.target.value);

    this.setState({ configuration });
  }

  getPersonalizationValue() {
    const configuration = this.state.configuration[this.configuringItem().id];

    if (configuration) {
      return configuration.personalizationValue;
    }

    return '';
  }

  getDesktopSelectedPose(configuringIndex) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    if (!this.shootIsPreShoot()) {
      // Handle Geskus Post-Event
      // TODO: revisit
      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        return (
          <div className="bnl-product-config__selected-option-container">
            <img
              className="bnl-product-config__selected-pose__image"
              src={studentPreviewImageUrl}
            />
          </div>
        );
      }

      if (this.state.selectedPose[configuringIndex]) {
        return (
          <div className="bnl-product-config__selected-option-container">
            <img
              className="bnl-product-config__selected-pose__image"
              src={this.state.selectedPose[configuringIndex].url}
            />
          </div>
        );
      }

      return (
        <div className="bnl-product-config__selected-option-container">
          <img
            className="bnl-product-config__selected-pose__image"
            src={DUMMY_IMAGE_URL}
          />
          <div className="bnl-product-config__selected-pose__desc">Pose</div>
        </div>
      );
    }
  }

  goToMobilePoseStep() {
    if (!this.shootIsPreShoot()) {
      this.setState(
        {
          activeStep: this.STEPS.pose,
          mobileConfiguring: true,
        },
        () => {
          window.scrollTo(0, 0);
        },
      );
    }
  }

  goToMobileBackgroundStep(configuringIndex) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    // Handle Geskus Post-Event
    // TODO: revisit
    if (
      !this.state.selectedPose[configuringIndex] &&
      !this.shootIsPreShoot() &&
      !isGeskus &&
      !isPostEvent &&
      !studentPreviewImageUrl
    ) {
      Swal.fire('Please select a pose first');
    } else {
      this.setState(
        {
          activeStep: this.STEPS.background,
          mobileConfiguring: true,
        },
        () => {
          window.scrollTo(0, 0);
        },
      );
    }
  }

  getMobileSelectedPose(configuringIndex) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    if (!this.shootIsPreShoot()) {
      const outdoor = this.shootIsOutdoorsBackground() ? '--outdoor' : '';

      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        return (
          <div className="bnl-product-config__selected-option-container cursor-pointer">
            <img
              className="bnl-product-config__selected-pose__image"
              src={studentPreviewImageUrl}
            />
          </div>
        );
      }

      if (this.state.selectedPose[configuringIndex]) {
        return (
          <div
            className="bnl-product-config__selected-option-container cursor-pointer"
            onClick={() => this.goToMobilePoseStep(configuringIndex)}
          >
            <img
              className="bnl-product-config__selected-pose__image"
              src={this.state.selectedPose[configuringIndex].url}
            />
          </div>
        );
      }

      return (
        <div
          className="bnl-product-config__selected-option-container cursor-pointer"
          onClick={this.goToMobilePoseStep}
        >
          <img
            className="bnl-product-config__selected-pose__image"
            src={DUMMY_IMAGE_URL}
          />
          <div className="bnl-product-config__selected-pose__desc">Pose</div>
        </div>
      );
    }
  }

  getDesktopSelectedBackground(configuringIndex) {
    if (!this.shootIsOutdoorsBackground()) {
      const className = 'bnl-product-config__selected-background';
      const shootClassName = this.shootIsPreShoot()
        ? 'bnl-product-config__selected-background--pre-shoot'
        : 'bnl-product-config__selected-background--post-shoot';

      if (this.state.selectedBackground[configuringIndex]) {
        return (
          <div className={`${className} ${shootClassName}`}>
            <img
              className="bnl-product-config__selected-background__image"
              src={
                this.state.selectedBackground[configuringIndex].image_thumbnail
              }
            />
          </div>
        );
      }

      return (
        <div className={`${className} ${shootClassName}`}>
          <div className="bnl-product-config__selected-background__image" />
          <div className="bnl-product-config__selected-pose__desc">
            Background
          </div>
        </div>
      );
    }
  }

  getMobileSelectedBackground(configuringIndex) {
    if (!this.shootIsOutdoorsBackground()) {
      const { selectedBackground } = this.state;

      if (selectedBackground[configuringIndex]) {
        return (
          <div
            className="bnl-product-config__selected-option-container cursor-pointer"
            onClick={() => this.goToMobileBackgroundStep(configuringIndex)}
          >
            <img
              className="bnl-product-config__selected-background__image"
              src={selectedBackground[configuringIndex].image_thumbnail}
            />
          </div>
        );
      }

      return (
        <div
          className="bnl-product-config__selected-option-container cursor-pointer"
          onClick={() => this.goToMobileBackgroundStep(configuringIndex)}
        >
          <div className="bnl-product-config__selected-background__image" />
          <div className="bnl-product-config__selected-background__desc">
            Background
          </div>
        </div>
      );
    }
  }

  getInstruction() {
    switch (this.state.activeStep) {
      case this.STEPS.pose:
        return 'Select Pose';
      case this.STEPS.background:
        return 'Select Background';
      case this.STEPS.sms:
        return 'Fill in phone number';
    }
  }

  configuringItem() {
    // console.log('configuring item <------------------------');
    // console.log(this.state);

    return this.state.configuringItems[this.state.configuringIndex];
  }

  previouslyConfiguredItem() {
    return this.state.configuringItems[this.state.configuringIndex - 1];
  }

  hasItemToConfigure() {
    return this.state.configuringIndex + 1 < this.state.configuringItems.length;
  }

  submitSmsPhone(callback) {
    if (this.validateConfiguringItem()) {
      this.props.submitSmsPhone(callback);
    }
  }

  onLastStep() {
    const configuringItem = this.configuringItem();

    if (this.shootIsOutdoorsBackground()) {
      return this.selectedPose();
    }
    if (configuringItem.sms_required) {
      if (this.props.order.smsAcquired) {
        return this.state.activeStep === this.STEPS.none;
      }
      return this.state.activeStep === this.STEPS.sms;
    }
    return this.state.activeStep === this.STEPS.background;
  }

  shootIsOutdoorsBackground() {
    return (
      this.props.shoot.shoot.background_choice_mode ===
      'outdoors_background_only'
    );
  }

  validateConfiguringItem() {
    const { configuringIndex } = this.state;
    const { selectedPose } = this.state;
    const { selectedBackground } = this.state;
    const configuringItem = this.configuringItem();

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        if (!selectedPose[configuringIndex]) {
          Swal.fire('Please select a pose');
          return false;
        }
      case this.STEPS.background:
        if (
          !this.shootIsOutdoorsBackground() &&
          !selectedBackground[configuringIndex]
        ) {
          Swal.fire('Please select a background');
          return false;
        }
      case this.STEPS.personalization:
        if (
          this.configuringItem().personalization_status ===
          'personalization_required'
        ) {
          if (
            !this.getPersonalizationValue() ||
            !this.getPersonalizationValue().trim()
          ) {
            Swal.fire('Please fill in a personalization message');
            return false;
          }
        }
      case this.STEPS.sms:
        if (configuringItem.sms_required && !this.props.smsPhone) {
          Swal.fire('Please fill in a phone number');
          return false;
        }
    }

    if (
      this.configuringItem().personalization_status ==
      'personalization_required'
    ) {
      if (
        !this.getPersonalizationValue() ||
        !this.getPersonalizationValue().trim()
      ) {
        Swal.fire('Please fill in a personalization message');
        return false;
      }
    }

    return true;
  }

  mobileCanBeAdded() {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    if (this.configuringItemIsSuperCd()) {
      return true;
    }

    if (this.shootIsPreShoot() && this.selectedBackground()) {
      if (this.configuringItemRequiresSms()) {
        return !!this.props.smsPhone;
      }
      return true;
    }

    if (this.shootIsOutdoorsBackground()) {
      if (this.selectedPose()) {
        return true;
      }
    } else if (
      (this.selectedPose() ||
        (isGeskus && isPostEvent && studentPreviewImageUrl)) &&
      this.selectedBackground()
    ) {
      if (this.configuringItemRequiresSms()) {
        return !!this.props.smsPhone;
      }
      return true;
    }

    return false;
  }

  selectPose(pose) {
    const configuringItem =
      this.state.configuringItems[this.state.configuringIndex];

    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfig = configuration[configuringItem.id];
    itemConfig.poseIds = [pose];
    itemConfig.pose = pose;

    const selectedPose = this.props.cloneObject(this.state.selectedPose);
    selectedPose[this.state.configuringIndex] = pose;

    let activeStep;

    if (this.shootIsOutdoorsBackground()) {
      if (
        this.configuringItem().personalization_status ===
        'personalization_required'
      ) {
        activeStep = this.STEPS.personalization;
      } else if (
        this.configuringItem().sms_required &&
        !this.props.order.smsAcquired
      ) {
        activeStep = this.STEPS.sms;
      } else {
        activeStep = this.STEPS.pose;
      }
    } else {
      activeStep = this.STEPS.background;
    }

    this.setState({
      activeStep,
      configuration,
      selectedPose,
    });
  }

  selectMobilePose(pose) {
    console.log('selectMobilePose');
    console.log(pose);
    const configuringItem =
      this.state.configuringItems[this.state.configuringIndex];

    const configuration = this.props.cloneObject(this.state.configuration);

    console.log(configuration);

    const itemConfig = configuration[configuringItem.id];
    itemConfig.poseIds = [pose];
    itemConfig.pose = pose;

    const selectedPose = this.props.cloneObject(this.state.selectedPose);
    selectedPose[this.state.configuringIndex] = pose;

    this.setState(
      {
        configuration,
        mobileConfiguring: false,
        selectedPose,
      },
      () => {
        window.scrollTo(0, 0);
      },
    );
  }

  selectBackground(background) {
    const configuringItem =
      this.state.configuringItems[this.state.configuringIndex];
    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfig = configuration[configuringItem.id];
    itemConfig.backgroundCodes = [background.id];

    const selectedBackground = this.props.cloneObject(
      this.state.selectedBackground,
    );
    selectedBackground[this.state.configuringIndex] = background;

    let activeStep;
    if (
      !this.state.mobileConfiguring &&
      this.configuringItem().personalization_status ===
        'personalization_required'
    ) {
      activeStep = this.STEPS.personalization;

      this.setState({
        activeStep,
        configuration,
        selectedBackground,
      });
    } else if (this.configuringItem().sms_required) {
      if (!this.props.order.smsAcquired) {
        activeStep = this.STEPS.sms;
      } else {
        activeStep = this.STEPS.none;
      }

      this.setState({
        activeStep,
        configuration,
        selectedBackground,
      });
    } else {
      let { mobileConfiguring } = this.state;

      if (mobileConfiguring) {
        mobileConfiguring = false;
      }
      this.setState({
        configuration,
        mobileConfiguring,
        selectedBackground,
      });
    }
  }

  selectMobileBackground(background) {
    const configuringItem =
      this.state.configuringItems[this.state.configuringIndex];
    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfig = configuration[configuringItem.id];
    itemConfig.backgroundCodes = [background.id];

    const selectedBackground = this.props.cloneObject(
      this.state.selectedBackground,
    );
    selectedBackground[this.state.configuringIndex] = background;

    this.setState(
      {
        configuration,
        mobileConfiguring: false,
        selectedBackground,
      },
      () => {
        window.scrollTo(0, 0);
      },
    );
  }

  goToNextItem() {
    // console.log('goToNextItem');
    const { configuration } = this.state;
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    if (this.validateConfiguringItem()) {
      const nextItem =
        this.state.configuringItems[this.state.configuringIndex + 1];

      if (
        nextItem.name === 'Super CD + Cloud Download' ||
        (this.productDoesNotRequirePoseOrBackground(nextItem) &&
          !this.state.configuration[nextItem.id].configured)
      ) {
        let activeStep = this.STEPS.sms;
        if (this.props.order.smsAcquired) activeStep = this.STEPS.none;

        configuration[this.configuringItem().id].configured = true;

        this.setState({
          activeStep,
          configuration,
          configuringIndex: this.state.configuringIndex + 1,
        });
      } else {
        let activeStep = this.STEPS.background;

        if (!this.shootIsPreShoot()) {
          // Handle Geskus Post-Event
          // TODO: revisit
          if (isGeskus && isPostEvent && studentPreviewImageUrl) {
            // do nothing
          } else {
            activeStep = this.STEPS.pose;
          }
        }

        const configuringItem = this.configuringItem();
        configuration[configuringItem.id].configured = true;

        this.setState({
          activeStep,
          configuration,
          configuringIndex: this.state.configuringIndex + 1,
        });
      }
    }
  }

  configureSuperCd(superCdId, callback = null) {
    let configPose;
    const configuration = this.props.cloneObject(this.state.configuration);
    const itemConfig = configuration[superCdId];
    const { cpmId } = this.props.gallery;
    const galleries = this.props.gallery.galleries[cpmId];

    for (const studentId in galleries) {
      const { gallery } = galleries[studentId];
      gallery.forEach((pose, i) => {
        if (i === 0 && !configPose) configPose = pose;
      });
    }

    itemConfig.backgroundCodes = [];
    itemConfig.poseIds = [configPose];

    this.setState(
      {
        activeStep: this.STEPS.none,
        configuration,
      },
      () => {
        if (callback) callback.call();
      },
    );
  }

  processNavigationAtPose() {
    const { configuringIndex } = this.state;

    if (configuringIndex === 0) {
      this.props.updateOrderActiveComponent(
        ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
      );
    } else {
      const prevConfiguringIndex = configuringIndex - 1;
      const prevConfiguringItem =
        this.state.configuringItems[prevConfiguringIndex];
      let activeStep = this.STEPS.background;

      if (prevConfiguringItem.sms_required && !this.props.order.smsAcquired) {
        activeStep = this.STEPS.sms;
      }

      const prevConfiguringItemId = Object.keys(this.state.configuration)[
        prevConfiguringIndex
      ];
      const selectedPose = this.props.cloneObject(this.state.selectedPose);
      delete selectedPose[configuringIndex];

      this.setState({
        activeStep,
        configuringIndex: configuringIndex - 1,
        selectedPose,
      });
    }
  }

  goBack() {
    // console.log('goBack');
    let { configuringIndex } = this.state;
    const selectedPose = this.props.cloneObject(this.state.selectedPose);
    const selectedBackground = this.props.cloneObject(
      this.state.selectedBackground,
    );
    let activeStep;

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        this.processNavigationAtPose();
        return;
      case this.STEPS.background:
        if (this.shootIsPreShoot()) {
          activeStep = this.STEPS.background;
          configuringIndex -= 1;
        } else {
          activeStep = this.STEPS.pose;
        }

        delete selectedBackground[configuringIndex];
        this.setState({
          activeStep,
          configuringIndex,
          selectedBackground,
        });
        return;
      case this.STEPS.personalization:
        this.setState({ activeStep: this.STEPS.background });
        return;
      case this.STEPS.sms:
        if (
          this.configuringItem().personalization_status ===
            'personalization_required' ||
          this.configuringItem().personalization_status ===
            'personalization_optional'
        ) {
          activeStep = this.STEPS.personalization;
        } else {
          activeStep = this.STEPS.background;
        }

        this.setState({ activeStep });
        return;
      case this.STEPS.none:
        let decrement = 0;
        if (
          this.configuringItem().personalization_status ===
            'personalization_required' ||
          this.configuringItem().personalization_status ===
            'personalization_optional'
        ) {
          activeStep = this.STEPS.personalization;
        } else if (
          this.configuringItem().sms_required &&
          !this.props.order.smsAcquired
        ) {
          activeStep = this.STEPS.sms;
        } else if (
          this.productDoesNotRequirePoseOrBackground(this.configuringItem())
        ) {
          if (this.state.configuringItems.length > 1) {
            if (
              this.previouslyConfiguredItem().personalization_status ===
                'personalization_required' ||
              this.previouslyConfiguredItem().personalization_status ===
                'personalization_optional'
            ) {
              activeStep = this.STEPS.personalization;
            } else {
              activeStep = this.STEPS.background;
            }
            decrement += 1;
          } else {
            activeStep = this.state.activeStep;
          }
        }

        this.setState({
          activeStep,
          configuringIndex: configuringIndex - decrement,
        });
    }
  }

  goBackMobile() {
    let { configuringIndex } = this.state;
    const selectedPose = this.props.cloneObject(this.state.selectedPose);
    const selectedBackground = this.props.cloneObject(
      this.state.selectedBackground,
    );
    const mobileConfiguring = false;

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        if (selectedPose[configuringIndex]) {
          delete selectedPose[configuringIndex];
        }
        if (!this.state.mobileConfiguring) configuringIndex -= 1;

        break;
      case this.STEPS.background:
        if (selectedBackground[configuringIndex]) {
          delete selectedBackground[configuringIndex];
        }

        if (this.shootIsPreShoot() && !this.state.mobileConfiguring) {
          configuringIndex -= 1;
        }
        break;
      // this is very super cd specific
      case this.STEPS.sms:
      case this.STEPS.none:
        configuringIndex -= 1;
        break;
    }

    this.setState({
      configuringIndex,
      mobileConfiguring,
      selectedBackground,
      selectedPose,
    });
  }

  presetConfiguration() {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    console.log('%cpresetConfiguration', 'color: cyan');

    const that = this;
    const { cpmId } = this.props.gallery;
    const galleries = this.props.gallery.galleries[cpmId];
    const configuration = this.props.cloneObject(this.state.configuration);

    let pose;

    if (!this.shootIsPreShoot() && !isGeskus) {
      pose = Object.values(galleries)[0].gallery[0];
    }

    if (this.state.configuringItems) {
      for (let i = 0; i < this.state.configuringItems.length; i++) {
        const item = this.state.configuringItems[i];
        const itemConfig = configuration[item.id];

        if (itemConfig) {
          if (!itemConfig?.configured) {
            if (item?.name === 'Super CD + Cloud Download') {
              const poseIds = [];
              let firstPose;
              for (const studentId in galleries) {
                const { gallery } = galleries[studentId];
                gallery.forEach((pose, i) => {
                  if (i === 0 && !firstPose) {
                    firstPose = pose;
                    poseIds.push(pose);
                  }
                });
              }

              itemConfig.poseIds = poseIds;
              itemConfig.pose = firstPose;
            } else {
              if (this.shootIsOutdoorsBackground()) {
                itemConfig.backgroundCodes = [];
              } else {
                const bgCode = Object.values(
                  this.props.formattedBackgroundOptions,
                )[0].id;
                itemConfig.backgroundCodes = [bgCode];
              }

              if (this.shootIsPreShoot()) {
                itemConfig.poseIds = [];
              } else {
                itemConfig.poseIds = [pose];
              }

              if (item.personalization_status === 'personalization_required') {
                itemConfig.personalizationValue = pose.student.first_name;
              }
            }
          }
        }
      }
    }

    this.props.configureBnlIncentiveItem(
      configuration,
      this.props.studentId,
      true, // unclear why this is here...
    );
  }

  addConfiguredIncentiveItemsToCart() {
    const { studentId } = this.props;
    console.log('%cADDING CONFIGURED INCENTIVE ITEM TO CART', 'color: cyan');
    const { configuration } = this.state;
    const configuringItem = this.configuringItem();

    console.log(configuration);
    console.log(configuringItem);
    console.log('%c-----------------------------------', 'color: cyan');

    configuration[configuringItem.id].studentId = studentId;

    if (this.configuringItemIsSuperCd()) {
      this.props.configureBnlIncentiveItem(configuration, this.props.studentId);
    } else if (this.validateConfiguringItem()) {
      console.log('configuring item is valid');

      configuration[configuringItem.id].configured = true;

      this.setState({ configuration }, () => {
        this.props.configureBnlIncentiveItem(
          configuration,
          this.props.studentId,
        );
      });
    }
  }

  renderBackButton() {
    const activeStep = this.shootIsPreShoot()
      ? this.STEPS.background
      : this.STEPS.pose;

    if (
      (this.state.configuringItems.length > 1 &&
        this.state.activeStep !== activeStep) ||
      this.state.configuringIndex !== 0
    ) {
      return (
        <div className="bnl-back-button cursor-pointer" onClick={this.goBack}>
          {'<  Back'}
        </div>
      );
    }
  }

  renderMobileBackButton() {
    if (
      (this.state.configuringItems.length > 1 &&
        this.state.configuringIndex !== 0) ||
      this.state.mobileConfiguring
    ) {
      return (
        <div
          className="bnl-back-button cursor-pointer"
          onClick={this.goBackMobile}
        >
          {'<  Back'}
        </div>
      );
    }
  }

  renderSmsForm() {
    if (this.configuringItem().sms_required && !this.props.order.smsAcquired) {
      return (
        <BnlOrderSmsForm
          errorMessage={this.props.errorMessage}
          handleSmsInputChange={this.props.handleSmsInputChange}
          mobileConfiguring
          smsPhone={this.props.smsPhone}
        />
      );
    }
  }

  renderActiveStep() {
    const configuringItem = this.configuringItem();
    const configuration = this.state.configuration[configuringItem?.id];
    const { configuringIndex } = this.state;

    // issue now is that configuration is blank

    switch (this.state.activeStep) {
      case this.STEPS.pose:
        return (
          <IncentiveConfigurationPose
            configuration={configuration}
            configuringItem={configuringItem}
            selectMobilePose={this.selectMobilePose}
            selectPose={this.selectPose}
            mobileConfiguring={this.state.mobileConfiguring}
            FILTER={this.props.BNL_FILTER}
          />
        );
      case this.STEPS.background:
        return (
          <IncentiveConfigurationBackground
            configuration={configuration}
            displayPrice={this.props.displayPrice}
            formattedBackgroundOptions={this.props.formattedBackgroundOptions}
            mobileConfiguring={this.state.mobileConfiguring}
            selectBackground={this.selectBackground}
            selectMobileBackground={this.selectMobileBackground}
            selectedPose={this.state.selectedPose[configuringIndex]}
          />
        );
      case this.STEPS.personalization:
        return (
          <BnlOrderProductConfigurationPersonalization
            handleOnChange={this.handleOnChange}
            getPersonalizationValue={this.getPersonalizationValue}
            processConfiguration={this.processConfiguration}
            requirement={this.configuringItem().personalization_status}
          />
        );
      case this.STEPS.sms:
        return (
          <BnlOrderSmsForm
            errorMessage={this.props.errorMessage}
            fromIncentive
            handleSmsInputChange={this.props.handleSmsInputChange}
            smsPhone={this.props.smsPhone}
          />
        );
      case this.STEPS.none:
        return (
          <IncentiveConfigurationNone
            getPersonalizationValue={this.getPersonalizationValue}
            handleOnChange={this.handleOnChange}
          />
        );
    }
  }

  renderButton() {
    let handleOnClick = this.addConfiguredIncentiveItemsToCart;
    const configuringItem = this.configuringItem();
    const disabled = this.onLastStep() ? '' : 'disabled--bg-grey';

    if (configuringItem.sms_required) {
      if (this.props.order.smsAcquired) {
        if (this.hasItemToConfigure()) {
          handleOnClick = this.goToNextItem;
        } else if (this.configuringItemIsSuperCd()) {
          const superCdId = this.configuringItem().id;
          handleOnClick = this.configureSuperCd.bind(
            this,
            superCdId,
            this.addConfiguredIncentiveItemsToCart,
          );
        } else {
          handleOnClick = this.addConfiguredIncentiveItemsToCart;
        }
      } else if (this.hasItemToConfigure()) {
        handleOnClick = this.props.submitSmsPhone.bind(this, this.goToNextItem);
      } else {
        handleOnClick = this.props.submitSmsPhone.bind(
          this,
          this.addConfiguredIncentiveItemsToCart,
        );
      }
    } else if (this.hasItemToConfigure()) {
      handleOnClick = this.goToNextItem;
    }

    return (
      <div
        className={`incentive-config-button button--round-border ${disabled}`}
        onClick={handleOnClick}
      >
        Done, Add Incentive to Cart
      </div>
    );
  }

  renderMobileButton() {
    let handleOnClick = this.addConfiguredIncentiveItemsToCart;
    const configuringItem = this.configuringItem();
    const disabled = this.mobileCanBeAdded() ? '' : 'disabled--bg-grey';

    if (configuringItem.sms_required) {
      if (this.props.order.smsAcquired) {
        if (this.hasItemToConfigure()) {
          handleOnClick = this.goToNextItem;
        } else if (this.configuringItemIsSuperCd()) {
          const superCdId = this.configuringItem().id;
          handleOnClick = this.configureSuperCd.bind(
            this,
            superCdId,
            this.addConfiguredIncentiveItemsToCart,
          );
        } else {
          handleOnClick = this.addConfiguredIncentiveItemsToCart;
        }
      } else if (this.hasItemToConfigure()) {
        handleOnClick = this.props.submitSmsPhone.bind(this, this.goToNextItem);
      } else {
        handleOnClick = this.props.submitSmsPhone.bind(
          this,
          this.addConfiguredIncentiveItemsToCart,
        );
      }
    } else if (this.hasItemToConfigure()) {
      handleOnClick = this.goToNextItem;
    }

    return (
      <div
        className={`incentive-config-button button--round-border ${disabled}`}
        onClick={handleOnClick}
      >
        Done, Add to Cart
      </div>
    );
  }

  renderSelectedPoseAndBackground() {
    // console.log('renderSelectedPoseAndBackground');
    if (
      this.configuringItem().name !== 'Super CD + Cloud Download' &&
      !this.productDoesNotRequirePoseOrBackground(this.configuringItem())
    ) {
      const { configuringIndex } = this.state;
      const outdoor = this.shootIsOutdoorsBackground() ? '--outdoor' : '';
      const className = 'bnl-product-config__selected-container';
      let shootClassName;

      if (this.shootIsPreShoot()) {
        if (!this.shootIsOutdoorsBackground()) {
          shootClassName = `${className}--one-box`;
        }
      } else if (this.shootIsOutdoorsBackground()) {
        shootClassName = `${className}--one-box`;
      } else {
        shootClassName = `${className}--two-boxes`;
      }

      return (
        <div className={`${className} ${shootClassName} clearfix`}>
          <div className={`bnl-product-config__selected-pose${outdoor}`}>
            {this.getDesktopSelectedPose(configuringIndex)}
          </div>
          {this.getDesktopSelectedBackground(configuringIndex)}
        </div>
      );
    }
  }

  renderMobileSelectedPoseAndBackground() {
    // console.log('renderMobileSelectedPostAndBackground');
    if (
      this.configuringItem().name !== 'Super CD + Cloud Download' &&
      !this.productDoesNotRequirePoseOrBackground(this.configuringItem())
    ) {
      const { configuringIndex } = this.state;
      const outdoor = this.shootIsOutdoorsBackground() ? '--outdoor' : '';

      return (
        <div className="clearfix">
          <div className={`bnl-product-config__selected-pose${outdoor}`}>
            {this.getMobileSelectedPose(configuringIndex)}
          </div>
          <div className="bnl-product-config__selected-background">
            {this.getMobileSelectedBackground(configuringIndex)}
          </div>
        </div>
      );
    }
  }

  renderDesktopConfiguringItem() {
    const item = this.state.configuringItems[this.state.configuringIndex];

    return (
      <div className="incentive-config-left col-xs-12 col-md-4 col-lg-4">
        <div className="bnl-product-config__product">
          <h5 className="bnl-product-config__product-name">{item.name}</h5>
          <img
            className="bnl-product-config__product-image"
            src={item.image_thumbnail}
          />
          <div className="bnl-product-config__product-desc">
            {item.description}
          </div>
          {this.renderSelectedPoseAndBackground()}
        </div>
        {this.renderButton()}
      </div>
    );
  }

  renderMobileConfiguringItem() {
    const item = this.state.configuringItems[this.state.configuringIndex];

    return (
      <div className="incentive-config-left col-xs-12 col-md-4 col-lg-4">
        <div className="bnl-product-config__product">
          <h5 className="bnl-product-config__product-name">{item.name}</h5>
          <img
            className="bnl-product-config__product-image"
            src={item.image_thumbnail}
          />
          <div className="bnl-product-config__product-desc">
            {item.description}
          </div>
          <div className="bnl-product-config__mobile-instruction">
            Tap to configure
          </div>
          {this.renderMobileSelectedPoseAndBackground()}
          {this.renderPersonalizationForm(true)}
          {this.renderSmsForm()}
        </div>
        {this.renderMobileButton()}
      </div>
    );
  }

  renderPersonalizationForm(mobile = false) {
    if (
      this.configuringItem().personalization_status ===
      'personalization_required'
    ) {
      return (
        <BnlOrderProductConfigurationPersonalization
          handleOnChange={this.handleOnChange}
          getPersonalizationValue={this.getPersonalizationValue}
          mobile={mobile}
        />
      );
    }
  }

  render() {
    // console.log('RENDERING INCENTIVE CONFIG CONTAINER');
    const product = this.state.configuringItems[this.state.configuringIndex];
    const activeStep = this.renderActiveStep();

    return (
      <div className="incentive-config-container">
        <IncentiveDesktopConfiguration
          activeStep={activeStep}
          getConfiguringItem={this.getConfiguringItem}
          getInstruction={this.getInstruction}
          presetConfiguration={this.presetConfiguration}
          renderBackButton={this.renderBackButton}
          renderDesktopConfiguringItem={this.renderDesktopConfiguringItem}
        />
        <IncentiveMobileConfiguration
          activeStep={activeStep}
          getConfiguringItem={this.getConfiguringItem}
          getInstruction={this.getInstruction}
          mobileConfiguring={this.state.mobileConfiguring}
          presetConfiguration={this.presetConfiguration}
          renderMobileBackButton={this.renderMobileBackButton}
          renderMobileConfiguringItem={this.renderMobileConfiguringItem}
          renderPersonalizationForm={this.renderPersonalizationForm}
          renderSmsForm={this.renderSmsForm}
        />
      </div>
    );
  }

  componentWillUnmount() {
    console.log('%cCOMPONENT WILL UNMOUNT', 'color: cyan');
    this.presetConfiguration();
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    gallery: state.gallery,
    order: state.order,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncentiveConfigurationContainer);
