import { useSelector } from 'react-redux';

export const selectYearbookPoses = () => {
  const studentGallery = useSelector(
    (state) => state.additionalData.studentGallery,
  );

  const filteredPoses = studentGallery.filter((pose) => {
    return pose?.name?.toLowerCase().includes('_yb');
  });

  if (filteredPoses.length > 0) {
    return filteredPoses;
  }

  return studentGallery;
};
