import React, { Component } from 'react';

// React.js components
import Dropdown from '../utils/dropdown';
import RadioButtons from '../utils/radio_buttons';

class OrderProductConfigurationWithoutBg extends Component {
  constructor(props) {
    super(props);

    this.addConfiguredItemToCart = this.addConfiguredItemToCart.bind(this);
  }

  addConfiguredItemToCart() {
    this.props.addConfiguredItemToCart(this.props.getNextComponent());
  }

  renderQuantityDropdown() {
    const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
      <Dropdown
        className="order-options-qty__dropdown"
        handleOnChange={this.props.handleOnChange}
        name="quantity"
        options={options}
        value={this.props.quantity}
      />
    );
  }

  renderMetallicOptionRadioButtons() {
    const options = {
      true: 'Yes, upgrade to metallic paper',
      false: "No, don't upgrade",
    };

    return (
      <RadioButtons
        className="order-options-metallic__dropdown"
        handleOnChange={this.props.handleOnChange}
        name="metallicOption"
        option={this.props.metallicOption}
        options={options}
      />
    );
  }

  imageThumbnail() {
    if (
      typeof this.props.shoot !== 'undefined' &&
      typeof this.props.shoot.pricing_model !== 'undefined' &&
      this.props.shoot.pricing_model !== null &&
      this.props.shoot.pricing_model.name === 'GROUP_1' && // TODO: make this a bit more flexible
      typeof this.props.studentPhotoPreviewUrl !== undefined &&
      this.props.studentPhotoPreviewUrl.length > 0
    ) {
      return this.props.studentPhotoPreviewUrl;
    }
    return this.props.shootProduct.image_thumbnail;
  }

  render() {
    return (
      <div className="product-config-no-bg">
        <div
          className="order-product-config__home-button cursor-pointer"
          onClick={this.props.goToProductGroups}
        >
          {'<  Back'}
        </div>
        <div className="product-config-info__desc clearfix">
          <div className="product-config-info__product-name">
            {this.props.shootProduct.name}
          </div>
          <div className="product-config-info__product-price">
            {this.props.displayPrice(this.props.shootProduct.price)}
          </div>
        </div>
        <div className="product-config-info__product-img-container clearfix">
          <img
            className="product-config-info__product-img"
            src={this.imageThumbnail()}
          />
          <div className="product-config-info__product-desc-container">
            <div className="product-config-info__product-desc">
              {this.props.shootProduct.description}
            </div>
          </div>
        </div>
        <div
          onClick={this.addConfiguredItemToCart}
          className="button--full-width bg--dark-blue"
        >
          Add To Cart
        </div>
      </div>
    );
  }
}

export default OrderProductConfigurationWithoutBg;
