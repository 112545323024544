import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import Dropdown from '../utils/dropdown';
import RadioButtons from '../utils/radio_buttons';
import OrderProductConfigurationWithBg from './order_product_configuration_with_bg';
import OrderProductConfigurationWithoutBg from './order_product_configuration_without_bg';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';
import { GESKUS, BNL, GB } from '../../constants/companies';

class OrderProductConfiguration extends Component {
  constructor(props) {
    super(props);

    this.getNextComponent = this.getNextComponent.bind(this);
    this.goToProductGroups = this.goToProductGroups.bind(this);
  }

  componentWillMount() {
    this.props.addCurrentOrderToReduxCart();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.resetConfiguration();
  }

  goToProductGroups() {
    this.props.updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
    this.props.resetConfiguration();
  }

  productRequiresPersonalization() {
    const status = ['personalization_optional', 'personalization_required'];

    return status.includes(this.props.shootProduct.personalization_status);
  }

  getNextComponent() {
    const offersRetouching = this.props.shoot.photoEnhancements.length > 0;
    const offersPersonalization = this.props.shoot.personalization;

    if (!offersRetouching && !offersPersonalization) {
      return ORDER_NAVIGATION.PRODUCT_GROUPS;
    }
    // product requires personalization
    const conditionOne = this.productRequiresPersonalization();

    // product doesn't require personalization but retouching hasn't been applied
    const conditionTwo =
      !this.productRequiresPersonalization() &&
      !this.props.photoEnhancementApplied;

    if (conditionOne || conditionTwo) {
      return ORDER_NAVIGATION.PRODUCT_ADDONS;
    }
    return ORDER_NAVIGATION.PRODUCT_GROUPS;
  }

  renderComponent() {
    const { backgroundChoiceMode } = this.props;

    if (!this.props.shootProduct.requires_image) {
      return (
        <OrderProductConfigurationWithoutBg
          addConfiguredItemToCart={this.props.addConfiguredItemToCart}
          configureItem={this.props.configureItem}
          displayPrice={this.props.displayPrice}
          getNextComponent={this.getNextComponent}
          goToProductGroups={this.goToProductGroups}
          handleOnChange={this.props.handleOnChange}
          metallicOption={this.props.metallicOption}
          quantity={this.props.quantity}
          shootProduct={this.props.shootProduct}
          {...this.props}
        />
      );
    }

    if (
      this.props.backgroundChoiceMode ===
      'advanced_greenscreen_backgrounds_only'
    ) {
      return (
        <OrderProductConfigurationWithBg
          addConfiguredItemToCart={this.props.addConfiguredItemToCart}
          addOrderToReduxCart={this.props.addOrderToReduxCart}
          additionalSelectedBackgroundOptions={
            this.props.additionalSelectedBackgroundOptions
          }
          allBackgroundOptionsSelected={this.props.allBackgroundOptionsSelected}
          backgroundOptions={this.props.backgroundOptions}
          backgroundOptionSelected={this.props.backgroundOptionSelected}
          configureItem={this.props.configureItem}
          configuration={this.props.configuration}
          displayPrice={this.props.displayPrice}
          formattedBackgroundOptions={this.props.formattedBackgroundOptions}
          getStudentImage={this.props.getStudentImage}
          goToProductGroups={this.goToProductGroups}
          handleOnChange={this.props.handleOnChange}
          metallicOption={this.props.metallicOption}
          nextComponent={this.getNextComponent()}
          proceedToCart={this.props.proceedToCart}
          quantity={this.props.quantity}
          renderNextComponent={this.props.renderNextComponent}
          selectedPose={this.props.selectedPose}
          selectedBackgroundOptions={this.props.selectedBackgroundOptions}
          shootProduct={this.props.shootProduct}
          studentId={this.props.studentId}
          toggleBackground={this.props.toggleBackground}
        />
      );
    }

    return (
      <OrderProductConfigurationWithoutBg
        addConfiguredItemToCart={this.props.addConfiguredItemToCart}
        configureItem={this.props.configureItem}
        displayPrice={this.props.displayPrice}
        getNextComponent={this.getNextComponent}
        goToProductGroups={this.goToProductGroups}
        handleOnChange={this.props.handleOnChange}
        metallicOption={this.props.metallicOption}
        quantity={this.props.quantity}
        shootProduct={this.props.shootProduct}
        shoot={this.props.shoot.shoot}
        studentPhotoPreviewUrl={this.props.studentPhotoPreviewUrl}
      />
    );
  }

  renderQuantityDropdown() {
    const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
      <Dropdown
        className="order-options-qty__dropdown"
        handleOnChange={this.props.handleOnChange}
        name="quantity"
        options={options}
        value={this.props.quantity}
      />
    );
  }

  renderMetallicOptionRadioButtons() {
    const options = {
      true: 'Yes, upgrade to metallic paper',
      false: "No, don't upgrade",
    };

    return (
      <RadioButtons
        className="order-options-metallic__dropdown"
        handleOnChange={this.props.handleOnChange}
        name="metallicOption"
        option={this.props.metallicOption}
        options={options}
      />
    );
  }

  render() {
    return <div className="order-product-config">{this.renderComponent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderProductConfiguration);
