import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SideBox = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 32px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const SubHeader = styled.div`
  font-size: 14px;
  margin: 0;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 24px;
  padding: 10px 16px;
  background-color: #479de6;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  width: 200px;
  font-size: 16px;

  &:hover {
    color: #fff;
    background-color: #357ab8;
  }
`;

const NewOrder = () => {
  return (
    <SideBox>
      <Header>Order for Another Student</Header>
      <SubHeader>
        Ordering for more than one student? Enter their unique key to create
        another order before checking out.
      </SubHeader>
      <StyledLink to="/dashboard">Add Order</StyledLink>
    </SideBox>
  );
};

export default NewOrder;
