import React, { Component } from 'react';

class OrderBuildYourOwnProduct extends Component {
  renderProducts() {
    const orderedProducts = {
      '1 - 8x10': null,
      '2 - 5x7s': null,
      '8 Wallets': null,
      '4 - 3x5s': null,
      '1 - 5x7, 4 Wallets': null,
    };

    const products = this.props.products.map((product, i) => {
      const selected = '';
      if (this.props.selectedChildItem) {
        const selectedId = parseInt(
          this.props.selectedChildItem.id.split('_')[0],
        );

        // if (product.id === selectedId) {
        //   selected = "byo--selected";
        // }
      }

      const imageClassName = 'byo__product__image';
      const className = 'byo__product col-xs-6 col-md-4 col-lg-4';

      return (
        <li
          className={`${className} cursor-pointer border--round`}
          key={`you-pick-x-item-${i}`}
          onClick={() => this.props.selectChildItem(product)}
        >
          <img
            src={product.image_thumbnail}
            className={`byo__product__image ${imageClassName}  ${selected} `}
          />
          <div className="byo__product__name">{product.name}</div>
        </li>
      );
    });

    return products;
  }

  render() {
    return (
      <ul className="byo__products-list row byo__row col-xs-12 col-md-8 col-lg-8">
        {this.renderProducts()}
      </ul>
    );
    // return (
    //   <div className="byo__config-container">
    //     <ul className="byo__products-list row byo__row">
    //       {this.renderProducts()}
    //     </ul>
    //   </div>
    // );
  }
}

export default OrderBuildYourOwnProduct;
