import { LockClosedIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import cartUtils from '../../../../../utils/cart';
import formatUSD from '../../../../../utils/formatUSD';
import PoseItem from '../../../shared/poseItem/PoseItem';

const PosesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media (max-width: 768px) {
    // Tablet and below breakpoint
    flex-direction: column;
    align-items: center;
  }
`;

const PosesRow = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    // Tablet and below breakpoint
    flex-direction: column;
    align-items: center;
  }
`;

const LockContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
`;

const LockIcon = styled(LockClosedIcon)`
  position: absolute;
  bottom: -44px;
  right: 5px;
  height: auto;
  width: 20px;
  color: white;
`;

const BackgroundsPosesSelector = ({
  addBackgroundSelection,
  addPoseSelection,
  allAvailablePosesForOrder,
  availablePoses,
  backgroundOptions,
  backgroundsPosesSelectorVisible,
  backgroundSelections,
  isPrepay,
  orderContainsSingleBackgroundUpgrade,
  posesCount,
  poseSelectionIds,
  poseSelections,
  product,
  productBackgroundOptionsCount,
  studentGallery,
}) => {
  const { studentPreviewImageUrl } = useSelector(
    (state) => state.additionalData,
  );
  const [backgroundsCategoryIndex, setbackgroundsCategoryIndex] = useState(0);
  const { orders } = useSelector((state) => state.v3Order);
  const { studentId } = useSelector((state) => state.additionalData);
  const unlockedGroups = cartUtils.unlockedGroups(
    orders?.[studentId]?.cartItems || [],
  );
  const [isPoseRecentlySelected, setIsPoseRecentlySelected] = useState(false);

  // useEffect(() => {
  //   let poseSource =
  //     posesCount > 1
  //       ? availablePoses
  //       : studentGallery && studentGallery?.length > 0
  //       ? studentGallery
  //       : availablePoses;

  //   if (allAvailablePosesForOrder?.length > 0) {
  //     poseSource = allAvailablePosesForOrder;
  //   }

  //   poseSource?.forEach((pose) => {
  //     if (pose?.cropped_images?.length > 0 && !pose.selected_crop) {
  //       addPoseSelection(pose.id, 0);
  //     }
  //   });
  // }, []);

  if (!productBackgroundOptionsCount || productBackgroundOptionsCount === 0) {
    return null;
  }

  const BackgroundPrice = (background) => {
    if (
      Number.isInteger(background?.price) &&
      background.price > 0 &&
      !orderContainsSingleBackgroundUpgrade // don't show price if the order already contains a single background upgrade
    ) {
      return (
        <>
          <br />
          <span className="bnl-bg-price">{formatUSD(background.price)}</span>
        </>
      );
    }

    return null;
  };

  const renderBackgroundCategoryTabs = () => {
    return backgroundOptions.map((backgroundCategory, index) => {
      let activeClass = '';

      if (index === backgroundsCategoryIndex) {
        activeClass = 'group-active';
      }

      return (
        <li
          key={backgroundCategory.id}
          className={`bnl-product-config__bg-selector__tab ${activeClass}`}
          onClick={() => setbackgroundsCategoryIndex(index)}
        >
          {backgroundCategory.name}
        </li>
      );
    });
  };

  const renderBackgroundOptions = () => {
    return backgroundOptions[backgroundsCategoryIndex]?.background_images?.map(
      (background) => {
        const isLocked =
          background.lock_type === 'unlockable' &&
          !unlockedGroups.some((set) => background.lock_group.includes(set)) &&
          !(
            product.lock_type === 'unlocker' &&
            product.lock_group.some((set) =>
              background.lock_group.includes(set),
            )
          );

        const lastPose =
          poseSelections.length > 0
            ? poseSelections[poseSelections.length - 1]
            : null;

        const backgroundOptionStyle = {
          backgroundImage: `url(${background.image_thumbnail})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          cursor: isLocked ? 'default' : 'pointer',
        };

        const imgStyle = {
          width: '100%', // Ensuring the overlay image covers the background
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          userSelect: 'none',
        };

        const handleClick = () => {
          if (isLocked) {
            return;
          }
          setIsPoseRecentlySelected(false);
          addBackgroundSelection(background);
        };

        return (
          <li
            key={background.id}
            className="bnl-product-config__bg-container col-lg-2"
            onClick={handleClick}
          >
            <div
              className="bnl-product-config__bg bnl-product-config__bg--b"
              style={backgroundOptionStyle}
            >
              {lastPose && (
                <img
                  src={
                    lastPose.selected_crop
                      ? lastPose.selected_crop.url
                      : lastPose.url
                  }
                  alt="Overlay Pose"
                  onContextMenu={(event) => event.preventDefault()}
                  style={imgStyle}
                />
              )}
              {!lastPose && !isPrepay && studentPreviewImageUrl && (
                <img
                  src={studentPreviewImageUrl}
                  alt="Overlay Pose"
                  onContextMenu={(event) => event.preventDefault()}
                  style={imgStyle}
                />
              )}
              {isLocked && (
                <LockContainer>
                  <LockIcon />
                </LockContainer>
              )}
            </div>
            <div className="bnl-bg-text text-center">
              {background.display_name}
              {BackgroundPrice(background)}
            </div>
          </li>
        );
      },
    );
  };

  const renderPoses = () => {
    // Prioritize availablePoses when posesCount > 1, otherwise prioritize non-empty studentGallery, fallback to availablePoses if studentGallery is empty or non-existent.
    let poseSource =
      posesCount > 1
        ? availablePoses
        : studentGallery && studentGallery?.length > 0
        ? studentGallery
        : availablePoses;

    if (allAvailablePosesForOrder?.length > 0) {
      poseSource = allAvailablePosesForOrder;
    }

    const poses = poseSource?.map((pose) => {
      // TODO: handle group image type
      if (pose.cropped_images?.length > 0) {
        return (
          <PosesRow>
            {pose.cropped_images.map((croppedImage, i) => {
              return (
                <PoseItem
                  key={`${pose.id}-${croppedImage.name}`}
                  onClick={() => {
                    setIsPoseRecentlySelected(true);
                    addPoseSelection(pose.id, i);
                  }}
                  src={croppedImage.url}
                  alt=""
                  name={croppedImage.name}
                />
              );
            })}
          </PosesRow>
        );
      }
      return (
        <PoseItem
          key={pose.id}
          onClick={() => {
            setIsPoseRecentlySelected(true);
            addPoseSelection(pose.id);
          }}
          src={pose.url}
          onContextMenu={(event) => event.preventDefault()}
        />
      );
    });

    return <PosesList>{poses}</PosesList>;
  };

  const renderPosesConfig = () => {
    if (
      !isPrepay &&
      posesCount > 0 &&
      (availablePoses || studentGallery) &&
      (poseSelectionIds.length === backgroundSelections.length ||
        !backgroundOptions.length) &&
      !isPoseRecentlySelected
    ) {
      return <div className="bnl-product-config__poses">{renderPoses()}</div>;
    }
    return null;
  };

  const renderBackgroundsConfig = () => {
    if (
      isPrepay ||
      (!isPrepay && !availablePoses && !studentGallery) ||
      (posesCount > 0 &&
        backgroundSelections.length < poseSelectionIds.length) ||
      isPoseRecentlySelected
    ) {
      return (
        <div className="bnl-product-config">
          <ul className="bnl-product-config__bg-selector__tabs-container">
            {renderBackgroundCategoryTabs()}
          </ul>
          <ul className="row equal-height">{renderBackgroundOptions()}</ul>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`backgroundsPosesContainer ${
        backgroundsPosesSelectorVisible ? 'element-visible' : 'element-hidden'
      }`}
    >
      {renderPosesConfig()}
      {renderBackgroundsConfig()}
    </div>
  );
};

BackgroundsPosesSelector.propTypes = {
  addBackgroundSelection: PropTypes.func.isRequired,
  addPoseSelection: PropTypes.func.isRequired,
  allAvailablePosesForOrder: PropTypes.array,
  availablePoses: PropTypes.array,
  backgroundOptions: PropTypes.array.isRequired,
  backgroundsPosesSelectorVisible: PropTypes.bool.isRequired,
  backgroundSelections: PropTypes.array.isRequired,
  isPrepay: PropTypes.bool.isRequired,
  posesCount: PropTypes.number.isRequired,
  poseSelectionIds: PropTypes.array.isRequired,
  poseSelections: PropTypes.array.isRequired,
  productBackgroundOptionsCount: PropTypes.number.isRequired,
  studentGallery: PropTypes.array,
  orderContainsSingleBackgroundUpgrade: PropTypes.bool.isRequired,
};

export default BackgroundsPosesSelector;
