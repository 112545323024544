import React from 'react';
import CartItem from './CartItem';
import cartUtils from '../../../utils/cart';

const CartItemsList = ({ order, studentId }) => {
  console.log('CART ITEMS LIST 💎💎💎💎💎💎💎');

  let hasDisplayedBackgroundPrice = false;

  const singleChargeForBackgroundUpgrades =
    order.event.singleChargeForBackgroundUpgrades;

  const chargeForYpxBackgroundUpgrades =
    order.event.chargeForYpxBackgroundUpgrades;

  return (
    <>
      {cartUtils
        .sortCartItems(order.cartItems)
        .map((cartItem, index, array) => {
          console.log('cartItem', cartItem);

          let shouldDisplayBackgroundPrice = true;

          if (singleChargeForBackgroundUpgrades === true) {
            shouldDisplayBackgroundPrice = false;
          }

          if (
            cartItem.isYPX === true &&
            chargeForYpxBackgroundUpgrades === false
          ) {
            shouldDisplayBackgroundPrice = false;
          } else if (
            singleChargeForBackgroundUpgrades === true &&
            !hasDisplayedBackgroundPrice &&
            Array.isArray(cartItem.backgroundSelections) &&
            cartItem.backgroundSelections.some(
              (selection) =>
                Number.isInteger(selection.price) && selection.price > 0,
            )
          ) {
            shouldDisplayBackgroundPrice = true;
            hasDisplayedBackgroundPrice = true;
          }

          return (
            <CartItem
              key={`cart-item-${order.student.id}-${cartItem.id}`}
              cartItem={cartItem}
              studentId={studentId}
              order={order}
              isLastItem={index === array.length - 1}
              shouldDisplayBackgroundPrice={shouldDisplayBackgroundPrice}
              singleChargeForBackgroundUpgrades={
                singleChargeForBackgroundUpgrades
              }
            />
          );
        })}
    </>
  );
};

export default CartItemsList;
