import { useSelector } from 'react-redux';

export const useCurrentOrderStudentId = () => {
  const {
    additionalData: { selectedStudent },
    v3Order: { orders },
  } = useSelector((state) => state);

  const order = orders[selectedStudent?.id];

  if (order) {
    return selectedStudent.id;
  }

  return null;
};
