import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import { viewedCompanyMessage } from '../../../actions/action_parent';

class CompanyDashboardMessage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.viewedCompanyMessage();
  }

  render() {
    if (
      moment().isAfter('2018-11-12', 'day') &&
      moment().isBefore('2019-01-04', 'day')
    ) {
      Swal.fire({
        title: 'Holiday Delivery Notice',
        customClass: {
          container: 'dashboard-message-alert',
        },
        html: `
          <p>Orders placed after <strong style="color: red;">Thursday, December 13th</strong> may not arrive prior to the Holidays.</p>
          <p>(All orders received by this date will ship prior to December 23rd)</p>
          <p>Any orders placed between December 24th and January 1st will be shipped the week of January 2nd.</p>
        `,
      });
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      viewedCompanyMessage,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDashboardMessage);
