import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { addStudentSuccess } from '../../actions/action_parent';

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const CancelLink = styled(Link)`
  display: inline-block;
  margin-left: 8px;
`;

const ParentAccountAddStudent = ({ history }) => {
  const onSuccessRoute = '/my-account';
  const dispatch = useDispatch();

  const { publicToken } = useSelector((state) => state.parent);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [firstNameSubmissionError, setFirstNameSubmissionError] = useState('');
  const [lastNameSubmissionError, setLastNameSubmissionError] = useState('');

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('/api/v2/students', {
        first_name: data.firstName,
        last_name: data.lastName,
        publicToken,
      });
      dispatch(addStudentSuccess(response.data));
      history.push(onSuccessRoute);
    } catch (error) {
      console.log('error', error);

      let firstNameErrorMessages = '';
      if (error.response.data.last_name) {
        error.response.data.last_name.forEach((message) => {
          firstNameErrorMessages += message;
        });
      }
      setfirstNameSubmissionError(firstNameErrorMessages);

      let lastNameErrorMessages = '';
      if (error.response.data.last_name) {
        error.response.data.last_name.forEach((message) => {
          lastNameErrorMessages += message;
        });
      }
      setLastNameSubmissionError(lastNameErrorMessages);
    }
  };

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Add Student</MainHeader>
        <Divider />
        <div className="order-checkout__main-content row">
          <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
            <Wrapper>
              <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="form-group">
                  <label htmlFor="firstName">First name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors?.firstName ? 'is-invalid' : ''
                    }`}
                    name="firstName"
                    {...register('firstName', {
                      required: 'First name is required',
                    })}
                  />
                  {errors.firstName && (
                    <div className="text-danger">
                      {errors?.firstName.message}
                    </div>
                  )}
                  {firstNameSubmissionError && (
                    <div className="text-danger">{`First name ${firstNameSubmissionError}`}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors?.lastName ? 'is-invalid' : ''
                    }`}
                    name="lastName"
                    id="lastName"
                    {...register('lastName', {
                      required: 'Last name is required',
                    })}
                  />
                  {errors.lastName && (
                    <div className="text-danger">
                      {errors?.lastName.message}
                    </div>
                  )}
                  {lastNameSubmissionError && (
                    <div className="text-danger">{`Last name ${lastNameSubmissionError}`}</div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary">
                  Add Student
                </button>
                <CancelLink to={onSuccessRoute} className="ml-2">
                  Cancel
                </CancelLink>
              </form>
            </Wrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ParentAccountAddStudent);
