import React, { Component } from 'react';

// React.js components
import ProductDigitalDownload from '../products/product_digital_download';
import ProductPackage from '../products/product_package';

class OrderProduct extends Component {
  constructor(props) {
    super(props);

    this.selectShootProduct = this.selectShootProduct.bind(this);
  }

  selectShootProduct() {
    this.props.selectShootProduct(this.props.product);
  }

  renderProduct() {
    const product = this.props.product;

    switch (this.props.groupName) {
      case 'digital-download':
        return (
          // <div className="col-xs-12 col-md-6">
          <ProductDigitalDownload
            displayPrice={this.props.displayPrice}
            product={this.props.product}
            selectShootProduct={this.selectShootProduct}
            shoot={this.props.shoot}
            studentPhotoPreviewUrl={this.props.studentPhotoPreviewUrl}
          />
          // </div>
        );
      case 'packages':
        return (
          // <div className="col-xs-12 col-md-4">
          <ProductPackage
            displayPrice={this.props.displayPrice}
            product={this.props.product}
            selectShootProduct={this.selectShootProduct}
            shoot={this.props.shoot}
            studentPhotoPreviewUrl={this.props.studentPhotoPreviewUrl}
          />
          // </div>
        );
      case 'novelties':
        // TODO: add a novelty component here
        return (
          // <div className="col-xs-12 col-md-4">
          <ProductPackage
            displayPrice={this.props.displayPrice}
            product={this.props.product}
            selectShootProduct={this.selectShootProduct}
            shoot={this.props.shoot}
            studentPhotoPreviewUrl={this.props.studentPhotoPreviewUrl}
          />
          // </div>
        );
      default:
        // TODO: better handle default case
        return (
          // <div className="col-xs-12 col-md-4">
          <ProductPackage
            displayPrice={this.props.displayPrice}
            product={this.props.product}
            selectShootProduct={this.selectShootProduct}
            shoot={this.props.shoot}
            studentPhotoPreviewUrl={this.props.studentPhotoPreviewUrl}
          />
          // </div>
        );
    }
  }

  render() {
    const columnClass =
      this.props.groupName === 'digital-download'
        ? 'col-xs-12 col-md-6'
        : 'col-xs-12 col-md-4';
    return (
      <div
        className={`order-product order-product__${this.props.groupName} ${columnClass}`}
      >
        {this.renderProduct()}
      </div>
    );
  }
}

export default OrderProduct;
