import React, { Component } from 'react';

// React.js components
import OrderProduct from './order_product';

class OrderProductGroup extends Component {
  showDisclaimerText() {
    const studentImage = this.props.getStudentImage();

    const disclaimerStyle = {
      fontStyle: 'italic',
    };

    if (
      studentImage.imageDisclaimer &&
      this.props.name.toLowerCase() === 'packages'
    ) {
      return (
        <div>
          <p style={disclaimerStyle}>{studentImage.imageDisclaimer}</p>
        </div>
      );
    }

    return null;
  }

  renderProducts() {
    const that = this;
    const products = this.props.products.map((product, i) => {
      const groupName = that.props.name.toLowerCase().split(' ').join('-');
      return (
        <OrderProduct
          displayPrice={this.props.displayPrice}
          groupName={groupName}
          key={`order-product-${i}`}
          product={product}
          selectShootProduct={this.props.selectShootProduct}
          {...this.props}
        />
      );
    });

    return products;
  }

  render() {
    const name = this.props.name.toLowerCase().split(' ').join('-');
    const className = `order-product-group__${name}`;

    return (
      <div className={`order-product-group ${className}`}>
        <div className="order-product-group__header">{this.props.name}</div>
        {this.showDisclaimerText()}
        <div className="row order-product-group__row">
          {this.renderProducts()}
        </div>
      </div>
    );
  }
}

export default OrderProductGroup;
