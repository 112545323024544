import React from 'react';
import styled from 'styled-components';
import formatUSD from '../../../../../utils/formatUSD';

const ProductPrice = styled.span`
  display: block;
  color: #666;
  font-size: 14px;
  margin-top: 0;
  font-weight: 400;
  padding-bottom: 8px;
`;

const ProductSubheader = ({ product }) => {
  const displayPrice = product.price === 0 ? 'Free' : formatUSD(product.price);

  return <ProductPrice>{displayPrice}</ProductPrice>;
};

export default ProductSubheader;
