import React, { Component } from 'react';

import OrderBuildYourOwnDesktopSlots from './order_build_your_own_desktop_slots';

class OrderBuildYourOwnDestkopConfiguration extends Component {
  render() {
    return (
      <div className="row byo__config--desktop">
        <OrderBuildYourOwnDesktopSlots
          addConfiguredChildItemsToCart={
            this.props.addConfiguredChildItemsToCart
          }
          activeComponent={this.props.activeComponent}
          canBeAdded={this.props.canBeAdded}
          cartItems={this.props.cartItems}
          cloneObject={this.props.cloneObject}
          configuration={this.props.configuration}
          freeIndices={this.props.freeIndices}
          productIsBnlYouPickX={this.props.productIsBnlYouPickX}
          removeConfiguredChildItem={this.props.removeConfiguredChildItem}
          selectedShootProduct={this.props.selectedShootProduct}
          SLOTS_COUNT={this.props.SLOTS_COUNT}
          STEPS={this.props.STEPS}
        />
        {this.props.renderActiveComponent}
      </div>
    );
  }
}

export default OrderBuildYourOwnDestkopConfiguration;
