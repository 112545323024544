import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import styled from 'styled-components';
import { updateParentSMSNumber } from '../../../../../actions/v3Order';

const EditLink = styled.a`
  color: #31708f !important;
  text-decoration: underline !important;
`;

const SMSNumberConfirmation = ({
  currentUserId,
  editMode,
  phone,
  setEditMode,
  smsNumber,
  smsNumberConfirmed,
}) => {
  const dispatch = useDispatch();
  const initialPhoneNumber = parsePhoneNumberFromString(
    String(smsNumber || phone),
  );
  const formattedPhoneNumber = parsePhoneNumberFromString(
    String(smsNumber || phone),
    'US',
  );

  const [phoneNumber, setPhoneNumber] = useState(
    initialPhoneNumber ? initialPhoneNumber.nationalNumber : '',
  );
  const [formattedNumber, setFormattedNumber] = useState(
    formattedPhoneNumber ? formattedPhoneNumber.formatNational() : '',
  );

  useEffect(() => {
    const formattedPhoneNumber = parsePhoneNumberFromString(
      String(smsNumber || phone),
    );
    setPhoneNumber(
      formattedPhoneNumber ? formattedPhoneNumber.nationalNumber : '',
    );
    setFormattedNumber(
      formattedPhoneNumber ? formattedPhoneNumber.formatNational() : '',
    );
  }, [smsNumber, phone]);

  const handleUpdate = () => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, 'US');
    if (!phoneNumberObject || !phoneNumberObject.isValid()) {
      Swal.fire('Invalid Phone Number', '', 'error');
      return;
    }

    dispatch(updateParentSMSNumber(currentUserId, phoneNumberObject.number));
    setPhoneNumber(phoneNumberObject.number);
    setFormattedNumber(phoneNumberObject.formatNational());
    setEditMode(false);
  };

  const handleCancel = () => {
    const formattedPhoneNumber = parsePhoneNumberFromString(
      String(smsNumber || phone),
    );
    setPhoneNumber(
      formattedPhoneNumber ? formattedPhoneNumber.nationalNumber : '',
    );
    setFormattedNumber(
      formattedPhoneNumber ? formattedPhoneNumber.formatNational() : '',
    );
    setEditMode(false);
  };

  const alertGraphTopMargin = { marginTop: '14px' };

  return (
    <div>
      {!editMode && (
        <div className="alert alert-info" role="alert">
          <p>
            <strong>
              Please verify your cell phone number and edit if necessary.
            </strong>
          </p>
          <p style={alertGraphTopMargin}>
            <strong>
              The image will be sent to this number once it is ready:
            </strong>
          </p>
          <p style={alertGraphTopMargin}>
            <strong>{formattedNumber}</strong>
          </p>
          <p style={alertGraphTopMargin}>
            <EditLink
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setEditMode(true);
              }}
            >
              edit
            </EditLink>
          </p>
        </div>
      )}
      {editMode && (
        <>
          <div className="form-group">
            <input
              type="tel"
              className="form-control"
              value={phoneNumber}
              placeholder="Your phone number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary"
              style={{ marginRight: '8px' }}
              onClick={handleUpdate}
            >
              Update
            </button>
            <button className="btn btn-default" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SMSNumberConfirmation;
