import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginParent, setAuthToken } from '../../actions/action_auth';
import { setHistory } from '../../actions/action_history';
import { getParentData } from '../../actions/action_parent';
import * as Sentry from '@sentry/react';

// React.js components
import ParentSignupFormStep2 from './parent_signup_form_step_2';

class ParentLoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errors: {},
    };

    this.goToSignupForm = this.goToSignupForm.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this._displayFormErrors = this._displayFormErrors.bind(this);
  }

  goToSignupForm() {
    this.props.history.replace('/signup');
  }

  handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOnSubmit(event) {
    event.preventDefault();
    const that = this;
    const data = {
      email: this.state.email,
      password: this.state.password,
      authenticity_token: this.props.auth.authToken,
    };

    this.setState({ errors: {} }, () => {
      this.props.loginParent(data).then((response) => {
        if (response.payload.data) {
          if (response.payload.data.errors) {
            that.setState({ errors: response.payload.data.errors });
          } else if (
            response.payload.data.currentUserId &&
            !response.payload.data.signupCompleted
          ) {
            window.location.reload(true);

            that.props.history.push('/signup', { step: 2 });
          } else {
            window.location.reload(true);
            that.props.getParentData();
            that.props.setHistory(that.props.history);
            that.props.history.push('/dashboard');
          }
        }
      });
    });
  }

  _displayFormErrors() {
    if (Object.keys(this.state.errors).length > 0) {
      return (
        <div className="alert alert-danger login-alert">
          {this.state.errors.login}
        </div>
      );
    }
    return null;
  }

  render() {
    const className =
      Object.keys(this.state.errors).length > 0 ? 'error--red' : 'hidden';

    return (
      <div className="parent-login">
        <div className="row">
          <div className="col-xs-12 col-md-4 col-md-offset-4">
            <div
              className={`login__company-logo ${this.props.company.name}-logo`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4 col-md-offset-4">
            <div className="login__header text-center">Log In</div>
            <div className="parent-login__signup-container">
              <div className="parent-login__signup-inner-container clearfix">
                <div className="parent-login__signup-header">
                  Don't have an account?
                </div>
                <div
                  onClick={this.goToSignupForm}
                  className="parent-login__signup-link cursor-pointer"
                >
                  Sign Up
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4 col-md-offset-4">
            {this._displayFormErrors()}
          </div>
        </div>
        <form onSubmit={this.handleOnSubmit} className="login-form">
          <div className="row">
            <div className="col-xs-12 col-md-4 col-md-offset-4">
              <input
                id="email"
                className="login-form__input border--round"
                onChange={this.handleOnChange}
                name="email"
                value={this.state.email}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4 col-md-offset-4">
              <input
                id="password"
                className="login-form__input border--round"
                onChange={this.handleOnChange}
                name="password"
                value={this.state.password}
                placeholder="Password"
                type="password"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4 col-md-offset-4">
              <input
                className="button--round-border bg--light-blue center-block"
                type="submit"
                value="Done"
              />
              <a href="/password-reset" className="forgot-password-link">
                Forgot your password?
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginParent,
      setAuthToken,
      setHistory,
      getParentData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentLoginForm);
