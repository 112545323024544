import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import cartUtils from '../../../../utils/cart';

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const Header1 = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #479de6;
`;

const Header2 = styled.div`
  font-size: 14px;
  margin-top: 0;
`;

const ContactLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 16px;

  @media (max-width: 767px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContactLineLessMargin = styled(ContactLine)`
  margin-top: 4px;
`;

const ContactLabel = styled.p`
  margin: 0;
`;

const ContactData = styled.p`
  margin: 0;
  font-weight: bold;

  @media (max-width: 767px) {
    margin-top: 4px;
  }
`;

const EditLinkStyled = styled(Link)`
  text-decoration: underline;
  color: #479de6;
  cursor: pointer;
  margin-top: 16px;
`;

const CustomerDetails = ({ cartTotals }) => {
  const {
    address: { address1, address2, city, state, zipcode },
    email,
    phone,
    smsPhone,
  } = useSelector((state) => state.parent);
  const { orders } = useSelector((state) => state.v3Order);

  const formattedPhoneNumber = parsePhoneNumberFromString(
    smsPhone || phone,
    'US',
  );

  return (
    <>
      {cartUtils.cartContainsShippingRequiredProducts(orders) && (
        <Wrapper>
          <Header1>Address</Header1>
          <ContactLine>
            <p>
              For ship-to-home orders, please ensure that the below address is
              where you'd like your order shipped.
            </p>
          </ContactLine>

          {/* <div className="alert alert-info">
          This is the explainer paragraph that provides additional context or
          information about the address shown below.
        </div> */}
          <Header2>
            <ContactLineLessMargin>
              <ContactData>
                {address1}
                <br />
                {address2 && address2}
                {address2 && <br />}
                {city}, {state} {zipcode}
              </ContactData>
            </ContactLineLessMargin>
            <EditLinkStyled to="/v3/checkout/customer-address-edit">
              edit
            </EditLinkStyled>
          </Header2>
        </Wrapper>
      )}
      <Wrapper>
        <Header1>Contact Information</Header1>
        <Header2>
          <ContactLine>
            <ContactLabel>Please confirm your email address:</ContactLabel>
            <br />
            <ContactData>{email}</ContactData>
            <EditLinkStyled to="/v3/checkout/customer-email-edit">
              edit
            </EditLinkStyled>
          </ContactLine>
          <ContactLine>
            <ContactLabel>
              Please confirm a mobile number to send any SMS-based products to:
            </ContactLabel>
            <ContactData>
              {formattedPhoneNumber
                ? formattedPhoneNumber.formatNational()
                : 'Invalid Number'}
            </ContactData>
            <EditLinkStyled to="/v3/checkout/customer-phone-edit">
              edit
            </EditLinkStyled>
          </ContactLine>
        </Header2>
      </Wrapper>
    </>
  );
};

export default CustomerDetails;

CustomerDetails.PropTypes = {
  cartTotals: PropTypes.shape({
    shipping: PropTypes.number,
  }),
};
