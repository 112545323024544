import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import additionalDataReducer from './additionalData';
import AuthReducer from './reducer_auth';
import BnlIncentiveReducer from './reducer_bnl_incentive';
import BnlPreShootReducer from './reducer_bnl_preshoot';
import BnlYearbookPhotoSelection from './reducer_bnl_yearbook_photo_selection';
import CartReducer from './reducer_cart';
import CompanyReducer from './reducer_company';
import DiscountCodeReducer from './reducer_discount_code';
import GalleryReducer from './reducer_gallery';
import GeskusIncentiveReducer from './reducer_geskus_incentive';
import HistoryReducer from './reducer_history';
import NavbarReducer from './reducer_navbar';
import NotificationReducer from './reducer_notification';
import OrderNavigationReducer from './reducer_order_navigation';
import OrderReducer from './reducer_order';
import ParentReducer from './reducer_parent';
import ShootReducer from './reducer_shoot';
import ShootProductReducer from './reducer_shoot_product';
import v3OrderReducer from './v3Order';
import locationReducer from './locationReducer';

import { CLEAR, LOGOUT_PARENT } from '../actions/action_auth';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const appReducer = combineReducers({
  additionalData: additionalDataReducer,
  auth: AuthReducer,
  bnlIncentive: BnlIncentiveReducer,
  bnlPreShoot: BnlPreShootReducer,
  bnlYearbookPhotoSelection: BnlYearbookPhotoSelection,
  cart: CartReducer,
  company: CompanyReducer,
  discountCode: DiscountCodeReducer,
  gallery: GalleryReducer,
  geskusIncentive: GeskusIncentiveReducer,
  location: locationReducer,
  navbar: NavbarReducer,
  notification: NotificationReducer,
  orderNavigation: OrderNavigationReducer,
  order: OrderReducer,
  parent: ParentReducer,
  reducerHistory: HistoryReducer,
  router: connectRouter(history),
  shoot: ShootReducer,
  shootProduct: ShootProductReducer,
  uiLocation: locationReducer,
  v3Order: v3OrderReducer,
});

const createRootReducer = (history) => (state, action) => {
  if (action && action.type === LOGOUT_PARENT) {
    state = undefined;
  } else if (action && action.type === CLEAR) {
    state = undefined;
  }

  return appReducer(connectRouter(history)(state, action), action);
};

export default createRootReducer;
