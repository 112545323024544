import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import formatUSD from '../../../utils/formatUSD.js';

const NthFreePrice = styled.p`
  margin-top: 2px;
  font-size: 14px;
`;

// TODO: v3 - remove useTestingCategoryPaths when setup & testing complete
const CategoryProduct = ({
  isNthFree,
  product,
  unlockedGroups,
  useTestingCategoryPaths,
}) => {
  // TODO: this is a bit of a hack and should be revisited
  useEffect(() => {
    const elements = document.querySelectorAll('.bnl-product-regular');

    for (let i = 0; i < elements.length; i++) {
      if ((i + 1) % 4 === 1 || (i + 1) % 4 === 0) {
        elements[i].classList.add('bnl-product-checker-white');
      }
    }
  }, []);

  const className = (product) => {
    let className =
      'bnl-product-regular col-xs-12 col-md-6 col-lg-6 cursor-pointer';

    if (product.display_type === 'display_full') {
      className = 'bnl-product-most-popular col-xs-12 col-md-12 col-lg-12';
    }

    return className;
  };

  const renderProductImage = (product) => {
    if (product.display_image_in_list) {
      return (
        <div className="bnl-product-regular__image-container">
          <img
            className="bnl-product-regular__image"
            src={product.image_thumbnail}
            alt={product.name}
            loading="lazy"
          />
        </div>
      );
    }

    return null;
  };

  const handleProductClick = (product, event) => {
    let locked = false;

    if (product.lock_type === 'unlockable') {
      locked = true;

      product.lock_group.forEach((set) => {
        if (unlockedGroups.includes(set)) locked = false;
      });
    }

    if (locked) {
      event.preventDefault();
    }
  };

  const renderContent = (product, unlockedGroups) => {
    // TODO: add lock/unlock logic

    if (product.display_type === 'display_full') {
      const imageContainer = 'bnl-product-most-popular__image-container';

      return (
        <>
          <Link
            to={
              useTestingCategoryPaths === true
                ? `/v3/products-regular/${product.id}`
                : `/v3/products/${product.id}`
            }
            onClick={(event) => handleProductClick(product, event)}
          />
          <div className="row">
            <div className="bnl-product-most-popular-left col-xs-12 col-md-5 col-lg-5">
              <h3 className="bnl-product-most-popular__header">
                {product.name} - Our Most Popular Option
              </h3>
              <div className={`${imageContainer} ${imageContainer}--mobile`}>
                <img
                  className="bnl-product-most-popular__image"
                  src={product.image_large || product.image_thumbnail}
                />
              </div>
              <div className="bnl-product__items row">
                {renderContentsOrDesc(product)}
              </div>
              <div className="bnl-product__button button--round-border bg--white-black cursor-pointer">
                Get {product.name} - {formatUSD(product.price)}
              </div>
            </div>
            <div className="bnl-product-most-popular-right col-xs-12 col-md-7 col-lg-7">
              <div className="bnl-product-most-popular__image-container">
                <img
                  className="bnl-product-most-popular__image"
                  src={product.image_large || product.image_thumbnail}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    if (product.display_product_in_list) {
      return (
        <>
          <Link
            to={
              useTestingCategoryPaths === true
                ? `/v3/products-regular/${product.id}`
                : `/v3/products/${product.id}`
            }
            onClick={(event) => handleProductClick(product, event)}
          />
          <div className="row">
            <h3 className="bnl-product__name col-xs-8 col-md-8 col-lg-8">
              {product.name}
            </h3>
            <div className="bnl-product__price col-xs-4 col-md-4 col-lg-4">
              {product.price === 0 ? 'Free' : formatUSD(product.price)}
              {isNthFree && (
                <NthFreePrice>
                  value: {formatUSD(product.nth_free_value)}
                </NthFreePrice>
              )}
            </div>
          </div>
          {renderProductImage(product)}
          <div className="bnl-product-regular__desc">{product.description}</div>
          <div className="bnl-product__items row">
            {renderProductContents(product)}
          </div>
        </>
      );
    }

    return null;
  };

  const renderProductContents = (product) => {
    if (product.contents && product.contents.length > 0) {
      const contents = [];
      let lists = [];
      let midPoint;

      if (product.contents.length % 2 === 0) {
        midPoint = product.contents.length / 2 - 1;
      } else {
        midPoint = Math.floor(product.contents.length / 2);
      }

      for (let i = 0; i <= midPoint; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-left`}>
          {lists}
        </ul>,
      );
      lists = [];

      for (let i = midPoint + 1; i < product.contents.length; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-right`}>
          {lists}
        </ul>,
      );

      return contents;
    }
  };

  const renderContentsOrDesc = (product) => {
    if (product.contents && product.contents.length > 0) {
      const contents = [];
      let lists = [];
      let midPoint;

      if (product.contents.length % 2 === 0) {
        midPoint = product.contents.length / 2 - 1;
      } else {
        midPoint = Math.floor(product.contents.length / 2);
      }

      for (let i = 0; i <= midPoint; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-left`}>
          {lists}
        </ul>,
      );
      lists = [];

      for (let i = midPoint + 1; i < product.contents.length; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-right`}>
          {lists}
        </ul>,
      );

      return contents;
    }
    if (product.description) {
      return (
        <div className="bnl-product__description">{product.description}</div>
      );
    }
  };

  const renderLock = (product, unlockedGroups) => {
    if (product.lock_type === 'unlockable') {
      let locked = true;

      product.lock_group.forEach((set) => {
        if (unlockedGroups.includes(set)) locked = false;
      });

      if (locked) {
        return (
          <img
            className="bnl-product__lock-image"
            alt="lock icon"
            src="https://s3.amazonaws.com/pics-assets/utils/product_lock.png"
          />
        );
      }
    }

    return null;
  };

  return (
    <li className={className(product)} key={product.id}>
      {renderLock(product, unlockedGroups)}
      {renderContent(product, unlockedGroups)}
    </li>
  );
};

CategoryProduct.propTypes = {
  unlockedGroups: PropTypes.array.isRequired,
};

export default CategoryProduct;
