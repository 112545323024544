import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Modal } from 'react-responsive-modal';
import styled, { css } from 'styled-components';

const baseButton = css`
  position: absolute;
  height: 60px;
  width: 60px;
  color: #0b76b7;
  top: calc(50% - 30px);
  cursor: pointer;
  z-index: 2;
`;

const LeftButton = styled(ArrowLeftCircleIcon)`
  ${baseButton}
  left: 30px;
  @media screen and (max-width: 500px) {
    left: 0;
  }
`;
const RightButton = styled(ArrowRightCircleIcon)`
  ${baseButton}
  right: 30px;
  @media screen and (max-width: 500px) {
    right: 0;
  }
`;

const ModalCarousel = ({ children, open, onClose, selectedItem }) => (
  <Modal
    open={open}
    onClose={onClose}
    center
    classNames={{
      modal: 'modal',
      overlay: 'overlay',
    }}
  >
    <Carousel
      selectedItem={selectedItem}
      showIndicators={false}
      autoPlay={false}
      useKeyboardArrows
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <LeftButton type="button" onClick={onClickHandler} title={label} />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <RightButton type="button" onClick={onClickHandler} title={label} />
        )
      }
    >
      {children}
    </Carousel>
  </Modal>
);

ModalCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedItem: PropTypes.number,
};

ModalCarousel.defaultProps = {
  selectedItem: 0,
};

export default ModalCarousel;
