import React, { Component } from 'react';
import Swal from 'sweetalert2';

class OrderProductAddOnsPersonalization extends Component {
  constructor(props) {
    super(props);

    this.applyPersonalization = this.applyPersonalization.bind(this);
    this.focusInput = this.focusInput.bind(this);
  }

  applyPersonalization() {
    if (
      this.props.personalizationValue.replace(/\s/g, '').length === 0 ||
      this.props.personalizationValue.length > 20
    ) {
      Swal.fire('Please enter 1-20 characters');
    } else if (this.props.personalizationEdit) {
      this.props.editPersonalization();
    } else {
      this.props.applyPersonalization();
    }
  }

  focusInput() {
    document.querySelector('.add-on__input').focus();
  }

  render() {
    return (
      <div className="order-product-add-on">
        <div className="add-on__cta-container col-xs-12 col-md-4 col-lg-4">
          <div className="add-on__header-container clearfix">
            <div className="add-on__header">Add Personalization</div>
            <div className="add-on__subheader">
              {this.props.displayPrice(this.props.personalization.price)}
            </div>
          </div>
          <div className="add-on__desc">
            Add personalization to your prints.
            <br />
            <br />
            Enter your student's first name only (max 20 characters). School
            year will be added automatically.
          </div>
          <div className="add-on__input-container">
            <input
              className="add-on__input"
              onChange={this.props.handleOnChange}
              name="personalizationValue"
              value={this.props.personalizationValue}
              placeholder="Student First Name"
            />
            <div
              className="add-on__input-focus-button cursor-pointer"
              onClick={this.focusInput}
            >
              Edit
            </div>
          </div>
          <div
            className="add-on__button-cta button--full-width bg--dark-blue"
            onClick={this.applyPersonalization}
          >
            Add Personalization
          </div>
          <div
            onClick={this.props.skipPersonalization}
            className="add-on__done-button button--full-width bg--dark-grey"
          >
            Skip Personalization
          </div>
        </div>
        <div className="add-on__images col-xs-12 col-md-4 col-lg-4">
          <img
            src="https://s3.amazonaws.com/pics-assets/products/geskus/spring-2019/Personalization.png"
            className="add-on__image--personalization"
          />
        </div>
        <div className="add-on__upsale-container col-xs-12 col-md-4 col-lg-4" />
      </div>
    );
  }
}

export default OrderProductAddOnsPersonalization;
