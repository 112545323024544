import React from 'react';
import styled from 'styled-components';

const PersonalizationValueWrapper = styled.p`
  @media (min-width: 991px) {
    margin-top: 16px;
    margin-left: 0;
  }
`;

const PersonalizationValue = styled.em`
  font-style: italic;
`;

const CartItemPersonalization = ({ cartItem }) => {
  if (!cartItem.personalizationValue) {
    return null;
  }

  return (
    <>
      <PersonalizationValueWrapper>
        Personalization:
        <br />
        <PersonalizationValue>
          {cartItem.personalizationValue}
        </PersonalizationValue>
      </PersonalizationValueWrapper>
    </>
  );
};

export default CartItemPersonalization;
