import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import cartUtils from '../../../../utils/cart';
import { StyledCheck } from '../../unsubscribe/Checkbox';
import CheckoutPaymentButton from './CheckoutPaymentButton';
import CheckoutSummaryLineItem from './CheckoutSummaryLineItem';
import PicsPlusModal from './PicsPlusModal';

const SummaryBox = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 0;
  text-align: center;
`;

const SummaryHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const PicsPlusBanner = styled.div`
  background: #0faaa4ff;
  margin-bottom: -12px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 24px;
  color: white;
  padding: 10px 10px 16px 10px;
  text-align: left;
  display: flex;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InfoButton = styled(InformationCircleIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #327ab7;
  margin-left: 5px;
`;

const ServiceFeeText = styled.p`
  margin: 0;
  span {
    font-weight: 700;
  }
`;

const PriceLabel = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(StyledCheck)`
  margin-right: 5px;
  border-radius: 5px;
  border: none;
  background: white;
  cursor: pointer;
`;

const CheckoutSummary = ({
  cartTotals,
  discountCodes,
  handleSubmit,
  submittingOrder,
}) => {
  const { offerPicsPlus } = useSelector((state) => state.v3Order);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const handleCheck = () => setChecked(!checked);
  const closeModal = () => {
    setOpen(false);
    setChecked(false);
  };
  const openModal = () => setOpen(true);
  const handleModalSubmit = () => {
    setChecked(true);
    setOpen(false);
  };
  // const handleSubmitWithPicsPlus = () => {
  //   setChecked(true);
  //   handleSubmit(true);
  // };
  const handleCheckout = () => {
    handleSubmit(checked, offerPicsPlus);
  };

  const containsFreeShippingDiscount =
    discountCodes?.some((discountCode) => {
      return discountCode.is_free_shipping;
    }) || false;

  return (
    <SummaryBox>
      <SummaryHeader>Order Summary</SummaryHeader>
      <CheckoutSummaryLineItem label="Subtotal" price={cartTotals['total']} />
      <CheckoutSummaryLineItem
        hideIfZero={true}
        label="Discount"
        price={cartTotals['discount']}
      />
      <CheckoutSummaryLineItem
        hideIfZero={true}
        label="Family Discount"
        price={cartTotals['familyDiscount']}
      />
      {/* Only show shipping if greater than 0 */}
      {cartUtils.cartHasShippingCost(cartTotals) && (
        <CheckoutSummaryLineItem
          label="Shipping"
          showFreeIfDiscount={containsFreeShippingDiscount}
          price={cartTotals['shipping']}
        />
      )}
      <CheckoutSummaryLineItem label="Tax" price={cartTotals['tax']} />
      {offerPicsPlus && (
        <CheckoutSummaryLineItem
          label={
            <PriceLabel>
              Service Fee <InfoButton onClick={openModal} />
            </PriceLabel>
          }
          price={0}
          showZeroIfZero={true}
        />
      )}
      <CheckoutSummaryLineItem
        label="Grand Total"
        price={cartTotals['grandTotal']}
        showZeroIfZero={true}
      />
      <CheckoutPaymentButton
        grandTotal={cartTotals['grandTotal']}
        handleSubmit={handleCheckout}
        submittingOrder={submittingOrder}
      />
      {/* <CheckoutPaymentButton
        grandTotal={cartTotals['grandTotal']}
        handleSubmit={() => handleSubmit(false)}
        submittingOrder={submittingOrder}
      /> */}
      {offerPicsPlus && (
        <PicsPlusBanner>
          <StyledCheckbox
            checked={checked}
            onClick={handleCheck}
            stroke="#0faaa4ff"
          />
          <ServiceFeeText>
            Sign me up for a free trial of{' '}
            <span onClick={openModal}>Pics+</span> so I can access{' '}
            <span onClick={openModal}>exclusive perks and deals!</span>
          </ServiceFeeText>
        </PicsPlusBanner>
      )}
      <PicsPlusModal
        open={open}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
      />
      {/* <PicsPlusModal
        open={open}
        onClose={closeModal}
        onSubmit={handleSubmitWithPicsPlus}
      /> */}
    </SummaryBox>
  );
};

export default CheckoutSummary;
