import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStudents } from '../../actions/action_parent';
import NewParentSignupFormStep1 from './NewParentSignupFormStep_1';
import ParentSignupFormStep2 from './parent_signup_form_step_2';

class NewParentSignupFormContainer extends Component {
  constructor(props) {
    super(props);

    let step;

    if (this.props.history.location.state) {
      step = this.props.history.location.state.step;
    } else {
      step = 1;
    }

    this.state = {
      step,
    };

    this.updateStep = this.updateStep.bind(this);
  }

  componentDidMount() {
    const {
      history,
      auth: { currentUserId, signupCompleted },
    } = this.props;

    if (currentUserId && !signupCompleted) {
      this.setState({ step: 2 });
    } else if (currentUserId && signupCompleted) {
      this.props.getStudents(currentUserId).then(() => {
        history.replace('/dashboard');
      });
    }
  }

  updateStep(step) {
    this.setState({ step });
  }

  renderParentSignupForm() {
    const { step } = this.state;
    const { history } = this.props;

    switch (step) {
      case 2:
        return <ParentSignupFormStep2 history={history} />;
      default:
        return (
          <NewParentSignupFormStep1
            history={history}
            updateStep={this.updateStep}
          />
        );
    }
  }

  render() {
    return <>{this.renderParentSignupForm()}</>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getStudents }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewParentSignupFormContainer);
