import React from 'react';
import styled from 'styled-components';

// TODO: extract this to a shared component
const PaymentButton = styled.button`
  display: inline-block;
  padding: 10px 16px;
  margin-top: 24px;
  border: none;
  background-color: #479de6;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  width: 100%;
  font-size: 16px;

  &:hover {
    color: #fff;
    background-color: #357ab8;
  }
`;

const CheckoutPaymentButton = ({
  grandTotal,
  handleSubmit,
  submittingOrder,
}) => {
  const getPaymentText = () => {
    if (grandTotal === 0) {
      return 'Place Order';
    }

    return 'Pay & Submit Order';
  };

  return (
    <PaymentButton
      id="payment-button"
      onClick={handleSubmit}
      disabled={submittingOrder}
    >
      {getPaymentText()}
    </PaymentButton>
  );
};

export default CheckoutPaymentButton;
