import React, { Component } from 'react';

class BnlOrderProductConfigurationPersonalization extends Component {
  renderRequirementText() {
    let text = '*';

    this.props.requirement
      .split('_')[1]
      .split('')
      .forEach((letter, i) => {
        if (i === 0) {
          text += letter.toUpperCase();
        } else {
          text += letter;
        }
      });

    return text;
  }

  renderSkipButton() {
    if (
      this.props.requirement === 'personalization_optional' &&
      !this.props.mobile
    ) {
      return (
        <div
          className="bnl-personalization__button button--round-border bg--bnl-light-blue"
          onClick={this.props.processConfiguration}
        >
          Skip Personalization
        </div>
      );
    }

    return null;
  }

  renderInstruction() {
    switch (this.props.personalizationType) {
      case 'custom_message':
        return (
          <h5 className="bnl-personalization-subheader">
            Write your very own custom message to be imprinted onto this
            product. (Max 25 Characters)
          </h5>
        );
      default:
        return (
          <>
            <p>
              Personalize your product by entering the student&apos;s first name
              using upper and lower case letters.
            </p>
            <p>For example: &quot;John&quot;</p>
            <p>Please do NOT use all caps (Max 20 characters).</p>
          </>
        );
    }
  }

  render() {
    return (
      <div className="bnl-product-config__personalization">
        <h4 className="bnl-personalization-header">Personalization</h4>
        {this.renderInstruction()}
        <div className="clearfix">
          <input
            className="bnl-personalization__input"
            onChange={this.props.handleOnChange}
            placeholder="Student First Name"
            value={this.props.getPersonalizationValue()}
            name="personalizationValue"
          />
          {this.renderSkipButton()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default BnlOrderProductConfigurationPersonalization;
