import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const PaddingElement = styled.div``;

const BackLink = styled.div`
  color: #479de6;
  font-size: 16px;
  top: 25px;
  &:hover {
    cursor: pointer;
  }
`;

const BackLinkFromProduct = ({
  hidden = false,
  history,
  hideBackLink = false,
}) => {
  if (hidden || hideBackLink) {
    return (
      <PaddingElement className="backlink-from-product">&nbsp;</PaddingElement>
    );
  }

  return (
    <BackLink className="backlink-from-product" onClick={history.goBack}>
      &larr; Back
    </BackLink>
  );
};

export default withRouter(BackLinkFromProduct);
