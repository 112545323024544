import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
// import { useToasts } from 'react-toast-notifications';
import GalleryResults from './subcomponents/GalleryResults';
import GalleryStudentAssignment from './subcomponents/GalleryStudentAssignment';
import VenueSearchInput from './subcomponents/VenueSearchInput';
import VenueSearchResults from './subcomponents/VenueSearchResults';
import OverlayLoader from '../utils/OverlayLoader';
import { debounce } from 'lodash';
import {
  AnswerSection,
  AssignSection,
  BackButton,
  BackLink,
  BackLinkWrapper,
  Button,
  Card,
  Dropdown,
  EventName,
  EventYear,
  FormWrapper,
  GalleryImage,
  GalleryPassword,
  Header,
  Input,
  PrimaryLink,
  QuestionTitle,
  ResultCard,
  ResultsSection,
  SearchDetails,
  StudentName,
  StyledInput,
  SuccessMessage,
  Title,
  UtilityLink,
  Wrapper,
} from '../../../styles/StudentKeyLookupStyles';

const LookupByVenueAndStudentId = () => {
  const [componentRendered, setComponentRendered] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [galleries, setGalleries] = useState([]);
  const [galleryForStudentAssignment, setGalleryForStudentAssignment] =
    useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lookupPerformed, setLookupPerformed] = useState(false);
  const [newStudentFirstName, setNewStudentFirstName] = useState('');
  const [newStudentLastName, setNewStudentLastName] = useState('');
  const [results, setResults] = useState([]);
  const [selectedGalleries, setSelectedGalleries] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [selectedVenue, setSelectedVenue] = useState({});
  const [selectedVenueId, setSelectedVenueId] = useState(null);
  const [selectedVenueName, setSelectedVenueName] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [studentId, setStudentId] = useState('');
  const [students, setStudents] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [venueNameForSearch, setVenueNameForSearch] = useState('');
  const [venueSearchInitiated, setVenueSearchInitiated] = useState(false);
  const [venueSearchResults, setVenueSearchResults] = useState([]);
  const [venueSearchResultsLoading, setVenueSearchResultsLoading] =
    useState(false);

  useEffect(() => {
    // This will be executed after the component mounts,
    // indicating the component has rendered at least once.
    setComponentRendered(true);
  }, []);

  useEffect(() => {
    if (componentRendered) {
      // Now we're sure the component has been rendered,
      // and it's safe to perform actions like deleting cookies.
      const activeRedirect = Cookies.get('active_redirect');
      if (activeRedirect) {
        Cookies.remove('active_redirect');
      }
    }
  }, [componentRendered]);

  useEffect(() => {
    let isMounted = true; // Flag to track mounting status

    const fetchStudents = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/v2/students-galleries');

        if (isMounted) {
          // Check if the component is still mounted
          setStudents(response.data?.students);
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchStudents();

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, []);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  const handleAssignStudent = async (galleryId) => {
    console.log('handleAssignStudent');
    console.log('selectedStudentId:', selectedStudentId);
    console.log('galleryId:', galleryId);

    try {
      const response = await fetch('/api/v2/assign-galleries-to-student', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          studentId: selectedStudentId,
          galleries: [galleryId],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to assign galleries');
      }

      const responseData = await response.json();

      let successMessage = 'galleries assigned';

      if (responseData.galleries && responseData.galleries.length === 1) {
        successMessage = 'Gallery assigned!';
      }

      setSuccessMessage(successMessage);
      setSelectedGalleries([]);
      setSelectedStudentId('');
      setNewStudentFirstName('');
      setNewStudentLastName('');

      const studentsResponse = await axios.get('/api/v2/students-galleries');
      setStudents(studentsResponse.data?.students);

      // toast.success('Galleries assigned successfully!');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error('Error: Bad request');
      } else {
        // toast.error('An unexpected error occurred');
      }
    }
  };

  const handleFormSubmit = async (galleryId, event) => {
    event.preventDefault();

    const galleryForSubmit = galleries.find(
      (gallery) => gallery.id === galleryId,
    );

    setLoading(true);

    if (!selectedStudentId && !newStudentFirstName && !newStudentLastName) {
      setFormError('Please select a student or enter a new student’s name.');
      setLoading(false);
      return;
    }

    if (!selectedStudentId && (!newStudentFirstName || !newStudentLastName)) {
      setFormError('Both first and last names are required for a new student.');
      setLoading(false);
      return;
    }

    if (isExistingStudent()) {
      setFormError('This student already exists.');
      setLoading(false);
      return;
    }

    setFormError('');

    try {
      const response = await fetch('/api/v2/assign-galleries-to-student', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          studentId: selectedStudentId,
          firstName: newStudentFirstName,
          lastName: newStudentLastName,
          galleries: [galleryId],
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to assign galleries');
      }

      const responseData = await response.json();

      let successMessage = 'galleries assigned';

      if (responseData.galleries && responseData.galleries.length === 1) {
        successMessage = 'gallery assigned';
      }

      setSuccessMessage(successMessage);
      setSelectedGalleries([]);
      setSelectedStudentId('');
      setNewStudentFirstName('');
      setNewStudentLastName('');

      const studentsResponse = await axios.get('/api/v2/students-galleries');
      setStudents(studentsResponse.data?.students);

      // toast.success('Galleries assigned successfully!');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error('Error: Bad request');
      } else {
        // toast.error('An unexpected error occurred');
      }
    }

    setLoading(false);
  };

  const handleDropdownChange = (e) => {
    setSelectedStudentId(e.target.value);
    setNewStudentFirstName('');
    setNewStudentLastName('');
    setFormError('');
  };

  const handleFirstNameChange = (e) => {
    setNewStudentFirstName(e.target.value);
    setSelectedStudent('');
    setSelectedStudentId('');
    setFormError('');
  };

  const handleLastNameChange = (e) => {
    setNewStudentLastName(e.target.value);
    setSelectedStudent('');
    setSelectedStudentId('');
    setFormError('');
  };

  const isExistingStudent = () => {
    const trimmedFirstName = newStudentFirstName.trim().toLowerCase();
    const trimmedLastName = newStudentLastName.trim().toLowerCase();

    return students.some(
      (student) =>
        student.first_name.trim().toLowerCase() === trimmedFirstName &&
        student.last_name.trim().toLowerCase() === trimmedLastName,
    );
  };

  const toggleGallerySelection = (galleryId) => {
    if (selectedGalleries.includes(galleryId)) {
      setSelectedGalleries(selectedGalleries.filter((id) => id !== galleryId));
    } else {
      setSelectedGalleries([...selectedGalleries, galleryId]);
    }
  };

  const getDomain = () => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    // Assuming standard domain formats like 'subdomain.domain.tld'
    // This will work for 'www.domain.com' as well, giving 'domain.com'
    const domain = parts.length > 2 ? parts.slice(-2).join('.') : hostname;
    return domain;
  };

  const getSubdomain = () => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    // Assuming the subdomain is the first part of the hostname
    // This will return an empty string for 'domain.com'
    const subdomain = parts.length > 2 ? parts[0] : '';
    return subdomain;
  };

  const handleSelectGallery = (galleryId) => {
    const gallery = galleries.find((gallery) => gallery.id === galleryId);

    // TODO: handle case where gallery can't be found(?)
    setGalleryForStudentAssignment(gallery);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLookupPerformed(true);
    setResults([]); // Clear previous results
    setErrorMessage(''); // Reset error message
    setSuccessMessage(''); // Reset success message

    try {
      const response = await axios.post('/api/v2/find-gallery-by-student-id', {
        student_id: studentId,
        company_domain: getDomain(),
        company_subdomain: getSubdomain(),
        venue_id: selectedVenue.id,
      });

      if (response.data?.galleries) {
        setGalleries(response.data.galleries);
      }

      if (response?.response?.status === 429) {
        setError(true);
        setErrorMessage(
          'too many lookups within given time period. Please try again later.',
        );
      }
    } catch (error) {
      if (error.response) {
        // Handle known errors (like 422) here
        setErrorMessage(
          `Error: ${error.response.status} - ${error.response.data.message}`,
        );
      } else {
        // Handle unknown errors
        setErrorMessage('An unknown error occurred.');
        console.error('Error:', error);
      }
    }

    setLoading(false);
  };

  const getAssignedStudent = (galleryId) => {
    const student = students.find((student) =>
      student.galleries.some((gallery) => gallery.id === galleryId),
    );
    return student || null;
  };

  const debouncedVenueSearch = useCallback(
    debounce(async (query) => {
      if (!query || query.length < 3) {
        setVenueSearchResults([]);
        return;
      }

      setVenueSearchResultsLoading(true);
      setVenueSearchInitiated(true);
      try {
        const response = await axios.post('/api/v2/venues-name-search', {
          query,
          student_id: studentId,
          company_domain: getDomain(), // TODO: set once on component load
          company_subdomain: getSubdomain(), // TODO: set once on component load
        });

        if (response.data?.venues) {
          setVenueSearchResults(response.data.venues);
        }
      } catch (error) {
        console.error('Failed to fetch venues:', error);
        setVenueSearchResults([]);
      } finally {
        setVenueSearchResultsLoading(false);
      }
    }, 200), // 200ms debounce time
    [],
  );

  const searchVenueName = (value) => {
    setVenueNameForSearch(value); // Update the input state
    debouncedVenueSearch(value); // Call the debounced search function
  };

  return (
    <Wrapper>
      <OverlayLoader loading={loading} />
      <Header>Find Galleries</Header>
      <BackLink to="/dashboard">&larr; Back to Dashboard</BackLink>

      {/* Step 1: Search for a venue */}
      {!selectedVenue.id && (
        <>
          <VenueSearchInput
            loading={loading}
            searchVenueName={searchVenueName}
            venueNameForSearch={venueNameForSearch}
          />
          <VenueSearchResults
            setSelectedVenue={setSelectedVenue}
            venueNameForSearch={venueNameForSearch}
            venueSearchInitiated={venueSearchInitiated}
            venueSearchResults={venueSearchResults}
            venueSearchResultsLoading={venueSearchResultsLoading}
          />
        </>
      )}

      {/* Step 2: Search for galleries via Student ID */}
      {selectedVenue.id && !galleries?.length > 0 && (
        <Card>
          <Title>
            <strong>Step 2</strong>
            <br />
            Search with your student's ID
            <br />
            <PrimaryLink
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleAnswer();
              }}
            >
              Where is the Student ID located?
            </PrimaryLink>
          </Title>
          {/* <QuestionTitle>Where is the Student ID Located?</QuestionTitle> */}
          <AnswerSection isOpen={isOpen}>
            <ul>
              <li>The Student ID can be found through your School Portal.</li>
              <li>
                The School Portal is the school's system to post things like
                announcements, events, grades, etc.
              </li>
              <li>
                The Student ID will also be listed on the student's School ID
                Card and Report Card.
              </li>
              <li>
                <strong>
                  Please Note: The portrait studio is unable to look up or
                  provide the Student ID.
                </strong>
              </li>
            </ul>
          </AnswerSection>
          <SearchDetails>School: {selectedVenue.name}</SearchDetails>
          <FormWrapper onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="student ID"
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
              disabled={loading}
            />
            <Button type="submit" disabled={loading}>
              Search
            </Button>
          </FormWrapper>
          <BackLinkWrapper>
            <UtilityLink
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedVenue({});
                setStudentId('');
                setVenueNameForSearch('');
                setVenueSearchInitiated(false);
                setVenueSearchResults([]);
              }}
            >
              back to step 1
            </UtilityLink>
          </BackLinkWrapper>
        </Card>
      )}
      {successMessage && (
        <>
          <SuccessMessage>{successMessage}</SuccessMessage>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <BackButton to="/dashboard">Back to Dashboard</BackButton>
          </div>
        </>
      )}

      {!successMessage && !galleryForStudentAssignment && (
        <GalleryResults
          error={error}
          errorMessage={errorMessage}
          formError={formError}
          galleries={galleries}
          getAssignedStudent={getAssignedStudent}
          handleAssignStudent={handleAssignStudent}
          handleDropdownChange={handleDropdownChange}
          handleFirstNameChange={handleFirstNameChange}
          handleFormSubmit={handleFormSubmit}
          handleLastNameChange={handleLastNameChange}
          handleSelectGallery={handleSelectGallery}
          isExistingStudent={isExistingStudent}
          loading={loading}
          lookupPerformed={lookupPerformed}
          newStudentFirstName={newStudentFirstName}
          newStudentLastName={newStudentLastName}
          selectedStudentId={selectedStudentId}
          selectedVenue={selectedVenue}
          setGalleries={setGalleries}
          setLookupPerformed={setLookupPerformed}
          studentId={studentId}
          students={students}
        />
      )}

      {/* Step 4: Assign gallery to a student */}
      {!successMessage && galleryForStudentAssignment && (
        <GalleryStudentAssignment />
      )}
      {/* {selectedGalleries.length > 0 && (
        <>
          <Header>Assign to a Student</Header>
          <AssignSection>
            <Dropdown
              value={selectedStudentId}
              onChange={handleDropdownChange}
              // onChange={(e) => setSelectedStudent(e.target.value)}
            >
              <option value="">Select a Student</option>
              {students.map((student) => (
                <option key={student.id} value={student.id}>
                  {student.first_name} {student.last_name}
                </option>
              ))}
            </Dropdown>
            <p>Or add a new student</p>
            {formError && <p style={{ color: 'red' }}>{formError}</p>}
            {isExistingStudent() && (
              <p style={{ color: 'red' }}>
                Error: Student already exists. Please select their name from the
                dropdown.
              </p>
            )}
            <StyledInput
              type="text"
              placeholder="First Name"
              value={newStudentFirstName}
              onChange={handleFirstNameChange}
            />

            <StyledInput
              type="text"
              placeholder="Last Name"
              value={newStudentLastName}
              onChange={handleLastNameChange}
            />
            <Button
              type="submit"
              onClick={handleFormSubmit}
              disabled={isExistingStudent()}
            >
              Assign
            </Button>
          </AssignSection>
        </>
      )} */}
    </Wrapper>
  );
};

export default LookupByVenueAndStudentId;
