import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { updateParent } from '../../../../actions/action_parent';

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const CancelLink = styled(Link)`
  display: inline-block;
  margin-left: 8px;
`;

const CustomerPhoneEdit = ({ history, onSuccessRoute }) => {
  const dispatch = useDispatch();

  const { smsPhone, phone, loading, publicToken } = useSelector(
    (state) => state.parent,
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [submissionError, setSubmissionError] = useState('');

  const formattedPhoneNumber = parsePhoneNumberFromString(
    smsPhone || phone,
    'US',
  );

  const onSubmit = async (data) => {
    const requestData = {
      phone: data.phone,
      publicToken,
    };

    dispatch(updateParent(requestData))
      .then(() => {
        history.push(onSuccessRoute);
      })
      .catch((error) => {
        let errorMessages = '';
        if (error.response.data.errors) {
          error.response.data.errors.forEach((message) => {
            errorMessages += message;
          });
        }
        setSubmissionError(errorMessages);
      });
  };

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Edit Phone</MainHeader>
        <Divider />
        <div className="order-checkout__main-content row">
          <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
            <Wrapper>
              <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors?.phone ? 'is-invalid' : ''
                    }`}
                    id="phone"
                    {...register('phone', { required: 'Phone is required' })}
                    defaultValue={
                      formattedPhoneNumber
                        ? formattedPhoneNumber.formatNational()
                        : 'Invalid Number'
                    }
                  />
                  {errors.phone && (
                    <div className="text-danger">{errors?.phone.message}</div>
                  )}
                  {submissionError && (
                    <div className="text-danger">{submissionError}</div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary">
                  Update Phone
                </button>
                <CancelLink to={onSuccessRoute} className="ml-2">
                  Cancel
                </CancelLink>
              </form>
            </Wrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerPhoneEdit.propTypes = {
  history: PropTypes.object,
  onSuccessRoute: PropTypes.string,
};

CustomerPhoneEdit.defaultProps = {
  history: {},
  onSuccessRoute: '/v3/checkout',
};

export default withRouter(CustomerPhoneEdit);
