import React, { Component } from 'react';

class BnlOrderProductConfigurationRequiredAttributes extends Component {
  listRequiredAttributes() {
    return this.props.selectedShootProduct.required_attributes.map(
      (attr, i) => (
        <div className="input-group" key={i}>
          <label>{this.renderAttributeName(attr)}</label>
          <input
            type="text"
            className="form-control"
            name={attr}
            value={this.props.getAdditionalAttributeValue(attr)}
            onChange={this.props.handleOnChange}
          />
        </div>
      )
    );
  }

  renderAttributeName(attr) {
    switch (attr) {
      case 'name':
        return 'Name';
      case 'school':
        return 'School Name';
      case 'address':
        return 'Address';
      case 'city':
        return 'City';
      case 'state':
        return 'State';
      case 'city_state':
        return 'City, State';
      case 'date':
        return 'Date';
      case 'rsvp':
        return 'RSVP Instruction';
      default:
        return attr;
    }
  }

  render() {
    return (
      <div>
        <p>Please fill out the following fields:</p>
        <div className="requiredAttributeInputs">
          {this.listRequiredAttributes()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

export default BnlOrderProductConfigurationRequiredAttributes;
