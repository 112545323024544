import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { updateParentAddress } from '../../../actions/action_parent';
import { confirmShippingAddress } from '../../../actions/v3Order';

// TODO: move to (shared) styled components file
const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const OriginalAddressWrapper = styled.div`
  margin-top: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const Header1 = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: bold;
  color: #479de6;
`;

const EditLinkStyled = styled(Link)`
  text-decoration: underline;
  color: #479de6;
  cursor: pointer;
  margin-top: 16px;
`;

const StyledButton = styled.button`
  display: block;
  margin-top: 16px;
  padding: 10px 16px;
  background-color: #479de6;
  border: none;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease;
  font-size: 16px;

  &:hover {
    color: #fff;
    background-color: #357ab8;
  }
`;

const StyledButtonGrey = styled(StyledButton)`
  background-color: #b0b0b0;
  &:hover {
    background-color: #8c8c8c;
  }
`;

const CustomerAddressConfirmation = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [shouldDispatchUseSuggested, setShouldDispatchUseSuggested] =
    useState(false);
  const [submittedAddress, setSubmittedAddress] = useState(null);
  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);

  const {
    address: { address1, address2, city, state, zipcode },
  } = useSelector((state) => state.parent);
  const { shippingAddressConfirmed } = useSelector((state) => state.v3Order);

  useEffect(() => {
    if (shouldDispatchUseSuggested) {
      dispatch(updateParentAddress(suggestedAddress));
      dispatch(confirmShippingAddress());
      setShouldDispatchUseSuggested(false); // Reset to prevent repeated dispatches
    }
  }, [shouldDispatchUseSuggested, dispatch, suggestedAddress]);

  // Redirect to cart if address is already confirmed (this case should only happen if the component is accessed directly via URL)
  if (shippingAddressConfirmed) {
    return <Redirect to="/v3/checkout" />;
  }

  const handleAddressConfirmationOverride = async () => {
    setIsLoading(true);
    dispatch(confirmShippingAddress());
  };

  const handleUseSuggestedAddress = async () => {
    setIsLoading(true);

    await axios.post('/api/v2/parents/update-address-with-suggested', {
      address_1: suggestedAddress.address_1,
      address_2: suggestedAddress.address_2,
      city: suggestedAddress.city,
      state: suggestedAddress.state,
      zipcode: suggestedAddress.zip_code,
    });

    setIsLoading(false);
    setShouldDispatchUseSuggested(true);
  };

  const handleAddressConfirmation = async () => {
    setIsLoading(true);
    setVerificationStatus(null);
    setSuggestedAddress(null);
    setSubmittedAddress(null);

    const response = await axios.post(
      '/api/v2/parents/check-address-verification-status',
      {
        address_1: address1,
        address_2: address2,
        city,
        state,
        zipcode,
      },
    );

    const { status, submitted_address, suggested_address, message } =
      response.data;

    setIsLoading(false);

    if (status === 'verification_check_not_active') {
      dispatch(confirmShippingAddress());
    } else if (status === 'verified') {
      dispatch(confirmShippingAddress());
    } else if (status === 'suggested') {
      setSuggestedAddress(suggested_address);
      setVerificationStatus('suggested');
    } else if (status === 'unverified') {
      setVerificationStatus('unverified');
    } else if (status === 'error') {
      setVerificationStatus('error');
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading">
          <div className="spinner" />
        </div>
      )}
      <MainHeader>Confirm Your Shipping Address</MainHeader>
      <Divider />
      <Wrapper>
        {verificationStatus === 'suggested' && (
          <>
            <div className="alert alert-warning">
              <p>
                Please consider using this suggested mailing address for better
                accuracy and faster delivery.
              </p>
            </div>
          </>
        )}
        {verificationStatus === 'unverified' && (
          <>
            <div className="alert alert-danger">
              <p>
                The submitted address could not be verified as a valid mailing
                address. Please edit or confirm you'd like to use this address
                anyway.
              </p>
            </div>
          </>
        )}
        {!verificationStatus && (
          <div className="alert alert-info">
            <p>
              Your cart contains items that will be shipped to the address
              below. Please confirm or edit before checking out.
            </p>
          </div>
        )}
        <>
          {suggestedAddress && (
            <>
              <Header1>Suggested Address</Header1>
              <p>
                {suggestedAddress.address_1}
                <br />
                {suggestedAddress.address_2 && suggestedAddress.address_2}
                {suggestedAddress.address_2 && <br />}
                {suggestedAddress.city}, {suggestedAddress.state}{' '}
                {suggestedAddress.zip_code}
                <StyledButton
                  id="use-suggested-address-button"
                  onClick={() => {
                    handleUseSuggestedAddress();
                  }}
                >
                  Use Suggested Address
                </StyledButton>
              </p>
              <OriginalAddressWrapper>
                <Header1>Original Address</Header1>
                <p>
                  {address1}
                  <br />
                  {address2 && address2}
                  {address2 && <br />}
                  {city}, {state} {zipcode}
                  <br />
                  <EditLinkStyled to="/v3/checkout/customer-address-edit">
                    edit
                  </EditLinkStyled>
                  <StyledButtonGrey
                    id="use-original-address-button"
                    onClick={() => {
                      handleAddressConfirmationOverride();
                    }}
                  >
                    Use Original Address
                  </StyledButtonGrey>
                </p>
              </OriginalAddressWrapper>
            </>
          )}
          {!suggestedAddress && (
            <>
              <Header1>Your Address</Header1>
              <p>
                {address1}
                <br />
                {address2 && address2}
                {address2 && <br />}
                {city}, {state} {zipcode}
                <br />
                <EditLinkStyled to="/v3/checkout/customer-address-edit">
                  edit
                </EditLinkStyled>
              </p>
            </>
          )}
        </>
        <div>
          {!verificationStatus && (
            <StyledButton
              id="confirm-address-button"
              onClick={() => {
                handleAddressConfirmation();
              }}
            >
              Confirm Shipping Address
            </StyledButton>
          )}
          {verificationStatus === 'unverified' && (
            <StyledButton
              id="confirm-non-verified-address-button"
              type="submit"
              className="btn btn-primary"
              onClick={() => handleAddressConfirmationOverride()}
            >
              Confirm Shipping Address
            </StyledButton>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default CustomerAddressConfirmation;
