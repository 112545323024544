import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';
import { setBnlPreShootStudent } from '../../../actions/action_bnl_pre_shoot';
import { storeYearbookStudentId } from '../../../actions/action_bnl_yearbook_photo_selection';
import { setStudentData } from '../../../actions/action_parent';
import {
  getShootData,
  formatAndSetBackgroundOptions,
  formatAndSetIncentiveProducts,
  setRetouchingProduct,
  setRetouchingProducts,
  setPersonalizationProduct,
} from '../../../actions/action_shoot';
import { removeOrder } from '../../../actions/action_cart';
import { setAdditionalData } from '../../../actions/additionalData';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';
import {
  getGalleries,
  setCpmId,
  storeGallery,
  // removeStudentImage
} from '../../../actions/action_gallery';
import {
  storeOrder,
  updateBnlUnlockedGroups,
} from '../../../actions/action_cart';
import galleryUtils from '../../../utils/gallery';

// React.js components
import Spinner from '../../utils/spinner';

import UIContext from '../../../contexts/UIContext';

import { ORDER_NAVIGATION } from '../../../constants/order_navigation';
import { add } from 'lodash';
import gallery from '../../../utils/gallery';

const StudentSelectionWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 100px 0;
  text-align: center;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

class BnlStudentSelection extends Component {
  static contextType = UIContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.goToAddStudent = this.goToAddStudent.bind(this);
    this.goToPreShootDetailForm = this.goToPreShootDetailForm.bind(this);
    this.submitGalleryKey = this.submitGalleryKey.bind(this);
  }

  componentDidMount() {
    console.log('⚽🏀🏐🏈 IS SPORTS: ' + this.props.additionalData.isSports);
    const page = document.getElementById('main');
    page.style.zoom = '100%';

    const eventKey = Cookies.get('pics_event_key');

    // remove stored event key
    if (eventKey && eventKey !== '') {
      Cookies.remove('pics_event_key');
    }
  }

  cloneArray(array) {
    return array.slice();
  }

  goToAddStudent() {
    this.props.history.push('/add-student');
  }

  goToPreShootDetailForm(student) {
    const enteredShootKey = this.props.bnlPreShoot.shootKey;
    const { priceListEventType } = this.props.additionalData;
    const that = this;

    const {
      additionalData: { keySubmittedByUser, picsGalleryId, isSports },
      auth: { currentUserEmail },
      v3Order,
    } = this.props;

    const v3Orders = v3Order.orders;

    Sentry.setUser({ email: currentUserEmail });

    if (v3Orders && v3Orders[student.id]) {
      const event = v3Orders[student.id]?.event;

      if (event.id !== this.props.bnlPreShoot?.shootId) {
        Sentry.captureMessage('Student already has a v3 order in progress');

        Swal.fire({
          title: 'Notice',
          html: `
                <p>You already have an order in your cart for ${student.firstName} for ${event.name}.</p>
                <p>Only one event per student can be placed per order.</p>
                <p>Please complete or remove your order for ${student.firstName} for ${event.name} before starting a new order.</p>
            `,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        return;
      }
    }

    let alertTitle = `Does ${student.firstName} attend ${this.props.bnlPreShoot.shootName}?`;

    if (priceListEventType === 'sports') {
      alertTitle = `Please confirm you are ordering for ${student.firstName} at the following event: ${this.props.bnlPreShoot.shootName}?`;
    }

    if (this.props.cart.orders[student.id]) {
      const orderState = this.props.cart.orders[student.id];
      const storedShootKey = orderState.shootKey;

      if (enteredShootKey !== storedShootKey) {
        Sentry.setContext('getFormattedOrdersData', {
          enteredShootKey,
          storedShootKey,
          studentId: student.id,
        });

        Sentry.captureMessage('Student already has an order in progress');

        Swal.fire({
          showCancelButton: true,
          focusConfirm: true,
          reverseButtons: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          title: `${student.firstName} ${student.lastName} currently has an order in progress with a different code: ${storedShootKey}`,
          html: `
            <p>Do you want to start a new order with the code you just entered (${enteredShootKey})?</p>
          `,
          customClass: {
            container: 'order-in-progress',
          },
        }).then((res) => {
          if (res.value) {
            Swal.fire({
              title: alertTitle,
              confirmButtonText: 'Yes',
              showCancelButton: true,
              reverseButtons: true,
              focusConfirm: true,
              cancelButtonText: 'No',
            }).then((res) => {
              if (res.value) {
                // TDOO: revist this logic... we shouldn't need to pass the order object, only the student ID
                this.props.removeOrder(student.id, this.props.cart.orders);

                that.props.setBnlPreShootStudent(student);

                if (picsGalleryId) {
                  galleryUtils
                    .assignStudentToPicsGallery(picsGalleryId, student.id)
                    .finally(() => {
                      that.props.history.push('/preshoot-student-form');
                    });
                } else {
                  that.props.history.push('/preshoot-student-form');
                }
              } else {
                that.props.history.push('/dashboard');
              }
            });
          } else {
            that.props.history.push('/dashboard');
          }
        });
      } else {
        // there's already an order in progress for the selected student (with the same shoot key)
        that.props.setBnlPreShootStudent(student);

        if (picsGalleryId) {
          galleryUtils
            .assignStudentToPicsGallery(picsGalleryId, student.id)
            .finally(() => {
              that.props.history.push('/preshoot-student-form');
            });
        } else {
          that.props.history.push('/preshoot-student-form');
        }
      }
    } else {
      Swal.fire({
        title: alertTitle,
        confirmButtonText: 'Yes',
        showCancelButton: true,
        reverseButtons: true,
        focusConfirm: true,
        cancelButtonText: 'No',
      }).then((res) => {
        if (res.value) {
          that.props.setBnlPreShootStudent(student);

          if (picsGalleryId) {
            galleryUtils
              .assignStudentToPicsGallery(picsGalleryId, student.id)
              .finally(() => {
                that.props.history.push('/preshoot-student-form');
              });
          } else {
            that.props.history.push('/preshoot-student-form');
          }
        } else {
          that.props.history.push('/dashboard');
        }
      });
    }
  }

  storeGallery(student, responseData) {
    const that = this;
    let storingGallery = [];
    const cpmId = responseData.shoot.pricing_model.id;

    // redo this
    if (
      this.props.gallery.galleries[cpmId] &&
      this.props.gallery.galleries[cpmId][student.id] &&
      this.props.gallery.galleries[cpmId][student.id].gallery
    ) {
      storingGallery = this.cloneArray(
        this.props.gallery.galleries[cpmId][student.id].gallery,
      );
    }

    responseData.gallery_images.forEach((galleryImage) => {
      let duplicate = false;
      if (
        this.props.gallery.galleries[cpmId] &&
        this.props.gallery.galleries[cpmId][student.id] &&
        this.props.gallery.galleries[cpmId][student.id].gallery
      ) {
        that.props.gallery.galleries[cpmId][student.id].gallery.forEach(
          (storedGallery) => {
            if (galleryImage.id === storedGallery.id) duplicate = true;
          },
        );
      }

      if (!duplicate) storingGallery.push(galleryImage);
    });

    const storingAssociatedGroupImages = [];

    if (
      this.props.gallery.galleries[cpmId] &&
      this.props.gallery.galleries[cpmId][student.id] &&
      this.props.gallery.galleries[cpmId][student.id].associatedGroupImages
    ) {
      this.cloneArray(
        this.props.gallery.galleries[cpmId][student.id].associatedGroupImages,
      );
    }

    responseData.associated_group_images.forEach((groupImage) => {
      let duplicate = false;
      if (
        this.props.gallery.galleries[cpmId] &&
        this.props.gallery.galleries[cpmId][student.id] &&
        this.props.gallery.galleries[cpmId][student.id].associatedGroupImages
      ) {
        that.props.gallery.galleries[cpmId][
          student.id
        ].associatedGroupImages.forEach((storedGallery) => {
          if (groupImage.id === storedGallery.id) duplicate = true;
        });
      }

      if (!duplicate) storingAssociatedGroupImages.push(groupImage);
    });

    const gallery = {
      gallery: storingGallery,
      associatedGroupImages: storingAssociatedGroupImages,
      shoot: responseData.shoot,
    };

    this.props.storeGallery(
      cpmId,
      student,
      gallery,
      responseData.requires_yearbook_photo_selection,
    );
  }

  formatBackgroundOptions(containsBackgroundCategories, backgroundOptions) {
    const formattedBackgroundOptions = {};

    if (containsBackgroundCategories) {
      backgroundOptions.forEach((backgroundSet) => {
        backgroundSet.background_images.forEach((backgroundOption) => {
          formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
        });
      });
    } else {
      backgroundOptions.forEach((backgroundOption) => {
        formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
      });
    }

    this.props.formatAndSetBackgroundOptions(formattedBackgroundOptions);
  }

  formatBackgroundOptions(containsBackgroundCategories, backgroundOptions) {
    const formattedBackgroundOptions = {};

    if (containsBackgroundCategories) {
      backgroundOptions.forEach((backgroundSet) => {
        backgroundSet.background_images.forEach((backgroundOption) => {
          formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
        });
      });
    } else {
      backgroundOptions.forEach((backgroundOption) => {
        formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
      });
    }

    this.props.formatAndSetBackgroundOptions(formattedBackgroundOptions);
  }

  formatIncentiveProducts(incentiveProducts) {
    const formattedIncentiveProducts = {};

    incentiveProducts.forEach((incentiveProduct) => {
      if (!formattedIncentiveProducts[incentiveProduct.incentive_threshold]) {
        formattedIncentiveProducts[incentiveProduct.incentive_threshold] = [];
      }

      formattedIncentiveProducts[incentiveProduct.incentive_threshold].push(
        incentiveProduct,
      );
    });

    this.props.formatAndSetIncentiveProducts(formattedIncentiveProducts);
  }

  setUnlockedGroups(studentId) {
    const orderState = this.props.cart.orders[studentId];
    let bnlUnlockedGroups = [];

    if (orderState) {
      bnlUnlockedGroups = this.cloneArray(this.props.cart.bnlUnlockedGroups);

      orderState.cartItems.forEach((cartItem) => {
        if (cartItem.lock_type === 'unlocker') {
          cartItem.lock_group.forEach((set) => {
            if (!bnlUnlockedGroups.includes(set)) bnlUnlockedGroups.push(set);
          });
        }
      });
    }

    this.props.updateBnlUnlockedGroups(bnlUnlockedGroups);
  }

  submitGalleryKey(student) {
    const uiSettings = this.context; // get UI version from context
    const { uiVersion } = uiSettings;

    const that = this;
    const data = {
      student_id: student.id,
      shoot_key: this.props.bnlPreShoot.galleryPassword,
      grade_code: 'IDK',
    };

    const { setPersonalizationProduct } = this.props;

    Swal.fire({
      title: `Is ${this.props.bnlPreShoot.galleryPassword} for ${student.firstName}?`,
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((res) => {
      if (res.value) {
        that.setState(
          {
            loading: true,
          },
          () => {
            that.props.getShootData(data).then((response) => {
              that.setState(
                {
                  loading: false,
                },
                () => {
                  if (!response.payload.data.errors) {
                    const shootData = response.payload.data;
                    const ui_version = shootData.shoot?.ui_version;
                    // 'Handle Geskus Post-Event'
                    // TODO: remove Geskus-specific logic
                    if (shootData?.shoot?.company_auth_token === 'geskus') {
                      // TODO: remove
                      console.log('Handling Geskus Student Datum');
                      console.log(
                        'UI VERSION: ' + shootData?.shoot?.ui_version,
                      );
                      console.log(shootData);
                      console.log('+-+-+-+-+');

                      // get the ui version from the event (shoot)
                      const eventUIVersion =
                        response.payload.data.shoot?.ui_version;

                      // TODO: v3 - remove
                      console.log(
                        '%corder entry point: 4 -- Geskus',
                        'color: yellow;',
                      );
                      console.log(
                        `%cUI version: ${uiVersion}`,
                        `color: yellow;`,
                      );
                      console.log(
                        `%cevent UI version: ${eventUIVersion}`,
                        `color: yellow;`,
                      );

                      that.formatBackgroundOptions(
                        shootData.contains_background_categories,
                        shootData.background_options,
                      );

                      that.props.setRetouchingProduct(
                        shootData.retouching_product,
                      );

                      that.props.setRetouchingProducts(
                        shootData.retouching_products,
                      );

                      setPersonalizationProduct(
                        shootData.personalization_product,
                      );

                      that.formatIncentiveProducts(
                        shootData.incentive_products,
                      );

                      that.props.setCpmId(shootData.shoot.pricing_model.id);

                      that.props.setAdditionalData({
                        eventUIVersion,
                        student: student,
                        selectedStudent: student,
                        studentId: student.id,
                        shootKey: this.props.bnlPreShoot.galleryPassword,
                        eventName: shootData?.shoot?.name,
                        keyEntry: this.props.bnlPreShoot.galleryPassword,
                        isGeskus: true,
                        isPostEvent: true,
                        studentPreviewImageUrl:
                          shootData.student_photo_preview_url,
                        studentImage: shootData.student_image,
                        uiVersion,
                      });

                      this.props.setStudentData({
                        id: student.id,
                        name: `${student.firstName} ${student.lastName}`,
                        firstName: student.firstName,
                      });

                      that.setUnlockedGroups(student.id);

                      // TODO: v3 - move to helper function
                      if (
                        ['3', 'v3'].includes(uiVersion) ||
                        eventUIVersion === 'ui_v3'
                      ) {
                        const productKey = Cookies.get('product_key');
                        if (productKey) {
                          this.props.history.push(
                            `/v3/products-regular/${productKey}`,
                          );
                          // this.props.history.push(`/v3/products/${productKey}`);
                        } else {
                          this.props.history.push('/v3/incentive-offers');
                        }
                      } else {
                        // nav to V1 of UI if set on shoot
                        if (ui_version === 'ui_v1') {
                          that.props.updateOrderActiveComponent(
                            ORDER_NAVIGATION.PRODUCT_GROUPS,
                          );
                        } else {
                          that.props.updateOrderActiveComponent(
                            ORDER_NAVIGATION.BNL_PRODUCT_GROUPS,
                          );
                        }

                        that.props.history.push('/order');
                      }
                    } else {
                      that.storeGallery(student, shootData);

                      that.formatBackgroundOptions(
                        shootData.contains_background_categories,
                        shootData.background_options,
                      );
                      // if dp2 shoot requires year book selection && selection is not expired
                      if (shootData.requires_yearbook_photo_selection) {
                        that.props.storeYearbookStudentId(student.id);
                        that.props.history.push('/yearbook-photo-selection');
                      } else {
                        that.props.history.push('/dashboard');
                      }
                    }
                  }
                },
              );
            });
          },
        );
      } else {
        that.props.history.push('/dashboard');
      }
    });
  }

  renderImagePreviews() {
    const images = this.props.bnlPreShoot.imageUrls.map((imageUrl) => (
      <li className="col-xs-3 col-md-3 col-lg-3" key={`image-${imageUrl}`}>
        <img
          className="bnl-dashboard__student-selection__img-previews__img"
          src={imageUrl}
          onContextMenu={(event) => event.preventDefault()}
        />
      </li>
    ));

    return (
      <ul className="bnl-dashboard__student-selection__img-previews row equal-height">
        {images}
      </ul>
    );
  }

  renderHeader(priceListEventType) {
    let headerText = `Which student in ${this.props.bnlPreShoot.shootName} are you ordering for?`;

    if (priceListEventType === 'sports') {
      headerText = `Which athlete are you ordering for?`;
    }

    if (this.props.bnlPreShoot.enteredKeyIsPrepay) {
      return (
        <div className="bnl-dashboard__student-selection__header-container">
          <div className="bnl-dashboard__student-selection__header">
            {this.props.bnlPreShoot.shootKey}
          </div>
          <div className="bnl-dashboard__student-selection__subheader">
            {headerText}
          </div>
        </div>
      );
    }

    return (
      <div className="bnl-dashboard__student-selection__header-container">
        <div className="bnl-dashboard__student-selection__header">
          Which student does gallery {this.props.bnlPreShoot.galleryPassword}{' '}
          belong to?
        </div>
      </div>
    );
  }

  renderStudents() {
    let onClick;

    if (this.props.bnlPreShoot.enteredKeyIsPrepay) {
      onClick = this.goToPreShootDetailForm;
    } else {
      onClick = this.submitGalleryKey;
    }

    const students = this.props.parent.students.map((student) => (
      <li
        className="bnl-dashboard__student-seleciton__students__student"
        key={`student-${student.id}`}
        onClick={() => onClick(student)}
      >
        {student.firstName} {student.lastName}
      </li>
    ));

    return students;
  }

  render() {
    const { priceListEventType } = this.props.additionalData;
    return (
      <StudentSelectionWrapper>
        <Spinner loading={this.state.loading} />
        {this.renderHeader(priceListEventType)}
        <ul className="bnl-dashboard__student-selection__students">
          {this.renderStudents()}
        </ul>
        <div
          className="bnl-dashboard__student-selection__add-student cursor-pointer"
          onClick={this.goToAddStudent}
        >
          + Add New Student
        </div>
        {this.renderImagePreviews()}
      </StudentSelectionWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    gallery: state.gallery,
    parent: state.parent,
    shoot: state.shoot,
    v3Order: state.v3Order,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getShootData,
      formatAndSetBackgroundOptions,
      formatAndSetIncentiveProducts,
      removeOrder,
      setAdditionalData,
      setBnlPreShootStudent,
      setCpmId,
      setRetouchingProduct,
      setRetouchingProducts,
      setPersonalizationProduct,
      setStudentData,
      storeGallery,
      storeYearbookStudentId,
      updateBnlUnlockedGroups,
      updateOrderActiveComponent,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BnlStudentSelection);
