import axios from 'axios';

export const GET_BNL_KEY_LOOKUP = 'GET_BNL_KEY_LOOKUP';
export const SET_BNL_PRE_SHOOT_STUDENT = 'SET_BNL_PRE_SHOOT_STUDENT';
export const SET_BNL_PRE_SHOOT_STUDENTS_DETAILS =
  'SET_BNL_PRE_SHOOT_STUDENTS_DETAILS';
export const SET_BNL_PRE_SHOOT = 'SET_BNL_PRE_SHOOT';
export const SET_ENTERED_KEY_IS_PREPAY = 'SET_ENTERED_KEY_IS_PREPAY';

const apiUrl = '/api/v2';

export function getBnlKeyLookup(data) {
  const request = axios.get(`${apiUrl}/shoots/bnl-key-lookup`, {
    params: data,
  });

  return {
    type: GET_BNL_KEY_LOOKUP,
    payload: request,
  };
}

export function setBnlPreShootStudent(student) {
  return {
    type: SET_BNL_PRE_SHOOT_STUDENT,
    payload: student,
  };
}

export function setBnlPreShootStudentsDetails(studentId, details) {
  const data = { studentId, details };

  return {
    type: SET_BNL_PRE_SHOOT_STUDENTS_DETAILS,
    payload: data,
  };
}

export function setBnlPreShoot(preShoot) {
  return {
    type: SET_BNL_PRE_SHOOT,
    payload: preShoot,
  };
}

export function setEnteredKeyIsPrepay(preShoot) {
  return {
    type: SET_ENTERED_KEY_IS_PREPAY,
    payload: preShoot,
  };
}
