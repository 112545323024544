import * as Sentry from '@sentry/react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { setBnlPreShoot } from '../../../actions/action_bnl_pre_shoot';
import {
  formatAndSetBackgroundOptions,
  formatAndSetIncentiveProducts,
  getShootData,
  setInterstitialProducts,
  setPersonalizationProduct,
  setPreCartOfferProducts,
  setRetouchingProduct,
  setRetouchingProducts,
  setTriggersPreCartOfferProducts,
} from '../../../actions/action_shoot';
import { setAdditionalData } from '../../../actions/additionalData';
import cartUtils from '../../../utils/cart';
import {
  EditPoseLink,
  EventWrapper,
  GalleryImage,
  GalleryImageContainer,
  GalleryOrderButton,
  GalleryWrapper,
  GroupGalleryImage,
  SelectPoseBanner,
  SelectedBanner,
  StudentCard,
  StudentName,
  ViewGalleryButton,
} from './dashboardGalleriesStyles';

const DashboardGalleries = ({ history }) => {
  // TODO: move to a const file?
  const BASE_IMAGE_URL = 'https://pics-previews.s3.amazonaws.com/';
  const BASE_WATERMARKED_IMAGE_URL =
    'https://pics-previews-watermarked.s3.amazonaws.com/';

  const dispatch = useDispatch();

  const orders = useSelector((state) => state.v3Order.orders);
  const currentUserId = useSelector((state) => state.auth.currentUserId);

  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState(false);

  useEffect(() => {
    if (currentUserId) {
      mixpanel.identify(currentUserId);

      // Optionally, you might also want to set profile properties here
      mixpanel.people.set({
        // Set any relevant user properties
      });
    }
  }, [currentUserId]);

  useEffect(() => {
    let isMounted = true; // Flag to track mounted status

    const fetchData = async () => {
      try {
        setIsLoading(true); // Start loading
        const response = await axios.get('/api/v2/dashboard');

        if (isMounted) {
          setDashboardData(response.data); // Only update state if component is mounted
          setIsLoading(false); // Only update state if component is mounted
        }
      } catch (error) {
        console.error('Failed to fetch galleries: ', error);
        if (isMounted) {
          setIsLoading(false); // Only update state if component is mounted
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array to run the effect only once

  // TODO: move to a helper?
  const formatBackgroundOptions = (
    containsBackgroundCategories,
    backgroundOptions,
  ) => {
    const formattedBackgroundOptions = {};

    if (containsBackgroundCategories) {
      backgroundOptions.forEach((backgroundSet) => {
        backgroundSet.background_images.forEach((backgroundOption) => {
          formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
        });
      });
    } else {
      backgroundOptions.forEach((backgroundOption) => {
        formattedBackgroundOptions[backgroundOption.id] = backgroundOption;
      });
    }

    dispatch(formatAndSetBackgroundOptions(formattedBackgroundOptions));
  };

  // TODO: move to a helper?
  const formatIncentiveProducts = (incentiveProducts) => {
    const formattedIncentiveProducts = {};

    incentiveProducts.forEach((incentiveProduct) => {
      if (!formattedIncentiveProducts[incentiveProduct.incentive_threshold]) {
        formattedIncentiveProducts[incentiveProduct.incentive_threshold] = [];
      }

      formattedIncentiveProducts[incentiveProduct.incentive_threshold].push(
        incentiveProduct,
      );
    });

    dispatch(formatAndSetIncentiveProducts(formattedIncentiveProducts));
  };

  // TODO: can we remove this? i.e. is it needed in v3?
  // const setUnlockedGroups = (studentId) => {
  //   const orderState = this.props.cart.orders[studentId];
  //   let bnlUnlockedGroups = [];

  //   if (orderState) {
  //     bnlUnlockedGroups = this.cloneArray(this.props.cart.bnlUnlockedGroups);

  //     orderState.cartItems.forEach((cartItem) => {
  //       if (cartItem.lock_type === 'unlocker') {
  //         cartItem.lock_group.forEach((set) => {
  //           if (!bnlUnlockedGroups.includes(set)) bnlUnlockedGroups.push(set);
  //         });
  //       }
  //     });
  //   }

  //   dispatch(updateBnlUnlockedGroups(bnlUnlockedGroups));
  // };

  const determinePriceListIdForOrder = (gallery) => {
    if (gallery.post_shoot_company_pricing_model_id) {
      return gallery.post_shoot_company_pricing_model_id;
    }
    if (
      gallery.reorder_buyer_pricing_model_id &&
      gallery.reorder_non_buyer_pricing_model_id
    ) {
      if (gallery.gallery_buyer_status_at_ingest === 'buyer') {
        return gallery.reorder_buyer_pricing_model_id;
      } else if (gallery.gallery_buyer_status_at_ingest === 'non_buyer') {
        return gallery.reorder_non_buyer_pricing_model_id;
      } else if (gallery.gallery_buyer_status_at_ingest === 'unknown') {
        return gallery.reorder_non_buyer_pricing_model_id;
      }
    }

    return gallery.company_pricing_model_id;
  };

  const isBeforeDeadline = (deadline) => {
    const today = new Date();
    return today <= new Date(deadline);
  };

  const shouldShowBanner = (student) => {
    return student.galleries.some(
      (gallery) =>
        gallery.requires_yearbook_selection &&
        !gallery.yearbook_selection_performed &&
        isBeforeDeadline(gallery.yearbook_selection_deadline),
    );
  };

  const goToOrder = (student, gallery, isEdit = false) => {
    const requiresYearbookSelection = gallery.requires_yearbook_selection;

    const hasYearbookSelection = gallery.yearbook_selection_performed;

    console.log('requiresYearbookSelection: ', requiresYearbookSelection);

    const selectedGalleryId = gallery.id;

    const showPriceListMatchedPoses =
      dashboardData?.show_price_list_matched_poses_in_order_ui || false;

    console.log('showPriceListMatchedPoses: ', showPriceListMatchedPoses);

    const v3CartContainsPrepayOrders =
      cartUtils.cartContainsPrepayOrders(orders);

    if (v3CartContainsPrepayOrders) {
      Swal.fire({
        title: 'Your cart contains prepay orders',
        html: '<p>Your cart contains prepay orders, which cannot be submitted with gallery-based orders.</p><p>Please submit or clear your prepay orders to enable gallery-based ordering.</p>',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Okay',
      });

      Sentry.setContext('orders', {
        orders: orders,
        orderKeys: Object.keys(orders),
      });

      Sentry.captureMessage('pre-order in progress warning');

      return;
    }

    const priceListIdForOrder = determinePriceListIdForOrder(gallery);

    if (!priceListIdForOrder) {
      Swal.fire({
        title: 'Error',
        html: '<p>Something went wrong. Please try again in a few minutes.</p>',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Okay',
      });

      Sentry.captureMessage(
        `Cannot determine price list for gallery ${gallery.id}`,
      );

      return;
    }

    const galleryThatInitiatedOrder = {
      galleryId: gallery.id,
      studentId: student.id,
      buyerStatusAtIngest: gallery.gallery_buyer_status_at_ingest,
      priceListId: gallery.company_pricing_model_id,
      postEventPriceListId: gallery.post_shoot_company_pricing_model_id,
      reorderBuyerPriceListId: gallery.reorder_buyer_pricing_model_id,
      reorderNonBuyerPriceListId: gallery.reorder_non_buyer_pricing_model_id,
      priceListIdForOrder,
    };

    const selectedGalleryImages = [];
    const otherGalleryImages = [];

    dashboardData.students.forEach((student) => {
      student.galleries.forEach((tmpGallery) => {
        const isPriceListMatch =
          (tmpGallery.company_pricing_model_id === priceListIdForOrder ||
            tmpGallery.post_shoot_company_pricing_model_id ===
              priceListIdForOrder ||
            tmpGallery.reorder_non_buyer_pricing_model_id ===
              priceListIdForOrder ||
            tmpGallery.reorder_buyer_pricing_model_id ===
              priceListIdForOrder) &&
          priceListIdForOrder != null; // Ensure priceListIdForOrder is not null

        if (isPriceListMatch) {
          // add the gallery that initiated the order to the selectedGalleryImages array
          if (tmpGallery.id === selectedGalleryId) {
            selectedGalleryImages.push(...tmpGallery.images);
          } else {
            // only add other galleries if showPriceListMatchedPoses is true
            if (showPriceListMatchedPoses) {
              otherGalleryImages.push(...tmpGallery.images);
            }
          }
        }
      });
    });

    const allAvailablePosesForOrder = [
      ...selectedGalleryImages,
      ...otherGalleryImages,
    ];

    console.log('galleryThatInitiatedOrder');
    console.log(galleryThatInitiatedOrder);

    const data = {
      student_id: student.id,
      shoot_key: gallery?.password || gallery?.key,
      grade_code: 'IDK',
    };

    setDataIsLoading(true);

    dispatch(setBnlPreShoot(false));

    dispatch(getShootData(data)).then((resp) => {
      if (!resp.payload.data.errors) {
        const shootData = resp.payload.data;
        const eventUIVersion = resp.payload.data.shoot?.ui_version;

        // TODO: determine why we need this formatting logic... can we just
        // return the backgrounds and incentive products formatted as needed?
        formatBackgroundOptions(
          shootData.contains_background_categories,
          shootData.background_options,
        );
        formatIncentiveProducts(shootData.incentive_products);

        // TODO: can we remove this? i.e. is it needed in v3?
        // setUnlockedGroups(student.id);

        dispatch(setRetouchingProduct(shootData.retouching_product));
        dispatch(setRetouchingProducts(shootData.retouching_products));
        dispatch(setPersonalizationProduct(shootData.personalization_product));
        dispatch(setInterstitialProducts(shootData.interstitial_products));
        dispatch(setPreCartOfferProducts(shootData.pre_cart_offer_products));
        dispatch(
          setTriggersPreCartOfferProducts(
            shootData.triggers_pre_cart_offer_products,
          ),
        );

        // TODO: do we need to set this for v3?
        // that.props.setCpmId(cpmId);

        // TODO: revisit around student yearbook photo selection
        // for (const galleryStudentId in that.props.gallery.galleries[cpmId]) {
        //   const galleryObj =
        //     that.props.gallery.galleries[cpmId][galleryStudentId];

        //   // if at least 1 student gallery requires yearbook photo selection
        //   // go to selection screen
        //   if (galleryObj.requiresYearbookPhotoSelection) {
        //     that.props.history.push('/yearbook-photo-selection');
        //     return;
        //   }
        // }

        dispatch(
          setAdditionalData({
            // availablePoses: studentGalleryObj.gallery,
            allAvailablePosesForOrder,
            availablePoses: gallery?.images || [],
            availableGroupImages: gallery?.associated_group_images || [],
            galleryThatInitiatedOrder,
            studentGallery: gallery?.images ? gallery?.images : [],
            studentGroupImages: gallery?.associated_group_images || [],
            eventUIVersion,
            selectedStudent: student,
            student,
            studentId: student.id,
            uiVersion: 3, // TODO: is this right?
          }),
        );

        setDataIsLoading(false);

        if (isEdit || (requiresYearbookSelection && !hasYearbookSelection)) {
          return history.push('/v3/yearbook-selection');
        }

        history.push('/v3/incentive-offers');
      }
    });
  };

  const renderBanner = (student) => {
    const gallery = student.galleries.find(
      (gallery) => gallery.requires_yearbook_selection,
    );
    return (
      <SelectPoseBanner>
        {`Important: please select a yearbook pose by ${moment(
          gallery.yearbook_selection_deadline,
        ).format('MMMM DD, YYYY')}`}
        <ViewGalleryButton onClick={() => goToOrder(student, gallery)}>
          View Gallery
        </ViewGalleryButton>
      </SelectPoseBanner>
    );
  };

  const handleImageClick = (student, gallery) => () => {
    if (
      gallery.requires_yearbook_selection &&
      !gallery.yearbook_selection_performed &&
      isBeforeDeadline(gallery.yearbook_selection_deadline)
    ) {
      goToOrder(student, gallery);
    }
  };

  const handleEditPoseClick = (student, gallery) => () => {
    goToOrder(student, gallery, true);
  };

  return (
    <>
      <div className={dataIsLoading ? 'loading' : 'hidden'}>
        <div className="spinner" />
      </div>
      {!dashboardData && isLoading && (
        <div>
          <p>loading...</p>
        </div>
      )}
      {dashboardData && !isLoading && (
        <div>
          {dashboardData.students.map((student) => {
            if (
              !Array.isArray(student.galleries) ||
              student.galleries.length === 0
            ) {
              return null;
            }

            const atLeastOneGalleryHasImages = student.galleries.some(
              (gallery) =>
                Array.isArray(gallery.images) && gallery.images.length > 0,
            );

            if (!atLeastOneGalleryHasImages) {
              return null;
            }

            return (
              <StudentCard key={student.id}>
                {shouldShowBanner(student) && renderBanner(student)}
                <StudentName>
                  {student.first_name} {student.last_name}
                </StudentName>
                {student.galleries.map((gallery) => {
                  if (
                    !Array.isArray(gallery.images) ||
                    gallery.images.length === 0
                  ) {
                    return null;
                  }

                  return (
                    <EventWrapper key={gallery.id}>
                      <div key={gallery.event_name}>
                        <strong>{gallery.event_name}</strong>
                        <div>{gallery.gallery_job_created_at_year}</div>
                        <GalleryOrderButton
                          className="order-from-gallery-button"
                          onClick={() => {
                            goToOrder(student, gallery);
                          }}
                        >
                          order from this gallery
                        </GalleryOrderButton>
                        {gallery.yearbook_selection_performed &&
                          isBeforeDeadline(
                            gallery.yearbook_selection_deadline,
                          ) && (
                            <EditPoseLink
                              onClick={handleEditPoseClick(student, gallery)}
                            >
                              Edit yearbook pose selection
                            </EditPoseLink>
                          )}
                        <GalleryWrapper>
                          {gallery.images.map((image) => {
                            // TODO: revisit this logic
                            let imageSrc = '';

                            if (
                              image.watermarked_preview_filename ===
                                undefined &&
                              image.url !== null &&
                              image.url !== undefined &&
                              image.url !== ''
                            ) {
                              imageSrc = image.url;
                            } else {
                              imageSrc =
                                image.watermarked_preview_filename !== null &&
                                image.watermarked_preview_filename !== undefined
                                  ? `${BASE_WATERMARKED_IMAGE_URL}${image.watermarked_preview_filename}`
                                  : `${BASE_IMAGE_URL}${image.web_preview_filename}`;
                            }

                            return (
                              <GalleryImageContainer>
                                <GalleryImage
                                  onContextMenu={(event) =>
                                    event.preventDefault()
                                  }
                                  key={image.web_preview_filename}
                                  onClick={handleImageClick(student, gallery)}
                                  src={imageSrc}
                                  alt="Gallery Image Preview"
                                />
                                {image.yearbook_selection && (
                                  <SelectedBanner>
                                    ✅ Selected Pose
                                  </SelectedBanner>
                                )}
                              </GalleryImageContainer>
                            );
                          })}
                          {gallery.associated_group_images.map((image) => {
                            const imageSrc =
                              image.watermarked_preview_filename !== null &&
                              image.watermarked_preview_filename !== undefined
                                ? `${BASE_WATERMARKED_IMAGE_URL}${image.watermarked_preview_filename}`
                                : `${BASE_IMAGE_URL}${image.web_preview_filename}`;
                            return (
                              <GalleryImageContainer>
                                <GroupGalleryImage
                                  onContextMenu={(event) =>
                                    event.preventDefault()
                                  }
                                  key={image.web_preview_filename}
                                  src={imageSrc}
                                  alt="Gallery Image Preview"
                                />
                                {image.yearbook_selection && (
                                  <SelectedBanner>
                                    ✅ Selected Pose
                                  </SelectedBanner>
                                )}
                              </GalleryImageContainer>
                            );
                          })}
                        </GalleryWrapper>
                      </div>
                    </EventWrapper>
                  );
                })}
              </StudentCard>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DashboardGalleries;
