import React from 'react';
import PropTypes from 'prop-types';
import {
  AssignStudentButton,
  Card,
  EventName,
  EventYear,
  GalleryImage,
  GalleryPassword,
  GalleryResultsWrapper,
  ResultCard,
  ResultsSection,
  SearchDetails,
  StudentAssignedMessage,
  StudentAssignmentWrapper,
  StudentName,
  Title,
} from '../../../../styles/StudentKeyLookupStyles';

const GalleryStudentAssignment = ({}) => {
  return <>Gallery/Student Assignment Component</>;
};

export default GalleryStudentAssignment;

GalleryStudentAssignment.propTypes = {};
