import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

// React.js components
import Dropdown from '../../utils/dropdown';

// constants files
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../../constants/companies';

class BnlOrderCart extends Component {
  constructor(props) {
    super(props);

    this.editQuantity = this.editQuantity.bind(this);
    this.renderPersonalization = this.renderPersonalization.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  shootIsPreShoot() {
    return this.props.bnlPreShoot.preShoot;
  }

  shootIsOutdoorsBackground() {
    return (
      this.props.shoot.shoot.background_choice_mode ===
      'outdoors_background_only'
    );
  }

  isNotSuperCd(cartItem) {
    return cartItem.poses_count !== 0;
  }

  productDoesNotRequirePoseOrBackground(cartItem) {
    return (
      cartItem.background_options_count === 0 && cartItem.poses_count === 0
    );
  }

  editQuantity(event, cartItem, orderState) {
    this.props.editQuantity(event, cartItem, orderState);
  }

  renderEditButton(cartItem, orderState) {
    let render = true;

    if (!cartItem.requires_configuration) {
      render = false;
    }

    if (render) {
      return (
        <div
          onClick={() =>
            this.props.editBackground(cartItem, orderState.studentId)
          }
          className="bnl-cart-item__bg-edit cursor-pointer"
        >
          Edit
        </div>
      );
    }
  }

  renderBackgroundOptionsSection(cartItem, orderState) {
    if (
      orderState.backgroundChoiceMode ===
        'advanced_greenscreen_backgrounds_only' ||
      this.props.shoot.shoot.background_choice_mode ===
        'advanced_greenscreen_backgrounds_only' ||
      (this.shootIsOutdoorsBackground() &&
        cartItem.image_type === 'portrait_image')
    ) {
      return (
        <ul className="bnl-cart-item__backgrounds row clearfix">
          {this.renderBackgroundOptions(cartItem, orderState)}
        </ul>
      );
    }

    return <div className="bnl-cart-item__backgrounds" />;
  }

  renderParentProductPose(childConfig, studentId) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    let previewToDisplay;
    let cartStudentPreviewImageUrl;

    if (
      this.props.cart.orders &&
      this.props.cart.orders[studentId] &&
      this.props.cart.orders[studentId].studentPreviewImageUrl
    ) {
      cartStudentPreviewImageUrl =
        this.props.cart.orders[studentId].studentPreviewImageUrl;
    }

    // TODO: revisit this logic
    // Handle Geskus Post-Event
    if (isGeskus && isPostEvent && studentPreviewImageUrl) {
      previewToDisplay = studentPreviewImageUrl;

      // prefer the cart student preview image url
      if (cartStudentPreviewImageUrl) {
        previewToDisplay = cartStudentPreviewImageUrl;
      }
    }

    if (previewToDisplay) {
      const poseStyle = {
        backgroundImage: `url("${previewToDisplay}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return <div className="bnl-cart-item__pose" style={poseStyle} />;
    }

    if (!this.shootIsPreShoot() && childConfig?.pose?.url) {
      const poseStyle = {
        backgroundImage: `url("${childConfig.pose.url}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return <div className="bnl-cart-item__pose" style={poseStyle} />;
    }
  }

  renderParentProductBackgroundOptions(cartItem, orderState) {
    const backgroundOptions =
      orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id];
    const that = this;
    const options = [];
    let { studentId } = orderState;
    let trackingStudentId;

    for (const slotIndex in backgroundOptions) {
      const childConfig = backgroundOptions[slotIndex];
      const { childItem } = childConfig;
      if (!this.shootIsPreShoot()) studentId = 'postShoot';

      if (!this.shootIsOutdoorsBackground()) {
        childConfig.backgroundCodes.forEach((code, i) => {
          const background = that.props.formattedBackgroundOptions[code];
          const backgroundPrice = background.price;
          const bgStyle = {
            background: `url("${background.image_thumbnail}")`,
            backgroundSize: 'cover',
          };

          options.push(
            <li
              className="bnl-cart-item__background-image-container col-xs-3 col-md-2 col-lg-2"
              key={`cart-parent-background-option-${childItem.id}-${i}`}
            >
              <div className="bnl-cart-item__background-image" style={bgStyle}>
                {this.renderParentProductPose(childConfig, studentId)}
                {this.renderBackgroundPrice(
                  childItem,
                  studentId,
                  backgroundPrice,
                )}
              </div>
            </li>,
          );
        });
      } else {
        options.push(
          <li
            className="bnl-cart-item__background-image-container col-xs-3 col-md-2 col-lg-2"
            key={`cart-parent-background-option-${childItem.id}-${slotIndex}`}
          >
            <div className="bnl-cart-item__background-image">
              {this.renderParentProductPose(childConfig)}
            </div>
          </li>,
        );
      }
    }

    return options;
  }

  renderPose(innerConfig, studentId) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    // look for student preview image url stored in the cart
    const cartStudentPreviewImageUrl =
      this.props.cart.orders[studentId].studentPreviewImageUrl;

    // TODO: revisit this logic
    // Handle Geskus Post-Event
    if (isGeskus && isPostEvent && studentPreviewImageUrl) {
      const className = 'bnl-cart-item__pose';

      let previewToDisplay = studentPreviewImageUrl;

      // prefer the cart student preview image url
      if (cartStudentPreviewImageUrl) {
        previewToDisplay = cartStudentPreviewImageUrl;
      }

      const poseStyle = {
        backgroundImage: `url("${previewToDisplay}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return <div className={className} style={poseStyle} />;
    }

    if (!this.shootIsPreShoot()) {
      let className = 'bnl-cart-item__pose';

      if (innerConfig?.pose?.image_type === 'group_photo') {
        className += ' bnl-cart-item__pose--group-photo';
      }

      let poseUrl = innerConfig?.pose?.url;

      const poseStyle = {
        backgroundImage: `url("${poseUrl}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return <div className={className} style={poseStyle} />;
    }

    return null;
  }

  renderBackgroundPrice(cartItem, studentId, backgroundPrice) {
    const { bnlBackgroundSelection } = this.props.cart;
    const { bnlFreeBackgrounds } = this.props.cart;

    Sentry.setContext('renderBackgroundPrice', {
      bnlBackgroundSelection,
      bnlFreeBackgrounds,
    });

    if (
      !this.props.shoot.shoot.all_backgrounds_free &&
      !bnlFreeBackgrounds[studentId] &&
      bnlBackgroundSelection[studentId][backgroundPrice].indexOf(
        cartItem.id,
      ) === 0
    ) {
      // const price = this.props.formattedBackgroundOptions[bgCode].price;

      if (backgroundPrice > 0) {
        return (
          <div className="bnl-cart-item__background-image__price">
            {this.props.displayPrice(backgroundPrice).split('.')[0]}
          </div>
        );
      }
    }
  }

  renderBackgroundOptions(cartItem, orderState) {
    const { studentId } = orderState;

    if (this.isNotSuperCd(cartItem) && cartItem.requires_image) {
      let backgroundOptions;

      switch (cartItem.product_type) {
        case 'parent_product':
          return this.renderParentProductBackgroundOptions(
            cartItem,
            orderState,
          );
        case 'regular_product':
        case 'package_product':
        case 'nth_free':
          backgroundOptions = orderState.bnlSelectedBackgroundOptions;
          break;
        default:
          backgroundOptions = orderState.bnlSelectedBackgroundOptions;
      }

      const list = [];
      let trackingStudentId;
      if (this.shootIsPreShoot()) {
        trackingStudentId = orderState.studentId;
      } else {
        trackingStudentId = 'postShoot';
      }

      const that = this;

      Sentry.setContext('renderBackgroundOptions', {
        bnlBackgroundSelection: this.props.cart.bnlBackgroundSelection,
        cartItem,
        orderState,
      });

      for (const index in backgroundOptions[cartItem.id]) {
        const innerConfig = backgroundOptions[cartItem.id][index];

        if (!this.shootIsOutdoorsBackground()) {
          const bgCode = innerConfig.backgroundCodes[0];
          if (bgCode) {
            const background = that.props.formattedBackgroundOptions[bgCode];

            let backgroundPrice = 0;

            // TODO: remove this hack for case where background is not available
            if (background) {
              backgroundPrice = background.price;
            }

            // TODO: remove this hack for case where background is not available
            let bgStyle = {
              backgroundSize: 'cover',
            };

            if (background) {
              bgStyle = {
                background: `url("${background.image_thumbnail}")`,
                backgroundSize: 'cover',
              };
            }

            // if (!this.shootIsPreShoot())
            //   studentId = innerConfig.pose.student.id;

            list.push(
              <li
                className="bnl-cart-item__background-image-container col-xs-3 col-md-2 col-lg-2"
                key={`cart-background-option-${cartItem.id}-${index}`}
              >
                <div
                  className="bnl-cart-item__background-image"
                  style={bgStyle}
                >
                  {this.renderPose(innerConfig, studentId)}
                  {this.renderBackgroundPrice(
                    cartItem,
                    trackingStudentId,
                    backgroundPrice,
                  )}
                </div>
              </li>,
            );
          } else {
            let containerClass = 'bnl-cart-item__background-image-container';
            let imageClass = 'bnl-cart-item__background-image';

            if (cartItem.image_type === 'group_image') {
              containerClass +=
                ' bnl-cart-item__background-image-container--group-photo';
              imageClass += ' bnl-cart-item__background-image--group--photo';
            }

            list.push(
              <li
                className={`${containerClass} col-xs-6 col-md-6 col-lg-6`}
                key={`cart-background-option-${cartItem.id}-${index}`}
              >
                <div className={imageClass}>
                  {this.renderPose(innerConfig, studentId)}
                </div>
              </li>,
            );
          }
        } else {
          list.push(
            <li
              className="bnl-cart-item__background-image-container col-xs-3 col-md-2 col-lg-2"
              key={`cart-background-option-${cartItem.id}-${index}`}
            >
              <div className="bnl-cart-item__background-image">
                {this.renderPose(innerConfig, studentId)}
              </div>
            </li>,
          );
        }
      }

      return list;
    }
  }

  renderAdditionalBackgroundOptions(cartItem, orderState) {
    if (cartItem.additional_product_background_images_count > 0) {
      const that = this;
      const options = orderState.additionalSelectedBackgroundOptions[
        cartItem.id
      ].codes.map((code, i) => {
        const background = that.props.formattedBackgroundOptions[code];
        const style = {
          background: `url("${background.image_thumbnail}")`,
          backgroundSize: 'cover',
        };

        return (
          <div
            className="cart-item-background-option clearfix"
            key={`cart-background-option-${code}-${i}`}
          >
            <div className="cart-item-background__image" style={style} />
          </div>
        );
      });

      return (
        <div className="cart-item__background">
          <div className="cart-item-background__header">
            Additional Background:
          </div>
          <ul className="cart-item-background__options clearfix">
            {[options]}
          </ul>
        </div>
      );
    }
  }

  renderIncentivePoseOverBackgroundOption(configuration, cartItem, studentId) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;

    let cartStudentPreviewImageUrl;

    if (studentId) {
      // look for student preview image url stored in the cart
      cartStudentPreviewImageUrl =
        this.props.cart.orders[studentId].studentPreviewImageUrl;
    }

    if (!this.shootIsPreShoot()) {
      const pose = configuration.poseIds[0];
      let poseUrl;

      // TODO: revisit this logic
      if (isGeskus && isPostEvent && studentPreviewImageUrl) {
        if (cartStudentPreviewImageUrl) {
          // prefer the cart student preview image url
          poseUrl = cartStudentPreviewImageUrl;
        } else {
          poseUrl = studentPreviewImageUrl;
        }
      } else {
        poseUrl = configuration.poseIds[0].url;
      }

      const poseStyle = {
        backgroundImage: `url("${poseUrl}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      return <div className="bnl-cart-item__pose" style={poseStyle} />;
    }
  }

  renderIncentiveBackgroundOptions(cartItem, orderState, studentId) {
    const backgroundOptions = [];
    const { cart } = this.props;
    let incentiveConfiguration;

    // when possible, use the incentive configuration from the cart
    const incentiveBackgroundFromCart = orderState.incentiveConfiguration;

    if (this.shootIsPreShoot()) {
      if (
        cart &&
        cart?.orders[studentId] &&
        cart.orders[studentId]?.incentiveConfiguration[cartItem.id]
      ) {
        incentiveConfiguration = cart.orders[studentId].incentiveConfiguration;
      } else {
        incentiveConfiguration = this.props.incentiveConfiguration;
      }
    } else if (
      incentiveBackgroundFromCart &&
      incentiveBackgroundFromCart[cartItem.id]
    ) {
      incentiveConfiguration = incentiveBackgroundFromCart;
    } else {
      incentiveConfiguration = this.props.bnlIncentive.configuration;
    }

    const configuration = incentiveConfiguration[cartItem.id];

    Sentry.setContext('renderIncentiveBackgroundOptions', {
      configuration,
    });

    if (
      configuration &&
      configuration.backgroundCodes.length > 0 &&
      this.isNotSuperCd(cartItem) &&
      !this.productDoesNotRequirePoseOrBackground(cartItem) // TODO: revisit
    ) {
      const code = configuration.backgroundCodes[0];

      const background = this.props.formattedBackgroundOptions[code];

      const bgStyle = {
        background: `url("${background.image_thumbnail}")`,
        backgroundSize: 'cover',
      };

      backgroundOptions.push(
        <li
          key={`incentive-background-${cartItem}-${code}`}
          className="bnl-cart-item__background-image-container col-xs-3 col-md-2 col-lg-2"
        >
          <div className="bnl-cart-item__background-image" style={bgStyle}>
            {this.renderIncentivePoseOverBackgroundOption(
              configuration,
              cartItem,
              studentId,
            )}
          </div>
        </li>,
      );
    }

    return backgroundOptions;
  }

  renderPersonalization(cartItem, orderState) {
    const list = [];

    const { cart } = this.props;
    const { studentId } = orderState;

    if (
      cartItem.personalization_status === 'personalization_required' ||
      cartItem.personalization_status === 'personalization_optional'
    ) {
      if (cart.personalization && cart.personalization[studentId]) {
        list.push(
          <li
            className="bnl-cart-item__personalization-value"
            key={`personalization-${cartItem.id}`}
          >
            {cart.personalization[studentId]}
          </li>,
        );
      } else {
        const configuration =
          orderState.bnlSelectedBackgroundOptions[cartItem.id];

        for (const poseIndex in configuration) {
          if (list.length === 0) {
            const innerConfig = configuration[poseIndex];
            if (innerConfig.personalizationValue) {
              list.push(
                <li
                  className="bnl-cart-item__personalization-value"
                  key={`personalization-${cartItem.id}`}
                >
                  "{innerConfig.personalizationValue}"
                </li>,
              );
            }
          }
        }
      }

      if (list.length > 0) {
        return (
          <div className="bnl-cart-item__personalization">
            <div>Personalization:</div>
            <ul>{list}</ul>
          </div>
        );
      }
    }

    return null;
  }

  getDiscountPrice(discount) {
    if (discount === 0) {
      return this.props.displayPrice(discount);
    }
    return this.props.displayNegativePrice(discount);
  }

  renderFamilyDiscount(discountPrice) {
    if (discountPrice !== 0) {
      return (
        <div className="bnl-cart-item__prices__discount-price">
          Family Discount Applied: {this.getDiscountPrice(discountPrice)}
        </div>
      );
    }
    return null;
  }

  renderPrices(cartItem, orderState) {
    const itemPrice = cartItem.totalPrice;
    const backgroundPrice = this.props.calculateCartItemBackgroundPrice(
      cartItem,
      orderState,
    );

    const discountPrice = this.props.calculateCartItemDiscount(
      cartItem,
      orderState,
    );

    if (cartItem.product_type && cartItem.product_type === 'nth_free') {
      return <div className="bnl-cart-item__price">FREE</div>;
    }

    return (
      <div className="bnl-cart-item__prices">
        <div className="bnl-cart-item__prices__item-price">
          Item: {this.props.displayPrice(itemPrice)}
        </div>
        <div className="bnl-cart-item__prices__background-price">
          Backgrounds: {this.props.displayPrice(backgroundPrice)}
        </div>
        {this.renderFamilyDiscount(discountPrice)}
        <div className="bnl-cart-item__prices__total-price">
          Total:{' '}
          {this.props.displayPrice(itemPrice + backgroundPrice + discountPrice)}
        </div>
      </div>
    );
  }

  renderQuantity(cartItem, orderState) {
    const { shoot } = this.props;

    if (cartItem.product_type !== 'parent_product' && !cartItem.is_retouching) {
      const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

      if (cartItem.product_type && cartItem.product_type === 'nth_free') {
        return null;
      }

      if (
        cartItem.is_personalization ||
        (shoot.personalizationProduct &&
          shoot.personalizationProduct.id === cartItem.id)
      ) {
        return null;
      }

      return (
        <div className="bnl-cart-item__quantity clearfix">
          <div className="bnl-cart-item__quantity-header">Qty</div>
          <Dropdown
            className="bnl-cart-item__quantity-dropdown"
            handleOnChange={(event) =>
              this.editQuantity(event, cartItem, orderState)
            }
            name="quantity"
            options={options}
            value={cartItem.count}
          />
        </div>
      );
    }

    return null;
  }

  renderItemsList(cartItem, orderState) {
    const list = [];
    const backgrounds = this.props.shoot.formattedBackgroundOptions;

    Sentry.setContext('renderItemsList', {
      cart: this.props.cart,
      shoot: this.props.shoot,
    });

    if (cartItem.product_type === 'parent_product') {
      const configuration =
        orderState.buildYourOwnSelectedBackgroundOptions[cartItem.id];

      for (const slotIndex in configuration) {
        let displayingBgCode;
        const childItemConfig = configuration[slotIndex];
        const bgCode = childItemConfig.backgroundCodes[0];

        if (bgCode) {
          displayingBgCode = `(BG: ${backgrounds[bgCode].background_code})`;
        }

        list.push(
          <li key={`byo-child-item-${slotIndex}`}>
            {childItemConfig.childItem.name} {displayingBgCode}
          </li>,
        );
      }
    } else if (cartItem.contents && cartItem.contents.length > 0) {
      const config = orderState.bnlSelectedBackgroundOptions[cartItem.id];
      cartItem.contents.forEach((content, i) => {
        const displayingBgCodes = [];
        let displayingBgCodeText = '';

        for (const index in config) {
          const bgCode = config[index].backgroundCodes[0];
          if (bgCode && backgrounds[bgCode]) {
            displayingBgCodes.push(backgrounds[bgCode].background_code);
          }
        }

        if (displayingBgCodes.length > 0) {
          displayingBgCodeText = `(BG: ${displayingBgCodes.join(', ')})`;
        }

        list.push(
          <li key={`child-item-${cartItem.id}-${i}`}>
            {content} {displayingBgCodeText}
          </li>,
        );
      });
    } else {
      const config = orderState.bnlSelectedBackgroundOptions[cartItem.id];
      const displayingBgCodes = [];
      let displayingBgCodeText = '';

      // TODO: need to handle case where there are multiple backgrounds via multiple price lists in play
      for (const index in config) {
        const bgCode = config[index].backgroundCodes[0];
        if (bgCode && backgrounds[bgCode]) {
          displayingBgCodes.push(backgrounds[bgCode].background_code);
        }
      }

      if (displayingBgCodes.length > 0) {
        displayingBgCodeText = `BG: ${displayingBgCodes.join(', ')}`;
      }

      list.push(
        <li key={`child-item-${cartItem.id}`}>{displayingBgCodeText}</li>,
      );
    }

    return list;
  }

  renderRemoveButton(cartItem, orderState) {
    let render = true;

    // retouching was added via a product that includes retouching
    if (
      cartItem.is_retouching &&
      this.props.cart.bnlRetouching[orderState.studentId]
    ) {
      render = false;
    }

    if (render) {
      return (
        <div
          onClick={() =>
            this.props.removeCartItem(orderState.studentId, cartItem)
          }
          className="bnl-cart-item__remove cursor-pointer"
        >
          Remove
        </div>
      );
    }
  }

  renderIncludedItem(cartItem, includedItem, orderState) {
    const backgroundOptions = orderState.bnlIncludedProductsBackgroundOptions;

    // TODO FALL 2018
    // revisit
    const backgrounds = [];
    const config = backgroundOptions[cartItem.id];

    if (config) {
      const innerConfig = config[0];

      innerConfig.backgroundCodes.forEach((bgCode) => {
        const background = this.props.formattedBackgroundOptions[bgCode];
        const bgStyle = {
          background: `url("${background.image_thumbnail}")`,
          backgroundSize: 'cover',
        };
        backgrounds.push(
          <li
            className="bnl-cart-item__background-image-container col-xs-3 col-md-2 col-lg-2"
            key={`cart-background-option-${includedItem.id}`}
          >
            <div className="bnl-cart-item__background-image" style={bgStyle} />
          </li>,
        );
      });
    }

    return (
      <div>
        <hr />
        <div>Included Items - Free</div>
        <br />
        <div className="bnl-cart-item__container-one col-xs-12 col-md-4 col-lg-4 clearfix">
          <div className="clearfix">
            <div className="bnl-cart-item__name">{includedItem.name}</div>
          </div>
          <div className="bnl-cart-item__image-container">
            <img
              className="bnl-cart-item__image"
              src={includedItem.image_thumbnail}
            />
          </div>
        </div>
        <ul className="bnl-cart-item__items-list col-xs-12 col-md-3 col-lg-3" />
        <div className="col-xs-12 col-md-5 col-lg-5">
          <ul className="row">{backgrounds}</ul>
          <div className="bnl-cart-item__prices">
            <div className="bnl-cart-item__prices__item-price">Item: $0.00</div>
            <div className="bnl-cart-item__prices__background-price">
              Backgrounds: $0.00
            </div>
            <div className="bnl-cart-item__prices__total-price">
              Total: $0.00
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderIncludedItems(cartItem, orderState) {
    // TODO FALL 2018
    // reivist
    // logic works on notion that there's only 1 included item
    if (cartItem.included_products && cartItem.included_products.length > 0) {
      const includedItem = cartItem.included_products[0];

      return this.renderIncludedItem(cartItem, includedItem, orderState);
    }
  }

  renderCartItem(cartItem, orderState) {
    const priceContainer = 'bnl-cart-item__btn-price-container';

    return (
      <li
        className="bnl-cart-item row equal-height"
        key={`cart-item-${orderState.studentId}-${cartItem.id}`}
      >
        <div className="bnl-cart-item__container-one col-xs-12 col-md-4 col-lg-4 clearfix">
          <div className="clearfix">
            <div className="bnl-cart-item__name">{cartItem.name}</div>
            <div
              className={`${priceContainer} ${priceContainer}--mobile clearfix`}
            >
              {this.renderEditButton(cartItem, orderState)}
              {this.renderRemoveButton(cartItem, orderState)}
            </div>
          </div>
          <div className="bnl-cart-item__image-container">
            <img
              className="bnl-cart-item__image"
              src={cartItem.image_thumbnail}
            />
          </div>
          {this.renderQuantity(cartItem, orderState)}
        </div>
        <ul className="bnl-cart-item__items-list col-xs-12 col-md-3 col-lg-3">
          {this.renderItemsList(cartItem, orderState)}
        </ul>
        <div className="col-xs-12 col-md-5 col-lg-5">
          <div
            className={`${priceContainer} ${priceContainer}--desktop clearfix`}
          >
            {this.renderRemoveButton(cartItem, orderState)}
            {this.renderEditButton(cartItem, orderState)}
          </div>
          <div>{this.renderBackgroundOptionsSection(cartItem, orderState)}</div>
          {this.renderPersonalization(cartItem, orderState)}
          {this.renderPrices(cartItem, orderState)}
        </div>
        {this.renderIncludedItems(cartItem, orderState)}
      </li>
    );
  }

  renderCartItems(orderState) {
    let includesRetouching = false;
    const cartItems = orderState.cartItems.map((cartItem, i) => {
      if (cartItem.includes_retouching) includesRetouching = true;
      return this.renderCartItem(cartItem, orderState);
    });

    if (includesRetouching) {
      const retouchingProduct = this.props.cloneObject(
        this.props.shoot.retouchingProduct,
      );
      retouchingProduct.name = 'Retouching - Included';
      retouchingProduct.price = 0;
      retouchingProduct.totalPrice = 0;
      cartItems.push(this.renderCartItem(retouchingProduct, orderState));
    }

    return cartItems;
  }

  renderIncentiveEditButton(incentiveCartItem) {
    if (
      this.isNotSuperCd(incentiveCartItem) &&
      !this.productDoesNotRequirePoseOrBackground(incentiveCartItem) &&
      incentiveCartItem.requires_configuration
    ) {
      return (
        <div
          onClick={() =>
            this.props.editBnlIncentiveBackground(incentiveCartItem)
          }
          className="bnl-cart-item__bg-edit cursor-pointer"
        >
          Edit
        </div>
      );
    }
  }

  renderIncentiveBgCodes(incentiveCartItem, orderState = null) {
    const {
      additionalData: { isGeskus, isPostEvent, studentPreviewImageUrl },
    } = this.props;
    const list = [];

    if (incentiveCartItem.requires_configuration) {
      const backgrounds = this.props.shoot.formattedBackgroundOptions;
      const displayingBgCodes = [];
      let displayingBgCodeText = '';
      let incentiveConfiguration;

      if (this.shootIsPreShoot()) {
        if (orderState) {
          incentiveConfiguration = orderState.incentiveConfiguration;
        } else {
          incentiveConfiguration = this.props.incentiveConfiguration;
        }
      } else {
        incentiveConfiguration = this.props.bnlIncentive.configuration;

        // TODO: revisit this logic
        // Handle Geskus Post-Event
        if (isGeskus && isPostEvent && studentPreviewImageUrl) {
          if (orderState) {
            incentiveConfiguration = orderState.incentiveConfiguration;
          } else {
            incentiveConfiguration = this.props.incentiveConfiguration;
          }
        } else {
          incentiveConfiguration = orderState.incentiveConfiguration;
        }
      }

      const configuration = incentiveConfiguration[incentiveCartItem.id];

      const bgCode = configuration.backgroundCodes[0];
      if (bgCode) displayingBgCodes.push(backgrounds[bgCode].background_code);

      if (displayingBgCodes.length > 0) {
        displayingBgCodeText = `BG: ${displayingBgCodes.join(', ')}`;
      }

      list.push(
        <li key={`child-item-${incentiveCartItem.id}`}>
          {displayingBgCodeText}
        </li>,
      );
    }

    return list;
  }

  renderIncentivePersonalization(incentiveItem, orderState) {
    let configuration;

    if (this.props.bnlPreShoot.preShoot) {
      configuration = orderState.incentiveConfiguration;
    } else {
      configuration = this.props.bnlIncentive.configuration;
    }

    let personalizationValue;

    if (configuration && configuration[incentiveItem.id]) {
      personalizationValue =
        configuration[incentiveItem.id].personalizationValue;
    }

    if (personalizationValue) {
      return (
        <div className="bnl-cart-item__personalization">
          <div>Personalization:</div>
          <div className="bnl-cart-item__personalization-value">
            "{personalizationValue}"
          </div>
        </div>
      );
    }

    return null;
  }

  renderIncentiveCartItems(incentiveItems, orderState = null) {
    let cartItems;

    const { studentId } = orderState;

    const containerClass = 'bnl-cart-item__btn-price-container';
    if (incentiveItems.length > 0) {
      cartItems = incentiveItems.map((incentiveCartItem, i) => {
        if (
          incentiveCartItem.studentId &&
          incentiveCartItem.studentId !== studentId
        ) {
          return null;
        }

        return (
          <li key={`incentive-item-${i}`} className="bnl-cart-item row">
            <div className="zero-pdng col-xs-12 col-md-4 col-lg-4">
              <div className="bnl-cart-item__name">
                {incentiveCartItem.name}
              </div>
              <div
                className={`${containerClass} ${containerClass}--mobile clearfix`}
              >
                {this.renderIncentiveEditButton(incentiveCartItem, orderState)}
                <div className="bnl-cart-item__price">FREE</div>
              </div>
              <div className="bnl-cart-item__image-container">
                <img
                  className="bnl-cart-item__image"
                  src={incentiveCartItem.image_thumbnail}
                />
              </div>
            </div>
            <div className="bnl-cart-item__items-list col-xs-12 col-md-3 col-lg-3">
              Bonus Item
              {this.renderIncentiveBgCodes(incentiveCartItem, orderState)}
            </div>
            <div className="col-xs-12 col-md-5 col-lg-5">
              <div
                className={`${containerClass} ${containerClass}--desktop clearfix`}
              >
                <div className="bnl-cart-item__price">FREE</div>
                {this.renderIncentiveEditButton(incentiveCartItem, orderState)}
              </div>
              <div>
                <ul className="bnl-cart-item_backgrounds row">
                  {this.renderIncentiveBackgroundOptions(
                    incentiveCartItem,
                    orderState,
                    studentId,
                  )}
                </ul>
                {this.renderIncentivePersonalization(
                  incentiveCartItem,
                  orderState,
                )}
              </div>
            </div>
          </li>
        );
      });

      return cartItems;
    }
  }

  renderPreShootCartContent() {
    const cartContents = [];
    const orderContent = [];

    for (const studentId in this.props.cart.orders) {
      const orderState = this.props.cart.orders[studentId];

      orderContent.push(
        <li
          className="bnl-order-content__cart-items__student-container"
          key={`bnl-order-content__cart-items__${studentId}`}
        >
          <div className="bnl-order-content__cart-items__student__name">
            {orderState.studentName} -{orderState.shootName}
          </div>
          <ul className="bnl-order-content__cart-items__student__items">
            {this.renderCartItems(orderState)}
            {this.renderIncentiveCartItems(
              orderState.incentiveCartItems,
              orderState,
            )}
          </ul>
          <ul className="bnl-order-content__cart-items__student__items" />
        </li>,
      );
    }

    const cartItems = (
      <div className="bnl-cart-content">
        <div className="bnl-cart-content__header">Cart Items</div>
        <ul className="bnl-order-content__cart-items">{orderContent}</ul>
      </div>
    );

    cartContents.push(cartItems);
    return cartContents;
  }

  renderPostShootCartContent() {
    const cartContents = [];
    const orderContent = [];

    for (const studentId in this.props.cart.orders) {
      const orderState = this.props.cart.orders[studentId];

      let eventName = orderState.shootName;

      if (orderState.eventName) {
        eventName = orderState.eventName;
      }

      orderContent.push(
        <li
          className="bnl-order-content__cart-items__student-container"
          key={`bnl-order-content__cart-items__${studentId}`}
        >
          <div className="bnl-order-content__cart-items__student__name">
            {orderState.studentName} - {eventName}
          </div>
          <ul className="bnl-order-content__cart-items__student__items">
            {this.renderCartItems(orderState)}
            {this.renderIncentiveCartItems(
              orderState.incentiveCartItems,
              orderState,
            )}
          </ul>
          <ul className="bnl-order-content__cart-items__student__items" />
        </li>,
      );
    }

    const cartItems = (
      <div className="bnl-cart-content">
        <div className="bnl-cart-content__header">Cart Items</div>
        <ul className="bnl-order-content__cart-items">{orderContent}</ul>
      </div>
    );

    cartContents.push(cartItems);
    return cartContents;

    // TODO: remove this when we have a real cart

    // const cartContents = [];
    // const orderContent = [];
    // const incentiveItems = this.props.bnlIncentive.cartLevelItems;

    // for (const studentId in this.props.cart.orders) {
    //   const orderState = this.props.cart.orders[studentId];
    //   orderContent.push(this.renderCartItems(orderState));
    // }

    // const cartItems = (
    //   <div className="bnl-cart-content" key="1">
    //     <div className="bnl-cart-content__header">Cart Items</div>
    //     <ul className="bnl-order-content__cart-items">{orderContent}</ul>
    //   </div>
    // );

    // cartContents.push(cartItems);

    // const incentiveCartItems = (
    //   <div key="2">
    //     <div className="bnl-cart-content__header">Bonus Items</div>
    //     <ul>{this.renderIncentiveCartItems(incentiveItems)}</ul>
    //   </div>
    // );
    // cartContents.push(incentiveCartItems);

    // return cartContents;
  }

  renderCartContent() {
    if (this.shootIsPreShoot()) {
      return this.renderPreShootCartContent();
    }

    return this.renderPostShootCartContent();
  }

  renderDiscount() {
    if (this.props.discountTotal !== 0) {
      return (
        <div className="clearfix">
          <div className="order-summary__text">Discount:</div>
          <div className="order-summary__price">
            {this.props.displayNegativePrice(this.props.discountTotal)}
          </div>
        </div>
      );
    }
  }

  renderTax() {
    if (this.props.taxTotal > 0) {
      return (
        <div className="order-cart-summary__subtotal clearfix">
          <div className="order-summary__text">Estimated Tax:</div>
          <div className="order-summary__price">
            {this.props.displayPrice(this.props.taxTotal)}
          </div>
        </div>
      );
    }
  }

  renderEnterNewShootKeyBox() {
    if (this.shootIsPreShoot()) {
      return (
        <div className="bnl-cart__side-box border--round">
          <div className="bnl-cart__new-shoot-key__header">
            Add Another Order
          </div>
          <div className="bnl-cart__new-shoot-key__subheader">
            Ordering for more than 1 student? Enter their unique key to create
            another order before checking out.
          </div>
          <div className="button--bnl" onClick={this.props.goToDashboard}>
            Add Order
          </div>
        </div>
      );
    }
  }

  renderShipping() {
    // TODO: adjust to account for per-event shipping settings
    // TODO: remove Wagner-specific logic
    const companyShippingOptions =
      this.props.shoot.shoot.company_shipping_options;

    if (companyShippingOptions !== 'ship_to_school_only') {
      if (
        this.props.shoot.shoot.company_auth_token === 'wagner' &&
        this.props.shoot.shoot.pricing_model.shipping_price === 0
      ) {
        return null;
      }
      return (
        <div className="order-cart-summary__subtotal clearfix">
          <div className="order-summary__text">Shipping</div>
          <div className="order-summary__price">
            {this.props.displayShippingPrice()}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const style = {};
    style.marginTop = this.shootIsPreShoot() ? '60px' : '34px';

    return (
      <div className="bnl-order-cart">
        <h4 className="bnl-order-cart__header">Review My Cart</h4>
        <div className="bnl-order-cart-container row">
          <ul className="bnl-order-cart__content col-xs-12 col-md-8 col-lg-8">
            {this.renderCartContent()}
          </ul>
          <div className="bnl-order-cart__right col-xs-12 col-md-4 col-lg-4 clearfix">
            <div className="bnl-order-cart__right-container" style={style}>
              <div className="order-cart__header">Order Summary</div>
              <div className="order-cart-summary__subtotal clearfix">
                <div className="order-summary__text">Subtotal:</div>
                <div className="order-summary__price">
                  {this.props.displayPrice(this.props.subTotal)}
                </div>
              </div>
              {this.renderDiscount()}
              {this.renderShipping()}
              {this.renderTax()}
              <div className="order-summary__total clearfix">
                <div className="order-summary__text">Total:</div>
                <div className="order-summary__price">
                  {this.props.displayPrice(
                    this.props.subTotal +
                      this.props.discountTotal +
                      this.props.taxTotal +
                      this.props.calculateShipping(),
                  )}
                </div>
              </div>
              <div
                id="proceed-to-checkout"
                className="button--round-border bg--bnl-light-blue"
                onClick={this.props.renderNextComponent}
              >
                Proceed to Checkout
              </div>
            </div>
            {this.renderEnterNewShootKeyBox()}
            <div className="bnl-cart__side-box border--round">
              <div className="button--bnl" onClick={this.props.keepShopping}>
                Keep Shopping
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    bnlIncentive: state.bnlIncentive,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(BnlOrderCart);
