import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { loginParent, setAuthToken } from '../../actions/action_auth';
import { setHistory } from '../../actions/action_history';
import { getParentData } from '../../actions/action_parent';
import history from '../../utils/history';

const LoginError = styled.div`
  a {
    text-decoration: underline;
    color: #a94442;
    background: none;
    &:hover {
      background-color: #a94442;
      color: #f2dede;
      text-decoration: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: #337ab7;
  &:hover {
    background-color: #337ab7;
    color: #ffffff;
  }
`;

const NewPasswordLookupForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
    errors: {},
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');

    if (email) {
      setUserEmail(email);
    }

    if (props.auth.currentUserEmail) history.replace('/dashboard');
  }, [props.auth]);

  const handlePasswordChange = (event) => {
    setUserPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (userPassword === '') return;

    setLoading(true);

    const input = {
      email: userEmail,
      password: userPassword,
      authenticity_token: props.auth.authToken,
    };

    setState({ errors: {} });

    const response = await props.loginParent(input);
    const { data } = response.payload;

    if (data.errors) {
      setState({ errors: response.payload.data.errors });
      setLoading(false);
    } else if (data.currentUserId && !data.signupCompleted) {
      history.push('/new-account', { step: 2 });
      window.location.reload(true);
    } else if (data.currentUserId && data.signupCompleted) {
      props.getParentData();
      setIsLoggedIn(true);
      history.push('/dashboard');
      window.location.reload(true);
    } else {
      props.getParentData();
      history.push('/dashboard');
      window.location.reload(true);
    }
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const formErrors = state?.errors?.login ? (
    <LoginError className="alert alert-danger">
      <p>
        Error: the password you entered didn't match our records. Please try
        again or <a href="/password-reset">reset your password</a>.
      </p>
    </LoginError>
  ) : null;

  if (isLoggedIn) {
    return null;
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get('email');

  return (
    <Wrapper className="row row-no-gutters">
      <div className="col-lg-6 col-md-8 col-xs-12">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title text-center">Log In to Your Account</h3>
          </div>
          <div className="panel-body">
            <form onSubmit={handleLogin}>
              <div className="alert alert-info">
                <p>Great &mdash; it looks like you already have an account!</p>
                <p>
                  Please enter the password for:
                  <br />
                  {email}.
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="email">Password</label>
                <input
                  id="password"
                  className="form-control"
                  onChange={handlePasswordChange}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={userPassword}
                  placeholder="password"
                />
              </div>
              <div className="form-group">
                <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value=""
                    id="showPassword"
                    onClick={handleShowPasswordClick}
                  />
                  show password
                </label>
              </div>
              {formErrors}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <div style={{ marginTop: '15px' }}>
                <p>
                  <StyledLink href="/password-reset">
                    Forgot your password?
                  </StyledLink>
                </p>
                <p>
                  <StyledLink href="/account-lookup">
                    Use a different email
                  </StyledLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginParent,
      setAuthToken,
      setHistory,
      getParentData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPasswordLookupForm);
