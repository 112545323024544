import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { SpinnerSmall } from '../utils/spinner';
import { getCompanyTokenFromURL } from '../../utils/companyUtils';

// TODO: share these styles with the find_key component

const Wrapper = styled.div`
  margin-top: 115px;
`;

const Title = styled.h1`
  margin-bottom: 16px;
  padding-bottom: 0;
`;

const Box = styled.div`
  background: #ffffff;
  margin-bottom: 25px;
  box-shadow: 0 8px 16px rgb(0 0 0 / 15%) !important;
`;

const InputWrapper = styled.div`
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  border-radius: 5px;
  align-items: center;
`;

const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
`;

const Instructions = styled.h5`
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Fields = styled.div`
  display: grid;
  gap: 10px;
  max-width: 60%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'instructions instructions'
    'school school';
  margin: auto;
  padding-bottom: 25px;

  @media (max-width: 700px) {
    max-width: initial;
  }
`;

const VenuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  max-width: 60%;
  margin: auto;
  margin-top: 20px;

  @media (max-width: 700px) {
    max-width: initial;
  }

  &.no-results {
    font-style: italic;
    opacity: 0.5;
  }
`;

const Section = styled.div`
  box-shadow: 0 3px 5px rgb(0 0 0 / 10%) !important;
  padding: 25px;
`;

const ResultTitle = styled.div`
  font-size: 14px;
  ${'' /* color: rgba(0, 0, 0, 0.4); */}
  color: #000000;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ResultDescriptionExpired = styled.p`
  font-size: 14px;
  font-style: italic;
`;

const VenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  padding: 10px;
  cursor: pointer;

  :hover {
    background-color: #f9f9f9; /* replace with the subtle color of your choice */
  }

  .venue-name {
    color: #479de6;
    font-weight: bold;
  }

  &.active-false {
    cursor: not-allowed;
    .venue-name {
      color: #000000;
      opacity: 0.5;
    }
  }

  & span {
    opacity: 0.5;
    font-size: 13px;
    display: block;
    margin-top: 4px;
  }
`;

const BackLink = styled(Link)`
  display: inline-block;
  color: #479de6;
  margin-bottom: 12px;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const italicStyle = {
  fontStyle: 'italic',
};

const _Event = ({ event, history, isActive = true }) => {
  const onClick = (e) => {
    if (!isActive) return;
    history.push('/dashboard', { key: event.key });
  };

  let dateStrForDisplay = '';

  if (event.shoot_date) {
    const date = new Date(event.shoot_date);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    dateStrForDisplay = date.toLocaleDateString('en-US', options);
  }

  return (
    <VenueWrapper onClick={onClick} className={`active-${isActive}`}>
      <div className="venue-name">{event.name}</div>
      <span>pre-order password: {event.key}</span>
      <span>
        {event.date_range_display
          ? event.date_range_display
          : dateStrForDisplay}
      </span>
      <span>
        {event.venue.address_1} {event.venue.city}, {event.venue.state}{' '}
        {event.venue.zipcode}
      </span>
    </VenueWrapper>
  );
};

const Event = withRouter(_Event);

const TextInput = ({ style, loading, ...props }) => (
  <InputWrapper style={style}>
    <Input {...props} type="text" maxLength="5" />
    {loading && <SpinnerSmall />}
  </InputWrapper>
);

const Form = ({ runQuery, clearResults, setResultsLoading }) => {
  const [schoolName, setSchoolName] = useState('');
  const [loading, setLoading] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    if (schoolName.length < 5) {
      clearResults();
      setLoading(false);
      return;
    }

    clearTimeout(timeout?.current);
    timeout.current = setTimeout(async () => {
      setLoading(true);
      await runQuery(schoolName);
      setLoading(false);
    }, 300);
  }, [schoolName]);

  return (
    <Section>
      <Fields>
        <Instructions style={{ gridArea: 'instructions' }}>
          Enter the five-digit zip code of your school
        </Instructions>

        <TextInput
          loading={loading}
          value={schoolName}
          onChange={(e) => {
            setSchoolName(e.target.value), setResultsLoading(true);
          }}
          placeholder="zip code"
          type="text"
          style={{ gridArea: 'school' }}
        />
      </Fields>
    </Section>
  );
};

const Events = ({ events }) => {
  if (!events.length) return <div />;

  return (
    <Section style={{ paddingTop: 0 }}>
      <VenuesWrapper>
        <ResultTitle>Active Events</ResultTitle>
        <p>
          <em style={italicStyle}>
            Click on your school to pre-order for your upcoming picture day.
          </em>
        </p>
        {events.map((event) => (
          <Event event={event} />
        ))}
      </VenuesWrapper>
    </Section>
  );
};

// TODO: combine Events and ExpiredEvents into one component
const ExpiredEvents = ({ events }) => {
  if (!events.length) return <div />;

  return (
    <Section style={{ paddingTop: 0 }}>
      <VenuesWrapper>
        <ResultTitle>Expired Events</ResultTitle>
        <ResultDescriptionExpired>
          Picture day has passed for the following events. You will receive
          information on how to order your child's pictures approximately 2
          weeks after picture day.
        </ResultDescriptionExpired>
        {events.map((event) => (
          <Event event={event} isActive={false} />
        ))}
      </VenuesWrapper>
    </Section>
  );
};

const NoReults = ({ events, expiredEvents, loading }) => {
  console.log('loading');
  console.log(loading);
  if (!events.length && !expiredEvents.length && !loading) {
    return (
      <Section style={{ paddingTop: 0 }}>
        <VenuesWrapper className="no-results">
          <p>no results...</p>
        </VenuesWrapper>
      </Section>
    );
  }
  return null;
};

const VenueLookupByZipcode = () => {
  const [events, setEvents] = useState([]);
  const [expiredEvents, setExpiredEvents] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(true);

  const venueLookup = async (query) => {
    const companyToken = getCompanyTokenFromURL(window.location.href);

    const eventResults = await axios.get(
      `/api/v2/companies/${companyToken}/venues/zipcode_lookup?match=fuzzy`,
      {
        params: { zipcode: query },
      },
    );

    setResultsLoading(false);
    setEvents(eventResults.data.upcoming_events);
    setExpiredEvents(eventResults.data.expired_events);
  };

  const clearResults = () => {
    setEvents([]);
  };

  return (
    <Wrapper>
      <Title>Pre-Order Password Lookup</Title>
      <BackLink to="/dashboard">&larr; Back</BackLink>
      <Box>
        <Form
          runQuery={venueLookup}
          clearResults={clearResults}
          setResultsLoading={setResultsLoading}
        />
        <Events events={events} />
        <ExpiredEvents events={expiredEvents} />
        <NoReults
          events={events}
          expiredEvents={expiredEvents}
          loading={resultsLoading}
        />
      </Box>
    </Wrapper>
  );
};

export default VenueLookupByZipcode;
