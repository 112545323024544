export const RESET_ALL = 'RESET_ALL';
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const UPDATE_CART_LEVEL_ITEMS = 'UPDATE_CART_LEVEL_ITEMS';
export const UPDATE_BNL_FREE_SHIPPING = 'UPDATE_BNL_FREE_SHIPPING';

export function updateBnlIncentiveCartItems(cartItems) {
  return {
    type: UPDATE_CART_ITEMS,
    payload: cartItems,
  };
}

export function updateBnlIncentiveConfiguration(configuration) {
  console.log('ACTION: update BNL incentive');
  console.log(configuration);

  return {
    type: UPDATE_CONFIGURATION,
    payload: configuration,
  };
}

export function resetBnlIncentive() {
  return {
    type: RESET_ALL,
    payload: null,
  };
}

export function updateBnlIncentiveCartLevelItems(cartItems) {
  console.log('1_BNL UPDATE CART', cartItems);

  return {
    type: UPDATE_CART_LEVEL_ITEMS,
    payload: cartItems,
  };
}

export function updateBnlFreeShipping(boolean) {
  return {
    type: UPDATE_BNL_FREE_SHIPPING,
    payload: boolean,
  };
}
