import React from 'react';
import styled from 'styled-components';
import ChildItemBackgroundPose from './ChildItemBackgroundPose';

const ProductName = styled.p`
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 6px;
`;

const Header = styled.h6`
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 16px;
`;

const ProductWithChildElements = ({
  cartItem,
  shouldDisplayBackgroundPrice,
  singleChargeForBackgroundUpgrades,
}) => {
  let hasDisplayedBackgroundPrice = false; // Track if we've already displayed a background price

  return (
    <>
      <Header>components</Header>

      {cartItem.children.map((item, index) => {
        // Determine if we should display the background price for this child item
        let shouldShowPrice = shouldDisplayBackgroundPrice;

        if (
          shouldDisplayBackgroundPrice &&
          singleChargeForBackgroundUpgrades &&
          !hasDisplayedBackgroundPrice &&
          item.backgrounds.some((background) => background.price > 0)
        ) {
          shouldShowPrice = true;
          hasDisplayedBackgroundPrice = true; // Set flag to true after the first price is displayed
        } else if (singleChargeForBackgroundUpgrades) {
          shouldShowPrice = false; // Don't show the price for subsequent items
        }

        return (
          <div key={item.id}>
            <ProductName>{item.productName}</ProductName>
            <ChildItemBackgroundPose
              shouldDisplayBackgroundPrice={shouldShowPrice}
              item={item}
              isLastItem={index === cartItem.children.length - 1}
              singleChargeForBackgroundUpgrades={
                singleChargeForBackgroundUpgrades
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default ProductWithChildElements;
