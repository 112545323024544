import React, { Component } from 'react';
import { connect } from 'react-redux';

// React.js components
import BnlOrderProductMostPopular from './bnl_order_product_most_popular';
import BnlOrderProductRegular from './BnlOrderProductRegular';

// constants files
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../../constants/companies';

class BnlOrderProduct extends Component {
  constructor(props) {
    super(props);

    this.renderContents = this.renderContents.bind(this);
    this.renderContentsOrDesc = this.renderContentsOrDesc.bind(this);
  }

  getClassName() {
    const companyAuthToken = this.props.shoot.shoot.company_auth_token;

    switch (companyAuthToken) {
      case JELLYJAR:
        if (this.props.product.most_popular) {
          return 'bnl-product-most-popular col-xs-12 col-md-12 col-lg-12';
        }
        return 'bnl-product-regular col-xs-12 col-md-6 col-lg-6 cursor-pointer';
      case BNL:
      case GB:
      case NORMANDY:
      case TIPPING:
      case MARCEL:
      case CLASSACT:
      case WAGNER:
      case BIELMAR:
        if (this.props.product.display_type === 'display_full') {
          return 'bnl-product-most-popular col-xs-12 col-md-12 col-lg-12';
        }
        if (
          this.props.product.display_type === 'display_half' ||
          this.props.product.display_type === 'display_not_set'
        ) {
          return 'bnl-product-regular col-xs-12 col-md-6 col-lg-6 cursor-pointer';
        }
      default:
        if (this.props.product.display_type === 'display_full') {
          return 'bnl-product-most-popular col-xs-12 col-md-12 col-lg-12';
        }
        if (
          this.props.product.display_type === 'display_half' ||
          this.props.product.display_type === 'display_not_set'
        ) {
          return 'bnl-product-regular col-xs-12 col-md-6 col-lg-6 cursor-pointer';
        }
    }
  }

  getLockedClassName() {
    const className = '';

    return className;
  }

  renderLock() {
    const { product } = this.props;

    // console.log('rendering lock icon');
    // console.log(product);

    if (product.lock_type === 'unlockable') {
      let locked = true;
      const { bnlUnlockedGroups } = this.props.cart;

      product.lock_group.forEach((set) => {
        if (bnlUnlockedGroups.includes(set)) locked = false;
      });

      if (locked) {
        return (
          <img
            className="bnl-product__lock-image"
            alt="lock icon"
            src="https://s3.amazonaws.com/pics-assets/utils/product_lock.png"
          />
        );
      }
    }

    return null;
  }

  renderContentsOrDesc(product) {
    if (product.contents && product.contents.length > 0) {
      const contents = [];
      let lists = [];
      let midPoint;

      if (product.contents.length % 2 === 0) {
        midPoint = product.contents.length / 2 - 1;
      } else {
        midPoint = Math.floor(product.contents.length / 2);
      }

      for (let i = 0; i <= midPoint; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-left`}>
          {lists}
        </ul>,
      );
      lists = [];

      for (let i = midPoint + 1; i < product.contents.length; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-right`}>
          {lists}
        </ul>,
      );

      return contents;
    }
    if (product.description) {
      return (
        <div className="bnl-product__description">{product.description}</div>
      );
    }
  }

  renderContents(product) {
    if (product.contents && product.contents.length > 0) {
      const contents = [];
      let lists = [];
      let midPoint;

      if (product.contents.length % 2 === 0) {
        midPoint = product.contents.length / 2 - 1;
      } else {
        midPoint = Math.floor(product.contents.length / 2);
      }

      for (let i = 0; i <= midPoint; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-left`}>
          {lists}
        </ul>,
      );
      lists = [];

      for (let i = midPoint + 1; i < product.contents.length; i++) {
        const content = product.contents[i];
        lists.push(<li key={`${product.id}-${content}-${i}`}>{content}</li>);
      }

      contents.push(
        <ul className="col-xs-12 col-md-6 col-lg-6" key={`${product.id}-right`}>
          {lists}
        </ul>,
      );

      return contents;
    }
  }

  renderContent() {
    const companyAuthToken = this.props.shoot.shoot.company_auth_token;

    if (companyAuthToken === JELLYJAR) {
      switch (this.props.product.most_popular) {
        case true:
          return (
            <BnlOrderProductMostPopular
              displayPrice={this.props.displayPrice}
              product={this.props.product}
              renderContentsOrDesc={this.renderContentsOrDesc}
              selectShootProduct={this.props.selectShootProduct}
            />
          );
        case false:
          return (
            <BnlOrderProductRegular
              displayPrice={this.props.displayPrice}
              product={this.props.product}
              renderContents={this.renderContents}
              selectShootProduct={this.props.selectShootProduct}
            />
          );
      }
    } else if (
      companyAuthToken === BNL ||
      companyAuthToken === GB ||
      companyAuthToken === NORMANDY ||
      companyAuthToken === TIPPING ||
      companyAuthToken === MARCEL ||
      companyAuthToken === CLASSACT ||
      companyAuthToken === WAGNER ||
      companyAuthToken === BIELMAR
    ) {
      switch (this.props.product.display_type) {
        case 'display_full':
          return (
            <BnlOrderProductMostPopular
              displayPrice={this.props.displayPrice}
              product={this.props.product}
              renderContentsOrDesc={this.renderContentsOrDesc}
              selectShootProduct={this.props.selectShootProduct}
            />
          );
        case 'display_half':
        case 'display_not_set':
          return (
            <BnlOrderProductRegular
              displayPrice={this.props.displayPrice}
              product={this.props.product}
              renderContents={this.renderContents}
              selectShootProduct={this.props.selectShootProduct}
            />
          );
      }
    } else {
      switch (this.props.product.display_type) {
        case 'display_full':
          return (
            <BnlOrderProductMostPopular
              displayPrice={this.props.displayPrice}
              product={this.props.product}
              renderContentsOrDesc={this.renderContentsOrDesc}
              selectShootProduct={this.props.selectShootProduct}
            />
          );
        case 'display_half':
        case 'display_not_set':
          return (
            <BnlOrderProductRegular
              displayPrice={this.props.displayPrice}
              product={this.props.product}
              renderContents={this.renderContents}
              selectShootProduct={this.props.selectShootProduct}
            />
          );
      }
    }
  }

  render() {
    const className = this.getClassName();
    // const lockedClassName = this.getLockedClassName();

    return (
      <li
        className={`${className}`}
        key={this.props.name}
        onClick={() => this.props.selectShootProduct(this.props.product)}
      >
        {this.renderLock()}
        {this.renderContent()}
      </li>
    );
  }

  componentDidMount() {
    const elements = $('.bnl-product-regular');

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if ((i + 1) % 4 === 1 || (i + 1) % 4 === 0) {
        $(element).addClass('bnl-product-checker-white');
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    shoot: state.shoot,
  };
}

export default connect(mapStateToProps, null)(BnlOrderProduct);
