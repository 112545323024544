import React from 'react';
import { Puff } from 'react-loader-spinner';

const OverlayLoader = ({ loading }) => {
  if (!loading) return null;

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.15)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#0084ba"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default OverlayLoader;
