import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BackLink from '../shared/BackLink';
import { Header, PrimaryButton, Text, Wrapper } from '../v3Styles';
import Checkbox from './Checkbox';
import { Box, BoxWrapper, CheckboxWrapper } from './unsubStyles';

const UnsubscribeReasons = ({ history, location }) => {
  const [noLongerSchoolCheck, setNoLongerSchoolCheck] = useState(false);
  const [noLongerBuyCheck, setNoLongerBuyCheck] = useState(false);
  const [tooManyEmailsCheck, setTooManyEmailsCheck] = useState(false);
  const [doNotWantEmailsCheck, setDoNotWantEmailsCheck] = useState(false);
  const [receiveFromSchoolCheck, setReceiveFromSchoolCheck] = useState(false);
  const [otherCheck, setOtherCheck] = useState(false);
  const [otherReason, setOtherReason] = useState('');

  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const eventId = query.get('eventId');

  const handleSubmit = async () => {
    const reasons = [];
    if (noLongerSchoolCheck) {
      reasons.push('no_longer_attends_school');
    }
    if (noLongerBuyCheck) {
      reasons.push('no_longer_buy_portraits');
    }
    if (tooManyEmailsCheck) {
      reasons.push('too_many_emails');
    }
    if (doNotWantEmailsCheck) {
      reasons.push('do_not_want_emails');
    }
    if (receiveFromSchoolCheck) {
      reasons.push('receive_info_directly_from_school');
    }
    const body = {
      email,
      sourceEventId: eventId,
      all: true,
    };
    if (reasons.length > 0) {
      body.reasons = reasons;
    }
    if (otherCheck && otherReason) {
      body.otherReason = otherReason;
    }
    if (reasons.length === 0 && !otherCheck) {
      body.reasons = ['non_valid_reason'];
    }
    try {
      await axios.post('/api/v2/parents/unsubscribe-email', body);
      history.push('/unsubscribe-message');
    } catch (error) {
      console.error(error);
    }
  };

  const handleNoLongerSchoolCheck = (e) => {
    e.preventDefault();
    setNoLongerSchoolCheck(!noLongerSchoolCheck);
  };

  const handleNoLongerBuyCheck = (e) => {
    e.preventDefault();
    setNoLongerBuyCheck(!noLongerBuyCheck);
  };

  const handleTooManyEmailsCheck = (e) => {
    e.preventDefault();
    setTooManyEmailsCheck(!tooManyEmailsCheck);
  };

  const handleDoNotWantEmailsCheck = (e) => {
    e.preventDefault();
    setDoNotWantEmailsCheck(!doNotWantEmailsCheck);
  };

  const handleReceiveFromSchoolCheck = (e) => {
    e.preventDefault();
    setReceiveFromSchoolCheck(!receiveFromSchoolCheck);
  };

  const handleOtherCheck = (e) => {
    e.preventDefault();
    setOtherCheck(!otherCheck);
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.currentTarget.value);
  };

  return (
    <Wrapper>
      <BackLink />
      <Wrapper>
        <Header>
          Please check the reason you are unsubscribing from all emails below.
        </Header>
        <Text>
          We'd greatly appreciate your feedback on why you are unsubscribing so
          we can improve our service
        </Text>
        <Wrapper>
          <BoxWrapper>
            <Box>
              <CheckboxWrapper onClick={handleNoLongerSchoolCheck}>
                My child no longer attends this school
                <Checkbox checked={noLongerSchoolCheck} id="reason-1" />
              </CheckboxWrapper>
            </Box>
          </BoxWrapper>
          <BoxWrapper>
            <Box>
              <CheckboxWrapper onClick={handleNoLongerBuyCheck}>
                I no longer buy school portraits anymore
                <Checkbox checked={noLongerBuyCheck} id="reason-2" />
              </CheckboxWrapper>
            </Box>
          </BoxWrapper>
          <BoxWrapper>
            <Box>
              <CheckboxWrapper onClick={handleTooManyEmailsCheck}>
                I receive too many emails
                <Checkbox checked={tooManyEmailsCheck} id="reason-3" />
              </CheckboxWrapper>
            </Box>
          </BoxWrapper>
          <BoxWrapper>
            <Box>
              <CheckboxWrapper onClick={handleDoNotWantEmailsCheck}>
                I don't want to receive emails about this
                <Checkbox checked={doNotWantEmailsCheck} id="reason-4" />
              </CheckboxWrapper>
            </Box>
          </BoxWrapper>
          <BoxWrapper>
            <Box>
              <CheckboxWrapper onClick={handleReceiveFromSchoolCheck}>
                I receive this information from my child's school directly
                <Checkbox checked={receiveFromSchoolCheck} id="reason-5" />
              </CheckboxWrapper>
            </Box>
          </BoxWrapper>
          <BoxWrapper>
            <Box>
              <CheckboxWrapper onClick={handleOtherCheck}>
                Other
                <Checkbox checked={otherCheck} id="reason-6" />
              </CheckboxWrapper>
              <textarea
                onChange={handleOtherReasonChange}
                placeholder="Please write your reason here..."
                value={otherReason}
                rows={5}
              />
            </Box>
          </BoxWrapper>
          <PrimaryButton onClick={handleSubmit}>
            Submit & Unsubscribe
          </PrimaryButton>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

UnsubscribeReasons.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default UnsubscribeReasons;
