import { useSelector } from 'react-redux';
import cartUtils from '../utils/cart';

export const usePreCartOfferProductsToPrompt = () => {
  const { preCartOfferProducts, triggersPreCartOfferProducts } = useSelector(
    (state) => state.shoot,
  );
  const selectedStudentId = useSelector(
    (state) => state.additionalData.selectedStudent?.id,
  );
  const currentOrder = useSelector(
    (state) => state.v3Order.orders[selectedStudentId],
  );

  return cartUtils.preCartOfferProductsToPrompt(
    currentOrder,
    preCartOfferProducts,
    triggersPreCartOfferProducts,
  );
};
