import React, { Component } from 'react';

class IncentiveMobileConfiguration extends Component {
  render() {
    const product = this.props.getConfiguringItem();
    const infoClassName = this.props.mobileConfiguring ? 'hidden' : '';
    const stepClassName = this.props.mobileConfiguring ? '' : 'hidden';

    return (
      <div className="incentive-config--mobile row">
        <h3 className="incentive-config__header">
          Free {product.name} - {this.props.getInstruction()}
        </h3>
        {this.props.renderMobileBackButton()}
        <div className={`col-xs-12 ${infoClassName}`}>
          {this.props.renderMobileConfiguringItem()}
        </div>
        <div className={`col-xs-12 ${stepClassName}`}>
          {this.props.activeStep}
        </div>
      </div>
    );
  }
}

export default IncentiveMobileConfiguration;
