import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

const PersonalizationValue = styled.span`
  font-style: italic;
`;

const Instruction = styled.p`
  margin-bottom: 16px;
`;

const PersonalizationConfigurationV2 = (props) => {
  const {
    handleOnChange,
    personalizationAlreadyExists,
    personalizationFormValue,
    personalizationProduct,
    selectedShootProduct,
  } = props;

  const displayPrice = (price) => `$${(price / 100).toFixed(2)}`;

  const renderHeader = () => {
    if (personalizationAlreadyExists) {
      return 'Personalization';
    }

    return 'Add Personalization';
  };

  const renderInstruction = () => {
    if (personalizationAlreadyExists) {
      return (
        <>
          <Instruction>
            Personalization has been added to your order.
          </Instruction>
        </>
      );
    }

    return (
      <>
        <Instruction>
          Personalize your product by entering the student&apos;s first name
          using upper and lower case letters (max 20 characters).
        </Instruction>
        <Instruction>
          For example: <em>John</em>
        </Instruction>
        <Instruction>
          Please do NOT use all caps or add year (year will be added
          automatically).
        </Instruction>
        <Instruction>
          Personalization will be applied to all products in your student&apos;s
          order that allow for personalization.
        </Instruction>
        <Instruction>
          Cost: {displayPrice(personalizationProduct.price)}
        </Instruction>
        <Instruction>
          To skip, simply leave the personalization field blank.
        </Instruction>
      </>
    );
  };

  const renderValueOrForm = () => {
    if (personalizationAlreadyExists) {
      return (
        <>
          <Instruction>
            Personalization value:{' '}
            <PersonalizationValue>
              {personalizationFormValue}
            </PersonalizationValue>
          </Instruction>
        </>
      );
    }

    return (
      <input
        className="bnl-personalization__input"
        onChange={handleOnChange}
        placeholder="Student First Name"
        value={personalizationFormValue || ''}
        maxLength={20}
        name="personalizationProductValue"
      />
    );
  };

  if (!personalizationProduct) {
    Sentry.setContext('selectedShootProduct', {
      id: selectedShootProduct.id,
      name: selectedShootProduct.name,
    });

    Sentry.captureMessage('personalization V2 form incorrectly presented');

    return null;
  }

  return (
    <div className="bnl-product-config__personalization">
      <h4 className="bnl-personalization-header">{renderHeader()}</h4>
      {renderInstruction()}
      <div className="clearfix">{renderValueOrForm()}</div>
    </div>
  );
};

export default PersonalizationConfigurationV2;

PersonalizationConfigurationV2.propTypes = {
  personalizationFormValue: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  personalizationAlreadyExists: PropTypes.bool.isRequired,
  personalizationProduct: PropTypes.shape({
    price: PropTypes.number,
  }).isRequired,
  selectedShootProduct: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};
