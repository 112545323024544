import React, { Component } from 'react';

// constants files
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../constants/companies';

class IncentiveTracker extends Component {
  renderSections() {
    const that = this;
    const widthStyle = {
      width: `${100 / this.props.incentiveProducts.length}%`,
    };

    const sections = this.props.incentiveProducts.map((product, i) => {
      let desktopText;
      let mobileTextTop;
      let mobileTextCenter;
      let mobileTextBottom;
      let diff;

      if (product.incentive_applies_to === 'cart_level') {
        diff = product.incentive_threshold - that.props.calculateTotal();
      } else if (product.incentive_applies_to === 'order_level') {
        diff = product.incentive_threshold - that.props.calculateOrderTotal();
      }

      let className = 'incentive-tracker__section--unfilled';
      className += ` incentive-tracker__section--unfilled-${i + 1}`;
      const price = that.props.displayPrice(diff).split('.')[0];

      if (this.props.companyAuthToken === GESKUS) {
        mobileTextCenter = `${product.name}`;
        if (diff <= 0) {
          desktopText = `Earned: ${product.name}!`;
          mobileTextTop = 'Earned:';
          mobileTextBottom += '!';
        } else {
          desktopText = `Spend ${price} more: ${product.name}`;
          mobileTextTop = `Spend ${price} more:`;
        }
      } else {
        switch (product.name) {
          case 'Magnets (Set of 4)':
            mobileTextCenter = 'Free Magnets';
            mobileTextBottom = '(Set of 4)';

            if (diff <= 0) {
              // qualified
              desktopText = `Earned: Free ${product.name}!`;
              mobileTextTop = 'Earned:';
              mobileTextBottom += '!';
            } else {
              // unqualified
              desktopText = `Spend ${price} more: Free ${product.name}`;
              mobileTextTop = `Spend ${price} more:`;
            }
            break;
          case 'Text My Image + Shipping':
            mobileTextCenter = 'Free Text My Image';
            mobileTextBottom = '+ Shipping';
            if (diff <= 0) {
              desktopText = `Earned: Free ${product.name}!`;
              mobileTextTop = 'Earned:';
              mobileTextBottom += '!';
            } else {
              desktopText = `Spend ${price} more: Free ${product.name}`;
              mobileTextTop = `Spend ${price} more:`;
            }
            break;
          case 'Super CD + Cloud Download':
            mobileTextCenter = 'Free Super CD';
            mobileTextBottom = '+ Cloud Download';
            if (diff <= 0) {
              desktopText = `Earned: Free ${product.name}!`;
              mobileTextTop = 'Earned:';
              mobileTextBottom += '!';
            } else {
              desktopText = `Spend ${price} more: Free ${product.name}`;
              mobileTextTop = `Spend ${price} more:`;
            }
            break;
          default:
            if (diff <= 0) {
              desktopText = `Earned: ${product.name}!`;
              mobileTextTop = 'Earned:';
              mobileTextCenter = `${product.name}`;
              mobileTextBottom = '!';
            } else {
              desktopText = `Spend ${price} more: ${product.name}`;
              mobileTextTop = `Spend ${price} more:`;
              mobileTextCenter = `${product.name}`;
            }
        }
      }

      if (diff <= 0) {
        className = 'incentive-tracker__section--filled';
        className += ` incentive-tracker__section--filled-${i + 1}`;
      }

      const textClassName = 'incentive-tracker__section-text';
      const textCompanyClassName = '';
      const countclassName = `incentive-tracker__section--${this.props.incentiveProducts.length}-total`;

      return (
        <li
          className={`incentive-tracker__section ${className}`}
          key={`incentive-tracker-section-${i}`}
          style={widthStyle}
        >
          <div
            className={`${textClassName} ${textClassName}--desktop ${textCompanyClassName} ${countclassName}`}
          >
            {desktopText}
          </div>
          <div
            className={`${textClassName} ${textClassName}--mobile ${textCompanyClassName}`}
          >
            <div>{mobileTextTop}</div>
            <div>{mobileTextCenter}</div>
            <div>{mobileTextBottom}</div>
          </div>
        </li>
      );
    });

    return sections;
  }

  render() {
    return (
      <ul className="incentive-tracker clearfix">{this.renderSections()}</ul>
    );
  }
}

export default IncentiveTracker;
