export const SET_HISTORY = 'SET_HISTORY';
export const SET_REDIRECT = 'SET_REDIRECT';

export function setHistory(history) {
  return {
    type: SET_HISTORY,
    payload: history,
  };
}

export function setRedirect(redirect) {
  return {
    type: SET_REDIRECT,
    payload: redirect,
  };
}
