import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';

// React.js components
import OrderYouPickXConfiguration from './order_you_pick_x_configuration';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';

class OrderYouPickX extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItemIndex: 0,
      xCount: this.props.selectedShootProduct.required_children_count,
    };

    this.addItem = this.addItem.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goToBackgroundSelection = this.goToBackgroundSelection.bind(this);
    this.goToNextItem = this.goToNextItem.bind(this);
    this.goToProductSelection = this.goToProductSelection.bind(this);
    this.removeSelectedYouPickXItem =
      this.removeSelectedYouPickXItem.bind(this);
    this.updateSelectedItemIndex = this.updateSelectedItemIndex.bind(this);
  }

  // check this - update via redux
  goBack() {
    this.props.navigateToComponent(ORDER_NAVIGATION['PRODUCT_GROUPS']);
  }

  goToBackgroundSelection() {
    if (
      Object.keys(this.props.youPickXConfiguration).length < this.state.xCount
    ) {
      Swal.fire(
        `Please select ${this.state.xCount} items before selecting background(s)`
      );
    } else {
      this.setState({ selectedItemIndex: 0 }, () => {
        this.props.toggleYouPickXConfiguration();
      });
    }
  }

  goToProductSelection() {
    this.props.toggleYouPickXConfiguration();
  }

  goToNextItem(configuration, bgCode) {
    const nextComponent = this.props.renderAddOns
      ? 'PRODUCT_ADDONS'
      : 'PRODUCT_GROUPS';
    const additionalBackgroundImagesCount =
      this.props.selectedShootProduct
        .additional_product_background_images_count;

    if (configuration.backgroundCodes.length === 0) {
      Swal.fire('Please select a background to go to next item');
    } else if (
      this.state.selectedItemIndex ===
      this.state.xCount + additionalBackgroundImagesCount - 1
    ) {
      // else if (this.state.selectedItemIndex === this.state.xCount - 1) {
      //
      // }
      this.props.addConfiguredYouPickXItemsToCart(
        ORDER_NAVIGATION[nextComponent]
      );
    } else {
      this.setState({ selectedItemIndex: this.state.selectedItemIndex + 1 });
    }
  }

  updateSelectedItemIndex(index) {
    if (this.props.youPickXConfiguring) {
      this.setState({ selectedItemIndex: index });
    }
  }

  addItem(item) {
    if (
      Object.keys(this.props.youPickXConfiguration).length === this.state.xCount
    ) {
      Swal.fire('Please remove an old item in order to add a new item');
    } else if (this.state.selectedItemIndex < this.state.xCount) {
      let modifiedItem = Object.assign({}, item);
      modifiedItem.id = `${item.id}_${this.props.idSuffix}`;

      this.setState(
        { selectedItemIndex: this.state.selectedItemIndex + 1 },
        () => {
          this.props.configureYouPickXItem(modifiedItem);
        }
      );
    }
  }

  removeSelectedYouPickXItem(itemId) {
    // disable remove when configuring background
    if (!this.props.youPickXConfiguring) {
      this.setState(
        { selectedItemIndex: this.state.selectedItemIndex - 1 },
        () => {
          this.props.removeYouPickXItem(itemId);
        }
      );
    }
  }

  renderBackButton() {
    if (!this.props.youPickXConfiguring) {
      return (
        <div
          onClick={this.goBack}
          className="order-you-pick-x__back-button cursor-pointer"
        >
          {'<   Back'}
        </div>
      );
    } else {
      return (
        <div
          onClick={this.goToProductSelection}
          className="order-you-pick-x__back-button cursor-pointer"
        >
          {'<   Back to Product Selection'}
        </div>
      );
    }
  }

  renderPickSlots() {
    let slots = [];

    for (let i = 0; i < this.state.xCount; i++) {
      let selectedItem;
      const selectedItemId = Object.keys(this.props.youPickXConfiguration)[i];

      if (this.props.youPickXConfiguration[selectedItemId]) {
        selectedItem =
          this.props.youPickXConfiguration[selectedItemId].childItem;
      }

      const prodName = selectedItem ? selectedItem.name : '';
      const removeButton = prodName ? '' : 'hidden';
      const cursorPointer = this.props.youPickXConfiguring
        ? 'cursor-pointer'
        : '';
      let disabled = '';

      if (
        this.props.youPickXConfiguring &&
        i !== this.state.selectedItemIndex
      ) {
        disabled = 'disabled';
      }

      let additionalText = '';

      if (
        !this.props.youPickXConfiguring &&
        this.state.selectedItemIndex === i
      ) {
        additionalText = 'Add Item';
      }

      slots.push(
        <li
          onClick={() => this.updateSelectedItemIndex(i)}
          className={`you-pick-x-slot ${disabled} ${cursorPointer} clearfix`}
          key={`you-pick-x-slot-${i}`}
        >
          <div className="you-pick-x-slot__item-name clearfix">
            <div className="you-pick-x-slot__number">
              {i + 1}. {additionalText}
            </div>
            <div className="you-pick-x-slot__name">{prodName}</div>
          </div>
          <div
            className={`you-pick-x-slot__remove ${removeButton} cursor-pointer clearfix`}
            onClick={() => this.removeSelectedYouPickXItem(selectedItemId)}
          >
            <div className="you-pick-x-slot__remove-button">X</div>
            <div className="you-pick-x-slot__remove-text">Remove</div>
          </div>
        </li>
      );
    }
    const additionalBackgroundImagesCount =
      this.props.selectedShootProduct
        .additional_product_background_images_count;

    for (
      let i = 0 + this.state.xCount;
      i < this.state.xCount + additionalBackgroundImagesCount;
      i++
    ) {
      const prodName =
        this.props.selectedShootProduct.additional_product_description;
      const cursorPointer = this.props.youPickXConfiguring
        ? 'cursor-pointer'
        : '';
      let disabled = '';

      if (
        this.props.youPickXConfiguring &&
        i !== this.state.selectedItemIndex
      ) {
        disabled = 'disabled';
      } else {
        disabled = '';
      }

      slots.push(
        <li
          onClick={() => this.updateSelectedItemIndex(i)}
          className={`you-pick-x-slot ${disabled} ${cursorPointer} clearfix`}
          key={`you-pick-x-slot-${i}`}
        >
          <div className="you-pick-x-slot__item-name clearfix">
            <div className="you-pick-x-slot__number">Bonus Item -</div>
            <div className="you-pick-x-slot__name">{prodName}</div>
          </div>
        </li>
      );
    }

    return slots;
  }

  renderItems() {
    const items = this.props.selectedShootProduct.child_products.map(
      (item, i) => {
        return (
          <li
            className="you-pick-x-item col-xs-12 col-md-6 col-lg-6"
            key={`you-pick-x-item-${i}`}
          >
            <img
              src={item.image_thumbnail}
              className="you-pick-x-item__image"
            />
            <div className="you-pick-x-item__name">{item.name}</div>
            <div
              className="you-pick-x-item__add-button button bg--dark-blue"
              onClick={() => this.addItem(item)}
            >
              Add
            </div>
          </li>
        );
      }
    );

    return items;
  }

  renderActiveComponent() {
    switch (this.props.youPickXConfiguring) {
      case false:
        return (
          <div className="order-you-pick-x__right-container col-xs-12 col-md-7 col-lg-7">
            <div className="order-you-pick-x__header">
              <div className="you-pick-x-header__header">
                Choose {this.state.xCount}
              </div>
              <div className="you-pick-x-header__desc">
                Choose {this.state.xCount} of anything
              </div>
            </div>
            <ul className="order-you-pick-x__items equal-height row">
              {this.renderItems()}
            </ul>
          </div>
        );
      case true:
        return (
          <OrderYouPickXConfiguration
            additionalSelectedBackgroundOptions={
              this.props.additionalSelectedBackgroundOptions
            }
            backgroundOptions={this.props.backgroundOptions}
            configureAdditionalSelectedBackgroundOptions={
              this.props.configureAdditionalSelectedBackgroundOptions
            }
            configureYouPickXItem={this.props.configureYouPickXItem}
            formattedBackgroundOptions={this.props.formattedBackgroundOptions}
            getStudentImage={this.props.getStudentImage}
            goToNextItem={this.goToNextItem}
            itemCount={this.state.xCount}
            selectedItemIndex={this.state.selectedItemIndex}
            selectedShootProduct={this.props.selectedShootProduct}
            toggleBackground={this.props.toggleBackground}
            youPickXBackgroundOptionSelected={
              this.props.youPickXBackgroundOptionSelected
            }
            youPickXCartItems={this.props.youPickXCartItems}
            youPickXConfiguration={this.props.youPickXConfiguration}
          />
        );
    }
  }

  renderNextButton() {
    const bgButtonColor =
      Object.keys(this.props.youPickXConfiguration).length === this.state.xCount
        ? 'bg--dark-blue'
        : 'bg--dark-grey';
    const bgButtonDisplay = this.props.youPickXConfiguring ? 'hidden' : '';

    if (
      this.props.backgroundChoiceMode ===
      'advanced_greenscreen_backgrounds_only'
    ) {
      return (
        <div
          className={`order-you-pick-x__next-button button--full-width ${bgButtonColor} ${bgButtonDisplay}`}
          onClick={this.goToBackgroundSelection}
        >
          Next, Background Selection
        </div>
      );
    } else {
      const nextComponent = this.props.renderAddOns
        ? 'PRODUCT_ADDONS'
        : 'PRODUCT_GROUPS';

      return (
        <div
          className={`order-you-pick-x__next-button button--full-width ${bgButtonColor} ${bgButtonDisplay}`}
          onClick={() =>
            this.props.addConfiguredYouPickXItemsToCart(
              ORDER_NAVIGATION[nextComponent]
            )
          }
        >
          Done
        </div>
      );
    }
  }

  render() {
    return (
      <div className="order-you-pick-x">
        <div className="clearfix-- row">
          <div className="order-you-pick-x__slots-container col-xs-12 col-md-5 col-lg-5">
            {this.renderBackButton()}
            <div className="order-you-pick-x__header clearfix">
              <div className="you-pick-x-header__name">
                {this.props.selectedShootProduct.name}
              </div>
              <div className="you-pick-x-header__price">
                {this.props.displayPrice(this.props.selectedShootProduct.price)}
              </div>
            </div>
            <ul className="order-you-pick-x__slots">
              {this.renderPickSlots()}
            </ul>
            {this.renderNextButton()}
          </div>
          {this.renderActiveComponent()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.resetYouPickXConfiguration();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default connect(null, mapDispatchToProps)(OrderYouPickX);
