import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { loginParent, setAuthToken } from '../../actions/action_auth';
import { setHistory } from '../../actions/action_history';
import { getParentData } from '../../actions/action_parent';
import history from '../../utils/history';

const Headline = styled.h3`
  margin: auto;
  font-size: 24px;
  font-weight: 800;
  max-width: 70%;
  text-align: center;
  margin-bottom: 16px;
`;

const LoginError = styled.div`
  a {
    text-decoration: underline;
    color: #a94442;
    background: none;
    &:hover {
      background-color: #a94442;
      color: #f2dede;
      text-decoration: none;
    }
  }
`;

const EmailNotFound = styled.div`
  a {
    text-decoration: underline;
    color: #31708f;
    background: none;
    &:hover {
      background-color: #31708f;
      color: #d9edf7;
      text-decoration: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: #337ab7;
  &:hover {
    background-color: #337ab7;
    color: #ffffff;
  }
`;

const Button = styled.span`
  font-weight: 800;
  cursor: pointer;
`;

const AccountLookupForm = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [requestPassword, setRequestPassword] = useState(false);
  const [emailUnregistered, setEmailUnregistered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
    errors: {},
  });

  const checkAccount = async (e) => {
    e.preventDefault();

    if (userEmail === '') return;

    setLoading(true);
    setEmailUnregistered(false);

    axios
      .get(
        '/api/v2/parents/check-account?email=' + encodeURIComponent(userEmail),
      )
      .then(function (response) {
        if (response?.data?.message === 'account exists')
          setRequestPassword(true);

        if (response?.response?.status === 404) {
          console.log('set email unregisterd');
          setEmailUnregistered(true);
        }
      })
      .catch(function (error) {
        console.log(error);

        if (error?.response?.status === 404) {
          setEmailUnregistered(true);
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    if (props.auth.currentUserEmail) history.replace('/dashboard');
  }, [props.auth]);

  const goToSignupForm = () => {
    history.replace('/new-account');
  };

  const handleOnChange = (event) => {
    setState({ [event.target.name]: event.target.value });
  };

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setUserPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (userPassword === '') return;

    setLoading(true);

    const input = {
      email: userEmail,
      password: userPassword,
      authenticity_token: props.auth.authToken,
    };

    setState({ errors: {} });

    const response = await props.loginParent(input);

    const { data } = response.payload;

    if (data.errors) {
      setState({ errors: response.payload.data.errors });
      setLoading(false);
    } else if (data.currentUserId && !data.signupCompleted) {
      history.push('/new-account', { step: 2 });
      window.location.reload(true);
    } else if (data.currentUserId && data.signupCompleted) {
      props.getParentData();
      setIsLoggedIn(true);

      console.log('>> logged in, redirecting to dashboard...');

      const activeRedirect = Cookies.get('active_redirect');

      if (activeRedirect === 'lookup-by-id') {
        return history.push('/v3/lookup-by-student-id');
      }

      return history.push('/dashboard');
      window.location.reload(true); // TODO: can we remove this?
    } else {
      props.getParentData();
      console.log('>> redirecting to dashboard via scenario 2...');
      return history.push('/dashboard');
      window.location.reload(true); // TODO: can we remove this?
    }
  };

  const formErrors = state?.errors?.login ? (
    <LoginError className="alert alert-danger">
      <p>
        Error: the password you entered didn't match our records. Please try
        again or <a href="/password-reset">reset your password</a>.
      </p>
    </LoginError>
  ) : null;

  const emailNotRegisteredError = emailUnregistered ? (
    <EmailNotFound className="alert alert-info">
      <p>
        An account associated with that email was not found. Try a different
        one?
      </p>
      <p>
        Or <a href="/new-account">click here to create your free account</a>.
      </p>
    </EmailNotFound>
  ) : null;

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const EmailInput = !requestPassword && (
    <>
      <p>Enter the email associated with your account.</p>
      <p>
        If you do not have an account yet,{' '}
        <StyledLink href="/new-account">click here to create one</StyledLink>.
      </p>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          aria-describedby="emailHelp"
          onChange={handleEmailChange}
          value={userEmail}
          placeholder="name@example.com"
        />
      </div>
      {emailNotRegisteredError}
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </>
  );

  const PasswordInput = requestPassword && !emailUnregistered && (
    <>
      <p>Great! We found your account!</p>
      <p>Please enter the password for {userEmail}.</p>
      <div className="form-group">
        <label htmlFor="email">Password</label>
        <input
          id="password"
          className="form-control"
          onChange={handlePasswordChange}
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={userPassword}
          placeholder="password"
        />
      </div>
      <div className="form-group">
        <label className="checkbox-inline">
          <input
            type="checkbox"
            value=""
            id="showPassword"
            onClick={handleShowPasswordClick}
          />
          show password
        </label>
      </div>
      {formErrors}
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </>
  );

  if (isLoggedIn) {
    return null;
  }

  return (
    <Wrapper className="row row-no-gutters">
      <div className="col-lg-6 col-md-8 col-xs-12">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title text-center">Log In to Your Account</h3>
          </div>
          <div className="panel-body">
            <form onSubmit={requestPassword ? handleLogin : checkAccount}>
              {EmailInput}
              {PasswordInput}
              {requestPassword && (
                <div style={{ marginTop: '15px' }}>
                  <p>
                    <StyledLink href="/password-reset">
                      Forgot your password?
                    </StyledLink>
                  </p>
                  <p>
                    <StyledLink
                      onClick={(e) => {
                        e.preventDefault();
                        setRequestPassword(false);
                      }}
                    >
                      Use a different email
                    </StyledLink>
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginParent,
      setAuthToken,
      setHistory,
      getParentData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountLookupForm);
