import React, { useEffect } from 'react';
import styled from 'styled-components';
import history from '../../utils/history';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  height: 100vh;
`;

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  div {
    border: 1px solid black;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #0084ba;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0084ba transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const LoginRedirect = (props) => {
  useEffect(() => {
    setTimeout(() => {
      history.push('/login');
      window.location.reload(true);
    }, '500');
  }, []);

  return (
    <Wrapper>
      <Loader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Loader>
    </Wrapper>
  );
};

export default LoginRedirect;
