const ORDER_NAVIGATION = {
  SHOOT_KEY_FORM: 1,
  SHOOT_CONFIRMATION: 2,
  PRODUCT_GROUPS: 3,
  BNL_PRODUCT_GROUPS: 4,
  PRODUCT_CONFIGURATION: 5,
  BNL_PRODUCT_CONFIGURATION: 6,
  YOU_PICK_X: 7,
  BUILD_YOUR_OWN: 8,
  PRODUCT_ADDONS: 9,
  PRODUCT_RECS: 10,
  INCENTIVE_CONFIGURATION: 11,
  GESKUS_INCENTIVE_CONFIGURATION: 12,
  CART: 13,
  BNL_CART: 14,
  CHECKOUT: 15,
  ORDER_CONFIRMATION: 16,
  BNL_CHILDREN_PRODUCT_GROUPS: 17,
  NTH_FREE_PRODUCTS: 18,
};

export { ORDER_NAVIGATION };
