import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

class BnlOrderSmsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayFormatted: this.props.displayFormatted || true,
      rawSmsPhone: this.props.smsPhone,
      formattedSmsPhone: this.formattedPhoneNumber(),
    };

    this.displayFormattedSmsPhone = this.displayFormattedSmsPhone.bind(this);
    this.hideFormattedSmsPhone = this.hideFormattedSmsPhone.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  formattedPhoneNumber(smsPhone = this.props.smsPhone) {
    const number = smsPhone
      .replace(/-|\s/g, '')
      .split('')
      .map((digit, i) => {
        if (
          (i === 2 && smsPhone.length > 3) ||
          (i === 5 && smsPhone.length > 6)
        ) {
          return `${digit}-`;
        } else {
          return digit;
        }
      });

    return number.join('');
  }

  displayFormattedSmsPhone() {
    this.setState({ displayFormatted: true });
  }

  hideFormattedSmsPhone() {
    this.setState({ displayFormatted: false }, () => {
      document.querySelector('.bnl-sms-form__input').focus();
    });
  }

  handleOnChange(event) {
    if (event.target.name === 'rawSmsPhone') {
      event.persist();
      this.setState({ rawSmsPhone: event.target.value }, () => {
        this.props.handleSmsInputChange(event.target.value);
      });
    }
  }

  renderHeader() {
    if (!this.props.checkout && !this.props.mobileConfiguring) {
      return (
        <h4 className="bnl-sms-form__header">
          Please verify your cell phone and edit if necessary.
          <br />
          <br />
          The image will be sent to this number once it is ready.
        </h4>
      );
    } else if (this.props.mobileConfiguring) {
      return <h4 className="bnl-sms-form__header--mobile">SMS Phone Number</h4>;
    }
  }

  renderFormattedInput() {
    const formattedDisplay = this.state.displayFormatted ? '' : 'hidden';

    if (this.props.checkout) {
      return (
        <div className={`bnl-sms-form__input-container ${formattedDisplay}`}>
          {this.state.formattedSmsPhone}
        </div>
      );
    } else {
      return (
        <div className={`bnl-sms-form__input-container ${formattedDisplay}`}>
          <input
            className="bnl-sms-form__input--formatted"
            name="formattedSmsPhone"
            onChange={this.handleOnChange}
            onFocus={this.hideFormattedSmsPhone}
            placeholder="SMS Phone Number"
            value={this.state.formattedSmsPhone}
          />
          {this.renderEditButton()}
        </div>
      );
    }
  }

  renderEditButton() {
    if (!this.props.checkout) {
      return (
        <div
          className="bnl-sms-form__edit-button cursor-pointer"
          onClick={this.hideFromattedSmsPhone}
        >
          Edit
        </div>
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.smsPhone !== nextProps.smsPhone) {
      this.setState({
        formattedSmsPhone: this.formattedPhoneNumber(nextProps.smsPhone),
      });
    } else if (this.props.displayFormatted !== nextProps.displayFormatted) {
      this.setState({ displayFormatted: nextProps.displayFormatted });
    }
  }

  render() {
    const rawDisplay = this.state.displayFormatted ? 'hidden' : '';

    let containerClass = '';
    let inputsContainerClass = '';
    if (this.props.checkout) {
      containerClass = 'bnl-sms-form-container--checkout';
    }

    if (this.props.mobileConfiguring) {
      inputsContainerClass = 'bnl-sms-form__inputs-container--mobile';
    }

    const checkoutEditing = this.props.editing ? 'payment-info__input' : '';

    return (
      <div className={`bnl-sms-form-container ${containerClass}`}>
        {this.renderHeader()}
        <div className={`bnl-sms-form-inner-container ${inputsContainerClass}`}>
          <input
            className={`bnl-sms-form__input ${rawDisplay} ${checkoutEditing}`}
            name="rawSmsPhone"
            onBlur={this.displayFormattedSmsPhone}
            onChange={this.handleOnChange}
            value={this.state.rawSmsPhone}
          />
          {this.renderFormattedInput()}
        </div>
        <div className="bnl-sms-form__error">{this.props.errorMessage}</div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, null)(BnlOrderSmsForm);
