import { useSelector } from 'react-redux';

export const useCurrentOrderPromptedInterstitialProductIds = () => {
  const {
    additionalData: { selectedStudent },
    v3Order: { orders },
  } = useSelector((state) => state);

  const order = orders[selectedStudent?.id];
  return order ? order.interstitialProductsPrompted : [];
};
