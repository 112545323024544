import { useEffect } from 'react';

const usePreventNavigation = (history, path) => {
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        history.push(path);
        return false; // this prevents the navigation
      }
    });

    return () => {
      unblock();
    };
  }, [history, path]); // listening to changes in history and path
};

export default usePreventNavigation;
