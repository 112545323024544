import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { updateOrderActiveComponent } from '../../../actions/action_order_navigation';
import { storeYearbookStudentId } from '../../../actions/action_bnl_yearbook_photo_selection';

// React.js components
import Spinner from '../../utils/spinner';

// constants files
import { ORDER_NAVIGATION } from '../../../constants/order_navigation';

class BnlParentYearbookPhotoSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionData: {},
      loading: false,
      success: false,
    };

    this.selectPhoto = this.selectPhoto.bind(this);
    this.submitYearBookPhotoData = this.submitYearBookPhotoData.bind(this);
  }

  selectPhoto(studentId, galleryKey, imageId) {
    let selectionData = this.state.selectionData;

    if (!selectionData[studentId]) {
      selectionData[studentId] = {
        galleryKey: galleryKey,
        imageId: imageId,
      };
    } else {
      if (selectionData[studentId]['imageId'] != imageId) {
        selectionData[studentId]['imageId'] = imageId;
      } else {
        delete selectionData[studentId];
      }
    }

    this.setState({ selectionData: selectionData });
  }

  renderStudentsGalleries() {
    if (this.props.bnlYearbookPhotoSelection.studentId) {
      return this.renderSingleStudentGallery();
    } else {
      return this.renderMultipleStudentsGalleries();
    }
  }

  renderSingleStudentGallery() {
    const that = this;
    const studentId = this.props.bnlYearbookPhotoSelection.studentId;
    const cpmId = this.props.shoot.shoot.pricing_model.id;
    const className = 'yearbook__student-gallery__image-list';
    const galleryObj = this.props.gallery.galleries[cpmId][studentId];
    const studentName = `${galleryObj.student.firstName} ${galleryObj.student.lastName}`;
    const shootName = galleryObj.shoot.name;
    const gallery = galleryObj.gallery.map((image) => {
      let selected =
        that.state.selectionData[studentId] &&
        that.state.selectionData[galleryObj.student.id].imageId === image.id
          ? 'border--bnl-light-blue'
          : '';

      return (
        <li
          className={`col-xs-6 col-md-3 col-lg-3 ${className} ${selected}`}
          key={`image-${image.id}`}
          onClick={() =>
            this.selectPhoto(studentId, image.gallery_key, image.id)
          }
        >
          <img
            className="yearbook__student-gallery__image-list__image"
            src={image.url}
          />
        </li>
      );
    });

    return (
      <div>
        <div>
          {studentName} - {shootName}
        </div>
        <ul className="row yearbook__student-gallery">{gallery}</ul>
      </div>
    );
  }

  renderMultipleStudentsGalleries() {
    const cpmId = this.props.gallery.cpmId;
    const className = 'yearbook__student-gallery__image-list';
    let studentsGallery = [];

    for (let studentId in this.props.gallery.galleries[cpmId]) {
      let galleryObj = this.props.gallery.galleries[cpmId][studentId];

      if (galleryObj.requiresYearbookPhotoSelection) {
        const studentName = `${galleryObj.student.first_name} ${galleryObj.student.last_name}`;
        const shootName = galleryObj.shoot.name;
        const studentGallery = galleryObj.gallery.map((image) => {
          const selected =
            this.state.selectionData[galleryObj.student.id] &&
            this.state.selectionData[galleryObj.student.id].imageId === image.id
              ? 'border--bnl-light-blue'
              : '';

          return (
            <li
              className={`col-xs-6 col-md-3 col-lg-3 ${className} ${selected}`}
              key={`image-${image.id}`}
              onClick={() =>
                this.selectPhoto(
                  galleryObj.student.id,
                  image.gallery_key,
                  image.id
                )
              }
            >
              <img
                className="yearbook__student-gallery__image-list__image"
                src={image.url}
              />
            </li>
          );
        });

        studentsGallery.push(
          <li key={`student-${studentId}`}>
            <div>
              {studentName} - {shootName}
            </div>
            <ul className="row yearbook__student-gallery">{studentGallery}</ul>
          </li>
        );
      }
    }

    return <ul>{studentsGallery}</ul>;
  }

  submitYearBookPhotoData() {
    let studentCount = 0;

    // studentId is only set when parent authenticates a gallery
    // this states that there is only 1 gallery
    if (this.props.bnlYearbookPhotoSelection.studentId) {
      studentCount = 1;
    } else {
      const cpmId = this.props.gallery.cpmId;
      const galleriesData = this.props.gallery.galleries[cpmId];

      for (let studentId in galleriesData) {
        if (galleriesData[studentId].requiresYearbookPhotoSelection) {
          studentCount += 1;
        }
      }
    }

    if (Object.keys(this.state.selectionData).length !== studentCount) {
      Swal.fire('Please select a photo for each student and then press Save');
      return;
    }

    const that = this;
    let data = [];

    for (let studentId in this.state.selectionData) {
      let studentData = this.state.selectionData[studentId];
      data.push({
        student_id: studentId,
        gallery_password: studentData.galleryKey,
        image_id: studentData.imageId,
      });
    }

    this.setState(
      {
        loading: true,
        success: false,
      },
      () => {
        axios
          .post(`/api/v2/yearbook-photo-selections/select-yearbook-photo`, {
            selection_data: data,
          })
          .then((response) => {
            that.setState({
              loading: false,
              success: response.data.success,
            });

            if (response.data.success) {
              that.promptRedirectModal();
            }
          });
      }
    );
  }

  promptRedirectModal() {
    const that = this;

    Swal.fire({
      title: 'Yearbook Photo Selected!',
      text: 'Would you like to add another gallery or start your order?',
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        confirmButton: 'add-student-redirect__go-to-order',
        cancelButton: 'add-student-redirect__assign-gallery',
      },
      confirmButtonText: 'Start',
      cancelButtonText: 'Add',
    }).then((res) => {
      if (res.value) {
        that.startOrder();
      } else {
        that.props.history.push('/dashboard');
      }
    });
  }

  startOrder() {
    this.props.updateOrderActiveComponent(
      ORDER_NAVIGATION['BNL_PRODUCT_GROUPS']
    );
    this.props.history.push('/order');
  }

  render() {
    return (
      <div className="bnl-yearbook-photo-selection">
        <Spinner loading={this.state.loading} />
        <h3 className="yearbook-photo-selection__header">
          Yearbook Photo Selection
        </h3>
        <div className="yearbook-photo-selection__instruction">
          Please select a photo for the yearbook and click Save
        </div>
        {this.renderStudentsGalleries()}
        <div className="button--bnl" onClick={this.submitYearBookPhotoData}>
          Save
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.storeYearbookStudentId(null);
  }
}

function mapStateToProps(state) {
  return {
    bnlPreShoot: state.bnlPreShoot,
    bnlYearbookPhotoSelection: state.bnlYearbookPhotoSelection,
    gallery: state.gallery,
    shoot: state.shoot,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      storeYearbookStudentId,
      updateOrderActiveComponent,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BnlParentYearbookPhotoSelection);
