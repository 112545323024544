import React from 'react';
import styled from 'styled-components';

const ProductName = styled.div`
  font-weight: 900;
  padding-bottom: 16px;
  width: 100%; // Ensure 100% width of its container
`;

const ProductImageContainer = styled.div`
  margin-bottom: 20px;
  max-width: 72%; // Max width of the image container
  width: 300px; // Starting width of the image container
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // subtle drop shadow

  img {
    width: 100%; // Ensuring image scales within container
    height: auto; // Maintain aspect ratio
  }
`;

const CartItemProductNameImage = ({ cartItem }) => (
  <>
    <ProductName>{cartItem.productName}</ProductName>
    <ProductImageContainer>
      <img src={cartItem.imageThumbnail} alt={cartItem.productName} />
    </ProductImageContainer>
  </>
);

export default CartItemProductNameImage;
