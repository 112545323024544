export const STORE_ORDER = 'STORE_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const UPDATE_CART_COUNT = 'UPDATE_CART_COUNT';
export const RESET_CART = 'RESET_CART';
export const STORE_BNL_BACKGROUND_SELECTION = 'STORE_BNL_BACKGROUND_SELECTION';
export const SET_BNL_SHIPPING = 'SET_BNL_SHIPPING';
export const CONFIRM_USER_CONTACT_ADDRESS = 'CONFIRM_USER_CONTACT_ADDRESS';
export const UPDATE_BNL_UNLOCKED_GROUPS = 'UPDATE_BNL_UNLOCKED_GROUPS';
export const UPDATE_BNL_FORMATTED_LOCK_GROUPS =
  'UPDATE_BNL_FORMATTED_LOCK_GROUPS';
export const UPDATE_BNL_RETOUCHING = 'UPDATE_BNL_RETOUCHING';
export const UPDATE_BNL_PROMPT_RETOUCHING = 'UPDATE_BNL_PROMPT_RETOUCHING';
export const UPDATE_RETOUCHING_PROMPTED_FOR_CURRENT_ORDER =
  'UPDATE_RETOUCHING_PROMPTED_FOR_CURRENT_ORDER';
export const UPDATE_INTERSTITIAL_PROMPTED_FOR_CURRENT_ORDER =
  'UPDATE_INTERSTITIAL_PROMPTED_FOR_CURRENT_ORDER';
export const UPDATE_BNL_FREE_BACKGROUNDS = 'UPDATE_BNL_FREE_BACKGROUNDS';
export const SET_PERSONALIZATION_ON_ORDER = 'SET_PERSONALIZATION_ON_ORDER';
export const REMOVE_PERSONALIZATION_ON_ORDER =
  'REMOVE_PERSONALIZATION_ON_ORDER';
export const SET_PRODUCT_CONFIG = 'SET_PRODUCT_CONFIG';
export const CLEAR_PRODUCT_CONFIG = 'CLEAR_PRODUCT_CONFIG';
export const REMOVE_PRODUCT_CONFIG = 'REMOVE_PRODUCT_CONFIG';

export function storeOrder(studentId, order, additionalData = {}) {
  const data = { studentId, order, additionalData };

  return {
    type: STORE_ORDER,
    payload: data,
  };
}

// TODO: fix this -- shouldn't mutate orders here(?)
export function removeOrder(studentId, orders) {
  delete orders[studentId];

  return {
    type: REMOVE_ORDER,
    payload: orders,
  };
}

export function updateCartCount(count) {
  return {
    type: UPDATE_CART_COUNT,
    payload: count,
  };
}

export function resetCart() {
  return {
    type: RESET_CART,
    payload: null,
  };
}

export function storeBnlBackgroundSelection(backgroundSelection) {
  return {
    type: STORE_BNL_BACKGROUND_SELECTION,
    payload: backgroundSelection,
  };
}

export function setBnlShipping(shipping) {
  return {
    type: SET_BNL_SHIPPING,
    payload: shipping,
  };
}

export function confirmUserContactAddress() {
  return {
    type: CONFIRM_USER_CONTACT_ADDRESS,
  };
}

export function updateBnlUnlockedGroups(unlocked) {
  return {
    type: UPDATE_BNL_UNLOCKED_GROUPS,
    payload: unlocked,
  };
}

export function updateBnlFormattedLockGroups(data) {
  return {
    type: UPDATE_BNL_FORMATTED_LOCK_GROUPS,
    payload: data,
  };
}

export function updateBnlRetouching(data) {
  return {
    type: UPDATE_BNL_RETOUCHING,
    payload: data,
  };
}

export function updateBnlPromptRetouching(data) {
  return {
    type: UPDATE_BNL_PROMPT_RETOUCHING,
    payload: data,
  };
}

export function updateRetouchingPromptedForCurrentOrder(data) {
  return {
    type: UPDATE_RETOUCHING_PROMPTED_FOR_CURRENT_ORDER,
    payload: data,
  };
}

export function updateInterstitialPromptedForCurrentOrder(data) {
  return {
    type: UPDATE_INTERSTITIAL_PROMPTED_FOR_CURRENT_ORDER,
    payload: data,
  };
}

export function updateBnlFreeBackgrounds(data) {
  return {
    type: UPDATE_BNL_FREE_BACKGROUNDS,
    payload: data,
  };
}

export function setPersonalizationOnOrder(data) {
  return {
    type: SET_PERSONALIZATION_ON_ORDER,
    payload: data,
  };
}

export function removePersonalizationOnOrder(data) {
  return {
    type: REMOVE_PERSONALIZATION_ON_ORDER,
    payload: data,
  };
}
