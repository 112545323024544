// Determine whether the product config contains a paid background upgrade and return true if the product has the single_charge_for_background_upgrades flag
const productConfigContainsSingleBackgroundUpgrade = (productConfig) => {
  return Object.values(productConfig).some((product) => {
    const price = product?.background?.price;

    return (
      product?.background?.single_charge_for_background_upgrades === true &&
      typeof price === 'number' &&
      price > 0
    );
  });
};

export default {
  productConfigContainsSingleBackgroundUpgrade,
};
