/* eslint-disable no-undef */
import { useState, useEffect } from 'react';

export const MOBILE = 768;
export const MOBILE_L = 904;

const useScreenSize = () => {
  const [loading, setLoading] = useState(true);
  const [screenSize, setScreenSize] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth < 768) {
        setScreenSize('Mobile');
      } else if (innerWidth < 904) {
        setScreenSize('Mobile');
      } else {
        setScreenSize('Desktop');
      }
    };

    // Initial screen size
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenSize) setLoading(false);
  }, [screenSize]);

  return [screenSize, loading];
};

export default useScreenSize;
