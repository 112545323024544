import React, { Component } from 'react';
import { connect } from 'react-redux';

class IncentiveConfigurationPose extends Component {
  renderPoses(galleryObj, student, firstGalleryId) {
    console.log('RENDERING INCENTIVE CONFIGURATION POSE');
    console.log('firstGalleryId', firstGalleryId);
    console.log(this.props);
    const poses = [];
    const product = this.props.configuringItem;
    // const imageType = this.props.FILTER[product.image_type];
    let workingGallery;

    switch (product.image_type) {
      case 'portrait_image':
        workingGallery = galleryObj.gallery;
        break;
      case 'group_image':
        workingGallery = galleryObj.associatedGroupImage;
        break;
      default:
        workingGallery = galleryObj.gallery;
        break;
    }

    workingGallery.forEach(
      (pose, i) => {
        // if (imageType.includes(pose.image_type)) {
        let selected = '';

        console.log('POSE IDS');
        console.log(pose);

        if (this.props.configuration?.poseIds) {
          if (this.props.configuration.poseIds.includes(pose.id)) {
            selected = 'bnl-product-config__pose--selected ';
          }
        } else if (firstGalleryId === pose.id) {
          selected = 'bnl-product-config__pose--selected ';
        }

        pose.student = student;

        let onClickHandler = this.props.selectPose.bind(this, pose);

        if (this.props.mobileConfiguring) {
          onClickHandler = this.props.selectMobilePose.bind(this, pose);
        }

        poses.push(
          <li
            className="bnl-product-config__pose col-xs-6 col-md-3 col-lg-3 cursor-pointer"
            onClick={onClickHandler}
            key={`pose-${pose.id}`}
          >
            <img
              className={`bnl-product-config__pose-image ${selected}`}
              src={pose.url}
            />
          </li>,
        );
      },
      // }
    );

    return poses;
  }

  renderGalleries() {
    const galleries = [];
    let firstGalleryId = null;

    const companyPricingModelId = this.props.gallery.cpmId;
    const galleriesObj = this.props.gallery.galleries[companyPricingModelId];

    for (const studentId in galleriesObj) {
      const galleryObj = galleriesObj[studentId];
      if (galleryObj.gallery.length > 0) {
        if (firstGalleryId === null && galleryObj.gallery[0].id) {
          firstGalleryId = galleryObj.gallery[0].id;
        }

        galleries.push(
          <li
            className="bnl-product-config__student"
            key={`gallery-${studentId}`}
          >
            <div className="bnl-product-config__student__name">
              {galleryObj.student.first_name} {galleryObj.student.last_name}
            </div>
            <ul className="bnl-product-config__student__poses row">
              {this.renderPoses(galleryObj, galleryObj.student, firstGalleryId)}
            </ul>
            <div className="bnl-divider" />
          </li>,
        );
      }
    }

    return galleries;
  }

  render() {
    return (
      <ul className="incentive-config-galleries row">
        {this.renderGalleries()}
      </ul>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return { gallery: state.gallery };
}

export default connect(mapStateToProps, null)(IncentiveConfigurationPose);
