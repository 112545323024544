import React, { Component } from 'react';

class OrderYouPickXConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBackgroundCode: null,
    };

    this.selectBackground = this.selectBackground.bind(this);
    this._selectBackgroundColor = this._selectBackgroundColor.bind(this);
    this._displayStudentOnBackground =
      this._displayStudentOnBackground.bind(this);
  }

  componentDidMount() {
    console.log('component mounted');
    console.log(this.props);
  }

  componentWillUpdate() {
    // console.log('component will update');
    // console.log(this.props);
  }

  selectBackground(id) {
    this.setState({ selectedBackgroundCode: id });
  }

  _selectBackgroundColor(option, selectedYouPickXItem = null) {
    // console.log('selected');
    // console.log(selectedYouPickXItem);
    // console.log(option);
    if (selectedYouPickXItem) {
      this.props.configureYouPickXItem(selectedYouPickXItem, option);
    } else {
      this.props.configureAdditionalSelectedBackgroundOptions(option);
    }
  }

  getSelected(code, selectedYouPickXItem = null) {
    if (this.props.selectedItemIndex < this.props.itemCount) {
      return this.props.youPickXBackgroundOptionSelected(
        code,
        selectedYouPickXItem
      )
        ? 'order-background-option--selected'
        : '';
    } else {
      const options = this.props.additionalSelectedBackgroundOptions;
      if (options[this.props.selectedShootProduct.id].codes[0] === code) {
        return 'order-background-option--selected';
      } else {
        return '';
      }
    }
  }

  renderBackgroundOptions() {
    const that = this;
    let selectedYouPickXItem;

    if (this.props.selectedItemIndex < this.props.itemCount) {
      const selectedId = Object.keys(this.props.youPickXConfiguration)[
        this.props.selectedItemIndex
      ];

      selectedYouPickXItem =
        this.props.youPickXConfiguration[selectedId].childItem;
    }

    const options = this.props.backgroundOptions.map((option, i) => {
      const selected = that.getSelected(option.id, selectedYouPickXItem);
      const style = {
        backgroundImage: `url("${option.image_thumbnail}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

      // console.log('rendering background option...');
      // console.log(i);
      // console.log(option);

      if (this.props.selectedItemIndex < this.props.itemCount) {
        return (
          <li
            onClick={() =>
              this._selectBackgroundColor(option, selectedYouPickXItem)
            }
            className={`you-pick-x-config__background-option cursor-pointer ${selected}`}
            style={style}
            key={`background-option-${i}`}
          />
        );
      } else {
        return (
          <li
            onClick={() =>
              this.props.configureAdditionalSelectedBackgroundOptions(option)
            }
            className={`you-pick-x-config__background-option cursor-pointer ${selected}`}
            style={style}
            key={`background-option-${i}`}
          />
        );
      }
    });

    return options;
  }

  renderSelectedYouPickXItem() {
    const selectedId = Object.keys(this.props.youPickXConfiguration)[
      this.props.selectedItemIndex
    ];
    const selectedYouPickXItem =
      this.props.youPickXConfiguration[selectedId].childItem;

    return (
      <div className="you-pick-x-config__selected">
        <div className="you-pick-x-config__selected-name">
          {selectedYouPickXItem.name}
        </div>
      </div>
    );
  }

  renderNextButton() {
    const additionalBackgroundImagesCount =
      this.props.selectedShootProduct
        .additional_product_background_images_count;
    let configuration;
    let text = 'Next Item';

    if (
      this.props.selectedItemIndex ===
      this.props.itemCount + additionalBackgroundImagesCount - 1
    ) {
      text = 'Done';
    }

    if (this.props.selectedItemIndex < this.props.itemCount) {
      const childItemId = Object.keys(this.props.youPickXConfiguration)[
        this.props.selectedItemIndex
      ];
      configuration = this.props.youPickXConfiguration[childItemId];
    } else {
      configuration = {
        backgroundCodes:
          this.props.additionalSelectedBackgroundOptions[
            this.props.selectedShootProduct.id
          ].codes,
      };
    }

    return (
      <div
        onClick={() =>
          this.props.goToNextItem(
            configuration,
            this.state.selectedBackgroundCode
          )
        }
        className="you-pick-x-config__next-item button--full-width bg--dark-blue"
      >
        {text}
      </div>
    );
  }

  renderItem() {
    if (this.props.selectedItemIndex < this.props.itemCount) {
      return this.renderSelectedYouPickXItem();
    } else {
      return (
        <div className="you-pick-x-config__selected">
          <div className="you-pick-x-config__selected-name">
            {this.props.selectedShootProduct.additional_product_description}
          </div>
        </div>
      );
    }
  }

  _displayStudentOnBackground() {
    let backgroundId;
    const currentIndex = this.props.selectedItemIndex;

    if (currentIndex < this.props.itemCount) {
      backgroundId = Object.values(this.props.youPickXConfiguration)[
        currentIndex
      ]['backgroundCodes'][0];
    } else {
      backgroundId =
        this.props.additionalSelectedBackgroundOptions[
          this.props.selectedShootProduct.id
        ]['codes'][0];
    }

    // console.log('BACKGROUND ID');
    // console.log(backgroundId);
    // console.log(this.props.formattedBackgroundOptions);

    let selectedBgUrl;

    if (this.props.formattedBackgroundOptions[backgroundId]) {
      selectedBgUrl =
        this.props.formattedBackgroundOptions[backgroundId].image_thumbnail;
    } else {
      selectedBgUrl = '';
    }

    const selectedBgBackgroundStyle = {
      backgroundImage: `url("${selectedBgUrl}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
    const studentImage = this.props.getStudentImage();
    let className;
    let studentImageUrl;
    let desc;

    if (studentImage.studentImageUrl) {
      className = 'you-pick-x-student-image';
      studentImageUrl = studentImage.studentImageUrl;
      desc = 'Student Image';
    } else {
      className = 'you-pick-x-demo-student-image';
      studentImageUrl = studentImage.demoStudentImageUrl;
      desc = 'Demo Student Image';
    }
    studentImageUrl =
      'https://s3.amazonaws.com/cdn-portraitpics/products/1/example-images/example-image-1.png';
    className = 'you-pick-x-student-image';

    return (
      <div>
        <div className="order-options__demo-desc">{desc}</div>
        <div
          className="you-pick-x-background"
          style={selectedBgBackgroundStyle}
        >
          <img
            className={`you-pick-x-student-image ${className}`}
            src={studentImageUrl}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="order-you-pick-x__right-container col-xs-12 col-md-7 col-lg-7 clearfix">
        <div className="you-pick-x-config__left">
          <div className="order-you-pick-x__header">
            <div className="you-pick-x-header__header">Select a Background</div>
          </div>
          <ul className="you-pick-x-config__background-options equal-height">
            {this.renderBackgroundOptions()}
          </ul>
        </div>
        <div className="you-pick-x-config__right">
          {this.renderItem()}
          {this._displayStudentOnBackground()}
          {this.renderNextButton()}
        </div>
      </div>
    );
  }
}

export default OrderYouPickXConfiguration;
