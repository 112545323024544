import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import formatUSD from '../../../../utils/formatUSD';

const Wrapper = styled.div`
  padding: 10px 25px;
`;

const FamilyDiscountDetailsHeader = styled.h3`
  font-size: 18px;
  color: #0084ba;
  margin-bottom: 20px;
`;

const DiscountList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
  }

  li:last-child {
    border-bottom: none;
  }

  strong {
    font-weight: 600;
  }
`;

const FamilyDiscountDetails = ({ cartTotals }) => {
  const selectOrders = useCallback(
    (state) => state.v3Order.orders,
    [], // No dependencies
  );

  const orders = useSelector(selectOrders);

  if (!cartTotals || !cartTotals.cartTotalsObject) {
    return null;
  }

  const cartTotalsObject = cartTotals.cartTotalsObject;

  const discounts = Object.keys(orders).reduce((acc, studentId) => {
    const order = orders[studentId];

    if (
      cartTotalsObject[studentId] &&
      cartTotalsObject[studentId]?.family_discount_amount > 0
    ) {
      let familyDiscountAmount =
        cartTotalsObject[studentId]?.family_discount_amount;

      if (familyDiscountAmount > 0) {
        familyDiscountAmount *= -1.0;
      }

      const formattedDiscountAmount = formatUSD(familyDiscountAmount);

      acc.push({
        id: studentId,
        name: `${order?.student?.firstName} ${order?.student?.lastName}`,
        discountAmount: `${formattedDiscountAmount}`,
      });
    }

    return acc;
  }, []);

  if (discounts.length === 0) {
    return null;
  }

  return (
    <Wrapper className="payment-info-container clearfix">
      <FamilyDiscountDetailsHeader>Family Discount</FamilyDiscountDetailsHeader>
      <p>You qualified for a family discount!</p>
      <p>Details:</p>
      <DiscountList>
        {discounts.map((discount) => (
          <li key={discount.id}>
            <strong>{discount.name}</strong> {discount.discountAmount}
          </li>
        ))}
      </DiscountList>
    </Wrapper>
  );
};

FamilyDiscountDetails.propTypes = {
  cartTotals: PropTypes.shape({
    cartTotalsObject: PropTypes.objectOf(
      PropTypes.shape({
        family_discount_amount: PropTypes.number,
      }),
    ),
  }),
};

export default React.memo(FamilyDiscountDetails);
