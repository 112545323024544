import axios from 'axios';

export const APPLY_DISCOUNT_CODE = 'APPLY_DISCOUNT_CODE';
export const UPDATE_DISCOUNT_CODE = 'UPDATE_DISCOUNT_CODE';
export const RESET_DISCOUNT_CODE = 'RESET_DISCOUNT_CODE';

const apiUrl = '/api/v2';

export function applyDiscountCode(data) {
  const request = axios.get(`${apiUrl}/discount-codes/apply-discount-code`, {
    params: data,
  });

  return {
    type: APPLY_DISCOUNT_CODE,
    payload: request,
  };
}

export function updateDiscountCode(discountCode) {
  return {
    type: UPDATE_DISCOUNT_CODE,
    payload: discountCode,
  };
}

export function resetDiscountCode() {
  return {
    type: RESET_DISCOUNT_CODE,
    payload: null,
  };
}
