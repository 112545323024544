import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutParent } from '../../actions/action_auth';
import { resetAdditionalData } from '../../actions/additionalData';
import { resetBnlIncentive } from '../../actions/action_bnl_incentive';
import { resetCart } from '../../actions/action_cart';
import { resetDiscountCode } from '../../actions/action_discount_code';
import { resetGeskusIncentive } from '../../actions/action_geskus_incentive';
import { setCompanyName } from '../../actions/action_company';

class ParentLogoutButton extends Component {
  constructor(props) {
    super(props);

    this.logoutParent = this.logoutParent.bind(this);
  }

  logoutParent() {
    const that = this;

    // TODO: we need to better tie this to the resetState() function
    // in order_container.jsx
    this.props.resetCart();
    this.props.resetBnlIncentive();
    this.props.resetGeskusIncentive();
    this.props.resetDiscountCode();
    this.props.resetAdditionalData();

    this.props.logoutParent().then(
      // setCompanyName after redux state gets wiped clean
      (response) => {
        that.props.setCompanyName(that.props.companyName);
      },
    );
  }

  render() {
    return (
      <div onClick={this.logoutParent} className="logout-button cursor-pointer">
        Logout
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutParent,
      resetAdditionalData,
      resetBnlIncentive,
      resetCart,
      resetDiscountCode,
      resetGeskusIncentive,
      setCompanyName,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentLogoutButton);
