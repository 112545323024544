import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProductByLocation } from '../helpers/store';
import ConfigScreen from './configScreen';

const gridStyle = {};

export const Product = ({ shoot, ...props }) => {
  const product = getProductByLocation(shoot?.shootProducts, props.location);
  const { child_products } = product;

  return (
    <ConfigScreen
      itemsDesktop={4}
      itemsMobile={2}
      gridStyle={gridStyle}
      items={child_products}
      instruction="Select a Product"
    />
  );
};

const mapStateToProps = (state) => ({
  shoot: state.shoot,
});

export default withRouter(connect(mapStateToProps)(Product));
